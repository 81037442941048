<template>
    <div>
        <v-card elevation="1">
            <v-container>
                <v-row>
                    <v-col :cols="gridColumnSize">
                        <v-row>
                            <v-col cols="12">
                                <v-toolbar class="datatableToolbar" flat>
                                    <v-toolbar-title>Billing Items</v-toolbar-title>
                                    <div class="flex-grow-1"></div>
                                    <v-btn
                                        tile color="secondary"
                                        :loading="loadingItems"
                                        @click="fetchBillingItems"
                                    >
                                        <v-icon>mdi-refresh</v-icon>
                                        <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
                                    </v-btn>
                                    <v-btn
                                        v-if="!isFormActive"
                                        tile
                                        color="secondary"
                                        @click="activateForm"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                        <span v-if="$vuetify.breakpoint.mdAndUp">Add Items</span>
                                    </v-btn>
                                </v-toolbar>
                                <DxDataGrid
                                    id="billingItemsGrid"
                                    :ref="billingItemsDataGridRefName"
                                    :data-source="billingItems"
                                    :show-borders="false"
                                    :hoverStateEnabled="true"
                                    noDataText="No current items"
                                    height="600"
                                    key-expr="itemCode"
                                >
                                    <DxScrolling mode="virtual"/>
                                    <DxHeaderFilter :visible="true" />
                                    <DxColumn data-field="itemCode" data-type="string" caption="Item Code" width="20%"/>
                                    <DxColumn data-field="billingItemType" data-type="string" caption="Item Type"/>
                                    <DxColumn data-field="description" data-type="string" caption="Description"/>
                                    <DxColumn data-field="tuC_Percentage" data-type="number" caption="TUC %" />
                                    <DxColumn data-field="faF_Percentage" data-type="number" caption="FAF %"/>
                                    <DxColumn data-field="kR_UnitIso" data-type="string" caption="ISO"/>
                                    <DxColumn data-type="string" caption="KR Service"/>
                                    <DxColumn data-field="kR_Cartage" data-type="string" caption="KR Cartage"/>
                                    <DxColumn
                                        cell-template="actionCellTemplate"
                                        caption="Actions"
                                        width="10%"
                                    />
                                    <template #actionCellTemplate="{data}">
                                      <div>
                                        <!-- Show Delete Billing Item -->
                                        <v-icon
                                            color="primary"
                                            size="18"
                                            class="mr-2"
                                            title="Delete Billing Item"
                                            @click="deleteBillingItem(data.data)"
                                        >
                                          mdi-trash-can
                                        </v-icon>
                                      </div>
                                    </template>
                                </DxDataGrid>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col :cols="formColumnSize" v-if="isFormActive">
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col class="text-right" cols="5">
                                <v-btn
                                    v-if="isFormActive"
                                    tile class="mb-2"
                                    color="secondary"
                                    @click="deactivateForm"
                                >
                                    <v-icon>mdi-arrow-collapse-right</v-icon>
                                    <span v-if="$vuetify.breakpoint.smAndUp">Collapse</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-card elevation="3">
                                    <v-card-text>
                                        <ValidationSummary :validationErrors="validationErrors" />
                                        <v-form ref="itemForm" lazy-validation>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-select
                                                            v-model="billingData.billingItemType"
                                                            :items="computedBillingItemTypes"
                                                            label="Item Type"
                                                            :rules="itemTypeRules"
                                                            tabindex="1"
                                                            dense
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="billingData.description"
                                                            label="Item Description"
                                                            tabindex="2"
                                                            dense
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="billingData.billingItemType ? lowerCaseBillingType === 'faf_kr' : false ">
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model.number="billingData.FAF_Percentage"
                                                            type="number"
                                                            label="FAF Percentage"
                                                            oninput="if(this.value < 0) this.value = 0;"
                                                            :rules="lowerCaseBillingType === 'faf_kr' ? fafPercentageRules : []"
                                                            dense
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="billingData.billingItemType ? lowerCaseBillingType === 'roadbridge' : false ">
                                                  <v-col cols="6">
                                                    <v-autocomplete
                                                        v-model="billingData.RoadBridgeCode"
                                                        :items="computedRoadBridges"
                                                        label="Road Bridge Code"
                                                        clearable
                                                        :rules="lowerCaseBillingType === 'roadbridge' ? roadBridgeCodeRules : []"
                                                        dense
                                                    ></v-autocomplete>
                                                  </v-col>
                                                </v-row>
                                                <v-row v-if="billingData.billingItemType ? lowerCaseBillingType === 'tuc_kr' : false ">
                                                  <v-col cols="6">
                                                    <v-text-field
                                                        v-model.number="billingData.TUC_Percentage"
                                                        type="number"
                                                        label="FAF Percentage"
                                                        oninput="if(this.value < 0) this.value = 0;"
                                                        :rules="lowerCaseBillingType === 'tuc_kr' ? tucPercentageRules : []"
                                                    ></v-text-field>
                                                  </v-col>
                                                </v-row>
                                                <v-row v-if="billingData.billingItemType ? lowerCaseBillingType === 'service' : false ">
                                                    <v-col cols="6">
                                                        <v-select
                                                            v-model="billingData.KR_Origin"
                                                            :items="origins"
                                                            item-text="workstationName"
                                                            item-value="workstationCode"
                                                            :rules="lowerCaseBillingType === 'service' ? originRules : []"
                                                            label="Kiwi Rail Origin"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-select
                                                            v-model="billingData.KR_Destination"
                                                            :items="destinations"
                                                            item-text="workstationName"
                                                            item-value="workstationCode"
                                                            :rules="lowerCaseBillingType === 'service' ? destinationRules : []"
                                                            label="Kiwi Rail Destination"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-select
                                                            v-model="billingData.KR_UnitIso"
                                                            :items="['20`', '40`']"
                                                            :rules="lowerCaseBillingType === 'service' ? isoRules : []"
                                                            label="Container Length"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-select
                                                            v-model="billingData.KR_Cartage"
                                                            :items="['One Way', 'Return']"
                                                            :rules="lowerCaseBillingType === 'service' ? cartageRules : []"
                                                            label="Kiwi Rail Cartage"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="secondary"
                                            text
                                            @click="resetForm"
                                        >
                                            Reset
                                        </v-btn>
                                        <v-btn
                                            color="secondary"
                                            text
                                            @click="save"
                                        >
                                            {{ isUpdate? 'Update': 'Save' }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapState} from "vuex";
import {DxColumn, DxDataGrid, DxHeaderFilter, DxPager, DxPaging, DxScrolling} from "devextreme-vue/data-grid";
import ValidationSummary from "@/components/global/ValidationSummary.vue";
import CMSApi from "@/services/CMSApi";

export default defineComponent({
    name: "TransportBillings-Items",
    components: {
      DxScrolling,
        DxHeaderFilter,
        DxPaging,
        DxColumn,
        DxDataGrid,
        DxPager,
        ValidationSummary,
    },

    data() {
        return {
            // Grid
            billingItemsDataGridRefName: 'billingItemsGrid',
            pageSizes: [10,15,25],

            // Form
            isFormActive: false,
            isUpdate: false,
            validationErrors: {},
            itemTypeRules: [
                v => !!v || 'Item Type is required',
            ],
            descriptionRules: [
                v => !!v || 'Description is required',
            ],
            tucPercentageRules: [
                v => !!v || 'TUC Percentage is required',
            ],
            fafPercentageRules: [
                v => !!v || 'FAF Percentage is required',
            ],
            roadBridgeCodeRules: [
                v => !!v || 'Road Bridge Code is required',
            ],
            originRules: [
                v => !!v || 'Origin is required',
            ],
            destinationRules: [
                v => !!v || 'Destination is required',
            ],
            isoRules: [
                v => !!v || 'Container Length is required',
            ],
            cartageRules: [
                v => !!v || 'Cartage is required',
            ],
        }
    },

    computed: {
        ...mapState('transportBilling', [
            'billingItemTypes',
            'items',
            'workingBillingItem',
            'loadingItems',
        ]),

        ...mapState('transportMappings', [
            'roadBridges'
        ]),

        ...mapState('trainService', [
            'containerTerminalSitesAndSidings'
        ]),

        billingData () {
            return this.workingBillingItem.billingItemData;
        },

        billingItems() {
            return this.items;
        },

        origins() {
          return this.billingData.KR_Destination !== null && this.billingData.KR_Destination !== "" ?
            this.containerTerminalSitesAndSidings : this.containerTerminalSitesAndSidings.filter(x => x.transportServiceTerminalSiteId !== this.billingData.KR_Destination && x.isActive);
        },

        destinations() {
          return this.billingData.KR_Origin !== null  && this.billingData.KR_Origin !== "" ?
            this.containerTerminalSitesAndSidings : this.containerTerminalSitesAndSidings.filter(x => x.transportServiceTerminalSiteId !== this.billingData.KR_Origin && x.isActive);
        },

        gridColumnSize() {
            return this.isFormActive ? 6 : 12;
        },

        formColumnSize() {
            return this.isFormActive ? 6 : 0;
        },

        submittableBillingItem() {
            return this.workingBillingItem.convertToSubmittable();
        },

        computedBillingItemTypes () {
            return this.billingItemTypes.filter(x => x !== 'Service' && x !== 'Faf_KR' && x !== 'Tuc_KR');
        },

        computedRoadBridges() {
          let newRoadBridges = [];

          for (let i = 0; i < [...new Set(this.roadBridges.map(x => x.roadBridgeCode))].length; i++) {
            newRoadBridges.push([...new Set(this.roadBridges.map(x => x.roadBridgeCode))][i] + '-' +40);
            newRoadBridges.push([...new Set(this.roadBridges.map(x => x.roadBridgeCode))][i] + '-' +20);
          }

          return newRoadBridges;
        },

        lowerCaseBillingType() {
          return this.billingData.billingItemType.toLowerCase();
        }
    },

    mounted() {
        this.fetchBillingItems();
        this.fetchBillingItemTypes();
        this.fetchRoadBridges();
        this.fetchContainerTerminalSitesAndSidings();
    },

    methods: {
        ...mapActions('transportBilling', [
            'fetchBillingItems',
            'fetchBillingItemTypes',
            'resetWorkingBillingItem'
        ]),

        ...mapActions('transportMappings', [
            'fetchRoadBridges'
        ]),

        ...mapActions('trainService', [
            'fetchContainerTerminalSitesAndSidings',
        ]),

        activateForm() {
            this.isFormActive = true;
        },

        deactivateForm() {
            this.isFormActive = false;
        },

        resetForm() {
          this.resetWorkingBillingItem();
          this.$refs.itemForm.resetValidation();
        },

        save() {
            if (this.$refs.itemForm.validate())
            {
                let postData = this.submittableBillingItem;

                if (postData.itemCode === null) {
                    CMSApi.postBillingItem(postData)
                        .then(() => {
                            window.App.$emit('show-snackbar', "success", "New billing item added");
                            this.resetForm();
                            this.fetchBillingItems();
                        })
                        .catch(error => {
                            let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                            this.$root["$alert"].show('Unable to cancel appointment', errorMessage);
                        })
                        .finally(() => {
                          this.deactivateForm();
                        });
                } else {
                    CMSApi.putBillingItem(postData)
                        .then(() => {
                            window.App.$emit('show-snackbar', "success", "Billing item updated");
                            this.resetForm();
                            this.fetchBillingItems();
                        })
                        .catch(error => {
                          let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                          this.$root["$alert"].show('Unable to cancel appointment', errorMessage);
                        })
                        .finally(() => {
                          this.deactivateForm();
                        });
                }
            }
        },

        async deleteBillingItem(data) {
          if (await this.$root["$confirm"].open('Delete', 'Proceed to remove this billing item?' , { color: 'warning' })) {
            CMSApi.deleteBillingItem(data.itemCode)
                .then(() => {
                  window.App.$emit('show-snackbar', "success", "Billing item removed!");
                  this.fetchBillingItems();
                })
                .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to cancel appointment', errorMessage);
                })
          }
        },
    }
})
</script>

<style scoped>

</style>