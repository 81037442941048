<template>
    <div>
        <div v-show="loadingUserAccount" class="text-center">
            <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            ></v-progress-circular>
        </div>
        <div v-show="!loadingUserAccount">
            <div v-show="userStatus == UserStatus.Pending">
                <p>Hi {{ $auth.user.given_name }},</p>
                <p>You have successfully requested access to Propel, however it is yet to be approved by your company administrator.</p>
                <p>You will be emailed on {{ $auth.user.email }} once your request has been approved.</p>
            </div>
            <div v-show="userStatus == UserStatus.Approved">
                <p>Hi {{ $auth.user.given_name }},</p>
                <p>You do not currently have permission to access this application.</p>
                <p>Please either <a href="#" @click.prevent="logout">log in</a> as a user who has access, or <router-link to="/help">contact us</router-link> or your company administrator to arrange access.</p>
            </div>
            <div v-show="userStatus == UserStatus.None">
                <ValidationSummary :validationErrors="validationErrors" class="darktheme-validationerrors" />
                <div v-show="requestAccessTab == Tabs.SelectCompany">
                    <p>Hi {{ $auth.user.given_name }}, welcome to Propel!</p>
                    <p>To continue, please register your details.</p>

                    <v-form ref="requestAccessCompanySelectForm" autocomplete="off" lazy-validation> 
                        <v-autocomplete
                            class="darktheme-validation"
                            v-model="selectedCompany"
                            :items="companies"
                            :loading="loadingCompanies"
                            :rules="[v => !!v || 'Company is required']"
                            :error-messages="validationErrors['selectedCompany']"
                            item-text="name"
                            item-value="companyId"
                            placeholder="Select which company you work for"
                            no-data-text="No such company"
                            return-object
                            solo dense auto-select-first
                            autocomplete="company-select" type="search"
                            spellcheck="false"                                            
                        ></v-autocomplete>
                        <p>If your company does not exist in the above list select '&#60;New Company&#62;'</p>
                        <v-btn class="loginButtons wizardbutton right" tile color="propelDarkGreen" justify="start" @click="next" dark>
                            Next
                            <v-icon right dark>
                                mdi-arrow-right
                            </v-icon>
                        </v-btn>
                    </v-form>
                </div>
                <div v-if="requestAccessTab == Tabs.SelectUserRoles">
                    <p>You belong to company {{selectedCompany.name}}</p>
                    <p>{{selectedCompany.address}}</p>
                    <p>Select which roles you will need:</p>
                    <v-form ref="requestAccessUserRolesSelectForm" lazy-validation>

                        <p v-show="loadingCompanyUserRoles">Loading roles...</p>                     

                        <v-checkbox
                            v-for="(item, index) in this.companyUserRoles"
                            :key="item.roleId"
                            :label="item.name" :value="item.roleId"
                            v-model="selectedUserRoles" multiple
                            :rules="[v => v.length > 0 || 'At least one role is required']"
                            :hide-details="index < companyUserRoles.length-1 ? true : false"
                            dark dense class="compact darktheme-validation darktheme-checkbox">
                        </v-checkbox>

                        <div v-if="requiresMobileNumber">
                            You have a selected a role that requires you provide a mobile number:
                            <v-text-field 
                                v-model="userMobileNumber"
                                dense solo class="mobilenumber compact darktheme-validation"
                                placeholder="Enter a mobile number"
                                :rules="mobilePhoneRules" maxlength="15"
                                :errorMessages="validationErrors['MobileNumber']">
                            </v-text-field>
                        </div>
                        <br v-else />

                        <v-btn class="loginButtons wizardbutton left" tile color="propelDarkGreen" justify="start" @click="back" dark v-show="!savingIndicator">                            
                            <v-icon left dark>
                                mdi-arrow-left
                            </v-icon>
                            Back
                        </v-btn>

                        <v-btn class="loginButtons wizardbutton right" tile color="propelDarkGreen" justify="start" @click="next" :loading="savingIndicator" dark>
                            Submit Request
                        </v-btn>
                    </v-form>
                </div>
                <div v-if="requestAccessTab == Tabs.NewUserRequestSubmitted">
                    <p>Thanks!</p>
                    <p>Your request to register as a user has been submitted.</p>
                    <p>We will email you on {{ $auth.user.email }} when your request has been approved.</p>
                </div>
                <div v-show="requestAccessTab == Tabs.RegisterNewCompanyStepOne">
                    <p>Register New Company (1/2)</p>
                    <p>Company Details:</p>

                    <v-form ref="requestAccessNewCompanyForm1" autocomplete="off" lazy-validation> 
                        <v-text-field 
                            v-model="newCompany.name" label="Company Name"
                            placeholder="Company Name"
                            dense solo class="compact darktheme-validation"
                            :rules="[v => !!v || 'Company Name is required']" maxlength="100"
                            :errorMessages="validationErrors['CompanyName']"
                            autocomplete="company-name"></v-text-field>

                        <v-text-field 
                            v-model="newCompany.address" label="Company Address"
                            placeholder="Company Address"
                            dense solo class="compact darktheme-validation"
                            :rules="[v => !!v || 'Company Address is required']" maxlength="250"
                            :errorMessages="validationErrors['CompanyAddress']"
                            autocomplete="company-address"></v-text-field>

                        <v-text-field 
                            v-model="newCompany.phoneNumber" label="Company Phone"
                            placeholder="Company Phone"
                            dense solo class="compact darktheme-validation"
                            :rules="companyPhoneRules" maxlength="20"
                            :errorMessages="validationErrors['CompanyPhone']"
                            autocomplete="company-phone"></v-text-field>
                        
                        <v-btn class="loginButtons wizardbutton left" tile color="propelDarkGreen" justify="start" @click="back" dark>                            
                            <v-icon left dark>
                                mdi-arrow-left
                            </v-icon>
                            Back
                        </v-btn>

                        <v-btn class="loginButtons wizardbutton right" tile color="propelDarkGreen" justify="start" @click="next" dark>
                            Next
                            <v-icon right dark>
                                mdi-arrow-right
                            </v-icon>
                        </v-btn>
                    </v-form>
                </div>
                <div v-show="requestAccessTab == Tabs.RegisterNewCompanyStepTwo">
                    <p>Register New Company (2/2)</p>
                    <p>Company Access Requirements:</p>

                    <v-form ref="requestAccessNewCompanyForm2" autocomplete="off" lazy-validation> 
                        <p v-show="loadingCompanyApplications">Loading...</p>                     

                        <v-checkbox
                            v-for="(item, index) in this.companyApplications"
                            :key="item.applicationId"
                            :label="item.name" :value="item.applicationId"
                            v-model="newCompany.applications" multiple
                            :rules="[v => v.length > 0 || 'At least one application is required']"
                            :hide-details="index < companyApplications.length-1 ? true : false"
                            dark dense class="compact darktheme-validation darktheme-checkbox">
                        </v-checkbox>
                        <br/>

                        <v-btn class="loginButtons wizardbutton left" tile color="propelDarkGreen" justify="start" @click="back" dark v-show="!savingIndicator">                            
                            <v-icon left dark>
                                mdi-arrow-left
                            </v-icon>
                            Back
                        </v-btn>

                        <v-btn class="loginButtons wizardbutton right" tile color="propelDarkGreen" justify="start" @click="requestCompanyAccess" :loading="savingIndicator" dark>
                            Submit Request
                        </v-btn>
                    </v-form>
                </div>
                <div v-if="requestAccessTab == Tabs.NewCompanyRequestSubmitted">
                    <p>Thanks!</p>
                    <p>Your request to register {{ newCompany.name }} has been submitted.</p>
                    <p>We will email you on {{ $auth.user.email }} when your request has been approved.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {UserStatus} from '@/utils/constants.js';
import ValidationSummary from '@/components/global/ValidationSummary.vue'
import { mapState, mapActions } from 'vuex';

const Tabs = {
	SelectCompany: "TabSelectCompany",
	SelectUserRoles: "TabSelectUserRoles",
    NewUserRequestSubmitted: "TabNewUserRequestSubmitted",
	RegisterNewCompanyStepOne: "TabRegisterNewCompanyStepOne",
	RegisterNewCompanyStepTwo: "TabRegisterNewCompanyStepTwo",    
    NewCompanyRequestSubmitted: "TabNewCompanyRequestSubmitted",
}

export default {
    components: {
        ValidationSummary,
    },

    data () {
        return {
            companies: [],
            companyUserRoles: [],
            companyApplications: [],

            loadingUserAccount: true,
            loadingCompanies: false,
            loadingCompanyUserRoles: false,
            loadingCompanyApplications: false,

            selectedCompany: null,
            selectedUserRoles: [],
            userMobileNumber: '',
            newCompany: {
                name: '',
                address: '',
                phoneNumber: '',
                applications: []
            },

            requestAccessTab: Tabs.SelectCompany,
            savingIndicator: false,
            validationErrors: {},   
            companyPhoneRules: [
                v => !!v || 'Company Phone is required',
                v => v.length >= 9 && v.length <= 20 || 'Company Phone must be between 9 and 20 characters',
            ],
            mobilePhoneRules: [
                v => !!v || 'Mobile Number is required',
                v => /^[0][2]\d+$/.test(v) || 'Mobile Number must start with 02 and only contain numbers',
                v => v.length >= 9 && v.length <= 15 || 'Mobile Number must be between 9 and 15 characters',
            ],               
        }
    },

    created () {
        this.Tabs = Tabs;
        this.UserStatus = UserStatus;
        this.loadCompanies();
        this.loadUserAccount()
    },

    computed: {
        ...mapState('account', [
            'userAccount'
        ]),

        userStatus () {
            if (!this.userAccount)
                return UserStatus.None;
            else {
                return this.userAccount.status;
            } 
        },

        requiresMobileNumber () {
            if (this.companyUserRoles.length > 0 && this.selectedUserRoles.length > 0)
                return this.companyUserRoles.some(ur => this.selectedUserRoles.some(s => s === ur.roleId) && ur.requiresSMSIdentity);

            return false;
        },
    },
    
    methods: {
        ...mapActions('account', [
            'fetchUserAccount'
        ]),

        back() {
            this.clearValidationErrors();

            switch(this.requestAccessTab) {
                case Tabs.SelectUserRoles:
                    this.requestAccessTab = Tabs.SelectCompany;
                    this.companyUserRoles = []; //clear roles
                    break;

                case Tabs.RegisterNewCompanyStepOne:
                    this.requestAccessTab = Tabs.SelectCompany;
                    break;

                case Tabs.RegisterNewCompanyStepTwo:
                    this.requestAccessTab = Tabs.RegisterNewCompanyStepOne;
                    break;
            }
        },

        next() {
            this.clearValidationErrors();

            switch(this.requestAccessTab) {
                case Tabs.SelectCompany:
                    if (this.$refs.requestAccessCompanySelectForm.validate()) {
                        if (this.selectedCompany.companyId == -1) {
                            this.requestAccessTab = Tabs.RegisterNewCompanyStepOne;
                        } else {
                            this.loadCompanyUserRoles();
                            this.requestAccessTab = Tabs.SelectUserRoles;
                        }
                    }
                    break;

                case Tabs.SelectUserRoles:
                    if (this.$refs.requestAccessUserRolesSelectForm.validate()) {    
                        //clear mobile number if not required
                        if(!this.requiresMobileNumber)
                            this.userMobileNumber = '';

                        this.savingIndicator = true;

                        CMSApi.requestUserAccess(this.selectedCompany.companyId, this.selectedUserRoles, this.userMobileNumber)
                            .then(() => {
                                this.requestAccessTab = Tabs.NewUserRequestSubmitted;
                            })
                            .catch(error => {
                                this.validationErrors = error.response.data.errors;
                            })
                            .finally(() => {
                                this.savingIndicator = false;
                            }); 
                        
                    }
                    break;

                case Tabs.RegisterNewCompanyStepOne:
                    if (this.$refs.requestAccessNewCompanyForm1.validate()) {
                        this.loadCompanyApplications();
                        this.requestAccessTab = Tabs.RegisterNewCompanyStepTwo;
                    }
                    break;
            }
        },

        loadUserAccount() {
            this.loadingUserAccount = true;

            this.fetchUserAccount()
                .finally(() => {
                    this.loadingUserAccount = false;
                });
        },

        loadCompanies() {
            this.loadingCompanies = true;

            CMSApi.fetchCompaniesLite()
                .then(data => {
                    data.push({companyId:-1, name:'<New Company>'});
                    this.companies = data;                    
                })
                .finally(() => {
                    this.loadingCompanies = false;
                });
        },

        loadCompanyUserRoles() {
            this.loadingCompanyUserRoles = true;

            CMSApi.fetchUserRolesForCompany(this.selectedCompany.companyId)
                .then(data => {
                    this.companyUserRoles = data;                    
                })
                .finally(() => {
                    this.loadingCompanyUserRoles = false;
                });
        },

        loadCompanyApplications() {
            this.loadingCompanyApplications = true;

            CMSApi.fetchApplications()
                .then(data => {
                    this.companyApplications = data;                    
                })
                .finally(() => {
                    this.loadingCompanyApplications = false;
                });
        },

        clearValidationErrors() {
            this.validationErrors = {};
        },

        requestCompanyAccess() {
            if (this.$refs.requestAccessNewCompanyForm2.validate()) {    
                this.savingIndicator = true;

                CMSApi.requestCompanyAccess(this.newCompany)
                    .then(() => {
                        this.requestAccessTab = Tabs.NewCompanyRequestSubmitted;
                    })
                    .catch(error => {
                        this.validationErrors = error.response.data.errors;
                    })
                    .finally(() => {
                        this.savingIndicator = false;
                    }); 
                
            }
        }
    }
};

</script>

<style scoped>
    .loginButtons.wizardbutton
    {
        margin: 0;
        margin-top: 10px;
    }
    .loginButtons.right
    {
        float: right;
    }

    .mobilenumber{
        max-width: 500px;
    }
    .v-input.compact {
        padding-top: 0px;
        margin-top: 0;
    }
</style>