import store from '@/store/'

// initial state
const state = {
    notifications: [],
    dismissedNotificationsIds: [],
}

// getters
const getters = {
    //only new (non-dismissed notifications)
    getNewNotifications: (state, getters) => {
        let newNotifications;

        let dismissedNotifications = state.dismissedNotificationsIds;
        if (dismissedNotifications.length === 0)
            newNotifications = getters.getNotifications;
        else
            newNotifications = getters.getNotifications.filter(notification => !dismissedNotifications.includes(notification.notificationId));

        return newNotifications;
    },

    //only notifications applicable to the user
    getNotifications: state => {
        let account = store.state.account.userAccount;

        if (account == null || account.applications == null || account.applications.length === 0)
            return state.notifications;
        else
            return state.notifications.filter(notification => 
                notification.applications.some(nApp => 
                    account.applications.some(app => app.applicationId == nApp.applicationId)
            ));
    }
}

// actions
const actions = {
    dismissNotification: ({ commit, state }, notificationId) => {
        //we want to store dismissedNotifications in local storage, so we don't keep on showing the same ones everytime the user opens the same browser

        let dismissedNotificationsIds = state.dismissedNotificationsIds;
        if(!dismissedNotificationsIds.includes(notificationId))
        {
            dismissedNotificationsIds.push(notificationId);
            commit('setDismissedNotificationIds', dismissedNotificationsIds);                
        }
    },

    fetchNotifications: ({ commit, state }, ignoreError = false) => {
        return new Promise(function(resolve, reject) {
            CMSApi.fetchWebsiteNotifications(ignoreError)
                .then(data => {
                    commit('setNotifications', data);
                    
                    //when getting new notifications, clean out dismissedNotificationsIds so that it only includes applicable ids
                    let itemsRemoved = false;
                    let dismissedNotificationsIds = state.dismissedNotificationsIds;                       
                    dismissedNotificationsIds.forEach(item => {
                        if (!data.some(e => e.notificationId === item)) {
                            let index = dismissedNotificationsIds.indexOf(item);
                            if (index !== -1) {
                                dismissedNotificationsIds.splice(index, 1);
                                itemsRemoved = true;
                            }
                        }
                    });

                    if(itemsRemoved)
                        commit('setDismissedNotificationIds', dismissedNotificationsIds);  

                    resolve(data);
                })
                .catch(error => {
                    console.log("unable to fetch notifications");
                    reject(error);
                })
          });
    },
}

// mutations
const mutations = {
    initialiseStore(state) {
        // pre-load already dismissed notifications from localstorage, if any
        const notificationsStringFromLocalStorage = localStorage.getItem("dismissedNotifications");
        
        if (notificationsStringFromLocalStorage) {
            const dismissedNotifications = JSON.parse(notificationsStringFromLocalStorage);
            
            if (dismissedNotifications && Array.isArray(dismissedNotifications))
                state.dismissedNotificationsIds = dismissedNotifications;
            else
                state.dismissedNotificationsIds = [];
        } else {
            state.dismissedNotificationsIds = [];
        }
    },
    
    setNotifications(state, listOfNotifications) {
        state.notifications = listOfNotifications;
    },

    setDismissedNotificationIds(state, dismissedNotificationIds) {
        state.dismissedNotificationsIds = dismissedNotificationIds;
        localStorage.setItem("dismissedNotifications", JSON.stringify(dismissedNotificationIds));
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}