<template>    
    <v-container class="datetimePicker">
        <v-row dense class="no-vertical-padding">
            <v-col cols="6">
                <v-menu
                    ref="menu"
                    v-model="dateMenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="computedStartDateFormatted"
                            :label="datePickerLabel"
                            readonly
                            prepend-icon="mdi-calendar"
                            :error-messages="errorMessages"
                            class="datePicker"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="selectedDate" @input="dateMenu = false"></v-date-picker>
                </v-menu>
            </v-col> 
            <v-col cols="6">
                <v-menu
                    ref="timeMenu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="selectedTime"
                            prepend-inner-icon="mdi-clock-outline"
                            readonly
                            v-on="on"                            
                        ></v-text-field>
                    </template>
                    <v-time-picker v-if="timeMenu" v-model="selectedTime" @click:minute="$refs.timeMenu.save(selectedTime)"></v-time-picker>
                </v-menu>
            </v-col>
        </v-row>
    </v-container>                            
</template>

<script>

export default {
    props: {
        selectedDateTime: String,
        datePickerLabel: String,
        errorMessages : Array
    },

    data () {
        return {
            dateMenu: false,
            timeMenu: false,
            selectedDate: '',
            selectedTime: '',
      }
    },

    mounted () {
        this.setInternalSelectedDateandTime();
    },

    computed: {
        computedStartDateFormatted () {
            return window.App.formatDate(this.selectedDate)
        },
    },

    watch: {
        selectedDateTime: function() {
            this.setInternalSelectedDateandTime();
        },

        selectedDate: function() {
            this.notifyNewDate ();
        },

        selectedTime: function() {
            this.notifyNewDate ();
        }
    },
    
    methods: {   
        notifyNewDate () {
            let newDate = window.Moment(this.selectedDate + ' ' + this.selectedTime).format();
            this.$emit('update:selectedDateTime', newDate);
        },

        setInternalSelectedDateandTime()
        {
            let date = window.Moment(this.selectedDateTime);
            this.selectedDate = date.format('YYYY-MM-DD');
            this.selectedTime = date.format('HH:mm');
        }
    }
};

</script>

<style scoped>
    .datetimePicker {
        padding: 0px;
        margin-top: 4px;
    }
    .datePicker >>> label
    {
        overflow: visible !important;
    }
</style>