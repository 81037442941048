<template>
    <v-snackbar
        v-model="visible"
        :timeout="timeout"
        :color="color"
        id="snackbarAlert"
        top>
            <v-icon id="snackbar-indicatorIcon" large dark>mdi-check-circle-outline</v-icon>
            <span>{{ text }}</span>
            <template v-slot:action>
                <v-btn id="snackbar-closeButton" text @click="visible = false"><v-icon small dark>mdi-window-close</v-icon></v-btn>
            </template>  
    </v-snackbar>
</template>

<script>
export default {
    name: 'snackbaralert',
    data: () => ({
        visible: false,
        timeout: 4000,
        color: 'success',
        text: '',
    }),

    mounted() {
        //perhaps instead of using event bus, we could expose this directly somehow e.g. Windows.AppAlerts.showAlert(color, message);?
        window.App.$on('show-snackbar', (color, message) => {
            this.showAlert(color, message);
        })
    },

    beforeDestroy () {
        window.App.$off('show-snackbar');
    },

    methods: {
        showAlert(color, message) {
            this.color = color;
            this.text = message;
            this.visible = true;
        },
    }
};
</script>

<style>
    .v-snack__wrapper {
        border-radius: 0px !important;  /* want square snack */
    }

    #snackbarAlert .v-snack__content {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    #snackbar-indicatorIcon {
        margin-right: 12px;
    }

    #snackbar-closeButton{
        margin-left: 15px;
    }
</style>

