<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <ValidationSummary :validationErrors="validationErrors" />
      <v-form
          ref="gateDetailsForm"
          lazy-validation
      >
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="editedItem.gateId"
                  :rules="gateIdRules"
                  oninput="window.App['inputTextTransformUppercaseAlphaNumericOnly'](this)"
                  label="ID"
                  :disabled="disableId"
                  dense
                  autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12"
                   sm="6"
            >
              <v-text-field
                  v-model="editedItem.n4GateId"
                  :rules="n4GateIdRules"
                  oninput="window.App['inputTextTransformUppercaseAlphaNumericOnly'](this)"
                  label="N4 Gate ID"
                  id="n4Id"
                  dense
                  autocomplete="off"
                  :disabled="sameId"
              ></v-text-field>
            </v-col>
            <v-col cols="12"
                   sm="6"
            >
              <v-checkbox
                  v-model="sameId"
                  label="Same as ID above?"
                  class="shrink mr-2 mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="editedItem.gateName"
                  :rules="gateNameRules"
                  label="Gate Name"
                  dense
                  autocomplete="off"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                  v-model="editedItem.setGateConfig"
                  :items="gateConfigs"
                  :item-text="'name'"
                  :item-value="'id'"
                  :rules="gateConfigRules"
                  label="Freight Kind"
                  dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-radio-group
                  v-model="editedItem.isFromPropel"
                  row
                  label="Slots managed by:"
              >
                <v-radio
                    label="Propel"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="N4"
                    :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div>
                <label for="content" class="v-label theme--light" v-bind:class="{ 'error--text': validationErrors['Roles']}">Appointments allowed</label>
                <v-checkbox
                    v-for="item in appointmentTypeOptions"
                    :key="item.code"
                    :label="item.description" :value="item.code"
                    v-model="editedItem.appointmentTypes" multiple
                    :rules="[v => v.length > 0 || 'At least one appointment type is required']"
                    class="appointmentTypesCheckbox">
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12">
              <v-checkbox
                  label="Active?"
                  v-model="editedItem.isActive">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
          color="primary"
          text
          @click="$emit('close')"
      >
        Cancel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          text
          @click="saveGateForm()"
      >
        {{ formMode === 'add' ? 'Save' : 'Update' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ValidationSummary from '@/components/global/ValidationSummary.vue'
import {mapActions, mapState} from "vuex";
import {AppointmentType} from '@/utils/constants.js'

export default {
  name: "GateForm",

  components: {
    ValidationSummary
  },

  data() {
    return {
      appointmentTypeOptions: Object.values(AppointmentType),
      //Form Data & validation
      validationErrors: {},
      gateIdRules: [
        v => !!v || 'Gate Id is required',
      ],
      n4GateIdRules: [
        v => !!v || 'N4 Gate Id is required',
      ],
      gateNameRules: [
        v => !!v || 'Gate Name is required',
      ],
      gateConfigRules: [
        v => !!v || 'Freight Kind is required',
      ],

      // Fields
      sameId: false,
      editedItem: null,
      defaultItem: {
        gateId: null,
        n4GateId: null,
        gateName: null,
        setGateConfig: null,
        isActive: true,
        isFromPropel: null,
        isForPickup: null,
        isForDropOff: null,
        appointmentTypes: [],
      },
      gateConfigs : [
        {
          id: 1,
          name: "Full Containers"
        },
        {
          id: 2,
          name: "Empty Containers"
        },
        {
          id: 3,
          name: "Full and Empty Containers"
        }
      ]
    }
  },

  created() {
    //required to avoid runtime errors when first creating component
    this.editedItem = Object.assign({}, this.defaultItem);
  },

  watch: {
    'editedItem.appointmentTypes'(newVal) {
      if (newVal !== undefined) {
        this.editedItem.isForPickup = newVal.includes("PickUp");
        this.editedItem.isForDropOff = newVal.includes("DropOff");
      }
    },
    sameId(newVal) {
      if (newVal) {
        this.editedItem.n4GateId = this.editedItem.gateId;
      } else {
        document.getElementById('n4Id').focus();
      }
    }
  },

  computed: {
    ...mapState('gateManagement', [
        'formMode',
    ]),

    formTitle() {
      return this.formMode === 'add' ? 'New Gate' : 'Edit Gate';
    },

    disableId() {
      return this.formMode !== 'add';
    }
  },

  methods: {
    ...mapActions('gateManagement', [
        'saveGate'
    ]),

    async edit(item) { //Point of entry
      item.appointmentTypes = await this.parseAppointmentTypes(item);

      this.editedItem = Object.assign({}, item);
    },

    // Misc functions
    parseAppointmentTypes(item) {
      let temp_arr = [];
      item.isForPickup ? temp_arr.push('PickUp') : '';
      item.isForDropOff ? temp_arr.push('DropOff') : '';

      return temp_arr;
    },

    // Dialog buttons
    saveGateForm: async function () {
      if (this.$refs.gateDetailsForm.validate()) {
        let postData = this.editedItem;
        await this.saveGate(postData);
        this.$emit('closeAndReload');
      }
    }
  },
}
</script>

<style scoped>
.appointmentTypesCheckbox
{
  margin-top:0px
}
</style>