<template>
    <DxDataGrid
        id="exportPreAdvisedContainerGrid"
        :ref="dgExportPreAdvisedRefName"
        :data-source="exportPreAdvisedContainers"
        keyExpr="containerNumber"
        noDataText="No current export pre-advised containers"
        :show-borders="false"
        :hoverStateEnabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :onCellPrepared="highlightPending"
    >
        <DxHeaderFilter :visible="true"/>
        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="false"
            :allow-deleting="false"
        />

        <DxPaging :page-size="25"></DxPaging>
        <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
        />

        <DxColumn data-field="containerNumber" cell-template="statusTemplate" data-type="string" :allowSearch="true" caption="Container ID"
                  :hiding-priority="8"/>
        <DxColumn data-field="bookingNumber" cell-template="statusTemplate" data-type="string" :allowSearch="true" caption="Booking ID"
                  :hiding-priority="7"/>
        <DxColumn data-field="lineOperator" cell-template="statusTemplate" data-type="string" caption="Line Operator" :hiding-priority="2"/>
        <DxColumn data-field="vesselVisit" cell-template="statusTemplate" data-type="string" caption="Voyage" :hiding-priority="3"/>
        <DxColumn data-field="etd" cell-template="statusTemplate" data-type="date" format="EEE dd-MM HH:mm" caption="Vessel ETD" :hiding-priority="4" sort-order="asc"/>
        <DxColumn data-field="isoType" cell-template="statusTemplate" data-type="string" caption="Type" :hiding-priority="0"/>
        <DxColumn data-field="vgmWeight" cell-template="statusTemplate" data-type="number" caption="VGM Weight" :hiding-priority="1"
                  :allowFiltering="false"/>
        <DxColumn data-field="transitStateAndZone" cell-template="statusTemplate" data-type="string" caption="Status" :hiding-priority="5"/>
      <template #statusTemplate="{data}">
        <div v-if="!isAdviceEditable(data.data)" style="color:#C6C6C6">
          {{data.value}}
        </div>
        <div v-else>
          {{data.value}}
        </div>
      </template>
        <DxColumn :allowFiltering="false" width="70px" cell-template="editDeleteButtonTemplate" caption="Edit" :hiding-priority="6" ?/>
        <template #editDeleteButtonTemplate="{data}">
            <v-icon color="primary" v-show="isAdviceEditable(data.data)" small class="mr-2"
                    @click="editPreAdvice(data, $event.target)">
                mdi-pencil
            </v-icon>
        </template>
    </DxDataGrid>
</template>

<script>
import {mapState} from 'vuex';

import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSorting,
    DxEditing,
} from 'devextreme-vue/data-grid';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxPager,
        DxPaging,
        DxSorting,
        DxEditing
    },

    props: {
        loading: {type: Boolean, default: () => false},
        searchText: String,
    },

    data() {
        return {
            dgExportPreAdvisedRefName: 'exportPreAdvisedContainerGrid',
            pageSizes: [25, 50, 100],
        }
    },

    computed: {
        ...mapState('exportPreAdvice', [
            'exportPreAdvisedContainers'
        ]),
    },

    watch: {
        searchText: function (newVal) {
            // Trim any (accidental) white space from the search value.
            const searchVal = newVal ? newVal.trim() : newVal;
            this.$refs[this.dgExportPreAdvisedRefName].instance.searchByText(searchVal);
        },

        loading: function (newVal) {
            if (newVal)
                this.$refs[this.dgExportPreAdvisedRefName].instance.beginCustomLoading();
            else
                this.$refs[this.dgExportPreAdvisedRefName].instance.endCustomLoading();
        },
    },

    methods: {
        isAdviceEditable(container) {
            return container.transitState.toLowerCase() === 'advised' ||
                container.transitState.toLowerCase() === 'inbound';
        },

        editPreAdvice(cell) {
            this.$emit("edit", cell.data);
        },

        exportXlsFile() {
            this.$refs[this.dgExportPreAdvisedRefName].instance._options.export.fileName = "Propel Export Pre-Advised Containers"
            this.$refs[this.dgExportPreAdvisedRefName].instance.exportToExcel(false);
        },
        
        highlightPending(e) {
            if (e.rowType === 'data' && e.column.dataField === 'transitStateAndZone') {
                if (e.data.transitState === 'Pending') {
                    e.cellElement.style.fontStyle = "italic";
                }
            }
        }
    }
}
</script>