<template>
    <div>
        <UserList allowSelectionOfCompany />
    </div>
</template>

<script>
import UserList from '@/components/userManagement/UserList.vue'

export default {   
    name: 'portadmin-userManagement',
    components: {
        UserList,
    },
};
</script>