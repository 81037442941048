<template>
  <v-snackbar
      v-model="visible"
      :timeout="timeout"
      :color="color"
      id="snackbarAlert"
      top>
    <v-icon id="snackbar-indicatorIcon" large dark>mdi-check-circle-outline</v-icon>
    <span>{{ text }}</span>
    <template v-slot:action>
      <v-btn id="snackbar-closeButton" text @click.native="cancel">Undo {{countdownText}}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'snackbaralertwithundo',

  data: () => ({
    visible: false,
    timeout: 5000,
    color: 'success',
    text: '',
    resolve: null,
    reject: null,
    countdown: 0,
  }),

  computed: {
    countdownText() {
      return "("+ this.countdown + "s)";
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.countdown = 5;
        let instance = this;
        let timer = setInterval( function () {
          instance.countdown--;
          if (instance.countdown <= 0) {
            clearInterval(timer);
            instance.agree();
          }
        }, 1000)
      }
    }
  },

  methods: {
    open(color, message) {
      this.visible = true;
      this.color = color;
      this.text = message;

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    agree() {
      this.resolve(true);
      this.visible = false;
    },

    cancel() {
      this.resolve(false);
      this.visible = false;
    }
  }
}
</script>

<style>
.v-snack__wrapper {
  border-radius: 0px !important;  /* want square snack */
}

#snackbarAlert .v-snack__content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#snackbar-indicatorIcon {
  margin-right: 12px;
}

#snackbar-closeButton{
  margin-left: 15px;
}
</style>
