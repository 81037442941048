<template>
    <div>
        <NotificationAlerts :applicationFilter="[ApplicationType.ExportPreAdvice]" />

        <ExportPreAdvice />
        <br/>
    </div>
</template>

<script>
import {ApplicationType} from '@/utils/constants.js';
import NotificationAlerts from '@/components/global/NotificationAlerts.vue'
import ExportPreAdvice from "@/components/exportPreAdvice/ExportPreAdvice.vue";

export default {
    name: 'exportpreadvice',
    components: {
        ExportPreAdvice,
        NotificationAlerts,
    },

    created() {
        // make enums available within html tags
        this.ApplicationType = ApplicationType;
    },
}
</script>