<template>
  <PropelExpansionPanel :panelExpanded="panelExpanded" :showExpansionControl="!portAdminView" v-on:toggle-panel-click="$emit('toggle-panel-click')">

    <template v-slot:headerTitle>
      {{cardTitle}}
    </template>

    <template v-slot:headerToolbar>
      <v-btn
          tile color="secondary"
          :loading="loadingAppointments"
          @click="fetchAppointments" >
        <v-icon>mdi-refresh</v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
      </v-btn>
      <v-btn v-show="!bookingPanelDisplayed"
             tile color="secondary"
             @click="$emit('open-booking-panel-click')" >
        <v-icon>mdi-plus</v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">Book Appointments</span>
      </v-btn>
      <v-btn
          v-if="!showSlots && !showForm"
          tile
          color="secondary"
          @click="$emit('showSlot')"
      >
        <v-icon>mdi-arrow-collapse-left</v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">Show Slots</span>
      </v-btn>
    </template>

    <template>
      <v-container class="datatableControls">
        <v-row >
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
            <v-text-field
                clearable
                v-model="filterText"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                single-line hide-details
                autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3" v-if="!readOnly">
            <v-checkbox v-model="filterIncludeExpired" label="Show Past and Cancelled" hide-details></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="readOnly">
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
            <v-checkbox v-model="filterIncludeExpired" label="Show Past and Cancelled" hide-details></v-checkbox>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
            <v-checkbox v-model="showAdminButtons" label="Admin View" hide-details></v-checkbox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3" v-if="selectedRowKeys.length > 0 && showAdminButtons">
            <v-btn-toggle
                class="float-right"
                v-model="toggleBtn"
                borderless
            >
              <v-btn value="bill"
                 title="Bill selected appointments"
                 @click="billSelected"
                 :loading="updatingSelectedRows"
                 color="propelDarkGreen"
              >
                <v-icon>
                  mdi-currency-usd
                </v-icon>
              </v-btn>

              <v-btn value="noBill"
                 title="No charge for selected appointments"
                 @click="unBillSelected"
                 :loading="updatingSelectedRows"
                 color="propelDarkGreen"
              >
                <v-icon>
                  mdi-currency-usd-off
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>

      <DxDataGrid
          :ref="appointmentsGridRefKey"
          :data-source="computedAppointments"
          :show-borders="true"
          :selected-row-keys="selectedRowKeys"
          :hoverStateEnabled="true"
          noDataText="No appointments"
          key-expr="appointmentId"
          @selection-changed="onSelectionChanged"
          @editor-preparing="onEditorPreparing"
          @row-prepared="onRowPrepared"
          height="600"
          :column-hiding-enabled="$vuetify.breakpoint.mobile"
          :column-auto-width="$vuetify.breakpoint.mobile"
      >
        <DxSelection
            v-if="readOnly"
            mode="multiple"
            :allow-select-all="false"
        />
        <DxScrolling mode="virtual"/>
        <DxHeaderFilter
                :visible="true"
        />

        <DxColumn v-if="filterIncludeExpired"
          caption="Status"
        ></DxColumn>

        <DxColumn v-for="(column, index) in headers" :key="index"
          :caption="column.text"
          :data-field="column.useTemplate ? '': column.value"
          :data-type="column.type"
          :format="column.type === 'date'? column.format: ''"
          :allowSearch="column.allowSearch"
          :cell-template="column.useTemplate ? column.cellTemplate : ''"
          :width="$vuetify.breakpoint.mobile ? undefined : column.width"
          :allow-editing="true"
        ></DxColumn>

        <template #actionTemplate="{data}">
          <div>
            <v-icon
                color="primary"
                v-show="isAppointmentEditable(data)"
                small
                class="mr-2"
                title="Edit Appointment"
                @click="editAppointment(data, $event.target)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                color="primary"
                v-show="isAppointmentCancelable(data)"
                small
                class="mr-2"
                title="Cancel Appointment"
                @click="deleteItem(data)"
            >
              mdi-delete
            </v-icon>
            <v-icon
                color="primary"
                v-show="isAppointmentCanBeCompleted(data) && data.data.isFromPropel && readOnly"
                small
                class="mr-2"
                title="Tag Appointment as Used"
                @click="completeAppointment(data, $event.target)"
            >
                mdi-tag-check
            </v-icon>
          </div>
        </template>

        <template #alertTemplate="{data}">
          <div>
            <appointment-status-icon
              :isAppointmentCurrent="isAppointmentCurrent(data.data)"
              :id="data.data.appointmentId"
              :alertLevel="data.data.alertLevel"
              :alerts="data.data.alerts"
              :status="data.data.status"
            >
            </appointment-status-icon>
          </div>
        </template>

        <template #doorTemplate="{data}">
          <div v-if="data.data.appointmentType === AppointmentType.PickUp.code">
            {{ data.data.doorDirectionName +  ' - ' + data.data.position}}
          </div>
        </template>

        <template #weightTemplate="{data}">
          <div>
            {{ formatWeight(data.data)}}
          </div>
        </template>

        <template #importStatusTemplate="{data}">
          {{ formatImportStatus(data.data) }}
        </template>

        <template #holdsTemplate="{data}">
          {{ formatHolds(data.data.containerImpediments) }}
        </template>

      </DxDataGrid>


    </template>
  </PropelExpansionPanel>
</template>

<script>

import AppointmentStatusIcon from "@/components/appointment/Appointment-StatusIcon.vue";

let selectAllCheckBox;
let checkBoxUpdating = false;
import PropelExpansionPanel from "@/components/global/PropelExpansionPanel.vue";
import {
    DxDataGrid,
    DxColumn,
    // DxPaging,
    // DxPager,
    DxSelection,
    DxScrolling, DxHeaderFilter
} from "devextreme-vue/data-grid";
import {mapActions, mapGetters, mapState} from "vuex";
import {AppointmentFormMode, AppointmentState, AppointmentType, DoorDirection} from "@/utils/constants";
import {appointmentHelpers} from "@/store/modules/appointments";
import appointmentColumnData from "@/models/appointment/appointmentColumnData";

export default {
  name: "Appointment-AppointmentList",

  components : {
      DxHeaderFilter,
      AppointmentStatusIcon,
    PropelExpansionPanel,
    DxDataGrid,
    DxColumn,
    // DxPaging,
    // DxPager,
    DxSelection,
    DxScrolling
  },

  props: {
    readOnly: Boolean,
    portAdminView: Boolean,
    title: String,
    bookingPanelDisplayed: Boolean,
    panelExpanded: Boolean,
    showSlots: Boolean,
    showForm: Boolean,
  },

  data() {
    return {
      // Grid
      appointmentsGridRefKey: 'data-grid',
      filterIncludeExpired: false,
      filterText: '',
      showAdminButtons: false,
      pageSizes: [10, 25, 50, 100],
      selectedRowsData: [],

      toggleBtn : 'none',
      updatingSelectedRows: false,
    }
  },

  computed : {
      AppointmentState() {
          return AppointmentState
      },
      AppointmentType() {
          return AppointmentType
      },
    DoorDirection() {
      return DoorDirection
    },
    ...mapState('appointments', [
        'appointments',
        'loadingAppointments',
        'workingAppointment'
    ]),

    ...mapGetters('gateAppointments', [
      'getGateName'
    ]),

    currentAppointmentCount() {
      return this.appointments.filter(item => this.isAppointmentCurrent(item)).length;
    },

    canSelect() {
        return this.readOnly && this.showAdminButtons;
    },

    cardTitle() {
      if(this.portAdminView)
        return this.title;
      else
        return this.title + " (" + this.currentAppointmentCount + ")";
    },

    headers() {
        let headers = [];
        let appointmentColumns = new appointmentColumnData.AppointmentColumns();
        switch (true) {
            case this.portAdminView :
                  headers = this.showAdminButtons ? appointmentColumns.adminColumns : appointmentColumns.defaultColumns;
                break;
            default :
                  headers = appointmentColumns.customerColumns;
                break;
        }
        return headers;
    },

    computedAppointments() {
      if(this.filterIncludeExpired)
        return this.appointments;
      else {
        return this.appointments.filter(item => this.isAppointmentCurrent(item));
      }
    },

    selectedRowKeys() {
      return this.selectedRowsData.map((g) => g.appointmentId);
    },
  },

  created() {
    window.App.$on('new-appointment-single', (date, zone, gateId) => {
      this.createAppointment(date, zone, gateId);
    });

    window.App.$on('createDayStackAppointment', (date, dayStackType, containerNumber) => {
      this.createDayStackAppointment(date, dayStackType, containerNumber);
    });

    window.App.$on('createGroupStackAppointment', (group) => {
        this.createGroupStackAppointment(group);
    });
  },

  // beforeDestroy () {
  //     // window.App.$off('new-appointment-single');
  //     // window.App.$off('createDayStackAppointment');
  //     // window.App.$off('createGroupStackAppointment');
  // },

  async mounted() {
    await this.fetchAppointments();
  },

  watch : {
    async filterIncludeExpired(val) { // eslint-disable-line no-unused-vars
      await this.fetchAppointments();
    },

    filterText : function (newVal) { // eslint-disable-line no-unused-vars
      this.$refs[this.appointmentsGridRefKey]["instance"].searchByText(newVal?.trim());
    },

    loadingAppointments: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
      if(newVal)
          this.$refs[this.appointmentsGridRefKey].instance.beginCustomLoading();
      else
          this.$refs[this.appointmentsGridRefKey].instance.endCustomLoading();
    },

    showAdminButtons (val) {
        val ? this.$emit('hideSlot') : this.$emit('showSlot');
    },
  },

  methods : {
    ...mapActions('appointments', [
        'fetchCurrentAppointments',
        'fetchAppointmentsWithPast',
        'setWorkingAppointmentData',
        'fetchAvailableSlots'
    ]),

    //public method
    refreshData() {
      this.fetchAppointments();
    },

    fetchAppointments() {
      this.filterIncludeExpired ? this.fetchAppointmentsWithPast(this.portAdminView) : this.fetchCurrentAppointments(this.portAdminView);
    },

    // Grid functions
    formatHolds(holds) {
      return holds.join(", ");
    },

    formatImportStatus(appointment) {
      return appointmentHelpers.determineImportStatus(appointment.containerTransitState, appointment.containerEstimatedMoveTime, appointment.containerInBoundMode)
    },

    isAppointmentEditable({data}) {
      let appointment = data;
      const cutOffDate = window.Moment().add(-1, 'hours');
      if (data.isFromPropel) {
          return (window.Moment(appointment.appointmentDate).add(appointment.zone, 'hours') > cutOffDate || this.portAdminView) && (appointment.status !== AppointmentState.Canceled && appointment.status !== AppointmentState.Used);
      } else {
          return (window.Moment(appointment.appointmentDate).add(appointment.zone, 'hours') > cutOffDate && (appointment.status !== AppointmentState.Canceled && appointment.status !== AppointmentState.Used));
      }
    },

    isAppointmentCancelable({data}) {
      let appointment = data;
      const cutOffDate = window.Moment();
      if (data.isFromPropel) {
          return (window.Moment(appointment.appointmentDate).add(appointment.zone, 'hours') > cutOffDate || this.portAdminView) && (appointment.status !== AppointmentState.Canceled && appointment.status !== AppointmentState.Used && appointment.status !== AppointmentState.Expired);
      } else {
          return (window.Moment(appointment.appointmentDate).add(appointment.zone, 'hours') > cutOffDate && (appointment.status !== AppointmentState.Canceled && appointment.status !== AppointmentState.Used));
      }
    },

    isAppointmentCanBeCompleted({data}) {
        return (data.status === AppointmentState.Expired);
    },

    isAppointmentCurrent(appointment) {
      return appointmentHelpers.isAppointmentCurrent(appointment);
    },

    isAppointmentCompleted(appointment) {
        return appointment.status === AppointmentState.Used;
    },

    isContainerBooking(appointment) {
      return appointmentHelpers.isContainerBooking(appointment);
    },

    async editAppointment({data}, target) {
      this.appointmentEditIconProcessing(target, true);
      await CMSApi.fetchGateInfo(data.gate)
          .then(result => {
            this.setWorkingAppointmentData({"appointment": data, "gate": result});
          })
          .finally(() => {
            this.appointmentEditIconProcessing(target, false);
            this.$emit('showForm',true);
          });
    },

    async createAppointment(date, zone, gateId) { // eslint-disable-line no-unused-vars
      await CMSApi.fetchGateInfo(gateId)
          .then(async result => {
            await this.setWorkingAppointmentData({"appointment": [], "gate": result});
          })
          .finally(() => {
            this.workingAppointment.mode = AppointmentFormMode.General;
            this.workingAppointment.appointmentDetails.appointmentDate = date;
            this.workingAppointment.appointmentDetails.zone = zone;
            if (!this.workingAppointment.gateDetails.isForPickup || !this.workingAppointment.gateDetails.isForDropOff)
            {
              this.workingAppointment.appointmentDetails.appointmentType = this.workingAppointment.gateDetails.isForPickup ? AppointmentType.PickUp.code : AppointmentType.DropOff.code;
            }
            this.$emit('showForm',true);
          });
    },

    async createDayStackAppointment(date, dayStackType, containerNumber) {
        await CMSApi.fetchGateInfo('APPT_GATE')
            .then(async result => {
                await this.setWorkingAppointmentData({"appointment": [], "gate": result});
            })
            .finally(async() => {
                this.workingAppointment.mode = AppointmentFormMode.DayStack;
                this.workingAppointment.appointmentDetails.appointmentDate = date;
                this.workingAppointment.dayStackType = dayStackType;
                this.workingAppointment.appointmentDetails.containerNumber = containerNumber;
                this.workingAppointment.appointmentDetails.appointmentType = AppointmentType.PickUp.code;

                await this.fetchAvailableSlots(date, false,'APPT_GATE');
                this.$emit('showForm',true);
            });
    },

    async createGroupStackAppointment(group) {
        await CMSApi.fetchGateInfo('APPT_GATE')
            .then(async result => {
                await this.setWorkingAppointmentData({"appointment": [], "gate": result});
            })
            .finally(async() => {
                this.workingAppointment.mode = AppointmentFormMode.BlockStack;
                this.workingAppointment.appointmentDetails.appointmentDate = window.Moment().format('YYYY-MM-DD');
                this.workingAppointment.appointmentDetails.containerNumber = group;
                this.workingAppointment.appointmentDetails.appointmentType = AppointmentType.PickUp.code;

                await this.fetchAvailableSlots(window.Moment().format('YYYY-MM-DD'), false,'APPT_GATE');
                this.$emit('showForm',true);
            });
    },

    async deleteItem({data}) {
      if (await this.$root["$confirm"].open('Cancel appointment?', 'Are you sure you want to cancel this appointment?', { color: 'warning' }))
      {
        App["$appAnalytics"].trackEvent('VBS - Delete Appointment - Click');

        if (data.isFromPropel)
        {
          CMSApi.deletePropelAppointment(data.appointmentId)
              .then(() => {
                this.appointments[this.appointments.findIndex(g => g.appointmentId === data.appointmentId)].status = AppointmentState.Canceled;
                window.App.$emit('show-snackbar', "success", "Appointment cancelled");
                // this.fetchAppointments();
                window.App.$emit('update-slotavailability');
              })
              .catch(error => {
                let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                this.$root["$alert"].show('Unable to cancel appointment', errorMessage)
              })
        }
        else
        {
          CMSApi.deleteAppointment(data.appointmentId)
              .then(() => {
                this.appointments[this.appointments.findIndex(g => g.appointmentId === data.appointmentId)].status = AppointmentState.Canceled;
                window.App.$emit('show-snackbar', "success", "Appointment cancelled");
                // this.fetchAppointments();
                window.App.$emit('update-slotavailability');
              })
              .catch(error => {
                let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                this.$root["$alert"].show('Unable to cancel appointment', errorMessage)
              })
        }
      }
    },

    async billSelected() {
      if (await this.$root["$confirm"].open('Charge appointment?', 'Are you sure you want to charge selected appointments ?', { color: 'info' }))
      {
        this.updatingSelectedRows = true;
        this.selectedRowsData.forEach(e => {
          CMSApi.putBillingAppointment(e.appointmentId, true);
        })
        this.updatingSelectedRows = false;
        this.fetchAppointments();
      }
    },

    async unBillSelected() {
        if (await this.$root["$confirm"].open('No Charge appointment?', 'Are you sure you want the selected appointments to be free of charge ?', { color: 'info' }))
        {
            this.updatingSelectedRows = true;
            this.selectedRowsData.forEach(e => {
                CMSApi.putBillingAppointment(e.appointmentId, false);
            })
            this.updatingSelectedRows = false;
            this.fetchAppointments();
        }
    },

    async completeAppointment({data}, target) {
        if (await this.$root["$confirm"].open('Admin Override', 'Proceed in overriding the appointment status ? Please take note this will be recorded', { color: 'warning' }))
        {
            this.appointmentCompleteIconProcessing(target, true);
            this.updatingSelectedRows = true;
            CMSApi.putCompleteAppointment(data.appointmentId)
              .then(() => {
                  this.appointmentCompleteIconProcessing(target, false);
              })
              .finally(() => {
                  this.fetchAppointments();
              });
        }
    },

    // Misc
    clearSelection() {
      const dataGrid = this.$refs[this.appointmentsGridRefKey].instance;

      dataGrid.clearSelection();
    },

    isSelectable(item) {
      let status;
      if (item.isFromPropel) {
        switch (item.status) {
          case AppointmentState.Canceled :
            status = false;
            break;
          default:
            status = true;
            break;
        }
      } else {
        status = false;
      }
      return item.approved = status;
    },

    isSelectAll(dataGrid) {
      let items = [];
      dataGrid.getDataSource().store().load().done(function (data) {
        items = data;
      });

      let selectableItems = items.filter(this.isSelectable);
      let selectedRowKeys = dataGrid.option("selectedRowKeys");
      if (!selectedRowKeys.length) {
        return false;
      }
      return selectedRowKeys.length >= selectableItems.length ? true : undefined;
    },

    onSelectionChanged(e) {
      let deselectRowKeys = [];
      e["selectedRowsData"].forEach((item) => {
        if (!this.isSelectable(item))
          deselectRowKeys.push(e.component.keyOf(item));
      });
      if (deselectRowKeys.length) {
        e.component.deselectRows(deselectRowKeys);
      }
      checkBoxUpdating = true;
      selectAllCheckBox.option("value", this.isSelectAll(e.component));

      this.selectedRowsData = e["selectedRowsData"];
    },

    onEditorPreparing(e) {
      let dataGrid = e.component;

      if (e["command"] === "select") {
        if (e["parentType"] === "dataRow" && e.row) {
          if (!this.isSelectable(e.row.data))
            e.editorOptions.disabled = true;
        } else if (e["parentType"] === "headerRow") {
          e.editorOptions.onInitialized = (e) => {
            selectAllCheckBox = e.component;
          };
          e.editorOptions.value = this.isSelectAll(dataGrid);
          e.editorOptions.onValueChanged = (e) => {
            if (!e.event) {
              if (e.previousValue && !checkBoxUpdating) {
                e.component.option("value", e.previousValue);
              }
              return;
            }
            if (this.isSelectAll(dataGrid) === e.value) {
              return;
            }
            e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
            e.event.preventDefault();
          }
        }

      }
    },

    onRowPrepared(e) {
        if (e.rowType !== 'header')
        {
            if (!this.isAppointmentCurrent(e.data) || this.isAppointmentCompleted(e.data))
            {
                e.rowElement.className = e.rowElement.className + ' disabledRow';
            }
        }

    },

    appointmentEditIconProcessing(target, value) {
      if(value)
      {
        target.classList.remove("mdi-pencil");
        target.classList.add("mdi-loading", "icon-spinner");
      }
      else
      {
        target.classList.remove("mdi-loading", "icon-spinner");
        target.classList.add("mdi-pencil");
      }
    },

    appointmentCompleteIconProcessing(target, value) {
        if(value)
        {
            target.classList.remove("mdi-tag-check");
            target.classList.add("mdi-loading", "icon-spinner");
        }
        else
        {
            target.classList.remove("mdi-loading", "icon-spinner");
            target.classList.add("mdi-tag-check");
        }
    },

    formatWeight(appointment) {
      let stringWeight = "";
      switch (appointment["transactionType"])
      {
        case "RE":
        case "RI":
        case "DI":
        case "DE":
          if(appointment.containerVGMWeight !== 0)
            stringWeight = appointment.containerVGMWeight
          else if (this.isContainerBooking(appointment))
          {
            if (appointment.appointmentType === AppointmentType.DropOff.code)
              stringWeight = 'Not preadvised';
            else
              stringWeight = 'Weight TBC';
          }
          break;
        case "DM":
        case "RM":
          stringWeight = "empty";
          break;
      }

      return stringWeight;
    },
  }
}
</script>

<style>
.disabledRow {
    color: silver;
}
.disabledRow.dx-state-hover td {
    color: silver !important;
}
</style>