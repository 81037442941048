import {
    AlertLevel,
    AppointmentState, AppointmentType,
    ContainerInboundMode,
    ContainerTransitState,
    GateConfig
} from '@/utils/constants.js'
import utils from '@/utils/vuexUtils.js'
import appointmentData from "@/models/appointment/appointmentData";
import store from '@/store'

// initial state
const state = {
    // Objects
    appointments: [],
    slotAvailability: [],
    edoData: [],
    trucks: [],
    companies: [],

    // Loading Indicators
    loadingAppointments : false,
    loadingSlotAvailability : false,
    loadingEdoData : false,
    loadingTrucks : false,
    loadingCompanies : false,

    // Working Objects
    workingAppointment: new appointmentData.AppointmentDetails(),

    // Working Elements
    workingEdoNumber: null,
}

// actions
const actions = {
    fetchCurrentAppointments: ({ commit, state }, allCompanies = false) => {
        state.loadingAppointments = true;
        state.appointments = [];
        return utils.CommitArrayPromise(commit, CMSApi.fetchCurrentAppointments(allCompanies), 'setAppointments');
    },

    fetchAppointmentsWithPast: ({commit, state}, allCompanies = false) => {
        state.loadingAppointments = true;
        state.appointments = [];
        return utils.CommitArrayPromise(commit, CMSApi.fetchAppointmentsWithPast(allCompanies), 'setAppointments');
    },

    fetchTrucks: ({commit, state}) => {
      state.loadingTrucks = true;
      return utils.CommitArrayPromise(commit, CMSApi.fetchTruckRegos(), 'setTrucks');
    },

    fetchCompanies: ({commit, state}) => {
        state.loadingCompanies = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchVbsCompanies(), 'setCompanies');
    },

    fetchEdoDetails: ({commit, state}, id) => {
        state.loadingEdoData = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchEDODetails(id), 'setEdoData');
    },

    fetchAvailableSlots: ({commit, state}, {date, ignore, gateId}) => {
        state.loadingSlotAvailability = false;
        return utils.CommitArrayPromise(commit, CMSApi.fetchAvailableSlots(date, ignore, gateId), 'setSlotAvailability')
    },

    setWorkingAppointmentData: ({commit}, {appointment, gate}) => {
        let workingAppointment = new appointmentData.AppointmentDetails();

        workingAppointment.setFromFlattenedAppointmentData(appointment);
        workingAppointment.setFromFlattenedGateData(gate);

        commit('setCurrentWorkingAppointment', workingAppointment);
    },

    resetWorkingAppointment : ({commit}) => {
        let newWorkingAppointment = new appointmentData.AppointmentDetails();
        commit('setCurrentWorkingAppointment', newWorkingAppointment);
    },

    createAppointment: ({ commit, state }, {data, isPropelAppointment}) => { // eslint-disable-line no-unused-vars
        return isPropelAppointment ? utils.CommitArrayPromise(commit, CMSApi.postPropelGateAppointment(data), 'addOrEditAppointments') : utils.CommitArrayPromise(commit, CMSApi.postN4Appointment(data), 'addOrEditAppointments');
    },

    createBulkAppointments: ({ commit, state }, {selectedDate, zone, quantity, isPropelAppointment, gateId, company}) => { // eslint-disable-line no-unused-vars
        return isPropelAppointment ? utils.CommitArrayPromise(commit, CMSApi.postPropelGateBulkBooking(selectedDate, zone, quantity, gateId, company) , 'addOrEditAppointments') : utils.CommitArrayPromise(commit, CMSApi.postN4BulkBooking(selectedDate, zone, quantity), 'addOrEditAppointments');
    },

    updateAppointment: ({ commit, state }, {data, isPropelAppointment}) => { // eslint-disable-line no-unused-vars
        return isPropelAppointment ? utils.CommitArrayPromise(commit, CMSApi.putPropelGateAppointment(data), 'addOrEditAppointments') : utils.CommitArrayPromise(commit, CMSApi.putN4Appointment(data), 'addOrEditAppointments');
    },
}

// mutations
const mutations = {
    async setAppointments(state, appointments) {
    await store.dispatch('gateAppointments/fetchAllGates');
      appointments.forEach(appointment => {
          helpers.setAlerts(appointment);
      });
      state.appointments = appointments;
      state.loadingAppointments = false;
    },

    addOrEditAppointments(state, appointments) {
        for (const appointment of appointments) {
            helpers.setAlerts(appointment);

            const existingAppointmentIndex = state.appointments.findIndex(app => app.appointmentId === appointment.appointmentId);

            //if appointment exists in list, update, else add.
            if (existingAppointmentIndex >= 0)
                Object.assign(state.appointments[existingAppointmentIndex], appointment);
            else
                state.appointments.unshift(appointment);
        }

        state.appointments.sort((a,b) => a.appointmentDate.localeCompare(b.appointmentDate) || b.zone - a.zone);
    },

    setCurrentWorkingAppointment(state, workingAppointment) {
        state.workingAppointment = workingAppointment;
    },

    setEdoData(state, edoData) {
        state.edoData = edoData;
        state.workingEdoNumber = edoData["edoNumber"];
        state.loadingEdoData = false;
    },

    setTrucks(state, trucks) {
        state.trucks = trucks;
        state.loadingTrucks = false;
    },

    setCompanies(state, companies) {
        state.companies = companies
        state.loadingCompanies = false;
    },

    setSlotAvailability(state, slotAvailability) {
        state.slotAvailability = slotAvailability;
        state.loadingSlotAvailability = false;
    },
}

const helpers = {
    async setAlerts(appointment) {
        let alerts = [];
        let alertLevel = AlertLevel.None;

        if (helpers.isAppointmentCurrent(appointment))
        {
            const currentZone = window.Moment().startOf('hour');
            const nextZone = currentZone.clone().add(1, 'hours');

            let appointmentDateTime = window.Moment(appointment.appointmentDate).add(appointment.zone, 'hours');

            //late status alerts - note Late is not currently set to be used in N4, hence manual check
            if(appointment.status === AppointmentState.Late || appointmentDateTime.isBefore(currentZone))
                alerts.push({level: AlertLevel.Warning, description: "Late for Appointment – Immediate arrival required"});

            let gates = store.getters['gateAppointments/getGates'];

            let gateConfig =  GateConfig[gates.find( ({n4GateId}) => n4GateId === appointment.gate).setGateConfig];

            //required info alerts
            switch(gateConfig)
            {
                case 'Full Containers':
                    if(!appointment.truckRegistration || !appointment.containerNumber)
                    {
                        if(appointmentDateTime.isSameOrBefore(currentZone))
                            alerts.push({level: AlertLevel.Severe, description: "Incomplete appointment data - URGENT"});
                        else if(appointmentDateTime.isSame(nextZone))
                            alerts.push({level: AlertLevel.Warning, description: "Incomplete appointment data"});
                        else
                            alerts.push({level: AlertLevel.Minor, description: "Incomplete appointment data"});
                    }
                    break;
                case 'Empty Containers':
                    if(!appointment.truckRegistration || (!appointment.emptyReleaseNumber && appointment.transactionType === 'DM' ) || (!appointment.containerNumber && appointment.transactionType === 'RM'))
                    {
                        if(appointmentDateTime.isSameOrBefore(currentZone))
                            alerts.push({level: AlertLevel.Severe, description: "Incomplete appointment data - URGENT"});
                        else if(appointmentDateTime.isSame(nextZone))
                            alerts.push({level: AlertLevel.Warning, description: "Incomplete appointment data"});
                        else
                            alerts.push({level: AlertLevel.Minor, description: "Incomplete appointment data"});
                    }
                    break;
            }

            //pick up alerts - for containers (not groups)
            if(appointment.appointmentType === AppointmentType.PickUp.code && helpers.isContainerBooking(appointment) && (appointment.transactionType !== "DM" && appointment.transactionType !== "RM") )
            {
                if(appointment.containerImpediments.length > 0)
                    alerts.push({level: AlertLevel.Severe, description: "Hold on Container – resolve before collection"});

                if(appointment.containerTransitState !== ContainerTransitState.Yard)
                {
                    if(appointmentDateTime.isSameOrBefore(currentZone))
                        alerts.push({level: AlertLevel.Severe, description: "Container not yet in Yard"});
                    else
                        alerts.push({level: AlertLevel.Warning, description: "Container not yet in Yard"});
                }
            }

            //drop off alerts
            switch (appointment["transactionType"])
            {
                case "RE":
                case "RI":
                case "DI":
                case "DE":

                    if(appointment.appointmentType === AppointmentType.DropOff.code)
                    {
                        if(appointment.containerNumber != null && appointment.containerVGMWeight === 0)
                            alerts.push({level: AlertLevel.Severe, description: "Container not pre-advised"});
                    }

                    break;
            }

            if(alerts.length > 0)
                alertLevel = Math.max(...alerts.map(e => e.level));
        }
        else
        {
            alertLevel = -1;
        }

        appointment.alertLevel = alertLevel;
        appointment.alerts = alerts;
    },

    isAppointmentCurrent(appointment) {
        if (appointment.isFromPropel) {
            return (appointment.status === AppointmentState.Created || appointment.status === AppointmentState.Late || (appointment.status === AppointmentState.Used && !helpers.isCompletedMoreThanTenMinutesAgo(appointment.completedDate)));
        } else {
            return (appointment.status === AppointmentState.Created || appointment.status === AppointmentState.Late );
        }
    },

    isCompletedMoreThanTenMinutesAgo(datetime) {
        const dateTimeAgo = window.Moment().diff(datetime, 'minutes');
        return datetime != null ? dateTimeAgo > 10 : false;
    },

    isContainerBooking(appointment)
    {
        return appointment.containerNumber && appointment.containerNumber.length > 3;
    },

    determineImportStatus(transitState, estimatedMoveTime, inboundMode)
    {
        if (!transitState)
            return "";
        else if (transitState === ContainerTransitState.Yard)
            return "In Yard";
        else if (estimatedMoveTime != null)
            return window.Moment(estimatedMoveTime).add(30, 'minutes').format("DD-MM-YYYY HH:mm");
        else
        {
            if (inboundMode === ContainerInboundMode.Truck)
                return "Inbound - Truck";
            else if (inboundMode === ContainerInboundMode.Train)
                return "Inbound - Train";
            else
                return "On Vessel Time TBC";
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}

export const appointmentHelpers = helpers;

//could be useful in future - storing state in local storage - https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store
//how to update arrays  - https://gist.github.com/DawidMyslak/2b046cca5959427e8fb5c1da45ef7748