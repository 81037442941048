<template>
  <div>
    <v-card>
      <v-card-subtitle>Capacity Form</v-card-subtitle>
      <v-card-text>
        <v-container class="datatableControls">
          <v-form ref="capacityForm" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="originId"
                  ref="serviceOrigin"
                  :items="serviceRoutesTerminalOrigins"
                  :item-text="(row) => {return row.description +'  ( '+(row.workstationCode) +' )' }"
                  item-value="transportServiceTerminalSiteId"
                  label="Route Origin"
                  :disabled="disableOrigin"
                  :rules="routeOriginRules"
                  tabindex="1"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="destinationId"
                  ref="serviceDestination"
                  :items="serviceRoutesTerminalDestinations"
                  :item-text="(row) => {return row.description +'  ( '+(row.workstationCode) +' )' }"
                  item-value="transportServiceTerminalSiteId"
                  label="Route Destination"
                  :disabled="disableDestination"
                  :rules="routeDestinationRules"
                  tabindex="2"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="wagonCapacity"
                  label="Wagon Capacity (TEU)"
                  type="number"
                  step="any"
                  :rules="wagonCapacityRules"
                  tabindex="3"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="secondary"
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="secondary"
          @click="save"
          :loading="savingCapacity"
        >
            Save
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import generalHelpers from "@/utils/generalHelpers";

export default {
    name: "TransportPlannerList-CapacityForm",

    mixins: [generalHelpers],

    props : {
        direction: String,
        instanceId: Number,
        serviceId : Number,
    },

    data() {
        return {
            originId: null,
            destinationId : null,
            wagonCapacity: null,
            disableOrigin: false,
            disableDestination : false,

            // Validation
            routeOriginRules : [
                v => !!v || 'Route Origin is required',
            ],
            routeDestinationRules : [
                v => !!v || 'Route Destination is required'
            ],
            wagonCapacityRules : [
                v => !!v || "This field is required",
                v => ( v && v >= 0 ) || "Capacity TEU should be above 0",
                v => ( v && v <= 2 ) || "Capacity TEU should not be above 2",
            ],


            defaultWorkstationCode: 'NPR',
            savingCapacity : false,
        }
    },

    computed : {
        ...mapState('trainService', [
            'containerTerminalSitesAndSidings'
        ]),

        serviceRoutesTerminalOrigins() {
            return this.hasNonEmptyValue(this.direction) ?
                this.direction.toLocaleLowerCase() === 'outbound' ?
                    this.containerTerminalSitesAndSidings.filter(g => g.workstationCode === this.defaultWorkstationCode)
                    : this.containerTerminalSitesAndSidings.filter(k => k.workstationCode !== this.defaultWorkstationCode)
                : [];
        },

        serviceRoutesTerminalDestinations() {
            return this.hasNonEmptyValue(this.direction) ?
                this.direction.toLowerCase() === 'outbound' ?
                    this.containerTerminalSitesAndSidings.filter(k => k.workstationCode !== this.defaultWorkstationCode)
                    : this.containerTerminalSitesAndSidings.filter(k => k.workstationCode === this.defaultWorkstationCode)
                : [];
        },
    },

    watch : {
        serviceRoutesTerminalOrigins: {
            handler(val) {
                if (val.length === 1) {
                    this.originId = val[0].transportServiceTerminalSiteId;
                    this.disableOrigin = true;
                } else {
                    this.originId = null;
                    this.disableOrigin = false;
                }
            },
            deep: true
        },

        serviceRoutesTerminalDestinations: {
            handler(val) {
                if (val.length === 1) {
                    this.destinationId = val[0].transportServiceTerminalSiteId;
                    this.disableDestination = true;
                } else {
                    this.destinationId = null;
                    this.disableDestination = false;
                }
            }
        }
    },

    mounted() {
        this.fetchContainerTerminalSitesAndSidings();
    },

    methods : {
        ...mapActions('trainService', [
            'fetchContainerTerminalSitesAndSidings'
        ]),

        async save() {
            if(this.$refs.capacityForm.validate()) {
                if (await this.$root["$confirm"].open('Add Route Capacity', 'Confirm to add capacity on this instance?', { color: 'info'})) {
                    this.savingCapacity = true;
                    let postData = {
                        TrainServiceId: this.serviceId,
                        TrainServiceInstanceId: this.instanceId,
                        OriginTerminalSiteId: this.originId,
                        DestinationTerminalSiteId: this.destinationId,
                        CapacityTeu: this.wagonCapacity
                    }

                    CMSApi.postTrainServiceRoute(postData)
                        .then(() => {
                            this.savingCapacity = false;
                        })
                        .finally(() => {
                            this.$emit('closeAndReload');
                        })
                }
            }
        }
    }
}
</script>

<style scoped>

</style>