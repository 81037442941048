<!--suppress VueUnrecognizedSlot -->
<template>
  <div>
    <v-card elevation="1">
      <v-container>
        <v-row>
          <v-col :cols="gridColumnSize">
            <v-row>
              <v-col>
                <v-toolbar class="datatableToolbar" flat>
                  <v-toolbar-title>CT Sites & Sidings</v-toolbar-title>
                </v-toolbar>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-right" cols="2">
                <v-btn
                    v-if="!isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    right
                    :loading="loadSitesAndSidings"
                    @click="fetchContainerTerminalSitesAndSidings"
                >
                  <v-icon>mdi-reload</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Refresh</span>
                </v-btn>
              </v-col>
              <v-col class="text-right" cols="1">
                <v-btn
                    v-if="!isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    right
                    :loading="savingIndicator"
                    @click="activateForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Add</span>
                </v-btn>
              </v-col>
            </v-row>
            <DxDataGrid
                id="siteAndSidingsGrid"
                :ref="dataGridRefName"
                :data-source="sitesAndSidings"
                :show-borders="false"
                :hoverStateEnabled="true"
                noDataText="No current sites & sidings"
            >
              <DxPaging :page-size="10"/>
              <DxPager
                  :show-page-size-selector="true"
                  :allowed-page-sizes="pageSizes"
                  :show-info="true"
              />

              <DxColumn data-field="kiwiRailCodes" data-type="string" caption="KR codes"/>
              <DxColumn data-field="description" data-type="string" />
              <DxColumn data-field="type" data-type="string" />
              <DxColumn data-field="workstationCode" data-type="string" />
              <DxColumn data-field="workstationName" data-type="string" />
              <DxColumn data-field="isActive" data-type="boolean" caption="Status" />

              <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Actions" />
              <template #actionCellTemplate="{ data }">
                <div>
                  <v-icon color="primary" size="18" class="mr-2" @click="editSite(data)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="primary" size="18" class="mr-2" @click="deleteSite(data)">
                    mdi-delete
                  </v-icon>
                </div>
              </template>
            </DxDataGrid>
          </v-col>
          <v-col :cols="formColumnSize" v-if="isFormActive">
            <v-row>
              <v-spacer></v-spacer>
              <v-col class="text-right" cols="5">
                <v-btn
                    v-if="isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    @click="deactivateForm"
                >
                  <v-icon>mdi-arrow-collapse-right</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Collapse</span>
                </v-btn>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="3">
                <v-card-text>
                  <ValidationSummary :validationErrors="validationErrors" />
                  <v-form ref="siteForm" lazy-validation>
                    <v-container>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.kiwiRailCodes"
                            ref="kiwiRailCodes"
                            label="KiwiRail Codes"
                            :rules="kiwiRailCodesRules"
                            tabindex="1"
                            maxlength="10"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                              v-model="editedItem.isActive"
                              label="Active"
                              dense
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                              v-model="editedItem.includeInBilling"
                              label="Billing?"
                              dense
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field
                              v-model="editedItem.description"
                              label="Description"
                              tabindex="2"
                              :rules="nameRules"
                              dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                              v-model="editedItem.type"
                              :items="['CT','Siding']"
                              label="Type"
                              tabindex="3"
                              :rules="typeRules"
                              dense
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field
                              v-model="editedItem.workstationCode"
                              label="Workstation Code"
                              tabindex="4"
                              :rules="workstationCodeRules"
                              dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                              v-model="editedItem.abbreviation"
                              label="Abbreviation"
                              tabindex="5"
                              dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                              v-model="editedItem.workstationName"
                              label="Workstation Name"
                              tabindex="6"
                              :rules="workstationNameRules"
                              dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-textarea
                              v-model="locations"
                              label="Road Bridge Locations"
                              placeholder="Enter road bridge locations here and separate them by comma"
                              tabindex="7"
                              dense
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="secondary"
                      text
                      @click="resetForm"
                  >
                    Reset
                  </v-btn>
                  <v-btn
                      color="secondary"
                      text
                      @click="saveForm"
                      :loading="savingIndicator"
                  >
                    {{ isUpdate? 'Update': 'Save' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-container>

    </v-card>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxColumn,
} from "devextreme-vue/data-grid";
import ValidationSummary from '@/components/global/ValidationSummary.vue'
import {mapActions, mapState} from "vuex";
export default {
  name: "TransportMappings-SitesAndSidings",

  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxColumn,
    ValidationSummary
  },

  data () {
    return {
      // Grid
      dataGridRefName: 'siteAndSidingsGrid',
      pageSizes: [10,15,25],

      // Form
      isFormActive: false,
      isUpdate: false,
      validationErrors: {},
      editedItem: null,
      locations: null,
      defaultItem: {
        transportServiceTerminalSiteId: null,
        kiwiRailCodes: null,
        description: null,
        workstationCode: null,
        workstationName: null,
        isActive: true,
        includeInBilling : true,
        roadBridgeLocations: [],
        abbreviation: null,
      },
      kiwiRailCodesRules: [
          v => !!v || 'KiwiRail code is required'
      ],
      nameRules: [
        v => !!v || 'Name is required'
      ],
      typeRules: [
        v => !!v || 'Type is required'
      ],
      workstationCodeRules: [
        v => !!v || 'Workstation Code is required'
      ],
      workstationNameRules: [
        v => !!v || 'Workstation Name is required'
      ],
      savingIndicator: false,
    }
  },

  mounted() {
    this.editedItem = Object.assign({}, this.defaultItem);
    this.fetchContainerTerminalSitesAndSidings();
  },

  computed: {
    ...mapState('transportMappings', [
        'terminalSitesAndSidings',
        'loadingTerminalSitesAndSidings'
    ]),

    sitesAndSidings() {
      return this.terminalSitesAndSidings;
    },

    loadSitesAndSidings() {
      return this.loadingTerminalSitesAndSidings;
    },

    gridColumnSize() {
      return this.isFormActive ? 8 : 12;
    },

    formColumnSize() {
      return this.isFormActive ? 4 : 0;
    },

    computedLocations() {
      let instance = this;
      return this.locations ? this.locations.split(',').map(function(item) {
        return {
          terminalSiteRoadBridgeLocationsId : instance.editedItem.roadBridgeLocations.find(x => x.locationCode === item.trim()) ? instance.editedItem.roadBridgeLocations.find(x => x.locationCode === item.trim()).terminalSiteRoadBridgeLocationsId : null,
          locationCode : item.trim(),
        }}) : [];
    }
  },

  watch: {

  },

  methods: {
    ...mapActions('transportMappings',[
        'fetchContainerTerminalSitesAndSidings'
    ]),

    activateForm() {
      this.isFormActive = true;
      this.resetForm();
    },

    deactivateForm() {
      this.isFormActive = false;
    },

    async resetForm() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.isUpdate = false;
      this.locations = null;

      await this.$nextTick();

      this.$refs.siteForm.resetValidation();
      this.validationErrors = {};
      this.$refs.kiwiRailCodes.focus();
    },

    saveForm() {
      if (this.$refs.siteForm.validate())
      {
        this.savingIndicator = true;
        this.editedItem.roadBridgeLocations = this.computedLocations;

        if (this.editedItem.transportServiceTerminalSiteId) {

          CMSApi.putContainerTerminalSitesAndSidings(this.editedItem)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Terminal site updated");
              })
              .catch(error => {
                this.validationErrors = error.response.data.errors;
              })
              .finally(() => {
                this.savingIndicator = false;
                this.fetchContainerTerminalSitesAndSidings();
                this.resetForm();
              });

        } else {

          CMSApi.postContainerTerminalSitesAndSidings(this.editedItem)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Terminal site created");
              })
              .catch(error => {
                this.validationErrors = error.response.data.errors;
              })
              .finally(() => {
                this.savingIndicator = false;
                this.fetchContainerTerminalSitesAndSidings();
                this.resetForm();
              });
        }
      }
    },

    // Buttons
    editSite(e) {
      CMSApi.fetchContainerTerminalSiteAndSidingById(e.data['transportServiceTerminalSiteId'])
          .then(data => {
            this.editedItem = Object.assign({}, data);
            this.locations = data.roadBridgeLocations.map(function(item) {
              return item.locationCode;
            }).join(', ');
            this.isUpdate = true;
          })
          .finally(() => {
            this.isFormActive = true;
          })
    },

    async deleteSite(e) {
      e.cancel = true;
      if (await this.$root.$confirm.open('Site Removal', 'Are you sure you want to perform this action?', { color: 'warning'})) {
        App["$appAnalytics"].trackEvent('Transport Capacity/ Mappings - Delete site Click');

        CMSApi.deleteContainerTerminalSitesAndSidings(e.data["transportServiceTerminalSiteId"])
            .then(() => {
              this.fetchContainerTerminalSitesAndSidings();
            })
            .catch(error => {
              let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
              this.$root.$alert.show('Unable to delete Site', errorMessage)
            })
      }
    },
  }
}
</script>

<style scoped>

</style>