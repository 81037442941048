<template>
    <div>
        <v-data-table
            id="notificationsListTable"
            :headers="computedHeaders"
            :items="notifications"
            :items-per-page="10"
            item-key="notificationId"
            class="elevation-1"
            :loading=loadingNotifications loading-text="Loading... Please wait"
            no-data-text="There are currently no notifications"
            @click:row="editNotification"
            >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Notifications Management</v-toolbar-title>
                
                    <div class="flex-grow-1"></div>
                    <v-btn                  
                        tile class="mb-2" 
                        color="secondary" 
                        @click="newNotification" >
                            <v-icon>mdi-plus</v-icon>
                            <span id="newNotificationButtonLabel" v-if="$vuetify.breakpoint.smAndUp">New Notification</span>
                    </v-btn>
                </v-toolbar>
            </template>

            <template v-slot:item.startDate="{ item }">
                <span class="nowrap">{{item.startDate | formatDate}}</span>&nbsp;
                <span class="nowrap">{{item.startDate | formatTime}}</span>
            </template>

            <template v-slot:item.endDate="{ item }">
                <span class="nowrap">{{item.endDate | formatDate}}</span>&nbsp;
                <span class="nowrap">{{item.endDate | formatTime}}</span>
            </template>

            <template v-slot:item.content="{ item }">
                <div class="longtext">
                    {{prepareContentToDisplay(item.content)}}
                </div>
            </template>

            <template v-slot:item.websiteAlert="{ item }">
                <v-icon>
                    {{ item.websiteAlert ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                </v-icon>
            </template>

            <template v-slot:item.emailAlert="{ item }">
                <v-icon>
                    {{ item.emailAlert ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                </v-icon>
            </template>

            <template v-slot:item.applications="{ item }">
                <div>
                    <v-chip                            
                        v-for="(app, i) in item.applications" :key="i"
                        color="propelDarkGrey"
                        label small dark
                        class="gridChips"
                    >{{app.name}}</v-chip>
                </div>
            </template>

            <template v-slot:item.action="{ item }">
                <v-icon color="primary" small class="mr-2">
                    {{isNotificationEditable(item) ? 'mdi-pencil': 'mdi-eye'}}
                </v-icon>
            </template>

        </v-data-table> 
        <NotificationForm 
            ref="notificationForm"
            v-on:notificationCreated="notificationCreatedOrUpdated($event)"
            v-on:notificationUpdated="notificationCreatedOrUpdated($event)" />
    </div>
</template>

<script>
import NotificationForm from './NotificationForm.vue';

export default {
    components: {
        NotificationForm,
    },   
    data () {
        return {
            notifications: [],
            loadingNotifications: false,
            headers: [
                { text: 'Title', value: 'title', align: 'left' },
                { text: 'Content', value: 'content', align: 'left', hide: 'lgAndDown' },
                { text: 'Start Date', value: 'startDate', align: 'left' },
                { text: 'End Date', value: 'endDate', align: 'left' },
                { text: 'Website/Mobile Alert', value: 'websiteAlert', align: 'center', sortable: false },
                { text: 'Email Alert', value: 'emailAlert', align: 'center', sortable: false },
                { text: 'Port Function/Audience', value: 'applications', align: 'left', sortable: false },
                { text: 'Action', value: 'action', align: 'center', sortable: false },
            ],
        }
    },

    computed: {
        computedHeaders () {
            return this.headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])  
        }
    },

    mounted () {
        this.fetchNotifications();
    },

    methods: {
        fetchNotifications() {
            this.loadingNotifications = true;

            CMSApi.fetchAllNotifications()
                .then(data => {
                    this.notifications = data;
                })
                .finally(() => {
                    this.loadingNotifications = false;
                });
        },

        isNotificationEditable(notification) {
            return notification.websiteAlert;
        },

        editNotification(item) {
            App.$appAnalytics.trackEvent('Port Admin - Notitications - Edit Click');
            this.$refs.notificationForm.editNotification(item);
        },

        newNotification() {
            App.$appAnalytics.trackEvent('Port Admin - Notitications - Create Click');
            this.$refs.notificationForm.createNotification();
        },

        notificationCreatedOrUpdated(notification) {
            const existingRecordIndex = this.notifications.findIndex(n => n.notificationId == notification.notificationId);

            //if record exists in list, update, else add.
            if (existingRecordIndex >= 0)
                Object.assign(this.notifications[existingRecordIndex], notification);
            else
                this.notifications.unshift(notification);
        },

        prepareContentToDisplay(htmlContent) {
            let textContent = this.stripOutHtmlTags(htmlContent);
            return textContent.substring(0, 100) + (textContent.length > 100 ? '...' : '')
        },

        stripOutHtmlTags(html) {
            html = html.replace(new RegExp("</p>", 'g'), "</p> ");
            var doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        },
    }
};
</script>

<style>
    .nowrap {
        white-space: nowrap
    }

    #newNotificationButtonLabel {
        margin-left: 10px;
    }
</style>