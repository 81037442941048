<template>
  <div>
    <v-card-title>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="1">
            <span class="headline">Zone Slots</span>
          </v-col>
          <v-col cols="2">
            <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="computedSelectedDateFormatted"
                    label="Slots Date"
                    readonly
                    prepend-icon="mdi-calendar"
                    v-on="on"
                    @click:prepend="dateMenu = true"
                    autocomplete="off"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dateSelected"
                  :min="showStartDateNow"
                  @input="dateMenu = false; reloadSlots()"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-btn
                tile
                color="secondary"
                @click="$emit('hideEditForm')"
                class="float-right"
                title="Hide this"
                style="margin-top: 10px"
            >
              Back to Appointments
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6" v-for="gate in gates" :key="gate.gateId">
          <v-card elevation="3"
            height="320px"
          >
            <v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <strong>{{gate.gateName}}</strong>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" v-if="gate.isFromPropel">
                    <v-text-field
                        v-model="gateSlotQty[gate.gateId]"
                        dense
                        label="Slot Qty"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" v-if="gate.isFromPropel">
                    <v-btn
                        text
                        color="secondary"
                        class="float-left"
                        title="Hide this"
                        @click="updateAllSlot(gate.gateId)"
                    >
                      Update All
                       <v-icon>mdi-content-save-all</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>

            </v-card-title>
            <v-card-text>
              <v-virtual-scroll
                  :items="slotAvailability"
                  height="200"
                  item-height="50"
              >
                <template v-slot:default="{ item, index }">
                  <v-list-item :key="index">
                    <v-list-item-action>
                      <v-btn
                          fab
                          small
                          depressed
                          color="propelDarkGreen"
                          class="white--text"
                      >
                        {{ item.zone }}
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="padding:10px">
                        Slots : <strong>{{ typeof( item[gate.gateId] ) !== "undefined" ? item[gate.gateId]: 0  }}</strong>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action v-if="gate.isFromPropel">
                      <v-icon color="secondary" @click="showDialog(gate.gateId, item.zone, item[gate.gateId])">
                        mdi-pencil
                      </v-icon>
                    </v-list-item-action>

                    <v-list-item-action v-if="gate.isFromPropel && item[gate.gateId] > 0">
                      <v-icon color="secondary" @click="deleteSlot(gate.gateId, item.zone, $event.target)">
                        mdi-trash-can
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
        v-model="slotForm"
        max-width="300px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit Slot </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field label="GateId" v-model="editedItem.gateId" disabled autocomplete="off"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field label="Zone" v-model="editedItem.zone" disabled autocomplete="off"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field label="Current Qty" v-model="editedItem.qty" disabled autocomplete="off"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                  label="New Slot Qty"
                  color="primary"
                  v-model.number="newQty"
                  autocomplete="off"
              >
              </v-text-field>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                    depressed
                    tile
                    color="primary"
                    class="ma-0"
                    width="100%"
                    @click="updateSlotQty"
                    :loading="updatingSlotQty"
                >
                  Update
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="primary"
              text
              @click="closeDialog"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  name:"gateZoneSlotQuickBookModal",

  data() {
    return {
      // Datepicker
      dateMenu: false,
      dateSelected: null,

      // Dialog
      slotForm: false,
      editedItem: {
        gateId: null,
        zone: null,
        qty: null,
      },
      newQty: null,
      editMode: null,
      updatingSlotQty: false,

      // Gates Model
      gateSlotQty: {
        APPT_GATE: 0,
        THAMES_ST: 0,
        T2_GATE: 0,
        ['MANUAL GATE']: 0
      }
    }
  },

  computed: {
    ...mapState('gateAppointments', [
      'gates',
      'slotAvailability'
    ]),

    computedSelectedDateFormatted () {
      return window.App.formatDate(this.dateSelected);
    },

    showStartDateNow() {
      return window.Moment().startOf('day').format('YYYY-MM-DD');
    },

    updateMethod() {
      return this.editedItem.qty > this.newQty ? 'S' : 'A';
    }
  },

  methods: {
    ...mapActions('gateAppointments', [
      'fetchSlots'
    ]),

    init(date) { // Point of entry
      this.dateSelected = date;
      this.fetchSlots(date);
    },

    // Dialog
    showDialog(gateId, zone, qty) { // eslint-disable-line no-unused-vars
      this.editedItem.gateId = gateId;
      this.editedItem.zone = zone;
      this.editedItem.qty = qty;
      this.slotForm = true;
    },

    async closeDialog() {
      await this.resetSlotForm();
      this.slotForm = false;
    },

    async updateAllSlot(gateId) {
      this.updatingSlotQty = true;

      for (let i = 0; i < this.slotAvailability.length; i++)
      {
        let currentQty = this.slotAvailability[i][gateId];
        let inputtedQty = this.gateSlotQty[gateId];
        let updateMode = currentQty > inputtedQty ? 'S' : 'A';

        let qty = updateMode === 'A' ? inputtedQty - currentQty : currentQty - inputtedQty;

          if (qty > 0)
          {
            let putData = {
              instanceDate: this.dateSelected,
              Zone: this.slotAvailability[i]['zone'],
              GateId: gateId,
              ModificationMode: updateMode,
              SlotQty : qty
            }

            this.updatingSlotQty = true;
            await this.$nextTick();
            await CMSApi.putGateZoneSlots(putData);
          }
      }

      // this.gateSlotQty[gateId] = 0
      // this.updatingSlotQty = false;
      // await this.fetchSlots(this.dateSelected);
      // await window.App.$emit('show-snackbar', "success", "Slots updated");

      // await this.slotAvailability.forEach(async (e) => {
      //   let currentQty = e[gateId];
      //   let inputtedQty = this.gateSlotQty[gateId];
      //   let updateMode = currentQty > inputtedQty ? 'S' : 'A';
      //
      //   let qty = updateMode === 'A' ? inputtedQty - currentQty : currentQty - inputtedQty;
      //
      //   if (qty > 0)
      //   {
      //     let putData = {
      //       instanceDate: this.dateSelected,
      //       Zone: e.zone,
      //       GateId: gateId,
      //       ModificationMode: updateMode,
      //       SlotQty : qty
      //     }
      //
      //     this.updatingSlotQty = true;
      //     CMSApi.putGateZoneSlots(putData);
      //   }
      // })
      this.gateSlotQty[gateId] = 0
      this.updatingSlotQty = false;
      await this.fetchSlots(this.dateSelected);
      await window.App.$emit('show-snackbar', "success", "Slots updated");
    },

    updateSlotQty() {
      const inputtedQty = this.newQty;
      const currentQty = this.editedItem.qty;
      const updateMode = this.updateMethod;

      let qty = updateMode === 'A' ? inputtedQty - currentQty : currentQty - inputtedQty;
      if (qty > 0)
      {
        let putData = {
          instanceDate: this.dateSelected,
          Zone: this.editedItem.zone,
          GateId: this.editedItem.gateId,
          ModificationMode: updateMode,
          SlotQty : qty
        }

        this.updatingSlotQty = true;
        CMSApi.putGateZoneSlots(putData)
            .then(async () => {
              await this.resetSlotForm();
              await window.App.$emit('show-snackbar', "success", "Slot updated");
            })
            .finally(() => {
              this.updatingSlotQty = false;
              this.fetchSlots(this.dateSelected);
              this.slotForm = false;
            })
      }
    },

    async deleteSlot(gateId, zone, target) {
      this.gateDeleteIconProcessing(target, true);
      if (await this.$root["$confirm"].open('Delete slot?', 'Are you sure you want to delete slot for this zone?', { color: 'warning' }))
      {
        let putData = {
          instanceDate: this.dateSelected,
          Zone: zone,
          GateId: gateId,
          ModificationMode: "D"
        };

        CMSApi.putGateZoneSlots(putData)
            .then( async () => {
              await this.resetSlotForm();
              await window.App.$emit('show-snackbar', "success", "Slot updated");
            })
            .finally(() => {
              this.gateDeleteIconProcessing(target, false);
              this.fetchSlots(this.dateSelected);
              this.slotForm = false;
            })
      }
      else
      {
        this.gateDeleteIconProcessing(target, false);
      }
    },

    // Misc
    reloadSlots() {
      this.fetchSlots(this.dateSelected);
    },

    resetSlotForm() {
      this.editedItem = {
        gateId: null,
        zone: null,
        qty: null,
      };
      this.newQty = null;
    },

    gateDeleteIconProcessing(target, value) {
      if (value)
      {
        target.classList.remove("mdi-trash-can");
        target.classList.add("mdi-loading", "icon-spinner");
      }
      else
      {
        target.classList.remove("mdi-loading", "icon-spinner");
        target.classList.add("mdi-trash-can");
      }
    }
  }
}
</script>