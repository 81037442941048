
class TransportPlannerCustomerDetails {
    constructor() {
        this.companyId = null;
        this.fwCompanyId = null;
    }
}

class TransportPlannerKiwiRailDetails {
    constructor() {
        this.bookingReferenceNumber = null;
        this.customerReferenceNumber = null;
        this.railReferenceNumber = null;
        this.senderCompanyId = null;
        this.receiverCompanyId = null;
    }
}

class TransportPlannerContainerDetails {
    constructor() {
        this.containerNumber = null;
        this.emptyReleaseNumber = null;
        this.containerIsoType = null;
        this.containerLength = null;
        this.containerWeight = null;
        this.containerTemperature = null;
        this.isGenset = false;
        this.gensetHours = null;
        this.hoursOffPower = null;
        this.containerCommodity = null;
        this.isHazardous = false;
        this.containerNotes = null;
        this.hazardNotes = null;
        this.hazardClass = null;
        this.isReturning = false;
        this.oldContainerNumber = null;
        this.isGoingRoad = false;
    }
}

class TransportPlannerVesselDetails {
    constructor() {
        this.vesselVisitId = null;
    }
}

class TransportPlannerRoadBridge {
    constructor() {
        this.roadBridges = [];
    }
}


export default {
    PlannerDetails : function() {
        this.trainServicePlannerBookingId = null;
        this.trainServiceWagonInstanceId = null;
        this.routes = null;
        this.instanceDate = null;
        this.wagonCapacity = null;
        this.customerDetails = new TransportPlannerCustomerDetails();
        this.kiwiRailDetails = new TransportPlannerKiwiRailDetails();
        this.containerDetails = new TransportPlannerContainerDetails();
        this.vesselDetails = new TransportPlannerVesselDetails();
        this.roadBridgeDetails = new TransportPlannerRoadBridge();

        this.convertToSubmittableService = function() {
            // flatten it all out
            return {
                trainServicePlannerBookingId : this.trainServicePlannerBookingId,
                trainServiceWagonInstanceId : this.trainServiceWagonInstanceId,
                customerDetails : this.customerDetails,
                railDetails : this.kiwiRailDetails,
                containerDetails : this.containerDetails,
                vesselDetails : this.vesselDetails,
                roadBridgeDetailsCollection : this.roadBridgeDetails.roadBridges
            }
        }

        this.setFromFlattenedData = function (flattenedData) {
            this.customerDetails = flattenedData["customerDetails"];
            this.kiwiRailDetails = flattenedData["railDetails"];
            this.containerDetails = flattenedData["containerDetails"];
            this.vesselDetails = flattenedData["vesselDetails"];
            this.roadBridgeDetails.roadBridges = flattenedData["roadBridgeDetailsCollection"];
            this.containerDetails.oldContainerNumber = flattenedData["containerDetails"].containerNumber;
        }
    },
}