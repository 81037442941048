<template>
    <v-form ref="additionalDetailsForm" v-model="stepState.formIsValid" @submit.prevent>
        <div class="stepperStepTitle">{{ titleBase }} (4/4)</div>
        <v-row>
            <v-col cols="3" v-if="showSummary">
                <export-pre-advice-summary :showBookingData="true"></export-pre-advice-summary>
            </v-col>
            <v-divider vertical v-if="showSummary"></v-divider>
            <v-col cols="12" sm="9">
                <v-row class="no-bottom-padding">
                    <v-col cols="12">
                        <label class="v-label theme--light formMinimisedLabel">Hazard Info</label>
                    </v-col>
                </v-row>
                <v-row dense-vertical>
                    <v-col cols="12" md="2">
                    </v-col>
                    <v-col cols="6" md="5">
                        <v-text-field
                            v-model="additionalContainerData.hazardData.imdgClass"
                            class="customBold"
                            ref="hazardImdgClass"
                            label="IMDG Class"
                            type="text"
                            dense
                            :readonly="bookingData.isHazardous"
                            :disabled="true"
                            validate-on-blur
                            @blur="hazardBlur('hazardImdgClass')"
                            tabindex="2"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" md="5">
                        <v-text-field
                            v-model="additionalContainerData.hazardData.undgNumber"
                            class="customBold"
                            ref="hazardUndgNumber"
                            label="UNDG Number"
                            type="text"
                            dense
                            :readonly="bookingData.isHazardous"
                            :disabled="true"
                            validate-on-blur
                            @blur="hazardBlur('hazardUndgNumber')"
                            tabindex="3"
                        >
                        </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="no-vertical-padding mt-n4" v-if="stepState.overallHazardDataIsInvalid">
                    <v-col offset="2" cols="10">
                        <span class="error--text formMinimisedLabel">When "Hazard" is selected, you must specify at least one of IMDG Class or UNDG Number</span>
                    </v-col>
                </v-row>

                <v-row class="no-bottom-padding">
                    <v-col cols="12" md="2">
                        <label class="v-label theme--light formMinimisedLabel customBold">Reefer Specs</label>
                    </v-col>
                </v-row>
                <v-row dense-vertical>
                    <v-col cols="12" md="2">
                        <v-switch
                            class="mt-n1 ml-1"
                            v-model="additionalContainerData.isReefer"
                            inset
                            :readonly="true"
                            tabindex="4"
                            :disabled="!additionalContainerData.isReefer"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" md="10">
                        <!-- Set up another row, so we can divide up the whole space in a nice way -->
                        <v-row>
                            <v-col cols="3" md="3">
                                <v-text-field
                                    v-model="additionalContainerData.reeferData.temperature"
                                    class="customBold"
                                    label="Temperature"
                                    ref="reeferTemperature"
                                    type="number"
                                    dense
                                    :readonly="true"
                                    :disabled="!additionalContainerData.isReefer"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field
                                    v-model="additionalContainerData.reeferData.ventilation"
                                    class="customBold"
                                    ref="reeferVentilation"
                                    type="number"
                                    dense
                                    :readonly="true"
                                    :disabled="!additionalContainerData.isReefer"
                                >
                                    <template slot="label">
                                        <span v-if="ventUnit==='CubicM'">Vent setting (m<sup>3</sup>/hr)</span>
                                        <span v-if="ventUnit==='CubicFt'">Vent setting (ft<sup>3</sup>/min)</span>
                                        <span v-if="ventUnit==='Perc'">Vent setting (perc (%))</span>
                                        <span v-if="ventUnit==='None'">Vent setting</span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" md="3">
                                <v-text-field
                                    v-model="additionalContainerData.reeferData.humidity"
                                    class="customBold"
                                    label="Humidity"
                                    ref="reeferHumidity"
                                    type="number"
                                    dense
                                    :readonly="true"
                                    :disabled="!additionalContainerData.isReefer"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="no-vertical-padding mt-n4" v-if="stepState.overallReeferDataIsInvalid">
                    <v-col cols="12" offset-md="2" md="10">
                        <span class="error--text formMinimisedLabel">When "Reefer" is selected, at least one of Temperature or Ventilation must be set.</span>
                    </v-col>
                </v-row>

                <v-row class="no-bottom-padding">
                    <v-col cols="12">
                        <label class="v-label theme--light formMinimisedLabel">Oversize unit</label>
                    </v-col>
                </v-row>
                <v-row dense-vertical>
                    <v-col cols="12" md="2">
                        <v-switch
                            class="mt-n1 ml-1"
                            v-model="additionalContainerData.isOversize"
                            inset
                            tabindex="6"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" md="10">
                        <!-- Add a separate row here so we can divide the remaining 10 cols of the row above into 4 equal sized cols -->
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="additionalContainerData.oversizeData.top"
                                    class="customBold"
                                    ref="oversizeTop"
                                    label="Top (cm)"
                                    type="number"
                                    dense
                                    :disabled="!additionalContainerData.isOversize"
                                    :rules="stepState.topRules"
                                    validate-on-blur
                                    @keydown="preventExponentialsAndNegatives"
                                    @blur="oversizeBlur('oversizeTop')"
                                    tabindex="7"
                                >
                                </v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row dense-vertical>
                    <v-col cols="12" offset-md="2" md="10">
                        <!-- Add a separate row here so we can divide the remaining 10 cols of the row above into 4 equal sized cols -->
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-text-field
                                    v-model="additionalContainerData.oversizeData.front"
                                    class="customBold"
                                    ref="oversizeFront"
                                    label="Front (cm)"
                                    type="number"
                                    dense
                                    :disabled="!additionalContainerData.isOversize"
                                    :rules="stepState.frontRules"
                                    validate-on-blur
                                    @keydown="preventExponentialsAndNegatives"
                                    @blur="oversizeBlur('oversizeFront')"
                                    tabindex="8"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field
                                    v-model="additionalContainerData.oversizeData.back"
                                    class="customBold"
                                    ref="oversizeBack"
                                    label="Back (cm)"
                                    type="number"
                                    dense
                                    :disabled="!additionalContainerData.isOversize"
                                    :rules="stepState.backRules"
                                    validate-on-blur
                                    @keydown="preventExponentialsAndNegatives"
                                    @blur="oversizeBlur('oversizeBack')"
                                    tabindex="9"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field
                                    v-model="additionalContainerData.oversizeData.left"
                                    class="customBold"
                                    ref="oversizeLeft"
                                    label="Left (cm)"
                                    type="number"
                                    dense
                                    :disabled="!additionalContainerData.isOversize"
                                    :rules="stepState.leftRules"
                                    validate-on-blur
                                    @keydown="preventExponentialsAndNegatives"
                                    @blur="oversizeBlur('oversizeLeft')"
                                    tabindex="10"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field
                                    v-model="additionalContainerData.oversizeData.right"
                                    class="customBold"
                                    ref="oversizeRight"
                                    label="Right (cm)"
                                    type="number"
                                    dense
                                    :disabled="!additionalContainerData.isOversize"
                                    :rules="stepState.rightRules"
                                    validate-on-blur
                                    @keydown="preventExponentialsAndNegatives"
                                    @blur="oversizeBlur('oversizeLeft')"
                                    tabindex="11"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="no-vertical-padding mt-n4" v-if="stepState.overallOversizeDataIsInvalid">
                    <v-col cols="12" offset-md="2" md="10">
                        <span class="error--text formMinimisedLabel">When "Oversize" is selected, you must specify at least one of Top, Front, Back, Left or Right as larger than 0</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" sm="4">
                <v-btn
                    color="secondary"
                    plain
                    @click="cancel"
                    tabindex="14"
                >
                    Cancel
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="9" sm="8" class="text-right">
                <v-btn
                    color="secondary"
                    plain
                    @click="previous"
                    tabindex="13"
                >
                    Previous
                </v-btn>
                <v-btn
                    color="secondary"
                    plain
                    @click="submit"
                    tabindex="12"
                >
                    Submit
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import ExportPreAdviceSummary from "@/components/exportPreAdvice/ExportPreAdviceSummary.vue";
import {mapGetters, mapActions} from "vuex";

export default {
    name: "ExportAdditionalDetails.vue",

    props: {
        titleBase: {
            type: String,
            required: true
        },
    },

    components: {
        ExportPreAdviceSummary
    },

    data() {
        return {
            stepState: {
                hasReeferSpecs: false,
                isHazardousOnBooking: false,
                topRules: [
                    val => this.validateOversizeValue(val, 'Top')
                ],
                frontRules: [
                    val => this.validateOversizeValue(val, 'Front')
                ],
                backRules: [
                    val => this.validateOversizeValue(val, 'Back')
                ],
                leftRules: [
                    val => this.validateOversizeValue(val, 'Left')
                ],
                rightRules: [
                    val => this.validateOversizeValue(val, 'Right')
                ],
                overallReeferDataIsInvalid: false,
                overallOversizeDataIsInvalid: false,
                overallHazardDataIsInvalid: false,
                formIsValid: false
            },
        }
    },

    computed: {
        ...mapGetters('exportPreAdvice', [
            'workingBookingDetails',
            'workingAdditionalContainerDetails'
        ]),
        bookingData() {
            return this.workingBookingDetails;
        },
        additionalContainerData() {
            return this.workingAdditionalContainerDetails;
        },
        showSummary() {
            return !this.$vuetify.breakpoint.xs;
        },
        ventUnit() {
            let unit;

            if (this.additionalContainerData.reeferData.ventilationUnit === 'CUBIC_M_HOUR') {
                unit = "CubicM";
            } else if (this.additionalContainerData.reeferData.ventilationUnit === 'CUBIC_FT_MIN') {
                unit = "CubicFt";
            } else if (this.additionalContainerData.reeferData.ventilationUnit === 'PERCENTAGE') {
                unit = "Perc";
            } else {
                unit = "None";
            }

            return unit;
        }
    },

    watch: {
        'additionalContainerData.isReefer': {
            handler(newVal) {
                if (!newVal) {
                    this.additionalContainerData.reeferData.ventilation = null;
                    this.stepState.overallReeferDataIsInvalid = false;
                }
            },
            deep: true
        },
        'additionalContainerData.isOversize': {
            handler(newVal) {
                if (!newVal) {
                    this.additionalContainerData.oversizeData.top = null;
                    this.additionalContainerData.oversizeData.front = null;
                    this.additionalContainerData.oversizeData.back = null;
                    this.additionalContainerData.oversizeData.left = null;
                    this.additionalContainerData.oversizeData.right = null;
                    this.stepState.overallOversizeDataIsInvalid = false;
                }
            },
            deep: true
        },
        'additionalContainerData.isHazardous': {
            handler(newVal) {
                if (!newVal) {
                    this.additionalContainerData.hazardData.imdgClass = null;
                    this.additionalContainerData.hazardData.undgNumber = null;
                    this.stepState.overallHazardDataIsInvalid = false;
                }
            },
            deep: true
        },
    },

    methods: {
        ...mapActions('exportPreAdvice', [
            'setWorkingAdditionalContainerData',
            'resetWorkingExportPreAdvice'
        ]),

        setFocusOnFirstField() {
            if (!this.additionalContainerData.isHazardous) {
                this.$refs.hazardImdgClass.focus();
            } else if (this.additionalContainerData.isReefer) {
                this.$refs.reeferVentilation.focus();
            } else if (this.additionalContainerData.isOversize) {
                this.$refs.oversizeTop.focus();
            }
            // else: No focus to set.
        },

        preventExponentialsAndNegatives(evt) {
            window.App.preventExponentialsAndNegatives(evt);
        },

        // General validation for oversize values, but with specific message for which side's data is invalid
        validateOversizeValue(val, side) {
            const rulesToCheck = [
                val => !val || val >= 0 || side + " oversize must be at least 0",
                val => !val || val <= 40000 || side + " oversize cannot be more than 40000"
            ];

            // If any value is given, then turn off any potential overall validation error 
            if (val) {
                this.stepState.overallOversizeDataIsInvalid = false;
            }

            return window.App.checkValidationRules(val, rulesToCheck);
        },

        // Overall check for oversize data that at least one of front, back, left or right is set as > 0 when "is oversize" is ticked
        // We only check the overall data rules, if the element we just blurred is indeed valid (could be empty)
        oversizeBlur(ref) {
            let element = this.$refs[ref];
            if (element.validate()) {
                this.overallOversizeDataValidate();
            }
        },

        overallOversizeDataValidate() {
            if (this.additionalContainerData.isOversize) {
                this.stepState.overallOversizeDataIsInvalid =
                    (!this.additionalContainerData.oversizeData.top || this.additionalContainerData.oversizeData.top <= 0)
                    && (!this.additionalContainerData.oversizeData.front || this.additionalContainerData.oversizeData.front <= 0)
                    && (!this.additionalContainerData.oversizeData.back || this.additionalContainerData.oversizeData.back <= 0)
                    && (!this.additionalContainerData.oversizeData.left || this.additionalContainerData.oversizeData.left <= 0)
                    && (!this.additionalContainerData.oversizeData.right || this.additionalContainerData.oversizeData.right <= 0);
            } else {
                this.stepState.overallOversizeDataIsInvalid = false;
            }
        },

        // Overall check for hazard data that at least one of imdg or undg is set when "is hazard" is ticked
        // We only check the overall data rules, if the element we just blurred is indeed valid (could be empty)
        hazardBlur(ref) {
            let element = this.$refs[ref];
            if (element.validate()) {
                this.overallHazardDataValidate();
            }
        },

        overallHazardDataValidate() {
            // No extra requirements if IsHazardous was set on the booking. We then accept what ever is there. So only if that is NOT set
            // but it is set manually here, we want at least one of IMDG or UNDG set
            if (!this.bookingData.IsHazardous && this.additionalContainerData.isHazardous) {
                // Hazard setting is INVALID if both IMDG and UNDG are not set
                this.stepState.overallHazardDataIsInvalid =
                    !this.additionalContainerData.hazardData.imdgClass && !this.additionalContainerData.hazardData.undgNumber;
            } else {
                this.stepState.overallHazardDataIsInvalid = false;
            }
        },

        async submit() {
            // First make sure the form is valid.
            this.formIsValid = this.$refs.additionalDetailsForm.validate();

            // If the form itself is valid, also validate that specific data is specified for any of the three groups if 
            // the group is turned on
            if (this.formIsValid) {
                this.overallOversizeDataValidate();
                this.overallHazardDataValidate()
            }

            if (this.formIsValid
                && !this.stepState.overallReeferDataIsInvalid
                && !this.stepState.overallOversizeDataIsInvalid
                && !this.stepState.overallHazardDataIsInvalid) {
                this.setWorkingAdditionalContainerData(this.additionalContainerData);

                this.$emit("submit");
            }
        },

        resetFormForAdviseAnother() {
            this.$refs.additionalDetailsForm.resetValidation();
        },

        previous() {
            // Does not need to be valid (yet) if the user decides to do a step back 
            this.setWorkingAdditionalContainerData(this.additionalContainerData);
            this.$emit("previous");
        },

        cancel() {
            this.resetWorkingExportPreAdvice();
            this.$emit("cancel");
        },
    }
}
</script>
<style scoped>
.customBold {
    font-weight: 700;
}
</style>
