// initial state
const state = {
    userAccount: null,
}

const getters = {
    getUserAccount( state ) {
        return state.userAccount;
    },
}

// actions
const actions = {
    fetchUserAccount: ({ commit, state }, forceRefresh = false, ignoreError = false) => { // eslint-disable-line no-unused-vars
        return new Promise(function(resolve, reject) {
            if (forceRefresh || state.userAccount == null)
            {
                CMSApi.fetchAccount(ignoreError)
                    .then(data => {
                        commit('setUserAccount', data);
                        resolve(data);
                    })
                    .catch(error => {
                        console.log("unable to fetch user account");
                        reject(error);
                    })
            }
        });
    },
}

// mutations
const mutations = {   
    setUserAccount(state, userAccount) {
        state.userAccount = userAccount;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}