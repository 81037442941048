<template>
  <div>
    <v-card elevation="3">
      <v-card-title>Booking Issues</v-card-title>
      <v-card-text>
        <v-alert
            type="error"
        >
          Fix the following issues before you are allowed to calculate the Invoice:
        </v-alert>
        <v-list-item v-for="(item, i) in issues" :key="i">
          <v-list-item-content v-html="item"></v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="$emit('close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "TransportPlanner-IssuesDialog",

  data() {
    return {
      issues: [],
    }
  },

  methods: {
    init(e) {
      this.issues = e["billingIssues"];
    }
  },
})
</script>



<style scoped>

</style>