<!--suppress JSValidateTypes -->
<template>
  <div>
      <v-card elevation="1">
        <v-toolbar class="datatableToolbar" flat>
          <v-toolbar-title>Transport Capacity -> Mappings</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="2">
              <v-card elevation="3">
                <v-container>
                  <v-list>
                    <v-list-item-group v-model="model">
                      <v-list-item
                          v-for="(item, i) in items"
                          :key="i"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-container>
              </v-card>
            </v-col>
            <v-divider vertical style="display: none"></v-divider>
            <v-col cols="10">
              <component v-bind:is="items[model].component"></component>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
  </div>
</template>

<script>

import TransportMappingsSitesAndSidings
  from "@/components/transportCapacity/mappings/TransportMappings-SitesAndSidings.vue";
import TransportMappingsCustomerAndSites
  from "@/components/transportCapacity/mappings/TransportMappings-CustomerAndSites.vue";
import TransportMappingsRoadBridges
  from "@/components/transportCapacity/mappings/TransportMappings-RoadBridges.vue";
import TransportMappingsFreightForwarders
    from "@/components/transportCapacity/mappings/TransportMappings-FreightForwarders.vue";
import TransportMappingsTransportOperators
  from "@/components/transportCapacity/mappings/TransportMappings-TransportOperators.vue";

export default {
  name: "TransportMappings",

  components: {
    TransportMappingsSitesAndSidings,
    TransportMappingsCustomerAndSites,
    TransportMappingsFreightForwarders,
    TransportMappingsTransportOperators
  },

  data: () => ({
    items: [
      {
        icon: 'fa-solid fa-map-location-dot',
        text: 'CT Sites & Sidings',
        component: TransportMappingsSitesAndSidings
      },
      {
        icon: 'fa-solid fa-user-group',
        text: 'Customers',
        component: TransportMappingsCustomerAndSites
      },
      {
        icon: 'fa-solid fa-truck-plane',
        text: 'Freight Forwarders',
        component: TransportMappingsFreightForwarders
      },
      {
        icon: 'mdi-road-variant',
        text: 'Road Bridges',
        component: TransportMappingsRoadBridges
      },
      {
        icon: 'fa-solid fa-truck-container',
        text: 'Transport Operators',
        component: TransportMappingsTransportOperators
      }
    ],
    model: 0,
  }),
}
</script>

<style scoped>

</style>