import utils from '@/utils/vuexUtils.js'
import CMSApi from "@/services/CMSApi";
import transportPlannerData from "@/models/transportCapacity/transportPlannerData";
// initial state
const state = {
    // Date Range
    dateRangeSelected: null,
    datesObject: [],
    fromDate: null,
    toDate: null,

    // Switch
    switch: false,

    // Objects
    transportPlannerList: [],
    transportPlannerWagonList : [],
    transportLogisticCustomers: [],
    kiwiRailCustomers: [],

    // Misc
    equipmentTypes: [],
    commodities: [],
    roadBridgeCodes : [],
    transportOperators : [],
    bookingDates: [],

    // Loading indicators
    loadingTransportPlannerList: false,
    loadingTransportPlannerWagonList: false,
    loadingTransportLogisticCustomers: false,
    loadingKiwiRailCustomers: false,
    loadingEquipmentTypes: false,
    loadingCommodities: false,
    loadingRoadBridgeCodes: false,
    loadingOperators: false,
    loadingBookingDates: false,

    // Working Objects
    workingPlannerBooking: new transportPlannerData.PlannerDetails(),

    // Forms
    customerDetailsForm : false,
    kiwiRailDetailsForm : false,
    containerDetailsForm : false,

    // Indicators
    formFromMaster : false,
}

// getters
const getters = {
    customerFormValidity: state => {
        return state.customerDetailsForm;
    },

    kiwiRailFormValidity: state => {
        return state.kiwiRailDetailsForm;
    },

    containerFormValidity: state => {
        return state.containerDetailsForm;
    },

    getCustomerName : (state) => (id) => {
        return state.transportLogisticCustomers.length > 0 && id !== undefined ? state.transportLogisticCustomers.find(g => g.companyId === id).name : '';
    },

    getKiwiRailLocationName : (state) => (id) => {
        return state.kiwiRailCustomers.length > 0 && id !== undefined ? state.kiwiRailCustomers.find(g => g.companyId === id).name : '';
    },

    getDatesOnly( state ) {
        let newArr =  state.bookingDates.map(function(i) { return i.formattedDate}).filter(function (value, index, array) {
           return array.indexOf(value) === index;
        });

        return newArr.sort();
    },

    getBookingDateInstanceId: (state) => (date) => {
        return state.bookingDates.find(({formattedDate}) => formattedDate === date)["trainServiceInstanceId"];
    },

    getRoadBridgeCode: (state) => (id) => {
        if (state.roadBridgeCodes.length > 0 && id !== undefined)
        {
            let selectedRoadBridge = state.roadBridgeCodes.find(g => g.transportServiceRoadBridgeId === parseInt(id) );
            return selectedRoadBridge.roadBridgeCode;
        }
        return '';
    },
}

// actions
const actions = {
    initFetch : ({commit, state}) => {
        let fromDate = window.Moment().format('YYYY-MM-DD');
        let toDate = window.Moment().add(6,'days').format('YYYY-MM-DD');
        state.fromDate = fromDate;
        state.toDate = toDate;
        state.datesObject = [fromDate,toDate];
        state.dateRangeSelected = state.datesObject.sort().join('~');

        state.loadingTransportPlannerList = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchPlannerListByDates(fromDate, toDate), 'setPlannerList');
    },

    fetchPlannerListByDates : ({commit, state}, data) => {
        state.loadingTransportPlannerList = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchPlannerListByDates(data.fromDate, data.toDate), 'setPlannerList');
    },

    fetchPlannerWagonListByDates : ({commit, state}, data) => {
        state.loadingTransportPlannerWagonList = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchPlannerWagonListByDates(data.fromDate, data.toDate), 'setPlannerWagonList');
    },

    fetchPlannerWagonListByInstance : ({commit, state}, id) => {
        state.loadingTransportPlannerWagonList = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchPlannerWagonListByInstance(id), 'setPlannerWagonList');
    },

    fetchKiwiRailCustomers: ({ commit, state}) => {
        state.loadingKiwiRailCustomers = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchKiwiRailCustomers(), 'setKiwiRailCustomers');
    },

    fetchTransportServiceLogisticCustomers: ({ commit, state}) => {
        state.loadingTransportLogisticCustomers = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchTransportServiceLogisticCustomers(), 'setTransportServiceLogisticCustomers');
    },

    fetchTransportPlannerEquipmentTypes: ({commit, state}) => {
        state.loadingEquipmentTypes = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchTransportPlannerEquipmentTypes(), 'setTransportPlannerEquipmentTypes');
    },

    fetchTransportPlannerCommodities: ({commit, state}) => {
        state.loadingCommodities = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchTransportPlannerCommodities(), 'setTransportPlannerCommodities');
    },

    fetchRoadBridgeCodes : ({commit,state}) => {
        state.loadingRoadBridgeCodes = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchRoadBridges(), 'setRoadBridgeCodes')
    },

    fetchTransportOperators : ({commit, state}) => {
      state.loadingOperators = true;
      return utils.CommitArrayPromise(commit, CMSApi.fetchTransportPlannerTransportOperators(), 'setTransportOperators')
    },

    fetchBookingDates : ({commit, state}, id) => {
        state.loadingBookingDates = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchAvailableBookingDates(id), 'setBookingDates')
    },

    initNewWorkingPlanner: ({commit}) => {
        let newWorkingPlanner = new transportPlannerData.PlannerDetails();
        commit('setCurrentWorkingPlannerBooking', newWorkingPlanner);
    },

    setWorkingPlannerDataForEdit: ({commit}, flattenedAdviceData) => {
        let workPlanner = new transportPlannerData.PlannerDetails();

        workPlanner.setFromFlattenedData(flattenedAdviceData);
        commit('setCurrentWorkingPlannerBooking', workPlanner);
    },

    resetWorkingPlannerBooking: ({commit}) => {
        let newWorkingPlanner = new transportPlannerData.PlannerDetails();
        commit('setCurrentWorkingPlannerBooking', newWorkingPlanner);
    },
}

// mutations
const mutations = {
    setPlannerList(state, transportPlannerList) {
        state.transportPlannerList = transportPlannerList;
        state.loadingTransportPlannerList = false;
    },

    resetPlannerForm(state) {
        state.customerDetailsForm = false;
        state.containerDetailsForm = false;
        state.kiwiRailDetailsForm = false;
    },

    setPlannerWagonList(state, transportPlannerWagonList) {
        state.transportPlannerWagonList = transportPlannerWagonList;
        state.loadingTransportPlannerWagonList = false;
    },

    setKiwiRailCustomers(state, kiwiRailCustomers)  {
        state.kiwiRailCustomers = kiwiRailCustomers;
        state.loadingKiwiRailCustomers = false;
    },

    setTransportServiceLogisticCustomers(state, transportLogisticCustomers)  {
        state.transportLogisticCustomers = transportLogisticCustomers;
        state.loadingTransportLogisticCustomers = false;
    },

    setTransportPlannerEquipmentTypes(state, equipmentTypes) {
        state.equipmentTypes = equipmentTypes;
        state.loadingEquipmentTypes = false;
    },

    setTransportPlannerCommodities(state, commodities) {
        state.commodities = commodities;
        state.loadingCommodities = false;
    },

    setCurrentWorkingPlannerBooking( state, workingPlannerBooking) {
        state.workingPlannerBooking = workingPlannerBooking;
    },

    setRoadBridgeCodes(state, roadBridgeCodes) {
        state.roadBridgeCodes = roadBridgeCodes;
        state.loadingRoadBridgeCodes = false;
    },

    setFormStatus(state, {formName, value}) {
        state[formName] = value;
    },

    setTransportOperators(state, transportOperators) {
        state.transportOperators = transportOperators;
        state.loadingOperators = false;
    },

    setBookingDates(state, bookingDates) {
        bookingDates.forEach(function (element){
            element.formattedDate = window.Moment(new Date(element.instanceDate)).format('YYYY-MM-DD');
        })

        state.bookingDates = bookingDates;
        state.loadingBookingDates = false;
    },

    setDatesObject(state, datesObject) {
        state.datesObject = datesObject;
    },

    setDateRangeSelected(state, dateRangeSelected) {
        state.dateRangeSelected = dateRangeSelected;
    },

    setFromDate(state, fromDate) {
        state.fromDate = fromDate;
    },

    setToDate(state, toDate) {
        state.toDate = toDate;
    },

    setFormFromMaster(state, formFromMaster) {
        state.formFromMaster = formFromMaster;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}