<template>    
    <v-alert text outlined dense color="error" icon="mdi-alert-circle-outline" v-show="validationErrorList.length > 0" >
        <div v-if="introText">{{introText}}</div>
        <div v-if="validationErrorList.length == 1">
            {{ this.validationErrorList[0] }}
        </div>
        <div v-else>
            <span v-if="!introText">Please fix the following issues:<br/></span>
            <ul>
                <li v-for="(item, index) in this.validationErrorList" v-bind:key="index">
                    {{ item }}
                </li>
            </ul>
        </div>        
    </v-alert>
</template>

<script>

export default {
    props: {
        introText: { default: '', type: String },
        validationErrors: Object,
        showGeneralErrorsOnly: { default: false, type: Boolean },
        showExceptions: { default: true, type: Boolean }
    },

    computed: {
        validationErrorList() {
            let errorList = [];

            for (var key in this.validationErrors) {
                if(!this.showExceptions && key == "Exception")
                    continue;

                if(this.showGeneralErrorsOnly && !key == '')
                    continue;

                for (var e in this.validationErrors[key]) {
                    errorList.push(this.validationErrors[key][e]);
                }
            }

            return errorList;
        }
    },
};

</script>

<style>   
    .v-alert {
        font-size: 14px;
    }
</style>