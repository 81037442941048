<template>
    <div>
        <div v-if="readOnly || locked">                            
            <v-icon v-if="locked" small class="groupCellLockedIcon">mdi-lock</v-icon> {{containerGroupDescription}}
        </div>
        <div v-else>
            <v-menu
                v-model="showMenu"
                :close-on-click="true"
                :close-on-content-click="false"
                :min-width="$vuetify.breakpoint.smAndUp && this.importBlockGroups.length > 0 ? '450' : '0'"
                offset-y allow-overflow
                class="vmenuDatagridFix"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small tile color="secondary"
                        :outlined="containerGroupIsSet"
                        :loading="savingChanges"
                        v-bind="attrs" v-on="on"
                        v-bind:class="{ 'text-none': containerGroupIsSet}"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                        <span>{{containerGroupDescription}}</span>
                    </v-btn>
                </template>

                <v-card>
                    <v-card-text>
                        <v-form ref="importGroupSingleSelectForm" lazy-validation>
                            <v-container class="shortColumn">
                                <v-row>
                                    <v-col cols="12" :sm="this.importBlockGroups.length > 0 ? '6' : '12'" class="shortColumn">
                                            <v-container >
                                                Day Groups:
                                                <v-radio-group v-model="selectedGroup" hide-details @mouseup="groupSelected" @change="groupChanged">
                                                <v-radio
                                                    v-for="(item, index) in this.importDayGroupOptions" :key="item.groupId"
                                                    :label="item.description" :value="item.groupId"
                                                    :class="isDistinctDayOption(index) ? 'groupDayBlock' : ''">
                                                </v-radio>
                                                </v-radio-group>
                                            </v-container>
                                    </v-col>
                                    <v-col cols="12" :sm="6" class="shortColumn" v-show="this.importBlockGroups.length > 0">
                                            <v-container >
                                                Block Groups:
                                                <v-radio-group v-model="selectedGroup" hide-details @mouseup="groupSelected" @change="groupChanged">
                                                <v-radio
                                                    v-for="(item) in this.importBlockGroups" :key="item.groupId"
                                                    :label="item.description" :value="item.groupId">
                                                </v-radio>
                                                </v-radio-group>
                                            </v-container>
                                    </v-col>
                                </v-row>
                                <v-row id="removeGroupRow" v-show="containerGroupIsSet">
                                    <v-col cols="12" class="shortColumn">
                                            <v-container id="removeGroupSection">
                                                <v-radio-group v-model="selectedGroup" hide-details @mouseup="groupSelected" @change="groupChanged">
                                                <v-radio
                                                    :key="ImportGroupCommand.RemoveGroup"
                                                    label="Remove Day/Group" :value="ImportGroupCommand.RemoveGroup">
                                                </v-radio>
                                                </v-radio-group>
                                            </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-menu>
        </div>
    </div>
</template>

<script>
    import {importAdviceHelpers} from '@/store/modules/importAdvice.js';
    import {ImportGroupCommand} from '@/utils/constants.js';
    import { mapState, mapActions, mapGetters } from 'vuex';

    export default {
        props: {
            importContainer: Object,
            locked: Boolean,
            readOnly: Boolean,
        },

        data () {
            return {
                selectedGroup: '',
                showMenu: false,
                savingChanges: false,
            }
        },

        computed: {
            ...mapState('importAdvice', [
                'importContainers',
            ]),
            
            ...mapGetters('importAdvice', [
                'importDayGroupOptions',
            ]),

            containerGroup() {
                if(this.importContainer)
                    return this.importContainer.group
                
                return "";
            },

            containerGroupDescription() {
                return importAdviceHelpers.getGroupDescription(this.containerGroup);
            },

            containerGroupIsSet() {
                return !importAdviceHelpers.isNoGroup(this.containerGroup);
            },

            importBlockGroups() {
                //block stacks aren't an option if the container is already in the yard
                if (this.importContainer.transitState == 'Yard')
                    return [];

                return importAdviceHelpers.importBlockGroupOptionsForContainers(this.importContainers, [this.importContainer]);
            },
            
        },

        created() {
            this.ImportGroupCommand = ImportGroupCommand;
        },

        mounted() {
            this.setDefaultGroup();
        },

        methods: {
            ...mapActions('importAdvice', [
                'addOrUpdateImportContainer',
                'deselectContainers'
            ]),

            setDefaultGroup() {
                //need to set selected group to that of the container
                this.selectedGroup = this.containerGroup;
            },

            groupSelected() {
                this.showMenu = false;
            },

            groupChanged() {
                if (this.selectedGroup != this.containerGroup) {
                    App.$appAnalytics.trackEvent('IA - Individual Container Set Group - Save Click');         
                    this.savingChanges = true;
                    CMSApi.adviseImportContainers([this.importContainer.containerNumber], this.selectedGroup)
                        .then(data => {
                            //need to check if returned result successful too
                            let result = data[0];
                            if (result && result.success) {
                                window.App.$emit('show-snackbar', "success", "Container succesfully advised");
                                this.addOrUpdateImportContainer(result.container);
                                this.deselectContainers([result.container.containerNumber]);
                            }
                            else 
                                this.showError(result ? result.failureReason : "Unable to advise container");
                        })
                        .catch(error => {
                            let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                            this.showError(errorMessage);
                        })
                        .finally(() => {
                            this.setDefaultGroup();
                            this.savingChanges = false;
                        });
                }
            },

            showError(message) {
                this.$root.$alert.show('Unable to advise container', message);
            },

            isDistinctDayOption(index) {
                //if first option or less than 8 options total, then we do not want to further separate day options into AM/PM groupings
                if (index == 0 || this.importDayGroupOptions.length <=7)
                    return false;
                
                //check if a new day (compared to previous item), and if so mark as being a new distinct day option
                return this.importDayGroupOptions[index].dayOfWeek != this.importDayGroupOptions[index-1].dayOfWeek;
            },
        }
    };
</script>

<style scoped>
    .v-radio {
        margin-bottom: 4px !important;
    }

    .groupDayBlock {
        padding-top:12px;
    }

    .shortColumn {
        padding-top: 0;
        padding-bottom: 6px;
    }

    #removeGroupRow {
        border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    #removeGroupSection {
        padding-top: 0; 
        padding-bottom: 0;
    }

    .vmenuDatagridFix {
        /* devextreme datagrid's don't correctly calculate the column width for v-menus dy default */
        display: inline;
    }
    
</style>