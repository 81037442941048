<template>
  <div>
      <v-container>
          <v-row>
              <v-col cols="12">
                  <v-form ref="customerForm">
                      <v-row dense>
                          <v-col cols="1">
                              <v-checkbox
                                      label="Forwarder?"
                                      v-model="isForwarder"
                                      dense
                              >
                              </v-checkbox>
                          </v-col>
                      </v-row>
                      <v-row dense>
                          <v-col cols="6" v-if="isForwarder">
                              <v-row>
                                  <v-col cols="6">
                                      <v-autocomplete
                                          v-model="customerData.fwCompanyId"
                                          :items="freightForwarders"
                                          item-text="name"
                                          item-value="companyId"
                                          label="Forwarder"
                                          clearable
                                          tabindex="2"
                                          :rules="customerDetails.forwarderCustomerRules"
                                          dense
                                          @change="fetchFreightCustomers(customerData.fwCompanyId)"
                                      ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="6">
                                      <v-autocomplete
                                          v-model="customerData.companyId"
                                          :items="freightForwarderCustomers"
                                          :item-text="getCompanyName"
                                          item-value="companyId"
                                          label="Customer"
                                          clearable
                                          tabindex="2"
                                          :rules="customerDetails.senderCustomerRules"
                                          dense
                                      ></v-autocomplete>
                                  </v-col>
                              </v-row>
                          </v-col>
                          <v-col cols="3" v-else>
                              <v-autocomplete
                                  v-model="customerData.companyId"
                                  :items="customers"
                                  item-text="name"
                                  item-value="companyId"
                                  label="Customer"
                                  clearable
                                  tabindex="2"
                                  :rules="customerDetails.senderCustomerRules"
                                  dense
                              ></v-autocomplete>
                          </v-col>
                      </v-row>
                  </v-form>
              </v-col>
          </v-row>
      </v-container>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import generalHelpers from "@/utils/generalHelpers";

export default {
    name: "TransportPlannerForm-CustomerDetails",

    mixins: [generalHelpers],

    data() {
        return {
            customerDetails: {
                emailAddress: null,
                contactPerson: null,
                contactNumber: null,
                address: null,
                senderCustomerRules: [
                    v => !!v || 'Customer is required',
                ],
                forwarderCustomerRules: [
                    v => !!v || 'Forwarder is required',
                ]
            },

            isForwarder: false,

            // Form
            formIsValid: false,
        }
    },

    computed : {
        ...mapState('transportPlanner', [
            'transportLogisticCustomers',
            'workingPlannerBooking'
        ]),

        ...mapState('transportMappings', [
            'freightForwarders',
            'freightForwarderCustomers'
        ]),

        customerData() {
            return this.workingPlannerBooking.customerDetails;
        },

        customers() {
            return this.transportLogisticCustomers;
        },

        isUpdate() {
            return this.hasNonEmptyValue(this.workingPlannerBooking.trainServicePlannerBookingId);
        }
    },

    mounted() {
        this.fetchTransportServiceLogisticCustomers();
        this.fetchFreightForwarders();

        if (this.isUpdate && this.customerData.fwCompanyId !== null) {
            this.isForwarder = true;
            this.fetchFreightForwarderCustomers(this.customerData.fwCompanyId);
        }
    },

    watch : {
        'customerData.companyId'(val) {
            if (!val) {
                if (!this.isForwarder)
                    this.$refs.customerForm.reset();
            }
            this.checkForm();
        },

        'customerData.fwCompanyId'(val) {
            if (!val) {
                this.$refs.customerForm.reset();
            }
            this.checkForm();
        },

        isForwarder() {
            this.checkForm();
            if (!this.isForwarder)
            {
                this.customerData.fwCompanyId = null;
            }
        },

        formIsValid(val) {
            this.setFormStatus({formName: 'customerDetailsForm', value: val});
        }
    },

    methods: {
        ...mapActions('transportPlanner', [
            'fetchTransportServiceLogisticCustomers'
        ]),

        ...mapActions('transportMappings', [
            'fetchFreightForwarders',
            'fetchFreightForwarderCustomers'
        ]),

        ...mapMutations('transportPlanner', [
            'setFormStatus'
        ]),

        fetchFreightCustomers(id) {
            if (this.hasNonEmptyValue(id)) {
                this.fetchFreightForwarderCustomers(id);
            }
        },

        getCompanyName(e) {
            let company = this.customers.find(x => x.companyId === e.companyId);
            return company.name;
        },

        checkForm() {
            // Declare objects that are required
            let formData = this.isForwarder ?
                [
                    this.customerData.fwCompanyId,
                    this.customerData.companyId
                ]
                :
                [
                    this.customerData.companyId
                ];

            this.formIsValid =  !Object.values(formData).includes(null);
        }
    }
}
</script>