<template>
  <v-card elevation="3">
    <v-card-text>
      <v-row>
        <v-col v-if="isFormActive" :cols="getFormColumnSize($vuetify.breakpoint.name)">
          <v-form ref="wagonDetailForm" @submit.prevent>
            <v-row dense>
              <v-col cols="8">
                <v-select
                    v-model="originId"
                    ref="serviceOrigin"
                    :items="serviceOrigins"
                    :item-text="(row) => {return row.description +'  ( '+(row.workstationCode) +' )' }"
                    item-value="transportServiceTerminalSiteId"
                    label="Route Origin"
                    :disabled="disableOrigin"
                    :rules="routeOriginRules"
                    tabindex="1"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-btn
                    color="secondary"
                    tile
                    @click="isFormActive = false"
                    :disabled="listCount < 1"
                >
                  <v-icon>mdi-arrow-collapse-left</v-icon>
                  Hide
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="8">
                <v-select
                    v-model="destinationId"
                    ref="serviceDestination"
                    :items="serviceDestinations"
                    :item-text="(row) => {return row.description +'  ( '+(row.workstationCode) +' )' }"
                    item-value="transportServiceTerminalSiteId"
                    label="Route Destination"
                    :disabled="disableDestination"
                    :rules="routeDestinationRules"
                    tabindex="2"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="8">
                <v-text-field
                    v-model.number="wagonCapacity"
                    label="Wagon Capacity (TEU)"
                    type="number"
                    step="any"
                    :rules="wagonCapacityRules"
                    tabindex="3"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-btn
                    color="secondary"
                    tile
                    @click.stop="isEditing ? update() : add()"
                    width="100%"
                >
                  {{ isEditing ? 'Update' : 'Add'}}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col :cols="getGridColumnSize($vuetify.breakpoint.name)">
          <v-row v-if="!isFormActive">
            <v-col cols="2">
              <v-btn
                  tile class="mb-2"
                  color="secondary"
                  @click="isFormActive = true"
              >
                <v-icon>mdi-plus</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp">Add</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div style="height: 350px">
                <v-card v-for="(item,index) in historyList" :key="index" style="margin-bottom: 5px" variant="tonal">
                <v-card-title>
                  <v-row>
                    <v-col lg="3" xl="4">
                      <v-icon>mdi-train-car-container</v-icon>  {{item.originCode}} {{ $vuetify.breakpoint.name === 'xl' ? '('+ getRouteReadableName(item.originTerminalSiteId) + ')' : '' }}
                    </v-col>
                    <v-col cols="1">--></v-col>
                    <v-col lg="3" xl="4">
                      {{item.destinationCode}} {{ $vuetify.breakpoint.name === 'xl' ? '('+ getRouteReadableName(item.destinationTerminalSiteId) + ')' : '' }}
                    </v-col>
                    <v-col cols="2">TEU({{item.capacityTeu}})</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1" lg="2" xl="1" v-if="item.hasBooking">
                        <v-tooltip
                                right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon large color="red"
                                        v-bind="attrs"
                                        v-on="on"
                                >mdi-alert-circle</v-icon>
                            </template>
                            <span>
                                Has Booking
                            </span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="1" lg="2" xl="1" v-else>
                      <v-icon color="primary" size="18" class="mr-2" @click="editWagon(index)">
                        mdi-pencil
                      </v-icon>
                      <v-icon color="primary" size="18" class="mr-2" @click="removeWagon(index)">
                        mdi-delete
                      </v-icon>
                    </v-col>

                  </v-row>
                </v-card-title>
              </v-card>
              </div>
              <v-pagination v-if="listCount > 0" class="pagination mb-2" v-model="page" :length="pages" @input="updatePage"></v-pagination>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div>
        <v-btn
            color="secondary"
            text
            @click="previousForm"
        >
          Prev
        </v-btn>
        <v-btn
            color="secondary"
            text
            :disabled="!saveEnabled"
            @click="saveService"
        >
          Save
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  name: "TransportServiceForm-Routes",

  data() {
    return {
      // Form
      isFormActive: true,
      isEditing: false,

      // Validation
      routeOriginRules : [
        v => !!v || 'Route Origin is required',
      ],
      routeDestinationRules : [
        v => !!v || 'Route Destination is required'
      ],
      wagonCapacityRules : [
        v => !!v || "This field is required",
        v => ( v && v >= 0 ) || "Capacity TEU should be above 0",
        v => ( v && v <= 2 ) || "Capacity TEU should not be above 2",
      ],

      // Form Fields
      originId: null,
      destinationId: null,
      wagonCapacity: null,
      wagonSequence: null,
      disableOrigin: false,
      disableDestination: false,
      workingWagonIndex: null,

      // Page
      page: 1,
      pageSize: 5,
      listCount: 0,
      historyList: [],

      // Objects
      submitErrors: {},
      submitErrorCounter : 0
    }
  },

  mounted() {
    this.fetchContainerTerminalSitesAndSidings();
    this.initPage();
  },

  computed: {
    ...mapState('trainService', [
        'workingTrainService'
    ]),

    ...mapGetters('trainService', [
        'serviceRoutesTerminalOrigins',
        'serviceRoutesTerminalDestinations',
        'getNextWagonSequence',
        'getRouteCode',
        'getRouteReadableName',
        'allFormsAreValid'
    ]),

    pages() {
      if (this.pageSize == null || this.listCount == null) return 0;
      return Math.ceil(this.listCount / this.pageSize);
    },

    tsDetailsData() {
      return this.workingTrainService.trainServiceDetails;
    },

    tsRoutesData() {
      return this.workingTrainService.trainServiceRoutes;
    },

    serviceOrigins() {
      return this.serviceRoutesTerminalOrigins;
    },

    serviceDestinations() {
      return this.serviceRoutesTerminalDestinations;
    },

    nextWagonSequence() {
      return this.getNextWagonSequence;
    },

    originCode() {
      return this.isNotEmptyOrNull(this.originId) ? this.getRouteCode(this.originId) : null;
    },

    destinationCode() {
      return this.isNotEmptyOrNull(this.destinationId) ? this.getRouteCode(this.destinationId) : null;
    },

    formIsValid() {
      return this.tsRoutesData.wagons.length > 0
    },

    saveEnabled() {
      return this.allFormsAreValid;
    },

    hasAddedRoutes() {
        return this.tsRoutesData.wagons.filter(g => g["trainServiceWagonId"] === null).length > 0;
    },

    hasChangedRoutes() {
        return this.tsRoutesData.wagons.filter(g => g['hasChanged']).length > 0;
    },

    hasRemoveRoutes() {
        return this.workingTrainService.trainServiceRoutesRemoved.wagons.length > 0;
    },

    hasChanges() {
        return this.hasAddedRoutes || this.hasChangedRoutes || this.hasRemoveRoutes;
    },
  },

  watch: {
    formIsValid(val) {
      this.setServiceRoutesForm(val);
    },

    hasChanges(val) {
        this.setTSRoutesChangeState(val);
    },

    serviceRoutesTerminalOrigins: {
      handler(val) {
        if (val.length === 1) {
          this.originId = val[0].transportServiceTerminalSiteId;
          this.disableOrigin = true;
        } else {
          this.originId = null;
          this.disableOrigin = false;
        }
      },
      deep: true
    },

    serviceRoutesTerminalDestinations: {
      handler(val) {
        if (val.length === 1) {
          this.destinationId = val[0].transportServiceTerminalSiteId;
          this.disableDestination = true;
        } else {
          this.destinationId = null;
          this.disableDestination = false;
        }
      }
    }
  },

  methods: {
    ...mapActions('trainService', [
        'fetchContainerTerminalSitesAndSidings',
        'resetWorkingTrainService'
    ]),

    ...mapMutations('trainService', [
        'pushRoutesData',
        'updateRoutesData',
        'removeWagonFromRoutes',
        'setServiceRoutesForm',
        'setTSRoutesChangeState'
    ]),

    previousForm() {
      this.$emit('previousForm');
    },

    // Button functions
    add() {
      if (this.$refs.wagonDetailForm.validate()) {
        let wagon = {
          trainServiceWagonId : null,
          wagonSequence : this.nextWagonSequence,
          originTerminalSiteId : this.originId,
          originCode : this.originCode,
          destinationTerminalSiteId : this.destinationId,
          destinationCode : this.destinationCode,
          capacityTeu: this.wagonCapacity,
          hasChanged: false,
          isActive: true
        };

        this.pushRoutesData(wagon);
        this.initPage();
        this.resetForm();
      }
    },

    update() {
      if (this.$refs.wagonDetailForm.validate()) {
        let wagon = {
          trainServiceWagonId: this.trainServiceWagonId,
          wagonSequence: this.wagonSequence,
          originTerminalSiteId: this.originId,
          originCode : this.originCode,
          destinationTerminalSiteId: this.destinationId,
          destinationCode : this.destinationCode,
          capacityTeu: this.wagonCapacity,
          hasChanged: true,
          isActive: true
        }

        let param = {wagonData: wagon , index: this.workingWagonIndex};
        this.updateRoutesData(param);
        this.initPage();
        this.resetForm();
      }
    },

    editWagon(index) {
      let wagonData = this.tsRoutesData.wagons[index];
      this.initEdit(wagonData,index);
    },

    removeWagon(index) {
      this.removeWagonFromRoutes(index);
      this.initPage();
    },

    initEdit(data, index) {
      this.isEditing = true;
      this.isFormActive = true;
      this.trainServiceWagonId = data.trainServiceWagonId;
      this.originId = data.originTerminalSiteId;
      this.destinationId = data.destinationTerminalSiteId;
      this.wagonCapacity = data.capacityTeu;
      this.workingWagonIndex = index;
      this.wagonSequence = data.wagonSequence;
    },

    initPage: function() {
      let _this = this;
      _this.listCount = _this.tsRoutesData.wagons.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.tsRoutesData.wagons;
      } else {
        _this.historyList = _this.tsRoutesData.wagons.slice(0, _this.pageSize);
      }
    },

    updatePage: function(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.tsRoutesData.wagons.slice(_start, _end);
      _this.page = pageIndex;
    },

    // Misc functions
    resetForm() {
      if (this.tsDetailsData.trainServiceDirection.toLowerCase() === 'outbound') {
        this.destinationId = null;
        this.wagonCapacity = null;
        this.$refs.wagonDetailForm.resetValidation();
        this.$refs.serviceDestination.focus();
      } else {
        this.originId = null;
        this.wagonCapacity = null;
        this.$refs.wagonDetailForm.resetValidation();
        this.$refs.serviceOrigin.focus();
      }
      this.wagonSequence = null;
      this.isEditing = false;
    },

    isNotEmptyOrNull(val) {
      return val !== null && val !== '';
    },

    // Grid Adaptability
    getGridColumnSize(size) {
      let colNumber;
      switch (size) {
        case 'lg':
          colNumber = this.isFormActive ? 8 : 12;
          break;
        case 'xl':
          colNumber = this.isFormActive ? 9 : 12;
          break;
      }
      return colNumber;
    },

    getFormColumnSize(size) {
      let colNumber;
      switch (size) {
        case 'lg':
          colNumber = this.isFormActive ? 4 : 0;
          break;
        case 'xl':
          colNumber = this.isFormActive ? 3 : 0;
          break;
      }
      return colNumber;
    },

    // SAVING AND UPDATE
    async saveService() {
      let postData = this.workingTrainService.convertToSubmittableService();

      if (!postData.trainServiceId || postData.trainServiceId === 0) {
        // Submit a new service
        CMSApi.postTrainService(postData)
            .then(() => {
              window.App.$emit('show-snackbar', "success", "Service created ! ");
              App["$appAnalytics"].trackEvent('Transport Capacity - Service Created');
              this.resetWorkingTrainService();
            })
            .catch(error => {
              window.App.$emit('show-snackbar', "error", "Service creation failed");
              this.submitErrors = error.response.data.errors;
            })
            .finally(() => {
              this.$emit('savingDone');
            });

      } else {
          if (postData.TSDetailsChanged || postData.TSDatesChanged || postData.TSDaysChanged || postData.TSRoutesChanged)
          {
              if (postData.TSDetailsChanged)
              {
                  let putData = {
                      trainServiceId : postData.trainServiceId,
                      trainServiceName : postData.trainServiceName,
                      trainServiceNotes : postData.trainServiceNotes,
                      trainServiceDirection : postData.trainServiceDirection,
                      isAdHoc : postData.isAdHoc,
                      isActive : postData.isActive
                  };

                  await CMSApi.putTrainServiceDetails(putData)
                      .then( data => {
                          if (data)
                              console.log('Updated Train Service Details');
                      })
                      .catch(() => {
                          this.submitErrorCounter++;
                      });
              }

              if (postData.TSDatesChanged)
              {
                  let putData = {
                      trainServiceId : postData.trainServiceId,
                      startDate : postData.startDate,
                      endDate : postData.endDate,
                      isOneDayService : postData.isOneDayService
                  };

                  await CMSApi.putTrainServiceDates(putData)
                      .then( data => {
                          if (data)
                              console.log('Updated Train Service Dates');
                      })
                      .catch(() => {
                          this.submitErrorCounter++;
                      });
              }

              if (postData.TSDaysChanged)
              {
                  let putData = {
                      trainServiceId : postData.trainServiceId,
                      days : postData.days
                  };

                  await CMSApi.putTrainServiceDays(putData)
                      .then( data => {
                          if (data)
                              console.log('Updated Train Service Days');
                      })
                      .catch(() => {
                          this.submitErrorCounter++;
                      });
              }

              if (postData.TSRoutesChanged)
              {
                  if (postData.wagons.filter(k => k.trainServiceWagonId === null).length > 0)
                  { // Newly added routes
                      let putData = {
                          trainServiceId : postData.trainServiceId,
                          wagons : postData.wagons.filter(k => k.trainServiceWagonId === null)
                      };

                      await CMSApi.postTrainServiceRoutes(putData)
                          .then( data => {
                              if (data)
                                  console.log('Added new Wagons');
                          })
                          .catch(() => {
                              this.submitErrorCounter++;
                          });
                  }

                  if (postData.wagons.filter(k => k.hasChanged).length > 0)
                  { // Modified Routes
                      let putData = {
                          trainServiceId : postData.trainServiceId,
                          wagons : postData.wagons.filter(k => k.hasChanged)
                      };

                      await CMSApi.putTrainServiceRoutes(putData)
                          .then( data => {
                              if (data)
                                  console.log('Updated Wagon Info');
                          })
                          .catch(() => {
                              this.submitErrorCounter++;
                          });
                  }

                  if (postData.TSRoutesRemoved.length > 0)
                  { // Remove Routes
                      let putData = {
                          trainServiceId : postData.trainServiceId,
                          wagons : postData.TSRoutesRemoved
                      };

                      await CMSApi.cancelTrainServiceRoutes(putData)
                          .then( data => {
                              if (data)
                                  console.log('Removed Wagons');
                          })
                          .catch(() => {
                              this.submitErrorCounter++;
                          });
                  }
              }

              if (this.submitErrorCounter > 0)
              {
                  window.App.$emit('show-snackbar', "error", "Error when updating Train Service");
              }
              else
              {
                  window.App.$emit('show-snackbar', "success", "Train Service updated");
                  await this.resetWorkingTrainService();
                  this.$emit('savingDone');
              }
          }
          else
          {
              window.App.$emit('show-snackbar', "error", "No changes detected");
          }
      }
    }
  }
}
</script>

<style scoped>

</style>