<template>
    <div>
      <v-row v-if="!showEditMode">
        <v-col :cols="listGridLength">
          <AppointmentAppointmentList
              readOnly
              ref="companyAppointmentsList"
              id="appointmentList"
              portAdminView
              bookingPanelDisplayed
              panelExpanded
              title="Company Appointments"
              :showSlots="showSlots"
              :showForm="showForm"
              @showSlot="showSlots = true"
              @hideSlot="showSlots = false"
              @showForm="showAppointmentForm($event)"
          />
        </v-col>
        <v-col :cols="slotGridLength" v-if="showSlots">
          <AppointmentZoneSlotAvailability
              readOnly
              portAdminView
              id="zoneSlotAvailability"
              ref="bookingPanel"
              @hideSlot="hideSlot"
              @showEditForm="showEditForm($event)"
          />
        </v-col>
        <v-col :cols="formGridLength" v-if="showForm">
          <AppointmentForm
              id="appointmentForm"
              ref="formPanel"
              @hideForm="hideForm"
              @appointmentUpdated="appointmentUpdated"
              @appointmentCreated="appointmentCreated"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <gate-zone-slot-quick-book-modal
              ref="zoneSlotModal"
              :key="`${componentKey}=1`"
              @hideEditForm="hideEditForm"
          ></gate-zone-slot-quick-book-modal>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import AppointmentZoneSlotAvailability from "@/components/appointment/Appointment-ZoneSlotAvailability.vue";
import AppointmentAppointmentList from "@/components/appointment/Appointment-AppointmentList.vue";
import AppointmentForm from "@/components/appointment/Appointment-Form.vue";
import {mapActions} from "vuex";
import GateZoneSlotQuickBookModal from "@/components/portAdmin/gate/GateZoneSlotQuickBookModal.vue";

export default {
  name: 'appointments',

  components: {
    GateZoneSlotQuickBookModal,
    AppointmentForm,
    AppointmentZoneSlotAvailability,
    AppointmentAppointmentList
  },

  data() {
    return {
      showSlots : true,
      showForm : false,
      showEditMode : false,

      // props
      selectedDate : null,

      //ComponentKey
      componentKey: 0,
      loadedComponentCounter: 0,
    }
  },

  computed : {
    listGridLength() {
      let length;
      switch (true) {
        case this.showForm :
            length = 6;
          break;
        case this.showSlots :
            length = 9;
          break;
        case this.showForm && this.showSlots :
            length = 12;
          break;
      }
      return length;
    },

    slotGridLength() {
      return this.showSlots ? 3 : 0;
    },

    formGridLength() {
      return this.showForm ? 6 : 0;
    },
  },

  methods : {
    ...mapActions('appointments', [
        'resetWorkingAppointment'
    ]),

    hideSlot() {
      this.showSlots = false;
    },

    hideForm() {
      this.showForm = false;
      this.showSlots = !this.$refs.companyAppointmentsList.showAdminButtons;
      this.resetWorkingAppointment();
    },

    showAppointmentForm(edit) { // eslint-disable-line no-unused-vars
      this.showForm = true; // Show Form
      this.showSlots = false; // Hide Slots
    },

    async showEditForm(date) {
      if (this.loadedComponentCounter > 0) {
        this.componentKey += 1;
      }
      this.loadedComponentCounter += 1;
      this.showEditMode = true;
      await this.$nextTick(() => {
        this.$refs.zoneSlotModal.init(date);
      });
    },

    hideEditForm() {
      this.selectedDate = null;
      this.showEditMode = false;
    },

    appointmentUpdated() {
      this.showForm = false; // Hide Form
      this.showSlots = true; // Show Slots
      this.refreshList();
    },

    appointmentCreated() {
        this.showForm = false; // Hide Form
        this.showSlots = true; // Show Slots
        this.refreshList();
    },

    refreshList() {
      this.$refs.companyAppointmentsList.fetchAppointments();
    }
  }
}
</script>