// Define some constructors for the objects to be used in a new Export Pre-Advice.
import generalHelpers from '@/utils/generalHelpers.js'

class ContainerDetails {
    constructor() {
        this.containerNumber = '';
        this.containerNumberIsNewForN4 = false;
        this.equipmentTypeIsoId = '';
        this.vgmWeight = null;
        this.vgmVerifier = '';
        this.sealNumbers = [];
        this.transporterId = '';
        this.transitState = '';
        this.lineOperator = '';
    }
}

class BookingDetails {
    constructor() {
        this.bookingNumber = '';
        this.n4BookingKey = null;
        this.vesselVisit = '-';
        this.vesselEtd = null;
        this.lineOperator = '-';
        this.portOfLoad = '-';
        this.portOfDischarge = '-';
        this.secondPortOfDischarge = '-';
        this.isHazardous = false;
        this.agent = '';
    }
}

class FreightDetails {
    constructor() {
        this.packPoint = null;
        this.commodityCode = null;
        this.shipperId = null;
        this.shipperName = '';
        this.shipperIdAndName = '';
        this.shippersReference = null;
    }
}

class AdditionalContainerDetails {
    constructor() {
        this.isReefer = false;
        this.reeferData = {
            temperature: null,
            ventilation: null,
            ventilationUnit: null,
            humidity: null
        };
        this.isOversize = false;
        this.oversizeData = {
            top: null,
            front: null,
            back: null,
            left: null,
            right: null
        }
        this.isHazardous = false;
        this.hazardData = {
            imdgClass: null,
            undgNumber: null
        }
    }
}

export default {
    PreAdviceDetails: function () {
        this.exportPreAdviceId = null;
        this.containerDetails = new ContainerDetails();
        this.bookingDetails = new BookingDetails();
        this.freightDetails = new FreightDetails();
        this.additionalContainerDetails = new AdditionalContainerDetails();
        
        this.convertToSubmittableAdvice = function() {
            let sealNumbers = [];
            for (let i = 0; i < this.containerDetails.sealNumbers.length; ++i) {
                sealNumbers.push(this.containerDetails.sealNumbers[i]);
            }
            
            // flatten it all out
            return {
                ExportPreAdviceId: this.exportPreAdviceId,
                
                ContainerNumber: this.containerDetails.containerNumber,
                IsoType: this.containerDetails.equipmentTypeIsoId,
                VgmWeight: this.getNumberValueOrNull(this.containerDetails.vgmWeight),
                VgmVerifier: this.containerDetails.vgmVerifier,
                SealNumbers: sealNumbers,
                TransportCompanyId: this.containerDetails.transporterId,

                BookingNumber: this.bookingDetails.bookingNumber,
                N4BookingKey: this.bookingDetails.n4BookingKey,
                VesselVisit: this.bookingDetails.vesselVisit,
                VesselEtd: this.bookingDetails.vesselEtd,
                LineOperatorCode: this.bookingDetails.lineOperator,
                
                PackPoint: this.freightDetails.packPoint.id,
                CommodityCode: this.freightDetails.commodityCode,
                ShipperCode: this.freightDetails.shipperId,
                ShipperName: this.freightDetails.shipperName,
                ShippersReference: this.freightDetails.shippersReference,
                
                IsReefer: this.additionalContainerDetails.isReefer,
                Temperature: this.getNumberValueOrNull(this.additionalContainerDetails.reeferData.temperature),
                Ventilation: this.getNumberValueOrNull(this.additionalContainerDetails.reeferData.ventilation),
                VentilationUnit: this.additionalContainerDetails.reeferData.ventilationUnit,
                Humidity: this.getNumberValueOrNull(this.additionalContainerDetails.reeferData.humidity),
                
                IsOversize: this.additionalContainerDetails.isOversize,
                OversizeTop: this.getNumberValueOrNull(this.additionalContainerDetails.oversizeData.top),
                OversizeFront: this.getNumberValueOrNull(this.additionalContainerDetails.oversizeData.front),
                OversizeBack: this.getNumberValueOrNull(this.additionalContainerDetails.oversizeData.back),
                OversizeLeft: this.getNumberValueOrNull(this.additionalContainerDetails.oversizeData.left),
                OversizeRight: this.getNumberValueOrNull(this.additionalContainerDetails.oversizeData.right),
                
                IsHazardous: this.additionalContainerDetails.isHazardous,
                ImdgClass: this.additionalContainerDetails.hazardData.imdgClass,
                UndgNumber: this.additionalContainerDetails.hazardData.undgNumber,
                Agent : this.bookingDetails.agent
            }
        }
        
        this.setFromFlattenedData = function(flattenedData) {
            this.exportPreAdviceId = flattenedData.exportPreAdviceId;
            
            this.containerDetails.containerNumber = flattenedData.containerNumber;
            this.containerDetails.equipmentTypeIsoId = flattenedData.isoType;
            this.containerDetails.vgmWeight = flattenedData.vgmWeight;
            this.containerDetails.vgmVerifier = flattenedData.vgmVerifier;
            this.containerDetails.sealNumbers = this.containerDetails.sealNumbers.splice(0);
            for (let i = 0; i < flattenedData.sealNumbers.length; ++i) {
                this.containerDetails.sealNumbers.push(flattenedData.sealNumbers[i]);
            }
            this.containerDetails.transporterId = flattenedData.transportOperator;
            this.containerDetails.transitState = flattenedData.transitState;
            
            this.bookingDetails.bookingNumber = flattenedData.bookingNumber;
            this.bookingDetails.n4BookingKey = flattenedData.n4BookingKey;
            this.bookingDetails.vesselVisit = flattenedData.vesselVisit;
            this.bookingDetails.vesselEtd = flattenedData.etd;
            this.bookingDetails.lineOperator = flattenedData.lineOperator;
            this.bookingDetails.portOfLoad = flattenedData.portOfLoad;
            this.bookingDetails.portOfDischarge = flattenedData.poD;
            this.bookingDetails.secondPortOfDischarge = flattenedData.secondPoD;
            
            this.freightDetails.packPoint = { id: flattenedData.packPoint, name: flattenedData.packPoint };
            this.freightDetails.commodityCode = flattenedData.commodityCode;
            this.freightDetails.shipperId = flattenedData.shipperId;
            this.freightDetails.shipperName = flattenedData.shipperName;
            this.freightDetails.shipperIdAndName = flattenedData.shipperId + " (" + flattenedData.shipperName + ")";
            this.freightDetails.shippersReference = flattenedData.shippersReference;
            
            this.additionalContainerDetails.isReefer = flattenedData.isReefer;
            this.additionalContainerDetails.reeferData = {
                temperature: flattenedData.temperature,
                ventilation: flattenedData.ventSetting,
                ventilationUnit: flattenedData.ventUnit
            }
            
            this.additionalContainerDetails.isOversize = flattenedData.isOversize;
            this.additionalContainerDetails.oversizeData = {
                top: flattenedData.oversizeTop,
                front: flattenedData.oversizeFront,
                back: flattenedData.oversizeBack,
                right: flattenedData.oversizeRight,
                left: flattenedData.oversizeLeft
            };
            
            this.additionalContainerDetails.isHazardous = flattenedData.isHazardous;
            this.additionalContainerDetails.hazardData = {
                imdgClass: flattenedData.imdgClass,
                undgNumber: flattenedData.undgNumber
            }
        }
        
        this.getNumberValueOrNull = function(val) {
            if (!generalHelpers.methods.hasNonEmptyValue(val)) {
                return null;
            } else {
                let result = Number(val);
                if (isNaN(result)) {
                    return null;
                } else {
                    return result;
                }
            } 
        }
    }
}