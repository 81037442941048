<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="7">
            <v-menu
                v-model="appointmentDateMenu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="computedSelectedDateFormatted"
                    label="Appointment Date"
                    readonly
                    :rules="[v => !!v || 'Date is required']"
                    v-on="on"
                    required
                    :error-messages="validationErrors['AppointmentDate']"
                    :hide-details="!validationErrors['AppointmentDate']"
                    autocomplete="off"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="selectedDate"
                  style="width:100% !important"
                  @input="appointmentDateMenu = false"
                  :allowed-dates="allowedDates"
                  @change="fetchZoneSlots"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-container class="datatableControls">
              <v-btn
                  tile
                  color="secondary"
                  @click="$emit('hideSlot')"
                  class="float-right"
                  title="Hide this"
                  style="margin-top: 10px"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
        <v-row v-if="userHasRole(userRoles.PortAdmin) && !readOnly">
          <v-col cols="12">
            <v-select
                v-model="selectedCompany"
                :items="companies"
                item-text="name"
                item-value="n4TransportCompanyId"
                label="Company (optional)"
                :loading="loadingCompanies"
                :error-messages="validationErrors['CompanyId']"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-form ref="form" lazy-validation class="text-right" v-if="!readOnly">
              <v-container id="bulkBookContainer">
                <v-row>
                  <v-col cols="4">
                    <v-select
                        v-model="gate"
                        :items="activeGates"
                        :item-text="'gateName'"
                        :item-value="'gateId'"
                        :rules="[v => !!v || 'Gate is required']"
                        label="Gate"
                        required
                        :error-messages="validationErrors['gate']"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                        v-model="zone"
                        :items="slotZones"
                        item-text="zone"
                        :rules="[v => !!v || 'Zone is required']"
                        label="Zone"
                        required
                        no-data-text="No zones available"
                        :error-messages="validationErrors['zone']"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        v-model="quantity"
                        label="Quantity"
                        :rules="[
                                  v => !!v || 'Quantity is required',
                                  v => v <= maxQtyToSelect || 'Quantity cannot be more than the available slot',
                                  v => v > 0 || 'Quantity should not be equal to 0']"
                        required
                        :error-messages="validationErrors['quantity']"
                        type="number"
                        autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="4">
                    <v-btn
                        tile
                        color="secondary"
                        justify="start"
                        @click="addBulkItems"
                        :loading="addBulkItemsLoadingIndicator"
                    >
                      Book
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <p></p>
    <v-card>
      <v-card-title>
        Quick book:
        <v-spacer></v-spacer>
        <v-switch
            v-if="readOnly"
            color="secondary"
            v-model="editSlotMode"
            :label="`Edit Mode`"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <DxDataGrid
            :ref="slotGridRefKey"
            :data-source="slotAvailability"
            key-expr="zone"
        >
          <DxColumn v-for="(column, index) in slotColumns" :key="index"
                    :caption="column.text"
                    :data-type="column.useTemplate ? '' : column.type"
                    :data-field="column.value"
                    :allowSearch="column.allowSearch"
                    :cell-template="column.useTemplate ? column.cellTemplate : ''"
                    :alignment="column.align"
                    :visible="column.text === 'RB' ? hasRoadBridge : true"
          ></DxColumn>

          <template #slotTemplate="{data}">
            <div>
              {{ data.value }}
              <v-btn
                  v-if="!readOnly && data.value > 0"
                  text
                  icon
                  @click="quickBookSingleAppointment(selectedDate, data.data.zone, data.column.dataField, $event.target)"
              >
                <v-icon
                    color="primary"
                >
                  mdi-calendar-plus
                </v-icon>
              </v-btn>
            </div>
          </template>
        </DxDataGrid>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import {
  DxDataGrid,
  DxColumn
} from "devextreme-vue/data-grid";
import UserRoles from "@/auth/authUserRoles";
import {getInstance} from "@/auth";
import store from "@/store";

export default {
  name: "Appointment-ZoneSlotAvailability",

  components : {
    DxDataGrid,
    DxColumn
  },

  props: {
    readOnly: Boolean,
    portAdminView : Boolean
  },

  data() {
    return {
      // Grid Config
      slotGridRefKey: 'data-grid',

      // Validations
      validationErrors: {},

      // Datepicker
      appointmentDateMenu: false,
      selectedDate: window.Moment().format('YYYY-MM-DD'),

      // Indicator
      addBulkItemsLoadingIndicator: false,
      editSlotMode: false,

      // Form Fields
      gate: null,
      zone: null,
      quantity: null,
      selectedCompany : '',
      isPropelAppointment: null,

      // Objects
      slotZones: [],
    }
  },

  computed: {
    ...mapState('gateAppointments', [
      'gates',
      'slotAvailability'
    ]),

    ...mapState('appointments', [
      'companies',
      'loadingCompanies'
    ]),

    activeGates() {
      if (this.hasRoadBridge)
      {
          return this.gates;
      } else {
          return this.gates.filter( g => g.gateId !== 'RB');
      }
    },

    computedSelectedDateFormatted () {
      return window.App.formatDate(this.selectedDate);
    },

    slotColumns() {
      let columns = [
          { text: 'Zone', value: 'zone', useTemplate: false, type: 'number', align: 'left' }
      ];

      this.gates.forEach( g => {
          columns.push( {text: g.gateName, value: g.gateId, useTemplate: true, cellTemplate: 'slotTemplate', type: 'number', align: 'center'} );
      });

      return columns;
    },

    userRoles() {
      return UserRoles;
    },

    userCompany () {
      return store.state.account.userAccount !== null ? store.state.account.userAccount.company.n4TransportCompanyId : '';
    },

    userApplications () {
      return store.state.account.userAccount !== null ? store.state.account.userAccount.applications : [];
    },

    hasRoadBridge() {
        return store.state.account.userAccount !== null ? store.state.account.userAccount.applications.filter(g => g.applicationId === 'RBB').length > 0 : false;
    },

    appointmentDateIsFilledUp() {
      return this.selectedDate !== null;
    },

    gateIsFilledUp() {
      return this.gate !== null;
    },

    zoneIsFilledUp() {
      return this.zone !== null;
    },

    canLoadZones() {
      return this.appointmentDateIsFilledUp && this.gateIsFilledUp;
    },

    maxQtyToSelect() {
      return this.canLoadZones && this.zoneIsFilledUp ? this.slotZones.filter( ({zone}) => zone === this.zone)[0][this.gate] : 0;
    },
  },

  async created() {
    window.App.$on('update-slotavailability', () => {
      this.fetchZoneSlots();
    });

    await this.fetchZoneSlots();
    await this.fetchCompanies();
    this.refreshAvailableSlotsIntervalId = setInterval(this.fetchZoneSlots.bind(this, true), 120000);
  },

  beforeDestroy () {
      window.App.$off('update-slotavailability');
      clearInterval(this.refreshAvailableSlotsIntervalId);
  },

  watch : {
    editSlotMode(val) {
      if (val)
        this.$emit('showEditForm', this.selectedDate);
    },

    selectedDate: async function(newSelectedDate) { // eslint-disable-line no-unused-vars
      await this.fetchZoneSlots();
      if (this.canLoadZones)
        await this.populateZones(this.gate);
    },

    gate: async function(newVal) {
      if (this.canLoadZones)
        this.populateZones(newVal);
    },

    userCompany(val) {
      if (val !== '')
        this.selectedCompany = val;
    },
  },

  methods : {
    ...mapActions('gateAppointments', [
       'fetchSlots',
    ]),

    ...mapActions('appointments', [
        'fetchCompanies',
        'createBulkAppointments'
    ]),

    // Datepicker
    allowedDates: val => {
      return new window.Moment(val) >= new window.Moment().startOf('day');
    },

    // Button functions
    async quickBookSingleAppointment (date, zone, gateId, target) {
      this.quickBookIconProcessing(target, true);
      App["$appAnalytics"].trackEvent('VBS - Create Appointment - Quick Book - Click');
      await window.App.$emit('new-appointment-single', date, zone, gateId);
      this.quickBookIconProcessing(target, false);
    },

    quickBookIconProcessing(target, value) {
        if(value)
        {
            target.classList.remove("mdi-calendar-plus");
            target.classList.add("mdi-loading", "icon-spinner");
        }
        else
        {
            target.classList.remove("mdi-loading", "icon-spinner");
            target.classList.add("mdi-calendar-plus");
        }
    },

    addBulkItems () {
      if (this.$refs.form.validate()) {
        App["$appAnalytics"].trackEvent('VBS - Create Appointment - Bulk - Click');
        this.addBulkItemsLoadingIndicator = true;
        this.createBulkAppointments({selectedDate:this.selectedDate, zone:this.zone, quantity:this.quantity, isPropelAppointment: this.isPropelAppointment, gateId: this.gate, company: this.selectedCompany})
            .then(data => {
              this.zone = '';
              this.quantity = '';
              this.validationErrors = {};
              this.$refs.form.reset();

              window.App.$emit('show-snackbar', "success", data.length + " new appointments created");
            })
            .catch(error => {
              this.validationErrors = error.response.data.errors;
            })
            .finally(() => {
              this.addBulkItemsLoadingIndicator = false;
              this.fetchZoneSlots();
              this.$emit('reloadAppointmentList');
            });
      }
    },

    // Misc functions
    fetchZoneSlots() {
      this.fetchSlots(this.selectedDate);
    },

    populateZones(val) {
      let slotArr = [];
      this.slotAvailability.forEach(element => {
        if (element[val] !== undefined ){
          if (element[val] > 0) {
            let newObj = {
              zone: element.zone,
              [val]: element[val]
            };
            slotArr.push(newObj);
          }
        }
      });

      this.slotZones = slotArr;

      CMSApi.fetchGateInfo(this.gate)
          .then( data => {
            this.isPropelAppointment = data.isFromPropel;
          });
    },

    userHasRole(roles) {
      let authService = getInstance();
      return authService.userHasRole(roles);
    },

  }
}
</script>

<style>
#bulkBookContainer {
  padding: 0px;
}

</style>