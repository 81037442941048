export default {
    bind: function (el, _, vnode) {
        //assume first input is the one we are interested in
        let inputElement = el.getElementsByTagName('input')[0];
        if(inputElement != null)
        {
            // add event listener
            inputElement.addEventListener('input', function () {
                window.App.inputTextTransformUppercaseAlphaNumericOnly(inputElement);
                vnode.componentInstance.$emit('input', inputElement.value);                       
            }, false)
        }
    },
    unbind: function (el) {
        let inputElement = el.getElementsByTagName('input')[0];

        if(inputElement != null)
            inputElement.removeEventListener('input', el.transformUpper)
    }
}