<template>
  <div>
    <NotificationAlerts />

    <AppointmentReport id="appointmentReport" />
  </div>
</template>

<script>
import AppointmentReport from '@/components/reports/AppointmentReport.vue'
import NotificationAlerts from '@/components/global/NotificationAlerts.vue'

export default {
  name: "CompanyAppointments",

  components: {
    AppointmentReport,
    NotificationAlerts
  },
}
</script>

<style scoped>

</style>