import utils from '@/utils/vuexUtils.js'
import CMSApi from "@/services/CMSApi";

// initial state
const state = {
    // Objects
    terminalSitesAndSidings: [],
    customers: [],
    customerSites: [],
    roadBridges: [],
    freightForwarders: [],
    freightForwarderCustomers: [],
    truckingCompanies: [],
    transportOperators: [],

    // Loading indicators
    loadingTerminalSitesAndSidings: false,
    loadingCustomers: false,
    loadingCustomerSites: false,
    loadingRoadBridges: false,
    loadingFreightForwarders : false,
    loadingFreightForwarderCustomers: false,
    loadingTruckingCompanies: false,
    loadingTransportOperators: false,
}

// getters
const getters = {

    customersWithDefault: (state) => {
        let customers = state.customers;
        customers.unshift({companyId: 0, name: 'Default'});
        return customers;
    },


    getCustomerName: (state) => (customerId) => {
        let customer = state.customers.find(c => c.companyId === customerId);
        return customer ? customer.name : '';
    }

}

// actions
const actions = {
    fetchContainerTerminalSitesAndSidings: ({commit, state}) => {
        state.loadingTerminalSitesAndSidings = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchContainerTerminalSitesAndSidings(), 'setContainerTerminalSitesAndSidings');
    },

    fetchCustomers: ({commit, state}) => {
        state.loadingCustomers = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchTransportServiceLogisticCustomers(), 'setCustomers');
    },

    fetchCustomerSites: ({commit, state}, customerId) => {
        state.loadingCustomerSites = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchCustomerSites(customerId), 'setCustomerSites');
    },

    fetchFreightForwarders: ({commit, state}) => {
      state.loadingFreightForwarders = true;
      return utils.CommitArrayPromise(commit, CMSApi.fetchFreightForwarders(), 'setFreightForwarders');
    },

    fetchFreightForwarderCustomers: ({commit, state}, freightForwarderId) => {
        state.loadingFreightForwarderCustomers = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchFreightForwarderCustomers(freightForwarderId), 'setFreightForwarderCustomers');
    },

    fetchRoadBridges: ({commit, state}) => {
        state.loadingRoadBridges = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchRoadBridges(), 'setRoadBridges');
    },

    fetchTruckingCompanies: ({commit, state}) => {
        state.loadingTruckingCompanies = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchTransportPlannerTransportOperators(), 'setTruckingCompanies');
    },

    fetchTransportOperators: ({commit, state}) => {
        state.loadingTransportOperators = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchMappingTransportOperators(), 'setTransportOperators');
    }
}

// mutations
const mutations = {
    setContainerTerminalSitesAndSidings(state, terminalSitesAndSidings) {
        state.terminalSitesAndSidings = terminalSitesAndSidings;
        state.loadingTerminalSitesAndSidings = false;
    },

    setCustomers(state, customers) {
        state.customers = customers;
        state.loadingCustomers = false;
    },

    setCustomerSites(state, customerSites) {
        state.customerSites = customerSites;
        state.loadingCustomerSites = false;
    },

    setRoadBridges(state, roadBridges) {
        state.roadBridges = roadBridges;
        state.loadingRoadBridges = false;
    },

    setFreightForwarders(state, freightForwarders) {
        state.freightForwarders = freightForwarders;
        state.loadingFreightForwarders = false;
    },

    setFreightForwarderCustomers(state, freightForwarderCustomers) {
        state.freightForwarderCustomers = freightForwarderCustomers;
        state.loadingFreightForwarderCustomers = false;
    },

    setTruckingCompanies(state, truckingCompanies) {
        state.truckingCompanies = truckingCompanies;
        state.loadingTruckingCompanies = false;
    },

    setTransportOperators(state, transportOperators) {
        state.transportOperators = transportOperators;
        state.loadingTransportOperators = false;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}