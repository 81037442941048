<template>
    <v-dialog v-model="showDialog" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <ValidationSummary :validationErrors="validationErrors" />
                <v-form ref="cameraForm" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field 
                                    v-model="editedItem.name" 
                                    label="Camera Name" 
                                    :rules="cameraNameRules"
                                    :errorMessages="validationErrors['name']">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    v-model="editedItem.imageName" 
                                    label="Camera Image File Name" 
                                    :rules="cameraImageNameRules"
                                    :errorMessages="validationErrors['imageName']">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    v-model="editedItem.cctvId" 
                                    label="CCTV ID" 
                                    :rules="cctvIdRules"
                                    :errorMessages="validationErrors['CCTVID']">
                                    </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select 
                                    v-model="editedItem.cameraGroupId"
                                    :items="cameraGroups"
                                    item-text="name"
                                    item-value="cameraGroupId"
                                    label="Camera Group"
                                    :rules="cameraGroupIdRules"
                                    :error-messages="validationErrors['cameraGroups']"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-checkbox
                                    v-model="editedItem.isActive"
                                    label="Active"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="close" v-show="!savingIndicator">Cancel</v-btn>
                <v-btn color="primary darken-1" text @click="save" :loading="savingIndicator">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ValidationSummary from '@/components/global/ValidationSummary.vue'

export default {
    components: {
        ValidationSummary,
    },

    data () {
        return {
            showDialog: false,
            editedItem: null,
            defaultItem: {
                cameraId: null,
                name: '',
                imageName: '',
                cctvId: '',
                cameraGroupId: '',
                isActive: '',
            },
            validExt : [ 'pdf', 'jpg', 'png' ],
            cameraGroups: [],
            savingIndicator: false,
            validationErrors: {},
            cameraNameRules: [
                v => !!v || 'Camera Name is required',
            ],
            cameraImageNameRules: [
                v => !!v || 'Camera URL is required',
            ],
            cctvIdRules: [
                v => !!v || 'CCTV ID is required',
            ],
            cameraGroupIdRules : [
                v => !!v || 'Camera Group ID is required',
            ],
            loadingCameraGroups: false
        }
    },

    computed: {
        formTitle () {
            return this.isEdit ? 'Edit Camera' : 'New Camera';
        },
        
        isEdit() { 
            return !!this.editedItem.cameraId; 
        }
    },

    created() {
        //required to avoid runtime errors when first creating component
        this.editedItem = Object.assign({}, this.defaultItem);
        this.loadCameraGroups(); 
    },

    methods: {   
        //public method - point of entry to component
        editCamera (item) {
            this.editedItem = Object.assign({}, item);
            this.showDialog = true;
        },

        //public method - point of entry to component
        addCamera () {
            this.editedItem = Object.assign({}, this.defaultItem); 
            this.showDialog = true;
        },

        loadCameraGroups() {
            this.loadingCameraGroups = true;

            CMSApi.fetchCameraGroups()
                .then(data => {
                    this.cameraGroups = data;                    
                })
                .finally(() => {
                    this.loadingCameraGroups = false;
                });
        },

        save () {
            if (this.$refs.cameraForm.validate()) {
                var urlExtension = this.editedItem.imageName.split('.').pop();
                if (!urlExtension || this.validExt.indexOf(urlExtension.toLowerCase()) === -1) {
                    window.App.$emit('show-snackbar', "error", "Image is not valid");
                    return false;
                }
                this.savingIndicator = true;
                if (this.editedItem.cameraId) {
                    CMSApi.putCamera(this.editedItem)
                        .then(data => {
                            this.close();
                            this.$emit('cameraUpdated', data);
                            window.App.$emit('show-snackbar', "success", "Camera updated");
                        })
                        .catch(error => {
                            if (error.response && error.response.data && error.response.data.errors) {
                                this.validationErrors = error.response.data.errors;
                            }
                        })
                        .finally(() => {
                            this.savingIndicator = false;
                        });                   
                } else {
                    CMSApi.postCamera(this.editedItem)
                        .then(data => {
                            this.close();

                            this.$emit('cameraCreated', data);
                            window.App.$emit('show-snackbar', "success", "Camera added");
                        })
                        .catch(error => {
                            if (error.response && error.response.data && error.response.data.errors) {
                                this.validationErrors = error.response.data.errors;
                            }
                        })
                        .finally(() => {
                            this.savingIndicator = false;
                        }); 
                }
            }
        },

        close () {
            this.showDialog = false;
            //clear out data
            this.editedItem = Object.assign({}, this.defaultItem);
            this.clearValidationErrors();
        },

        clearValidationErrors() {
            this.validationErrors = {};
        }
    }
};
</script>
<style scoped>
</style>