export var UserStatus = Object.freeze({
    None:       'N',
    Pending:    'P',    
    Approved:   'A',
    Rejected:   'R',
    Deleted:    'D',
});
export var UserStatusLookup = Object.freeze([
    {code: UserStatus.None, description: 'None'},
    {code: UserStatus.Pending, description: 'Pending Approval'},    
    {code: UserStatus.Approved, description: 'Active'},
    {code: UserStatus.Rejected, description: 'Rejected'},
    {code: UserStatus.Deleted, description: 'Deleted'},
]);

export var CompanyStatus = Object.freeze({
    None:       'N',
    Pending:    'P',    
    Approved:   'A',
    Rejected:   'R',
    Deleted:    'D',
});
export var CompanyStatusLookup = Object.freeze([
    {code: CompanyStatus.None, description: 'None'},
    {code: CompanyStatus.Pending, description: 'Pending Approval'},    
    {code: CompanyStatus.Approved, description: 'Active'},
    {code: CompanyStatus.Rejected, description: 'Rejected'},
    {code: CompanyStatus.Deleted, description: 'Deleted'},
]);

export var AppointmentState = Object.freeze({
    Created:    'Created',
    Late:       'Late',    
    Used:       'Used',
    UsedLate:   'UsedLate',
    Expired:    'Expired',
    Canceled:   'Canceled',
    Closed:     'Closed',
    Completed: 'Completed'
});

export var TransportPlannerStatus = Object.freeze({
    Available: 'Available',
    Draft: 'Draft',
    ReadyToSubmit: 'ReadyToSubmit',
    InProgress: 'InProgress',
    Cancelled: 'Cancelled',
    Confirmed: 'Confirmed',
    Complete: 'Complete',
    Invoiced: 'Invoiced',
})

export var AppointmentType = Object.freeze({
    DropOff:    {code: 'DropOff', description: 'Drop off'},
    PickUp:     {code: 'PickUp', description: 'Pick up'}
});

export var DoorDirection = Object.freeze({
    Forward:    {code: 'Forward', description: 'Forward'}, 
    Rear:       {code: 'Rear', description: 'Rear'}, 
    Any:        {code: 'Any', description: 'Any'}, 
    Unknown:    {code: 'Unknown', description: 'Unknown'}
});

export var ContainerHold = Object.freeze({
    ImportCustom:   {code: '!IMPORT CUSTOMS', description: 'Customs Hold'}, 
    ImportLine:     {code: '!IMPORT LINE', description: 'Line Hold'}, 
    MPI:            {code: 'MPIIMPHOLD', description: 'MPI Hold'}, 
    Pay:            {code: 'PAY', description: 'Pay Hold'}, 
    Dirty:          {code: 'DIRTY', description: 'Dirty Hold'}, 
    Underbond:      {code: 'UNDERBOND HOLD', description: 'Customs Hold'}, 
    FumoImport:     {code: '!FUMO_IMPORT', description: 'Fumigation Hold'},
    Unknown:        {code: '', description: 'Unknown Hold'}
});

export var AlertLevel = Object.freeze({
    None:       0, 
    Minor:      1,
    Warning:    2,
    Severe:     3,
    Critical:   4
});

export var UserRoles = Object.freeze({
   PropelAdmin: "Propel Admin",
   PropelCompanyAdmin: "Propel Company Admin",
   PropelDispatcher: "Propel Dispatcher",
   PropelDriver: "Propel Driver",
   PropelDriverPlus: "Propel Driver Plus",
   PropelImportAdvisor: "Propel Import Advisor",
   PropelExportCoordinator: "Propel Export Coordinator",
   PropelReportViewer: "Propel Report Viewer",
   PropelTransportAdministrator: "Propel Transport Administrator",
   PropelTransportCoordinator: "Propel Transport Coordinator",
   PropelTransportUser: "Propel Transport User"
});

export var AppointmentFormMode = Object.freeze({
    General:    'General',
    DayStack:   'DayStack',    
    BlockStack: 'BlockStack',
});

export var AppointmentGate = Object.freeze({
    APPT_GATE: 'APPT_GATE',
    THAMES_ST: 'THAMES_ST',
    T2_GATE: 'T2_GATE',
});

export var GroupStackType = Object.freeze({
    Day:    'Day',
    Block:  'Block',
});

export var GroupDayStackType = Object.freeze({
    EntireDay:  'EntireDay',
    AM:         'AM',
    PM:         'PM',
});

export var ContainerTransitState = Object.freeze({
    Advised:'Advised',
    Inbound:'Inbound',
    Yard:   'Yard',
});

export var ContainerInboundMode = Object.freeze({
    Vessel: 'Vessel',
    Train:  'Train',
    Truck:  'Truck',
});

export var GateConfig = Object.freeze({
    1 : "Full Containers",
    2 : "Empty Containers",
    3 : "Full and Empty Containers"
})

export var TrainServiceDirection = Object.freeze([
    { id: 0, value: 'Inbound'},
    { id: 1, value: 'Outbound'}
])

export var ApplicationType = Object.freeze({
    NapierPortAdmin: 'NPT',
    VBS: 'VBS',
    ImportAdvice: 'IMP',
    ExportPreAdvice: 'EPA',
});

export var ImportGroupCommand = Object.freeze({
    NewBlockGroup: 'NEW_BLOCK',
    RemoveGroup: 'REMOVE_GROUP',
});

export var ReportDBContext = Object.freeze([
    { code: 'Propel', value: 'Propel'},
    { code: 'N4', value: 'N4 CMS Filters'},
    { code: 'N4DB', value: ' N4 - Database Reports'}
]);

export var ReportDBSourceType = Object.freeze([
    { code: 'SP', value: 'Stored Procedure', Propel: true, N4: false, N4DB: true },
    { code: 'TVF', value: 'Table Valued Function', Propel: true, N4: false, N4DB: true },
    { code: 'V', value: 'View' , Propel: true, N4: false, N4DB: true },
    { code: 'N4', value: 'N4 Views' , Propel: false, N4: true, N4DB: false }
]);

export var ReportColumnType = Object.freeze([
    {filterName: 'string', dataTypeName: 'nvarchar'},
    {filterName: 'number', dataTypeName: 'int'},
    {filterName: 'date', dataTypeName: 'date'},
    {filterName: 'boolean', dataTypeName: 'bit'}
])

export var EnumHelpers = Object.freeze({
    appointmentTypeArray: Object.values(AppointmentType),
    containerHoldArray: Object.values(ContainerHold),

    getAppointmentTypeByCode: function(value) {
        return this.appointmentTypeArray.find(i => i.code == value);  
    },

    getContainerHoldByCode: function(value) {
        return this.containerHoldArray.find(i => i.code == value);  
    },

    getUserStatusDescriptionByCode: function(value) {
        var status = UserStatusLookup.find(i => i.code == value);
        return (status ? status.description : '');
    },

    getCompanyStatusDescriptionByCode: function(value) {
        var status = CompanyStatusLookup.find(i => i.code == value);
        return (status ? status.description : '');
    },
});