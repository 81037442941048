<template>
    <v-dialog v-model="showDialog" persistent max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <ValidationSummary :validationErrors="validationErrors" />
                <v-form ref="companyform" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.name" label="Name" 
                                    :rules="[v => !!v || 'Name is required']"
                                    :errorMessages="validationErrors['Name']"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.phoneNumber" label="Phone Number" 
                                    :rules="phoneNumberRules" maxlength="20"
                                    :errorMessages="validationErrors['PhoneNumber']"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="editedItem.address" label="Address" 
                                    :rules="addressRules" maxlength="250"
                                    :errorMessages="validationErrors['Address']"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col cols="12">
                                <v-text-field v-model="editedItem.damagesEmailAddress" label="Damages Email Address"
                                    :rules="emailAddressRules"
                                    :errorMessages="validationErrors['DamagesEmailAddress']"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row v-if="!isPendingCompany">
                            <v-col cols="12" >
                                <label for="content" class="v-label theme--light formMinimisedLabel" v-bind:class="{ 'error--text': validationErrors['Applications']}">Port Functions</label>
                                <v-checkbox
                                    v-for="(item, index) in this.companyApps" :key="item.applicationId"
                                    :label="item.name" :value="item"
                                    v-model="editedItem.applications" multiple
                                    :rules="[v => v.length > 0 || 'At least one application is required']"
                                    :hide-details="index < companyApps.length-1 ? true : false"
                                    class="applicationsCheckbox">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12">
                                <label class="v-label theme--light formMinimisedLabel">Admin</label><br/>
                                <v-chip                            
                                    v-for="(item, i) in editedItem.adminUsers" :key="i"
                                    color="propelDarkGrey"
                                    label small dark
                                    class="gridChips"
                                >{{item.fullName}}</v-chip>
                            </v-col>
                            <v-col cols="12" >
                                <v-simple-table id="requestedApplications">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Port Function
                                                </th>
                                                <th class="text-center">
                                                    Requested
                                                </th>
                                                <th class="text-center">
                                                    Approved
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item) in companyApps" :key="item.applicationId">
                                                <td>{{ item.name }}</td>
                                                <td>
                                                    <v-checkbox
                                                        :key="item.applicationId"
                                                        :value="item"
                                                        v-model="requestedApplications" multiple
                                                        hide-details disabled
                                                        class="centeredCheckbox applicationsCheckbox">
                                                    </v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        :key="item.applicationId"
                                                        :value="item"
                                                        v-model="editedItem.applications" multiple
                                                        hide-details
                                                        class="centeredCheckbox applicationsCheckbox">
                                                    </v-checkbox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.n4TransportCompanyId" label="N4 Transport Company Code" 
                                    maxlength="10" :errorMessages="validationErrors['N4TransportCompanyId']"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.n4ShipperConsigneeCompanyId" label="N4 Shipper Consignee Company Code" 
                                    maxlength="10" :errorMessages="validationErrors['N4ShipperConsigneeCompanyId']"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.bcCode" label="Business Central Code"
                                              maxlength="10" :errorMessages="validationErrors['BCCode']"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" v-if="hasSelectedKiwiRailFunction">
                                <v-text-field v-model="editedItem.kiwiRailCode" label="Kiwi Rail Code"
                                              maxlength="7" :errorMessages="validationErrors['KiwiRailCode']"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions v-if="!isPendingCompany">
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="close" v-show="!savingIndicator">Cancel</v-btn>
                <v-btn color="primary darken-1" text @click="save" :loading="savingIndicator">Save</v-btn>
            </v-card-actions>
            <v-card-actions v-else>
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="close" v-show="!savingIndicator">Cancel</v-btn>
                <v-btn color="primary darken-1" text @click="reject" v-show="!savingIndicator">Reject</v-btn>
                <v-btn color="primary darken-1" text @click="approve" :loading="savingIndicator">Approve</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {CompanyStatus} from '@/utils/constants.js';
import ValidationSummary from '@/components/global/ValidationSummary.vue'

export default {
    components: {
        ValidationSummary,
    },

    props: {
        companyId: {
            type: Number,
            default: null
        }, 
    },
    data () {
        return {
            showDialog: false,
            editedItem: null,
            defaultItem: {
                companyId: null,
                name: '',
                address: '',
                phoneNumber: '',
                n4TransportCompanyId: null,
                n4ShipperConsigneeCompanyId: null,
                damagesEmailAddress: '',
                status: '',
                applications: [],
                adminUsers: [],
                kiwiRailCode: '',
                bcCode: null,
            },
            requestedApplications: [],
            companyApps: [],
            savingIndicator: false,
            validationErrors: {},
            emailAddressRules: [
                v => !v || v == "" || /.+@.+\..+/.test(v) || 'Email Address must be valid',
            ],
            loadingCompanyApps: false
        }
    },

    computed: {
        formTitle () {
            if (this.editedItem.status == CompanyStatus.Pending)
                return 'New Company Approval';
            else
                return this.editedItem.companyId ? 'Edit Company' : 'New Company';
        },

        isPendingCompany () {
            return this.editedItem.status == CompanyStatus.Pending;
        },

        isExistingApprovedCompany () {
            return this.editedItem.companyId && !this.isPendingCompany;
        },

        addressRules() {
            return [];
        },

        phoneNumberRules() {         
            //only applies for new records
            return this.isExistingApprovedCompany ? [] : [
                v => !v || v.length >= 9 && v.length <= 20 || 'Company Phone must be between 9 and 20 characters'
            ];
        },

        hasSelectedKiwiRailFunction() {
            return this.editedItem.applications.filter( g => g.applicationId === 'KRC').length > 0;
        }
    },

    created () {
        //required to avoid runtime errors when first creating component
        this.editedItem = Object.assign({}, this.defaultItem);
        this.loadCompanyApps();    
    },
    
    methods: {   

        //public method - point of entry to component
        editCompany (item) {
            this.editedItem = Object.assign({}, item);
            this.requestedApplications = this.editedItem.applications;
            this.showDialog = true;
        },

        //public method - point of entry to component
        createCompany () {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.showDialog = true;
        },

        loadCompanyApps() {
            this.loadingCompanyApps = true;

            CMSApi.fetchApplications()
                .then(data => {
                    this.companyApps = data;                    
                })
                .finally(() => {
                    this.loadingCompanyApps = false;
                });
        },

        save () {          
            if (this.$refs.companyform.validate())
            {
                this.savingIndicator = true;

                if (this.editedItem.companyId) {
                    CMSApi.putCompany(this.editedItem)
                        .then(data => {
                            //copy over admin users into new company object
                            data.adminUsers = this.editedItem.adminUsers;

                            this.close();

                            this.$emit('companyUpdated', data);
                            window.App.$emit('show-snackbar', "success", "Company updated");
                        })
                        .catch(error => {
                            this.validationErrors = error.response.data.errors;
                        })
                        .finally(() => {
                            this.savingIndicator = false;
                        });                   
                } else {
                    CMSApi.postCompany(this.editedItem)
                        .then(data => {
                            //copy over admin user into new company object
                            data.adminUsers = this.editedItem.adminUsers;

                            this.close();

                            this.$emit('companyCreated', data);
                            window.App.$emit('show-snackbar', "success", "Company created");
                        })
                        .catch(error => {
                            this.validationErrors = error.response.data.errors;
                        })
                        .finally(() => {
                            this.savingIndicator = false;
                        }); 
                }
            }
        },

        approve () {
            if (this.$refs.companyform.validate())
            {
                this.savingIndicator = true;

                CMSApi.activateCompany(this.editedItem)
                    .then(data => {
                        //copy over admin user into new company object
                        data.adminUsers = this.editedItem.adminUsers;

                        this.close();

                        this.$emit('companyApproved', data);
                        window.App.$emit('show-snackbar', "success", "Company approved");
                    })
                    .catch(error => {
                        this.validationErrors = error.response.data.errors;
                    })
                    .finally(() => {
                        this.savingIndicator = false;
                    });
            }
        },

        async reject () {
            if (await this.$root.$confirm.open('Reject company?', 'Are you sure you want to reject this company\'s request to access Propel?', { color: 'warning' }))
            {
                this.savingIndicator = true;

                let companyId = this.editedItem.companyId;
                CMSApi.deactivateCompany(companyId)
                    .then(() => {
                        this.close();

                        this.$emit('companyRejected', companyId);
                        window.App.$emit('show-snackbar', "success", "Company rejected");
                    })
                    .catch(error => {
                        let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                        this.$root.$alert.show('Unable to reject company', errorMessage)
                    })
                    .finally(() => {
                        this.savingIndicator = false;
                    });
            }
        },

        close () {
            this.showDialog = false;

            //clear out data
            this.editedItem = Object.assign({}, this.defaultItem);
            this.clearValidationErrors();

            //reset dialog scroll position
            document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
        },

        clearValidationErrors() {
            if(this.$refs.companyform != null)
                this.$refs.companyform.resetValidation();
            this.validationErrors = {};
        }
    }
};

</script>

<style scoped>
    .applicationsCheckbox
    {
        margin-top:0px
    }

    #requestedApplications th
    {
        padding: 0px;
    }
    #requestedApplications td
    {
        border-bottom: none;
        padding: 0px;
        height: 36px;
    }
</style>