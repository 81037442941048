<template>
    <v-card 
        outlined tile id="groupSelectPanel" :class="{'smAndUp-min-width': $vuetify.breakpoint.smAndUp}">
        
        <div id="groupSelectPanelTableHeader">Set Day/Group for {{selectedContainerIds.length}} containers</div>

        <v-card-text>

            <ValidationSummary :validationErrors="validationErrors" />

            <v-alert class="infoAlert" text outlined dense color="success" icon="mdi-check-circle" v-show="adviseContainersSuccessCount > 0" >
                <div>{{adviseContainersSuccessCount + (adviseContainersSuccessCount > 1 ? " containers were" : " container was") + " succesfully advised"}}</div>
            </v-alert>

            <v-alert class="infoAlert" text outlined dense type="warning" icon="mdi-alert-outline" v-show="adviseContainersWarnings" >
                <div>{{adviseContainersWarnings}}</div>
            </v-alert>
            
            <v-alert class="infoAlert" text outlined dense color="error" v-show="adviseContainersValidationErrors.length > 0" >
                <v-icon>mdi-alert-circle</v-icon>
                <div>{{adviseContainersValidationErrorsIntroText}}</div>
                <v-simple-table dense>
                    <tbody>
                        <tr v-for="(item, index) in this.adviseContainersValidationErrors" v-bind:key="index">
                            <td>{{ item.containerNumber }}</td>
                            <td>{{ item.failureReason }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>       
            </v-alert>
            
            <v-form ref="importGroupSelectForm" lazy-validation>
                <v-container class="shortContainer" :max-width="$vuetify.breakpoint.smAndUp ? '600' : ''">
                    <v-row>
                        <v-col cols="12" :sm="6" class="denseColumn">
                                <v-container >
                                    Day Groups:
                                    <v-radio-group v-model="selectedGroup" hide-details>
                                    <v-radio
                                        v-for="(item, index) in this.importDayGroupOptions" :key="item.groupId"
                                        :label="item.description" :value="item.groupId"
                                        :class="isDistinctDayOption(index) ? 'distinctOption' : ''">
                                    </v-radio>
                                    </v-radio-group>
                                </v-container>
                        </v-col>
                        <v-col cols="12" :sm="6" class="d-flex flex-column denseColumn">
                                <v-container class=" mb-auto">
                                        Block Groups:
                                        <v-radio-group v-model="selectedGroup" hide-details>
                                        <v-radio
                                            v-for="(item) in this.importBlockGroups" :key="item.groupId"
                                            :label="item.description" :value="item.groupId">
                                        </v-radio>
                                        </v-radio-group>
                                </v-container >
                                <v-container id="removeGroupSection" >
                                        <v-radio-group v-model="selectedGroup" hide-details>
                                        <v-radio
                                            :key="ImportGroupCommand.RemoveGroup"
                                            label="Remove Day/Group" :value="ImportGroupCommand.RemoveGroup">
                                        </v-radio>
                                        </v-radio-group>
                                </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-container id="panelActions">
                <v-row>
                    <v-col cols="12" :sm="6" class="text-center" :class="{'order-2': $vuetify.breakpoint.xs}">
                        <v-btn tile color="secondary" @click="close" v-show="!savingChanges">
                            <v-icon>mdi-window-close</v-icon>
                            <span>Cancel</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" :sm="6" class="text-center">
                        <v-btn tile color="secondary" @click="save" :loading="savingChanges">
                            <v-icon>mdi-check</v-icon>
                            <span>Save</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-container id="viewControls" class="shortContainer">
            <v-card-actions>
                <v-checkbox 
                    :input-value="viewSelectedContainersOnly" 
                    @change="$emit('update:viewSelectedContainersOnly', $event)" 
                    label="Show only selected containers" hide-details>
                </v-checkbox>
            </v-card-actions>
        </v-container>
        
    </v-card>
</template>

<script>
import ValidationSummary from '@/components/global/ValidationSummary.vue'
import {importAdviceHelpers} from '@/store/modules/importAdvice.js';
import {ImportGroupCommand} from '@/utils/constants.js';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    components: {
        ValidationSummary,
    },

    props: {
        viewSelectedContainersOnly: Boolean
    },

    data () {
        return {
            selectedGroup: ImportGroupCommand.NewBlockGroup,
            savingChanges: false,
            validationErrors: {},
            adviseContainersWarnings: "",
            adviseContainersValidationErrors: [],
            adviseContainersValidationErrorsIntroText: '',
            adviseContainersSuccessCount: 0,            
        }
    },

    computed: {
        ...mapState('importAdvice', [
            'importContainers',
            'selectedContainerIds'
        ]),

        ...mapGetters('importAdvice', [
            'selectedImportContainers',
            'importDayGroupOptions'
        ]),

        importBlockGroups(){
            let groups = importAdviceHelpers.importBlockGroupOptionsForContainers(this.importContainers, this.selectedImportContainers);

            //add new block option as first option
            groups.unshift({ groupId: ImportGroupCommand.NewBlockGroup, description: 'New Block Group' });

            return groups;
        },
    },

    created() {
        this.ImportGroupCommand = ImportGroupCommand;
    },

    methods: {
        ...mapActions('importAdvice', [
            'addOrUpdateImportContainers',
            'deselectContainers'
        ]),

        isDistinctDayOption(index) {
            //if first option or less than 8 options total, then we do not want to further separate day options into AM/PM groupings
            if (index == 0 || this.importDayGroupOptions.length <=7)
                return false;
            
            //check if a new day (compared to previous item), and if so mark as being a new distinct day option
            return this.importDayGroupOptions[index].dayOfWeek != this.importDayGroupOptions[index-1].dayOfWeek;
        },

        save() {
            this.clearValidationErrors();
            if (this.$refs.importGroupSelectForm.validate())
            {
                App.$appAnalytics.trackEvent('IA - Multi Container Set Group - Save Click');   
                this.savingChanges = true;
                CMSApi.adviseImportContainers(this.selectedContainerIds, this.selectedGroup)
                    .then(data => {
                        //handle individual succesful adds and also failures
                        // - for successes - add containers into grid and select, and clear entered #s
                        // - for failures - show errors, and keep showing entered #s
                        let successes = data.filter(adviseResult => adviseResult.success);
                        let failures = data.filter(adviseResult => !adviseResult.success);

                        //clear any existing validation errors
                        this.clearValidationErrors();

                        if (failures.length > 0) {                            
                            this.adviseContainersValidationErrorsIntroText = "The following " + (failures.length > 1 ? failures.length + " containers" : "container") + " could not be advised:";
                            this.adviseContainersValidationErrors = failures.map(item => ({containerNumber: item.containerNumber, failureReason: item.failureReason}));  
                        }                        

                        if (successes.length > 0) {
                            this.adviseContainersSuccessCount = successes.length;
                            window.App.$emit('show-snackbar', "success", successes.length + " container/s advised");

                            this.addOrUpdateImportContainers(successes.map(s => s.container));
                            this.deselectContainers(successes.map(s => s.containerNumber));
                        }
                            
                        if (failures.length == 0 && successes.length > 0) {
                            this.close();
                        }
                    })
                    .catch(error => {
                        this.validationErrors = error.response.data.errors;
                    })
                    .finally(() => {
                        this.savingChanges = false;
                    });
            }
        },

        close() {           
            //clear out data
            this.selectedGroup = ImportGroupCommand.NewBlockGroup;
            this.clearValidationErrors();
            this.$emit('closed');
        },

        clearValidationErrors() {
            this.adviseContainersValidationErrorsIntroText = "";
            this.adviseContainersSuccessCount = 0;
            this.adviseContainersValidationErrors = [];
            this.validationErrors = {};
            this.adviseContainersWarnings = "";
        },

        setWarningMessage(message) {
            this.adviseContainersWarnings = message;
        },
    }
};

</script>

<style scoped>
    #groupSelectPanel{
        border-left:0;
        border-top:0;
        border-bottom:0;
        height:100%;
    }

    #groupSelectPanel.smAndUp-min-width{
        min-width: 430px; /* also needed for transition effect */
    }

    #groupSelectPanelTableHeader {
        color: var(--v-propelDarkGrey-base);
        background-color: var(--v-propelLightGreen-base);
        border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 14px;
        padding-left: 14px;
        line-height: 18px;
        font-weight: 500;
        vertical-align: middle;        
    }

    #viewControls {
        border-top: thin solid rgba(0, 0, 0, 0.12) !important;
        padding-left: 16px;
        padding-bottom: 16px;
    }

    .shortContainer {
        padding-top: 0;
        padding-bottom: 0;
    }

    .denseColumn {
        padding: 0;
    }

    .v-radio {
        margin-bottom: 4px !important;
    }

    /* allowings grouping radio buttons by days */
    .v-radio.distinctOption {
        padding-top:12px;
    }

    #removeGroupSection {
        padding-top: 0;
        border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    #panelActions {
        padding-top: 24px;
    }

    #panelActions button {
        width:100%; 
        max-width: 250px
    }

    /* custom v-alert */
    .v-alert.infoAlert .v-icon {
        float: left;
        margin-right: 16px;
        color: inherit;
    }
    .v-alert.infoAlert .v-alert__content div{
        color: black; /* don't use alert colour */
    }

    .v-alert.infoAlert .v-data-table{
        clear: both;
        margin-top: 5px;
    }

    .v-alert.infoAlert .v-data-table tr:hover{
        background-color: transparent !important;
    }

    .v-alert.infoAlert .v-data-table td{
        vertical-align: top; 
        padding: 5px;
    }
</style>