<template>
    <div>

        <InboundVesselsSlider id="inboundVesselsSlider" :inboundVessels="inboundVessels" mode="import" />

        <MultiContainerSelectedToolbar 
            :selectedContainersCount="selectedContainerIds.length"
            :visible="selectedContainerIds.length > 0 && !showSetGroupPanel"
            v-on:setGroupClick="showSidePanel(true)"
            v-on:clearSelectedContainersClick="clearSelectedContainers()" />

        <v-card id="importAdvisedContainerList" outlined>
            <v-toolbar class="datatableToolbar" flat>
                <v-toolbar-title>Import Advised Containers</v-toolbar-title>

                <div class="flex-grow-1"></div>

                <v-btn                    
                    tile color="secondary"                     
                    :loading="loadingImportAdvisedContainersData"
                    @click="refreshData" >
                        <v-icon>mdi-refresh</v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
                </v-btn>
                <v-btn                   
                    tile outlined
                    color="secondary"
                    @click="exportXlsFile" >
                        <v-icon>mdi-file-excel-outline</v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Export XLS</span>
                </v-btn>
            </v-toolbar>

            <v-container class="datatableControls" >
                <v-row>
                    <v-col cols="12" sm="8" md="5" lg="5" xl="4" id="importContainersToAddField" v-show="importAdviceOperational"> 
                        <v-textarea
                            outlined dense hide-details="auto"
                            clearable auto-grow rows=1
                            v-model="importContainersToAdd"
                            label="Enter containers to advise here"
                            :error-messages="claimContainersValidationErrors">
                        </v-textarea>
                        <v-alert class="blackText" text outlined dense type="warning" icon="mdi-alert-outline" v-show="claimContainersWarnings" >
                            <div>{{claimContainersWarnings}}</div>
                        </v-alert>
                    </v-col>                    
                    <v-col cols="12" sm="4" md="3" lg="3" xl="5" v-show="importAdviceOperational">
                        <v-btn tile color="secondary" 
                            :block=$vuetify.breakpoint.xs                        
                            @click="addContainers" :loading="claimingBulkContainersIndicator">
                            Advise Containers
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="8" lg="6" xl="5" v-show="!importAdviceOperational">
                        <v-alert
                            id="importAdviceUnavailableAlert" dense
                            tile text colored-border outlined icon="mdi-alert-circle" >
                            <div>Outside of Import Advise operational hours. {{importAdviceUnavailableMessage}}</div>
                        </v-alert>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="3">
                        <v-text-field clearable v-model="searchText" label="Search by Container, Group or Visit #" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <div class="d-flex align-stretch" :class="{'flex-wrap': $vuetify.breakpoint.smAndDown}">
                <div class="flex-grow-1">
                    <v-expand-x-transition>
                        <div v-show="showSetGroupPanel" id="multiContainerSetGroupPanel">
                            <MultiContainerSetGroupPanel
                                ref="multiContainerSetGroupPanel"
                                :viewSelectedContainersOnly.sync=viewSelectedContainersOnly
                                v-on:closed="showSidePanel(false)" />                    
                        </div>
                    </v-expand-x-transition>
                </div>

                <div id="importAdvisedContainersSection" :class="{groupSelectionPanelScrollfix: viewSelectedContainersOnly}">
                    <importContainerList
                        ref="importContainerList"
                        :readonly=!importAdviceOperational
                        :searchText="searchText"
                        :loading=loadingImportAdvisedContainersData
                        :viewSelectedContainersOnly="viewSelectedContainersOnly" />                    
                </div>
            </div>
        </v-card>
        
    </div>
</template>

<script>
import importContainerList from './ImportContainerList.vue';
import MultiContainerSetGroupPanel from './MultiContainerSetGroupPanel.vue';
import MultiContainerSelectedToolbar from './MultiContainerSelectedToolbar.vue';
import InboundVesselsSlider from "../vessels/InboundVesselsSlider";
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    components: {
        InboundVesselsSlider,
        MultiContainerSelectedToolbar,
        importContainerList,
        MultiContainerSetGroupPanel,
    },

    data () {
        return {
            loadingImportAdvisedContainersData: false,
            pageSizes: [5, 10, 25, 50],
            searchText: '',
            importContainersToAdd: '',
            claimContainersValidationErrors: "",
            claimContainersWarnings:"",
            claimingBulkContainersIndicator: false,
            showSetGroupPanel: false,
            refreshInboundVesselsIntervalId: 0,
            refreshOperationalIntervalId: 0,
            operationalCurrentTimeTrigger: new Date(),
            viewSelectedContainersOnly: false,
            refreshImportDayGroupsIntervalId: 0,
        }
    },

    computed: {
        ...mapState('importAdvice', [
            'importContainers',
            'selectedContainerIds',
        ]),
        
        ...mapState('vessels', [
            'inboundVessels',
        ]),

        ...mapGetters('settings', [
            'importAdvice',
        ]),

        importAdviceOperational() {
            //if we don't yet have operational hours, assume operational - server will catch anyway
            if(this.importAdvice.operationalHours.length == 0)
                return true;
            
            //using a trigger in order to ensure this computed prop is re-evaulated periodicially taking into account current time
            let currentDateTime = this.operationalCurrentTimeTrigger;
            let currentDayOfWeek = currentDateTime.getDay();
            let currentHour = currentDateTime.getHours();

            let todaysOperationHours = (this.importAdvice.operationalHours).find(d => d.dayOfWeek == currentDayOfWeek);
            return todaysOperationHours != null && (currentHour >= todaysOperationHours.startHour && currentHour <= todaysOperationHours.endHour);
        },

        importAdviceUnavailableMessage() {
            //we need to cater availability message depending on when Import Advice is next available, which could be:
            // - later today
            // - tomorrow
            // - later in the week
            let operationalHours = this.importAdvice.operationalHours;

            if (!this.importAdviceOperational && operationalHours.length != 0)
            {
                //using a trigger in order to ensure this computed prop is re-evaulated periodicially taking into account current time
                let currentDateTime = this.operationalCurrentTimeTrigger;
                let currentDayOfWeek = currentDateTime.getDay();
                let currentHour = currentDateTime.getHours();

                let todaysOperationHours = operationalHours.find(d => d.dayOfWeek == currentDayOfWeek);
                
                //if currently before the start of todays operational hours
                if (todaysOperationHours != null && (currentHour < todaysOperationHours.startHour))
                    return "Import Advice is available today " + todaysOperationHours.startHour + ":00 - " + (todaysOperationHours.endHour + 1) + ":00.";
                else { 
                    //must be after todays operational hours (or no operational hours for today)
                    //loop through days of week and operational days to see when Import Advise is next available
                    let dayOfWeek = currentDayOfWeek + 1;
                    while(dayOfWeek % 7 != currentDayOfWeek)
                    {
                        let nextOperationalDay = operationalHours.find(d => d.dayOfWeek == dayOfWeek);
                        if(nextOperationalDay != null)
                        {
                            if(dayOfWeek == (currentDayOfWeek + 1))
                                return "Import Advice is available tomorrow " + nextOperationalDay.startHour + ":00 - " + (nextOperationalDay.endHour + 1) + ":00.";
                            else
                                return "Import Advice is next available " + this.dayOfWeekAsString(dayOfWeek) + " " + nextOperationalDay.startHour + ":00 - " + (nextOperationalDay.endHour + 1) + ":00.";
                        }

                        dayOfWeek++;
                    }
                }
            }

            return "";
        },
    },

    watch: {
        importContainersToAdd: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            //if import containers to add is cleared, clear any validation errors too
            if(!newVal || newVal == "")
                this.claimContainersValidationErrors = "";
        },

        selectedContainerIds: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            //close side panel if it is open and we no longer have any selected containers
            if (this.showSetGroupPanel && this.selectedContainerIds.length == 0)
                this.showSidePanel(false);
        },
    },

    mounted() {
        //fetching info for sub-components
        this.fetchImportDayGroups();
        this.fetchContainers();
        this.fetchInboundVessels();
    },

    created() {
        var self = this;

        //need to check if Import Advice is operational (or if non-operation message needs updating) every minute
        this.refreshOperationalIntervalId = setInterval(function () {
            self.operationalCurrentTimeTrigger = new Date();
        }, 60000)

        //need to refresh inbound vessel info every 5 minutes
        this.refreshInboundVesselsIntervalId = setInterval(function () {
            self.fetchInboundVessels(true);
        }, 300000)
        
        //need to force refresh of import day groups every 10 minutes - as if past midnight available day groups change
        this.refreshImportDayGroupsIntervalId = setInterval(function () {
            self.setImportDayGroupsTimeTrigger(new Date());
        }, 600000)
    },

    beforeDestroy() {
        //clear any selection when moving off the page
        this.setSelectedContainerIds([]);

        clearInterval(this.refreshInboundVesselsIntervalId);
        clearInterval(this.refreshOperationalIntervalId);
        clearInterval(this.refreshImportDayGroupsIntervalId);
    },

    methods: {
        ...mapActions('importAdvice', [
            'fetchImportContainers',
            'fetchImportDayGroups',
            'addOrUpdateImportContainers',
            'selectContainers',
            'setSelectedContainerIds',
            'setImportDayGroupsTimeTrigger'
        ]),

        ...mapActions('vessels', [
            'fetchInboundVessels'
        ]),

        refreshData() {
            App.$appAnalytics.trackEvent('IA - Refresh Data Click'); 
            this.fetchContainers();
            this.fetchInboundVessels();
        },
        
        fetchContainers() {
            this.loadingImportAdvisedContainersData = true;

            this.fetchImportContainers()
                .finally(() => {
                    this.loadingImportAdvisedContainersData = false;
                });
        },

        addContainers() {
            App.$appAnalytics.trackEvent('IA - Add Containers Click');

            this.claimContainersValidationErrors = "";
            this.claimContainersWarnings = "";

            //split out container numbers and also filter out empty values
            let containersToAdvise = this.splitMulti(this.importContainersToAdd.toUpperCase(), [' ', ',', '.', ':', '\t', '\n', '\r', ';']).filter(item => item);
            let newContainersToClaim = containersToAdvise.filter(c => !this.importContainers.some(ic => ic.containerNumber == c));

            //if no actual new containers to add to company (all pre-existing), just show and select containers user wants to advise
            if(newContainersToClaim.length == 0) {
                this.importContainersToAdd = "";
                this.showContainersToAdvise(containersToAdvise, true);
            } else {
                this.claimingBulkContainersIndicator = true;
                CMSApi.claimImportContainers(newContainersToClaim)
                    .then(data => {

                        //handle individual successful adds and also failures:
                        let successes = data.filter(adviseResult => adviseResult.success);
                        let failures = data.filter(adviseResult => !adviseResult.success);

                        // - for successes - add containers to local list, and display message
                        if (successes.length > 0) {
                            this.addOrUpdateImportContainers(successes.map(s => s.container));
                            window.App.$emit('show-snackbar', "success", successes.length + " new container/s added");
                        }

                        // - for any failures - show errors, and keep showing entered #s - else clear both
                        this.claimContainersValidationErrors = 'The following container/s could not be added:\n' + failures.map(item => item.containerNumber + ': ' + item.failureReason).join(".\n");
                        this.importContainersToAdd = failures.map(f => f.containerNumber).join("\n");
                        
                        //now show and select just containers user wants to advise
                        this.showContainersToAdvise(
                            containersToAdvise.filter(c => !failures.some(f => f.containerNumber == c)), //don't show containers that we couldn't claim
                            failures == 0
                        );
                    })
                    .catch(error => {
                        this.claimContainersValidationErrors = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                    })
                    .finally(() => {
                        this.claimingBulkContainersIndicator = false;
                    });
            }
        },

        showContainersToAdvise(containersToAdvise, scrollToGrid) {
            this.selectContainers(containersToAdvise);

            //for any containers added, but not editable, show as warning
            let containersNotAdvisable = containersToAdvise.filter(c => !this.selectedContainerIds.includes(c));
            if(containersNotAdvisable.length > 0) {
                let warningMessage = 'The following container/s are assigned to your company, but cannot be advised as they are not currently editable:\n' + containersNotAdvisable.join(", ");
                if(this.selectedContainerIds.length > 0)
                    this.$refs.multiContainerSetGroupPanel.setWarningMessage(warningMessage);
                else
                    this.claimContainersWarnings = warningMessage;
            }

            //only show side panel if we actually have containers selected
            if(this.selectedContainerIds.length > 0) {
                this.showSidePanel(true, scrollToGrid);
            }
        },

        splitMulti(str, tokens) {
            if(!str || !tokens)
                return [];

            var tempChar = tokens[0]; // use the first token as a temporary join character
            for(var i = 1; i < tokens.length; i++){
                str = str.split(tokens[i]).join(tempChar);
            }
            str = str.split(tempChar);
            return str;
        },

        exportXlsFile() {
            App.$appAnalytics.trackEvent('IA - Export xls file Click');
            this.$refs.importContainerList.exportXlsFile();
        },

        clearSelectedContainers() {
            this.$refs.importContainerList.clearSelection();
        },

        clearGridFilters() {
            this.$refs.importContainerList.clearFilter();
        },

        repaintGrid() {
            //we need to allow a bit of time for any non-datagrid layout changes to be applied 
            //or finish (i.e. transition animations) before getting the the datagrid to update itself
            setTimeout(async () => {
                this.$refs.importContainerList.repaintGrid();
            }, 500);
        },

        showSidePanel(show, scrollToView = true) {
            this.clearGridFilters();
            this.searchText = "";
            this.viewSelectedContainersOnly = show;

            //we need to allow a bit of time for filter to finish before scrolling to ensure UI transitions are smooth 
            setTimeout(async () => {
                //scroll to top of datagrid/panel
                if (show && scrollToView)
                    this.$vuetify.goTo('#importAdvisedContainersSection', {duration: 0, easing: 'linear'})         
            }, 100)
                     
            //we need to allow a bit of time for filter and scroll to finish before showing side panel to ensure UI transitions are smooth
            setTimeout(async () => {
                this.showSetGroupPanel = show;
                if (!show)
                    this.$refs.multiContainerSetGroupPanel.clearValidationErrors();
                this.repaintGrid();
            }, 500)
        },

        dayOfWeekAsString(dayIndex) {
            return ["Sunday", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][dayIndex % 7] || '';
        }

    }
};
</script>

<style scoped>

    #inboundVesselsSlider {
        margin-bottom: 15px;
    }

    #addImportContainerSection {
        padding-left: 0px;
    }

    #addImportContainerSection .v-textarea {
        padding-bottom: 10px;
    }

    .columnWordWrap {
        white-space: break-spaces;
    }

    #importAdvisedContainersSection {
        overflow: auto;
    }

    #importAdvisedContainersSection.groupSelectionPanelScrollfix {
        /* ensures screen doesn't unnecessarily scroll up when filtering to selected 
           items only and showing group selection panel */
        min-height: 700px;
    }

    #multiContainerSetGroupPanel {
        /* ensures panel borders correctly shows when a large number of containers selected */ 
        height: 100%;
    }
    
    #importAdviceUnavailableAlert {
        margin-bottom: 0px;
    }

</style>
<style>
    /* these styles don't work with scoped */
    #importContainersToAddField textarea {
        text-transform: uppercase;
    }

    #importContainersToAddField .v-messages {
        white-space: pre-wrap;
    }

    #importContainersToAddField .v-messages__message {
        line-height: inherit;
    }

</style>