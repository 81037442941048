<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels focusable>
            <v-expansion-panel
                v-for="(item,i) in formMenus"
                :key="i"
            >
              <v-expansion-panel-header
                  class="justify-self-start"
                  :color=" item.formIsValid ? '#c0d830': '#dcdbdc'"
                  disable-icon-rotate
                  style="padding: 7px 23px !important;"
              >
                <div>
                  <v-container>
                    <v-row>
                      <v-col cols="3" xl="3" lg="4" md="4">
                        <v-icon style="font-size: 18px; !important;color: rgba(0, 0, 0, 0.54)">{{ item.icon }}</v-icon>
                        <strong><span style="margin-left: 10px;font-size: 17px;color: rgba(0, 0, 0, 0.54)"> {{ item.name }} </span></strong>
                        <span style="font-size: 17px;color: rgba(0, 0, 0, 0.54)">{{ item.optional ? '(Optional)' : '' }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>

              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <component v-bind:is="formMenus[i].component"></component>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TransportPlannerFormCustomerDetails
  from "@/components/transportCapacity/planner/TransportPlannerForm-CustomerDetails.vue";
import TransportPlannerFormKiwiRailDetails
  from "@/components/transportCapacity/planner/TransportPlannerForm-KiwiRailDetails.vue";
import TransportPlannerFormContainerDetails
  from "@/components/transportCapacity/planner/TransportPlannerForm-ContainerDetails.vue";
import TransportPlannerFormVesselDetails
  from "@/components/transportCapacity/planner/TransportPlannerForm-VesselDetails.vue";
import TransportPlannerFormRoadBridgeDetails
  from "@/components/transportCapacity/planner/TransportPlannerForm-RoadBridgeDetails.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "TransportPlannerForm",

  components : {
    TransportPlannerFormCustomerDetails,
    TransportPlannerFormKiwiRailDetails,
    TransportPlannerFormContainerDetails,
    TransportPlannerFormVesselDetails,
    TransportPlannerFormRoadBridgeDetails
  },

  data() {
    return {
      formMenus : [
        {
          name: 'Customer Details',
          icon: 'fa-solid fa-address-book',
          component: TransportPlannerFormCustomerDetails,
          optional: false,
          headerInfo: '',
          formIsValid: false
        },
        {
          name: 'Kiwi-Rail Details',
          icon: 'fa-regular fa-train',
          component: TransportPlannerFormKiwiRailDetails,
          optional: false,
          headerInfo: '',
          formIsValid: false
        },
        {
          name: 'Container Details',
          icon: 'fa-solid fa-container-storage',
          component: TransportPlannerFormContainerDetails,
          optional: false,
          headerInfo: '',
          formIsValid: false
        },
        {
          name: 'Vessel Details',
          icon: 'mdi-ferry',
          component: TransportPlannerFormVesselDetails,
          optional: true,
          headerInfo: '',
          formIsValid: false
        },
        {
          name: 'RoadBridge Details',
          icon: 'mdi-truck-fast-outline',
          component: TransportPlannerFormRoadBridgeDetails,
          optional: true,
          headerInfo: '',
          formIsValid: false
        },
      ]
    }
  },

  computed : {
    ...mapState('transportPlanner', [
        'workingPlannerBooking'
    ]),

    ...mapGetters('transportPlanner', [
        'customerFormValidity',
        'kiwiRailFormValidity',
        'containerFormValidity'
    ]),

    allForms() {
      return [
        this.customerFormValidity,
        this.kiwiRailFormValidity,
        this.containerFormValidity
      ];
    },

    allFormsAreValid() {
      return !Object.values(this.allForms).includes(false);
    }
  },

  watch : {
    customerFormValidity(val) {
      this.formMenus[0].formIsValid = val;
    },

    kiwiRailFormValidity(val) {
      this.formMenus[1].formIsValid = val;
    },

    containerFormValidity(val) {
      this.formMenus[2].formIsValid = val;
    },

    allFormsAreValid(val) {
      this.$emit('formIsValid', val);
    },

  },

  methods: {
    setFormForEdit() {
      this.formMenus[0].formIsValid = true;
      this.formMenus[1].formIsValid = true;
      this.formMenus[2].formIsValid = true;
    }
  }
}
</script>

<style scoped>

</style>