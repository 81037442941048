<template>
    <DxDataGrid
        id="importAdvisedContainerGrid"
        :ref="dgImportAdvisedRefName"
        :data-source="importAdvisedContainersToView"
        keyExpr="containerNumber"                
        noDataText="No current import advised containers"
        @editor-preparing="onEditorPreparing"
        @selection-changed="onContainerSelectionChanged"
        :show-borders="false"
        :hoverStateEnabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :class="{readonly: readonly}"            
    >
        <DxHeaderFilter :visible="true" />
        <DxSorting mode="multiple"/>

        <DxPaging :page-size="25"/>
        <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
        />

        <DxSelection :mode="!readonly ? 'multiple' : 'none'" showCheckBoxesMode="always" />

        <DxColumn :allowEditing="false"
                    caption="" width="60" alignment="center" :allowSearch="false" cssClass="ImportAdvisedStatusColumn"
                    cell-template="statusCellTemplate" />
            <template #statusCellTemplate="{ data }">
                <ImportContainerStatusIcon :set="status = calculateImportStatus(data.data)"
                    :colour="status.colour"
                    :icon="status.icon"
                    :description="status.description" />
            </template>
        <DxColumn data-field="containerNumber" data-type="string" :allowEditing="false"
                    caption="Container #" />
        <DxColumn data-field="lineOperator" data-type="string" :allowEditing="false"
                    caption="Shipping Line" width="120" :hiding-priority="2" :allowSearch="false" />
        <DxColumn data-field="inBoundActualVisitId" data-type="string" :allowEditing="false"
                    caption="I/B Visit" />                    
        <DxColumn data-field="inBoundActualETA" data-type="date" :allowEditing="false"
                    caption="Vessel ETA" format="EEE dd-MM HH:mm" :allowSearch="false" />
        <DxColumn data-field="group"
                    caption="Day/Group" :allowEditing="false"
                    :header-filter="{ dataSource: containerGroupHeaderOptions }"
                    :calculate-filter-expression="calculateGroupFilterExpression"
                    cell-template="groupCellTemplate" />
            <template #groupCellTemplate="{ data }">
                <ContainerSetGroupSelect
                    :readOnly="!isContainerEditable(data.data) || readonly"
                    :locked="isContainerLocked(data.data) && !readonly"
                    :importContainer=data.data />
            </template>
        <DxColumn data-field="transitState" data-type="string" :allowEditing="false"
                    caption="Location" :hiding-priority="4" :allowSearch="false" :calculate-cell-value="calculateLocationColumn" />                
        <!-- wanting Holds header to be alligned left for better display when sorting, but contents to be centered -->
        <DxColumn data-field="impediments" data-type="string" :allowEditing="false"
                    caption="Holds" width="100" alignment="left" :allowSearch="false" cssClass="CenteredColumn"
                    :header-filter="{ dataSource: containerHoldsHeaderFilterOptions }"
                    :calculate-filter-expression="calculateHoldFilterExpression"
                    cell-template="holdCountCellTemplate" />
            <template #holdCountCellTemplate="{ data }">
                <v-tooltip 
                    v-if="data.value.length > 0"
                    right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip small outlined color="#595859" v-bind="attrs" v-on="on">
                            {{formatHoldsCount(data)}}
                        </v-chip>
                    </template>
                    <span>
                        {{formatHolds(data)}}
                    </span>
                </v-tooltip>                         
            </template>
        <DxColumn data-field="impediments" data-type="string" :allowEditing="false"
                    caption="Hold Types" :customize-text="formatHolds" :hiding-priority="1" :allowSearch="false"
                    :header-filter="{ dataSource: containerHoldsHeaderFilterOptions }"
                    :calculate-filter-expression="calculateHoldFilterExpression" />
        <DxColumn data-field="isoType" data-type="string" :allowEditing="false" 
                    caption="ISO Type" width="100" :allowSearch="false"
                    :header-filter="{ dataSource: isoTypeHeaderFilterOptions }" />
        <!-- wanting Reefer header to be alligned left for better display when sorting, but contents to be centered -->
        <DxColumn data-field="requiresPower" data-type="boolean" :allowEditing="false"
                    caption="Reefer" width="80" alignment="left" cssClass="CenteredColumn" />
        <DxColumn data-field="vgmWeight" data-type="string" :allowEditing="false" :allowHeaderFiltering="false"
                    caption="Weight (kg)" width="100" alignment="right" :allowSearch="false" :hiding-priority="3" />

    </DxDataGrid>
</template>

<script>
import ImportContainerStatusIcon from './ImportContainerStatusIcon.vue';
import ContainerSetGroupSelect from './ContainerSetGroupSelect.vue';
import {importAdviceHelpers} from '@/store/modules/importAdvice.js';
import {storeVesselHelpers} from "@/store/modules/vessels.js";
import { mapState, mapActions, mapGetters } from 'vuex';

import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSorting,
    DxSelection,
} from 'devextreme-vue/data-grid';

let selectAllCheckBox;
let checkBoxUpdating = false;

export default {
    components: {
        ImportContainerStatusIcon,
        ContainerSetGroupSelect,
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxPager,
        DxPaging,
        DxSorting,
        DxSelection
    },

    props: {
        readonly: { type: Boolean, default: () => false },
        loading: { type: Boolean, default: () => false },        
        searchText: String,
        viewSelectedContainersOnly: { type: Boolean, default: () => false },
    },

    data () {
        return {
            dgImportAdvisedRefName: 'importAdvisedContainerGrid',
            pageSizes: [25, 50, 100],
            isoTypeHeaderFilterOptions:  [{
                    text: '20\'',
                    value: ['isoType', 'startswith', 2]
                },{
                    text: '40\'',
                    value: ['isoType', 'startswith', 4]
            }],
            calculateGroupFilterExpression(filterValue, selectedFilterOperations, target) {
                let column = this;
                if(target === 'headerFilter' && filterValue === '') {
                    return function(data) {
                        return importAdviceHelpers.isNoGroup(data.group);
                    }
                }
                return column.defaultCalculateFilterExpression.apply(this, arguments);
            },
            calculateHoldFilterExpression(filterValue, selectedFilterOperations, target) {
                let column = this;
                if(target === 'headerFilter') {
                    return function(data) {
                        return data.impediments.includes(filterValue);
                    }
                }
                return column.defaultCalculateFilterExpression.apply(this, arguments);
            }
        }
    },

    computed: {
        ...mapState('importAdvice', [
            'importContainers',
            'selectedContainerIds',
            'importDayGroups'
        ]),

        ...mapGetters('importAdvice', [
            'selectedImportContainers',
        ]),

        ...mapGetters('settings', [
            'importAdvice',
        ]),

        importAdvisedContainersToView() {
            if (!this.viewSelectedContainersOnly)
                return this.importContainers;
            else {
                return this.selectedImportContainers;
            }
        },

        containerGroupHeaderOptions() {
            //get applicable block groups
            let groups = [...new Set(
                this.importAdvisedContainersToView
                    .filter(c => importAdviceHelpers.isContainerInBlockStack(c))
                    .map(c => c.group)
            )].sort();

            //get applicable days groups
            this.importDayGroups.forEach(dg => {
                if(this.importAdvisedContainersToView.some(c => c.group == dg.groupId))
                    groups.push(dg.groupId);
            });
            
            //add no group at start
            if(this.importAdvisedContainersToView.some(c => importAdviceHelpers.isContainerInNoStack(c)))
                groups.unshift("");

            //get full descriptions
            let options = groups.map(g => 
                ({text: this.formatImportGroup(g), value: g})
            );

            return options;
        },

        containerHoldsHeaderFilterOptions() {
            let holds = [];

            //get applicable days groups
            this.importAdvisedContainersToView.forEach(c => {
                c.impediments.forEach(i => {
                    if(!holds.includes(i))
                        holds.push(i);
                })
            });

            return holds.sort().map(g => 
                ({text: g, value: g})
            );
        }
    },

    watch: {
        searchText: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            this.$refs[this.dgImportAdvisedRefName].instance.searchByText(newVal?.trim());
        },

        loading: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            if(newVal)
                this.$refs[this.dgImportAdvisedRefName].instance.beginCustomLoading();
            else
                this.$refs[this.dgImportAdvisedRefName].instance.endCustomLoading();
        },

        //would ideally just use the DxDataGrid's :selected-row-keys property to keep selected containers in sync, but this
        //is very unperformant as it refreshes every displayed row everytime a single row is selected or unselected.
        //therefore have gone with manually selecting the rows whenever either the selected containers change
        selectedContainerIds: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            //need to have a very slight delay to allow datagrid datasource to include any new containers that may have just been added     
            setTimeout(async () => {
                this.updateSelectedRows();     
            }, 100)
        },
    },

    mounted () {
        //ensure selected rows are shown - used for maintaining UI when developing
        this.updateSelectedRows();  
    },

    methods: {
        ...mapActions('importAdvice', [
            'setSelectedContainerIds',
        ]),

        //pubic methods
        clearFilter() {
            this.$refs[this.dgImportAdvisedRefName].instance.clearFilter();
        },

        repaintGrid() {
            this.$refs[this.dgImportAdvisedRefName].instance.updateDimensions();
            this.$refs[this.dgImportAdvisedRefName].instance.repaint();
        },

        exportXlsFile() {
            this.$refs[this.dgImportAdvisedRefName].instance._options.export.fileName = "Propel Import Advised Containers"
            this.$refs[this.dgImportAdvisedRefName].instance.exportToExcel(false);
        },

        clearSelection() {
            this.$refs[this.dgImportAdvisedRefName].instance.clearSelection();
        },

        //internal methods 
        updateSelectedRows() {
            this.$refs[this.dgImportAdvisedRefName].instance.selectRows(this.selectedContainerIds);
        },

        formatImportGroup(group) {
            return importAdviceHelpers.getGroupDescription(group);
        },

        formatHolds(cellInfo) {
          let content = "Req Event: UNIT_CHARTED";
          let filtered = cellInfo.value.filter(function (str) {
            return str.indexOf(content) !== 0;
          });
          return filtered.join(", ");
        },

        formatHoldsCount(cellInfo) {
            return cellInfo.value.length > 0 ? cellInfo.value.length.toString() : "";
        },

        calculateLocationColumn(rowData) {
            return rowData.transitState == "Yard" ? "Yard" : "Inbound";
        },

        isContainerEditable(container) {
            return importAdviceHelpers.isContainerGroupEditable(container);
        },

        isContainerLocked(container) {
            return storeVesselHelpers.isContainerVesselLockedForImport(container) && container.transitState != 'Yard'
        },

        calculateImportStatus(container) {
            if (this.isContainerLocked(container))
                return this.importStatus('mdi-lock', 'gray', "Vessel is locked for planning");
            
            if (importAdviceHelpers.isContainerInBlockStack(container)) {
                let numberOfContainersInStack = this.importContainers.filter(c => c.group == container.group).length;
                if(numberOfContainersInStack < this.importAdvice.minimumStackSize)
                    return this.importStatus('mdi-alert-circle', 'error', "Not enough containers in the block group. You need " + (this.importAdvice.minimumStackSize - numberOfContainersInStack) + " more.");
            }

            if (container.appointmentId)
                return this.importStatus('mdi-truck-check', 'propelDarkGreen', "VBS appointment exists");
            
            if (importAdviceHelpers.isContainerInNoStack(container) && container.transitState == 'Yard')
                return this.importStatus('mdi-alert-circle', 'warning', "Group required");
            
            if (!importAdviceHelpers.isContainerInNoStack(container))
                return this.importStatus('mdi-check-circle', 'propelDarkGreen', '');
            
            return this.importStatus('', '', '');
        },

        importStatus(icon, colour, description) {
            return {icon:icon, colour: colour, description};
        },

        //below methods are for implementing multi-select where some checkboxes are disabled
        //----------------------------------------------------------------------------------
        //we want to disable some of the multi-select checkboxes depending on the container status
        //there is no built in way to do this curently in dxdatagrid, so we must do this manually as below 
        //and in onContainerSelectionChanged() - this also caters for the select all checkbox in the header
        //see https://supportcenter.devexpress.com/ticket/details/t869704/datagrid-how-to-conditionally-disable-selection-checkboxes#
        onEditorPreparing(e) {
            let dataGrid = e.component;
            if (e.command === "select") {
                if (e.parentType === "dataRow" && e.row) {
                    if (!this.isSelectable(e.row.data)) {
                        e.editorOptions.disabled = true; //e.editorOptions.visible = false;
                        e.editorOptions.hint = "No selectable";
                    }
                } else if (e.parentType === "headerRow") {
                    e.editorOptions.onInitialized = (e) => {
                        selectAllCheckBox = e.component;
                    };
                    e.editorOptions.value = this.isSelectAll(dataGrid);
                    e.editorOptions.onValueChanged = (e) => {
                        if (!e.event) {
                            if (e.previousValue && !checkBoxUpdating) {
                                e.component.option("value", e.previousValue);
                            }
                            return;
                        }
                        if (this.isSelectAll(dataGrid) === e.value) {
                            return;
                        }
                        e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
                        e.event.preventDefault();
                    }
                }
            }
        },

        onContainerSelectionChanged(e) { // eslint-disable-line no-unused-vars
            this.setSelectedContainerIds(e.selectedRowKeys);

            let deselectRowKeys = [];
            e.selectedRowsData.forEach((item) => {
                if (!this.isSelectable(item))
                    deselectRowKeys.push(e.component.keyOf(item));
            });

            if (deselectRowKeys.length) {
                e.component.deselectRows(deselectRowKeys);
            }
            
            checkBoxUpdating = true;
            selectAllCheckBox.option("value", this.isSelectAll(e.component));
            checkBoxUpdating = false;
        },

        isSelectAll(dataGrid) {
            let items = [];
            dataGrid.getDataSource().store().load().done(function (data) {
                items = data;
            });

            let selectableItems = items.filter(this.isSelectable);
            let selectedRowKeys = dataGrid.option("selectedRowKeys");
            if (!selectedRowKeys.length) {
                return false;
            }
            return selectedRowKeys.length >= selectableItems.length ? true : undefined;
        },

        isSelectable(container) {
            return importAdviceHelpers.isContainerSelectable(container);
        },

    }
};
</script>

<style>
    /* these styles don't work with scoped */
    #importAdvisedContainerGrid .importGroupColumnSelect[role="gridcell"] {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #importAdvisedContainerGrid .importGroupColumnSelect[role="gridcell"] .dx-texteditor-input:not([aria-readonly="true"]),
    #importAdvisedContainerGrid .importGroupColumnSelect[role="gridcell"] .dx-dropdowneditor-icon,
    #importAdvisedContainerGrid .importGroupColumnSelect[role="gridcell"] .dx-placeholder {
        color: #4630d8 !important;
    }

    #importAdvisedContainerGrid .importGroupColumnSelect[role="gridcell"] .dx-texteditor-input {
        font-size: 14px;
    }

    #importAdvisedContainerGrid .importGroupColumnSelect[role="gridcell"] .dx-texteditor.dx-editor-underlined:after {
        border-bottom-width: 0px;
    }

    #importAdvisedContainerGrid.readonly .dx-datagrid-headers {
        background-color: rgba(0, 0, 0, 0.12);
    }

    /* don't show cell focus underline */
    #importAdvisedContainerGrid .dx-datagrid-focus-overlay {
        visibility: hidden !important;
    }    

    #importAdvisedContainerGrid .dx-editor-cell .dx-texteditor .dx-texteditor-input {
        height: 20px;
    }

    #importAdvisedContainerGrid .v-chip.v-size--small {
        padding: 0 8px; 
        height: 24px;
        line-height: 24px;
    }    

    #importAdvisedContainerGrid .dx-group-row {
        background-color: rgba(0,0,0,.12);
    }

    #importAdvisedContainerGrid .dx-group-row .dx-datagrid-group-space {
        display:none
    }

    #importAdvisedContainerGrid .dx-command-select .dx-state-disabled .dx-checkbox-icon {
        border-color: rgba(0,0,0,.26);
    }

    #importAdvisedContainerGrid .ImportAdvisedStatusColumn {
        /* required to stop issue with scrolling on the grid with lock icon being smaller than others */
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    #importAdvisedContainerGrid .CenteredColumn {
        /* wanting header to be alligned left for better display when sorting, but contents to be centered */
        text-align: center !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #importAdvisedContainerGrid .v-btn.v-btn--disabled .v-btn__content {
        color: rgba(0, 0, 0, 0.68);
    }

    #importAdvisedContainerGrid .groupCellLockedIcon {
        vertical-align: text-top;
        padding-right: 2px;
    }

</style>