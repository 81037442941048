<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-form ref="vesselForm">
            <v-row dense>
              <v-col cols="2">
                <v-text-field
                    v-model="vesselData.vesselVisitId"
                    label="Vessel"
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TransportPlannerForm-VesselDetails",

  data() {
    return {

    }
  },

  computed : {
    ...mapState('transportPlanner', [
        'workingPlannerBooking'
    ]),

    vesselData() {
      return this.workingPlannerBooking.vesselDetails;
    }
  }
}
</script>

<style scoped>

</style>