import utils from '@/utils/vuexUtils.js'
import CMSApi from "@/services/CMSApi";

// initial state
const state = {
    // Objects
    reportList: [],
    reportColumns: [],
    reportFilters: [],
    reportData: [],

    // Indicators
    loadingReportList: false,
    loadingReportColumns: false,
    loadingReportFilters: false,
    loadingReportData: false,
    initialLoadOfUserReport: true,
}

// getters
const getters = {
    getReportName: state => (id) => {
        return state.reportList.find(g => g.reportId === id)["reportName"];
    },

    getReportColumn: state => {
        return state.reportColumns;
    }
}

// actions
const actions = {
    fetchUserReportList: ( { commit, state} ) => {
      state.loadingReportList = true;
      return utils.CommitArrayPromise(commit, CMSApi.fetchUserReportList(), 'setReportList')
    },

    fetchReportColumnList: ( { commit, state }, reportId) => {
        state.loadingReportColumns = false;
        return utils.CommitArrayPromise(commit, CMSApi.fetchReportColumnList(reportId), 'setReportColumns')
    },

    fetchReportFilterList: ( { commit, state }, reportId) => {
        state.loadingReportFilters = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchReportFilterList(reportId), 'setReportFilters')
    },

    fetchReportRecord: ( {commit, state }, reportId) => {
        state.loadingReportData = true;
        return utils.CommitArrayPromise(commit, CMSApi.postFetchReportRecord(reportId, state.reportFilters), 'setReportData')
    },
}

// mutations
const mutations = {
    setReportList(state, result) {
        const resultData = result.shift();
        state.loadingReportList = false;
        state.reportList = resultData.reports;
        if (state.initialLoadOfUserReport)
            state.initialLoadOfUserReport = false;
    },

    setReportColumns(state, reportColumns) {
        state.loadingReportColumns = false;
        state.reportColumns = reportColumns;
    },

    setReportFilters(state, reportFilters) {
        state.loadingReportFilters = false;

        reportFilters.filter(ele => {
           if (ele.filterDataType === 'selection')
           {
               ele.items = ele.filterOptionValue.split(',');
           }

           if (ele.filterDataType === 'date')
           {
               if (ele.filterDefaultValue !== null)
               {
                   let items = ele.filterDefaultValue.split(',');

                   switch (true) {
                       case (items[0].includes('GETDATE()')):
                           ele.filterDefaultValue = items[1] === '-' ? window.Moment().subtract(items[2], 'days').format('YYYY-MM-DD') : window.Moment().add(items[2], 'days').format('YYYY-MM-DD');
                           break;
                       case (items[0].includes('StartOfMonth')):
                           ele.filterDefaultValue = window.Moment().clone().startOf('month').format('YYYY-MM-DD');
                           break;
                       case (items[0].includes('EndOfMonth')):
                           ele.filterDefaultValue = window.Moment().clone().endOf('month').format('YYYY-MM-DD');
                           break;
                   }
               }
           }

           return ele;
        });

        state.reportFilters = reportFilters.map(obj => ({ ...obj, date: null }));
    },

    setReportData(state, reportData) {
        state.loadingReportData = false;
        state.reportData = reportData;
    },

    resetReportFilters() {
        state.reportFilters = [];
    },

    resetReportData(state) {
        state.reportData = [];
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}