<template>
    <NotificationList />
</template>

<script>
import NotificationList from '@/components/portAdmin/notification/NotificationList.vue'

export default {   
    name: 'admin-notifications',
    components: {
        NotificationList,
    },
};
</script>