<template>
  <v-card elevation="3">
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-text-field
              ref="serviceName"
              v-model="tsDetailsData.trainServiceName"
              type="text"
              label="Service Name"
              clearable
              :rules="serviceNameRules"
              tabindex="1"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-switch
              v-model="tsDetailsData.isActive"
              inset
              :label="swText"
          ></v-switch>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="1" lg="2" class="text-right">
          <v-checkbox
              v-model="tsDetailsData.isAdHoc"
              label="Ad Hoc?"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-select
              v-model="tsDetailsData.trainServiceDirection"
              :items="TrainServiceDirection()"
              item-text="value"
              item-value="value"
              label="Service Direction"
              clearable
              :rules="serviceDirectionRules"
              tabindex="2"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-textarea
              v-model="tsDetailsData.trainServiceNotes"
              label="Notes"
              rows=1
              auto-grow
              tabindex="3"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="secondary"
          text
          :disabled="!formIsValid"
          @click="nextForm"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {TrainServiceDirection} from "@/utils/constants";

export default {
  name: "TransportServiceForm-Details",

  data() {
    return {
      // Form validation
      serviceNameRules: [
        val => !!val || 'Service Name is required'
      ],
      serviceDirectionRules: [
        val => !!val || 'Service Direction is required'
      ],

      //Indicators
      stillOnReversingPhase: false,

      //
      defaultData: [],
    }
  },

  computed : {
    ...mapState('trainService', [
        'workingTrainService',
        'retrievingTrainService',
    ]),

    ...mapGetters('trainService', [
    ]),

    isUpdate() {
      return this.workingTrainService.trainServiceId && this.workingTrainService.trainServiceId > 0;
    },

    tsDetailsData() {
      return this.workingTrainService.trainServiceDetails;
    },

    swText() {
      return this.tsDetailsData.isActive ? 'Active': 'Idle';
    },

    serviceNameIsValid() {
      return this.isNotNullOrEmpty(this.tsDetailsData.trainServiceName);
    },

    serviceDirectionIsValid() {
      return this.isNotNullOrEmpty(this.tsDetailsData.trainServiceDirection);
    },

    formIsValid() {
      return this.serviceNameIsValid && this.serviceDirectionIsValid;
    },

    serviceRoutesCount() {
      return this.workingTrainService.trainServiceRoutes.wagons.length;
    },

    formHasChanged() {
      return Object.keys(this.tsDetailsData).some(field => this.tsDetailsData[field] !== this.defaultData[field])
    },
  },

  watch: {
    formIsValid(val) {
      this.setServiceDetailsForm(val);
    },

    async 'tsDetailsData.trainServiceDirection'(val, oldVal) {
      if (this.serviceRoutesCount > 0 && !this.stillOnReversingPhase && !this.retrievingTrainService) {
        this.stillOnReversingPhase = true;

        if(await this.$root["$confirm"].open('Direction and Routes mismatch', 'The service direction you selected is ' + val + ' but there are routes created for ' + oldVal +'. ' +
            'Proceeding would mean to reset the routes list you initially created. Do you want to proceed ?', { color: 'warning' }))
        {
          await this.resetRoutesData();
          setTimeout(async () => {
            this.stillOnReversingPhase = false;
          }, 1000);
        } else {
          this.tsDetailsData.trainServiceDirection = oldVal;
          setTimeout(async () => {
            this.stillOnReversingPhase = false;
          }, 1000);
        }
      }
    },

    async 'tsDetailsData.isAdHoc'(val, oldVal) {
      if (this.serviceRoutesCount.length > 0 && !this.stillOnReversingPhase) {
        this.stillOnReversingPhase = true;

        if(await this.$root["$confirm"].open('Ad-hoc service?', 'You have created wagons, activating ad-hoc will remove all wagons created. Proceed ?', { color: 'warning'} )) {
          await this.resetRoutesData();
          setTimeout(async () => {
            this.stillOnReversingPhase = false;
          }, 1000);
        } else {
          this.tsDetailsData.isAdHoc = oldVal;
          setTimeout(async () => {
            this.stillOnReversingPhase = false;
          }, 1000);
        }
      }
    },

    isUpdate: {
      immediate: true,
      handler(val) {
        if (val) {
          this.defaultData = Object.assign({}, this.tsDetailsData);
        }
      }
    },

    formHasChanged(val) {
      this.setTSDetailsChangeState(val);
    },
  },

  methods: {
    TrainServiceDirection() {
      return TrainServiceDirection
    },
    ...mapMutations('trainService', [
        'setServiceDetailsForm',
        'resetRoutesData',
        'setTSDetailsChangeState'
    ]),

    isNotNullOrEmpty(val) {
      return (val !== "" && val !== null);
    },

    nextForm() {
      this.$emit('nextForm');
    }
  }



}
</script>

<style scoped>

</style>