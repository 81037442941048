<template>
    <v-app>
        <template v-if="$auth.hasAccess">
            <Navigation :showDrawer.sync="showDrawer"  />
            <AppBar v-on:menu-click="showDrawer = !showDrawer" />
            <v-main class="ma-5" :class="[$vuetify.breakpoint.lgAndUp && showDrawer ? 'mainContentWithMiniVariantNavigation' : '']">
                <router-view></router-view>
            </v-main>
        </template>
        <template v-else>
            <AppBar />
            <v-main>
                <router-view></router-view>
            </v-main>
        </template>

        <SnackbarAlert />

        <ConfirmDialog ref="confirm"></ConfirmDialog>
        <AlertDialog ref="alert"></AlertDialog>
        <SnackbarAlertsWithUndo ref="snackUndo"></SnackbarAlertsWithUndo>

    </v-app>
</template>


<script>
import { mapActions } from 'vuex';
import AppBar from '@/components/global/AppBar.vue'
import Navigation from '@/components/global/Navigation.vue'
import SnackbarAlert from '@/components/global/SnackbarAlerts.vue'
import SnackbarAlertsWithUndo from "@/components/global/SnackbarAlertsWithUndo";
import ConfirmDialog from '@/components/global/ConfirmDialog.vue'
import AlertDialog from '@/components/global/AlertDialog.vue'

export default {
    name: 'App',
    components: {
        AppBar,
        Navigation,
        SnackbarAlert,
        ConfirmDialog,
        AlertDialog,
        SnackbarAlertsWithUndo
    },

    data: () => ({
        showDrawer: true,
    }),

    mounted () {
        //helpful for debugging layout
        console.log("Screen breakpoints", this.$vuetify.breakpoint);

        this.$root.$confirm = this.$refs.confirm;
        this.$root.$alert = this.$refs.alert;
        this.$root.$snackUndo = this.$refs.snackUndo;

        this.fetchAppSettings();

        //want to continously fetch notifications, but want first call to wait slightly to allow authentication to finish
        //a bit ugly - could potentially use event bus to watch for this.$auth.loading or this.$auth.isauthenticated changes instead
        setTimeout(() => this.continouslyFetchNotifications(), 2000);
    },

    methods: {
        ...mapActions('settings', [
            'fetchAppSettings',
        ]),

        ...mapActions('notifications',[
            'fetchNotifications'
        ]),

        continouslyFetchNotifications() {
            this.fetchNotificationsIfAuthenticated();

            setInterval(this.fetchNotificationsIfAuthenticated.bind(this), 60000);
        },

        fetchNotificationsIfAuthenticated() {
            if(this.$auth.isAuthenticated)
                this.fetchNotifications(true);
        }
    }
};
</script>

<style>

    /* current bug with v-navigation-drawer.expand-on-hover where if you shrink and then expand screen the
       v-content area will not have the correct padding applied (it will apply the padding of the expanded nav)
    */
    .mainContentWithMiniVariantNavigation
    {
        padding-left: 55px !important;
    }

    .container{
        max-width: 100% !important;
    }
   
</style>