import moment from "moment";
import store from "@/store";
import {TrainServiceDirection} from "@/utils/constants";

class TrainServiceDetails {
    constructor() {
        this.trainServiceName = "";
        this.trainServiceDirection = "";
        this.trainServiceNotes = "";
        this.isActive = true;
        this.isAdHoc = false;
    }
}

class TrainServiceDays {
    constructor() {
        // Date pickers and Dates
        this.dates = [];
        this.date = null;
        this.dateRangeSelected = null;
        this.dateSelected = null;

        // Objects
        this.startDate = null;
        this.endDate = null;
        this.isOneDayService = false;
        this.oneDayServiceDate = null;
        this.days = [
            {
                id: null,
                day: "Monday",
                isActive: false,
                dbVal: false,
            },
            {
                id: null,
                day: "Tuesday",
                isActive: false,
                dbVal: false,
            },
            {
                id: null,
                day: "Wednesday",
                isActive: false,
                dbVal: false,
            },
            {
                id: null,
                day: "Thursday",
                isActive: false,
                dbVal: false,
            },
            {
                id: null,
                day: "Friday",
                isActive: false,
                dbVal: false,
            },
            {
                id: null,
                day: "Saturday",
                isActive: false,
                dbVal: false,
            },
            {
                id: null,
                day: "Sunday",
                isActive: false,
                dbVal: false,
            },
        ]
    }
}

class TrainServiceRoutes {
    constructor() {
        this.wagons = [];
    }
}

class WagonsRemoved {
    constructor() {
        this.wagons = [];
    }
}

class ChangesMade {
    constructor() {
        this.tSDetailsChanged = false;
        this.tSDatesChanged = false;
        this.tSDaysChanged = false;
        this.tSRoutesChanged = false;
    }
}

export default {
    tsDetails : function() {
        this.trainServiceId = null;
        this.trainServiceDetails = new TrainServiceDetails();
        this.trainServiceDays = new TrainServiceDays();
        this.trainServiceRoutes = new TrainServiceRoutes();
        this.trainServiceRoutesRemoved = new WagonsRemoved();
        this.trainServiceChanges = new ChangesMade();

        this.convertToSubmittableService = function() {
            // flatten it all out
            return {
                trainServiceId: this.trainServiceId,
                trainServiceDirection: this.trainServiceDetails.trainServiceDirection,
                trainServiceName: this.trainServiceDetails.trainServiceName,
                trainServiceNotes: this.trainServiceDetails.trainServiceNotes,
                startDate: this.trainServiceDays.isOneDayService ? this.trainServiceDays.oneDayServiceDate : this.trainServiceDays.startDate,
                endDate: this.trainServiceDays.isOneDayService ? this.trainServiceDays.oneDayServiceDate : this.trainServiceDays.endDate,
                isActive: this.trainServiceDetails.isActive,
                isAdHoc: this.trainServiceDetails.isAdHoc,
                days: this.trainServiceDays.days,
                wagons: this.trainServiceRoutes.wagons,
                isOneDayService : this.trainServiceDays.isOneDayService,
                TSDetailsChanged: this.trainServiceChanges.tSDetailsChanged,
                TSDatesChanged: this.trainServiceChanges.tSDatesChanged,
                TSDaysChanged: this.trainServiceChanges.tSDaysChanged,
                TSRoutesChanged: this.trainServiceChanges.tSRoutesChanged,
                TSRoutesRemoved: this.trainServiceRoutesRemoved.wagons
            }
        }

        this.setFromFlattenedData = async function(flattenedData) {

            this.trainServiceId = flattenedData.trainServiceId;
            this.trainServiceDetails.trainServiceDirection = TrainServiceDirection[flattenedData.trainServiceDirection].value;
            this.trainServiceDetails.trainServiceName = flattenedData.trainServiceName;
            this.trainServiceDetails.trainServiceNotes = flattenedData.trainServiceNotes;
            this.trainServiceDetails.isActive = flattenedData.isActive;
            this.trainServiceDetails.isAdHoc = flattenedData.isAdHoc;

            this.trainServiceDays.days.isOneDayService = flattenedData.isOneDayService;
            if (flattenedData.isOneDayService)
            {
                this.trainServiceDays.oneDayServiceDate = moment(flattenedData.startDate).format('YYYY-MM-DD');
                this.trainServiceDays.dateSelected = moment(flattenedData.startDate).format('YYYY-MM-DD');
            }
            else
            {
                let datesSelected = [];
                this.trainServiceDays.startDate = moment(flattenedData.startDate).format('YYYY-MM-DD');
                this.trainServiceDays.endDate = moment(flattenedData.endDate).format('YYYY-MM-DD');
                datesSelected.push(this.trainServiceDays.startDate,this.trainServiceDays.endDate);

                this.trainServiceDays.dates = datesSelected;
                this.trainServiceDays.dateRangeSelected = this.trainServiceDays.dates.join('~');
            }

            let trainServiceDays = [];
            for (let k = 0; k < flattenedData.days.length; ++k)
            {
                let newArr = {
                    trainServiceDayId: flattenedData.days[k]["trainServiceDayId"],
                    day: flattenedData.days[k].day,
                    isActive: flattenedData.days[k].isActive,
                    dbVal: flattenedData.days[k].isActive
                }

                trainServiceDays.push(newArr);
            }

            let trainServiceRoutes = [];
            for (let g = 0; g < flattenedData.wagons.length; ++g)
            {
                let newArr = {
                    trainServiceWagonId: flattenedData.wagons[g]["trainServiceWagonId"],
                    wagonSequence: flattenedData.wagons[g]['wagonSequence'],
                    originTerminalSiteId: flattenedData.wagons[g]['originTerminalSiteId'],
                    originCode: store.getters["trainService/getRouteCode"](flattenedData.wagons[g]['originTerminalSiteId']),
                    destinationTerminalSiteId: flattenedData.wagons[g]['destinationTerminalSiteId'],
                    destinationCode: store.getters["trainService/getRouteCode"](flattenedData.wagons[g]['destinationTerminalSiteId']),
                    capacityTeu: flattenedData.wagons[g].capacityTeu,
                    isActive: flattenedData.wagons[g]["isActive"],
                    hasChanged: false,
                    hasBooking : flattenedData.wagons[g]["hasBooking"],
                }
                trainServiceRoutes.push(newArr);
            }

            this.trainServiceDays.days = trainServiceDays;
            this.trainServiceRoutes.wagons = trainServiceRoutes;
        }
    }
}