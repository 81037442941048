<!--suppress CssUnresolvedCustomProperty, CssUnusedSymbol -->
<template>
    <v-navigation-drawer 
        v-model="showNavigationDrawer"
        :expand-on-hover="$vuetify.breakpoint.lgAndUp" 
        app clipped
        @transitionend="collapseSubItems"
        width="356"
    >
        <v-list id="navigationMenuItemList" dense>
            <div v-if="$auth.userHasSmartFlowRole() && $auth.userHasPropelRole()">
              <v-list-item to="/smartflow" exact>
                <v-list-item-action>
                  <v-badge :value="true"
                           class='newNavigationItemBadge' left overlap offset-y="5" content="NEW">
                    <v-icon>mdi-circle-double</v-icon>
                  </v-badge>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>SmartFlow</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
            <div v-for="(items,i) in this.menuItems" :key="i" >
                <v-divider v-if="items.meta['menuItem']['divider'] === true"></v-divider>
                <div v-if="items.meta['group'] === true">
                    <v-list-group
                        :prepend-icon="items.meta['menuItem']['icon']"
                        v-model="items.meta['active']"
                    >
                        <template v-slot:activator>
                          <v-list-item-title>{{ items.meta['menuItem']['description'] }}</v-list-item-title>
                        </template>

                        <div v-for="(firstGenChild,k) in items.children" :key="k">
                          <v-list-group v-if="firstGenChild.meta['group'] === true" style="padding-left: 40px"
                            no-action
                            sub-group
                          >
                            <template v-slot:activator>
                              <v-list-item-title>{{ firstGenChild.meta['menuItem']['description'] }}</v-list-item-title>
                            </template>
                            <div v-for="(secondGenChild,g) in firstGenChild.children" :key="g">
                              <v-list-item :to="secondGenChild.path" exact style="padding-left: 100px">
                                <v-list-item-title v-text="secondGenChild.meta['menuItem']['description']"></v-list-item-title>

                                <v-list-item-icon>
                                  <v-icon v-text="secondGenChild.meta['menuItem']['icon']"></v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </div>
                          </v-list-group>
                          <v-list-item v-else :to="firstGenChild.path" exact style="padding-left: 70px">
                            <v-list-item-title v-text="firstGenChild.meta['menuItem']['description']"></v-list-item-title>

                            <v-list-item-icon>
                              <v-icon v-text="firstGenChild.meta['menuItem']['icon']"></v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </div>
                    </v-list-group>
                </div>
                <v-list-item v-else :to="items.path" exact>
                  <v-list-item-action>
                    <v-badge :value="items.meta['menuItem']['new'] === true"
                             class='newNavigationItemBadge' left overlap offset-y="5" content="NEW">
                      <v-icon>{{ items.meta['menuItem']['icon'] }}</v-icon>
                    </v-badge>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ items.meta['menuItem']['description'] }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>

export default {
    props: {
        //would ideally use prop as a direct sync property or v-model in v-navigation-drawer, but the v-navigation-drawer
        //doesn't handle correctly, and it mucks up its internal value, and therefore I need to manually map between the
        //showDrawer prop and showNavigationDrawer internal data. Painful, but necessary.
        //could potentially look to making this a v-model based component and see if that works see https://alligator.io/vuejs/add-v-model-support/
        showDrawer: Boolean
    },

    data () {
        return {
            showNavigationDrawer: false,
            miniVariant: true,
            group: [],
            menuItems: [],
        }
    },

    mounted () {
        //on mobile by default we don't show menu, but on larger devices we do, so set initial value 
        //correctly and ensure parent component is set correctly
        this.showNavigationDrawer = this.$vuetify.breakpoint.lgAndUp;
        this.$emit('update:showDrawer', this.showNavigationDrawer);

        let routes = this.$router.options.routes.filter(
            //only routes that are defined as menu items
            route => route.meta != null && route.meta["menuItem"] != null
                //only routes that are not restricted or those whose roles matches the users
                && (route.meta.permittedRoles == null || this.$auth.userHasARole(route.meta.permittedRoles))
        );

        this.menuItems = routes;
    },

    watch: {
        showDrawer: function(newValue) {
            this.showNavigationDrawer = newValue;
        },
        showNavigationDrawer: function(newValue) {
            this.$emit('update:showDrawer', newValue);
        },
    },

    methods: {
      collapseSubItems() {
        this.menuItems.filter(g => g.meta["group"] === true).map((item)=>item.meta.active=false);
      },
    }
};
</script>

<style scoped>
    #navigationMenuItemList {
        color: var(--v-primary-base);
    }

    /*  have a slight delay on menu hover expand, so that moving the mouse to close 
        controls (e.g. dataGrid multi-select) doesn't accidentally trigger menu can be annoying  */
    .v-navigation-drawer--is-mouseover {
        transition-delay: 0.7s;
    }
</style>

<style>
    /* these styles don't work with scoped */
    .newNavigationItemBadge .v-badge__badge {
        font-size: 8px;
        height: 14px;
        width: 100%;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
        padding-top: 3px;
    }
</style>