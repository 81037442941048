import utils from '@/utils/vuexUtils.js'

//initial state
const state = {
    //Objects
    services : [],

    //Loading indicators
    vx_loadingServices: false,

    //Form
    formMode: 'add',
}

// getters
const getters = {

}

// actions
const actions = {
    fetchGateServices: ({ commit, state }) => {
        state.vx_loadingServices = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchGateServices(), 'setServices');
    },

    fetchAllGateServices: ({ commit, state }) => {
        state.vx_loadingServices = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchAllGateServices(), 'setServices');
    },
}

// mutations
const mutations = {

    setServices(state, services) {
        state.vx_loadingServices = false;
        state.services = services;
    },

    initEditMode(state) {
        state.formMode = 'edit';
    },

    resetMode(state) {
        state.formMode = 'add';
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}