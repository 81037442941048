import utils from '@/utils/vuexUtils.js'
import moment from "moment";

//initial state
const state = {
    //Objects
    gates: [],
    allGates: [],
    slotAvailability: [],

    //Loading Indicators
    loadingGates: false,

    //Zones
    allowedZones: [7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
    dateNow: moment().format('YYYY-MM-DD'),
    timeNow: moment().hours(),
}

// getters
const getters = {

    getGateName: (state) => (id) => {
        return state.allGates.find( ({n4GateId}) => n4GateId === id ).gateName;
    },

    getGates( state ) {
        return state.allGates;
    },

}

// actions
const actions = {
    fetchAllGates: ({ commit, state }) => {
        state.loadingGates = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchAllGates(), 'setAllGates');
    },

    fetchActiveGates: ({ commit, state }) => {
        state.loadingGates = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchActiveGates(), 'setGates');
    },

    async fetchSlots ({commit, state, getters, dispatch}, date) { // eslint-disable-line no-unused-vars
        state.loadingSlotAvailability = true;

        let promises = [];

        if (state.gates.length === 0)
            await dispatch('fetchActiveGates');

        state.gates.forEach(function(element) {
            promises.push(new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
                CMSApi.fetchAvailableSlots(date,false, element.gateId).
                then((data) => {
                    // var newObj = { [element.gateId] : data}
                    resolve(data);
                })
            }))
        })

        Promise.all(promises).then(x => { // eslint-disable-line no-unused-vars
            let zonesOfGates = [];
            for (let i = 0; i < x.length; i++) {
                let gateId = state.gates[i].gateId;
                zonesOfGates[gateId] = x[i];
            }
            commit('setZoneSlotAvailability', {zones: zonesOfGates, date: date});
        })
    }
}

// mutations
const mutations = {
    setGates( state, gates ) {
        state.gates = gates;
        state.loadingGates = false;
    },

    setAllGates( state, gates) {
        state.allGates = gates;
        state.loadingGates = false;
    },

    setZoneSlotAvailability( state, payload) { // eslint-disable-line no-unused-vars
        let computedZones = state.dateNow === payload.date ? state.allowedZones.filter( i => i >= state.timeNow) : state.allowedZones;

        state.slotAvailability = computedZones.map(zoneId => {
            return {
                zone: zoneId,
            }
        });

        let zoneGates = payload.zones; // eslint-disable-line no-unused-vars

        state.gates.forEach(function(element) { // eslint-disable-line no-unused-vars
            computedZones.forEach(g => { // eslint-disable-line no-unused-vars
                if (zoneGates[element.gateId].length === 0)
                {
                    state.slotAvailability[state.slotAvailability.findIndex( p => p.zone === g)][element.gateId] = 0;
                }
                else
                {
                    let gateZone = zoneGates[element.gateId].find(k => k.zone === g);
                    state.slotAvailability[state.slotAvailability.findIndex(p => p.zone === g)][element.gateId] = gateZone !== undefined ? gateZone["availableSlots"] : 0;
                }
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}