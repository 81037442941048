<template>
  <div>
      <v-icon color="propelDarkGreen" size="25" v-if="alerts.length === 0 && isAppointmentCurrent" >mdi-check-circle</v-icon>

      <v-tooltip
              v-if="alerts.length > 0 && isAppointmentCurrent"
              right>
          <template v-slot:activator="{ on, attrs }">
              <v-icon large :color="formatAlertLevelColour(overallAlertLevel)"
                      v-bind="attrs"
                      v-on="on"
              >{{ formatAlertLevelIcon(overallAlertLevel) }}</v-icon>
          </template>
          <span v-for="alert in alerts" :key="alert.description">
                {{alert.description}}<br/>
          </span>
      </v-tooltip>
  </div>
</template>

<script>
import {AlertLevel, AppointmentState} from "@/utils/constants";

export default {
    name: "Appointment-StatusIcon",

    props : {
        isAppointmentCurrent: Boolean,
        alertLevel: Number,
        alerts: Array,
        id: Number,
        status : String
    },

    data () {
        return {
        }
    },

    computed: {
        AppointmentState() {
            return AppointmentState
        },
        overallAlertLevel() {
            if(this.alerts.length > 0)
                return Math.max(...this.alerts.map(e => e.level));
            else
                return 0;
        }
    },

    methods : {
        formatAlertLevelIcon(alertLevel)
        {
            switch(alertLevel)
            {
                case AlertLevel.None:
                    return "mdi-check-circle";
                case AlertLevel.Minor:
                    return "";
                case AlertLevel.Warning:
                    return "mdi-alert-circle";
                case AlertLevel.Severe:
                    return "mdi-alert-circle";
                default:
                    return "mdi-help-circle";
            }
        },

        formatAlertLevelColour(alertLevel)
        {
            switch(alertLevel)
            {
                case AlertLevel.None:
                    return "propelDarkGreen";
                case AlertLevel.Warning:
                    return "warning";
                case AlertLevel.Severe:
                    return "error";
                default:
                    return "warning";
            }
        },
    }
}
</script>

<style scoped>

</style>