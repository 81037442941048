<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col cols="5" v-if="formActive">
          <v-card elevation="3">
            <v-card-subtitle>
              <v-container>
                <v-row dense>
                  <v-col cols="4" xl="4" lg="6" md="8">
                    <v-btn
                        text
                        color="primary"
                        title="Collapse"
                        @click="formActive = false"
                    >
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    Road Bridge Form
                  </v-col>
                </v-row>
              </v-container>

            </v-card-subtitle>
            <v-card-text>
              <v-container>
                <v-form ref="roadBridgeAddForm">
                  <v-row dense>
                    <v-col cols="8" xl="8" lg="12" md="12">
                      <v-select
                          v-model="roadBridgeForm.roadBridgeCode"
                          :items="roadBridgeCodes"
                          item-text="roadBridgeCode"
                          item-value="roadBridgeCode"
                          label="Road Bridge"
                          clearable
                          tabindex="2"
                          :rules="roadBridgeRules"
                          dense
                      ></v-select>
                    </v-col>
                    <v-col cols="4" xl="4" lg="12" md="12">
                      <v-select
                          v-model="roadBridgeForm.truckCompanyId"
                          :items="operators"
                          :loading="loadingOperators"
                          label="Transport Operator"
                          item-text="idAndName"
                          item-value="transportCompanyId"
                          clearable
                          :rules="truckCompanyRules"
                          dense
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" xl="4" lg="6" md="12">
                      <v-text-field
                          v-model="roadBridgeForm.shipmentBookingNumber"
                          label="Shipment #"
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" xl="4" lg="6" md="12">
                      <v-text-field
                          v-model="roadBridgeForm.purchaseOrderNumber"
                          label="PO #"
                          :rules="poRules"
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" xl="4" lg="6" md="12">
                      <v-menu
                          v-model="roadBridgeForm.estimatedDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              v-model="computedEstimatedDateFormatted"
                              label="Estimated Date"
                              readonly
                              prepend-icon="mdi-calendar"
                              hide-details="auto"
                              v-on="on"
                              @click:prepend="roadBridgeForm.estimatedDateMenu = true"
                              autocomplete="off"
                              dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="roadBridgeForm.estimatedDate"
                            :min="showEstimatedDateNow()"
                            @input="roadBridgeForm.estimatedDateMenu = false"
                            style="width:100%"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                  </v-row>
                  <v-row dense>
                    <v-spacer></v-spacer>
                    <v-col cols="4" xl="4" lg="12" md="12">
                      <v-btn
                          text
                          color="primary"
                          style="margin-top: 15px; width: 100%"
                          @click="saveForm"
                      >
                        <v-icon>mdi-content-save-check-outline</v-icon>
                        {{ isUpdating? 'Update Road Bridge' : 'Add Road Bridge' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="formActive ? 7 : 12">
          <v-btn
              v-if="!formActive"
              text
              color="primary"
              title="Collapse"
              @click="formActive = true"
          >
            <v-icon>mdi-plus</v-icon>
            Show Form
          </v-btn>
          <DxDataGrid
              :ref="roadBridgeGridRefKey"
              :data-source="roadBridgeData.roadBridges"
              :show-borders="true"
              :hoverStateEnabled="true"
              noDataText="No road bridges"
              key-expr="trainServicePlannerBookingRoadBridgeDetailsId"
              :height="320"
          >
            <DxColumn
                type="buttons"
                cell-template="actionCellTemplate"
                caption="Actions"
                width="10%"
            />
            <template #actionCellTemplate="{ data }">
              <div>
                <v-icon color="primary" size="18" class="mr-2" @click="editRoadBridge(data)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary" size="18" class="mr-2" @click="deleteRoadBridge(data)">
                  mdi-delete
                </v-icon>
              </div>
            </template>

            <DxColumn
                caption="ETA"
                data-field="estimatedDate"
                width="15%"
                data-type="date"
                format="dd/MM/yyyy"
            ></DxColumn>

            <DxColumn
                caption="Road Bridge Code"
                data-field="roadBridgeCode"
                width="40%"
            ></DxColumn>

            <DxColumn
                caption="PO#"
                data-field="purchaseOrderNumber"
                width="10%"
            ></DxColumn>

            <DxColumn
                caption="Shipment #"
                data-field="shipmentBookingNumber"
                width="10%"
            ></DxColumn>

            <DxColumn
                caption="Trucking Company"
                data-field="truckCompanyId"
                width="10%"
            ></DxColumn>
          </DxDataGrid>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import generalHelpers from "@/utils/generalHelpers";
import {
  DxDataGrid,
  DxColumn
} from "devextreme-vue/data-grid";

export default {
  name: "TransportPlannerForm-RoadBridgeDetails",

  mixins: [generalHelpers],

  components : {
    DxDataGrid,
    DxColumn,
  },

  data() {
    return {
      // Grid Config
      roadBridgeGridRefKey: 'data-grid',

      roadBridgeForm : {
        trainServicePlannerBookingRoadBridgeDetailsId: null,
        roadBridgeCode : null,
        from: null,
        to: null,
        purchaseOrderNumber : null,
        shipmentBookingNumber : null,
        truckCompanyId : null,
        estimatedDate : window.Moment().format('YYYY-MM-DD'),
        estimatedDateMenu: false,
        isReturning : false,
      },
      workingRoadBridgeIndex: null,

      // Form
      formActive: true,
      isUpdating : false,
      roadBridgeRules : [
        v => !!v || 'Road Bridge is required',
      ],
      truckCompanyRules : [
          v => !!v || 'Truck Company is required',
      ],
      poRules : [
          v => !!v || 'Purchase Order # is required',
      ],
    }
  },

  mounted() {
    this.fetchRoadBridgeCodes();
    this.fetchTransportOperators();
  },

  computed : {
    ...mapState('transportPlanner', [
        'workingPlannerBooking',
        'roadBridgeCodes',
        'transportOperators',
        'loadingOperators',
    ]),

    ...mapGetters('transportPlanner', [
        'getRoadBridgeCode'
    ]),

    roadBridgeData() {
      return this.workingPlannerBooking.roadBridgeDetails;
    },

    operators() {
      return this.transportOperators;
    },

    computedEstimatedDateFormatted() {
      return this.roadBridgeForm.estimatedDate !== null ? this.formatDate(this.roadBridgeForm.estimatedDate) : ''
    },
  },

  watch : {

  },

  methods : {
    ...mapActions('transportPlanner', [
        'fetchRoadBridgeCodes',
        'fetchTransportOperators'
    ]),

    // Buttons
    saveForm() {
      if (this.$refs.roadBridgeAddForm.validate())
      {
        if (this.workingRoadBridgeIndex !== null) {
          this.roadBridgeData.roadBridges.splice(this.workingRoadBridgeIndex, 1);
        }

        this.roadBridgeData.roadBridges.push({
          trainServicePlannerBookingRoadBridgeDetailsId : null,
          roadBridgeCode : this.roadBridgeForm.roadBridgeCode,
          from : this.roadBridgeForm.from,
          to : this.roadBridgeForm.to,
          purchaseOrderNumber : this.roadBridgeForm.purchaseOrderNumber,
          shipmentBookingNumber : this.roadBridgeForm.shipmentBookingNumber,
          estimatedDate : this.roadBridgeForm.estimatedDate,
          isReturning : this.roadBridgeForm.isReturning,
          truckCompanyId : this.roadBridgeForm.truckCompanyId
        })

        this.resetForm();
      }
    },

    resetForm() {
      this.roadBridgeForm.trainServicePlannerBookingRoadBridgeDetailsId = null;
      this.roadBridgeForm.roadBridgeCode = null;
      this.roadBridgeForm.from = null;
      this.roadBridgeForm.to = null;
      this.roadBridgeForm.purchaseOrderNumber = null;
      this.roadBridgeForm.shipmentBookingNumber = null;
      this.roadBridgeForm.estimatedDate = window.Moment().format('YYYY-MM-DD');
      this.roadBridgeForm.isReturning = false;
      this.roadBridgeForm.truckCompanyId = null;
      this.isUpdating = false;
      this.workingRoadBridgeIndex = null;
    },

    async deleteRoadBridge(e) {
      e.cancel = true;
      if (await this.$root.$confirm.open('Road Bridge Removal', 'Are you sure you want to perform this action?', { color: 'warning'})) {
        App["$appAnalytics"].trackEvent('Transport Planner Booking - Delete Road Bridge Click');
        this.roadBridgeData.roadBridges.splice(e.rowIndex, 1);
        this.resetForm();
      }
    },

    editRoadBridge(e) {
      this.formActive = true;
      this.isUpdating = true;
      e.data.roadBridgeCode = parseInt(e.data.roadBridgeCode);
      this.roadBridgeForm = Object.assign({}, e.data);
      this.workingRoadBridgeIndex = e.rowIndex;
    },

    // Misc
    showEstimatedDateNow() {
      return window.Moment().startOf('day').format('YYYY-MM-DD');
    },

    truckRegoFilter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .startsWith(query.toString().toLowerCase());
    },
  }
}
</script>

<style scoped>

</style>