<template>
  <v-card elevation="3" style="margin-top:10px">
    <v-container class="datatableControls">
      <v-row>
        <v-col cols="12">
            <v-form ref="paramFiltersForm" lazy-validation>
              <div v-if="['SP','TVF','N4'].includes(dbSourceType)" class="filter-container">
                <v-row>
                <v-col cols="3">
                  <v-text-field
                      v-model="paramData.name"
                      label="Param Name"
                      :rules="paramNameRules"
                      ref="paramName"
                      :disabled="paramData.isUpdating"
                  >
                  </v-text-field>
                </v-col>
                    <v-col cols="3">
                    <v-select
                        v-model="paramData.filterDataType"
                        :items="['numeric','string','selection','date','datetime']"
                        label="Param Type"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                        v-model="paramData.defaultValue"
                        label="Default Value"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-checkbox
                        v-model="paramData.isRequired"
                        :label="`Required`"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                        color="primary"
                        @click="saveOrUpdate"
                    >
                      <span v-if="!paramData.isUpdating">Add</span>
                      <span v-else>Update</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
        :headers="headers"
        :items="reportData.reportParams"
        class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editParams(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteParams(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ReportForm-Filters",

  data() {
    return {
      headers: [
        { text: 'Parameter Name', value: 'FilterColumn' },
        { text: 'Filter Data Type', value: 'FilterDataType'},
        { text: 'Default Value', value: 'FilterDefaultValue'},
        { text: 'Is Required ?', value: 'IsRequired' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      paramData: {
        name: null,
        filterDataType: null,
        defaultValue: null,
        isRequired: null,
        isUpdating: false,
      },

      // Validation
      paramNameRules: [
        v => !!v || 'Param name is required',
      ],
    }
  },

  computed: {
    ...mapState('reportManagement', [
      'sourceColumnList',
      'loadingSourceColumnList',
      'workingReport'
    ]),

    dbSourceType() {
      return this.workingReport.reportDetails.reportDataSourceType;
    },

    params() {
      return this.workingReport.reportDetails.reportParams;
    },

    reportData() {
      return this.workingReport.reportDetails;
    },

  },

  methods: {
    init(columns) {
      columns.forEach(element => {

        let columnType;
        switch (element.columnType)
        {
          case "nvarchar":
              columnType = "string"
            break;
          case "decimal":
          case "int":
              columnType = "number"
            break;
          case "datetime":
          case "datetime2":
          case "date":
              columnType = "date"
            break;
        }

        let newObj = {
          caption: element["columnStringName"],
          dataField: element["columnName"],
          dataType: columnType,
        }

        this.fields.push(newObj);
      });
    },

    saveOrUpdate() {
      this.paramData.isUpdating ? this.updateParams() : this.addParams();
    },

    addParams() {
      if (this.$refs.paramFiltersForm.validate()) {

        let paramData = {
          FilterColumn : this.paramData.name,
          FilterDataType: this.paramData.filterDataType,
          FilterDefaultValue: this.paramData.defaultValue,
          IsRequired: this.paramData.isRequired,
          FilterType: 'Parameters'
        }

        this.reportData.reportParams.push(paramData);
        this.resetParamDetailsForm();
      }
    },

    updateParams() {
      if (this.$refs.paramFiltersForm.validate()) {
        let selectedParamIndex = this.reportData.reportParams.findIndex( k => k["FilterColumn"] === this.paramData.name);

        this.reportData.reportParams[selectedParamIndex].FilterDataType = this.paramData.filterDataType;
        this.reportData.reportParams[selectedParamIndex].FilterDefaultValue = this.paramData.defaultValue;
        this.reportData.reportParams[selectedParamIndex].isRequired = this.paramData.isRequired;
        this.reportData.reportParams[selectedParamIndex].FilterType = 'Parameters';
        this.resetParamDetailsForm();
      }
    },

    editParams(e) {
      this.paramData.name = e.FilterColumn;
      this.paramData.filterDataType = e.FilterDataType;
      this.paramData.defaultValue = e.FilterDefaultValue;
      this.paramData.isRequired = e.IsRequired;
      this.paramData.isUpdating = true;
    },

    async deleteParams(e) {
      if (await this.$root["$confirm"].open('Delete confirmation', 'Are you sure you want to remove this parameter?' , { color: 'info' })) {
        this.reportData.reportParams.splice(e.rowIndex, 1);
      }
    },

    // Misc functions
    resetParamDetailsForm() {
      this.paramData.name = null;
      this.paramData.filterDataType = null;
      this.paramData.isRequired = false;
      this.paramData.isUpdating = false;
      this.$refs.paramFiltersForm.resetValidation();
      this.$refs.paramName.focus();
    },
  },
}
</script>

<style scoped>
</style>