<!--suppress CssUnresolvedCustomProperty -->
<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-form ref="containerForm">
                      <v-row style="margin-top: 10px" dense>
                        <v-col cols="8">
                          <v-row>
                            <v-col cols="9">
                              <v-row>
                                <v-col cols="4">
                                  <div id="transportPlannerContainerNumberInput">
                                    <v-text-field
                                        ref="containerNumberInput"
                                        v-model="containerSearch"
                                        label="Container ID*"
                                        type="text"
                                        v-input-upper-alpha-numeric
                                        dense
                                        required
                                        :loading="loadingMatchingContainer"
                                        :rules="containerData.containerCommodity === 'MTY' ? [] : containerIdRules"
                                        :messages="unknownContainerMessage"
                                        :error-messages="containerErrorMessage"
                                        validate-on-blur
                                        @focus="containerNumberFocus"
                                        @blur="containerNumberBlur"
                                        tabindex="1"
                                    >
                                    </v-text-field>
                                  </div>
                                </v-col>
                                <v-col cols="4" v-if="containerData.containerCommodity === 'MTY'">
                                  <div id="transportPlannerEmptyReleaseNumberInput">
                                    <v-text-field
                                        v-model="containerData.emptyReleaseNumber"
                                        label="Empty Release #"
                                        v-input-upper-alpha-numeric
                                        validate-on-blur
                                        :rules="emptyReleaseNumberRules"
                                        @blur="checkForm"
                                        @change="checkForm"
                                        dense
                                    >
                                    </v-text-field>
                                  </div>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="3" v-if="containerData.isGenset || isReeferAndDoNotRequireGenSet">
                                  <v-text-field
                                      v-if="containerData.isGenset"
                                      v-model.number="containerData.gensetHours"
                                      label="Genset Hours"
                                      type="number"
                                      step="any"
                                      dense
                                      @blur="checkForm"
                                      @change="checkForm"
                                  >
                                  </v-text-field>
                                  <v-text-field
                                      v-else-if="isReeferAndDoNotRequireGenSet"
                                      v-model.number="containerData.hoursOffPower"
                                      label="Hours Off Power"
                                      type="number"
                                      step="any"
                                      dense
                                      @blur="checkForm"
                                      @change="checkForm"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  <v-autocomplete
                                      v-model="containerData.containerCommodity"
                                      :items="commodityTypes"
                                      item-text="displayText"
                                      item-value="commodityId"
                                      label="Commodity / Category"
                                      :rules="containerCommodityRules"
                                      clearable
                                      @change="checkForm"
                                      dense
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                  <v-autocomplete
                                      v-model="containerData.containerIsoType"
                                      :items="isoTypes"
                                      item-text="displayText"
                                      item-value="isoId"
                                      label="Type"
                                      :rules="containerIsoTypeRules"
                                      clearable
                                      @change="checkForm"
                                      dense
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                      v-model.number="containerData.containerWeight"
                                      label="Est Wt. (Kg)"
                                      type="number"
                                      step="any"
                                      :rules="containerData.containerCommodity === 'MTY' ? [] : containerWeightRules"
                                      @blur="checkForm"
                                      @change="checkForm"
                                      dense
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                      v-model.number="containerData.containerTemperature"
                                      label="Req Temp. (°C)"
                                      type="number"
                                      step="any"
                                      :rules="
                                                containerData.containerCommodity !== undefined &&
                                                containerData.containerCommodity !== null &&
                                                 containerData.containerCommodity !== '' ? (
                                                (containerData.containerCommodity.includes('CHP') ||
                                                 containerData.containerCommodity.includes('FRP') ||
                                                 containerData.containerCommodity.includes('FRM')
                                            ) ? containerTemperatureRules : [] ) : [] "
                                      @blur="checkForm"
                                      @change="checkForm"
                                      dense
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="6">
                                  <v-textarea
                                      v-model="containerData.containerNotes"
                                      name="input-7-4"
                                      label="Notes"
                                      rows=1
                                      auto-grow
                                      @blur="checkForm"
                                      @change="checkForm"
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                      v-show="containerData.isHazardous"
                                      v-model="containerData.hazardClass"
                                      label="Hazard Class"
                                      @blur="checkForm"
                                      @change="checkForm"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                  <v-textarea
                                      v-show="containerData.isHazardous"
                                      v-model="containerData.hazardNotes"
                                      name="input-7-4"
                                      label="Hazard Notes"
                                      rows=1
                                      auto-grow
                                      @blur="checkForm"
                                      @change="checkForm"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="3">
                              <v-row dense>
                                <v-col cols="12" v-if="containerData.containerCommodity !== 'MTY'">
                                  <v-checkbox
                                      label="Genset?"
                                      v-model="containerData.isGenset"
                                      @change="checkForm"
                                      dense
                                      :disabled="!isReefer"
                                  >
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" v-if="containerData.containerCommodity !== 'MTY'">
                                  <v-checkbox
                                      label="Hazard?"
                                      v-model="containerData.isHazardous"
                                      @change="checkForm"
                                      dense
                                  >
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-checkbox
                                      label="Returning?"
                                      v-model="containerData.isReturning"
                                      @change="checkForm"
                                      :disabled="containerExpectedToReturn"
                                      dense
                                  >
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-checkbox
                                      label="Going Road?"
                                      v-model="containerData.isGoingRoad"
                                      @change="checkForm"
                                      dense
                                  >
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="3" v-if="showN4ContainerInfo">
                          <v-card elevation="3">
                            <v-card-subtitle>Current Napier Port Info</v-card-subtitle>
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="6">
                                  <v-chip
                                      class="ma-2"
                                      label
                                      small
                                      style="height: 24px;width: 100%"
                                  >
                                    Category :
                                  </v-chip>
                                </v-col>
                                <v-col cols="6">
                                  <v-chip
                                      class="ma-2"
                                      :color="n4ContainerInfo.category === 'Export' ? 'primary' : 'brown'"
                                      outlined
                                      style="height: 24px;width: 100%"
                                  >
                                    <v-icon left>
                                      {{ n4ContainerInfo.category === 'Export' ? 'mdi-export' : 'mdi-import' }}
                                    </v-icon>
                                    {{ n4ContainerInfo.category }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="6">
                                  <v-chip
                                      class="ma-2"
                                      label
                                      small
                                      style="height: 24px;width: 100%"
                                  >
                                    Transit State :
                                  </v-chip>
                                </v-col>
                                <v-col cols="6">
                                  <v-chip
                                      class="ma-2"
                                      :color="transitStateColor"
                                      outlined
                                      style="height: 24px;width: 100%"
                                  >
                                    {{ n4ContainerInfo.transitState }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="6">
                                  <v-chip
                                      class="ma-2"
                                      label
                                      small
                                      style="width: 100%"
                                  >
                                    ISO Type :
                                  </v-chip>
                                </v-col>
                                <v-col cols="6">
                                  <v-chip
                                      class="ma-2"
                                      outlined
                                      color="success"
                                      style="height: 24px;width: 100%"
                                  >
                                    <v-icon left>
                                      mdi-train-car-container
                                    </v-icon>
                                    {{ n4ContainerInfo.isoType }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="6">
                                  <v-chip
                                      class="ma-2"
                                      label
                                      small
                                      style="width: 100%"
                                  >
                                    VGM Weight :
                                  </v-chip>
                                </v-col>
                                <v-col cols="6">
                                  <v-chip
                                      class="ma-2"
                                      outlined
                                      style="height: 24px;width: 100%"
                                  >
                                    <v-icon left>
                                      mdi-weight-kilogram
                                    </v-icon>
                                    {{ n4ContainerInfo.vgmWeight }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

export default {
    name: "TransportPlannerForm-ContainerDetails",

    data() {
        return {
            minCharsForFetchingMatchingContainer: 8,
            unknownContainerIdMessageText: 'Container ID not yet known to Napier Port',
            containerExpectedToReturn: false,

            containerSearch: '',
            containerSearchUpdated: false,
            containerNumberIsNewForN4: false,
            pendingFindMatchingContainer: 0,
            containerErrorMessage: null,
            unknownContainerMessage: null,
            unknownContainerNumberConfirmed: false,
            isDataInit: false,
            showN4ContainerInfo: false,

            n4ContainerInfo : {
                category : null,
                isoType : null,
                transitState : null,
                vgmVerifier : null,
                vgmWeight : null
            },

            // Validations
            containerIdRules: [
                val => this.validateContainerId(val.trim())
            ],
            emptyReleaseNumberRules : [
                v => !!v || 'Empty Release # is required',
            ],
            containerIsoTypeRules : [
              v => !!v || 'ISO Type is required',
              v => ( v && !this.containerNotGonnaFit ) || 'Container not gonna fit',
            ],
            containerCommodityRules : [
                v => !!v || 'Commodity type is required',
            ],
            containerWeightRules : [
                v => !!v || 'Container weight is required',
            ],
            containerTemperatureRules: [
                v => !!v  || "This field is required",
                v => ( v && v >= -99 ) || "Temperature should be above -99",
                v => ( v && v <= 99 ) || "Temperature should not be above 99",
            ],

            // Form
            formIsValid : false,
        }
    },

    computed : {
        ...mapState('transportPlanner', [
            'workingPlannerBooking',
            'equipmentTypes',
            'commodities'
        ]),

        isoTypes() {
            return this.wagonCapacity < 2 ? this.equipmentTypes.filter( k => k.length !== "40'") : this.equipmentTypes;
        },

        commodityTypes() {
            return this.commodities;
        },

        containerData() {
            return this.workingPlannerBooking.containerDetails;
        },

        isReeferAndDoNotRequireGenSet() {
            return this.isReefer && !this.containerData.isGenset;
        },

        isReefer() {
          return this.containerData.containerIsoType !== null && this.equipmentTypes.length > 0
              ? this.equipmentTypes.find(g => g.isoId === this.containerData.containerIsoType)["displayText"].toUpperCase().includes("REEFER") : false;
        },

        loadingMatchingContainer() {
            return this.pendingFindMatchingContainer > 0;
        },

        transitStateColor() {
            let result = "";
            switch (this.n4ContainerInfo.transitState.toLowerCase())
            {
                case 'yard':
                    result = "#C0A803FF";
                    break;
                case 'inbound':
                    result = "#961B1BFF";
                    break;
                case 'advised':
                    result = "#34b4eb";
                    break;
                case 'departed':
                    result = "#305369FF";
                    break;
                default:
                    result = "#625959FF";
                    break;
            }

            return result;
        },

        formRequiredData() {
            return [
                this.containerData.containerCommodity === 'MTY' ? this.containerData.emptyReleaseNumber : this.containerData.containerNumber,
                this.containerData.containerIsoType,
                this.containerData.containerCommodity,
                this.containerData.containerCommodity === 'MTY' ? true : this.containerData.containerWeight,
                this.containerData.containerCommodity !== null ?
                    this.containerData.containerCommodity.includes('CHP','FRP','FRM') ? this.containerData.containerTemperature : true
                    : true,
            ];
        },

        isUpdate() {
            return this.workingPlannerBooking.trainServicePlannerBookingId !== null;
        },

        wagonCapacity() {
          return this.workingPlannerBooking.wagonCapacity;
        },

        containerNotGonnaFit() {
          return this.containerData.containerLength !== null ? this.wagonCapacity < 2 && this.containerData.containerLength === "40'" : false;
        }
    },

    mounted() {
        this.fetchTransportPlannerEquipmentTypes();
        this.fetchTransportPlannerCommodities();

        if (this.isUpdate) { // Load container number into the field if its update
            this.containerSearch = this.containerData.containerNumber;
        }
    },

    watch : {
        'containerData.containerIsoType'(val) {
          if (val)
          {
            if (this.equipmentTypes.find( ({isoId}) => isoId === val).length !== undefined)
            {
              this.containerData.containerLength = this.equipmentTypes.find( ({isoId}) => isoId === val).length;
            }
            else
            {
              window.App.$emit('show-snackbar', "error", "Container type : " + val + " not found in the N4 system. Please contact the administrator and have it added in N4.");
            }
          }
        },

        containerSearch() {
            // As soon as the user types something, we remove what we had potentially already confirmed earlier
            // Except when the "containerSearch" value is set from initialising data because of an edit
            if (!this.isDataInit) {

                this.unknownContainerNumberConfirmed = false;
                this.containerErrorMessage = null;
                this.containerSearchUpdated = true;
                this.containerNumberIsNewForN4 = false;

            } else {
                // Data init done now then
                this.isDataInit = false;
            }
        },

        formIsValid(val) {
            this.setFormStatus({formName: 'containerDetailsForm', value: val});
        },

        containerNotGonnaFit(val) {
            if (val) {
              window.App.$emit('show-snackbar', "error", "Wagon only has 1 TEU Capacity, the container won't fit into the wagon. Please select a different container.");
              this.containerData.containerIsoType = null;
              this.containerData.containerCommodity = null;
              this.containerData.containerNumber = null;
              this.containerData.containerLength = null;
              this.containerSearch = null;
              this.showN4ContainerInfo = false;
            }
        }
    },

    methods : {
        ...mapActions('transportPlanner', [
            'fetchTransportPlannerEquipmentTypes',
            'fetchTransportPlannerCommodities'
        ]),

        ...mapMutations('transportPlanner', [
            'setFormStatus'
        ]),

        // Form functions
        checkForm() {
            this.formIsValid =  !Object.values(this.formRequiredData).includes(null);
        },

        // Container functions
        validateContainerId(val) {
            val = !val ? val : val.trim();
            let rulesToCheck = [
                val => !!val || 'Container ID is required',
                val => !val || val.length >= this.minCharsForFetchingMatchingContainer || 'Not a valid container number',
                val => !val || val.length < 30 || 'A container ID must be less than 30 chars',
                val => !val || /^[A-Za-z0-9]+$/.test(val) || 'A container ID can only contain letters and numbers'
            ];

            return window.App.checkValidationRules(val, rulesToCheck);
        },

        containerNumberFocus() {
            this.unknownContainerMessage = null;
        },

        containerNumberBlur() {
          this.containerExpectedToReturn = false;
          // If the container number typed was changed since we were last here (i.e. containerData.containerNumber is empty) and there
          // is enough data to perform a search, we try and find a matching container in N4 and use that data.
          if (this.containerSearchUpdated && this.containerSearch !== this.containerData.containerNumber) {

            const searchValue = this.containerSearch?.trim();
            if (searchValue && searchValue.length >= this.minCharsForFetchingMatchingContainer) {

              this.containerSearchUpdated = false;  // we are now processing this update
              const containerSearchIsValid = this.validateContainerId(searchValue);

              if (this.workingPlannerBooking.customerDetails.companyId !== null) {

                if (typeof containerSearchIsValid === 'boolean' && containerSearchIsValid) {
                  this.pendingFindMatchingContainer++;
                  const containerId = searchValue;
                  // First check if the container is already active in N4,  if it is not allowed to add again.
                  let companyId = this.workingPlannerBooking.customerDetails.companyId;
                  let fwCompanyId = this.workingPlannerBooking.customerDetails.fwCompanyId === null ? 0 : this.workingPlannerBooking.customerDetails.fwCompanyId;

                  CMSApi.fetchCheckContainerIfAllowedToBook(containerId, companyId, fwCompanyId)
                      .then(res => {
                        if (res['item1'])
                        {
                          let containerIsReturning = res['item2'].includes("Container is expected to return");
                          // If it is allowed to be booked, then we check if it is already in N4 to extract data
                          CMSApi.fetchPlannerN4ContainerData(containerId)
                              .then(data => {
                                // Only process this data if it is still a response to the current text typed
                                if (containerId === this.containerSearch?.trim()) {
                                  this.processMatchFound(data, containerIsReturning);
                                }
                              })
                              .catch(error => {
                                // Only show an error if it is still a response to the current text typed
                                if (containerId === this.containerSearch?.trim()) {
                                  if (error.response.data.errors.containerNumber) {
                                    this.containerErrorMessage = error.response.data.errors.containerNumber[0];
                                  }
                                }
                              })
                              .finally(() => {
                                this.pendingFindMatchingContainer--;
                              });
                        } else {
                          this.containerErrorMessage = res['item2'];
                          this.pendingFindMatchingContainer--;
                        }
                      });
                }

              } else {
                this.containerErrorMessage = "Please select a customer first";
              }

            } else if (this.containerNumberIsNewForN4) {

              // If the search above did not happen because there was no change, and the container number now is (still) new for N4,
              // we put the warning message back in.
              this.unknownContainerMessage = this.unknownContainerIdMessageText;
              this.containerData.containerCommodity = null;
              this.containerData.containerIsoType = null;
              this.showN4ContainerInfo = false;

            }
          }
        },

        processMatchFound(data, isReturning) {
            if (isReturning) {
                this.setReturningContainerData(this.containerSearch?.trim());
            } else if (data) {
              // Found an exact match in N4
              this.setMatchedContainerData(data);
            } else {
                // We did not find a match in N4, but we still accept the number typed as "new"
                this.setNewContainerData(this.containerSearch?.trim());
            }
        },

        setMatchedContainerData(matchedContainer) {
            this.containerData.containerNumber = matchedContainer.containerNumber;
            this.containerNumberIsNewForN4 = false;
            this.n4ContainerInfo = Object.assign({}, matchedContainer);
            this.showN4ContainerInfo = true;
            this.containerData.containerCommodity = matchedContainer["commodity"];
            this.containerData.containerIsoType = matchedContainer["isoType"];
        },

        setReturningContainerData(containerNumber) {
          this.containerData.containerNumber = containerNumber;
          this.containerExpectedToReturn = true;
          this.showN4ContainerInfo = false;
          this.unknownContainerMessage = "Container expected to return";
          this.containerData.containerCommodity = null;
          this.containerData.containerIsoType = null;
        },

        setNewContainerData(containerNumber) {
            this.containerData.containerNumber = containerNumber;
            this.containerNumberIsNewForN4 = true;
            this.unknownContainerMessage = this.unknownContainerIdMessageText;
            this.showN4ContainerInfo = false;
            this.containerData.containerCommodity = null;
            this.containerData.containerIsoType = null;
        },
    }
}
</script>

<!-- Scoping does not work here. So using very specific id to limit risk of affecting other elements -->
<style>
#transportPlannerContainerNumberInput .v-messages:not(.error--text) {
    color: var(--v-warning-base);
}

/*
.formMediumLabel

    font-size: 14px;
}
*/

</style>