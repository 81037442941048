<template>
    <div>
        <v-alert
            v-for="notification in filteredNotifications" v-bind:key="notification.notificationId"
            tile dismissible colored-border
            border="top"
            elevation="1"
            icon="mdi-alert-circle-outline"
            type="warning"
            @input="dismissNotification(notification.notificationId)"
        >
            <div class="notificationTitle">{{notification.title}}</div>            
            <div v-html="notification.content" />
            <p v-if="notification.hasAttachment">For further information, please see the attachment in the associated notification email that was sent to your email address.</p>
        </v-alert>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        applicationFilter: {
            type: Array,
            default: () => []
        },
    },

    data () {
        return {
            loadingNotifications: false,
        }
    },

    computed: {
        ...mapGetters('notifications', {
            newNotifications : 'getNewNotifications'
        }),

        filteredNotifications () {           
            return this.newNotifications.filter(n => 
                this.applicationFilter.length == 0 
                || this.applicationFilter.some(appfilter => n.applications.map((app) => app.applicationId).includes(appfilter))
            );
            
        }
    },

    methods: {
        ...mapActions('notifications', [
            'dismissNotification'
        ]),
    }
};

</script>

<style>   
    .v-alert {
        font-size: 14px;
    }

    .notificationTitle {
        font-weight: bold; 
        padding-bottom:5px;
    }
</style>