<template>
    <div class="inboundVesselsSplide grab-cursor"
        v-show="inboundVessels.length > 0" 
        :class="{ disablePrevArrow: !vesselListPrevArrowEnabled, disableNextArrow: !vesselListNextArrowEnabled }">
        <splide
            :ref="splideInboundVesselsRefName"   
            :slides="inboundVessels"
            :options="{autoWidth: true, pagination: false}"
            @splide:moved="inboundVesselMoved"
            >          
                <splide-slide v-for="(item) in this.inboundVessels" :key="item.visitId">
                    <v-card
                        :ripple="false"
                        class="vesselCard"
                        :min-width="$vuetify.breakpoint.smAndUp ? '330' : ''"
                        outlined tile>
                        <v-toolbar flat dense height="auto" extension-height="auto" color="propelLightGreen">
                            <v-icon>mdi-ferry</v-icon>
                            <!-- normal view -->
                            <v-card-text v-if="$vuetify.breakpoint.smAndUp" class="vesselCardText">
                                <span>{{item.vesselName}}{{showInboundVoyage(item)}} - {{item.visitId}}</span><br/>   
                                <span class="vesselCardSubText">{{formatVesselStatus(item)}}</span>
                            </v-card-text>
                            <!-- mobile view -->
                            <v-card-text v-else class="vesselCardText">
                                <span>{{item.vesselName}}{{showInboundVoyage(item)}}<br/>{{item.visitId}}</span>                                    
                            </v-card-text>
                            <template v-slot:extension v-if="$vuetify.breakpoint.xsOnly">
                                <div class="vesselCardExtensionText">
                                    <span class="vesselCardSubText">{{formatVesselStatus(item)}}</span>
                                </div>
                            </template>
                            <v-icon v-if="item.importsLockedOff">mdi-lock</v-icon>
                        </v-toolbar>
                    </v-card>
                </splide-slide>
        </splide>
    </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
    name: "InboundVesselsSlider",
    components: {
        Splide,
        SplideSlide,
    },

    props: {
        inboundVessels: Array,
        mode: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            vesselListPrevArrowEnabled: false,
            vesselListNextArrowEnabled: false,
            splideInboundVesselsRefName: "splideInboundVesselsRefName"
        }
    },

    watch: {
        inboundVessels: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            //we want to determine prev and next arrow state, but we need to allow time for the slider to load data first
            setTimeout(async () => {
                this.inboundVesselMoved(this.$refs[this.splideInboundVesselsRefName].splide, null);
            }, 500);            
        },
    },

    methods: {
         showInboundVoyage(visit) {
             if (this.mode === "export") {
                 return "";
             } else if (this.mode === "import") {
                 return ` (${visit.inBoundVoyage})`;
             }
         },
    
        formatVesselStatus(visit)
        {
            if (this.mode === "import") {
                if(visit.importsLockedOff)
                    return "Locked Off";
                else if(visit.startWorkDate && window.Moment(String(visit.startWorkDate)) <= new window.Moment())
                    return visit.phase;
                else
                    return "Estimated Lock Off: " + this.formatVisitRelatedDateTime(visit.estimatedImportsLockOffDate);
            } else if (this.mode === "export") {
                if (visit.beginReceiveDateTime) {
                    // if begin receive date/time is still in the future, we display this, else we display the ETD
                    if (window.Moment(String(visit.beginReceiveDateTime)) > new window.Moment()) {
                        return "Begin Receive: " + this.formatVisitRelatedDate(visit.beginReceiveDateTime);
                    } else {
                        return "Cargo Cutoff: " + this.formatVisitRelatedDateTime(visit.cargoCutoffDateTime);
                    }
                } else {
                    // if there is no begin receive, we show this as TBC
                    return "Begin Receive: TBC";
                }
            }
        },

        formatVisitRelatedDate(value) {
            if (value) {
                return window.Moment(String(value)).format('ddd DD-MM');
            }
            else {
                return "TBC";
            }
        },
        
        formatVisitRelatedDateTime(value) {
            if (value) {
                return window.Moment(String(value)).format('ddd DD-MM HH:mm');
            }
            else {
                return "TBC";
            }
        },

        inboundVesselMoved(splide, e) { // eslint-disable-line no-unused-vars
            let slides = splide.Components.Elements.getSlides();
            if (slides.length === 0) {
                this.vesselListNextArrowEnabled = false;
                this.vesselListNextArrowEnabled = false;
            }
            else {
                this.vesselListPrevArrowEnabled = !slides[0].isVisible();
                this.vesselListNextArrowEnabled = !slides[slides.length-1].isVisible();
            }
        },
    }
};
</script>

<style scoped>
    .vesselCard {
        margin-right:10px; 
        background-color: var(--v-propelLightGreen-base) !important;
        cursor: inherit;
        user-select: none; /* to stop issues with click and drag */
    }
    
    .vesselCardText {
        color: rgba(0, 0, 0, 0.87);
        padding-top: 5px;
        padding-bottom: 5px;
        white-space: nowrap; 
    }

    .vesselCardExtensionText {
        padding-bottom: 5px;
        white-space: nowrap; 
        text-align: center;
        width: 100%;
    }

    .vesselCardSubText {
        font-size: 12.5px;
    }
</style>
<style>
    /* these styles don't work with scoped */
    
    /* want arrows outside slider */
    .inboundVesselsSplide .splide--slide{
        padding-left: 40px;
        padding-right: 40px;
    }

    .inboundVesselsSplide .splide__arrow--prev {
        left: 0px;
    }

    .inboundVesselsSplide .splide__arrow--next {
        right: 0px;
    }
    
    /* style sliders as boxes */
    .inboundVesselsSplide .splide__arrow{
        border-radius: 0;
        height: 100%;
        width: 35px;
    }

    /* disable arrow if no prev or next records */
    .inboundVesselsSplide.disablePrevArrow .splide__arrow--prev{
        opacity: 0.15;
    }
    .inboundVesselsSplide.disableNextArrow .splide__arrow--next {
        opacity: 0.15;
    }

    /* want to actually hide both arrows if already showing all vessels on screen */     
    .inboundVesselsSplide.disablePrevArrow.disableNextArrow .splide--slide{
        padding-left: 0px;
        padding-right: 0px;
    }
    .inboundVesselsSplide.disablePrevArrow.disableNextArrow .splide__arrow{
        display: none;
    }
</style>