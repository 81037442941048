<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-form ref="kiwiRailForm">
            <v-row style="margin-top: 10px">
                <v-col cols="3">
                    <v-autocomplete
                            v-model="kiwiRailData.senderCompanyId"
                            :items="customers"
                            item-text="name"
                            item-value="companyId"
                            label="From"
                            clearable
                            tabindex="2"
                            :rules="senderRules"
                            dense
                    ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                    <v-autocomplete
                            v-model="kiwiRailData.receiverCompanyId"
                            :items="customers"
                            item-text="name"
                            item-value="companyId"
                            label="To"
                            clearable
                            tabindex="2"
                            :rules="receiverRules"
                            dense
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                <v-text-field
                    v-model="kiwiRailData.bookingReferenceNumber"
                    ref="bookingReferenceInput"
                    label="Booking Reference#"
                    placeholder="Do you have a booking reference#?"
                    dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                  <v-text-field
                          v-model="kiwiRailData.customerReferenceNumber"
                          label="Customer Reference#"
                          placeholder="Do you have a customer reference#?"
                          dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="3">
                  <v-text-field
                          v-model="kiwiRailData.railReferenceNumber"
                          label="Rail Reference#"
                          placeholder="Do you have a rail reference#?"
                          dense
                  >
                  </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "TransportPlannerForm-KiwiRailDetails",

  data() {
    return {
        senderRules: [
            v => !!v || 'Sender is required',
        ],
        receiverRules: [
            v => !!v || 'Receiver is required',
        ],

        // Form
        formIsValid: false,
    }
  },

  computed : {
    ...mapState('transportPlanner', [
        'workingPlannerBooking',
        'kiwiRailCustomers'
    ]),

    customers() {
        return this.kiwiRailCustomers;
    },

    kiwiRailData() {
      return this.workingPlannerBooking.kiwiRailDetails;
    }
  },

  watch : {
      'kiwiRailData.senderCompanyId'(val) { // eslint-disable-line no-unused-vars
          this.checkForm();
      },

      'kiwiRailData.receiverCompanyId'(val) { // eslint-disable-line no-unused-vars
          this.checkForm();
      },

      formIsValid(val) {
          this.setFormStatus({formName: 'kiwiRailDetailsForm', value: val});
      }
  },

  mounted() {
      this.fetchKiwiRailCustomers();
  },

  methods : {
      ...mapActions('transportPlanner', [
          'fetchKiwiRailCustomers'
      ]),

      ...mapMutations('transportPlanner', [
          'setFormStatus'
      ]),

      checkForm() {
          // Declare objects that are required
          let formData = [
              this.kiwiRailData.senderCompanyId,
              this.kiwiRailData.receiverCompanyId
          ];

          this.formIsValid =  !Object.values(formData).includes(null);
      }
  }
}
</script>

<style scoped>

</style>