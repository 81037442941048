<template>
  <div>
    <v-container>
      <v-row dense>

      </v-row>
      <v-row>
        <v-col cols="12">
          <v-container class="datatableControls">
            <v-row>
              <v-col cols="12" sm="6" md="5" lg="3" xl="3">
                <v-text-field
                    clearable
                    v-model="searchText"
                    label="Search by Container or Invoice #"
                    append-icon="mdi-magnify" hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="3" lg="2" xl="1">
                <v-btn
                    tile color="secondary"
                    :loading="loadingInvoices"
                    @click="refreshData" >
                  <v-icon>mdi-refresh</v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
                </v-btn>
              </v-col>
              <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
              <v-col cols="12" sm="2" md="3" lg="2" xl="2">
                <v-switch
                    v-model="onlyUnprocessed"
                    label="Unprocessed Invoices"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="2" md="3" lg="3" xl="2" class="text-right">
                <v-btn
                    tile outlined
                    color="secondary"
                    @click="recalculateInvoices"
                    :loading="recalculatingInvoices"
                    width="100%"
                    :disabled="selectedInvoices.length === 0"
                >
                  <v-icon>fa-solid fa-calculator</v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Recalculate Invoice</span>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="2" md="3" lg="3" xl="2" class="text-right">
                <v-btn
                    tile outlined
                    color="secondary"
                    @click="exportInvoiceFiles"
                    :loading="exportingInvoices"
                    width="100%"
                    :disabled="selectedInvoices.length === 0"
                >
                  <v-icon>fa-solid fa-file-invoice-dollar</v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Generate Invoice</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!onlyUnprocessed">
              <v-col cols="12" sm="6" md="5" lg="3" xl="3">
                <v-dialog
                    ref="dialog"
                    v-model="datePickerModal"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="datesSelected"
                        label="Date Range"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dates"
                      scrollable
                      range
                      first-day-of-week="1"
                      @change="dateRangeFilled"
                      :show-current="true"
                      show-adjacent-months
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="datePickerModal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="hasSelectedRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
          <DxDataGrid
              id="invoicesGrid"
              :ref="invoicesGrid"
              :data-source="invoices"
              keyExpr="invoiceNumber"
              @selection-changed="onInvoicesSelectionChanged"
              height="600"
          >
            <DxSelection
                mode="multiple"
                showCheckBoxesMode="always"
            />
            <DxScrolling mode="virtual"/>
            <DxHeaderFilter :visible="true" />
            <DxSorting mode="multiple"/>

            <DxColumn
                data-field="invoiceNumber"
                caption="Invoice Number"
                width="15%"
            />

            <DxColumn
                data-field="createdDate"
                caption="Invoice Date"
                data-type="date"
                width="8%"
            />

            <DxColumn
                data-field="customerCode"
                caption="Customer Code"
                width="8%"
            />

            <DxColumn
                data-field="containerNumber"
                caption="Container #"
                width="9%"
            />

            <DxColumn
                data-field="customerName"
                caption="Customer Name"
                width="10%"
            />

            <DxColumn
                data-field="forwarderName"
                caption="Forwarder Name"
                width="10%"
            />

            <DxColumn
                data-field="invoiceAmount"
                caption="Invoice Amount ($NZD)"
                data-type="number"
                width="10%"
            />

            <DxColumn
                data-field="costAmount"
                caption="Cost Amount ($NZD)"
                data-type="number"
                width="10%"
            />

            <DxColumn
                data-field="isInvoiced"
                caption="Is Invoiced ?"
                data-type="boolean"
                width="10%"
            />

            <DxColumn
                cell-template="actionCellTemplate"
                caption="Actions"
                width="10%"
            />

            <template #actionCellTemplate="{data}">
              <div>
                  <!-- Show / Edit Booking Invoice -->
                  <v-icon
                      color="primary"
                      size="18"
                      class="mr-2"
                      title="Show / Edit Booking Invoice"
                      @click="showBookingInvoice(data.data)"
                  >
                    mdi-receipt-text-edit-outline
                  </v-icon>
              </div>
            </template>

            <template #CustomerNameTemplate="{data}">
              <div>{{ getCustomerName(data.data.customerId) }}</div>
            </template>

            <template #ForwarderNameTemplate="{data}">
              <div>{{ getCustomerName(data.data.fwCompanyId) }}</div>
            </template>

          </DxDataGrid>
        </v-col>
      </v-row>
    </v-container>

    <!-- Invoice Dialog -->
    <v-dialog
        v-model="invoiceDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <transport-planner-invoice-dialog
          :bookingId="selectedBookingId"
          :readonly="readOnly"
          @close="invoiceDialog = false"
          :key="`invoiceDialog+${componentKey}`"
      >
      </transport-planner-invoice-dialog>
    </v-dialog>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {
  DxDataGrid,
  DxColumn, DxHeaderFilter, DxSorting, DxPaging, DxPager, DxScrolling, DxSelection
} from "devextreme-vue/data-grid";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import TransportPlannerInvoiceDialog from "@/components/transportCapacity/planner/TransportPlanner-InvoiceDialog.vue";
export default defineComponent({
    name: "TransportBillings-Invoices",

    components: {
      DxSelection, DxScrolling,
      DxPager, DxPaging,
      DxSorting,
      DxHeaderFilter,
      TransportPlannerInvoiceDialog,
      DxDataGrid,
      DxColumn
    },

    data() {
      return {
        invoicesGrid: "invoicesGrid",
        invoices: [],
        searchText: "",
        onlyUnprocessed: true,

        // Dialogs
        invoiceDialog : false,

        // Loading Indicators
        invoiceDialogInitialLoad: true,
        loadingInvoices: false,
        recalculatingInvoices : false,
        exportingInvoices: false,

        // ComponentKey
        componentKey: 0,
        selectedBookingId : null,
        readOnly: false,

        // Date Pickers
        datePickerModal: false,
        datesSelected: null,
        dates: [],
        startDate : null,
        endDate : null,
      }
    },

    computed : {
      ...mapGetters('transportMappings', [
          'getCustomerName'
      ]),

      ...mapState('transportBilling', [
        'selectedInvoices',
      ]),

      invoicesSelected() {
        return this.selectedInvoices;
      }

    },

    watch: {
      searchText: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
        this.$refs[this.invoicesGrid].instance.searchByText(newVal?.trim());
      },

      onlyUnprocessed (val)
      {
        if (val)
        {
          this.getUnprocessedInvoices();
        }
      }
    },

    mounted() {
      this.getUnprocessedInvoices()
      this.fetchCustomers();
    },

    methods: {
      ...mapActions('transportMappings', [
        'fetchCustomers',
      ]),

      ...mapMutations('transportBilling', [
        'setSelectedInvoices',
      ]),

      refreshData() {
        if (this.onlyUnprocessed)
        {
          this.getUnprocessedInvoices();
        }
        else
        {
          this.getAllInvoices();
        }
      },

      getUnprocessedInvoices() {
        CMSApi.fetchUnprocessedInvoices()
            .then((data) => {
              this.invoices = data;
            });
      },

      getAllInvoices() {
        CMSApi.fetchAllInvoices(this.startDate, this.endDate)
            .then((data) => {
              this.invoices = data;
            });
      },

      showBookingInvoice(e) {
        if (!this.invoiceDialogInitialLoad)
          this.componentKey += 1;

        this.invoiceDialogInitialLoad = false;
        this.invoiceDialog = true;

        this.readOnly = e["isInvoiced"];
        this.selectedBookingId = e["propelReferenceNumber"];
      },

      // Grid functions
      onInvoicesSelectionChanged(e) { // eslint-disable-line no-unused-vars
        this.setSelectedInvoices(e.selectedRowsData);
      },

      exportInvoiceFiles() {
        this.exportingInvoices = true;
        let invoiceNumbers = this.selectedInvoices.map(x => x.invoiceNumber);
        CMSApi.postGenerateInvoiceFiles(invoiceNumbers)
            .then(data => {
              console.log(data);
              const blobURL = URL.createObjectURL(data);
              window.open(blobURL);
            })
            .finally(() => {
              this.exportingInvoices = false;
            });
      },

      recalculateInvoices() {
        this.recalculatingInvoices = true;
        let bookingIds = this.selectedInvoices.map(x => x["propelReferenceNumber"]);
        CMSApi.postRecalculateInvoices(bookingIds)
            .then(data => {
              console.log(data);
              this.getUnprocessedInvoices();
            })
            .finally(() => {
              this.recalculatingInvoices = false;
            });
      },

      // Misc functions
      dateRangeFilled(e) {
        this.datesSelected = e.sort().join('~');
        this.startDate = e[0];
        this.endDate = e[1];
      },

      hasSelectedRange(){
        this.datePickerModal = false;
        this.getAllInvoices();
      },

    },
})
</script>

<style scoped>

</style>