import { render, staticRenderFns } from "./TransportBillings.vue?vue&type=template&id=71c85c3c&scoped=true&"
import script from "./TransportBillings.vue?vue&type=script&lang=js&"
export * from "./TransportBillings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c85c3c",
  null
  
)

export default component.exports