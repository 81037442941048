<template>
    <CompanyList />
</template>

<script>
import CompanyList from '@/components/portAdmin/company/CompanyList.vue'

export default {   
    name: 'admin-companyManagement',
    components: {
        CompanyList,
    },
};
</script>