import { getInstance } from "./authWrapper";
import UserRoles from "@/auth/authUserRoles"

export const authGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = () => {
        //don't navigate if we have auth0 linked account issue (which will result in app refresh)
        if (authService.auth0LinkedAccountRolesIssue)
            return next(false);

        //by default we want to secure all pages, except those defined in the below array
        //note: the login page is a special case and is handled further down below
        const unsecuredPages = ['help','terms','smartflow'];
        console.log(to);
        if (unsecuredPages.includes(to.name))
            return next();

        //if going to login page but already has access, go to home page
        if (to.name == 'login')
        {
            if (authService.hasAccess)
                return next('/');
            else
                return next();
        }

        //if not authenticated, redirect to login page
        if (!authService.hasAccess)
            return next('/login');

        //default pages for different roles (for home page or bad url)
        if (to.name == null || to.name == '/')
        {
            if (authService.userHasPropelRole() && authService.userHasSmartFlowRole())
                return next('/appointments');
            else if (authService.userHasSmartFlowRole())
                return next('/smartflow');
            else if (authService.userHasRole(UserRoles.Dispatcher))
                return next('/appointments');
            else if (authService.userHasRole(UserRoles.ImportAdvisor))
                return next('/import-advice');
            else if (authService.userHasRole(UserRoles.PortAdmin))
                return next('/portadmin/user-management');
            else if (authService.userHasRole(UserRoles.CompanyAdmin))
                return next('/admin/users');
            else if (authService.userHasRole(UserRoles.ExportCoordinator))
                return next('/export-pre-advice');
            else
                return next('/help');
        }

        //check if page requires role, and if so, whether user has that access
        if(to.meta.permittedRoles != null && !authService.userHasARole(to.meta.permittedRoles))
        {
            window.alert("You do not currently have permission to access this screen. Please either log in as a user who has access, or contact us to arrange access.");
            //if we don't have a from page (i.e. the user has entered the url directly), redirect to home page, else just stay on the current page
            if (from.name == null)
                return next('/');
        }
        else
            return next();
    };

    if (!authService.loading) {
        return fn();
    }

    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
};
