<template>
  <div>
    <v-card id="transportServiceList" elevation="1">
      <v-toolbar class="datatableToolbar" flat>
        <v-toolbar-title>Report Management</v-toolbar-title>

        <div class="flex-grow-1"></div>

        <v-btn
            tile class="mb-2"
            color="secondary"
            @click="addReport">
          <v-icon>mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Add Report</span>
        </v-btn>
      </v-toolbar>

      <v-container class="datatableControls">
        <v-row>
          <v-col cols="12" sm="6" md="5" lg="4" xl="4">
            <v-text-field clearable v-model="searchText" label="Search by Report Name" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <DxDataGrid
          id="transportGrid"
          :ref="dataGridRefName"
          :data-source="reportList"
          :show-borders="false"
          :hoverStateEnabled="true"
          noDataText="No current report list"
          :column-auto-width="true"
          :column-hiding-enabled="true"
      >
        <DxColumn
            data-field="reportName"
            data-type="string"
            caption="Report Name"
        />

        <DxColumn
            data-field="dataSource"
            data-type="string"
            caption="Data Source Name"
        />

        <DxColumn
            data-field="dataSourceContext"
            data-type="string"
            caption="Data Source Context"
        />

        <DxColumn
            data-field="dataSourceType"
            data-type="string"
            caption="Data Source Type"
            cell-template="showSourceType"
        />
        <template #showSourceType="{ data: cellData}">
          {{parseVal(cellData)}}
        </template>

        <DxColumn
            data-type="string"
            caption="Action"
            cell-template="showActionButtons"
        />
        <template #showActionButtons="{data: cellData}">
          <div>
            <v-icon
                color="primary"
                size="18"
                class="mr-2"
                title="Edit Column"
                @click="editReport(cellData)"
            >
              mdi-pencil-box-multiple
            </v-icon>

            <v-icon
                color="primary"
                size="18"
                class="mr-2"
                title="Delete Report"
                @click="deleteReport(cellData)"
            >
              mdi-trash-can
            </v-icon>
          </div>
        </template>

      </DxDataGrid>
    </v-card>

    <v-dialog
        content-class="reportDialog"
        class="rmDialog"
        v-model="reportFormDialog"
        width="65%"
        ref="rmDialog"
    >
      <report-form
          ref="reportForm"
          :key="`reportForm+${componentKey}`"
          @close="closeReportForm(false)"
          @closeAndReload="closeReportForm(true)"
      ></report-form>
    </v-dialog>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import {mapActions, mapState} from "vuex";
import reportForm from "@/components/reports/ReportForm";
import {UserRoles} from "@/utils/constants";

export default {
  name: "portadmin-reportManagement",

  components : {
    DxDataGrid,
    DxColumn,
    reportForm
  },

  data() {
    return {
      // Grid config
      searchText: '',
      dataGridRefName: 'reportListGrid',

      //Dialog & Component Keys
      reportFormDialog: false,
      reportFormInitialLoad: true,
      componentKey: 0,

      refreshRoleUsersIntervalId: 0,

      tab: null
    }
  },

  mounted() {
    this.fetchReportList();
    this.fetchRoleUsers(UserRoles.PropelReportViewer);
  },

  watch: {
    searchText: function (newVal) {
      this.$refs[this.dataGridRefName]["instance"].searchByText(newVal?.trim());
    }
  },

  computed: {
    ...mapState('reportManagement', [
        'reports'
    ]),

    reportList() {
      return this.reports;
    },

    dataGrid: function() {
      return this.$refs[this.dataGridRefName]["instance"];
    },
  },

  methods: {
    ...mapActions('reportManagement', [
        'fetchReportList',
        'setWorkingReportData',
        'fetchRoleUsers',
        'resetWorkingReport'
    ]),

    addReport() {
      App["$appAnalytics"].trackEvent('RM - Add Report - Click');

      if (!this.reportFormInitialLoad)
          this.componentKey += 1;

      this.reportFormDialog = true; // Show Dialog
      this.reportFormInitialLoad = false;
    },

    // eslint-disable-next-line no-unused-vars
    editReport(row) {
      App["$appAnalytics"].trackEvent('RM - Edit Report - Click');

      CMSApi.fetchReportData(row.data['reportId'])
          .then( data => {
            this.setWorkingReportData(data);
          })
          .finally(() => {
            if (!this.reportFormInitialLoad)
              this.componentKey += 1;

            this.reportFormDialog = true; // Show Dialog
            this.reportFormInitialLoad = false;
          })
    },

    closeReportForm(reload) {
      App["$appAnalytics"].trackEvent('RM - Close Add Report Form - Click');

      this.resetWorkingReport(); // Reset
      this.reportFormDialog = false; // Close Dialog

      if (reload)
        this.fetchReportList();
    },

    //Grid Buttons
    async deleteReport(row) {
      if (await this.$root["$confirm"].open('Delete report', 'Are you sure you want to remove this report?' , { color: 'info' })) {
        App["$appAnalytics"].trackEvent('RM - Delete Report - Click');

        CMSApi.deleteReport(row.data['reportId'])
            .then(() => {
              this.fetchReportList();
            })
            .finally(() => {
              App["$appAnalytics"].trackEvent('RM - Report Deletion Success');
            })
      }
    },

    //Misc functions
    parseVal(val) {
      let res = '';
      switch (val.value)
      {
        case 'TVF':
          res = 'Table Valued Function'
          break;
        case 'SP':
          res = 'Stored Procedure'
          break;
        case 'V':
          res = 'View'
          break;
        case 'N4':
          res = 'Private Filters'
          break;
      }
      return res;
    }
  }
}
</script>

<style scoped>
>>> .v-dialog{
  overflow: hidden !important;
  box-shadow: unset;
}

>>> .reportDialog {
  position: absolute;
  top: 10%;
  left: 15%
}
</style>