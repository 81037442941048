<template>
  <div>
    <v-card>
      <v-card-title>
        {{formTitle}}
      </v-card-title>
      <v-card-text>
        <ValidationSummary :validationErrors="validationErrors" />
        <v-container>
          <v-row>
            <v-col :cols="formMode === 'add' ? 6: 12">
              <v-card elevation="3">
                <v-card-subtitle>
                  <v-icon>mdi-book-information-variant</v-icon>
                  Service Details
                </v-card-subtitle>
                <v-card-text>
                  <v-form ref="gateServiceDetailsForm" v-model="formData.detailFormIsValid" @submit.prevent>
                    <v-row>
                      <v-col :cols="formMode === 'add' ? 4: 6">
                        <v-text-field
                            v-model="formData.gateServiceName"
                            label="Gate Service Name"
                            :rules="formData.gateServiceNameRules"
                            :error-messages="formData.gateServiceNameErrorMessage"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col :cols="formMode === 'add' ? 4: 6">
                        <v-select
                            v-model="formData.gateId"
                            :items="propelGates"
                            :loading="loadingGates"
                            label="Gate"
                            item-text="gateName"
                            item-value="gateId"
                            clearable
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col :cols="formMode === 'add' ? 4 : 6">
                        <v-menu
                            v-model="formData.startDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="computedStartDateFormatted"
                                label="Start Date"
                                readonly
                                prepend-icon="mdi-calendar"
                                v-on="on"
                                @click:prepend="formData.startDateMenu = true"
                                :rules="formData.startDateRules"
                                :error-messages="formData.gateServiceDetailErrorMessage"
                                autocomplete="off"
                                :disabled=" formData.gateServiceId !== null ? startDateCannotBeModified : false"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="formData.startDate"
                              :min="showStartDateNow()"
                              @input="formData.startDateMenu = false"
                              style="width:100%"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col :cols="formMode === 'add' ? 4: 6" >
                        <v-menu
                            v-model="formData.endDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="computedEndDateFormatted"
                                label="End Date"
                                readonly
                                prepend-icon="mdi-calendar"
                                hide-details="auto"
                                v-on="on"
                                @click:prepend="formData.endDateMenu = true"
                                :rules="formData.endDateRules"
                                :error-messages="formData.gateServiceDetailErrorMessage"
                                autocomplete="off"
                                :disabled=" formData.gateServiceId !== null ? endDateCannotBeModified : false"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="formData.endDate"
                              :min="showEndDateNow()"
                              @input="formData.endDateMenu = false"
                              style="width:100%"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                          <v-select
                              v-model="formData.selectedDays"
                              :items="days"
                              :menu-props="{ maxHeight: '400' }"
                              label="Select Days"
                              multiple
                              hint="Pick occurrence days"
                              persistent-hint
                              return-object
                              :rules="formData.selectedDaysRules"
                          ></v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" v-if="formMode === 'add'">
              <v-card elevation="3">
                <v-card-subtitle>
                  <v-icon>mdi-chart-timeline</v-icon>
                  Zone and Slots
                </v-card-subtitle>
                <v-card-text>
                  <v-form ref="gateServiceZoneAndSlotsForm">
                    <v-row>
                      <v-col cols="3">
                        <v-select v-if="!formData.slotEditMode"
                            v-model="formData.zoneSelected"
                            :items="formData.allowedZones.filter(i => !formData.zoneAdded.includes(i))"
                            label="Zone"
                            dense
                            required
                            :rules="formData.zoneRules"
                            tabindex="1"
                        >
                        </v-select>
                        <v-text-field v-else
                            v-model="formData.zoneSelected"
                            label="Zone"
                            type="number"
                            dense
                            required
                            disabled
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3">
                        <v-text-field
                            v-model.number="formData.slotQuantity"
                            label="Slots"
                            type="number"
                            dense
                            required
                            :rules="formData.slotRules"
                            tabindex="2"
                            autocomplete="off"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-btn
                            color="secondary"
                            @click="addSlot"
                            tabindex="4"
                        >
                          {{ formData.slotEditMode ? 'Update Slot' : 'Add Slot' }}
                        </v-btn>
                      </v-col>
                      <v-col cols="1" v-if="formData.slotEditMode">
                        <v-btn
                            color="secondary"
                            @click="resetFormField"
                            title="Reset"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </v-col>

                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <DxDataGrid
                            id="zoneSlotGrid"
                            :ref="dataGridRefName"
                            :data-source="formData.zones"
                            :show-borders="false"
                            :hoverStateEnabled="true"
                            noDataText="No current zones and slots"
                            :column-auto-width="true"
                            :column-hiding-enabled="true"
                            :height="280"
                        >
                          <DxScrolling mode="virtual"/>
                          <DxColumn
                              data-field="zone"
                              caption="Zone"
                              :sort-index="0"
                              sort-order="asc"
                          />
                          <DxColumn
                              data-field="slots"
                              caption="Slots"
                          />
                          <DxColumn type="buttons" cell-template="actionCellTemplate" />
                          <template #actionCellTemplate="{ data }">
                            <div>
                              <v-icon color="primary" size="18" class="mr-2" @click="editZoneAndSlot(data)">
                                mdi-pencil
                              </v-icon>
                              <v-icon color="primary" size="18" class="mr-2" @click="deleteZoneAndSlot(data)">
                                mdi-delete
                              </v-icon>
                            </div>
                          </template>
                        </DxDataGrid>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="$emit('close')"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="saveServiceForm()"
        >
          {{ formMode === 'add' ? 'Save' : 'Update' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import generalHelpers from '@/utils/generalHelpers.js'
import {mapActions, mapState} from "vuex";
import {DxDataGrid, DxColumn, DxScrolling} from "devextreme-vue/data-grid";
import ValidationSummary from "@/components/global/ValidationSummary";

export default {
  name: "GateServiceForm",

  mixins: [generalHelpers],

  components: {
    ValidationSummary,
    DxDataGrid,
    DxColumn,
    DxScrolling
  },

  data() {
    return {
      validationErrors: {},
      dataGridRefName: 'zoneSlotGrid',
      formData: {
        startDateRules: [
          val => !!val || 'Start Date is required'
        ],
        endDateRules: [
          val => !!val || 'End Date is required'
        ],
        gateServiceNameRules: [
          val => !!val || 'Gate Service Name is required'
        ],
        zoneRules: [
          val => !!val || 'Zone is required'
        ],
        slotRules: [
          val => !!val || 'Slot is required'
        ],
        selectedDaysRules: [
          (v) =>  v.length > 0 || "At least one should be selected"
        ],

        gateServiceDetailErrorMessage: null,
        gateServiceNameErrorMessage: null,

        // Datepicker
        startDateMenu: false,
        startDate: null,
        endDateMenu: false,
        endDate: null,

        gateServiceId: null,
        gateId: null,
        gateServiceName: null,

        // Days
        selectedDays: [],

        // Zones
        allowedZones: [7,8,9,10,11,12,13,14,15,16,17,18,19,20],
        zoneSelected: null,
        slotQuantity: null,
        zoneAdded: [],
        zones: [],

        // Data Table
        sortBy: "zone",

        slotEditMode: false,
        detailFormIsValid: false,
      },
      days: [
        {
          text: 'Monday',
        },
        {
          text: 'Tuesday',
        },
        {
          text: 'Wednesday',
        },
        {
          text: 'Thursday',
        },
        {
          text: 'Friday',
        },
        {
          text: 'Saturday',
        },
        {
          text: 'Sunday',
        },
      ],
    }
  },

  mounted() {
    this.fetchAllGates();
  },

  computed: {
    ...mapState('gateService', [
        'formMode'
    ]),

    ...mapState('gateManagement',[
      'gates',
      'vx_loadingGates'
    ]),

    computedStartDateFormatted() {
      return this.formatDate(this.formData.startDate)
    },

    computedEndDateFormatted() {
      return this.formatDate(this.formData.endDate)
    },

    propelGates() {
      return this.gates.filter( ({isFromPropel}) => isFromPropel === true);
    },

    loadingGates() {
      return this.vx_loadingGates;
    },

    formTitle() {
      return this.formMode === 'add' ? 'New Service': 'Update Service'
    },

    isEditing() {
      return this.formMode !== 'add';
    },

    computedSelectedDays () {
      return this.formData.selectedDays.length > 0 ? this.formData.selectedDays.map(obj  => {
        let val = {};
        val.Day = obj['text'];
        val.GateServiceDayId = obj['gateServiceDayId'];
        return val;
      }) : null;
    },

    startDateCannotBeModified() {
      return window.Moment(this.formData.startDate).format('YYYY-MM-DD') <= window.Moment().format('YYYY-MM-DD');
    },

    endDateCannotBeModified() {
      return window.Moment(this.formData.endDate).format('YYYY-MM-DD') <= window.Moment().format('YYYY-MM-DD');
    }
  },

  methods: {
    ...mapActions('gateManagement', [
        'fetchAllGates'
    ]),

    edit(data) { //Point of entry method for edit
      this.formData.gateServiceId = data.gateServiceId;
      this.formData.gateId = data.gateId;
      this.formData.gateServiceName = data.gateServiceName;
      this.formData.startDate = window.Moment(data.startDate).format('YYYY-MM-DD');
      this.formData.endDate = window.Moment(data.endDate).format('YYYY-MM-DD');
      this.formData.selectedDays = this.parseSelectedDays(data.days);
    },

    //Misc
    showStartDateNow() {
      return window.Moment().startOf('day').format('YYYY-MM-DD');
    },

    showEndDateNow() {
      return window.Moment(this.formData.startDate).startOf('day').add(1,'days').format('YYYY-MM-DD');
    },

    isZoneExist(zone) {
      return this.formData.zones.find((x) => x.zone === zone) !== undefined;
    },

    resetFormField() {
      this.formData.slotEditMode = false;
      this.formData.zoneSelected = null;
      this.formData.slotQuantity = null;
      this.$refs.gateServiceZoneAndSlotsForm.resetValidation();
    },

    parseSelectedDays(selectedDays) {
      return selectedDays.map(obj => {
        let val = {};
        // val.text = obj[Object.keys(obj)[0]];
        val.text = obj['day'];
        val.gateServiceDayId = obj['gateServiceDayId'];
        return val;
      })
    },

    // Button functions
    addSlot() {
      if (this.$refs.gateServiceZoneAndSlotsForm.validate()){
        let zoneData = {
          zone : this.formData.zoneSelected,
          slots: this.formData.slotQuantity,
          isFromDb: false
        }

        if (this.isZoneExist(this.formData.zoneSelected)) {
          let newSlotQuantity = this.formData.slotEditMode ? this.formData.slotQuantity : this.formData.zones.find(item => item.zone === this.formData.zoneSelected).slots + this.formData.slotQuantity;

          let newZoneSlotData = {
            zone: this.formData.zoneSelected,
            slots: newSlotQuantity,
            isFromDb : false
          }

          // Delete the old Slot
          this.formData.zoneAdded.splice(this.formData.zoneAdded.findIndex(i => i === this.formData.zoneSelected), 1);
          this.formData.zones.splice(this.formData.zones.findIndex(i => i.zone === this.formData.zoneSelected), 1);
          // Replace with new One
          this.formData.zoneAdded.push(this.formData.zoneSelected);
          this.formData.zones.push(newZoneSlotData);
        } else {
          this.formData.zoneAdded.push(this.formData.zoneSelected);
          this.formData.zones.push(zoneData);
        }
        this.resetFormField();
      }
    },

    deleteZoneAndSlot({data}) {
      this.formData.zones.splice(this.formData.zones.findIndex(i => i.zone == data.zone), 1);
      this.formData.zoneAdded.splice(this.formData.zoneAdded.findIndex(i => i === data.zone), 1);
    },

    editZoneAndSlot({data}) {
      this.formData.zoneSelected = data.zone;
      this.formData.slotQuantity = data.slot;
      this.formData.slotEditMode = true;
    },

    async saveServiceForm() {
      if (this.$refs.gateServiceDetailsForm.validate()) {
        if (await this.$root["$confirm"].open('Create gate service?', 'Are you sure you want to proceed?', { color: 'info' }))
        {
          App["$appAnalytics"].trackEvent('GS - Create Service - Save Click');
          if (this.formMode === 'add') {
            let postData = {
              gateServiceName: this.formData.gateServiceName,
              gateId: this.formData.gateId,
              startDate: this.formData.startDate,
              endDate: this.formData.endDate,
              days: this.computedSelectedDays,
              zones: this.formData.zones
            }

            CMSApi.postGateService(postData)
                .then( () => {
                  window.App.$emit('show-snackbar', "success", "Service created");
                })
                .catch(error => {
                  this.validationErrors = error.response.data.errors;
                })
                .finally(() => {
                  this.$emit('closeAndReload');
                })
          } else {
            let putData = {
              gateServiceId: this.formData.gateServiceId,
              gateServiceName: this.formData.gateServiceName,
              gateId: this.formData.gateId,
              startDate: this.formData.startDate,
              endDate: this.formData.endDate,
              days: this.computedSelectedDays,
              zones: this.formData.zones
            }

            CMSApi.putGateService(putData)
                .then( () => {
                  window.App.$emit('show-snackbar', "success", "Service updated");
                })
                .finally(() => {
                  this.$emit('closeAndReload');
                })
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>