<template>
    <v-form ref="containerDetailsForm" v-model="stepState.formIsValid" @submit.prevent>
        <div class="stepperStepTitle">{{ titleBase }} (1/4)</div>
        <v-row>
            <v-col cols="12" sm="6">
                <div id="exportPreAdviceContainerNumberInput">
                    <v-text-field
                        ref="containerNumberInput"
                        v-model="stepState.containerSearch"
                        label="Container ID*"
                        type="text"
                        v-input-upper-alpha-numeric
                        dense
                        required
                        :readonly="stepState.isUpdateOfExisting"
                        :loading="loadingMatchingContainer"
                        :rules="stepState.containerIdRules"
                        :messages="stepState.unknownContainerMessage"
                        :error-messages="stepState.containerErrorMessage"
                        validate-on-blur
                        @focus="containerNumberFocus"
                        @blur="containerNumberBlur"
                        tabindex="1"
                    >
                    </v-text-field>
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <v-select
                    ref="equipmentTypeIsoIdSelect"
                    v-model="containerData.equipmentTypeIsoId"
                    :items="equipmentTypes"
                    :loading="stepState.loadingEquipmentTypes"
                    item-text="isoId"
                    item-value="isoId"
                    label="Type*"
                    dense
                    required
                    :rules="stepState.equipmentTypeRules"
                    :class="{'highlight': stepState.isoIdSetFromContainer}"
                    tabindex="2"
                >
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model.number="containerData.vgmWeight"
                    label="VGM Weight (Kg)*"
                    type="number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    dense
                    required
                    :rules="stepState.vgmWeightRules"
                    @keydown="preventExponentialsAndNegatives"
                    validate-on-blur
                    tabindex="3"
                    maxlength="5"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="containerData.vgmVerifier"
                    label="VGM Verifier (Name)*"
                    dense
                    required
                    :rules="stepState.vgmVerifierRules"
                    validate-on-blur
                    tabindex="4"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-row dense>
                    <v-col cols="12">
                        <label class="v-label v-label--active theme--light formMinimisedLabel">Seal Number(s)*</label>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <div v-if="containerData.sealNumbers.length === 0">
                            <span class="formMediumLabel"><em>Click "+ Seal" to add one or two seal numbers</em></span>
                        </div>
                        <div v-else>
                            <v-chip v-for="(sealNumber, index) in containerData.sealNumbers"
                                    :key="sealNumber"
                                    close
                                    close-icon="mdi-close-outline"
                                    color="var(--v-propelLightGreen-base)"
                                    label
                                    class="mr-2 mb-2"
                                    @click:close="removeSealNumber(index)"
                            >
                                {{ sealNumber }}
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="9">
                        <v-text-field
                            ref="sealNumberInput"
                            v-model="stepState.sealNumber"
                            label="Seal number to add"
                            v-input-upper-alpha-numeric
                            :rules="stepState.sealNumberRules"
                            validate-on-blur
                            class="pr-2"
                            tabindex="5"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-btn color="secondary"
                               small
                               @click="addSealNumber"
                               tabindex="6"
                        >
                            <v-icon>mdi-plus</v-icon>&nbsp;
                            <span>Seal</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="6">
                <v-select
                    v-model="containerData.transporterId"
                    :items="transportOperators"
                    :loading="stepState.loadingTransporters"
                    label="Transport Operator"
                    item-text="idAndName"
                    item-value="transportCompanyId"
                    clearable
                    dense
                    :class="{'highlight': stepState.transporterSetFromContainer}"
                    tabindex="7"
                >
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-btn
                    color="secondary"
                    plain
                    @click="cancel"
                    tabindex="9"
                >
                    Cancel
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" class="text-right">
                <v-btn
                    color="secondary"
                    plain
                    @click="next"
                    :disabled="!hasContainerNumber"
                    tabindex="8"
                >
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";

export default {
    name: "ExportContainerDetails",

    props: {
        titleBase: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            minCharsForFetchingMatchingContainer: 8,
            unknownContainerIdMessageText: 'Container ID not yet known to Napier Port',
            maxNumberOfSealNumbers: 2,

            stepState: {
                containerIdRules: [
                    val => this.validateContainerId(val.trim())
                ],
                equipmentTypeRules: [
                    val => !!val || 'Equipment Type is required'
                ],
                vgmWeightRules: [
                    val => !!val || 'VGM Weight is required',
                    val => !val || /^[0-9]+$/.test(val) || 'VGM Weight must be a number',
                    val => !val || (Number.parseInt(val) >= 1000 && Number.parseInt(val) <= 99999) || 'VGM Weight must be 4 or 5 digits'
                ],
                vgmVerifierRules: [
                    val => !!val || 'VGM Verifier is required',
                    val => !val || /^[a-zA-Z0-9 \-']+$/.test(val) || 'VGM Verifier not recognised as a valid name',
                ],
                sealNumberRules: [
                    () => this.validateSealNumbers()
                ],

                isUpdateOfExisting: false,
                isDataInit: false,

                containerSearch: '',
                containerSearchUpdated: false,
                pendingFindMatchingContainer: 0,
                containerErrorMessage: null,
                unknownContainerMessage: null,
                unknownContainerNumberConfirmed: false,

                loadingEquipmentTypes: false,
                isoIdSetFromContainer: false,

                loadingTransporters: false,
                transporterSetFromContainer: false,

                cancelDataChangedHighlightTimerId: null,

                sealNumber: '',

                validatingForNext: false,
                formIsValid: false,
            },
        }
    },

    computed: {
        ...mapState('exportPreAdvice', [
            'equipmentTypes',
            'transportOperators'
        ]),
        ...mapGetters('exportPreAdvice', [
            'workingContainerDetails'
        ]),
        containerData() {
            return this.workingContainerDetails;
        },
        loadingMatchingContainer() {
            return this.stepState.pendingFindMatchingContainer > 0;
        },
        hasContainerNumber() {
            // User is allowed to click the Next button as soon as their is a validated and checked container number
            return !!this.containerData.containerNumber;
        }
    },

    mounted() {
        this.loadEquipmentTypes();
        this.loadTransportOperators();

        if (this.containerData.containerNumber) {
            this.stepState.isUpdateOfExisting = true;
            this.stepState.isDataInit = true;
            this.stepState.containerSearch = this.containerData.containerNumber;
        }

        setTimeout(() => { this.setFocusOnFirstField() }, 200);
    },

    watch: {
        'stepState.containerSearch'() {
            // As soon as the user types something, we remove what we had potentially already confirmed earlier
            // Except when the "containerSearch" value is set from initialising data because of an edit
            if (!this.stepState.isDataInit) {
                this.containerData.containerNumber = '';
                this.containerData.containerNumberIsNewForN4 = false;
                this.stepState.unknownContainerNumberConfirmed = false;
                this.stepState.containerErrorMessage = null;
                this.containerData.equipmentTypeIsoId = '';
                this.$refs.equipmentTypeIsoIdSelect.resetValidation();
                this.containerData.transporterId = '';

                // And record that there was an update, so that we know we have to find a match again on blur()
                this.stepState.containerSearchUpdated = true;
            } else {
                // Data init done now then
                this.stepState.isDataInit = false;
            }
        },
    },

    methods: {
        ...mapActions('exportPreAdvice', [
            'setWorkingContainerData',
            'fetchEquipmentTypes',
            'fetchTransportOperators',
            'resetWorkingExportPreAdvice'
        ]),

        setFocusOnFirstField() {
            if (!this.stepState.isUpdateOfExisting) {
                this.$refs.containerNumberInput.focus();
            } else {
                // cannot edit the container number
                this.$refs.equipmentTypeIsoIdSelect.focus();
            }
        },

        loadEquipmentTypes() {
            this.stepState.loadingEquipmentTypes = true;

            this.fetchEquipmentTypes()
                .finally(() => {
                    this.stepState.loadingEquipmentTypes = false;
                });
        },

        loadTransportOperators() {
            this.stepState.loadingTransporters = true;

            this.fetchTransportOperators()
                .finally(() => {
                    this.stepState.loadingTransporters = false;
                });
        },

        containerNumberFocus() {
            this.stepState.unknownContainerMessage = null;
        },

        setMatchedContainerData(matchedContainer) {
            this.containerData.containerNumber = matchedContainer.containerNumber;
            this.containerData.containerNumberIsNewForN4 = false;

            this.containerData.equipmentTypeIsoId = matchedContainer.isoId;

            if (matchedContainer.transportCompanyId) {
                this.containerData.transporterId = matchedContainer.transportCompanyId
            } else if (this.$store.state.account.userAccount.company.n4TransportCompanyId) {
                this.containerData.transporterId = this.$store.state.account.userAccount.company.n4TransportCompanyId;
            } else {
                this.containerData.transporterId = '';
            }

            this.containerData.lineOperator = matchedContainer.operatorId;

            this.highlightUpdatedData();
        },

        setNewContainerData(containerNumber) {
            this.containerData.containerNumber = containerNumber;
            this.containerData.containerNumberIsNewForN4 = true;
            this.stepState.unknownContainerMessage = this.unknownContainerIdMessageText;

            this.containerData.equipmentTypeIsoId = '';
            if (this.$store.state.account.userAccount.company.n4TransportCompanyId) {
                this.containerData.transporterId = this.$store.state.account.userAccount.company.n4TransportCompanyId;
            } else {
                this.containerData.transporterId = '';
            }

            this.highlightUpdatedData();
        },

        highlightUpdatedData() {
            // Highlight the updated fields by changing their appearance
            this.stepState.isoIdSetFromContainer = true;
            this.stepState.transporterSetFromContainer = true;

            // And then cancel highlighting again of auto updated fields after 1 sec
            clearTimeout(this.stepState.cancelDataChangedHighlightTimerId);
            this.stepState.cancelDataChangedHighlightTimerId = setTimeout(
                this.cancelDataChangedHighlight,
                2000
            );
        },

        cancelDataChangedHighlight() {
            this.stepState.isoIdSetFromContainer = false;
            this.stepState.transporterSetFromContainer = false;
        },

        containerNumberBlur() {
            // If the container number typed was changed since we were last here (i.e. containerData.containerNumber is empty) and there
            // is enough data to perform a search, we try and find a matching container in N4 and use that data.
            if (this.stepState.containerSearchUpdated) {
                const searchValue = this.stepState.containerSearch?.trim();

                if (searchValue && searchValue.length >= this.minCharsForFetchingMatchingContainer) {

                    this.stepState.containerSearchUpdated = false;  // we are now processing this update

                    const containerSearchIsValid = this.validateContainerId(searchValue);

                    if (typeof containerSearchIsValid === 'boolean' && containerSearchIsValid) {
                        this.stepState.pendingFindMatchingContainer++;
                        const containerId = searchValue;

                        // First check if the container is already active in N4, if it is it is not allowed to add again.
                        CMSApi.fetchExportPreAdviceActiveContainer(containerId)
                            .then(data => {
                                // Only process this data if it still a response to the current text typed
                                if (containerId === this.stepState.containerSearch?.trim()) {
                                    if (data) {
                                        this.stepState.containerErrorMessage = "This container has already been pre-advised by another party"
                                        this.stepState.pendingFindMatchingContainer--;
                                    } else {
                                        // If it was not found as an Active container in N4, check whether it is one that is known in N4
                                        CMSApi.fetchExportPreAdviceContainerWithNumber(containerId)
                                            .then(data => {
                                                // Only process this data if it is still a response to the current text typed
                                                if (containerId === this.stepState.containerSearch?.trim()) {
                                                    this.processMatchFound(data);
                                                }
                                            })
                                            .catch(error => {
                                                // Only show an error if it is still a response to the current text typed
                                                if (containerId === this.stepState.containerSearch?.trim()) {
                                                    if (error.response.data.errors.containerNumber) {
                                                        this.stepState.containerErrorMessage = error.response.data.errors.containerNumber[0];
                                                    }
                                                }
                                            })
                                            .finally(() => {
                                                this.stepState.pendingFindMatchingContainer--;
                                            });
                                    }
                                }
                            })
                            .catch(error => {
                                // Only show an error if it is still a response to the current text typed
                                if (containerId === this.stepState.containerSearch?.trim()) {
                                    if (error.response.data.errors.containerNumber) {
                                        this.stepState.containerErrorMessage = error.response.data.errors.containerNumber[0];
                                    }
                                }
                                this.stepState.pendingFindMatchingContainer--;
                            });
                    }
                }
            } else if (this.containerData.containerNumberIsNewForN4) {
                // If the search above did not happen because there was no change, and the container number now is (still) new for N4,
                // we put the warning message back in.
                this.stepState.unknownContainerMessage = this.unknownContainerIdMessageText;
            }
        },

        processMatchFound(data) {
            if (data) {
                // Found an exact match in N4
                this.setMatchedContainerData(data);
            } else {
                // We did not find a match in N4, but we still accept the number typed as "new"
                this.setNewContainerData(this.stepState.containerSearch?.trim());
            }
        },

        validateContainerId(val) {
            val = !val ? val : val.trim();
            let rulesToCheck = [
                val => !!val || 'Container ID is required',
                val => !val || val.length >= this.minCharsForFetchingMatchingContainer || 'Not a valid container number',
                val => !val || val.length < 30 || 'A container ID must be less than 30 chars',
                val => !val || /^[A-Za-z0-9]+$/.test(val) || 'A container ID can only contain letters and numbers'
            ];

            return window.App.checkValidationRules(val, rulesToCheck);
        },

        preventExponentialsAndNegatives(evt) {
            window.App.preventExponentialsAndNegatives(evt);
        },

        addSealNumber() {
            if (this.stepState.sealNumber) {
                // We should only add a seal number if it is valid
                const sealNumberIsValid = this.$refs.sealNumberInput.validate();

                if (!sealNumberIsValid) {
                    return;
                }

                // Don't add one that is already there
                if (!this.containerData.sealNumbers.includes(this.stepState.sealNumber)) {
                    this.containerData.sealNumbers.push(this.stepState.sealNumber.toUpperCase());
                }
                this.stepState.sealNumber = '';
            }
        },

        removeSealNumber(index) {
            this.containerData.sealNumbers.splice(index, 1);
        },

        validateSingleSealNumber(sealNum) {
            const alphaNum = /^[A-Za-z0-9]+$/;
            
            if (!sealNum) {
                return true;
            }
            
            if (this.containerData.sealNumbers.length >= this.maxNumberOfSealNumbers) {
                return `You cannot specify more than ${this.maxNumberOfSealNumbers} seal numbers`;
            }

            if (sealNum.length < 5 || sealNum.length > 25) {
                return "A seal number should be between 5 and 25 characters";
            }

            if (!alphaNum.test(sealNum)) {
                return "A seal number can only contain letters and numbers";
            }

            return true;
        },

        validateSealNumbers() {
            // Validation here is different depending on whether this is happening on a
            // blur of the SealNumber input text box (usually a "+ Seal" click), or on a
            // Next button click (which also validates the whole form), because then we 
            // also want to validate that there is at least 1 and no more than 2 seal numbers specified.
            //
            // What kind of validation is requires is controlled by the "validatingForNext" 
            // boolean in stepState

            if (this.stepState.validatingForNext) {
                // There must be at least one seal number
                if (this.containerData.sealNumbers.length === 0) {
                    if (this.stepState.sealNumber) {
                        return "Add at least one seal number. You need to click '+ Seal' to add it.";
                    } else {
                        return "Add at least one seal number";
                    }
                }

                // But there cannot be more than "maxNumberOfSealNumbers"
                if (this.containerData.sealNumbers.length > this.maxNumberOfSealNumbers) {
                    return `You cannot specify more than ${this.maxNumberOfSealNumbers} seal numbers`;
                }

                // There should be no seal number still sitting in the
                // seal number text box anymore
                if (this.stepState.sealNumber) {
                    return 'You have started adding a seal number, but did not click the "+ Seal" button. Either complete the process by clicking the button or delete what you typed.';
                }

                // if we get here, then everything is okay
                return true;
            } else {
                // we are just validating the typed text in the seal number text box
                let validateResult = this.validateSingleSealNumber(this.stepState.sealNumber);

                if (typeof validateResult === 'string') {
                    return validateResult;
                } else {
                    return true;
                }
            }
        },

        resetFormForAdviseAnother() {
            this.stepState.isUpdateOfExisting = false;

            this.stepState.containerSearch = '';
            this.stepState.unknownContainerMessage = null;
            this.stepState.unknownContainerNumberConfirmed = false;

            this.stepState.isoIdSetFromContainer = false;
            this.stepState.transporterSetFromContainer = false;

            this.stepState.sealNumber = '';

            this.$refs.containerDetailsForm.resetValidation();
        },

        async next() {
            // Make sure the form is valid. Also make the validation for the seal numbers check the 
            // list of seal numbers instead of just the one just typed in.
            this.stepState.validatingForNext = true;
            this.formIsValid = this.$refs.containerDetailsForm.validate();
            this.stepState.validatingForNext = false;

            if (this.formIsValid) {
                if (this.containerData.containerNumberIsNewForN4 && !this.stepState.unknownContainerNumberConfirmed) {
                    const unknownContainerNumberIsOkay = await this.$root.$confirm.open(
                        'Unknown container number',
                        'Container number ' + this.containerData.containerNumber + ' is not yet known to Napier Port. Are you sure the number you have typed is correct?',
                        {color: 'warning', width: 400});

                    if (unknownContainerNumberIsOkay) {
                        this.stepState.unknownContainerNumberConfirmed = true;      // Remember this for if we get back to this step in clicking "previous" on step 2. 
                        this.setWorkingContainerData(this.containerData);           // The user doesn't need to confirm it again, unless they change the number.
                        this.$emit("next");
                    } // else: Do nothing. Stay on the step.
                } else {
                    this.setWorkingContainerData(this.containerData);
                    this.$emit("next");
                }
            }
        },

        cancel() {
            this.resetWorkingExportPreAdvice();
            this.$emit("cancel");
        },
    }
}
</script>

<!-- Scoping does not work here. So using very specific id to limit risk of affecting other elements -->
<style>
#exportPreAdviceContainerNumberInput .v-messages:not(.error--text) {
    color: var(--v-warning-base);
}

.highlight .v-input__slot:before, .highlight .v-input__slot:after {
    border-color: var(--v-warning-base) !important;
    background-color: var(--v-warning-base) !important;
    border-width: 2px !important;
}

/*
.formMediumLabel

    font-size: 14px;
}
*/

</style>
