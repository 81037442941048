<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-card height="220px">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    vertical
                    color="propelDarkGreen"
                >
                  <v-tab>
                    <v-icon left>
                      mdi-file-chart
                    </v-icon>
                    Report Details
                  </v-tab>
                  <v-tab
                      :disabled="!reportDetailsFilled"
                  >
                    <v-icon left>
                      mdi-format-columns
                    </v-icon>
                    Report Columns
                  </v-tab>
                  <v-tab
                      :disabled="!reportDetailsFilled"
                  >
                    <v-icon left>
                      mdi-filter
                    </v-icon>
                    Report Filters
                  </v-tab>
                  <v-tab
                      :disabled="!reportColumnsFilled"
                  >
                    <v-icon left>
                      mdi-account-cog-outline
                    </v-icon>
                    User Access
                  </v-tab>
                </v-tabs>
              </v-col>
          </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-divider vertical style="opacity: 0"></v-divider>
      <v-col cols="9">
        <v-card>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <report-form-details
                    @filled="reportDetailsFilled = true"
                    @unfilled="reportDetailsFilled = false"
                >
                </report-form-details>
              </v-tab-item>
              <v-tab-item>
                <report-form-columns
                    @filled="reportColumnsFilled = true"
                    @unfilled="reportColumnsFilled = false"
                >
                </report-form-columns>
              </v-tab-item>
              <v-tab-item>
                <report-form-filters
                ></report-form-filters>
              </v-tab-item>
              <v-tab-item>
                <report-form-users
                    @filled="reportUsersFilled = true"
                    @unfilled="reportUsersFilled = false"
                >
                </report-form-users>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="$emit('close')">Close</v-btn>
            <v-btn color="primary" text :disabled="!canSave()" @click="saveReport()">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="tab > 0" color="primary" text @click="tab--" >Prev</v-btn>
            <v-btn v-if="tab < 2" color="primary" text @click="tab++" :disabled="cannotNext()">Next</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import reportFormDetails from "@/components/reports/ReportForm-Details";
import reportFormColumns from "@/components/reports/ReportForm-Columns";
import reportFormFilters from "@/components/reports/ReportForm-Filters.vue";
import reportFormUsers from "@/components/reports/ReportForm-Users";

export default {
  name: "ReportForm",

  components: {
    reportFormDetails,
    reportFormColumns,
    reportFormFilters,
    reportFormUsers
  },

  data() {
    return {
      tab: null,

      reportDetailsFilled: false,
      reportColumnsFilled: false,
      reportUsersFilled: false,
    }
  },

  created() {
    if (this.isUpdate)
    {
      this.reportDetailsFilled = true;
      this.reportColumnsFilled = true;
      this.reportUsersFilled = true;
    }

  },

  computed: {
    ...mapState('reportManagement', [
      'isUpdate'
    ]),

    ...mapGetters('reportManagement', [
      'workingReport'
    ]),

    formTitle() {
      return  this.isUpdate? 'Edit Report: '+this.reportData.reportName :'New Report'
    },

    reportData() {
      return this.workingReport.reportDetails;
    },

    workingReportData() {
      return this.workingReport;
    },
  },

  methods: {
    ...mapActions('reportManagement', [
      'resetWorkingReport'
    ]),

    // Misc
    cannotNext(){
      let res = false;
      switch (this.tab)
      {
        case 0:
          res = !this.reportDetailsFilled;
          break;
        case 1:
          res = !this.reportColumnsFilled;
          break;
        case 2:
          res = !this.reportUsersFilled;
          break;
      }
      return res;
    },

    canSave() {
      return this.reportDetailsFilled && this.reportColumnsFilled && this.reportUsersFilled;
    },

    saveReport() {
      const completeReportDetailsForSubmit = this.workingReportData.convertToSubmittableReport();

      if (this.isUpdate) {

        CMSApi.putReport(completeReportDetailsForSubmit)
            .then(() => {
              this.$emit('closeAndReload');
              window.App.$emit('show-snackbar', "success", "Updated report");
            })
            .finally(() => {
              this.resetWorkingReport();
            })

      } else {

        CMSApi.postReport(completeReportDetailsForSubmit)
            .then(() => {
              this.$emit('closeAndReload');
              window.App.$emit('show-snackbar', "success", "Created new report");
            })
            .finally(() => {
              this.resetWorkingReport();
            })

      }
    }
  }
}

</script>