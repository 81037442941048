
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import rg4js from 'raygun4js';

/**
 * Vue Plugin for app insights/Analytics
 */
function install (Vue, options) {
  
    Vue.appAnalytics = new VueAppAnalytics(Vue, options);
    
    Object.defineProperty(Vue.prototype, '$appAnalytics', {
        get: () => Vue.appAnalytics
    })

}

// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(install);
}

export default install

class VueAppAnalytics {

    constructor(Vue, options) {
        this.prefix = options.appEventPrefix + ' ' || ''; 

        //setup Azure app insights
        const config = options.azureAppInsightsConfig || {};
        this.azureAppInsights = new ApplicationInsights({ config });
        this.azureAppInsights.loadAppInsights();
        this.azureAppInsights.context.application.ver = options.appVersion;

        //setup Raygun logging
        rg4js('apiKey', options.raygunConfig.apiKey);
        rg4js('enableCrashReporting', true);
        rg4js('setVersion', options.appVersion);
        rg4js('options', options.raygunConfig.options);
        

        // Watch route event if router option is defined
        const router = options.router;
        if (router) {
            if (options.trackInitialPageView !== false) {
                this.setupPageTracking(router);
            } else {
                router.onReady(() => this.setupPageTracking(router));
            }
        }

        this.setupErrorTracking(Vue, options);
    }

    //Track route changes as page views with AppInsights 
    setupPageTracking(router) {

        router.beforeEach( (route, from, next) => {
            const name = this.prefix + route.name;
            this.azureAppInsights.startTrackPage(name);
            next();
        })

        router.afterEach( route => {
            const name = this.prefix + route.name;
            const url = location.protocol + '//' + location.host + route.fullPath;
            this.azureAppInsights.stopTrackPage(name, url);
            this.azureAppInsights.flush();
        })
    }

    setupErrorTracking(Vue, options) {
        //capture all generic errors
        if(options.enableWindowOnErrorExceptionTracking) {
            window.onerror = (message, source, lineno, colno, error) => { // eslint-disable-line no-unused-vars
                this.trackAppException('Window-On-Error', window.App, message, { source: source, position: lineno + ':' + colno, error: error });
            };
        }

        //capture all unhandled promise/async errors
        if(options.enableOnunhandledRejectionExceptionTracking) {
            window.onunhandledrejection = event => {
                this.trackAppException('Unhandled-Promise-Rejection', window.App, event.reason);
            };
        }

        //capture Vue errors (render and methods)
        if(options.enableVueErrorExceptionTracking) {
            Vue.config.errorHandler = (err, vm, info) => { // eslint-disable-line no-unused-vars
                this.trackAppException('Vue-Error', vm, err);
                throw err;
            }
        }
    }

    setAuthenticatedUser(authenticatedUserId, accountId) {
        var invalidAnalyticsUserIdCharsRegEx = "/[,;=| ]+/g";
        if (authenticatedUserId)
            this.azureAppInsights.setAuthenticatedUserContext(authenticatedUserId.replace(invalidAnalyticsUserIdCharsRegEx, "_"), accountId, true);
    }

    clearAuthenticatedUser() {
        this.azureAppInsights.clearAuthenticatedUserContext();
    }

    trackPageView(name, url) { // option to call manually
        this.azureAppInsights.trackPageView({ name: this.prefix + name, url: url });
    }
    
    trackEvent(name, properties) {
        this.azureAppInsights.trackEvent({ name: this.prefix + name }, properties);
    }

    trackMetric(name, average, properties) {
        this.azureAppInsights.trackMetric({ name: this.prefix + name, average: average }, properties);
    }

    trackTrace(message, properties) {
        this.azureAppInsights.trackTrace({ message: message }, properties);
    }

    trackException(exception, severityLevel) {
        this.azureAppInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    trackAppException(errorType, vueInstance, exception, data) {
        //extra error info to send
        let errorProperties = {
            errorType: errorType,
            component: vueInstance.$options?.name || vueInstance.$options?._componentTag,
            routeName: vueInstance.$route?.name,
            routePath: vueInstance.$route?.path,
            userId: vueInstance.$auth?.user?.sub,
            data: data
        };

        //Raygun logging
        rg4js('send', {
            error: exception,
            tags: [errorType],
            customData: errorProperties
        });

        //Azure logging
        this.azureAppInsights.trackException({
            exception: exception, 
            properties: errorProperties
        });
        this.azureAppInsights.flush();
    }

}