import utils from '@/utils/vuexUtils.js'

// initial state
const state = {
    appSettings: {},
}

// getters
const getters = {
    importAdvice: state => {
        return {
            operationalHours: state.appSettings.importAdvice?.operationalHours ?? [],
            minimumStackSize: state.appSettings.importAdvice?.minimumStackSize ?? 0
        };
    },

    eVBSStockThreshold: state => {
        return state.appSettings["eVBS"].find(g => g.key === 'EVBS Stock Minimum Threshold').value;
    },

    eVBSStockGoodColor: state => {
        return state.appSettings["eVBS"].find(g => g.key === 'EVBS Stock Level Color Good').value;
    },

    eVBSStockWarningColor: state => {
        return state.appSettings["eVBS"].find(g => g.key === 'EVBS Stock Level Color Warning').value;
    },

    eVBSStockInactiveColor: state => {
        return state.appSettings["eVBS"].find(g => g.key === 'EVBS Stock Level Color Inactive').value;
    },

    eVBSStockWarningText: state => {
        return state.appSettings["eVBS"].find(g => g.key === 'EVBS Stock Level Warning Text').value;
    },

    eVBSStockInactiveText: state => {
        return state.appSettings["eVBS"].find(g => g.key === 'EVBS Stock Level Inactive Text').value;
    },
}

// actions
const actions = {
    fetchAppSettings: ({ commit, state }) => { // eslint-disable-line no-unused-vars
        return utils.CommitPromise(commit, CMSApi.fetchAppSettings(), 'setAppSettings');
    },
}

// mutations
const mutations = {   
    setAppSettings(state, appSettings) {
        state.appSettings = appSettings;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}