<!--suppress JSValidateTypes -->
<template>
  <v-container>
    <v-row>
      <v-col cols="2" lg="3">
        <v-card elevation="3">
          <v-card-subtitle style="background-color: #c0d830;">
            <span class="headline">{{ formTitle }}</span>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-container>
            <v-list>
              <v-list-item-group v-model="model">
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :disabled="i > 0 ? item.disable : false"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-container>
        </v-card>
      </v-col>
      <v-divider vertical style="display: none"></v-divider>
      <v-col cols="10" lg="9">
        <component v-bind:is="items[model].component"
          @nextForm="nextForm"
          @previousForm="previousForm"
          @savingDone="$emit('returnToList')"
        ></component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransportServiceFormDetails from "@/components/transportCapacity/service/TrainServiceForm-Details.vue";
import TransportServiceFormRoutes from "@/components/transportCapacity/service/TrainServiceForm-Routes.vue";
import TransportServiceFormDays from "@/components/transportCapacity/service/TrainServiceForm-Days.vue";
import { mapState } from "vuex";
export default {
  name: "TransportServiceForm",

  components : {
    TransportServiceFormDetails,
    TransportServiceFormRoutes,
    TransportServiceFormDays
  },

  data() {
    return {
      model: 0,
      items: [
        {
          icon: 'mdi-book-information-variant',
          text: 'Service Info',
          component: TransportServiceFormDetails,
        },
        {
          icon: 'mdi-calendar-multiselect',
          text: 'Service Date Range',
          component: TransportServiceFormDays,
          disable: true,
        },
        {
          icon: 'mdi-transit-connection-variant',
          text: 'Service Routes',
          component: TransportServiceFormRoutes,
          disable: true,
        }
      ],
    }
  },

  computed: {
    ...mapState('trainService', [
        'formServiceDetail',
        'formServiceDays',
        'formServiceWagons',
        'workingTrainService'
    ]),

    isUpdate() {
      return this.workingTrainService.trainServiceId && this.workingTrainService.trainServiceId > 0;
    },

    formTitle() {
      return this.isUpdate ? "Update Transport Service" : "New Transport Service";
    },
  },

  watch: {
    formServiceDetail: {
      immediate: true,
      handler(val) {
        this.items[1].disable = !val;
      },
    },

    formServiceDays: {
      immediate: true,
      handler(val) {
        this.items[2].disable = !val;
      }
    },
  },

  methods: {

    nextForm() {
      this.model = this.model + 1;
    },

    previousForm() {
      this.model = this.model - 1;
    }
  }
}
</script>

<style scoped>

</style>