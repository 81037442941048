<template>
    <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
            <v-stepper-step 
                step="1" 
                :complete="step > 1"
                :editable="editable[0]"
            >
                Container details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                step="2" 
                :complete="step > 2"
                :editable="editable[1]"
            >
                Booking details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step 
                step="3" 
                :complete="step > 3"
                :editable="editable[2]"
            >
                Freight details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                step="4"
                :complete="step > 4"
                :editable="editable[3]"
            >
                Additional details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                step="5"
                :complete="step > 5"
            >
                Confirmation
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <export-container-details
                    ref="containerDetailsStep"
                    :titleBase="stepTitleBase"                      
                    @next="next"
                    @cancel="cancel"
                >
                </export-container-details>
            </v-stepper-content>

            <v-stepper-content step="2">
                <export-booking-details
                    ref="bookingDetailsStep"
                    :titleBase="stepTitleBase"
                    @next="next"
                    @previous="previous"
                    @cancel="cancel"
                >
                </export-booking-details>
            </v-stepper-content>

            <v-stepper-content step="3">
                <export-freight-details
                    ref="freightDetailsStep"
                    :titleBase="stepTitleBase"
                    @next="next"
                    @previous="previous"
                    @cancel="cancel"
                >
                </export-freight-details>            
            </v-stepper-content>

            <v-stepper-content step="4">
                <export-additional-details
                    ref="additionalDetailsStep"
                    :titleBase="stepTitleBase"
                    @submit="submitAdvice"
                    @previous="previous"
                    @cancel="cancel"
                >
                </export-additional-details>
            </v-stepper-content>

            <v-stepper-content step="5">
                <export-confirmation
                    :title="stepTitleBase"
                    :submitting="submittingAdvice"
                    @submitError="submitError"
                    @changeData="changeData"
                    @adviseAnother="adviseAnother"
                    @close="close"
                >
                </export-confirmation>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>

</template>

<script>
import ExportContainerDetails from "@/components/exportPreAdvice/ExportContainerDetails.vue";
import ExportBookingDetails from "@/components/exportPreAdvice/ExportBookingDetails.vue";
import ExportFreightDetails  from "@/components/exportPreAdvice/ExportFreightDetails.vue";
import ExportAdditionalDetails from "@/components/exportPreAdvice/ExportAdditionalDetails.vue";
import ExportConfirmation from "@/components/exportPreAdvice/ExportConfirmation.vue";
import {mapGetters, mapActions} from "vuex";

export default {
    name: "SingleExportPreAdvice",
    components: {
        ExportBookingDetails,
        ExportContainerDetails,
        ExportFreightDetails,
        ExportAdditionalDetails,
        ExportConfirmation
    },

    data() {
        return {
            totalSteps: 5,
            step: 1,
            editable: [false, false, false, false, false],
            submittingAdvice: false
        }
    },
    
    computed: {
        ...mapGetters('exportPreAdvice', [
            'workingAdvice'
        ]),
        isUpdate() {
            return this.workingAdvice.exportPreAdviceId && this.workingAdvice.exportPreAdviceId > 0;
        },
        stepTitleBase() {
            return this.isUpdate ? "Update Export Pre-Advice" : "New Export Pre-Advice"
        }
    },
    
    watch: {
        step(newVal) {
            this.setEditable(newVal);
        }
    },

    methods: {
        ...mapActions('exportPreAdvice', [
            'setWorkingExportPreAdviceForSameBooking',
            'resetWorkingExportPreAdvice'
        ]),
        
        setEditable(beforeStep) {
            // if e.g. step == 3, we want to make step 1 and 2 editable. That is editable[0] and editable[1]
            // Note that when we are in the last step (Confirmation), nothing is editable anymore.
            if (beforeStep < this.totalSteps) {
                for (let j = 0; j < beforeStep - 1; ++j) {
                    this.editable[j] = true;
                }

                for (let j = beforeStep - 1; j < this.totalSteps; ++j) {
                    this.editable[j] = false;
                }
            } else {
                // On the confirmation step. Nothing editable anymore.
                for (let j = 0; j < this.totalSteps; ++j) {
                    this.editable[j] = false;
                }
            }
        },
        
        setFocusOnFirstField() {
            switch (this.step) {
                case 1:
                    this.$refs.containerDetailsStep.setFocusOnFirstField();
                    break;
                case 2:
                    this.$refs.bookingDetailsStep.setFocusOnFirstField();
                    break;
                case 3:
                    this.$refs.freightDetailsStep.setFocusOnFirstField();
                    break;
                case 4:
                    this.$refs.additionalDetailsStep.setFocusOnFirstField();
                    break;
            }
        },

        next() {
            this.step++;
            
            // Give the browser a bit of time to render the screen and then set the focus for the new step
            setTimeout(this.setFocusOnFirstField, 200);
        },

        previous() {
            if (this.isUpdate) {
                App.$appAnalytics.trackEvent('EPA - Edit Advice - Previous Step - Click');
            } else {
                App.$appAnalytics.trackEvent('EPA - Create Advice - Previous Step - Click');
            }
            this.step--;

            // Give the browser a bit of time to render the screen and then set the focus for the new step
            setTimeout(this.setFocusOnFirstField, 200);
        },
        
        cancel() {
            if (this.isUpdate) {
                App.$appAnalytics.trackEvent('EPA - Edit Advice - Cancel - Click');
            } else {
                App.$appAnalytics.trackEvent('EPA - Create Advice - Cancel - Click');
            }
            this.$emit("cancel");
        },

        submitAdvice() {
            if (this.isUpdate) {
                App.$appAnalytics.trackEvent('EPA - Edit Advice - Submit - Click');
            } else {
                App.$appAnalytics.trackEvent('EPA - Create Advice - Submit - Click');
            }
            // Go to the final (confirmation) step
            this.submitTitle = this.isUpdate ? "Update Export Pre-Advice" : "New Export Pre-Advice";
            this.submittingAdvice = true;
            this.step = this.totalSteps;
        },

        close() {
            this.resetWorkingExportPreAdvice();
            this.$emit("close");
        },
        
        submitError() {
            this.submittingAdvice = false;
            for (let j = 0; j < this.totalSteps-1; ++j) {
                this.editable[j] = true;
            }
            this.$forceUpdate();
        },

        changeData() {
            this.submittingAdvice = false;
            this.step--;
        },
        
        adviseAnother(adviseForSameBooking) {
            this.submittingAdvice = false;

            if (adviseForSameBooking) {
                App.$appAnalytics.trackEvent('EPA - Advise Another (Same Booking) - Click');
                this.setWorkingExportPreAdviceForSameBooking();
            } else {
                App.$appAnalytics.trackEvent('EPA - Advise Another (Different Booking) - Click');
                this.resetWorkingExportPreAdvice();
            }

            // Data will have been removed on all data form in the stepper, we don't want it to show "required" data errors
            // And also need to clean up any remaining internal "state" data for each step
            this.$refs.containerDetailsStep.resetFormForAdviseAnother();
            this.$refs.bookingDetailsStep.resetFormForAdviseAnother(adviseForSameBooking);
            this.$refs.freightDetailsStep.resetFormForAdviseAnother(adviseForSameBooking);
            this.$refs.additionalDetailsStep.resetFormForAdviseAnother();

            this.step = 1;
        }
    },
}
</script>
