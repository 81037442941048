<template>
    <v-form ref="freightDetailsForm" v-model="stepState.formIsValid" @submit.prevent>
        <div class="stepperStepTitle">{{ titleBase }} (3/4)</div>
        <v-row>
            <v-col cols="3" v-if="showSummary">
                <export-pre-advice-summary :showBookingData="true"></export-pre-advice-summary>
            </v-col>
            <v-divider vertical v-if="showSummary"></v-divider>
            <v-col cols="12" sm="9">
                <v-row>
                    <v-col cols="8">
                        <v-autocomplete
                            ref="packPointInput"
                            v-model="freightData.packPoint"
                            label="Pack point*"
                            :loading="stepState.loadingMatchingPackPoints"
                            :items="stepState.matchingPackPoints"
                            :search-input.sync="stepState.packPointSearch"
                            :rules="stepState.packPointRules"
                            item-text="name"
                            item-value="id"
                            hide-details="auto"
                            :hide-no-data="!stepState.showNoMatchingPackPointFound"
                            :no-data-text="noMatchingPackPointMessage"
                            :error-messages="stepState.packPointErrorMessage"
                            return-object
                            tabindex="1"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-spacer></v-spacer>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="8">
                        <v-select
                            v-model="freightData.commodityCode"
                            :items="commodities"
                            :loading="stepState.loadingCommodities"
                            label="Commodity Code*"
                            item-text="shortNameAndDescription"
                            item-value="id"
                            flat
                            :rules="stepState.commodityRules"
                            :readonly="bookingData.isHazardous"
                            tabindex="2"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-spacer></v-spacer>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        <v-combobox
                            v-model="stepState.selectedShipperOrTypeahead"
                            :loading="stepState.loadingMatchingShippers"
                            :items="stepState.matchingShippers"
                            :search-input.sync="stepState.shipperSearch"
                            item-text="idAndName"
                            item-value="id"
                            :hide-no-data="!stepState.isUnknownShipper"
                            :no-data-text="unknownShipperMessageText"
                            :error-messages="stepState.unknownShipperMessage"
                            :rules="stepState.shipperRules"
                            flat
                            hide-details="auto"
                            return-object
                            label="Shipper*"
                            tabindex="3"
                        >
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        <v-text-field
                            v-model="freightData.shippersReference"
                            label="Shipper's Reference"
                            :rules="stepState.shippersReferenceRules"
                            validate-on-blur
                            tabindex="4"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" sm="4">
                <v-btn
                    color="secondary"
                    plain
                    @click="cancel"
                    tabindex="7"
                >
                    Cancel
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="9" sm="8" class="text-right">
                <v-btn
                    color="secondary"
                    plain
                    @click="previous"
                    tabindex="6"
                >
                    Previous
                </v-btn>
                <v-btn
                    color="secondary"
                    plain
                    @click="next"
                    tabindex="5"
                >
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import ExportPreAdviceSummary from "@/components/exportPreAdvice/ExportPreAdviceSummary.vue";
import {mapState, mapGetters, mapActions} from "vuex";

export default {
    name: "ExportFreightDetails.vue",

    props: {
        titleBase: {
            type: String,
            required: true
        },
    },

    components: {
        ExportPreAdviceSummary
    },

    data() {
        return {
            minCharsForFetchingMatchingPackPoints: 2,
            minCharsForFetchingMatchingShippers: 2,
            minCharsForDisplayUnknownShipper: 2,
            noMatchingPackPointMessage: 'Napier Port has no information on this Pack point',
            unknownShipperMessageText: 'Shipper not (yet) known to Napier Port',
            hazardCommodityCode: 'HAZ',

            stepState: {
                packPointRules: [
                    val => !!val || 'Pack point is required'
                ],
                commodityRules: [
                    val => !!val || 'Commodity is required'
                ],
                shipperRules: [
                    val => !!val || 'Shipper is required'
                ],
                shippersReferenceRules: [
                    val => !val || val.length <= 20 || `Max length of Shipper's Reference is 20. Current length is ${val.length}`
                ],

                packPointSearch: '',
                loadingMatchingPackPoints: false,
                debouncedLoadingPackPointsTimerId: null,
                matchingPackPoints: [],
                showNoMatchingPackPointFound: false,
                packPointErrorMessage: '',

                loadingCommodities: false,

                shipperSearch: '',
                selectedShipperOrTypeahead: null,
                loadingMatchingShippers: false,
                debouncedLoadingShippersTimerId: null,
                matchingShippers: [],
                isUnknownShipper: false,
                unknownShipperMessage: null,

                formIsValid: false,
            }
        }
    },

    computed: {
        ...mapState('exportPreAdvice', [
            'commodities'
        ]),
        ...mapGetters('exportPreAdvice', [
            'workingBookingDetails',
            'workingFreightDetails'
        ]),
        bookingData() {
            return this.workingBookingDetails;
        },
        freightData() {
            return this.workingFreightDetails;
        },
        showSummary() {
            return !this.$vuetify.breakpoint.xs;
        }
    },

    mounted() {
        this.loadCommodities();
        this.loadUserShipperDetails();

        if (this.freightData.packPoint) {
            this.stepState.packPointSearch = this.freightData.packPoint.id;
            this.stepState.matchingPackPoints.push(this.freightData.packPoint);
        }

        if (this.freightData.shipperId) {
            let selectedShipper = {
                id: this.freightData.shipperId,
                name: this.freightData.shipperName,
                idAndName: this.freightData.shipperId + ' (' + this.freightData.shipperName + ')'
            };
            this.stepState.selectedShipperOrTypeahead = selectedShipper;
            this.stepState.matchingShippers.push(selectedShipper);
            this.stepState.isUnknownShipper = false;
            this.stepState.unknownShipperMessage = null;
        }
    },

    watch: {
        'bookingData.isHazardous'(val) {
            if (val) {
                this.freightData.commodityCode = this.hazardCommodityCode;
            }
        },
        'stepState.packPointSearch'(val) {
            this.stepState.packPointErrorMessage = '';

            const searchVal = val?.trim();
            if (searchVal
                && (!this.freightData.packPoint || searchVal !== this.freightData.packPoint.id)
                && searchVal.length >= this.minCharsForFetchingMatchingPackPoints
                && this.packPointSearchIsValid(searchVal)) {

                this.stepState.showNoMatchingPackPointFound = false;

                this.fetchMatchingPackPointsDebounced(searchVal);
            } else if (!this.packPointSearchIsValid(searchVal)) {
                if (searchVal) {
                    this.stepState.packPointErrorMessage = this.packPointSearchValidate(searchVal);
                }
            }
        },
        'stepState.shipperSearch'(val) {
            // Assume any data typed is valid until shown otherwise. Only lookup as well
            // if search value type consists of the minimum number of characters
            const searchVal = val?.trim();
            if (searchVal && searchVal.length >= this.minCharsForFetchingMatchingShippers) {
                if (searchVal !== this.freightData.shipperIdAndName) {
                    this.stepState.isUnknownShipper = false;
                    this.stepState.unknownShipperMessage = null;
                    this.fetchMatchingShippersDebounced(searchVal);
                }
            } else {
                this.stepState.isUnknownShipper = false;
                this.stepState.unknownShipperMessage = null;
            }
        },
        'stepState.selectedShipperOrTypeahead': {
            handler(newVal) {
                if (newVal) {
                    if (typeof newVal === 'string') {
                        // This is a shipper that is unknown in N4
                        this.stepState.isUnknownShipper = true;
                        this.stepState.unknownShipperMessage = this.unknownShipperMessageText;
                    } else {
                        this.freightData.shipperId = newVal.id;
                        this.freightData.shipperName = newVal.name;
                        this.freightData.shipperIdAndName = newVal.idAndName;
                        this.stepState.isUnknownShipper = false;
                        this.stepState.unknownShipperMessage = null;
                    }
                } else {
                    this.freightData.shipperId = '';
                    this.freightData.shipperName = '';
                    this.freightData.shipperIdAndName = '';
                    this.stepState.isUnknownShipper = false;
                    this.stepState.unknownShipperMessage = null;
                }
            },
            deep: true
        },
    },

    methods: {
        ...mapActions('exportPreAdvice', [
            'setWorkingFreightData',
            'fetchCommodities',
            'resetWorkingExportPreAdvice'
        ]),

        loadCommodities() {
            this.stepState.loadingCommodities = true;

            this.fetchCommodities()
                .finally(() => {
                    this.stepState.loadingCommodities = false;
                    if (this.bookingData.isHazardous) {
                        this.freightData.commodityCode = this.hazardCommodityCode;
                    }
                });
        },

        loadUserShipperDetails() {
            // If the user's company is a know "shipper" in N4, we default the shipper to that value
            if (this.$store.state.account.userAccount.company.n4ShipperConsigneeCompanyId) {
                CMSApi.fetchUserShipperDetails()
                    .then(data => {
                        if (data && !this.freightData.shipperId) {
                            this.freightData.shipperId = data.id;
                            this.freightData.shipperName = data.name;
                            this.freightData.shipperIdAndName = data.idAndName;
                            this.stepState.matchingShippers.push(data);
                            this.stepState.selectedShipperOrTypeahead = data;
                            this.stepState.isUnknownShipper = false;
                            this.stepState.unknownShipperMessage = null;
                        }
                    });
            }
        },

        setFocusOnFirstField() {
            this.$refs.packPointInput.focus();
        },

        packPointSearchIsValid(val) {
            const validateResult = this.packPointSearchValidate(val);

            return typeof validateResult === 'boolean' && validateResult === true;
        },

        packPointSearchValidate(val) {
            let rulesToCheck = [
                val => !!val || 'Pack point is required',
                val => !val || val.length <= 7 || 'A Pack point must be 7 chars or less',
                val => !val || /^[A-Za-z0-9]+$/.test(val) || 'A Pack point can only contain letters and numbers'
            ];

            let message = null;
            let idIsValid = true;

            rulesToCheck.forEach(function (rule) {
                let result = rule(val);
                if (!result || typeof result === 'string') {
                    message = message || result;
                    idIsValid = false;
                }
            });

            return idIsValid || message;
        },

        fetchMatchingPackPointsDebounced(matchString) {
            this.stepState.loadingMatchingPackPoints = true;

            // cancel any pending call to fetch matching pack points, the match string has already changed.
            clearTimeout(this.stepState.debouncedLoadingPackPointsTimerId);

            // delay new call 500ms
            this.stepState.debouncedLoadingPackPointsTimerId = setTimeout(() => {
                this.fetchMatchingPackPoints(matchString);
            }, 500);
        },

        fetchMatchingPackPoints(matchString) {
            CMSApi.fetchExportPreAdviceMatchingPackPoints(matchString)
                .then(data => {
                    // Only process this response if the matchString is still the same as the search text at the
                    // moment, otherwise it is a response to already expired data
                    if (matchString === this.stepState.packPointSearch?.trim()) {
                        this.stepState.matchingPackPoints = data.slice();   // copy data
                        if (!data || data.length === 0) {
                            this.stepState.showNoMatchingPackPointFound = true;
                            this.freightData.packPoint = null;
                        }
                    }
                })
                .finally(() => {
                    this.stepState.loadingMatchingPackPoints = false;
                });
        },

        fetchMatchingShippersDebounced(matchString) {
            this.stepState.loadingMatchingShippers = true;

            // cancel any pending call to fetch matching shippers, the match string has already changed.
            clearTimeout(this.stepState.debouncedLoadingShippersTimerId);

            // delay new call 500ms
            this.stepState.debouncedLoadingShippersTimerId = setTimeout(() => {
                this.fetchMatchingShippers(matchString);
            }, 500);
        },

        fetchMatchingShippers(matchString) {
            CMSApi.fetchExportPreAdviceMatchingShippers(matchString)
                .then(data => {
                    // Only process this response if the matchString is still the same as the search text at the
                    // moment, otherwise it is response to already expired data
                    if (matchString === this.stepState.shipperSearch) {
                        this.stepState.matchingShippers = data.slice();

                        this.stepState.isUnknownShipper =
                            this.stepState.shipperSearch &&
                            this.stepState.shipperSearch.length >= this.minCharsForDisplayUnknownShipper &&
                            (!data || data.length === 0);
                        if (this.stepState.isUnknownShipper) {
                            this.stepState.unknownShipperMessage = this.unknownShipperMessageText;
                        }
                    }
                })
                .finally(() => {
                    this.stepState.loadingMatchingShippers = false;
                });
        },

        resetFormForAdviseAnother(isSameBooking) {
            if (!isSameBooking) {
                this.stepState.packPointSearch = '';
                this.stepState.matchingPackPoints = this.stepState.matchingPackPoints.splice(0);

                // reset shipper details to nothing, but then also reload the details of the user's shipper if their company 
                // is a shipper themselves.
                this.stepState.shipperSearch = '';
                this.stepState.matchingShippers = [];
                this.loadUserShipperDetails();
            }

            this.$refs.freightDetailsForm.resetValidation();
        },

        next() {
            // First make sure the form is valid. We check separately that we are not having an "unknown shipper" sitting
            // in the shipper selection
            this.formIsValid = this.$refs.freightDetailsForm.validate()
                && !this.stepState.isUnknownShipper
                && !(this.bookingData.isHazardous && this.freightData.commodityCode !== this.hazardCommodityCode)

            if (this.formIsValid) {
                this.setWorkingFreightData(this.freightData);
                this.$emit("next");
            }
        },

        previous() {
            // Does not need to be valid (yet) if the user decides to do a step back 
            this.setWorkingFreightData(this.freightData);
            this.$emit("previous");
        },

        cancel() {
            this.resetWorkingExportPreAdvice();
            this.$emit("cancel");
        },
    }
}
</script>
