export default {
    methods: {
        extractErrorsFromAjaxResponse: function (errors, includeExceptions) {
            if (includeExceptions === undefined) includeExceptions = false;

            let errorMessage = '';

            for (var key in errors) {
                //if we aren't interested in exceptions, skip
                if (!includeExceptions && key == "Exception")
                    continue;

                for (var e in errors[key]) {
                    errorMessage = errorMessage.concat(errors[key][e]) + "\n"
                }
            }

            return errorMessage;
        },

        formatDate(value) {
            if (value) {
                return window.Moment(String(value)).format('DD-MM-YYYY');
            } else {
                return "";
            }
        },

        formatDateTime(value) {
            if (value) {
                return window.Moment(String(value)).format('DD-MM-YYYY HH:mm');
            } else {
                return "";
            }
        },

        formatTime(value) {
            if (value) {
                return window.Moment(String(value)).format('HH:mm');
            } else {
                return "";
            }
        },

        inputTextTransformUppercaseAlphaNumericOnly(input) {
            //need to ensure caret is kept in correct spot - otherwise always set to end of input
            let start = input.selectionStart;
            let originalLength = input.value.length;

            input.value = input.value.replace(/[^0-9a-zA-Z_]/, '').toUpperCase();

            //if we stripped out an invalid character, then move caret position back 1 position
            if (start != 0 && input.value.length < originalLength)
                start = start - 1;

            input.setSelectionRange(start, start);
        },

        checkValidationRules(val, rulesToCheck) {
            // Validation rules should be in the form of an array of functions, each return either true or an validation
            // error message. Like:
            // rules = [
            //      val => !!val || 'Container Number is required',
            //      val => !val || val.length !== 11 || 'Container number must be eleven characters 
            // ]
            // This function will then return "true", meaning the "val" was valid, or the first validation message
            // where it did not comply.
            let message = null;
            let idIsValid = true;

            rulesToCheck.forEach(function (rule) {
                if (idIsValid) {        // Only check if still valid
                    let result = rule(val);
                    if (!result || typeof result === 'string') {
                        message = result;
                        idIsValid = false;
                    }
                }
            });

            return idIsValid || message;
        },

        preventExponentials(evt) {
            if (['e'].includes(evt.key)) {
                evt.preventDefault();
            }
        },

        preventExponentialsAndNegatives(evt) {
            if (['e', '-'].includes(evt.key)) {
                evt.preventDefault();
            }
        },

        hasNonEmptyValue(val) {
            if (val === undefined || val === null)
                return false; 
            
            // for strings also check empty string. And for arrays check length > 0
            if (typeof val === 'string') {
                return val.trim() !== '';
            } else if (Array.isArray(val)) {
                return val.length > 0;
            } else {
                return true;
            }
        },

        objectsAreEqual(a, b) {
            return a.length === b.length && // same length and
                a.every( // every element in a
                    e1 => b.some( // has a match in b
                        e2 => Object.keys(e1).every(key => e1[key] === e2[key])
                    )
                )
        }
    }
}