import utils from '@/utils/vuexUtils.js'

//initial state
const state = {
    //Objects
    gates: [],

    //Loading indicators
    vx_loadingGates: false,
    vx_addingGate: false,

    //Form
    formMode: 'add',
}

// getters
const getters = {

}

// actions
const actions = {
    fetchAllGates: ({ commit, state }) => {
        state.vx_loadingGates = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchAllGates(), 'setGates');
    },

    fetchActiveGates: ({ commit, state }) => {
        state.vx_loadingGates = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchActiveGates(), 'setGates');
    },

    removeGate: ({ commit, state }, id) => { // eslint-disable-line no-unused-vars
        return new Promise(function(resolve, reject) {
            CMSApi.deleteGate(id)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.log("Unable to delete gate");
                    reject(error)
                })
                .finally(() => {

                })
        });
    },

    saveGate: ({ state }, postData) => {
        state.vx_addingGate = true;
        let apiFunction = state.formMode === 'add' ? CMSApi.postGate(postData) : CMSApi.putGate(postData);
        let apiErrorMessage = state.formMode === 'add' ? 'Unable to create gate' : 'Unable to update gate';
        return new Promise(function(resolve, reject) {
           apiFunction
               .then(data => {
                   window.App.$emit('show-snackbar', "success", state.formMode  === 'add' ? 'Gate created!': 'Gate updated!');
                   resolve(data);
               })
               .catch(error => {
                   console.log(apiErrorMessage);
                   reject(error);
               })
               .finally(() => {
                   state.vx_addingGate = false;
               })
        });
    }
}

// mutations
const mutations = {
    setGates( state, gates ) {
        state.gates = gates;
        state.vx_loadingGates = false;
    },

    setGateFormMode( state, formMode) {
        state.formMode = formMode;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}