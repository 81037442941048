<!--suppress CssUnresolvedCustomProperty -->
<template>
  <div>
    <v-card outlined>
      <v-toolbar class="datatableToolbar" flat>
        <v-container>
          <v-row>
            <v-col cols="3" xl="3" lg="5" md="5" id="formTitleColumn">
              <v-btn
                  v-if="formActive"
                  text
                  color="primary"
                  @click="backToList"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <span id="formTitleSpan">
                {{ formTitle }}
              </span>
            </v-col>

          <v-spacer></v-spacer>
          <v-col cols="3" xl="3" lg="5" md="5"  v-if="formActive">
            <v-chip
                class="ma-2 float-left"
                color="#a3bc00"
                label
                text-color="white"
            >
              <v-icon left>
                mdi-train-variant
              </v-icon>
              {{ workingPlannerBooking.routes }}
            </v-chip>
            <v-chip
                class="ma-2 float-right"
                color="#a3bc00"
                label
                text-color="white"
            >
              <v-icon left>
                mdi-calendar-text-outline
              </v-icon>
              {{ readableBookingDate }}
            </v-chip>
          </v-col>
        </v-row>
        </v-container>
      </v-toolbar>
      <TransportPlannerForm
              ref="plannerForm"
              v-if="formActive"
              @formIsValid="setForm($event)"
              :key="`${componentKey}=1`"
      />
      <TransportPlannerList
          v-show="!formActive"
          ref="plannerListRef"
          @activateForm="newForm"
          @activateFormEdit="editForm"
      />
      <v-card-actions v-if="formActive">
        <v-spacer></v-spacer>
        <v-btn
            color="secondary"
            text
        >
          Reset
        </v-btn>
        <v-btn
            color="secondary"
            text
            :disabled="!formIsValid"
            @click="saveBooking"
        >
          {{ saveButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import TransportPlannerList from "@/components/transportCapacity/planner/TransportPlannerList.vue";
import TransportPlannerForm from "@/components/transportCapacity/planner/TransportPlannerForm.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import CMSApi from "@/services/CMSApi";

export default {
  components: {TransportPlannerForm, TransportPlannerList},

  data() {
    return {
      formActive: false,
      formIsValid : false,

      // Component
      componentKey: 0,
      componentFirstLoad: true,
    }
  },

  computed : {
    ...mapState('transportPlanner', [
        'workingPlannerBooking',
        'formFromMaster'
    ]),

    formTitle() {
      return this.formActive ?
          this.workingPlannerBooking.trainServicePlannerBookingId ?
          'Transport Planner Form (Edit)' : 'Transport Planner Form (New)'
      : 'Transport Planner List';
    },

    readableBookingDate() {
      return window.Moment(this.workingPlannerBooking.instanceDate, 'YYYY-MM-DD').format("dddd, MMMM Do YYYY");
    },

    saveButtonText() {
      return this.workingPlannerBooking.trainServicePlannerBookingId ? 'Update' : 'Save';
    },

  },

  methods : {
    ...mapActions('transportPlanner', [
        'resetWorkingPlannerBooking'
    ]),

    ...mapMutations('transportPlanner', [
        'resetPlannerForm'
    ]),

    async backToList() {
      this.formActive = false;
      await this.$nextTick();
      this.$refs.plannerListRef.switchMe = !this.formFromMaster;
      await this.$refs.plannerListRef.reload();
      this.resetPlannerForm();
    },

    setForm(val) {
      this.formIsValid = val
    },

    async newForm() {
        if (!this.componentFirstLoad)
            this.componentKey += 1;

        this.componentFirstLoad = false;
        this.formActive = true;
    },

    async editForm() {
      this.formActive = true;
      this.formIsValid = true;
      await this.$nextTick();
      this.$refs.plannerForm.setFormForEdit();
    },

    async saveBooking() {
      if (await this.$root["$confirm"].open('Transport Planner', 'Proceed in saving the booking?', {color: 'info'})) {
        let postData = this.workingPlannerBooking.convertToSubmittableService();

        if (this.workingPlannerBooking.trainServicePlannerBookingId) {

          CMSApi.putPlannerBooking(postData)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Booking updated");
                this.resetWorkingPlannerBooking();
                this.backToList();
              })
              .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to update booking', errorMessage)
              })

        } else {
          CMSApi.postPlannerBooking(postData)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Booking created");
                this.resetWorkingPlannerBooking();
                this.backToList();
              })
              .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to create booking', errorMessage)
              })
        }
      }
    }
  },

}
</script>

<style scoped>
#formTitleSpan {
  margin-left: 10px ;
  font-size: 1.5rem;
  color: var(--v-propelDarkGrey-base) !important;
  vertical-align: bottom;
}

#formTitleColumn {
  margin-top: 10px;
}
</style>