<template>
    <div>
        <v-card id="userList" elevation="1">
            <v-toolbar class="datatableToolbar" flat>
                <v-toolbar-title>User Management</v-toolbar-title>

                <div class="flex-grow-1"></div>

                <v-btn
                    v-show="displayUserManagementControls"
                    tile class="mb-2"
                    color="secondary"
                    @click="newUser" >
                        <v-icon>mdi-plus</v-icon>
                        <span v-if="$vuetify.breakpoint.smAndUp">New User</span>
                </v-btn>
            </v-toolbar>

            <v-container class="datatableControls" id="userListTableControls">
                <v-row>                    
                    <v-col v-if="allowSelectionOfCompany" cols="12" sm="6" md="5" lg="3" xl="3">
                        <v-autocomplete 
                            :items="companies" item-text="name" item-value="companyId"
                            v-model="selectedCompanyId"
                            label="Company" placeholder="Select a company" no-data-text="No matching companies" hide-details>
                        </v-autocomplete>
                    </v-col>
                    <div v-if="allowSelectionOfCompany" class="flex-grow-1"></div>
                    <v-col v-show="displayUserManagementControls" cols="12" sm="6" md="5" lg="4" xl="4">                        
                        <v-text-field clearable v-model="searchText" label="Search by Name, Mobile # or Email Address" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <DxDataGrid
                id="userGrid"
                :ref="dataGridRefName"
                :data-source="users"
                :show-borders="false"
                :hoverStateEnabled="true"
                noDataText="No current users"
                @editing-start="editUser"
                @row-removing="deleteUser"
                :column-auto-width="true"
                :column-hiding-enabled="true"                
            >
                <DxHeaderFilter :visible="false" />
                <DxSorting mode="multiple"/>

                <DxEditing
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="row"
                >
                    <DxTexts confirmDeleteMessage="" />
                </DxEditing>

                <DxPaging :page-size="10"/>
                <DxPager
                    :show-page-size-selector="true"
                    :allowed-page-sizes="pageSizes"
                    :show-info="true"
                />

                <DxColumn data-field="firstName" data-type="string" />
                <DxColumn data-field="lastName" data-type="string" />
                <DxColumn data-field="mobileNumber" data-type="string" :hiding-priority="1" />
                <DxColumn data-field="emailAddress" data-type="string" :hiding-priority="2" />
                <DxColumn data-field="status" cell-template="statusCellTemplate"  :hiding-priority="3" />
                <template #statusCellTemplate="{ data }">
                    <div>
                        <span v-if="data.value != UserStatus.Pending">{{formatUserStatus(data.value)}}</span>                        
                        <span v-else><a href="#" @click.prevent="approveUser(data)">{{formatUserStatus(data.value)}}</a></span>
                    </div>
                </template>

                <DxColumn data-field="roles" cell-template="roleCellTemplate" css-class="columnWordWrap" :hiding-priority="4" />
                <template #roleCellTemplate="{ data }">
                    <div>
                        <v-chip                            
                            v-for="(item, i) in data.value" :key="i"
                            color="propelDarkGrey"
                            label small dark
                            class="roleChips"
                        >{{item.name}}</v-chip>
                    </div>
                </template>

                <DxColumn type="buttons" cell-template="actionCellTemplate" />
                <template #actionCellTemplate="{ data }">
                    <div>
                        <v-icon color="primary" size="18" class="mr-2" @click="editUser(data)" v-visible="isUserEditable(data)">
                            mdi-pencil
                        </v-icon>
                        <v-icon color="primary" size="18" class="mr-2" @click="deleteUser(data)">
                            mdi-delete
                        </v-icon>
                    </div>
                </template>
            </DxDataGrid>
        </v-card>
        <UserForm
            ref="userForm"
            :companyId="companyId"
            v-on:userCreated="userCreatedOrUpdated($event)"
            v-on:userUpdated="userCreatedOrUpdated($event)" />
        <UserApproval
            ref="userApproval"
            :companyId="companyId"
            v-on:userApproved="userCreatedOrUpdated($event)"
            v-on:userRejected="userDeleted($event)" />
    </div>
</template>

<script>
import {UserStatus, EnumHelpers} from '@/utils/constants.js';
import UserForm from './UserForm.vue';
import UserApproval from './UserApproval.vue';

import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSorting,
    DxEditing,
    DxTexts
} from 'devextreme-vue/data-grid';


export default {
    components: {
        UserForm,
        UserApproval,
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxPager,
        DxPaging,
        DxSorting,
        DxEditing,
        DxTexts
    },

    props: {
        allowSelectionOfCompany: {
            type: Boolean,
            default: false
        }, 
    },
    data () {
        return {
            users: [],
            companies: [],
            selectedCompanyId: null,
            loadingUsers: false,
            searchText: '',
            pageSizes: [10, 25, 50, 100],
            dataGridRefName: 'userGrid',
            initialLoad: true,
        }
    },
    computed: {
        displayUserManagementControls () {
            return !this.allowSelectionOfCompany || this.selectedCompanyId != null; 
        },
        roleColumnWidth () {
            return (this.$vuetify.breakpoint.lgAndUp ? null : "100"); 
        },
        companyId () {
            if (!this.allowSelectionOfCompany && this.$store.state.account.userAccount != null)
                return this.$store.state.account.userAccount.companyId;
            else
                return this.selectedCompanyId;
        }
    },

    watch: {
        searchText: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            this.$refs[this.dataGridRefName].instance.searchByText(newVal?.trim());
        },

        selectedCompanyId:function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            this.fetchUsers();
        },

        loadingUsers: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            if(newVal)
                this.$refs[this.dataGridRefName].instance.beginCustomLoading();
            else
                this.$refs[this.dataGridRefName].instance.endCustomLoading();
        }
    },

    mounted () {
        this.UserStatus = UserStatus;

        if (this.allowSelectionOfCompany)
            this.fetchCompanies();
        else
            this.fetchUsers();
    },

    methods: {
        fetchUsers() {
            this.loadingUsers = true;

            if (this.allowSelectionOfCompany && this.selectedCompanyId != null)
            {
                CMSApi.fetchUsersForCompany(this.selectedCompanyId)
                    .then(data => {
                        this.users = data;
                    })
                    .finally(() => {
                        this.loadingUsers = false;
                    });
            }
            else
            {
                CMSApi.fetchUsers()
                    .then(data => {
                        this.users = data;
                    })
                    .finally(() => {
                        this.loadingUsers = false;
                    });
            }
        },

        fetchCompanies() {
            this.loadingUsers = true;

            CMSApi.fetchCompaniesLookup()
                .then(data => {
                    this.companies = data;
                })
                .finally(() => {
                    this.loadingUsers = false;
                    if (this.initialLoad) {
                        this.checkUrl();
                    }
                });
        },

        isUserEditable(e){ // eslint-disable-line no-unused-vars
            return e.row.data.status == "A";
        },

        checkUrl() {
            if (this.$route.query.companyId != undefined && this.$route.query.emailAddress != undefined) {
                this.selectedCompanyId = parseInt(this.$route.query.companyId);
                this.searchText = this.$route.query.emailAddress;
                this.initialLoad = false;
            }
        },

        async deleteUser(e) {
            e.cancel = true;

            if (await this.$root.$confirm.open('Remove user?', 'Are you sure you want to remove this user from your company?', { color: 'warning' }))
            {
                App.$appAnalytics.trackEvent('Users - Delete User Click');
                this.loadingUsers = true;

                CMSApi.deactivateUser(e.data.userId)
                    .then(() => {
                        this.userDeleted(e.data.userId);
                        window.App.$emit('show-snackbar', "success", "User removed");
                    })
                    .catch(error => {
                        let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                        this.$root.$alert.show('Unable to deactivate user', errorMessage)
                    })
                    .finally(() => {
                        this.loadingUsers = false;
                    });
            }
        },

        editUser(e) {
            App.$appAnalytics.trackEvent('Users - Edit User Click');
            e.cancel = true;
            this.$refs.userForm.editUser(e.data);
        },

        newUser() {
            App.$appAnalytics.trackEvent('Users - Create User Click');
            this.$refs.userForm.createUser();
        },

        approveUser(e) {
            App.$appAnalytics.trackEvent('Users - Approve User Click');
            this.$refs.userApproval.viewUser(e.data);
        },

        userCreatedOrUpdated(user) {
            const existingRecordIndex = this.users.findIndex(i => i.userId == user.userId);

            //if record exists in list, update, else add.
            if (existingRecordIndex >= 0)
                Object.assign(this.users[existingRecordIndex], user);
            else
                this.users.unshift(user);
        },

        userDeleted(userId) {
            this.users.splice(this.users.findIndex(i => i.userId == userId), 1);
        },

        formatUserStatus(value) {
            return EnumHelpers.getUserStatusDescriptionByCode(value);
        },

    }
};
</script>

<style>
    #userList {
        padding-bottom: 12px;
    }

    .roleChips {
        margin: 2px;
        margin-right: 5px;
    }

    .columnWordWrap {
        white-space: break-spaces;
    }

</style>