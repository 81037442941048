
// initial state
import trainServiceData from "@/models/transportCapacity/trainServiceData";
import utils from '@/utils/vuexUtils.js'
import Vue from "vue";

const state = {
    trainServices : [],
    containerTerminalSitesAndSidings : [],
    defaultWorkstationCode: 'NPR',

    // Data Model
    workingTrainService : new trainServiceData.tsDetails(),

    // Form Validators
    formServiceDetail: false,
    formServiceDays: false,
    formServiceWagons: false,

    // Loading Indicators
    retrievingTrainService: false,
    loadingTrainService: false,
    loadingContainerTerminalSitesAndSidings: false,
}

// getters
const getters = {
    workingService: state => {
        return state.workingTrainService;
    },

    serviceRoutesTerminalOrigins: state => {
        return helpers.isNotEmptyOrNull(state.workingTrainService.trainServiceDetails.trainServiceDirection) ?
                state.workingTrainService.trainServiceDetails.trainServiceDirection.toLowerCase() === 'outbound' ?
                    state.containerTerminalSitesAndSidings.filter(k => k.workstationCode === state.defaultWorkstationCode && k.isActive)
                    : state.containerTerminalSitesAndSidings.filter(k => k.workstationCode !== state.defaultWorkstationCode && k.isActive)
                : [];
    },

    serviceRoutesTerminalDestinations: state => {
        return helpers.isNotEmptyOrNull(state.workingTrainService.trainServiceDetails.trainServiceDirection) ?
            state.workingTrainService.trainServiceDetails.trainServiceDirection.toLowerCase() === 'outbound' ?
                state.containerTerminalSitesAndSidings.filter(k => k.workstationCode !== state.defaultWorkstationCode && k.isActive)
                : state.containerTerminalSitesAndSidings.filter(k => k.workstationCode === state.defaultWorkstationCode&& k.isActive)
            : [];
    },

    getRouteCode: (state) => (id) => {
        return state.containerTerminalSitesAndSidings.find( ({transportServiceTerminalSiteId}) => transportServiceTerminalSiteId === id).workstationCode;
    },

    getRouteReadableName: (state) => (id) => {
        return state.containerTerminalSitesAndSidings.find( ({transportServiceTerminalSiteId}) => transportServiceTerminalSiteId === id).workstationName;
    },

    getNextWagonSequence(state) {
        return Object.keys(state.workingTrainService.trainServiceRoutes.wagons).length + 1;
    },

    allFormsAreValid(state) {
        return state.formServiceDays && state.formServiceDetail && state.formServiceWagons;
    }
}

// actions
const actions = {

    fetchContainerTerminalSitesAndSidings: ({commit, state}) => {
        state.loadingContainerTerminalSitesAndSidings = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchContainerTerminalSitesAndSidings(), 'setContainerTerminalSitesAndSidings');
    },

    fetchTrainServices: ({commit, state}) => {
        state.loadingTrainService = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchTrainServices(), 'setTrainServices')
    },

    setWorkingTrainServiceForEdit: ({commit}, flattenedServiceData) => {
      let workingService = new trainServiceData.tsDetails();

      workingService.setFromFlattenedData(flattenedServiceData);

      commit('setCurrentWorkingTrainService', workingService);
    },

    resetWorkingTrainService: ({commit}) => {
        let newService = new trainServiceData.tsDetails();
        commit('setCurrentWorkingTrainService', newService)
    }

}

// mutations
const mutations = {
    setTrainServices(state, value) {
        state.trainServices = value;
        state.loadingTrainService = false;
    },

    setServiceDetailsForm(state, value) {
        state.formServiceDetail = value;
    },

    setCurrentWorkingTrainService(state, service) {
        state.workingTrainService = service;

        if (state.retrievingTrainService) { //Set timeout to allow rendering of UI
            setTimeout(async () => {
                state.retrievingTrainService = false;
            }, 2000)
        }
    },

    setServiceDaysForm(state, value) {
        state.formServiceDays = value;
    },

    setServiceRoutesForm(state, value) {
        state.formServiceWagons = value;
    },

    setContainerTerminalSitesAndSidings(state, value) {
        state.containerTerminalSitesAndSidings = value
        state.loadingContainerTerminalSitesAndSidings = false;
    },

    setAllFormAsValidForEdit(state) {
      state.formServiceDetail = true;
      state.formServiceWagons = true;
      state.formServiceDays = true;
    },

    pushRoutesData(state , wagonData) {
        state.workingTrainService.trainServiceRoutes.wagons.push(wagonData);
    },

    updateRoutesData(state, {wagonData, index}) {
        Vue.set(state.workingTrainService.trainServiceRoutes.wagons, index, wagonData);
    },

    resetRoutesData(state) {
        state.workingTrainService.trainServiceRoutes.wagons = [];
    },

    removeWagonFromRoutes(state, index) {

        if (state.workingTrainService.trainServiceRoutes.wagons[index].trainServiceWagonId !== null) {
            //Transfer the removed wagons into a temp
            state.workingTrainService.trainServiceRoutesRemoved.wagons.push(state.workingTrainService.trainServiceRoutes.wagons[index]);
        }

        state.workingTrainService.trainServiceRoutes.wagons.splice(index, 1);

        //after removal sort the object and reassign wagon sequence
        let newWagonData = state.workingTrainService.trainServiceRoutes.wagons.sort((a,b) => b.wagonSequence - a.wagonSequence );

        newWagonData.forEach((item,index) => {
            item.wagonSequence = index+1;
        })

        state.workingTrainService.trainServiceRoutes.wagons = newWagonData;
    },

    setTSDetailsChangeState(state, status) {
        state.workingTrainService.trainServiceChanges.tSDetailsChanged = status;
    },

    setTSDatesChangeState(state, status) {
        state.workingTrainService.trainServiceChanges.tSDatesChanged = status;
    },

    setTSDaysChangeState(state, status) {
        state.workingTrainService.trainServiceChanges.tSDaysChanged = status;
    },

    setTSRoutesChangeState(state, status) {
        state.workingTrainService.trainServiceChanges.tSRoutesChanged = status;
    },

}

const helpers = {
    isNotEmptyOrNull(val) {
        return val !== null && val !== '';
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


export const trainServiceHelpers = helpers;