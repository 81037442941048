export default {
    //allows for a simple call to a vuex commit after fetching data
    CommitPromise: (commit, promise, mutation) => {
        return new Promise(function(resolve, reject) {
            promise // eslint-disable-line no-undef
                .then(data => {
                    commit(mutation, data);
                    resolve(data);
                })
                .catch(error =>  reject(error));
        });
    },

    //allows for a simple call to a vuex commit after fetching data, ensuring data is in an array
    CommitArrayPromise: (commit, promise, mutation) => {
        return new Promise(function(resolve, reject) {
            promise // eslint-disable-line no-undef
                .then(data => {
                    if (!Array.isArray(data))
                        data = [data];

                    commit(mutation, data);
                    resolve(data);
                })
                .catch(error =>  reject(error));
        });
    },
}