import utils from '@/utils/vuexUtils.js';

// initial state
const state = {
    inboundVessels: [],
}

// actions
const actions = {
    fetchInboundVessels: ({ commit, state }, ignoreError = false) => { // eslint-disable-line no-unused-vars
        return utils.CommitArrayPromise(commit, CMSApi.fetchInboundVessels(ignoreError), 'setInboundVessels');
    },
}

// mutations
const mutations = {
    setInboundVessels(state, inboundVessels) {
        state.inboundVessels = inboundVessels;
    },
}

const helpers = {
    isContainerVesselLockedForImport(container) {
        return state.inboundVessels.some(v => v.visitId == container.inBoundActualVisitId && v.importsLockedOff);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}

export const storeVesselHelpers = helpers;