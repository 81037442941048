<template>
    <div>
        <v-card elevation="1">
            <v-container>
                <v-row>
                  <v-col :cols="gridColumnSize">
                      <v-row>
                        <v-col cols="3">
                            <v-toolbar class="datatableToolbar" flat>
                                <v-toolbar-title>Freight Forwarders</v-toolbar-title>
                            </v-toolbar>
                            <DxDataGrid
                                    id="freightForwardersGrid"
                                    :ref="freightForwardersDataGridRefName"
                                    :data-source="freightForwarders"
                                    :show-borders="false"
                                    :hoverStateEnabled="true"
                                    noDataText="No freight forwarders on database"
                                    :column-auto-width="true"
                                    :column-hiding-enabled="true"
                                    key-expr="companyId"
                                    @selection-changed="onFreightForwarderSelect"
                            >
                                <DxPaging :page-size="10"/>
                                <DxPager
                                        :show-page-size-selector="true"
                                        :allowed-page-sizes="pageSizes"
                                        :show-info="true"
                                />

                                <DxColumn data-field="name" data-type="string" caption="Forwarder"/>

                                <DxSelection mode="single" />
                            </DxDataGrid>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="8">
                            <v-row>
                                <v-col>
                                    <v-toolbar class="datatableToolbar" flat>
                                        <v-toolbar-title>Associated Customers</v-toolbar-title>
                                    </v-toolbar>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col class="text-right" cols="4">
                                    <v-btn
                                            v-if="!isFormActive"
                                            tile class="mb-2"
                                            color="secondary"
                                            right
                                            :loading="savingIndicator"
                                            @click="activateForm"
                                            :disabled="selectedFreightForwarderCode === null"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                        <span v-if="$vuetify.breakpoint.smAndUp">Add</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <DxDataGrid
                                    id="forwarderCustomersGrid"
                                    :ref="forwarderCustomersDataGridRefName"
                                    :data-source="freightForwarderCustomers"
                                    :show-borders="false"
                                    :hoverStateEnabled="true"
                                    noDataText="No associated customers yet."
                                    :column-auto-width="true"
                                    :column-hiding-enabled="true"
                                    key-expr="companyId"
                                    @selection-changed="onFreightForwarderSelect"
                            >
                                <DxColumn cell-template="companyNameTemplate" caption="Name" />
                                <template #companyNameTemplate="{ data }">
                                    <div>
                                        {{ getCompanyName(data.data) }}
                                    </div>
                                </template>

                                <DxColumn data-field="groupNumber"></DxColumn>

                                <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Actions" />
                                <template #actionCellTemplate="{ data }">
                                    <div>
                                        <v-icon color="primary" size="18" class="mr-2" @click="editCustomer(data)">
                                          mdi-pencil
                                        </v-icon>
                                        <v-icon color="primary" size="18" class="mr-2" @click="deleteFreightCustomer(data)">
                                            mdi-delete
                                        </v-icon>
                                    </div>
                                </template>
                            </DxDataGrid>
                        </v-col>
                      </v-row>
                  </v-col>
                    <v-col :cols="formColumnSize" v-if="isFormActive">
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col class="text-right" cols="5">
                                <v-btn
                                        v-if="isFormActive"
                                        tile class="mb-2"
                                        color="secondary"
                                        @click="deactivateForm"
                                >
                                    <v-icon>mdi-arrow-collapse-right</v-icon>
                                    <span v-if="$vuetify.breakpoint.smAndUp">Collapse</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-card elevation="3">
                                  <v-card-text>
                                    <ValidationSummary :validationErrors="validationErrors" />
                                    <v-form ref="forwarderForm" lazy-validation>
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12">
                                            <v-select
                                                v-if="!isEditing"
                                                v-model="selectedCustomers"
                                                :items="logisticCustomers"
                                                item-text="name"
                                                item-value="companyId"
                                                label="Customers"
                                                tabindex="1"
                                                multiple
                                                hint="Pick customers you want to associate w/ this forwarder"
                                                :rules="selectedCustomerRules"
                                            ></v-select>
                                            <v-text-field v-else
                                              v-model="selectedCompanyName"
                                              disabled
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row v-if="isEditing">
                                          <v-col cols="12">
                                            <v-select
                                                v-model="editedItem.groupNumber"
                                                :items="groups"
                                                label="Group Number"
                                            ></v-select>
                                          </v-col>
                                        </v-row>

                                      </v-container>
                                    </v-form>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      tile
                                      class="mb-2"
                                      color="primary"
                                      @click="save"
                                      :loading="savingIndicator"
                                    >
                                        <v-icon>mdi-content-save</v-icon>
                                        <span v-if="$vuetify.breakpoint.smAndUp">Save</span>
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import {defineComponent} from 'vue'
import {DxColumn, DxDataGrid, DxPager, DxPaging, DxSelection} from "devextreme-vue/data-grid";
import {mapActions, mapState} from "vuex";
import ValidationSummary from "@/components/global/ValidationSummary.vue";

export default defineComponent({
    name: "TransportMappings-FreightForwarders",

    components: {
        DxDataGrid,
        DxPaging,
        DxPager,
        DxColumn,
        DxSelection,
        ValidationSummary
    },

    data() {
      return {
        // Grid
        freightForwardersDataGridRefName: 'freightForwardersGrid',
        forwarderCustomersDataGridRefName: 'forwarderCustomersGrid',
        pageSizes: [10,15,25],

        // Form
        isFormActive: false,
        isUpdate: false,
        validationErrors: {},
        selectedFreightForwarderCode: null,
        selectedCustomers: [],
        selectedCustomerRules: [
            v => v.length > 0 || 'Select at least one customer'
        ],
        groups: [1,2,3,4],

        savingIndicator: false,

        isEditing : false,
        editedItem : null,
        defaultItem : {
          freightForwarderCustomerId : null,
          groupNumber : null,
        },
      }
    },

    computed : {
        ...mapState('transportMappings', [
            'customers',
            'freightForwarders',
            'freightForwarderCustomers',
        ]),

        selectedCompanyName() {
          return this.getCompanyName(this.editedItem);
        },

        gridColumnSize() {
            return this.isFormActive ? 8 : 12;
        },

        formColumnSize() {
            return this.isFormActive ? 4 : 0;
        },

        logisticCustomers() {
            let recordedCustomers = [];

            for (let i = 0; i < this.freightForwarderCustomers.length; i++) {
                recordedCustomers.push(this.freightForwarderCustomers[i].companyId);
            }

            return this.customers.filter(x => x.companyId !== this.selectedFreightForwarderCode && !recordedCustomers.includes(x.companyId));
        }
    },

    mounted() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.fetchFreightForwarders();
      this.fetchCustomers();
    },

    watch: {
        selectedFreightForwarderCode(val) {
            if (val !== null)
                this.fetchFreightForwarderCustomers(val);
        }
    },

    methods: {
        ...mapActions('transportMappings', [
          'fetchFreightForwarders',
          'fetchFreightForwarderCustomers',
          'fetchCustomers'
        ]),

        onFreightForwarderSelect({ selectedRowsData }) {
          const data = selectedRowsData[0];

          this.selectedFreightForwarderCode = data.companyId;
        },

        activateForm() {
          this.isFormActive = true;
        },

        deactivateForm() {
          this.isEditing = false;
          this.isFormActive = false;
        },


        getCompanyName({companyId}) {
            let company = this.customers.find(x => x.companyId === companyId);
            return company.name;
        },

        save() {
            if (this.$refs.forwarderForm.validate()) {
                this.savingIndicator = true;

                if (this.isEditing) {

                  let putData = {
                    freightForwarderCustomerId : this.editedItem.freightForwarderCustomerId,
                    groupNumber : this.editedItem.groupNumber,
                  };

                  CMSApi.putFreightForwarderCustomer(putData)
                      .then(() => {
                        window.App.$emit('show-snackbar', "success", "Updated freight forwarder customer");
                        this.fetchFreightForwarderCustomers(this.selectedFreightForwarderCode);
                      })
                      .finally(() => {
                        this.savingIndicator = false;
                        this.selectedCustomers = [];
                        this.isFormActive = false;
                      });

                } else {

                  let postData = {
                    freightForwarderCode : this.selectedFreightForwarderCode,
                    customers : [],
                  };

                  for (let i = 0; i < this.selectedCustomers.length; i++) {
                    let customerData = {
                      companyId : this.selectedCustomers[i]
                    }

                    postData.customers.push(customerData);
                  }

                  CMSApi.postFreightForwarder(postData)
                      .then(() => {
                        window.App.$emit('show-snackbar', "success", "Added new customer to freight forwarder.");
                        this.fetchFreightForwarderCustomers(this.selectedFreightForwarderCode);
                      })
                      .finally(() => {
                        this.savingIndicator = false;
                        this.selectedCustomers = [];
                        this.isFormActive = false;
                      });
                }
            }
        },

        async deleteFreightCustomer(e) {
            e.cancel = true;
            if (await this.$root.$confirm.open('Freight Forwarder Customer Removal', 'Are you sure you want to perform this action?', { color: 'warning'})) {
                App["$appAnalytics"].trackEvent('Transport Capacity/ Mappings - Delete Forwarder Customer Click');

                CMSApi.deleteFreightCustomer(e.data["freightForwarderCustomerId"])
                    .then(() => {
                        window.App.$emit('show-snackbar', "success", "Removed customer from freight forwarder.");
                        this.fetchFreightForwarderCustomers(this.selectedFreightForwarderCode);
                    })
                    .catch(error => {
                        let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                        this.$root.$alert.show('Unable to delete freight customer', errorMessage)
                    })
            }
        },

        editCustomer(e) {
          this.isEditing = true;
          this.editedItem = Object.assign({}, e.data);
          console.log(this.editedItem);
          this.activateForm();
        }
    }
})
</script>


<style scoped>

</style>