import Vue from 'vue'
import Vuex from 'vuex'
import settings from './modules/settings'
import account from './modules/account'
import vessels from './modules/vessels'
import notifications from './modules/notifications'
import appointments from './modules/appointments'
import importAdvice from './modules/importAdvice'
import exportPreAdvice from './modules/exportPreAdvice'
import gateManagement from "@/store/modules/gateManagement";
import gateService from "@/store/modules/gateService";
import gateAppointments from "@/store/modules/gateAppointments";
import report from "@/store/modules/report";
import reportManagement from "@/store/modules/reportManagement";
import transportMappings from "@/store/modules/transportMappings";
import trainService from "@/store/modules/trainService";
import transportPlanner from "@/store/modules/transportPlanner";
import transportBilling from "@/store/modules/transportBilling";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        settings,
        account,
        vessels,
        notifications,
        appointments,
        importAdvice,
        exportPreAdvice,
        gateManagement,
        gateService,
        gateAppointments,
        report,
        reportManagement,
        transportMappings,
        trainService,
        transportPlanner,
        transportBilling
    },
});