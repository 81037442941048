<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="7">
            {{ formTitle }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
              <v-btn
                  tile
                  color="secondary"
                  @click="$emit('hideForm')"
                  class="float-right"
                  title="Hide this"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <ValidationSummary :validationErrors="validationErrors" />
        <v-form ref="appointmentForm" lazy-validation>
            <v-container>
              <v-row v-if="userHasRole(userRoles.PortAdmin)">
                <v-col cols="4">
                  <v-select
                      v-model="appointmentData.companyId"
                      :items="companies"
                      item-text="name"
                      item-value="n4TransportCompanyId"
                      label="Company (optional)"
                      :error-messages="validationErrors['CompanyId']"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" v-show="isEditing">
                  <v-text-field
                      :value="appointmentData.appointmentId"
                      disabled
                      label="Appointment #"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-menu
                      v-model="appointmentDateMenu"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="computedAppointmentDateFormatted"
                          label="Date"
                          :disabled="workingAppointment.mode !== AppointmentFormMode.BlockStack"
                          readonly
                          :append-icon="workingAppointment.mode !== AppointmentFormMode.BlockStack ? 'mdi-calendar' : ''"
                          :rules="[v => !!v || 'Date is required']"
                          v-on="on"
                          @click:append="appointmentDateMenu = !appointmentDateMenu"
                          required
                          :error-messages="validationErrors['AppointmentDate']"
                          :hide-details="!validationErrors['AppointmentDate']"
                          autocomplete="off"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="appointmentData.appointmentDate"
                        @input="appointmentDateMenu = false"
                        :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-if="workingAppointment.mode === AppointmentFormMode.General"
                      v-model.number="appointmentData.zone"
                      disabled
                      label="Zone"
                      type="number"
                      min="0"
                      max="23"
                      autocomplete="off"
                      :error-messages="validationErrors['Zone']">
                  </v-text-field>
                  <v-select
                      v-if="workingAppointment.mode !== AppointmentFormMode.General"
                      v-model.number="appointmentData.zone"
                      :items="computedSlotAvailability"
                      :loading="loadingSlotAvailability"
                      item-text="zone"
                      :rules="[v => !!v || 'Zone is required']"
                      label="Zone"
                      required
                      no-data-text="No zones available"
                      :error-messages="validationErrors['Zone']"
                  ></v-select>
                </v-col>
                <v-col cols="3" v-if="GateConfig[gateData.setGateConfig] === 'Full and Empty Containers' ">
                  <div class="d-flex">
                    <p class="mr-4 my-auto" :class="appointmentData.isEmpty? 'inactiveSwitch': 'activeSwitch'"> FCL</p>
                    <v-switch
                        v-model="appointmentData.isEmpty"
                        inset
                    >
                    </v-switch>
                    <p class="mr-4 my-auto" :class="appointmentData.isEmpty? 'activeSwitch': 'inactiveSwitch'"> Empties</p>
                  </div>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="6">
                  <v-radio-group
                      v-model="appointmentData.appointmentType"
                      :disabled="workingAppointment.mode !== AppointmentFormMode.General"
                      row
                  >
                    <v-radio
                        v-if="gateData.isForDropOff"
                        :label="AppointmentType.DropOff.description"
                        :value="AppointmentType.DropOff.code"
                    ></v-radio>
                    <v-radio
                        v-if="gateData.isForPickup"
                        :label="AppointmentType.PickUp.description"
                        :value="AppointmentType.PickUp.code"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="3"
                       v-show="appointmentData.appointmentType === AppointmentType.PickUp.code"
                >
                    <v-text-field
                            v-model.number="appointmentData.position" label="Position" type="number" min="0" max="5"
                            :rules="[v => (appointmentData.appointmentType === AppointmentType.DropOff.code || !!v) || 'Position is required']"
                            required
                            autocomplete="off"
                            :error-messages="validationErrors['Position']" >
                    </v-text-field>
                </v-col>
                <v-col cols="3" v-show="appointmentData.appointmentType === AppointmentType.PickUp.code">
                  <v-select
                      v-model="appointmentData.doorDirection"
                      :items="doorDirectionOptions"
                      item-text="description"
                      item-value="code"
                      label="Doors"
                      :error-messages="validationErrors['DoorDirection']"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer v-if="appointmentData.appointmentType !== 'DropOff'"></v-spacer>
                <v-col cols="3" id="containerNumber">
                  <!-- restrict to alpha numeric and convert to upper case using v-input-upper-alpha-numeric -->
                  <v-text-field
                          v-if="userHasRole(userRoles.PortAdmin) && appointmentData.status === AppointmentState.Expired && appointmentData.isEmpty && appointmentData.appointmentType === 'PickUp'"
                          :label="computedLabel"
                          v-model="appointmentData.containerNumber"
                          :disabled="workingAppointment.mode !== AppointmentFormMode.General"
                          maxlength="11"
                          v-input-upper-alpha-numeric
                          autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                          :hint="containerDetails"
                          persistent-hint
                          :error-messages="validationErrors['ContainerNumber']"
                          @change="containerChange"
                  ></v-text-field>
                  <v-text-field
                      v-if="appointmentData.isEmpty && appointmentData.appointmentType === 'PickUp' && GateConfig[gateData.setGateConfig] !== 'Full and Empty Containers'"
                      label="Empty Release #"
                      v-model="appointmentData.emptyReleaseNumber"
                      :disabled="workingAppointment.mode !== AppointmentFormMode.General"
                      maxlength="20"
                      v-input-upper-alpha-numeric
                      autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                      persistent-hint
                      :error-messages="validationErrors['EmptyReleaseNumber']"
                      :loading="loadingEdoData"
                      @blur="emptyReleaseNumberBlur"
                      @paste="emptyReleaseNumberPaste"
                  ></v-text-field>
                  <v-text-field
                      v-else
                      :label="computedLabel"
                      v-model="appointmentData.containerNumber"
                      :disabled="workingAppointment.mode !== AppointmentFormMode.General"
                      maxlength="11"
                      v-input-upper-alpha-numeric
                      autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                      :hint="containerDetails"
                      persistent-hint
                      :error-messages="validationErrors['ContainerNumber']"
                      @change="containerChange"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <!-- restrict to alpha numeric and convert to upper case
                       can't use v-input-upper-alpha-numeric as v-combobox doesn't have a proper vue event for onimput,
                       as v-on:input only works when leaving control -->
                  <v-combobox
                      label="Truck Rego"
                      v-model="appointmentData.truckRegistration"
                      :items="trucks"
                      :loading="loadingTrucks"
                      :filter="truckRegoFilter"
                      clearable
                      auto-select-first
                      maxlength="6"
                      oninput="window.App.inputTextTransformUppercaseAlphaNumericOnly(this)"
                      autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                      :error-messages="validationErrors['TruckRegistration']"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row v-if="hasValidEdoNumber">
                <v-col cols="12" sm="12" v-if="edoDetails.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          ISO Type
                        </th>
                        <th class="text-left">
                          Quantity to Release
                        </th>
                        <th class="text-left">
                          Message
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(item, i) in edoDetails"
                          :key="i"
                          v-bind:style="getStyle(item['availableQty'], item['isStockAvailable'])"
                      >
                        <td>{{ item.iso }}</td>
                        <td>{{ item.availableQty }}</td>
                        <td v-html="parseEDOMessage(item['availableQty'], item['isStockAvailable'])"></td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" sm="12" v-else>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Release Number
                        </th>
                        <th class="text-left">
                          Message
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr :style="{ 'backgroundColor': eVBSStockInactiveColor}">
                        <td>Release is not currently active</td>
                        <td v-html="eVBSStockInactiveText"></td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary darken-1" text @click="$emit('hideForm')" v-show="!savingIndicator">Cancel</v-btn>
        <v-btn color="primary darken-1" text @click="save" :loading="savingIndicator">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {
    AppointmentFormMode,
    AppointmentState,
    AppointmentType,
    DoorDirection,
    GateConfig,
    GroupDayStackType
} from "@/utils/constants";
import ValidationSummary from "@/components/global/ValidationSummary.vue";
import generalHelpers from "@/utils/generalHelpers";
import {appointmentHelpers} from "@/store/modules/appointments";
import UserRoles from "@/auth/authUserRoles";
import {getInstance} from "@/auth";

export default {
  name: "Appointment-Form",

  components : {
    ValidationSummary
  },

  mixins: [generalHelpers],

  data() {
    return {
      validationErrors: {},
      doorDirectionOptions: Object.values(DoorDirection),

      // Date Pickers
      appointmentDateMenu: false,

      // Misc
      showPositionTooltip: false,
      hasValidEdoNumber: false,
      savingIndicator : false,
      gateOption: ['Empty Containers','Full Containers'],
      gateOptionSelected: null,
      isOptionDisabled: false,
    }
  },

  mounted() {
    this.fetchTrucks();
    this.fetchCompanies();
  },

  computed: {
      AppointmentState() {
          return AppointmentState
      },
    ...mapState('appointments', [
      'workingAppointment',
      'slotAvailability',
      'loadingSlotAvailability',
      'workingEdoNumber',
      'edoData',
      'loadingEdoData',
      'trucks',
      'loadingTrucks',
      'companies',
      'loadingCompanies'
    ]),

    ...mapGetters('settings', [
      'eVBSStockThreshold',
      'eVBSStockGoodColor',
      'eVBSStockWarningColor',
      'eVBSStockWarningText',
      'eVBSStockInactiveColor',
      'eVBSStockInactiveText'
    ]),

    AppointmentType() {
      return AppointmentType
    },

    GateConfig() {
      return GateConfig
    },

    AppointmentFormMode() {
      return AppointmentFormMode
    },


    appointmentData() {
      return this.workingAppointment.appointmentDetails;
    },

    gateData() {
      return this.workingAppointment.gateDetails;
    },

    edoDetails() {
      return this.edoData;
    },

    formTitle() {
      let title;
      switch (true) {
        case this.appointmentData.appointmentId > 0:
            title = 'Edit Appointment - ' + this.gateData.gateName;
          break;
        case this.mode === AppointmentFormMode.DayStack:
            title = 'New Day Stack Appointment';
          break;
        case this.mode === AppointmentFormMode.BlockStack:
            title = 'New Group Stack Appointment';
          break;
        default :
            title = 'New Appointment - ' + this.gateData.gateName;
          break;
      }
      return title;
    },

    computedSlotAvailability () {
      if(this.workingAppointment.mode === AppointmentFormMode.DayStack) {
        if(this.workingAppointment.dayStackType === GroupDayStackType.AM)
          return this.slotAvailability.filter(s => s.zone <= 12);
        else if(this.workingAppointment.dayStackType === GroupDayStackType.PM)
          return this.slotAvailability.filter(s => s.zone >= 12);
      }
      return this.slotAvailability;
    },

    computedLabel() {
      return GateConfig[this.gateData.setGateConfig] === "Empty Containers" ? "Container #" : "Container / Group #";
    },

    containerDetails () {
      let containerDetails = [];

      if(this.appointmentData.containerNumber)
      {
        if(this.appointmentData.containerVGMWeight > 0)
          containerDetails.push("Weight: " + this.appointmentData.containerVGMWeight + "kg");

        if(this.appointmentData.appointmentType === AppointmentType.PickUp.code)
        {
          let importStatus = appointmentHelpers.determineImportStatus(this.appointmentData.containerTransitState, this.appointmentData.containerEstimatedMoveTime, this.appointmentData.containerInBoundMode);
          if(importStatus !== "")
            containerDetails.push("Import Status: " + importStatus);
        }
      }

      return containerDetails.join("\n");
    },

    isEditing() {
      return this.appointmentData.appointmentId > 0;
    },

    computedAppointmentDateFormatted () {
      return this.formatDate(this.appointmentData.appointmentDate);
    },

    allowedDates: val => {
      return new window.Moment(val) >= new window.Moment().startOf('day');
    },

    userRoles() {
      return UserRoles;
    },
  },

  watch : {

  },

  methods : {
    ...mapActions('appointments', [
        'fetchEdoDetails',
        'fetchTrucks',
        'fetchCompanies',
        'createAppointment',
        'updateAppointment',
    ]),

    // Buttons
    save() {
      //vuetify's combobox has an issue, where its v-model requires that you click off the combo before saving/submitting
      //workaround is by doing a setTimeout. See https://github.com/vuetifyjs/vuetify/issues/4679
      setTimeout(async () => {
            if (this.$refs.appointmentForm.validate())
            {
              //if a new truck rego has been entered, check with user they definitely want to use a new one, and if so, add to list
              if((this.appointmentData.truckRegistration !== "" && this.appointmentData.truckRegistration != null) && !this.trucks.includes(this.appointmentData.truckRegistration))
              {
                if(!await this.$root["$confirm"].open('Unknown truck registration', 'The truck registration ' + this.appointmentData.truckRegistration + ' is not known to Napier Port. Are you sure you want to proceed with this truck registration?', { color: 'warning' })) {
                  return;
                } else {
                  this.trucks.push(this.editedItem.truckRegistration);
                }
              }

              //if no value entered for position, set to 0 to avoid json errors (i.e. for drop-offs where position isn't required).
              if(this.appointmentData.position === '')
                this.appointmentData.position = 0;

              this.savingIndicator = true;
              if (this.appointmentData.appointmentId > 0) // Update Appointment
              {
                let putData = this.workingAppointment.convertToSubmittableAppointment();

                this.updateAppointment({data : putData, isPropelAppointment: this.gateData.isFromPropel })
                    .then(() => {
                      App["$appAnalytics"].trackEvent('VBS - Appointment Updated - ' + this.appointmentData.appointmentType);
                      window.App.$emit('show-snackbar', "success", "Appointment updated");
                      this.$emit('appointmentUpdated');
                    })
                    .catch(error => {
                        this.validationErrors = error.response.data.errors;
                    })
                    .finally(() => {
                      this.savingIndicator = false;
                    })
              }
              else // New Appointment
              {
                let postData = this.workingAppointment.convertToSubmittableAppointment();

                this.createAppointment({data : postData, isPropelAppointment: this.gateData.isFromPropel })
                    .then(data => {
                        window.App.$emit('show-snackbar', "success", "Appointment created");
                        App["$appAnalytics"].trackEvent('VBS - Appointment Created - ' + this.appointmentData.appointmentType);
                        this.$emit('appointmentCreated', data);
                    })
                    .catch(error => {
                        console.log(error);
                        this.validationErrors = error.response.data.errors;
                    })
                    .finally(() => {
                        this.savingIndicator = false;
                    });
              }

            }
      })
    },

    // Misc
    containerChange() {
      this.appointmentData.containerTransitState = '';
      this.appointmentData.containerEstimatedMoveTime = '';
      this.appointmentData.containerVGMWeight = 0;
    },

    truckRegoFilter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .startsWith(query.toString().toLowerCase());
    },

    async emptyReleaseNumberPaste(evt) {
      evt.preventDefault();
      await this.$nextTick(() => {
        this.appointmentData.emptyReleaseNumber = evt.clipboardData.getData('text/plain').replace(/\s/g, '');
      });
      await this.emptyReleaseNumberBlur();
    },

    async emptyReleaseNumberBlur() {
      const searchValue = this.appointmentData.emptyReleaseNumber?.trim();
      if (searchValue !== this.workingEdoNumber && searchValue !== "" && searchValue !== undefined) {
        await this.fetchEdoDetails(searchValue);
        this.hasValidEdoNumber = true;
      } else {
        this.hasValidEdoNumber = false;
      }
    },

    parseEDOMessage(qty,hasStock)
    {
      if (qty < this.eVBSStockThreshold || hasStock === false) {
        return this.eVBSStockWarningText;
      }
    },

    getStyle(qty,hasStock) {
      let style = "";
      switch (true) {
        case hasStock === false :
          style = {
            color: 'white',
            background: this.eVBSStockWarningColor
          }
          break;
        case qty >= this.eVBSStockThreshold :
          style = {
            color: 'white',
            background: this.eVBSStockGoodColor
          }
          break;
        case qty < this.eVBSStockThreshold :
          style = {
            color: 'black',
            background: this.eVBSStockWarningColor
          }
          break;
      }
      return style;
    },

    userHasRole(roles) {
      let authService = getInstance();
      return authService.userHasRole(roles);
    }
  }
}
</script>

<style scoped>
  #containerNumber .v-messages {
    white-space: pre-wrap;
  }
</style>