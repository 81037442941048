<template>
    <v-dialog v-model="showDialog" persistent max-width="800px">
        <v-card>
            <v-card-title>
                <span class="headline">New User Approval</span>
            </v-card-title>

            <v-card-text>
                <ValidationSummary :validationErrors="validationErrors" />
                <v-form ref="userform" lazy-validation>
                    <v-container>
                        <v-row>
                            <!-- fields laid out very dynamically here, with name and email taking precedent (note order prop), and mobile being hidden if required -->
                            <v-col cols="12" sm="6" md="">
                                <v-text-field v-model="fullname" label="Name" readonly />
                            </v-col>                            
                            <v-col :order="$vuetify.breakpoint.sm ? '2' : ''">
                                <v-text-field v-model="editedItem.emailAddress" label="Email Address" readonly />
                            </v-col>
                            <v-col cols="12" sm="6" md="3" v-show="editedItem.mobileNumber">
                                <v-text-field v-model="editedItem.mobileNumber" label="Mobile #" readonly />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" >
                                <v-simple-table id="requestedRoles">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Role
                                                </th>
                                                <th class="text-center">
                                                    Requested
                                                </th>
                                                <th class="text-center">
                                                    Approved
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item) in userRoles" :key="item.roleId">
                                                <td>{{ item.name }}</td>
                                                <td>
                                                    <v-checkbox
                                                        :key="item.roleId"
                                                        :value="item"
                                                        v-model="editedItem.requestedRoles" multiple
                                                        hide-details disabled
                                                        class="centeredCheckbox">
                                                    </v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        :key="item.roleId"
                                                        :value="item"
                                                        v-model="editedItem.roles" multiple
                                                        hide-details
                                                        class="centeredCheckbox">
                                                    </v-checkbox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="close" v-show="!savingIndicator">Cancel</v-btn>
                <v-btn color="primary darken-1" text @click="reject" v-show="!savingIndicator">Reject</v-btn>
                <v-btn color="primary darken-1" text @click="approve" :loading="savingIndicator">Approve</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ValidationSummary from '@/components/global/ValidationSummary.vue'

export default {
    components: {
        ValidationSummary,
    },

    props: {
        companyId: {
            type: Number,
            default: null
        }, 
    },
    data () {
        return {
            showDialog: false,
            editedItem: null,
            defaultItem: {
                userId: null,
                companyId: null,
                firstName: '',
                lastName: '',
                mobileNumber: '',
                emailAddress: '',
                roles: [],
                requestedRoles: [],
            },
            companyUserRoles: [],
            savingIndicator: false,
            validationErrors: {},
            loadingCompanyUserRoles: false,
        }
    },

    computed: {
        fullname () {
            return this.editedItem.firstName + ' ' + this.editedItem.lastName; 
        },

        userRoles() {
            //only show mobile roles if user has a mobile #, and include all email roles 
            let possibleRoles = this.companyUserRoles.filter(item => 
                item.requiresEmailIdentity
                || (this.editedItem.mobileNumber && item.requiresSMSIdentity));

            //create slimmed down model that matches v-model
            return possibleRoles.map(r => ({roleId: r.roleId, name: r.name }));
        }
    },

    created () {
        //required to avoid runtime errors when first creating component
        this.editedItem = Object.assign({}, this.defaultItem);
        this.loadCompanyUserRoles();
    },

    watch: {
        companyId:function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            this.loadCompanyUserRoles();
        },
    },
    
    methods: {   

        //public method - point of entry to component
        viewUser (item) {
            let eitem = Object.assign({}, this.defaultItem);
            this.editedItem = Object.assign(eitem, item);
            this.editedItem.roles = this.editedItem.requestedRoles;
            this.showDialog = true;
        },

        loadCompanyUserRoles() {
            this.loadingCompanyUserRoles = true;

            CMSApi.fetchUserRolesForCompany(this.companyId)
                .then(data => {
                    this.companyUserRoles = data;                    
                })
                .finally(() => {
                    this.loadingCompanyUserRoles = false;
                });
        },

        approve () {
            if (this.$refs.userform.validate())
            {
                this.savingIndicator = true;

                CMSApi.activateUser(this.editedItem.userId, this.editedItem.roles.map(r => r.roleId))
                    .then(data => {
                        this.close();

                        this.$emit('userApproved', data);
                        window.App.$emit('show-snackbar', "success", "User approved");
                    })
                    .catch(error => {
                        this.validationErrors = error.response.data.errors;
                    })
                    .finally(() => {
                        this.savingIndicator = false;
                    });
            }
        },

        async reject () {
            if (await this.$root.$confirm.open('Reject user?', 'Are you sure you want to reject this user\'s request to become a user for your company?', { color: 'warning' }))
            {
                this.savingIndicator = true;

                let userId = this.editedItem.userId;
                CMSApi.deactivateUser(userId)
                    .then(() => {
                        this.close();

                        this.$emit('userRejected', userId);
                        window.App.$emit('show-snackbar', "success", "User rejected");
                    })
                    .catch(error => {
                        let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                        this.$root.$alert.show('Unable to reject user', errorMessage)
                    })
                    .finally(() => {
                        this.savingIndicator = false;
                    });
            }
        },

        close () {
            this.showDialog = false;
            
            //clear out data
            this.editedItem = Object.assign({}, this.defaultItem);
            this.clearValidationErrors();
        },

        clearValidationErrors() {
            if(this.$refs.userform != null)
                this.$refs.userform.resetValidation();
            this.validationErrors = {};
        }
    }
};

</script>

<style scoped>
    #requestedRoles
    {
        max-width: 500px
    }
    #requestedRoles th
    {
        padding: 0px;
    }
    #requestedRoles td
    {
        border-bottom: none;
        padding: 0px;
        height: 36px;
    }
</style>