<template>
  <v-card elevation="3">
    <v-card-text>
      <v-container class="datatableControls" >
        <v-form ref="columnDetailForm" lazy-validation>
          <v-row>
            <v-col cols="3">
              <v-select
                  v-if="!columnData.isUpdating"
                  v-model="columnData.name"
                  ref="columnName"
                  :items="dbSourceColumnList"
                  label="Column Name"
                  item-text="columnName"
                  item-value="columnName"
                  clearable
                  tabindex="5"
                  :rules="columnNameRules"
                  :loading="loadingDbSourceColumnList"
              ></v-select>
              <v-text-field
                  v-else
                  ref="columnName"
                  v-model="columnData.name"
                  label="Column Name"
                  tabindex="5"
                  disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  v-model="columnData.ColumnStringName"
                  label="Display Name"
                  :rules="displayNameRules"
                  tabindex="6"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" v-if="reportData.reportDataSourceType === 'N4'">
              <v-select
                  v-model="columnData.type"
                  :items="columnTypes"
                  label="Column Type"
                  item-text="dataTypeName"
                  item-value="dataTypeName"
                  clearable
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="6">
                  <v-btn
                      tile class="mb-2"
                      color="secondary"
                      @click="saveOrUpdate">
                    <v-icon>mdi-plus</v-icon>
                    <span v-if="!columnData.isUpdating">Add</span>
                    <span v-else>Update</span>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <v-btn v-if="columnData.isUpdating"
                         tile class="mb-2"
                         color="secondary"
                         @click="resetColumnDetailsForm"
                  >
                    <v-icon>mdi-undo</v-icon>
                    <span>Reset</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <DxDataGrid
          :ref="reportColumnGridRefKey"
          :data-source="reportData.reportColumns"
          key-expr="SortNumber"
          :show-borders="true"
          :column-auto-width="true"
          :word-wrap-enabled="true"
          :height="300"
      >
        <DxRowDragging
            :allow-reordering="true"
            :on-reorder="onReorder"
            :show-drag-icons="showDragIcons"
        />
        <DxSorting mode="none"/>
        <DxScrolling mode="virtual"/>

        <DxColumn
            data-field="SortNumber"
            data-type="number"
            caption="Seq#"
        />
        <DxColumn
            data-field="ColumnName"
            data-type="string"
            caption="Column Name"
        />
        <DxColumn
            data-field="ColumnStringName"
            data-type="string"
            caption="Display Name"
        />
        <DxColumn
            data-field="ColumnType"
            data-type="string"
            caption="Column Type"
        />
        <DxColumn
            data-type="string"
            caption="Action"
            cell-template="actionTemplate"
        />
        <template #actionTemplate="{ data }">
          <div>
            <v-icon
                color="primary"
                size="18"
                class="mr-2"
                title="Edit Column"
                @click="initUpdate(data)"
            >
              mdi-pencil-box-multiple
            </v-icon>

            <v-icon
                color="primary"
                size="18"
                class="mr-2"
                title="Delete Column"
                @click="deleteColumn(data)"
            >
              mdi-trash-can
            </v-icon>
          </div>
        </template>

      </DxDataGrid>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxRowDragging,
  DxSorting,
  DxScrolling,
} from 'devextreme-vue/data-grid';
import {mapGetters, mapState} from "vuex";
import {ReportColumnType} from "@/utils/constants";
export default {
  name: "ReportForm-Columns",

  components: {
    DxDataGrid,
    DxColumn,
    DxRowDragging,
    DxSorting,
    DxScrolling,
  },

  data() {
    return {
      // Grid config
      reportColumnGridRefKey: 'data-grid-column',
      reportUserGridRefKey: 'data-grid-user',
      showDragIcons: true,

      columnData: {
        name: null,
        ColumnStringName: null,
        isUpdating: false,
        type: null
      },

      // Validation
      columnNameRules: [
        v => !!v || 'Column name is required',
      ],
      displayNameRules: [
        v => !!v || 'Display name is required',
      ],
    }
  },

  computed: {
    ...mapState('reportManagement', [
      'sourceColumnList',
      'loadingSourceColumnList',
    ]),

    ...mapGetters('reportManagement', [
      'workingReport'
    ]),

    reportData() {
      return this.workingReport.reportDetails;
    },

    columnTypes() {
      return ReportColumnType;
    },

    dbSourceColumnList() {
      return this.sourceColumnList.filter( i => !this.savedColumns.includes(i["columnName"]));
    },

    loadingDbSourceColumnList() {
      return this.loadingSourceColumnList;
    },

    savedColumns() {
      return this.reportData.reportColumns.map( a => a.ColumnName);
    },
  },

  watch: {
    'reportData.reportColumns'(val) {
      return val.length > 0 ? this.$emit('filled') : this.$emit('unfilled');
    },
  },

  methods: {
    //Grid functions
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.reportData.reportColumns.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.reportData.reportColumns.indexOf(e.itemData);
      const newReportColumns = [...this.reportData.reportColumns];

      newReportColumns.splice(fromIndex, 1);
      newReportColumns.splice(toIndex, 0, e.itemData);

      this.reportData.reportColumns = newReportColumns;
      this.reassignSequence();
    },

    reassignSequence() {
      for (let i = 0; i < this.reportData.reportColumns.length; i++ ) {
        this.reportData.reportColumns[i].SortNumber = i + 1;
      }
    },

    // Button functions
    initUpdate({data}) {
      this.columnData.name = data.ColumnName;
      this.columnData.ColumnStringName = data.ColumnStringName;
      this.columnData.isUpdating = true;
      this.columnData.type = data.ColumnType;
    },

    saveOrUpdate() {
      this.columnData.isUpdating ? this.updateColumn() : this.addColumn();
    },

    addColumn() {
      if (this.$refs.columnDetailForm.validate()) {

        let selectedColumn = this.dbSourceColumnList.find( k => k["columnName"] === this.columnData.name);
        let sequence = this.reportData.reportColumns.length + 1;

        let columnData = {
          SortNumber: sequence,
          ColumnName: selectedColumn["columnName"],
          ColumnStringName: this.columnData.ColumnStringName,
          ColumnType: this.reportData.reportDataSourceType === 'N4'? this.columnData.type : selectedColumn["columnType"]
        }

        this.reportData.reportColumns.push(columnData);
        this.resetColumnDetailsForm();
      }
    },

    updateColumn() {
      if (this.$refs.columnDetailForm.validate()) {
        let selectedColumnIndex = this.reportData.reportColumns.findIndex( k => k["ColumnName"] === this.columnData.name);


        this.reportData.reportColumns[selectedColumnIndex].ColumnStringName = this.columnData.ColumnStringName;
        this.reportData.reportColumns[selectedColumnIndex].ColumnType = this.columnData.type;

        this.resetColumnDetailsForm();
      }
    },

    async deleteColumn(data) {
      if (await this.$root["$confirm"].open('Delete confirmation', 'Are you sure you want to remove this column?' , { color: 'info' })) {
        this.reportData.reportColumns.splice(data.rowIndex, 1);
        this.reassignSequence();
      }
    },

    // Misc functions
    resetColumnDetailsForm() {
      this.columnData.name = null;
      this.columnData.ColumnStringName = null;
      this.columnData.isUpdating = false;
      this.columnData.type = null;
      this.$refs.columnDetailForm.resetValidation();
      this.$refs.columnName.focus();
    },
  },
}
</script>

<style scoped>

</style>