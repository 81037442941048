import {AppointmentFormMode, DoorDirection, GateConfig, GroupDayStackType} from "@/utils/constants";

class AppointmentDetails {
    constructor() {
        this.appointmentId = null;
        this.appointmentDate = null;
        this.zone = null;
        this.appointmentType = null;
        this.transactionType = null;
        this.status = null;

        // Company
        this.companyId = null;

        // Truck Info
        this.truckRegistration = null;
        this.position = null;
        this.doorDirection = DoorDirection.Unknown.code;

        // Container Info
        this.containerNumber = null;
        this.containerTransitState = null;
        this.containerEstimatedMoveTime = null;
        this.containerVGMWeight = null;
        this.containerISOType = null;
        this.containerInBoundMode = null;
        this.emptyReleaseNumber = null;
        this.isEmpty = false;
    }
}

class GateDetails {
    constructor() {
        this.gateId = null;
        this.gateName = null;
        this.isActive = null;
        this.isForDropOff = null;
        this.isForPickup = null;
        this.isFromPropel = null;
        this.n4GateId = null;
        this.setGateConfig = null;
    }
}

export default {
    AppointmentDetails : function () {
        this.mode = AppointmentFormMode.General;
        this.appointmentDetails = new AppointmentDetails();
        this.gateDetails = new GateDetails();
        this.dayStackType = GroupDayStackType.EntireDay;

        this.convertToSubmittableAppointment = function () {
            // flatten it all out
            return {
                appointmentId : this.appointmentDetails.appointmentId,
                appointmentDate : this.appointmentDetails.appointmentDate,
                appointmentType : this.appointmentDetails.appointmentType,
                containerNumber : this.appointmentDetails.containerNumber,
                doorDirection : this.appointmentDetails.doorDirection,
                emptyReleaseNumber : this.appointmentDetails.emptyReleaseNumber,
                gate : this.gateDetails.n4GateId,
                isEmpty : this.appointmentDetails.isEmpty,
                isFromPropel : this.gateDetails.isFromPropel,
                position : this.appointmentDetails.position,
                transactionType : this.appointmentDetails.transactionType,
                truckRegistration : this.appointmentDetails.truckRegistration,
                truckingCompanyId : this.appointmentDetails.companyId,
                zone : this.appointmentDetails.zone
            }
        }

        this.setFromFlattenedAppointmentData = function(flattenedData) {

            this.appointmentDetails.appointmentId = flattenedData.appointmentId;
            this.appointmentDetails.appointmentDate = flattenedData.appointmentDate;
            this.appointmentDetails.zone = flattenedData.zone;
            this.appointmentDetails.appointmentType = flattenedData.appointmentType;
            this.appointmentDetails.companyId = flattenedData.truckingCompanyId;
            this.appointmentDetails.transactionType = flattenedData.transactionType;

            // Truck
            this.appointmentDetails.truckRegistration = flattenedData.truckRegistration;
            this.appointmentDetails.position = flattenedData.position;
            this.appointmentDetails.doorDirection = Object.keys(DoorDirection)[flattenedData.doorDirection];

            // Container
            this.appointmentDetails.containerNumber = flattenedData.containerNumber;
            this.appointmentDetails.containerTransitState = flattenedData.containerTransitState;
            this.appointmentDetails.containerEstimatedMoveTime = flattenedData.containerEstimatedMoveTime;
            this.appointmentDetails.containerVGMWeight = flattenedData.containerVGMWeight;
            this.appointmentDetails.containerISOType = flattenedData.containerISOType;
            this.appointmentDetails.containerInBoundMode = flattenedData.containerInBoundMode;
            this.appointmentDetails.emptyReleaseNumber = flattenedData.emptyReleaseNumber;


            // Interim
            this.appointmentDetails.isEmpty = flattenedData.emptyReleaseNumber !== null && flattenedData.emptyReleaseNumber !== undefined;
            this.appointmentDetails.status = flattenedData.status;
        }

        this.setFromFlattenedGateData = function(flattenedData) {
            this.gateDetails.gateId = flattenedData.gateId;
            this.gateDetails.gateName = flattenedData.gateName;
            this.gateDetails.isActive = flattenedData.isActive;
            this.gateDetails.isForDropOff = flattenedData.isForDropOff;
            this.gateDetails.isForPickup = flattenedData.isForPickup;
            this.gateDetails.isFromPropel = flattenedData.isFromPropel;
            this.gateDetails.n4GateId = flattenedData.n4GateId;
            this.gateDetails.setGateConfig = flattenedData.setGateConfig;

            if (flattenedData.isFromPropel)
            {
                if ( (flattenedData.isForDropOff && !flattenedData.isForPickup) || (!flattenedData.isForDropOff && flattenedData.isForPickup))
                {
                    if (GateConfig[flattenedData.setGateConfig] === 'Empty Containers')
                    {
                        this.appointmentDetails.isEmpty = true;
                    }
                }
            }
        }
    }
}