<template>
    <div>
        <v-row dense>
            <v-col cols="12">
                <label class="v-label theme--light formMinimisedLabel">Container ID</label>
                <p class="text--primary pl-3">{{ containerData.containerNumber }}</p>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <label class="v-label theme--light formMinimisedLabel">Type</label>
                <p class="text--primary pl-3">{{ containerData.equipmentTypeIsoId }}</p>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <label class="v-label theme--light formMinimisedLabel">Transport Operator</label>
                <p class="text--primary pl-3">{{ containerData.transporterId }}</p>
            </v-col>
        </v-row>
        <v-row v-if="showBookingData" dense>
            <v-col cols="12">
                <label class="v-label theme--light formMinimisedLabel">Booking Number</label>
                <p class="text--primary pl-3">{{ bookingData.bookingNumber }}</p>
            </v-col>
        </v-row>
        <v-row v-if="showBookingData" dense>
            <v-col cols="12">
                <label class="v-label theme--light formMinimisedLabel">2<sup>nd</sup> POD</label>
                <p class="text--primary pl-3">{{ secondPortOfDischargeOrNone }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ExportPreAdviceSummary.vue",

    props: {
        showBookingData: {
            type: Boolean, 
            default: () => true
        }
    },

    computed: {
        ...mapGetters('exportPreAdvice', [
            'workingContainerDetails',
            'workingBookingDetails'
        ]),
        containerData() {
            return this.workingContainerDetails;
        },
        bookingData() {
            return this.workingBookingDetails;
        },
        secondPortOfDischargeOrNone() {
            return this.bookingData.secondPortOfDischarge || '-';
        }
    }
}
</script>
