<template>
  <div>
    <v-card elevation="1">
      <v-container>
        <v-row>
          <v-col :cols="gridColumnSize">
            <v-row>
              <v-col cols="3">
                <v-toolbar class="datatableToolbar" flat>
                  <v-toolbar-title>Customers</v-toolbar-title>
                </v-toolbar>
                <DxDataGrid
                    id="customerGrid"
                    :ref="customerDataGridRefName"
                    :data-source="logisticCustomers"
                    :show-borders="false"
                    :hoverStateEnabled="true"
                    noDataText="No current customers"
                    :column-auto-width="true"
                    :column-hiding-enabled="true"
                    key-expr="companyId"
                    @selection-changed="onCustomerSelect"
                >
                  <DxPaging :page-size="10"/>
                  <DxPager
                      :show-page-size-selector="true"
                      :allowed-page-sizes="pageSizes"
                      :show-info="true"
                  />

                  <DxColumn data-field="name" data-type="string" caption="Company"/>

                  <DxSelection mode="single" />
                </DxDataGrid>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="8">
                <v-row>
                  <v-col>
                    <v-toolbar class="datatableToolbar" flat>
                      <v-toolbar-title>Associated Terminal Site & Sidings</v-toolbar-title>
                    </v-toolbar>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="text-right" cols="4">
                    <v-btn
                        v-if="!isFormActive"
                        tile class="mb-2"
                        color="secondary"
                        right
                        :loading="savingIndicator"
                        @click="activateForm"
                        :disabled="selectedCompanyId === null"
                    >
                      <v-icon>mdi-plus</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp">Add</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <DxDataGrid
                    id="customerSiteGrid"
                    :ref="customerSitesDataGridRefName"
                    :data-source="associatedSites"
                    :show-borders="false"
                    :hoverStateEnabled="true"
                    noDataText="No current sites & sidings"
                    :column-auto-width="true"
                    :column-hiding-enabled="true"
                >
                  <DxColumn data-field="bookingType" data-type="string" caption="Booking Type"/>
                  <DxColumn data-field="siteType" data-type="string" caption="Site Type"/>
                  <DxColumn data-field="siteType" cell-template="siteCellTemplate" data-type="string" caption="Terminal Site"/>
                  <template #siteCellTemplate="{ data }">
                    {{ findSite(data) }}
                  </template>

                  <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Actions" />
                  <template #actionCellTemplate="{ data }">
                    <div>
                      <v-icon color="primary" size="18" class="mr-2" @click="editSite(data)">
                        mdi-pencil
                      </v-icon>
                      <v-icon color="primary" size="18" class="mr-2" @click="deleteSite(data)">
                        mdi-delete
                      </v-icon>
                    </div>
                  </template>
                </DxDataGrid>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="formColumnSize" v-if="isFormActive">
            <v-row>
              <v-spacer></v-spacer>
              <v-col class="text-right" cols="5">
                <v-btn
                    v-if="isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    @click="deactivateForm"
                >
                  <v-icon>mdi-arrow-collapse-right</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Collapse</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="3">
                  <v-card-text>
                    <ValidationSummary :validationErrors="validationErrors" />
                    <v-form ref="siteForm" lazy-validation>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                                v-model="editedItem.transportServiceTerminalSiteId"
                                ref="terminalSite"
                                :items="siteLists"
                                item-text="description"
                                item-value="transportServiceTerminalSiteId"
                                label="Site"
                                tabindex="1"
                                :rules="bookingTypeRules"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-select
                                v-model="editedItem.bookingType"
                                :items="['Sending','Receiving']"
                                label="Type"
                                tabindex="2"
                                :rules="bookingTypeRules"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                                v-model="editedItem.siteType"
                                label="Site Type"
                                :disabled="true"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="resetForm"
                    >
                      Reset
                    </v-btn>
                    <v-btn
                        color="secondary"
                        text
                        @click="saveForm"
                        :loading="savingIndicator"
                    >
                      {{ isUpdate? 'Update': 'Save' }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {
    DxDataGrid,
    DxPaging,
    DxPager,
    DxColumn,
    DxSelection
} from "devextreme-vue/data-grid";
import ValidationSummary from '@/components/global/ValidationSummary.vue'

export default {
  name: "TransportMappings-CustomerAndSites",

  components: {
    DxDataGrid,
    DxPaging,
    DxPager,
    DxColumn,
    DxSelection,
    ValidationSummary
  },

  data() {
    return {
      // Grid
      customerDataGridRefName: 'customerGrid',
      customerSitesDataGridRefName: 'customerSiteGrid',
      pageSizes: [10,15,25],

      // Form
      isFormActive: false,
      isUpdate: false,
      validationErrors: {},
      selectedCompanyId: null,
      editedItem: null,
      defaultItem: {
        transportServiceCustomerTerminalSiteAndSidingId: null,
        transportServiceTerminalSiteId: null,
        bookingType: null,
        siteType: null,
      },
      bookingTypeRules: [
        v => !!v || 'Booking Type is required'
      ],
      savingIndicator: false,
    }
  },

  computed: {
    ...mapState('transportMappings', [
        'customers',
        'customerSites',
        'terminalSitesAndSidings',
        'loadingCustomerSites'
    ]),

    logisticCustomers() {
      return this.customers;
    },

    siteLists() {
      return this.terminalSitesAndSidings.filter(g => g.isActive);
    },

    associatedSites() {
      return this.customerSites;
    },

    loadingAssociatedSites() {
      return this.loadingCustomerSites;
    },

    gridColumnSize() {
      return this.isFormActive ? 8 : 12;
    },

    formColumnSize() {
      return this.isFormActive ? 4 : 0;
    },
  },

  mounted() {
    this.editedItem = Object.assign({}, this.defaultItem);
    this.fetchCustomers();
    this.fetchContainerTerminalSitesAndSidings();
  },

  watch: {
    selectedCompanyId(val) {
      if (val !== null)
        this.fetchCustomerSites(val);
    },

    'editedItem.transportServiceTerminalSiteId'(val) {
      if (val !== null) {
        this.editedItem.siteType = this.siteLists.find(g => g.transportServiceTerminalSiteId === val).type;
      }
    },

    loadingAssociatedSites: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
      if(newVal)
        this.$refs[this.customerSitesDataGridRefName]["instance"].beginCustomLoading();
      else
        this.$refs[this.customerSitesDataGridRefName]["instance"].endCustomLoading();
    }
  },

  methods: {
    ...mapActions('transportMappings', [
        'fetchCustomers',
        'fetchCustomerSites',
        'fetchContainerTerminalSitesAndSidings'
    ]),

    // Buttons
    editSite(e) {
      CMSApi.fetchCustomerSiteInfo(e.data['transportServiceCustomerTerminalSiteAndSidingId'])
          .then(data => {
            this.editedItem = Object.assign({}, data);
            this.isUpdate = true;
          })
          .finally(() => {
            this.isFormActive = true;
          })
    },

    async deleteSite(e) {
      e.cancel = true;
      if (await this.$root.$confirm.open('Customer Site Removal', 'Are you sure you want to perform this action?', { color: 'warning'})) {
        App["$appAnalytics"].trackEvent('Transport Capacity/ Mappings - Delete Customer Site Click');

        CMSApi.deleteCustomerSiteAndSidings(e.data["transportServiceCustomerTerminalSiteAndSidingId"])
            .then(() => {
              this.fetchCustomerSites(this.selectedCompanyId);
            })
            .catch(error => {
              let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
              this.$root.$alert.show('Unable to Delete site', errorMessage)
            })
      }
    },

    onCustomerSelect({ selectedRowsData }) {
      const data = selectedRowsData[0];

      this.selectedCompanyId = data.companyId;
    },

    activateForm() {
      this.isFormActive = true;
    },

    deactivateForm() {
      this.isFormActive = false;
    },

    async resetForm() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.isUpdate = false;

      await this.$nextTick();

      this.$refs.siteForm.resetValidation();
      // this.validationErrors = {};
      this.$refs.terminalSite.focus();
    },

    saveForm() {
      if (this.$refs.siteForm.validate())
      {
        this.savingIndicator = true;

        if (this.editedItem.transportServiceCustomerTerminalSiteAndSidingId) {

          CMSApi.putCustomerSiteAndSidings(this.editedItem)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Customer site updated");
              })
              .catch(error => {
                let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                this.$root["$alert"].show('Unable to update customer site', errorMessage)
              })
              .finally(() => {
                this.savingIndicator = false;
                this.fetchCustomerSites(this.selectedCompanyId);
                this.resetForm();
              });

        } else {

          CMSApi.postCustomerSiteAndSidings(this.selectedCompanyId, this.editedItem)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Customer site created");
              })
              .catch(error => {
                let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                this.$root["$alert"].show('Unable to create site for this customer', errorMessage)
              })
              .finally(() => {
                this.savingIndicator = false;
                this.fetchCustomerSites(this.selectedCompanyId);
                this.resetForm();
              });

        }
      }
    },

    // Misc
    findSite({data}) {
      return this.siteLists.find(g => g.transportServiceTerminalSiteId === data.transportServiceTerminalSiteId).description;
    }
  }
}
</script>

<style scoped>

</style>