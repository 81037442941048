<template>
    <div>
        <div class="stepperStepTitle">{{ title }}</div>
        <v-row>
            <v-col cols="3" v-if="showSummary">
                <export-pre-advice-summary :showBookingData="true"></export-pre-advice-summary>
            </v-col>
            <v-divider vertical v-if="showSummary"></v-divider>
            <v-col cols="12" sm="9">
                <ValidationSummary :validationErrors="submitErrors"/>
                <div v-if="submittingAdvice" class="pt-10 pb-5">
                    <h2>Submitting Export Pre-Advice</h2>
                    <div :class="{'dot-flashing': submittingAdvice }"></div>
                </div>
                <h2 id="submittedEpaResultMessage" v-else class="pt-10 pb-5" :class="{'submittedEpaError': isInError}">{{ submitResultMessage }}</h2>
                <h2 id="submittedEpaResultWarningMessage" v-if="submitWarningMessage !== '' || submitWarningMessage !== null" class="pt-10 pb-5" >{{ submitWarningMessage }}</h2>
            </v-col>
        </v-row>
        <v-row dense v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="3">
                <v-btn
                    color="secondary"
                    plain
                    @click="close"
                    :disabled="submittingAdvice"
                    tabindex="3"
                >
                    {{ closeOrCancel }}
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4">
                <v-btn
                    v-if="!isInError"
                    color="secondary"
                    plain
                    @click="adviseAnother"
                    :disabled="submittingAdvice"
                    tabindex="1"
                >
                    Advise Another Unit
                </v-btn>
            </v-col>
            <v-col cols="3" class="text-right">
                <v-btn
                    color="secondary"
                    plain
                    @click="changeData"
                    v-if="isInError"
                    tabindex="1"
                >
                    Change Data
                </v-btn>
                <v-switch
                    v-model="adviseUnitInSameBooking"
                    v-if="!isInError"
                    inset
                    label="Same booking"
                    :disabled="submittingAdvice"
                    tabindex="2"
                    class="mt-0"
                >
                </v-switch>
            </v-col>
        </v-row>
        <v-row dense v-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="4">
                <v-btn
                    color="secondary"
                    plain
                    @click="close"
                    :disabled="submittingAdvice"
                    tabindex="3"
                >
                    {{ closeOrCancel }}
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" class="text-right">
                <v-btn
                    v-if="!isInError"
                    color="secondary"
                    plain
                    @click="adviseAnother"
                    :disabled="submittingAdvice"
                    tabindex="1"
                >
                    Advise Another Unit
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense v-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="12">
                <div v-if="isInError" class="text-right">
                    <v-btn
                        color="secondary"
                        plain
                        @click="changeData"
                        v-if="isInError"
                        tabindex="1"
                    >
                        Change Data
                    </v-btn>
                </div>
                <div v-if="!isInError" id="switch-div">
                    <v-switch
                        v-model="adviseUnitInSameBooking"
                        v-if="!isInError"
                        inset
                        label="Same booking"
                        :disabled="submittingAdvice"
                        tabindex="2"
                        class="switch-right mt-0"
                    >
                    </v-switch>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ExportPreAdviceSummary from "@/components/exportPreAdvice/ExportPreAdviceSummary.vue";
import {mapGetters} from "vuex";
import ValidationSummary from '@/components/global/ValidationSummary.vue'

export default {
    name: "ExportPreAdviceConfirmation.vue",

    props: {
        title: {
            type: String,
            required: true
        },
        submitting: {
            type: Boolean,
            required: true
        }
    },

    components: {
        ExportPreAdviceSummary,
        ValidationSummary
    },

    data() {
        return {
            submittingAdvice: this.submitting,
            submitResultMessage: '',
            submitWarningMessage: '',
            isInError: false,
            adviseUnitInSameBooking: false,
            submitErrors: {},
            closeOrCancel: "Close"
        }
    },

    computed: {
        ...mapGetters('exportPreAdvice', [
            'workingAdvice'
        ]),
        completeWorkingAdvice() {
            return this.workingAdvice;
        },
        showSummary() {
            return !this.$vuetify.breakpoint.xs;
        }
    },

    watch: {
        submitting(newVal) {
            this.submittingAdvice = newVal;
            if (newVal) {
                this.isInError = false;
                this.submitResultMessage = '';
                this.submitWarningMessage = '';
                this.submitErrors = {};
                this.closeOrCancel = "Close"

                this.submitAdvice();
            }
        }
    },

    methods: {
        submitAdvice() {
            const completedAdviceForSubmit = this.completeWorkingAdvice.convertToSubmittableAdvice();

            if (!completedAdviceForSubmit.ExportPreAdviceId || completedAdviceForSubmit.ExportPreAdviceId === 0) {
                // Submitting a new advice
                CMSApi.postExportPreAdvice(completedAdviceForSubmit)
                    .then(data => {
                        // need to check if returned result successful too
                        let result = data;
                        if (result && result.success) {
                            this.submitResultMessage = "Export Pre-Advice Submitted"
                          // If result.warningMessage is not empty, show it as a warning
                          if (result["warningMessage"] !== null || result["warningMessage"] !== "") {
                            this.submitWarningMessage = result["warningMessage"];
                          }
                        } else {
                            let error = {
                                "": [
                                    result ? result.failureReason : "An error occurred while trying to submit the Export Pre-Advice"
                                ]
                            };
                            this.showError('Unable to submit Export Pre-Advice', error);
                        }
                    })
                    .catch(error => {
                        this.showError('Unable to submit Export Pre-Advice', error.response.data.errors);
                    })
                    .finally(() => {
                        this.submittingAdvice = false;
                    });
            } else {
                // Updating an existing advice
                CMSApi.putExportPreAdvice(completedAdviceForSubmit)
                    .then(data => {
                        // need to check if returned result successful too
                        let result = data;
                        if (result && result.success) {
                            this.submitResultMessage = "Export Pre-Advice Updated"
                        } else {
                            let error = {
                                "": [
                                    result ? result.failureReason : "An error occurred while trying to submit the Export Pre-Advice"
                                ]
                            };
                            this.showError('Unable to update Export Pre-Advice', error);
                        }
                    })
                    .catch(error => {
                        this.showError('Unable to update Export Pre-Advice', error.response.data.errors);
                    })
                    .finally(() => {
                        this.submittingAdvice = false;
                    });
            }
        },

        showError(title, errors) {
            this.submitResultMessage = title;
            this.submitErrors = errors;
            this.isInError = true;
            this.closeOrCancel = "Cancel"
            this.$emit("submitError");
        },

        changeData() {
            this.isInError = false;
            this.submitResultMessage = '';
            this.submitWarningMessage = '';
            this.submitErrors = {};
            this.submittingAdvice = false;
            this.closeOrCancel = "Close"
            this.$emit("changeData");
        },

        adviseAnother() {
            this.$emit("adviseAnother", this.adviseUnitInSameBooking);
        },

        close() {
            this.$emit("close");
        },
    }
}
</script>

<style scoped>
#submittedEpaResultMessage {
    color: var(--v-propelLightGreen-base);
    font-weight: bold;
}

#submittedEpaResultMessage.submittedEpaError {
    color: var(--v-error-base);
    font-weight: bold;
}

#switch-div {
    position: relative;
    height: 35px;
}

.switch-right {
    position: absolute;
    right: 10px;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
    margin-left: 17px;
    margin-top: 10px;
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    /*background-color: #9880ff;*/
    /*color: #9880ff;*/
    background-color: #333333;
    color: #333333;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #333333;
    color: #333333;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #333333;
    color: #333333;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: #333333;
    }
    50%,
    100% {
        /*background-color: #ebe6ff;*/
        background-color: #dddddd;
    }
}
</style>