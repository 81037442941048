<template>
    <div>
        <NotificationAlerts />

        <UserList />
    </div>
</template>

<script>
import UserList from '@/components/userManagement/UserList.vue'
import NotificationAlerts from '@/components/global/NotificationAlerts.vue'

export default {   
    name: 'admin-userManagement',
    components: {
        UserList,
        NotificationAlerts
    },
};
</script>