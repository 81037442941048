import reportManagementData from "@/models/report/reportManagementData";
import utils from '@/utils/vuexUtils.js'
import CMSApi from "@/services/CMSApi";
import {UserRoles} from "@/utils/constants";

// initial state
const state = {
    reports: [],
    sourceList: [],
    sourceColumnList: [],
    workingReport: new reportManagementData.Details(),

    // Loading indicators
    loadingReportList: false,
    loadingSourceList: false,
    loadingSourceColumnList: false,
    loadingReportUsers: false,

    isUpdate: false,
}

// getters
const getters = {
    workingReport: state => {
        return state.workingReport;
    },
}

// actions
const actions = {
    fetchReportList: ( {commit, state}) => {
        state.loadingReportList = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchReportList(), 'setReportList');
    },

    fetchReportSourceList: ( {commit, state},  {context, type}) => {
        state.loadingSourceList = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchReportSourceList(context,type), 'setSourceList');
    },

    fetchReportSourceColumnList: ( {commit, state}, {context, type, source}) => {
      state.loadingSourceColumnList = true;
      return utils.CommitArrayPromise(commit, CMSApi.fetchReportSourceColumnList(context, type, source), 'setSourceColumnList');
    },

    fetchRoleUsers: ( {commit, state}, role) => {
        state.loadingReportUsers = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchRoleUsers(role), 'setReportUsers');
    },

    async setWorkingReportData({commit, state}, flattenedReportData) {
        state.isUpdate = true;

        let userRoles = [];
        await CMSApi.fetchRoleUsers(UserRoles.PropelReportViewer)
            .then( data => {
                userRoles = data;
            })

        let workingReport = new reportManagementData.Details();
        workingReport.setFromFlattenedData(flattenedReportData, userRoles);

        commit('setCurrentWorkingReport', workingReport);
    },

    resetWorkingReport: ({ commit, state }) => {
        state.isUpdate = false;
        let newReport = new reportManagementData.Details();
        commit('setCurrentWorkingReport', newReport);
    }
}

// mutations
const mutations = {
    setReportList(state, reports) {
        state.reports = reports;
        state.loadingReportList = false;
    },

    setSourceList(state, sourceList) {
        state.sourceList = sourceList;
        state.loadingSourceList = false;
    },

    setSourceColumnList(state, sourceColumnList) {
        state.sourceColumnList = sourceColumnList;
        state.loadingSourceColumnList = false;
    },

    setReportUsers(state, reportUsers) {
        state.workingReport.reportDetails.reportUsers = reportUsers;
        state.loadingReportUsers = false;
    },

    resetSourceList(state) {
        state.sourceList = [];
    },

    resetSelectedSource(state) {
        state.workingReport.reportDetails.reportDataSource = null;
    },

    resetSelectedColumns(state) {
        state.workingReport.reportDetails.reportColumns = [];
    },

    setCurrentWorkingReport(state, report) {
        state.workingReport = report;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}