<template>
  <div>
    <v-card elevation="1">
      <v-toolbar class="datatableToolbar" flat>
        <v-toolbar-title>Transport Capacity -> Services</v-toolbar-title>
        <div class="flex-grow-1"></div>

        <v-btn
            v-if="!expand"
            tile class="mb-2"
            color="secondary"
            :loading="loadingServiceList"
            @click="fetchTrainServices()"
        >
          <v-icon>mdi-refresh</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
        </v-btn>

        <v-btn
            tile class="mb-2"
            color="secondary"
            @click="toggleButton"
        >
          <v-icon>{{ buttonIcon }}</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">{{ buttonName }}</span>
        </v-btn>

      </v-toolbar>
      <div v-if="!expand">
        <v-container class="datatableControls" id="transportListTableControls" >
          <v-row>
            <v-col cols="12" sm="6" md="5" lg="4" xl="4">
              <v-text-field clearable v-model="searchText" label="Search by Service Name or Service Date" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <v-checkbox v-model="filterIncludeExpired"  label="Include Past & Cancelled" hide-details></v-checkbox>
            </v-col>
          </v-row>
        </v-container>

        <DxDataGrid
            id="trainServiceGrid"
            :ref="dataGridRefName"
            :data-source="serviceList"
            :show-borders="false"
            :hoverStateEnabled="true"
            noDataText="No train services"
            :column-auto-width="true"
            :column-hiding-enabled="true"
        >
          <DxHeaderFilter :visible="true" />
          <DxSorting mode="multiple"/>

          <DxPaging :page-size="10"/>

          <DxPager
              :show-page-size-selector="true"
              :allowed-page-sizes="pageSizes"
              :show-info="true"
          />

          <DxColumn
              data-field="trainServiceName"
              data-type="string"
              caption="Service Name"
          />

          <DxColumn
              data-field="trainServiceDirection"
              data-type="string"
              caption="Direction"
          />

          <DxColumn
              data-field="startDate"
              data-type="date"
              format="dd/MM/yyyy"
              :hiding-priority="5"
              caption="Start Date"
              alignment="center"
              :allow-search="true"
          />

          <DxColumn
              data-field="endDate"
              data-type="date"
              format="dd/MM/yyyy"
              :hiding-priority="6"
              caption="End Date"
              alignment="center"
              :allow-search="true"
          />

          <DxColumn
              data-field="wagonCount"
              data-type="number"
              :hiding-priority="7"
              caption="Routes"
              alignment="center"
          />

          <DxColumn
              data-field="teu"
              data-type="number"
              :hiding-priority="7"
              caption="TEU"
              alignment="center"
          />

          <DxColumn
              caption="Selected Days"
              :hiding-priority="0"
              alignment="center"
          >
            <DxColumn
                data-field="monday"
                caption="M"
                width="50"
                cell-template="checkDay"
                :allow-header-filtering="false"
            />
            <DxColumn
                data-field="tuesday"
                caption="T"
                width="50"
                cell-template="checkDay"
                :allow-header-filtering="false"
            />
            <DxColumn
                data-field="wednesday"
                caption="W"
                width="50"
                cell-template="checkDay"
                :allow-header-filtering="false"
            />
            <DxColumn
                data-field="thursday"
                caption="Th"
                width="50"
                cell-template="checkDay"
                :allow-header-filtering="false"
            />
            <DxColumn
                data-field="friday"
                caption="F"
                width="50"
                cell-template="checkDay"
                :allow-header-filtering="false"
            />
            <DxColumn
                data-field="saturday"
                caption="S"
                width="50"
                cell-template="checkDay"
                :allow-header-filtering="false"
            />
            <DxColumn
                data-field="sunday"
                caption="Sn"
                width="50"
                cell-template="checkDay"
                :allow-header-filtering="false"
            />
          </DxColumn>
          <template #checkDay="{ data }">
            <div>
              <v-icon v-if="data.value === true"
                      color="green">
                mdi-check
              </v-icon>
              <v-icon v-else
                      color="red">
                mdi-close
              </v-icon>
            </div>
          </template>

          <DxColumn
              data-field="isActive"
              cell-template="checkDay"
              data-type="string"
              caption="Status"
              alignment="center"
          />

          <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Action" />
          <template #actionCellTemplate="{ data }">
            <div>
              <v-icon
                  v-if="userHasRole(userRoles.PortAdmin, userRoles.TransportAdmin, userRoles.TransportCoordinator)"
                  color="primary"
                  size="18"
                  class="mr-2"
                  @click="editTrainService(data)"
                  v-visible="isTrainServiceEditable(data)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  v-if="userHasRole(userRoles.PortAdmin, userRoles.TransportAdmin, userRoles.TransportCoordinator)"
                  color="primary"
                  size="18"
                  class="mr-2"
                  @click="deleteTrainService(data)"
                  v-visible="isTrainServiceDeletable(data)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>

        </DxDataGrid>
      </div>

      <transport-service-form v-else
        @returnToList="returnToList"
      >
      </transport-service-form>
    </v-card>
  </div>
</template>

<script>
import TransportServiceForm from "@/components/transportCapacity/service/TrainServiceForm.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import { DxDataGrid, DxHeaderFilter, DxSorting, DxPaging, DxPager, DxColumn } from "devextreme-vue/data-grid";
import UserRoles from "@/auth/authUserRoles";
import { getInstance } from "@/auth/authWrapper";
export default {
  name: "TransportService",

  components: {
    TransportServiceForm,
    DxDataGrid,
    DxHeaderFilter,
    DxSorting,
    DxPaging,
    DxPager,
    DxColumn,
  },

  data() {
    return {
      // Grid Config
      searchText: '',
      pageSizes: [10, 25, 50, 100],
      dataGridRefName: 'trainServiceGrid',
      filterIncludeExpired: false,

      // Form
      expand: false,
    }
  },

  mounted() {
    this.fetchTrainServices();
  },

  computed: {
    ...mapState('trainService', [
        'trainServices',
        'loadingTrainService',
        'workingTrainService'
    ]),

    serviceList() {
      return this.filterIncludeExpired? this.trainServices : this.trainServices.filter(g => g.isActive && window.Moment(g.endDate).format('YYYY-MM-DD') > window.Moment().format('YYYY-MM-DD'));
    },

    loadingServiceList() {
      return this.loadingTrainService;
    },

    buttonName() {
      return this.expand ? 'Return to List' : 'Add Service';
    },

    buttonIcon() {
      return this.expand ? 'mdi-view-list': 'mdi-plus';
    },

    userRoles() {
      return UserRoles;
    },
  },

  watch: {
    searchText: function (newVal) {
      this.$refs[this.dataGridRefName]["instance"].searchByText(newVal?.trim());
    },

    expand: function (newVal) {
      if (!newVal)
        this.resetWorkingTrainService();
    },
  },

  methods: {
    ...mapActions('trainService', [
        'fetchTrainServices',
        'resetWorkingTrainService',
        'fetchContainerTerminalSitesAndSidings',
        'setWorkingTrainServiceForEdit'
    ]),

    ...mapMutations('trainService', [
        'setAllFormAsValidForEdit'
    ]),

    toggleButton() {
      this.expand = !this.expand;
    },

    returnToList() {
      this.expand = false;
      this.fetchTrainServices();
    },

    async editTrainService(e) {
      App["$appAnalytics"].trackEvent('Train Service - Edit - Click');
      await this.fetchContainerTerminalSitesAndSidings();

      // Fetch Train Service Data
      CMSApi.fetchTrainServiceInfo(e.data.trainServiceId)
          .then( result => {
            this.expand = true;
            this.setWorkingTrainServiceForEdit(result);
            this.setAllFormAsValidForEdit();
          })
    },

    async deleteTrainService(e) {
        if (await this.$root["$confirm"].open('Service Removal', 'Are you sure you want to delete this service?' , { color: 'warning' })) {
            CMSApi.deleteTrainService(e.data.trainServiceId)
                .then( () => {
                    App["$appAnalytics"].trackEvent('Train Service - Delete success');
                    window.App.$emit('show-snackbar', "success", "Service deleted");
                })
                .finally(() => {
                    this.fetchTrainServices();
                })
        }
    },

    // Misc Functions
    isTrainServiceEditable(e) {
      return this.isTrainServiceActive(e.data) && window.Moment(e.data.endDate).format('YYYY-MM-DD') > window.Moment().format('YYYY-MM-DD');
    },

    isTrainServiceDeletable(e) {
      return this.isTrainServiceActive(e.data) && window.Moment(e.data.startDate).format('YYYY-MM-DD') > window.Moment().format('YYYY-MM-DD');
    },

    isTrainServiceActive(service) {
      return service.isActive;
    },

    userHasRole(roles) {
      let authService = getInstance();
      return authService.userHasRole(roles);
    }
  },
}
</script>

<style scoped>

</style>