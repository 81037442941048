<template>
  <div>
    <v-card elevation="1">
      <v-container>
        <v-row>
          <v-col :cols="gridColumnSize">
            <v-row>
              <v-col>
                <v-toolbar class="datatableToolbar" flat>
                  <v-toolbar-title>Road Bridges</v-toolbar-title>
                </v-toolbar>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-right" cols="2">
                <v-btn
                    v-if="!isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    right
                    @click="fetchRoadBridges"
                    :loading="loadingRoadBridges"
                >
                  <v-icon>mdi-reload</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Refresh</span>
                </v-btn>
              </v-col>
              <v-col class="text-right" cols="1">
                <v-btn
                    v-if="!isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    right
                    @click="activateForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Add</span>
                </v-btn>
              </v-col>
            </v-row>

            <DxDataGrid
                id="roadBridgesGrid"
                :ref="dataGridRefName"
                :data-source="serviceRoadBridges"
                :show-borders="false"
                :hoverStateEnabled="true"
                noDataText="No current sites & sidings"
            >
              <DxPaging :page-size="10"/>
              <DxPager
                  :show-page-size-selector="true"
                  :allowed-page-sizes="pageSizes"
                  :show-info="true"
              />
              <DxHeaderFilter
                  :visible="true"
              />

              <DxColumn data-field="roadBridgeCode" data-type="string" caption="Road Bridge Code" width="30%"/>
              <DxColumn data-field="transportOperatorCode" data-type="string" caption="Operator" width="20%"/>
              <DxColumn data-field="costRate" data-type="string" caption="Buy Rate" width="10%"/>
              <DxColumn data-field="nextCostRate" data-type="string" caption="Next Buy Rate" width="10%"/>
              <DxColumn data-field="nextCostRateDate" data-type="string" caption="Next Change Date" width="10%"/>
              <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Actions" width="20%" />
              <template #actionCellTemplate="{ data }">
                <div>
                  <v-icon color="primary" size="18" class="mr-2" @click="editRoadBridge(data)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="primary" size="18" class="mr-2" @click="deleteRoadBridge(data)">
                    mdi-delete
                  </v-icon>
                </div>
              </template>

            </DxDataGrid>

          </v-col>
          <v-col :cols="formColumnSize" v-if="isFormActive">
            <v-row>
              <v-spacer></v-spacer>
              <v-col class="text-right" cols="5">
                <v-btn
                    v-if="isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    @click="deactivateForm"
                >
                  <v-icon>mdi-arrow-collapse-right</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Collapse</span>
                </v-btn>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="3">
                  <v-card-text>
                    <ValidationSummary :validationErrors="validationErrors" />
                    <v-form ref="roadBridgeForm" lazy-validation>
                      <v-container>
                        <v-row dense>
                          <v-col cols="6">
                            <v-text-field
                                v-model="computedRoadBridgeCode"
                                ref="roadBridgeCodes"
                                label="Road Bridge Code*"
                                tabindex="1"
                                maxlength="10"
                                disabled
                                dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                                v-model="selectedCTSite"
                                :items="serviceTerminalSites"
                                label="CT Site"
                                :item-text="'workstationName'"
                                :item-value="'workstationCode'"
                                dense
                                :rules="CTSiteRules"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="6">
                            <v-select
                                v-model="selectedRoadBridgeLocation"
                                :items="computedCTLocations"
                                label="Road Bridge Location"
                                :item-text="'locationCode'"
                                :item-value="'justCode'"
                                :disabled="selectedCTSite == null"
                                dense
                                :rules="CTSiteLocationRules"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                                v-model="editedItem.transportOperatorCode"
                                :items="truckingCompanies"
                                item-text="idAndName"
                                item-value="transportCompanyId"
                                label="Operator"
                                clearable
                                dense
                                :rules="operatorRules"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                                v-model.number="editedItem.costRate"
                                type="number"
                                label="Buy Rate"
                                dense
                                :rules="costRateRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                                v-model="editedItem.nextCostRate"
                                type="number"
                                label="Next Buy Rate"
                                dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-menu
                                v-model="dateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="computedNextChangeDateFormatted"
                                    label="Next Change Rate Date"
                                    readonly
                                    prepend-icon="mdi-calendar"
                                    hide-details="auto"
                                    v-on="on"
                                    @click:prepend="dateMenu = true"
                                    autocomplete="off"
                                    dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="editedItem.nextCostRateDate"
                                  :min="showNextChangeDateNow"
                                  @input="dateMenu = false"
                                  style="width:100%"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="resetForm"
                    >
                      Reset
                    </v-btn>
                    <v-btn
                        color="secondary"
                        text
                        @click="saveForm"
                    >
                      {{ isUpdate? 'Update': 'Save' }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {
  DxColumn,
  DxDataGrid, DxHeaderFilter,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";
import ValidationSummary from "@/components/global/ValidationSummary.vue";
import generalHelpers from "@/utils/generalHelpers";

export default {
  name: "TransportMappings-RoadBridges",

  mixins: [generalHelpers],

  components: {
    DxHeaderFilter,
    DxDataGrid,
    DxPager,
    DxPaging,
    DxColumn,
    ValidationSummary
  },

  data() {
    return {
      // Grid
      dataGridRefName: 'roadBridgesGrid',
      pageSizes: [10,15,25],

      // Form
      isFormActive: false,
      isUpdate: false,
      validationErrors: {},
      selectedCTSite: null,
      selectedRoadBridgeLocation: null,
      editedItem: null,
      defaultItem: {
        transportServiceRoadBridgeId: null,
        roadBridgeCode: null,
        transportOperatorCode: null,
        costRate: null,
        nextCostRate: null,
        nextCostRateDate: null,
      },

      CTSiteRules : [
        v => !!v || 'CT is required',
      ],
      CTSiteLocationRules : [
        v => !!v || 'Road Bridge Location is required',
      ],
      operatorRules : [
        v => !!v || 'Operator is required',
      ],
      costRateRules : [
        v => !!v || 'Cost Rate is required',
      ],

      // Switch
      fromType: false,
      toType: false,
      dateMenu : false,
    }
  },

  computed: {
    ...mapState('transportMappings', [
        'roadBridges',
        'customers',
        'terminalSitesAndSidings',
        'loadingRoadBridges',
        'loadingCustomers',
        'loadingTerminalSitesAndSidings',
        'truckingCompanies'
    ]),

    serviceRoadBridges() {
      return this.roadBridges;
    },

    serviceCustomers() {
      return this.customers
    },

    serviceTerminalSites() {
      return this.terminalSitesAndSidings.filter(g => g.workstationCode !== "NPR" );
    },

    loadingServiceCustomers() {
      return this.loadingCustomers;
    },

    loadingServiceTerminalSites() {
      return this.loadingTerminalSitesAndSidings
    },

    gridColumnSize() {
      return this.isFormActive ? 6 : 12;
    },

    formColumnSize() {
      return this.isFormActive ? 6 : 0;
    },

    computedRoadBridgeCode() {
      return this.selectedCTSite != null ? this.selectedCTSite + "->" + this.selectedRoadBridgeLocation : '';
    },

    computedCTLocations() {
      return this.selectedCTSite != null ? this.serviceTerminalSites.find(g => g.workstationCode === this.selectedCTSite).roadBridgeLocations  : [];
    },

    showNextChangeDateNow() {
      return window.Moment().startOf('day').add(1,'days').format('YYYY-MM-DD');
    },

    computedNextChangeDateFormatted() {
      return this.editedItem.nextCostRateDate !== null ? this.formatDate(this.editedItem.nextCostRateDate) : ''
    },
  },

  mounted() {
    this.editedItem = Object.assign({}, this.defaultItem);
    this.fetchRoadBridges();
    this.fetchCustomers();
    this.fetchTruckingCompanies();
    this.fetchContainerTerminalSitesAndSidings();
  },

  methods : {
    ...mapActions('transportMappings', [
        'fetchRoadBridges',
        'fetchCustomers',
        'fetchContainerTerminalSitesAndSidings',
        'fetchTruckingCompanies',
    ]),

    activateForm() {
      this.isFormActive = true;
      this.resetForm();
    },

    deactivateForm() {
      this.isFormActive = false;
    },

    async resetForm() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.selectedCTSite = null;
      this.selectedRoadBridgeLocation = null;
      this.isUpdate = false;

      await this.$nextTick();

      this.$refs.roadBridgeForm.resetValidation();
      this.validationErrors = {};
      this.$refs.roadBridgeCodes.focus();
    },

    saveForm() {
      if (this.$refs.roadBridgeForm.validate())
      {
        this.savingIndicator = true;
        this.editedItem.roadBridgeCode = this.computedRoadBridgeCode;

        if (this.editedItem.transportServiceRoadBridgeId) {

          CMSApi.putRoadBridge(this.editedItem)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Road Bridge updated");
              })
              .catch(error => {
                let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                this.$root["$alert"].show('Unable to create booking', errorMessage)
              })
              .finally(() => {
                this.savingIndicator = false;
                this.fetchRoadBridges();
                this.resetForm();
              });

        } else {

          CMSApi.postRoadBridge(this.editedItem)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Road Bridge created");
              })
              .catch(error => {
                let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                this.$root["$alert"].show('Unable to create booking', errorMessage)
              })
              .finally(() => {
                this.savingIndicator = false;
                this.fetchRoadBridges();
                this.resetForm();
              });
        }
      }
    },

    // Buttons
    editRoadBridge(e) {
      CMSApi.fetchRoadBridgeById(e.data['transportServiceRoadBridgeId'])
          .then(data => {
            let roadBridgeCodeArr = data.roadBridgeCode.split('->');
            this.selectedCTSite = roadBridgeCodeArr[0];
            this.selectedRoadBridgeLocation = roadBridgeCodeArr[1];
            this.editedItem = Object.assign({}, data);
            this.isUpdate = true;
          })
          .finally(() => {
            this.isFormActive = true;
          })
    },

    async deleteRoadBridge(e) {
      e.cancel = true;
      if (await this.$root.$confirm.open('Road Bridge Removal', 'Are you sure you want to perform this action?', { color: 'warning'})) {
        App["$appAnalytics"].trackEvent('Transport Capacity/ Mappings - Delete Road Bridge Click');

        CMSApi.deleteRoadBridgeBridge(e.data["transportServiceRoadBridgeId"])
            .then(() => {
              this.fetchRoadBridges();
            })
            .catch(error => {
              let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
              this.$root.$alert.show('Unable to delete road bridge', errorMessage)
            })
      }
    },
  }
}
</script>

<style scoped>
.activeSwitch {
  color : #4630d8;
}

.inactiveSwitch {
  color: grey;
}
</style>