<template>
    <div>
        <v-card elevation="1" v-show="!isFormActive">
            <v-container>
                <v-row dense>
                    <v-col cols="2">
                        <v-row dense>
                            <v-col cols="12">
                                <v-toolbar-title>Customers</v-toolbar-title>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="1">
                        <v-row dense>
                            <v-col cols="12">
                                <v-checkbox
                                    v-if="showForwarderTickBox"
                                    dense
                                    v-model="selectedIsForwarder"
                                    label="Forwarder"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn
                          v-if="!isFormActive && showCustomerItems"
                          tile
                          color="secondary"
                          @click="resetBillingRatesToDefault"
                          title="Reset billing rates to default"
                      >
                          <v-icon>fa-solid fa-arrow-rotate-left</v-icon>
                          <span v-if="$vuetify.breakpoint.mdAndUp">Reset</span>
                      </v-btn>
                      <v-btn
                          v-if="!isFormActive && showCustomerItems"
                          tile
                          color="secondary"
                          @click="setBillingItemsToDefault"
                          title="Set billing items to default"
                          style="margin-left: 10px;"
                      >
                        <v-icon>fa-solid fa-down-left-and-up-right-to-center</v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Default</span>
                      </v-btn>
                      <v-btn
                          v-if="!isFormActive && showCustomerItems"
                          tile
                          color="secondary"
                          @click="showForm"
                          title="Add Items"
                          style="margin-left: 10px;"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Add Items</span>
                      </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="customerGridSize">
                        <DxDataGrid
                            id="customerGrid"
                            :ref="customerDataGridRefName"
                            :data-source="logisticCustomers"
                            :show-borders="false"
                            :hoverStateEnabled="true"
                            noDataText="No current customers"
                            :column-auto-width="true"
                            :column-hiding-enabled="true"
                            key-expr="companyId"
                            height="600"
                            @selection-changed="onCustomerSelect"
                        >
                            <DxScrolling mode="virtual"/>
                            <DxHeaderFilter :visible="true" />

                            <DxColumn data-field="name" data-type="string" caption="Company"/>

                            <DxSelection mode="single" />
                        </DxDataGrid>
                    </v-col>
                    <v-col :cols="secondaryGridSize" v-if="selectedIsForwarder">
                        <DxDataGrid
                            id="secondaryCustomerGrid"
                            :ref="secondaryCustomerDataGridRefName"
                            :data-source="secondaryCustomers"
                            :show-borders="false"
                            :hoverStateEnabled="true"
                            noDataText="No current customers"
                            key-expr="freightForwarderCustomerId"
                            height="600"
                            @selection-changed="onSecondaryCustomerSelect"
                        >
                            <DxScrolling mode="virtual"/>
                            <DxHeaderFilter :visible="true" />
                            <DxColumn cell-template="secondaryCustomerTemplate" data-type="string" caption="Secondary Customer"/>
                            <template #secondaryCustomerTemplate="{ data }">
                                <div>
                                    {{getCustomerName(data.data.companyId)}}
                                </div>
                            </template>

                            <DxSelection mode="single" />
                        </DxDataGrid>
                    </v-col>
                    <v-col :cols="itemGridSize" v-if="showCustomerItems">
                        <DxDataGrid
                            id="itemGrid"
                            :ref="itemGridRefName"
                            :data-source="billingCustomerItems"
                            :show-borders="false"
                            :hoverStateEnabled="true"
                            height="600"
                            noDataText="No current billing items"
                        >
                            <DxScrolling mode="virtual"/>
                            <DxHeaderFilter :visible="true" />
                            <DxColumn data-field="itemCode" data-type="number" caption="Item Code" width="30%"/>
                            <DxColumn data-field="chargeRate" data-type="number" caption="Sell Rate (nz$)"/>
                            <DxColumn data-field="costRate" data-type="number" caption="Buy Rate (nz$)"/>
                            <DxColumn data-field="nextChargeRate" data-type="number" caption="Next Sell Rate (nz$)"/>
                            <DxColumn data-field="nextCostRate" data-type="number" caption="Next Buy Rate (nz$)"/>
                            <DxColumn data-field="nextChangeRateDate" data-type="date" caption="Next Change Rate Date"/>
                            <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Actions" />
                            <template #actionCellTemplate="{ data }">
                              <div>
                                <v-icon color="primary" size="18" class="mr-2" @click="editCustomerItem(data.data)">
                                  mdi-pencil
                                </v-icon>

                                <v-icon color="primary" size="18" class="mr-2" @click="deleteCustomerItem(data.data)">
                                  mdi-trash-can
                                </v-icon>
                              </div>
                            </template>
                        </DxDataGrid>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card elevation="1" v-show="isFormActive">
            <v-container>
                <v-form ref="customerItemForm" lazy-validation>
                    <v-row dense>
                        <v-col cols="2" class="text-left">
                            <v-btn
                                tile
                                color="secondary"
                                @click="backToList"
                            >
                                <v-icon>mdi-arrow-left-thin</v-icon>
                                <span v-if="$vuetify.breakpoint.mdAndUp">Back To List</span>
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                          <v-select
                              v-if="!isUpdate"
                              v-model="form.itemCode"
                              label="Item Code"
                              item-text="itemCode"
                              item-value="itemCode"
                              :items="defaultItems"
                              dense
                          ></v-select>
                          <v-text-field
                              v-else
                              v-model="form.itemCode"
                              label="Item Code"
                              disabled
                              dense
                          ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-text-field
                                v-model.number="form.chargeRate"
                                type="number"
                                :rules="itemRateRules"
                                :label="isFafOrTuc ? 'Percentage' : 'Sell Amount'"
                                dense
                                oninput="if(this.value < 0) this.value = 0;"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="2" v-if="!isFafOrTuc">
                            <v-text-field
                                v-model.number="form.costRate"
                                type="number"
                                label="Buy Amount"
                                dense
                                :disabled="isFafOrTuc || isRoadBridge"
                                oninput="if(this.value < 0) this.value = 0;"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="form.nextChargeRate"
                                type="number"
                                :label="isFafOrTuc ? 'Next % Rate' : 'Next Sell Rate'"
                                dense
                                oninput="if(this.value < 0) this.value = 0;"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                              v-model="form.nextCostRate"
                              type="number"
                              label="Next Buy Rate"
                              dense
                              :disabled="isFafOrTuc || isRoadBridge"
                              oninput="if(this.value < 0) this.value = 0;"
                          >
                          </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-menu
                                v-model="form.dateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="computedEstimatedDateFormatted"
                                        label="Next Change Rate Date"
                                        readonly
                                        prepend-icon="mdi-calendar"
                                        hide-details="auto"
                                        v-on="on"
                                        @click:prepend="form.dateMenu = true"
                                        autocomplete="off"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="form.nextChangeRateDate"
                                    :min="showStartDateNow"
                                    @input="form.dateMenu = false"
                                    style="width:100%"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="save"
                    :disabled="form.itemCode === null"
                >
                    <v-icon>mdi-content-save</v-icon>
                    <span v-if="$vuetify.breakpoint.mdAndUp">Save</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import {defineComponent} from 'vue'
import {mapActions, mapGetters, mapState} from "vuex";
import {
  DxColumn,
  DxDataGrid,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSelection
} from "devextreme-vue/data-grid";
import generalHelpers from "@/utils/generalHelpers";
import CMSApi from "@/services/CMSApi";

export default defineComponent({
    name: "TransportBillings-CustomerItems",
    components: {DxHeaderFilter, DxScrolling, DxDataGrid, DxPaging, DxPager, DxSelection, DxColumn},

    mixins: [generalHelpers],

    data() {
        return {
            // Grid
            customerDataGridRefName: 'customerGrid',
            secondaryCustomerDataGridRefName: 'secondaryCustomerSiteGrid',
            itemGridRefName: 'itemGrid',
            pageSizes: [10,15,25],

            // Form
            isFormActive: false,
            isUpdate: false,
            validationErrors: {},
            selectedCompanyId: null,
            selectedSecondaryCompanyId: null,
            selectedIsForwarder: false,
            showForwarderTickBox: false,
            showCustomerItems: false,
            itemCodeRules: [
                v => !!v || 'Item Code is required',
            ],
            fafBaseRateRules: [
                v => !!v || 'FAF Base Rate is required',
            ],
            fafPercentageRules: [
                v => !!v || 'FAF Percentage is required',
            ],
            itemRateRules: [
                v => !!v || 'Item Rate is required',
            ],

            // Form Active
            form: {
                billingCustomerItemId : null,
                itemCode : null,
                itemType : null,
                chargeRate : null,
                costRate : null,
                nextChargeRate : null,
                nextCostRate : null,
                nextChangeRateDate : null,
                dateMenu : false,
            },
            faf: {
                baseRate: null,
                percentage: null,
            }
        }
    },

    computed : {
        ...mapState('transportMappings', [
            'customers',
            'freightForwarders',
            'freightForwarderCustomers'
        ]),

        ...mapState('transportBilling', [
            'items',
            'billingCustomerItems'
        ]),

        ...mapGetters('transportMappings', [
            'customersWithDefault'
        ]),

        gridColumnSize() {
            return this.isFormActive ? 6 : 12;
        },

        defaultItems() {
          return this.items.filter(item => !this.billingCustomerItems.map(billingItem => billingItem.itemCode).includes(item.itemCode));
        },

        computedItems() {
          return this.items.filter(item => !this.billingCustomerItems.map(billingItem => billingItem.itemCode).includes(item.itemCode));
        },

        logisticCustomers() {
            return this.customersWithDefault;
        },

        secondaryCustomers() {
            return this.freightForwarderCustomers;
        },

        customerGridSize() {
            return this.selectedIsForwarder ? 3 : 3;
        },

        secondaryGridSize() {
            return this.selectedIsForwarder ? 2 : 0;
        },

        itemGridSize() {
            return this.selectedIsForwarder ? 7 : 9;
        },

        showStartDateNow() {
            return window.Moment().startOf('day').add(1,'days').format('YYYY-MM-DD');
        },

        computedEstimatedDateFormatted() {
            return this.form.nextChangeRateDate !== null ? this.formatDate(this.form.nextChangeRateDate) : ''
        },

        isRoadBridge() {
            return this.form.itemType !== null && this.form.itemCode !== null  ? this.form.itemType.toUpperCase() === 'ROADBRIDGE' || this.form.itemCode.toUpperCase().includes("RB-") : false;
        },

        isFafOrTuc() {
            return this.form.itemType !== null ? this.form.itemType.toUpperCase() === 'FAF_KR' || this.form.itemType.toUpperCase() === 'TUC_KR' : false;
        },
    },

    mounted() {
      this.fetchCustomers();
      this.fetchFreightForwarders();
      this.fetchBillingItems();
    },

    watch : {
        async selectedCompanyId(val) {
            if (this.freightForwarders.filter(x => x.companyId === val).length > 0) {
                this.showForwarderTickBox = true;
                await this.fetchFreightForwarderCustomers(val);
                await this.fetchBillingCustomerItems(val);
                this.showCustomerItems = true;
            } else {
                this.showForwarderTickBox = false;
                this.selectedIsForwarder = false;
                await this.fetchBillingCustomerItems(val);
                this.showCustomerItems = true;
            }
        },

        async selectedSecondaryCompanyId(val) {
            if (val) {
                await this.fetchBillingCustomerItemsOfSecondaryCustomer( {customerId : val, forwarderId : this.selectedCompanyId});
                this.showCustomerItems = true;
            }
        },

        showForwarderTickBox(val) {
            if (val) {
                this.fetchFreightForwarderCustomers(this.selectedCompanyId);
            } else {
                this.selectedSecondaryCompanyId = null;
                this.fetchBillingCustomerItems(this.selectedCompanyId);
            }
        },

        'form.itemType'(val) {
            if (val) {
                switch (val.toLowerCase()) {
                    case 'faf':
                        this.faf.baseRate = this.items.filter(x => x.itemCode === this.form.itemCode)[0]["faF_BaseRate"];
                        this.faf.percentage = this.items.filter(x => x.itemCode === this.form.itemCode)[0]["faF_Percentage"];
                        break;
                }
            }
        }
    },

    methods : {
        ...mapActions('transportBilling', [
            'fetchBillingItems',
            'fetchBillingCustomerItems',
            'fetchBillingCustomerItemsOfSecondaryCustomer'
        ]),

        ...mapActions('transportMappings', [
            'fetchCustomers',
            'fetchFreightForwarders',
            'fetchFreightForwarderCustomers'
        ]),

        showForm() {
            this.isFormActive = true;
        },

        onCustomerSelect({ selectedRowsData }) {
            const data = selectedRowsData[0];

            this.selectedIsForwarder = false;
            this.selectedCompanyId = data.companyId;
        },

        onSecondaryCustomerSelect({ selectedRowsData }) {
            const data = selectedRowsData[0];

            this.selectedSecondaryCompanyId = data.companyId;
        },

        getCustomerName(id) {
            if (id !== null && this.customers.length > 0)
                return this.customers.filter(x => x.companyId === id)[0].name;
        },

        editCustomerItem(data) {
          data.itemType = this.items.filter(x => x.itemCode.toLowerCase() === data.itemCode.toLowerCase())[0].billingItemType;
          data.nextChangeRateDate = data.nextChangeRateDate !== null ? window.Moment(data.nextChangeRateDate).format('YYYY-MM-DD') : null;

          this.form = Object.assign({}, data);
          this.isFormActive = true;
          this.isUpdate = true;
        },

        async deleteCustomerItem(data) {
          if (await this.$root["$confirm"].open('Delete', 'Proceed to remove this customer billing item?' , { color: 'warning' })) {
            CMSApi.deleteBillingCustomerItem(data.billingCustomerItemId)
                .then(() => {
                  window.App.$emit('show-snackbar', "success", "Customer billing item removed!");
                  if (this.selectedSecondaryCompanyId) {
                    this.fetchBillingCustomerItemsOfSecondaryCustomer( {customerId : this.selectedSecondaryCompanyId, forwarderId :this.selectedCompanyId});
                  } else {
                    this.fetchBillingCustomerItems(this.selectedCompanyId);
                  }
                })
                .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to cancel appointment', errorMessage);
                })
          }
        },

        backToList() {
          this.isFormActive = false;
          this.isUpdate = false;
          this.resetForm();
          this.$refs.customerItemForm.reset();
          this.$refs.customerItemForm.resetValidation();
        },

        save() {
            if (this.$refs.customerItemForm.validate()) {
                if (this.form.billingCustomerItemId !== null) {
                    this.update();
                } else {
                    this.create();
                }
            }
        },

        update() {

          let putData = {
              billingCustomerItemId : this.form.billingCustomerItemId,
              customerId : this.selectedSecondaryCompanyId !== null ? this.selectedSecondaryCompanyId : this.selectedCompanyId,
              fwCompanyId : this.selectedSecondaryCompanyId !== null ? this.selectedCompanyId : null,
              itemCode : this.form.itemCode,
              chargeRate : this.form.chargeRate,
              costRate : this.form.costRate,
              nextChargeRate : this.form.nextChargeRate,
              nextCostRate : this.form.nextCostRate,
              nextChangeRateDate : this.form.nextChangeRateDate
          };

          CMSApi.putBillingCustomerItem(putData)
              .then(() => {
                  window.App.$emit('show-snackbar', "success", "Billing Customer Item updated");
                  this.isFormActive = false;
              })
              .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to update customer item', errorMessage)
              })
              .finally(() => {
                  this.resetForm();
                  if (this.selectedSecondaryCompanyId) {
                      this.fetchBillingCustomerItemsOfSecondaryCustomer( {customerId : this.selectedSecondaryCompanyId, forwarderId :this.selectedCompanyId});
                  } else {
                      this.fetchBillingCustomerItems(this.selectedCompanyId);
                  }
              });
        },

        create() {
            let postData = {
                customerId : this.selectedSecondaryCompanyId !== null ? this.selectedSecondaryCompanyId : this.selectedCompanyId,
                fwCompanyId : this.selectedSecondaryCompanyId !== null ? this.selectedCompanyId : null,
                itemCode : this.form.itemCode,
                chargeRate : this.form.chargeRate,
                costRate : this.form.costRate,
                nextChargeRate : this.form.nextChargeRate,
                nextCostRate : this.form.nextCostRate,
                nextChangeRateDate : this.form.nextChangeRateDate
            };

            CMSApi.postBillingCustomerItem(postData)
                .then(() => {
                    window.App.$emit('show-snackbar', "success", "Billing Customer Item created");
                    this.isFormActive = false;
                })
                .catch(error => {
                    let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                    this.$root["$alert"].show('Unable to create customer item', errorMessage)
                })
                .finally(() => {
                    this.resetForm();
                    if (this.selectedSecondaryCompanyId !== null) {
                        this.fetchBillingCustomerItemsOfSecondaryCustomer( {customerId : this.selectedSecondaryCompanyId, forwarderId :this.selectedCompanyId});
                    } else {
                        this.fetchBillingCustomerItems(this.selectedCompanyId);
                    }
                })
        },

        async setBillingItemsToDefault() {
          if (await this.$root.$confirm.open('Set Default Items', 'Are you sure you want to perform this action? Doing so, would override anything you have for this customer and will all be set to default', { color: 'warning'})) {
            if (this.selectedSecondaryCompanyId !== null) {
              CMSApi.putSyncAllBillingItemsToForwardersCustomer(this.selectedSecondaryCompanyId, this.selectedCompanyId)
                .then(() => {
                  window.App.$emit('show-snackbar', "success", "Billing Customer Items set to default");
                })
                .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to set default items', errorMessage)
                })
                .finally(() => {
                  this.fetchBillingCustomerItemsOfSecondaryCustomer( {customerId : this.selectedSecondaryCompanyId, forwarderId :this.selectedCompanyId});
                })
            } else {
              CMSApi.putSyncAllBillingItemsToCustomer(this.selectedCompanyId)
                .then(() => {
                  window.App.$emit('show-snackbar', "success", "Billing Customer Items set to default");
                })
                .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to set default items', errorMessage)
                })
                .finally(() => {
                  this.fetchBillingCustomerItems(this.selectedCompanyId);
                });
            }
          }
        },

        async resetBillingRatesToDefault() {
          if (await this.$root.$confirm.open('Reset Items Rate', 'Are you sure you want to perform this action? Doing so, would put all cost/charge rate to 0', { color: 'warning'})) {
            if (this.selectedSecondaryCompanyId !== null) {
              CMSApi.putResetAllRatesOfForwardersCustomer(this.selectedSecondaryCompanyId, this.selectedCompanyId)
                .then(() => {
                  window.App.$emit('show-snackbar', "success", "Billing Customer Items rate reset to default");
                })
                .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to reset items rate', errorMessage)
                })
                .finally(() => {
                  this.fetchBillingCustomerItemsOfSecondaryCustomer( {customerId : this.selectedSecondaryCompanyId, forwarderId :this.selectedCompanyId});
                })
            } else {
              CMSApi.putResetAllRatesOfCustomer(this.selectedCompanyId)
                .then(() => {
                  window.App.$emit('show-snackbar', "success", "Billing Customer Items rate reset to default");
                })
                .catch(error => {
                  let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                  this.$root["$alert"].show('Unable to reset items rate', errorMessage)
                })
                .finally(() => {
                  this.fetchBillingCustomerItems(this.selectedCompanyId);
                });
            }
          }
        },

        resetForm() {
            this.form.billingCustomerItemId = null;
            this.form.itemCode = null;
            this.form.itemType = null;
            this.form.chargeRate = null;
            this.form.costRate = null;
            this.form.nextCostRate = null;
            this.form.nextChargeRate = null;
            this.form.nextChangeRateDate = null;
            this.form.dateMenu = null;
        }
    },
})
</script>

<style scoped>

</style>