<template>
    <v-dialog v-model="showDialog" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <ValidationSummary :validationErrors="validationErrors" />
                <v-form ref="userform" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.firstName" label="First Name" 
                                    :rules="firstNameRules"
                                    :errorMessages="validationErrors['FirstName']"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="editedItem.lastName" label="Last Name" 
                                    :rules="lastNameRules"
                                    :errorMessages="validationErrors['LastName']"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.mobileNumber" label="Mobile #"                                     
                                    :errorMessages="validationErrors['MobileNumber']"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.emailAddress" label="Email Address"
                                    :rules="emailAddressRules"
                                    :errorMessages="validationErrors['EmailAddress']"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col cols="12" >
                                <span v-if="loadingCompanyUserRoles">loading roles...</span>
                                <span v-else-if="this.userRoles.length==0">No applications or roles are setup for this company</span>
                                <div v-else>
                                    <label for="content" class="v-label theme--light" v-bind:class="{ 'error--text': validationErrors['Roles']}">Roles</label>
                                    <v-checkbox
                                        v-for="(item, index) in this.userRoles"
                                        :key="item.roleId"
                                        :label="item.name" :value="item"
                                        v-model="editedItem.roles" multiple
                                        :rules="[v => v.length > 0 || 'At least one role is required']"
                                        :hide-details="index < userRoles.length-1 ? true : false"
                                        class="rolesCheckbox">
                                    </v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="close" v-show="!savingIndicator">Cancel</v-btn>
                <v-btn color="primary darken-1" text @click="save" :loading="savingIndicator">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ValidationSummary from '@/components/global/ValidationSummary.vue'

export default {
    components: {
        ValidationSummary,
    },

    props: {
        companyId: {
            type: Number,
            default: null
        }, 
    },
    data () {
        return {
            showDialog: false,
            editedItem: null,
            defaultItem: {
                userId: null,
                companyId: null,
                firstName: '',
                lastName: '',
                mobileNumber: '',
                emailAddress: '',
                roles: [],
            },
            companyUserRoles: [],
            savingIndicator: false,
            validationErrors: {},
            firstNameRules: [
                v => !!v || 'First Name is required',
            ],
            lastNameRules: [
                v => !!v || 'Last Name is required',
            ],
            mobileNumberRules: [
                v => !!v || 'Mobile Number is required',
            ],
            emailAddressRules: [
                v => !v || v == "" || /.+@.+\..+/.test(v) || 'Email Address must be valid',
            ],
            loadingCompanyUserRoles: false
        }
    },

    computed: {
        formTitle () {
            return this.editedItem.userId ? 'Edit User' : 'New User';
        },

        userRoles() {
            //create slimmed down model that matches v-model
            return this.companyUserRoles.map(r => ({roleId: r.roleId, name: r.name }));
        }
    },

    created () {
        //required to avoid runtime errors when first creating component
        this.editedItem = Object.assign({}, this.defaultItem);
        this.loadCompanyUserRoles();     
    },

    watch: {
        companyId:function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            this.loadCompanyUserRoles();
        },
    },
    
    methods: {   

        //public method - point of entry to component
        editUser (item) {
            this.editedItem = Object.assign({}, item);
            this.showDialog = true;
        },

        //public method - point of entry to component
        createUser () {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedItem.companyId = this.companyId;         
            this.showDialog = true;
        },

        loadCompanyUserRoles() {
            this.loadingCompanyUserRoles = true;

            CMSApi.fetchUserRolesForCompany(this.companyId)
                .then(data => {
                    this.companyUserRoles = data;  
                    this.clearValidationErrors(); //ensures role checkboxe validation doesn't trigger when chnaging roles list                
                })
                .finally(() => {
                    this.loadingCompanyUserRoles = false;
                });
        },

        save () {
            if (this.$refs.userform.validate())
            {
                this.savingIndicator = true;

                if (this.editedItem.userId) {
                    CMSApi.putUser(this.editedItem)
                        .then(data => {
                            this.close();

                            this.$emit('userUpdated', data);
                            window.App.$emit('show-snackbar', "success", "User updated");
                        })
                        .catch(error => {
                            this.validationErrors = error.response.data.errors;
                        })
                        .finally(() => {
                            this.savingIndicator = false;
                        });                   
                } else {
                    CMSApi.postUser(this.editedItem)
                        .then(data => {
                            this.close();

                            this.$emit('userCreated', data);
                            window.App.$emit('show-snackbar', "success", "User created");
                        })
                        .catch(error => {
                            this.validationErrors = error.response.data.errors;
                        })
                        .finally(() => {
                            this.savingIndicator = false;
                        }); 
                }
            }
        },

        close () {
            this.showDialog = false;
            
            //clear out data
            this.editedItem = Object.assign({}, this.defaultItem);
            this.clearValidationErrors();
        },

        clearValidationErrors() {
            if(this.$refs.userform != null)
                this.$refs.userform.resetValidation();
            this.validationErrors = {};
        }
    }
};

</script>

<style scoped>
    .rolesCheckbox
    {
        margin-top:0px
    }
</style>