<template>
    <div>
        <NotificationAlerts :applicationFilter="[ApplicationType.ImportAdvice]" />

        <ImportAdvice />
        <br/>
    </div>
</template>

<script>
import {ApplicationType} from '@/utils/constants.js';
import ImportAdvice from '@/components/importAdvice/ImportAdvice.vue'
import NotificationAlerts from '@/components/global/NotificationAlerts.vue'

export default {
    name: 'importadvice',
    components: {
        ImportAdvice,
        NotificationAlerts
    },

    created() {
        //make enums availble within html tags
        this.ApplicationType = ApplicationType;
    },
}
</script>