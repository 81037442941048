class DefaultColumns {
    constructor() {
        this.columns = [
            { text: 'Alerts', value: '', useTemplate: true, cellTemplate: "alertTemplate", type: 'string', allowSearch: false, width: '8%' },
            { text: 'Gate', value: 'gateName', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: false, width: '7%' },
            { text: 'Appt #', value: 'appointmentId', useTemplate: false, cellTemplate: "", type: 'number', allowSearch: true, width: '9%' },
            { text: 'Date', value: 'appointmentDate', useTemplate: false, cellTemplate: "", type: 'date', format: 'dd/MM/yyyy', allowSearch: true, width: '8%' },
            { text: 'Zone', value: 'zone', useTemplate: false, cellTemplate: "", type: 'number', allowSearch: true, width: '7%' },
            { text: 'Company', value: 'truckingCompanyId', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '7%' },
            { text: 'Rego', value: 'truckRegistration', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '8%' },
            { text: 'Type', value: 'appointmentType', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: false, width: '7%' },
            { text: 'Pos-Dr', value: '', useTemplate: true, cellTemplate: "doorTemplate", type: 'string', allowSearch: false, width: '5%'},
            { text: 'Container / Group #', value: 'containerNumber', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '12%' },
            { text: 'Empty Rel.', value: 'emptyReleaseNumber', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '10%' },
            { text: 'ISO', value: 'containerISOType', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '5%' },
            { text: 'Wt. (kg)', value: '', useTemplate: true, cellTemplate: "weightTemplate", type: 'number', allowSearch: false, width: '7%' },
        ]
    }
}

class AdminColumns {
    constructor() {
        this.columns = [
            { text: 'Alerts', value: '', useTemplate: true, cellTemplate: "alertTemplate", type: 'string', allowSearch: false, width: '5%' },
            { text: 'Status',value: 'status',useTemplate: false,cellTemplate: "",type: 'string',allowSearch: true,width: '5%' },
            { text: 'Actions', value: '', useTemplate: true, cellTemplate: "actionTemplate", type: 'string', allowSearch: false, width: '7%' },
            { text: 'Bill?', value: 'bill', useTemplate: false, cellTemplate: "", type: 'boolean', allowSearch: false, width: '7%' },
            { text: 'Gate', value: 'gateName', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: false, width: '7%' },
            { text: 'Appt#', value: 'appointmentId', useTemplate: false, cellTemplate: "", type: 'number', allowSearch: true, width: '7%' },
            { text: 'Date', value: 'appointmentDate', useTemplate: false, cellTemplate: "", type: 'date', format: 'dd/MM/yyyy', allowSearch: true, width: '5%' },
            { text: 'Zone', value: 'zone', useTemplate: false, cellTemplate: "", type: 'number', allowSearch: true, width: '5%' },
            { text: 'Company', value: 'truckingCompanyId', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '7%' },
            { text: 'Rego', value: 'truckRegistration', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '5%' },
            { text: 'Type', value: 'appointmentType', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '5%' },
            { text: 'Pos-Dr', value: '', useTemplate: true, cellTemplate: "doorTemplate", type: 'string', allowSearch: false, width: '5%' },
            { text: 'Container / Group #', value: 'containerNumber', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '10%' },
            { text: 'Empty Rel.', value: 'emptyReleaseNumber', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '10%' },
            { text: 'ISO', value: 'containerISOType', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '5%' },
            { text: 'Wt. (kg)', value: '', useTemplate: true, cellTemplate: "weightTemplate", type: 'number', allowSearch: false, width: '5%' }
        ]
    }
}

class CustomerColumns {
    constructor() {
        this.columns = [
            { text: 'Alerts', value: '', useTemplate: true, cellTemplate: "alertTemplate", type: 'string', allowSearch: false, width: '10%' },
            { text: 'Actions', value: '', useTemplate: true, cellTemplate: "actionTemplate", type: 'string', allowSearch: false, width: '10%' },
            { text: 'Gate', value: 'gateName', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: false, width: '10%' },
            { text: 'Appt#', value: 'appointmentId', useTemplate: false, cellTemplate: "", type: 'number', allowSearch: true, width: '10%' },
            { text: 'Date', value: 'appointmentDate', useTemplate: false, cellTemplate: "", type: 'date', format: 'dd/MM/yyyy', allowSearch: true, width: '8%' },
            { text: 'Zone', value: 'zone', useTemplate: false, cellTemplate: "", type: 'number', allowSearch: true, width: '7%' },
            { text: 'Rego', value: 'truckRegistration', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '7%' },
            { text: 'Type', value: 'appointmentType', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '7%' },
            { text: 'Container / Group #', value: 'containerNumber', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '10%' },
            { text: 'Empty Rel.', value: 'emptyReleaseNumber', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '10%' },
            { text: 'ISO', value: 'containerISOType', useTemplate: false, cellTemplate: "", type: 'string', allowSearch: true, width: '5%' },
            { text: 'Wt. (kg)', value: '', useTemplate: true, cellTemplate: "weightTemplate", type: 'number', allowSearch: false, width: '5%' }
        ]
    }
}


export default {
    AppointmentColumns : function () {
        this.defaultColumns = new DefaultColumns().columns;
        this.adminColumns = new AdminColumns().columns;
        this.customerColumns = new CustomerColumns().columns;
    }
}