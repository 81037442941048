<template>
    <PropelExpansionPanel :panelExpanded="panelExpanded" v-on:toggle-panel-click="$emit('toggle-panel-click')">
        <template v-slot:headerTitle>
            Import Advised - No VBS Booking ({{computedTotalUnbookedImportAdvised}})
        </template>
        <template v-slot:headerToolbar>
            <v-btn 
                tile color="secondary" :loading="loadingImportAdvisedRecords" 
                @click="$emit('refresh-button-click')" >
                <v-icon>mdi-refresh</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
            </v-btn>
            <v-btn 
                tile color="secondary" 
                @click="navigateToImportAdvice">
                <v-icon>mdi-arrow-right-thin-circle-outline</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp">Import Advice</span>
            </v-btn>
        </template>

        <template>
            <v-container id="GroupMenuItems" >
                <v-row >
                    <v-col>
                        <v-btn tile v-for="item in computedGroupMenuItems" :key="item.group" 
                            :color="item.group == selectedGroupMenuItem.group ? 'propelGreen' : ''"
                            @click="displayContainersForGroup(item)">
                            {{item.displayName}} ({{item.containerCount}})
                        </v-btn>
                    </v-col>                            
                </v-row>
            </v-container>

            <v-data-table
                id="importAdvisedDayStackTable"
                v-show="selectedGroupMenuItem.group != 'Groups'"
                :headers="dayStackTableHeaders"
                :items="computedContainersForSelectedGroup"
                :items-per-page="10"
                item-key="containerNumber"
                class="elevation-1"
                :loading=loadingImportAdvisedRecords loading-text="Loading... Please wait"
                no-data-text="You currently have no unbooked import advised containers"
                multi-sort
            >
                <template v-slot:item.vgmWeight="{ item }">
                    {{formatWeight(item.vgmWeight)}}
                </template>

                <template v-slot:item.importStatus="{ item }">
                    {{formatImportStatus(item.transitState, item.estimatedMoveTime, item.inBoundMode)}}
                </template>

                <template v-slot:item.impediments="{ item }">
                    {{formatHolds(item.impediments)}}
                </template>
                
                <template v-slot:item.action="{ item }">
                    <div>
                        <v-btn tile small color="secondary" @click="createDayStackAppointment(selectedGroupMenuItem.groupDate, selectedGroupMenuItem.groupDayStackType, item.containerNumber, $event.target)">Book</v-btn>
                    </div>
                </template>
            </v-data-table>

            <v-data-table
                id="importAdvisedGroupStacksTable"
                v-show="selectedGroupMenuItem.group == 'Groups'"
                :headers="blockStackTableHeaders"
                :items="computedBlockStackGroups"
                :items-per-page="10"
                item-key="group"
                class="elevation-1"
                :loading=loadingImportAdvisedRecords loading-text="Loading... Please wait"
                no-data-text="You currently have no unbooked import advised groups"
                multi-sort
            >
                <template v-slot:item.maxContainerVGMWeight="{ item }">
                    {{formatWeight(item.maxContainerVGMWeight)}}
                </template>

                <template v-slot:item.netContainerImportStatus="{ item }">
                    <span :style="{ color: (item.netContainerTransitState == ContainerTransitState.Yard ? 'black' : 'red' )}">{{formatImportStatus(item.netContainerTransitState, item.netContainerEstimatedMoveTime, item.netContainerInBoundMode)}}</span>
                </template>
                
                <template v-slot:item.action="{ item }">
                    <div>
                        <v-btn tile small color="secondary" @click="createGroupStackAppointment(item.group)" :disabled="item.netContainerTransitState != ContainerTransitState.Yard">Book</v-btn>
                    </div>
                </template>
            </v-data-table>
        </template>

    </PropelExpansionPanel>
</template>

<script>
import PropelExpansionPanel from '@/components/global/PropelExpansionPanel.vue'
import {AppointmentType, AppointmentState, GroupStackType, GroupDayStackType, ContainerTransitState} from '@/utils/constants.js';
import {appointmentHelpers} from '@/store/modules/appointments.js';
import UserRoles from "@/auth/authUserRoles";
import { getInstance } from "@/auth/authWrapper";
import { mapState } from 'vuex';

export default {
    components: {
        PropelExpansionPanel
    },
    props: {
        panelExpanded: Boolean
    },
    data () {
        return {
            dayStackTableHeaders: [
                { text: 'Container', value: 'containerNumber', align: 'left' },
                { text: 'ISO Type', value: 'isoType', align: 'left' },
                { text: 'Weight (kg)', value: 'vgmWeight', align: 'left' },
                { text: 'Import Status', value: 'importStatus', align: 'left' },
                { text: 'Holds', value: 'impediments', align: 'left' },
                { text: 'Work days on Port', value: 'workDaysOnPort', align: 'left', width: '100px' },
                { text: '', value: 'action', align: 'left', width: '90px' },
            ],
            blockStackTableHeaders: [
                { text: 'Group', value: 'group', align: 'left' },
                { text: 'ISO Type', value: 'isoType', align: 'left' },
                { text: 'Max Weight (kg)', value: 'maxContainerVGMWeight', align: 'left' },
                { text: 'Net Import Status', value: 'netContainerImportStatus', align: 'left' },
                { text: 'Net Holds', value: 'netContainerImpediments', align: 'left' },
                { text: 'Max Work days on Port', value: 'maxWorkDaysOnPort', align: 'left', width: '100px' },
                { text: 'Remaining unbooked', value: 'containerCount', align: 'left', width: '100px' },
                { text: '', value: 'action', align: 'left', width: '90px' },

            ],
            loadingImportAdvisedRecords: false,
            importAdvisedContainers: [],
            importAdvisedGroupsSummary: [],
            selectedGroupMenuItem: {"group": ""},
        }
    },

    mounted () {
        this.fetchImportAdvisedContainers();
    },

    created() {
        //make enums availble within html tags
        this.ContainerTransitState = ContainerTransitState;
    },

    computed: {
        ...mapState('appointments', [
            'appointments'
        ]),

        computedContainersWithoutAppointments() {
            let activeAppointments = this.appointments.filter(a => 
                a.appointmentType == AppointmentType.PickUp.code
                && a.status != AppointmentState.Canceled 
            );

            let unbookedContainers = this.importAdvisedContainers.filter(importContainer => 
                !activeAppointments.some(a => 
                    a.containerNumber == importContainer.containerNumber
                    && a.appointmentType == AppointmentType.PickUp.code
                    && a.status != AppointmentState.Canceled 
                ));

            this.calculateImportAdvisedGroupsSummary(unbookedContainers, activeAppointments);

            return unbookedContainers;
        },

        computedContainersForSelectedGroup() {
            return this.computedContainersWithoutAppointments.filter(item => item.group == this.selectedGroupMenuItem.group);
        },

        computedBlockStackGroups() {
            return this.importAdvisedGroupsSummary.filter(item => item.groupType == GroupStackType.Block);
        },

        computedGroupMenuItems() {
            //add day groups
            const menuItems = this.importAdvisedGroupsSummary.filter(item => item.groupType == GroupStackType.Day);

            //add one item that summarises block stack
            const blockStackMenuItem = {
                "displayName": "Groups",
                "group": "Groups",
                "groupType": GroupStackType.Block,
                "groupDate": null,
                "containerCount": 0
            };

            for (const blockGroup of this.importAdvisedGroupsSummary.filter(item => item.groupType == GroupStackType.Block))
                blockStackMenuItem.containerCount += blockGroup.containerCount;
            
            if (blockStackMenuItem.containerCount > 0)
                menuItems.push(blockStackMenuItem);

            return menuItems;
        },

        computedTotalUnbookedImportAdvised () {
            let totalCount = 0;
            this.importAdvisedGroupsSummary.forEach(function (item) {
                totalCount += item.containerCount
            });

            return totalCount;
        },

        importAdviceUrl () {
            return process.env.VUE_APP_IMPORT_ADVICE_URL
        }
    },

    watch: {
        computedGroupMenuItems: function (menuItems) {
            if (menuItems.length > 0)
            {
                if (this.selectedGroupMenuItem == null || !menuItems.some(i => i.group == this.selectedGroupMenuItem.group))
                    this.selectedGroupMenuItem = menuItems[0];
            }
        }
    },
    
    methods: {
        //public method
        refreshData() {
            this.fetchImportAdvisedContainers();
        },

        fetchImportAdvisedContainers() {
            this.loadingImportAdvisedRecords = true;

            CMSApi.fetchImportAdvisedContainers()
                .then(data => {
                    this.importAdvisedContainers = data;
                })
                .finally(() => {
                    this.loadingImportAdvisedRecords = false;
                });
        },

        displayContainersForGroup(group) {
            this.selectedGroupMenuItem = group;
        },

        async createDayStackAppointment (date, dayStackType, containerNumber, target) {
            this.iconProcessing(target, true);
            App.$appAnalytics.trackEvent('VBS - Create Appointment - Day Stack - Click');
            await window.App.$emit('createDayStackAppointment', date.format('YYYY-MM-DD'), dayStackType, containerNumber);
            this.iconProcessing(target, false);
        },

        async createGroupStackAppointment (group) {
            App.$appAnalytics.trackEvent('VBS - Create Appointment - Group Stack - Click');
            await window.App.$emit('createGroupStackAppointment', group);
        },

        iconProcessing(target, value) {
            if(value)
            {
                target.classList.add("mdi-loading", "icon-spinner");
            }
            else
            {
                target.classList.remove("mdi-loading", "icon-spinner");
            }
        },

        formatWeight(weight) {
            return (weight != 0 ? weight : '');
        },

        formatHolds(holds) {
            let content = "Req Event: UNIT_CHARTED";
            let filtered = holds.filter(function (str) {
              return str.indexOf(content) !== 0;
            });
            return filtered.join(", ");
        },

        formatImportStatus(transitState, estimatedMoveTime, inBoundMode) {            
            return appointmentHelpers.determineImportStatus(transitState, estimatedMoveTime, inBoundMode);
        },

        calculateImportAdvisedGroupsSummary(importAdvisedUnbookedContainers, activeAppointments)
        {
            let groupsSummary = [];
            let date = window.Moment();

            for (let i = 0; i < 7; i++) {
                let group = this.mapDateToGroup(date);
                let displayName = "";

                if(i == 0)
                    displayName = "Today";
                else if(i == 1)
                    displayName = "Tomorrow";
                else
                    displayName = date.format('dddd');

                //support single day group
                groupsSummary.push(
                    {
                        "displayName": displayName,
                        "group": group,
                        "groupType": GroupStackType.Day,
                        "groupDayStackType": GroupDayStackType.EntireDay,
                        "groupDate": date.clone(),
                        "containerCount": 0
                    }
                );

                //support am and pm day groups (will be used in peak season)
                groupsSummary.push(
                    {
                        "displayName": displayName + " AM",
                        "group": group.slice(0, -1) + "A",
                        "groupType": GroupStackType.Day,
                        "groupDayStackType": GroupDayStackType.AM,
                        "groupDate": date.clone(),
                        "containerCount": 0
                    }
                );

                groupsSummary.push(
                    {
                        "displayName": displayName + " PM",
                        "group": group.slice(0, -1) + "P",
                        "groupType": GroupStackType.Day,
                        "groupDayStackType": GroupDayStackType.PM,
                        "groupDate": date.clone(),
                        "containerCount": 0
                    }
                );
                date.add(1, 'd');
            }

            for(const importContainer of importAdvisedUnbookedContainers)
            {
                const groupSummary = groupsSummary.find(s => s.group == importContainer.group);
                if (groupSummary == null){
                    groupsSummary.push(
                        {
                            "displayName": importContainer.group,
                            "group": importContainer.group,
                            "groupType": GroupStackType.Block,
                            "groupDate": null,
                            "containerCount": 1,
                            "isoType": importContainer.isoType,
                            "maxContainerVGMWeight": importContainer.vgmWeight,
                            "netContainerTransitState": importContainer.transitState,
                            "netContainerEstimatedMoveTime": importContainer.estimatedMoveTime,
                            "netContainerInBoundMode": importContainer.inBoundMode,
                            "netContainerImpediments": (importContainer.impediments.length),
                            "maxWorkDaysOnPort":importContainer.workDaysOnPort,
                        }
                    );
                }
                else { 
                    //update group
                    groupSummary.containerCount++;

                    //update Block Stack summary data
                    if (groupSummary.groupType == GroupStackType.Block)
                    {
                        if (importContainer.vgmWeight > groupSummary.maxContainerVGMWeight)
                            groupSummary.maxContainerVGMWeight = importContainer.vgmWeight;

                        if (importContainer.workDaysOnPort > groupSummary.maxWorkDaysOnPort)
                            groupSummary.maxWorkDaysOnPort = importContainer.workDaysOnPort;

                        if (importContainer.impediments.length > 0)
                            groupSummary.netContainerImpediments += importContainer.impediments.length;

                        //determine netContainerTransitState and netContainerEstimatedMoveTime
                        //- if container is in Yard, don't need to further process, as that is lowest/best case anyway and would have already been set
                        if (importContainer.transitState != ContainerTransitState.Yard)
                        {
                            //- only proceed if groupSummary not already at max state (not in yard and no move time e.g. "On Vessel Time TBC")
                            if (groupSummary.netContainerTransitState == ContainerTransitState.Yard || groupSummary.netContainerEstimatedMoveTime != null)
                            {
                                groupSummary.netContainerTransitState = importContainer.transitState;
                                groupSummary.netContainerInBoundMode = importContainer.inBoundMode;

                                if (importContainer.estimatedMoveTime == null)
                                    groupSummary.netContainerEstimatedMoveTime = null;
                                else {
                                    let netContainerEstimatedMoveTime = window.Moment(groupSummary.netContainerEstimatedMoveTime);
                                    let estimatedMoveTime = window.Moment(importContainer.estimatedMoveTime);
                                    if (estimatedMoveTime.isAfter(netContainerEstimatedMoveTime))
                                        groupSummary.netContainerEstimatedMoveTime = importContainer.estimatedMoveTime;
                                }
                            }
                        }

                    }                    
                }
            }

            //reduce count for block groups where there exists active bookings
            for(let groupSummary of groupsSummary)
            {
                if(groupSummary.groupType == GroupStackType.Block)
                    groupSummary.containerCount = groupSummary.containerCount - activeAppointments.filter(a => a.containerNumber == groupSummary.group).length;
            }

            //just return groups that have containers
            this.importAdvisedGroupsSummary = groupsSummary.filter(item => item.containerCount > 0);
        },

        mapDateToGroup(date)
        {
            let group = "";

            switch(date.isoWeekday())
            {
                case 1: group = "MON"; break;
                case 2: group = "TUE"; break;
                case 3: group = "WED"; break;
                case 4: group = "THU"; break;
                case 5: group = "FRI"; break;
                case 6: group = "SAT"; break;
                case 7: group = "SUN"; break;
            }

            return group;
        },

        navigateToImportAdvice()
        {
            App.$appAnalytics.trackEvent('VBS - Import Advice Link - Click');

            let authService = getInstance();
            if (authService.userHasRole(UserRoles.ImportAdvisor)) {
                this.$router.push("import-advice");
            }
            else {
                //for soft launch of import advice - redirect to internal page if they have the IA role, else redirect to Portal page
                //when we turn off Portal's IA, then we will switch to alert if the user doesn't have access
                window.open(this.importAdviceUrl, '_blank');
                //this.$root.$alert.show('Insufficient Access', 'You do not have the Import Advisor role. Please contact your Company Administrator to request access.')
            }
        }

    }
};
</script>

<style scoped> 
    #GroupMenuItems {
        padding-bottom: 2px;
    }

    #GroupMenuItems .v-btn {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    #GroupMenuItems > .row > [class*=col] {
        padding-top: 0px;
        padding-bottom: 0px;
    }

</style>