<template>
    <PropelExpansionPanel :panelExpanded="panelExpanded"  v-on:toggle-panel-click="$emit('toggle-panel-click')">
    <template v-slot:headerTitle>
        {{title}}
    </template>
    <template v-slot:headerToolbar>
            <v-btn                    
                tile color="secondary"                     
                :loading="loadingCameras"
                @click="$emit('refresh-button-click')" >
                    <v-icon>mdi-refresh</v-icon>
                    <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
            </v-btn>
            <v-btn v-show="!bookingPanelDisplayed"
                tile color="secondary" 
                @click="newCamera" >
                    <v-icon>mdi-plus</v-icon>
                    <span v-if="$vuetify.breakpoint.mdAndUp">New Camera</span>
            </v-btn>
        </template>
    <template>
        <div>
            <v-card id="cameraList" elevation="1">

                <v-container class="datatableControls" id="cameraListTableControls">
                    <v-row>
                        <v-col cols="12" sm="6" md="5" lg="4" xl="4">                        
                            <v-text-field 
                                clearable 
                                v-model="searchText" 
                                label="Search by Camera Name, CCTV ID or Image Name" 
                                prepend-inner-icon="mdi-magnify" 
                                hide-details="auto">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <DxDataGrid
                    id="cameraGrid"
                    :ref="dataGridRefName"
                    :data-source="cameras"
                    :show-borders="false"
                    :hoverStateEnabled="true"
                    noDataText="No current cameras"
                    @editing-start="editCamera"
                    @row-removing="deleteCamera"
                    :column-auto-width="true"
                    :column-hiding-enabled="true"
                >
                    <DxRowDragging
                        :allow-reordering="true"
                        :on-reorder="onReorder"
                    />
                    <DxHeaderFilter :visible="false" />
                    <DxSorting mode="multiple"/>

                    <DxEditing
                        :allow-updating="true"
                        :allow-deleting="true"
                        mode="row"
                    >
                        <DxTexts confirmDeleteMessage="" />
                    </DxEditing>

                    <DxPaging :page-size="10"/>
                    <DxPager
                        :show-page-size-selector="true"
                        :allowed-page-sizes="pageSizes"
                        :show-info="true"
                    />

                    <DxColumn data-field="name" data-type="string" :hiding-priority="0" />
                    <DxColumn data-field="imageName" data-type="string" caption="Image Filename" :hiding-priority="1"  />
                    <DxColumn data-field="cctvId" data-type="string" />
                    <DxColumn data-field="cameraGroupName" data-type="string" caption="Camera Group Name" />

                    <DxColumn data-field="isActive" cell-template="statusCell" data-type="string" caption="Status" />
                    <template #statusCell="{ data }">
                        <div>
                            <v-chip 
                                :color="statColour(data.value)"
                                class="roleChips">
                                {{ statValue(data.value) }}
                            </v-chip>
                        </div>
                    </template>    

                    <DxColumn type="buttons" cell-template="actionCellTemplate" />
                    <template #actionCellTemplate="{ data }">
                        <div>
                            <v-icon color="primary" size="18" class="mr-2" @click="editCamera(data)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="primary" size="18" class="mr-2" @click="deleteCamera(data)">
                                mdi-delete
                            </v-icon>
                        </div>
                    </template>

                </DxDataGrid>

            </v-card>
            <CameraForm 
                ref="cameraForm" 
                v-on:cameraCreated="cameraCreatedOrUpdated()" 
                v-on:cameraUpdated="cameraCreatedOrUpdated()
                "/>
        </div>
    </template>
    </PropelExpansionPanel>
</template>

<script>
import CameraForm from '@/components/cameras/CameraForm.vue'
import PropelExpansionPanel from '@/components/global/PropelExpansionPanel.vue'
import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSorting,
    DxEditing,
    DxRowDragging,
    DxTexts
} from 'devextreme-vue/data-grid';

export default {
    components: {
        CameraForm,
        PropelExpansionPanel,
        DxDataGrid,
        DxRowDragging,
        DxColumn,
        DxHeaderFilter,
        DxPager,
        DxPaging,
        DxSorting,
        DxEditing,
        DxTexts
    },

    props: {
        readOnly: Boolean,
        portAdminView: Boolean,
        title: String,
        bookingPanelDisplayed: Boolean,
        panelExpanded: Boolean
    },

    data () {
        return {
            cameras: [],
            loadingCameras: false,
            searchText: '',
            pageSizes: [10, 25, 50, 100],
            dataGridRefName: 'cameraGrid',
            sortData: {
                CameraIdFrom: '',
                CameraIdTo: ''
            },
            sortchange: false
        }
    },

    watch: {
        searchText: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            this.$refs[this.dataGridRefName].instance.searchByText(newVal?.trim());
        },

        loadingUsers: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            if(newVal)
                this.$refs[this.dataGridRefName].instance.beginCustomLoading();
            else
                this.$refs[this.dataGridRefName].instance.endCustomLoading();
        },

        panelExpanded: function (newVal, oldVal){ // eslint-disable-line no-unused-vars
            if(newVal)
                this.fetchCameras();

        }
    },

    methods: {
        //public method
        refreshData() {
            this.fetchCameras();
        },

        fetchCameras()
        {
            CMSApi.fetchAllCamera()
                    .then(data => {
                        this.cameras = data;
                    })
                    .finally(() => {
                        this.loadingCameras = false;
                    });
        },

        statColour (value){
            return value > 0 ? 'success' : 'primary';
        },

        statValue (value){
            return value > 0 ? 'Active' : 'Inactive';
        },

        newCamera() {
            App.$appAnalytics.trackEvent('Cameras - New Camera Click');
            this.$refs.cameraForm.addCamera();
        },

        editCamera(e) {
            App.$appAnalytics.trackEvent('Port Admin - Camera - Edit Click');
            e.cancel = true;
            this.$refs.cameraForm.editCamera(e.data);
        },

        cameraCreatedOrUpdated() {
            this.$emit('cameraRefresh');
            this.fetchCameras();
        },

        cameraDeleted(cameraId) {
            this.cameras.splice(this.cameras.findIndex(i => i.cameraId == cameraId), 1);
        },

        async deleteCamera(e){
            e.cancel = true;

            if (await this.$root.$confirm.open('Remove camera?', 'Are you sure you want to remove this camera?', { color: 'warning'}))
            {
                App.$appAnalytics.trackEvent('Port Admin - Camera - Delete Click');
                this.loadingCameras = true;

                CMSApi.deleteCamera(e.data.cameraId)
                    .then(() => {
                        this.cameraDeleted(e.data.cameraId);
                        this.$emit('cameraDeleted');
                        window.App.$emit('show-snackbar', "success", "Camera removed");
                    })
                    .catch(error => {
                        let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                        this.$root.$alert.show('Unable to delete camera', errorMessage)
                    })
                    .finally(() => {
                        this.loadingCameras = false;
                    });
            }
        },

        onReorder(e) {
            const visibleRows = e.component.getVisibleRows();
            const toIndex = this.cameras.indexOf(visibleRows[e.toIndex].data);
            const fromIndex = this.cameras.indexOf(e.itemData);

            this.sortData.CameraIdFrom = this.cameras[toIndex].cameraId;
            this.sortData.CameraIdTo   = this.cameras[fromIndex].cameraId;
            
            CMSApi.changeSort(this.sortData)
                .then(() => {
                        this.fetchCameras();
                        this.$emit('sortUpdated');
                        window.App.$emit('show-snackbar', "success", "Sorting changed");
                })
                .catch(error => {
                    let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                    this.$root.$alert.show('Unable to change sorting', errorMessage)
                })
                .finally(() => {
                    this.loadingCameras = false;
            });
        },
    }
}

</script>

<style>
    #cameraList {
        padding-bottom: 12px;
    }

    .roleChips {
        margin: 2px;
        margin-right: 5px;
    }
</style>