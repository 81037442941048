<template>
  <div>
    <v-card id="gateList" elevation="1">
      <v-container class="datatableControls" id="gateServiceListTableControls">
        <br>
        <v-row>
          <v-col cols="12" sm="2" md="2" lg="2" xl="2">
            <v-text-field autocomplete="off" clearable v-model="searchText" label="Search by Gate Service Name, Gate Id" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" lg="3">
            <v-checkbox v-model="filterIncludeExpired"  label="Show Inactive and Cancelled" hide-details></v-checkbox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="text-right">
            <v-btn
                tile class="mb-2"
                color="secondary"
                :loading="loadingServices"
                @click="reloadServices">
              <v-icon>mdi-refresh</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn
                tile class="mb-2"
                color="secondary"
                :disabled="!isPortAdmin"
                @click="openServiceFormDialog">
              <v-icon>mdi-plus</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">Service</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <DxDataGrid
          id="gateGrid"
          :ref="dataGridRefName"
          :data-source="gateServices"
          :show-borders="false"
          :hoverStateEnabled="true"
          noDataText="No current services"
          :column-auto-width="true"
          :column-hiding-enabled="true"
          :onRowPrepared="disablePastAndCancelled"
      >
        <DxLoadPanel :enabled="loadingServices" />
        <DxPaging :page-size="10"/>
        <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
        />

        <DxColumn
            data-field="gateServiceName"
            data-type="string"
            caption="Service Name"
            width="15%"
        />

        <DxColumn
            data-field="gateName"
            data-type="string"
            caption="Gate Name"
            width="15%"
        />

        <DxColumn
            data-field="startDate"
            data-type="date"
            caption="Start Date"
            width="15%"
        />

        <DxColumn
            data-field="endDate"
            data-type="date"
            caption="End Date"
            width="15%"
        />

        <DxColumn
            data-field="setGateConfig"
            caption="Gate Configuration"
            alignment="center"
            cell-template="gateConfig"
        />
        <template #gateConfig="{data}">
          {{ parseGateConfig(data) }}
        </template>

        <DxColumn
            caption="Selected Days"
            :hiding-priority="0"
            alignment="center"
        >
          <DxColumn
              data-field="monday"
              caption="M"
              width="50"
              cell-template="checkDay"
              :allow-header-filtering="false"
          />
          <DxColumn
              data-field="tuesday"
              caption="T"
              width="50"
              cell-template="checkDay"
              :allow-header-filtering="false"
          />
          <DxColumn
              data-field="wednesday"
              caption="W"
              width="50"
              cell-template="checkDay"
              :allow-header-filtering="false"
          />
          <DxColumn
              data-field="thursday"
              caption="Th"
              width="50"
              cell-template="checkDay"
              :allow-header-filtering="false"
          />
          <DxColumn
              data-field="friday"
              caption="F"
              width="50"
              cell-template="checkDay"
              :allow-header-filtering="false"
          />
          <DxColumn
              data-field="saturday"
              caption="S"
              width="50"
              cell-template="checkDay"
              :allow-header-filtering="false"
          />
          <DxColumn
              data-field="sunday"
              caption="Su"
              width="50"
              cell-template="checkDay"
              :allow-header-filtering="false"
          />
        </DxColumn>
        <template #checkDay="{ data }">
          <div>
            <v-icon v-if="data.value == true"
                    color="green">
              mdi-check
            </v-icon>
            <v-icon v-else
                    color="red">
              mdi-close
            </v-icon>
          </div>
        </template>

        <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Action" width="15%" />
        <template #actionCellTemplate="{ data }" >
          <div v-if="!data.data['isCancelled'] || endedServices(data)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="var(--v-propelDarkGrey-base)"
                    size="18"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="editZone(data)"
                    :disabled="!isPortAdmin"
                >
                  mdi-timeline-clock-outline
                </v-icon>
              </template>
              <span>Add / Remove Zone and Slots</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    class="mr-2"
                    size="18"
                    v-bind="attrs"
                    v-on="on"
                    @click="editService(data)"
                    :disabled="!isPortAdmin"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit Gate Service Info</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    class="mr-2"
                    size="18"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteService(data)"
                    :disabled="!isPortAdmin"
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span>Delete Gate Service</span>
            </v-tooltip>
          </div>
        </template>
      </DxDataGrid>
      <v-progress-linear
          v-if="loadingServices"
          indeterminate
          color="teal"
      ></v-progress-linear>
    </v-card>

    <v-dialog v-model="serviceFormDialog" :width="customWidth" persistent ref="serviceFormDialog">
      <gate-service-form
          ref="gateServiceForm"
          :key="`${componentKey}=1`"
          @close="closeServiceFormDialog(false)"
          @closeAndReload="closeServiceFormDialog(true)"
      ></gate-service-form>
    </v-dialog>

    <v-dialog v-model="zoneSlotDialog" width="600" persistent ref="zoneSlotDialog">
      <gate-zone-slot
          ref="gateZoneSlot"
          :key="`${componentKey}=1000`"
          @close="closeZoneSlotDialog(false)"
          @closeAndReload="closeZoneSlotDialog(true)"
      ></gate-zone-slot>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {DxDataGrid, DxLoadPanel, DxPaging, DxPager, DxColumn} from "devextreme-vue/data-grid";
import gateServiceForm from "@/components/portAdmin/gate/GateServiceForm";
import { GateConfig } from '@/utils/constants.js'
import gateZoneSlot from "@/components/portAdmin/gate/GateZoneSlot";
import CMSApi from "@/services/CMSApi";

export default {
  name: "GateServiceList",

  components: {
    DxDataGrid,
    DxLoadPanel,
    DxPaging,
    DxPager,
    DxColumn,
    gateServiceForm,
    gateZoneSlot
  },

  props: {
    isPortAdmin: Boolean,
  },

  data() {
    return {
      //Grid config
      searchText: '',
      filterIncludeExpired: false,
      dataGridRefName: 'gateGrid',
      pageSizes: [10, 25, 50, 100],

      //Dialog
      serviceFormDialog: false,
      serviceFormDialogInitialLoad: true,
      zoneSlotDialog: false,
      zoneSlotDialogInitialLoad: true,

      //ComponentKey
      componentKey: 0,

      customWidth: 1300
    }
  },

  mounted() {
    this.reloadServices();
  },

  computed: {
    ...mapState('gateService', [
        'services',
        'vx_loadingServices',
        'formMode'
    ]),

    gateServices() {
      return this.services;
    },

    loadingServices() {
      return this.vx_loadingServices;
    },
  },

  watch: {
    formMode(val) {
      this.customWidth = val === 'add' ? 1300 : 650;
    },

    filterIncludeExpired: function (val) {
      if (val)
        this.fetchAllGateServices();
      else {
        this.fetchGateServices();
      }
    },
  },

  methods: {
    ...mapActions('gateService', [
        'fetchGateServices',
        'fetchAllGateServices'
    ]),

    ...mapMutations('gateService',[
        'initEditMode',
        'resetMode'
    ]),

    //Misc
    reloadServices() {
      if (this.filterIncludeExpired)
        this.fetchAllGateServices();
      else
        this.fetchGateServices();
    },

    parseGateConfig(data) {
      return GateConfig[data.data.setGateConfig];
    },

    endedServices({data}) {
      return window.Moment(data.endDate).format('YYYY-MM-DD') <= window.Moment().format('YYYY-MM-DD');
    },

    //Dialog functions
    openServiceFormDialog() {
      App["$appAnalytics"].trackEvent('GS - Add Service - Click');

      if (!this.serviceFormDialogInitialLoad)
        this.componentKey += 1;

      this.serviceFormDialog = true; //Show Dialog
      this.serviceFormDialogInitialLoad = false;
    },

    closeServiceFormDialog(reload) {
      App["$appAnalytics"].trackEvent('GS - Close Service Form Dialog - Click');

      this.serviceFormDialog = false; //Close Dialog

      if (reload)
        this.reloadServices();

      this.resetMode();
    },

    async editService({data}) {
      App["$appAnalytics"].trackEvent('GS - Edit Gate Service - Click');

      await this.initEditMode();

      CMSApi.fetchGateServicesInfo(data.gateServiceId)
          .then(async (data) => {
            App["$appAnalytics"].trackEvent('GS - Updated Gate Service - Success');
            if (!this.serviceFormDialogInitialLoad)
              this.componentKey += 1;
            this.serviceFormDialog = true; //Show Dialog
            this.serviceFormDialogInitialLoad = false;
            this.$nextTick(() => {
              this.$refs.gateServiceForm.edit(data);
            });
          })
    },

    async editZone({data}) {
      App["$appAnalytics"].trackEvent('GS - Edit Zone Slot - Click');

      if (!this.zoneSlotDialogInitialLoad)
        this.componentKey += 1;

      this.zoneSlotDialog = true; //Show Dialog

      this.$nextTick(() => {
        this.$refs.gateZoneSlot.init(data);
      });
    },

    closeZoneSlotDialog(reload) {
      App["$appAnalytics"].trackEvent('GS - Close Zone Slot Dialog - Click');

      this.zoneSlotDialog = false; //Close Dialog

      if (reload)
          this.reloadServices();
    },

    async deleteService({data}) {
      App["$appAnalytics"].trackEvent('GS - Delete Service - Click');

      if (await this.$root["$confirm"].open('Gate Service Removal', 'Are you sure you want to delete this service?', { color: 'warning' })) {
        CMSApi.removeGateService(data.gateServiceId)
            .then(() => {
              window.App.$emit('show-snackbar', "success", "Gate service deleted");
            })
            .catch(error => {
              let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
              this.$root["$alert"].show('Unable to deactivate company', errorMessage)
            })
            .finally(() => {
              this.reloadServices();
            })
      }
    },

    disablePastAndCancelled(e) {
      if (e.rowType === "data" && e.data["isCancelled"]) {
        e["rowElement"].style.background = "#fcfcfc";
        e["rowElement"].style.color = "#909090";
      }
    }
  }
}
</script>

<style scoped>

</style>