<template>
  <v-card>
    <v-card-text>
      <v-container>
        <ValidationSummary :validationErrors="validationErrors" />
        <v-form
            ref="frmBookingDate"
        >
          <v-date-picker
              v-model="date"
              :allowed-dates="this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator) ? null : fetchDates"
              class="mt-4"
          >
          </v-date-picker>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" text @click="$emit('close')">Close</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text :disabled="!formIsValid" @click="confirmChange">Switch Date</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ValidationSummary from "@/components/global/ValidationSummary.vue";
import CMSApi from "@/services/CMSApi";
import {getInstance} from "@/auth";
import UserRoles from "@/auth/authUserRoles";

export default {
  name: "TransportPlannerForm-DateChange",

  components : {
    ValidationSummary
  },

  data() {
    return {
      //Form Validations
      validationErrors: {},
      wagonRules : [
        v => !!v || 'Wagon is required',
      ],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },

  mounted() {
    this.fetchBookingDates(this.workingPlannerBooking.trainServiceWagonInstanceId);
  },

  computed : {
    ...mapState('transportPlanner', [
        'workingPlannerBooking'
    ]),

    ...mapGetters('transportPlanner', [
        'getDatesOnly',
        'getBookingDateInstanceId'
    ]),

    userRoles() {
      return UserRoles;
    },

    isDateFilled() {
      return (this.date !== (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          || this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator))
          &&
              this.date !== null;
    },

    formIsValid() {
      return this.isDateFilled;
    },
  },

  methods : {
    ...mapActions('transportPlanner', [
        'fetchBookingDates',
        'resetWorkingPlannerBooking'
    ]),

    // Misc functions
    fetchDates(val) {
      for (let i = 0; i < this.getDatesOnly.length; i++) {
        if (this.getDatesOnly[i] === val){
          return val
        }
      }
    },

    async confirmChange() {
      let selectedDate = this.date;
      if (this.$refs.frmBookingDate.validate()) {
        if (await this.$root["$snackUndo"].open('success', 'Booking date change successful!')) {
          let putData = {
            trainServicePlannerBookingId : this.workingPlannerBooking.trainServicePlannerBookingId,
            selectedDate : selectedDate
          }
          CMSApi.putChangeBookingDate(putData)
              .then(() => {
                this.$emit('closeAndReload');
                this.resetWorkingPlannerBooking();
              })
        }
      }
    },

    userHasRole(roles) {
      let authService = getInstance();
      return authService.userHasRole(roles);
    },
  },


}
</script>

<style scoped>

</style>