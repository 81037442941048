import Vue from "vue";
import vuetify from '@/plugins/vuetify';
import createAuth0Client from "@auth0/auth0-spa-js";
import jwtDecode from 'jwt-decode'
import store from '@/store/'
import UserRoles from "@/auth/authUserRoles"
import loginBackgroundImage from "@/assets/img/login_background.jpg"
import propelLogo from "@/assets/img/propel_logo.png"

const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    redirectUri = window.location.origin,
    ...options
}) => {
    if (instance) return instance;

    instance = new Vue({
        vuetify,
        store,
        data() {
            return {
                //publically used
                loading: true,
                isAuthenticated: false,
                user: {},

                //privately used
                auth0Client: null,
                popupOpen: false,
                error: null,
                permissions: [],
            };
        },

        computed: {
            roles() {
                let roles = [];
                
                if (this.loading == false && this.user != null)
                    roles = this.user['https://napierport.co.nz/roles'] || [];
                
                return roles;
            },

            //there is a Auth0 issue when a user logs in for the first time (linking accounts) which means role info 
            //is not correctly returned. We are indicating this via the 'Add Auth0 roles to user' rule in Auth0
            //which sets the linkedAccountRoleIssue claim. 
            auth0LinkedAccountRolesIssue() {
                let pu = false;
                
                if (this.loading == false && this.user != null)
                    pu = this.user['https://napierport.co.nz/linkedAccountRoleIssue'] != null;

                return pu;
            },

            hasAccess() {
                const appRoles = [
                    UserRoles.PortAdmin,
                    UserRoles.CompanyAdmin,
                    UserRoles.Dispatcher,
                    UserRoles.ImportAdvisor,
                    UserRoles.ExportCoordinator,
                    UserRoles.ReportViewer,
                    UserRoles.FreightForwarder,
                    UserRoles.TransportAdmin,
                    UserRoles.TransportCoordinator,
                    UserRoles.TransportUser,
                    UserRoles.SmartFlowAdmin,
                    UserRoles.SmartFlowUser,
                ];
                console.log("User has access", this.isAuthenticated, this.roles.some(r=> appRoles.includes(r)), this.roles);
                return this.isAuthenticated && this.roles.some(r=> appRoles.includes(r));
            },
        },

        methods: {

            //publically used methods
            userHasRole(roleToCheckFor) {
                return this.userHasARole([roleToCheckFor]);
            },

            userHasARole(rolesToCheckFor) {
                return this.isAuthenticated && rolesToCheckFor.some(r=> this.roles.includes(r));
            },

            userHasPropelRole() {
                return this.roles.some(r=> r.startsWith("Propel"));
            },

            userHasSmartFlowRole() {
                return this.roles.some(r=> r.startsWith("SmartFlow"));
            },


            logout(o) {
                this.clearAnalyticsUser();
                return this.auth0Client.logout(o);
            },

            //internal privately used methods
            async loginWithPopup(o) {
                this.popupOpen = true;

                try {
                    await this.auth0Client.loginWithPopup(o);
                    this.user = await this.auth0Client.getUser();
                    this.isAuthenticated = await this.auth0Client.isAuthenticated();
                    this.error = null;
                } catch (e) {
                    console.error(e);
                    this.error = e;
                } finally {
                    this.popupOpen = false;
                }
            },
            async handleRedirectCallback() {
                this.loading = true;
                try {
                    await this.auth0Client.handleRedirectCallback();
                    this.user = await this.auth0Client.getUser();
                    this.isAuthenticated = true;
                    this.error = null;
                } catch (e) {
                    this.error = e;
                } finally {
                    this.loading = false;
                }
            },
            loginWithRedirect(o) {

                //create options object if is hasn't already been created
                if (!o) 
                    o = {};

                //these values are used by Auth0 login template to customise login for the app
                o.app_logo = window.location.origin + propelLogo;
                o.app_background_image = window.location.origin + loginBackgroundImage;
                o.app_primary_colour = this.$vuetify.theme.currentTheme.propelGreen;

                return this.auth0Client.loginWithRedirect(o);
            },
            getIdTokenClaims(o) {
                return this.auth0Client.getIdTokenClaims(o);
            },
            async getTokenSilently(o) {
                //my added code
                let result = null;

                try {
                    result = await this.auth0Client.getTokenSilently(o);
                    if(result == null)
                    {
                        console.log("getTokenSilently, no result");
                        this.isAuthenticated = false;
                    }
                    else if (this.permissions == null) {
                        this.permissions = this.retrievePermissionsFromToken(result);
                    }
                } catch (e) {
                    console.log("getTokenSilently error: ", e);
                    this.error = e;
                    this.isAuthenticated = await this.auth0Client.isAuthenticated();
                } finally {
                    // eslint-disable-next-line no-unsafe-finally
                    return result;
                }
            },
            getTokenWithPopup(o) {
                return this.auth0Client.getTokenWithPopup(o);
            },
            retrievePermissionsFromToken(token) {
                if(token != null) {
                    let apiTokenClaims = jwtDecode(token);
                    return apiTokenClaims['permissions'] || [];
                }
            },

            clearAnalyticsUser() {
                //do not want any error with analytics stopping processing
                try {
                    if(App?.$appAnalytics) {
                        App.$appAnalytics.clearAuthenticatedUser();
                    }
                } catch (e) {
                    console.error("Clearing auth user context:" + e);
                }
            },
            setAnalyticsUser() {
                //do not want any error with analytics stopping processing
                try {
                    if (App?.$appAnalytics && this.user != null) {
                        App.$appAnalytics.setAuthenticatedUser(this.user.sub, null);
                    }
                } catch (e) {
                    console.error("Setting auth user context:" + e);
                }
            },
        },

        watch: {
            isAuthenticated: function(value) {
                console.log("isAuthenticated change: ", value);
                if(this.isAuthenticated == false)
                {
                    console.log("logged out, so prompting login");
                    this.loginWithRedirect();
                }
                else if(this.isAuthenticated == true)
                {
                    console.log("loading user account");
                    this.$store.dispatch('account/fetchUserAccount');                    
                }
            },

            user: function(value) { // eslint-disable-line no-unused-vars
                if (this.user) {
                    console.log("User set");
                    this.setAnalyticsUser();
                }
            },
        },
        
        async created() {
            this.auth0Client = await createAuth0Client({
                domain: options.domain,
                client_id: options.clientId,
                audience: options.audience,
                redirect_uri: redirectUri
            });

            try {
                if (
                    window.location.search.includes("code=") &&
                    window.location.search.includes("state=")
                ) {
                    const { appState } = await this.auth0Client.handleRedirectCallback();
                    this.error = null;
                    onRedirectCallback(appState);
                }
            } catch (e) {
                this.error = e;
            } finally {
                this.isAuthenticated = await this.auth0Client.isAuthenticated();
                this.user = await this.auth0Client.getUser();
                this.loading = false;

                if(this.isAuthenticated) {
                    //there is a Auth0 issue when a user logs in for the first time (linking accounts) which means role info 
                    //is not correctly returned and we can't get the correct token via getTokenSilently(). In this situation 
                    //we need to refresh the app, and it will then get the correct token.
                    if(this.auth0LinkedAccountRolesIssue)
                    {
                        console.log("Auth0 Linked Account Roles Issue, reloading app")
                        location.reload();
                    }

                    let apiToken = await this.auth0Client.getTokenSilently();
                    this.permissions = this.retrievePermissionsFromToken(apiToken);
                }
            }
        }
    });

    return instance;
};

export const Auth0Plugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth0(options);
    }
};
