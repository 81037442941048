<template>
    <div>
        <v-card elevation="1">
            <v-toolbar class="datatableToolbar" flat>
                <v-container>
                  <v-row>
                    <v-col cols="2"><v-toolbar-title>{{ title }}</v-toolbar-title></v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <v-btn
                          v-if="collapseMenu"
                          id="btnReload"
                          tile
                          color="secondary"
                          width="100%"
                          @click="hideMenu = false"
                      >
                        Show Menu
                        <v-icon
                            right
                        >
                          fa-solid fa-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
            </v-toolbar>
            <v-container>
                <v-row>
                    <v-col cols="2" v-if="!collapseMenu">
                        <v-card elevation="3">
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-list>
                                    <v-list-item-group v-model="model">
                                      <v-list-item
                                          v-for="(item, i) in routeItems"
                                          :key="i"
                                      >
                                        <v-list-item-icon>
                                          <v-icon v-text="item.icon"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title v-text="item.text"></v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list-item-group>
                                  </v-list>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12">
                                  <v-btn
                                      id="btnReload"
                                      tile
                                      color="secondary"
                                      width="100%"
                                      @click="hideMenu = true"
                                  >
                                    <v-icon
                                        left
                                    >
                                      fa-solid fa-chevron-left
                                    </v-icon>
                                    Hide
                                  </v-btn>
                                </v-col>
                              </v-row>

                            </v-container>
                        </v-card>
                    </v-col>
                    <v-divider v-if="!collapseMenu" vertical style="display: none"></v-divider>
                    <v-col :cols="collapseMenu ? 12 : 10">
                        <component v-bind:is="items[model].component"></component>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import {defineComponent} from 'vue'
import TransportBillingsInvoices from "@/components/transportCapacity/billing/TransportBillings-Invoices.vue";
import TransportBillingsCustomerItems from "@/components/transportCapacity/billing/TransportBillings-CustomerItems.vue";
import TransportBillingsItems from "@/components/transportCapacity/billing/TransportBillings-Items.vue";
import UserRoles from "@/auth/authUserRoles";

export default defineComponent({
    name: "TransportBillings",

    components: {
        TransportBillingsInvoices,
        TransportBillingsCustomerItems,
        TransportBillingsItems,
    },

    data: () => ({
        items: [
            {
              icon: 'fa-solid fa-file-invoice-dollar',
              text: 'Invoicing',
              component: 'TransportBillingsInvoices',
              permittedRoles: [UserRoles.BillingAdmin, UserRoles.TransportAdmin, UserRoles.TransportCoordinator],
            },
            {
              icon: 'mdi-account-details',
              text: 'Customer Items',
              component: 'TransportBillingsCustomerItems',
              permittedRoles: [UserRoles.TransportAdmin, UserRoles.TransportCoordinator],
            },
            {
              icon: 'mdi-format-list-bulleted',
              text: 'Items',
              component: 'TransportBillingsItems',
              permittedRoles: [UserRoles.TransportAdmin, UserRoles.TransportCoordinator],
            },
        ],
        model: 0,
        hideMenu: false,
    }),

    computed: {
      title() {
        if (this.collapseMenu)
        {
          return this.items[this.model].text;
        } else {
          return "Transport Billing";
        }
      },

      routeItems() {
        return this.items.filter(
            route => (route.permittedRoles == null || this.$auth.userHasARole(route.permittedRoles))
        );
      },

      collapseMenu() {
        return this.routeItems.length === 1 || this.hideMenu === true;
      }
    },
})
</script>

<style scoped>

</style>