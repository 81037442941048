<template>
    <Terms v-bind:class="{ 'layout-noNavigationMenu': !$auth.hasAccess }" />
</template>

<script>
import Terms from '@/components/help/Terms.vue'


export default {
    name: 'terms',
    components: {
        Terms
    },
}
</script>