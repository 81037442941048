export default Object.freeze({
     PortAdmin: 'Propel Admin',
     CompanyAdmin: 'Propel Company Admin',
     Dispatcher: 'Propel Dispatcher',
     DriverPlus: 'Propel Driver Plus',
     Driver: 'Propel Driver',
     ImportAdvisor: 'Propel Import Advisor',
     ExportCoordinator: 'Propel Export Coordinator',
     ReportViewer: 'Propel Report Viewer',
     TransportAdmin: 'Propel Transport Administrator',
     TransportCoordinator: 'Propel Transport Coordinator',
     TransportUser: 'Propel Transport User',
     FreightForwarder: 'Propel Freight Forwarder',
     BillingAdmin: 'Propel Billing Administrator',
     SmartFlowAdmin: 'SmartFlow Admin',
     SmartFlowUser: 'SmartFlow User',
});