<template>
  <div>
    <v-card id="gateList" elevation="1">
      <v-container class="datatableControls" id="gateListTableControls">
        <br>
        <v-row>
          <v-col cols="12" sm="2" md="2" lg="2" xl="2">
            <v-text-field autocomplete="off" clearable v-model="searchText" label="Search by Gate Name" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" lg="3">
            <v-checkbox v-model="filterIncludeExpired"  label="Show Inactive" hide-details></v-checkbox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="text-right">
            <v-btn
                tile class="mb-2"
                color="secondary"
                :loading="loadingGates"
                @click="reloadGates">
              <v-icon>mdi-refresh</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn
                tile class="mb-2"
                color="secondary"
                :loading="addingGate"
                :disabled="!isPortAdmin"
                @click="addGate">
              <v-icon>mdi-plus</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">Add Gate</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <DxDataGrid
          id="gateGrid"
          :ref="dataGridRefName"
          :data-source="gateList"
          :show-borders="false"
          :hoverStateEnabled="true"
          noDataText="No current gates"
          :column-auto-width="true"
          :column-hiding-enabled="true"
      >
        <DxPaging :page-size="10"/>

        <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
        />

        <DxColumn
            data-field="gateName"
            data-type="string"
            caption="Gate Name"
            :allowSearch="true"
        />

        <DxColumn
            data-field="isFromPropel"
            caption="Data From"
            alignment="center"
            cell-template="dataFromTemplate"
        />
        <template #dataFromTemplate="{data: cellData}">
          {{ cellData.data["isFromPropel"] ? 'Propel': 'N4' }}
        </template>

        <DxColumn
            data-field="setGateConfig"
            caption="Gate Configuration"
            alignment="center"
            cell-template="gateConfig"
        />
        <template #gateConfig="{data: cellData}">
          {{ parseGateConfig(cellData) }}
        </template>

        <DxColumn
            data-field="isActive"
            caption="Gate Status"
            alignment="center"
            cell-template="gateStatus"
        />

        <template #gateStatus="{data}">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="data.data.isActive" large color="propelDarkGreen" v-bind="attrs" v-on="on">
                mdi-check-circle
              </v-icon>
              <v-icon v-else large color="error" v-bind="attrs" v-on="on">
                mdi-alert-circle
              </v-icon>
            </template>
            <span> {{ data.data.isActive ? 'Active' : 'Inactive'}}</span>
          </v-tooltip>
        </template>

        <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Action" />
        <template #actionCellTemplate="{ data }">
          <div>
            <v-icon
                color="var(--v-primary-base)"
                size="18"
                class="mr-2"
                @click="editGate(data, $event.target)"
                :disabled="!isPortAdmin"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                color="var(--v-propelDarkGrey-base)"
                size="18"
                class="mr-2"
                @click="deleteGate(data, $event.target)"
                :disabled="!isPortAdmin"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
      </DxDataGrid>
      <v-progress-linear
          v-if="loadingGates"
          indeterminate
          color="teal"
      ></v-progress-linear>
    </v-card>

    <v-dialog
        v-model="gateFormDialog"
        persistent
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="500px"
    >
      <gate-form
          ref="gateFormComponent"
          :key="`${componentKey}=1`"
          @close="closeGateForm(false)"
          @closeAndReload="closeGateForm(true)"
      >
      </gate-form>
    </v-dialog>
  </div>
</template>

<script>
import {DxDataGrid, DxPaging, DxPager, DxColumn} from "devextreme-vue/data-grid";
import {mapActions, mapState, mapMutations} from "vuex";
import { GateConfig } from '@/utils/constants.js'
import gateForm from "@/components/portAdmin/gate/GateForm";
export default {
  name: "GateList",

  props: {
    isPortAdmin: Boolean,
  },

  components: {
    DxDataGrid,
    DxPaging,
    DxPager,
    DxColumn,
    gateForm
  },

  data() {
    return {
      //Grid Config
      searchText: '',
      filterIncludeExpired: false,
      dataGridRefName: 'gateGrid',
      pageSizes: [10, 25, 50, 100],

      //Dialog
      gateFormDialog: false,
      gateFormDialogInitialLoad: true,


      //Component
      componentKey: 0,
    }
  },

  mounted() {
    this.reloadGates();
  },

  computed: {
    ...mapState('gateManagement', [
        'gates',
        'vx_loadingGates',
        'vx_addingGate',
    ]),

    loadingGates() {
      return this.vx_loadingGates;
    },

    gateList() {
      return this.gates;
    },

    addingGate() {
      return this.vx_addingGate;
    },
  },

  watch: {
    filterIncludeExpired: function (val) {
      if (val)
        this.fetchAllGates();
      else {
        this.fetchActiveGates();
      }
    },

    searchText: function (newVal) { // eslint-disable-line no-unused-vars
      this.$refs[this.dataGridRefName]["instance"].searchByText(newVal?.trim());
    },
  },

  methods: {
    ...mapActions('gateManagement', [
        'fetchActiveGates',
        'fetchAllGates',
        'removeGate'
    ]),

    ...mapMutations('gateManagement', [
        'setGateFormMode'
    ]),

    // Misc functions
    parseGateConfig(data) {
      return GateConfig[data.data["setGateConfig"]];
    },

    reloadGates() {
      this.filterIncludeExpired  === true ? this.fetchAllGates() : this.fetchActiveGates();
    },

    gateEditIconProcessing(target, value) {
      if (value)
      {
        target.classList.remove("mdi-pencil");
        target.classList.add("mdi-loading", "icon-spinner");
      }
      else
      {
        target.classList.remove("mdi-loading", "icon-spinner");
        target.classList.add("mdi-pencil");
      }
    },

    gateDeleteIconProcessing(target, value) {
      if (value)
      {
        target.classList.remove("mdi-delete");
        target.classList.add("mdi-loading", "icon-spinner");
      }
      else
      {
        target.classList.remove("mdi-loading", "icon-spinner");
        target.classList.add("mdi-delete");
      }
    },

    // Dialog button functions
    closeGateForm(reload) {
      this.gateFormDialog = false;

      if(reload)
        this.reloadGates();
    },

    async addGate() {
      App["$appAnalytics"].trackEvent('GM - Add Gate - Click');
      await this.setGateFormMode('add');

      if (!this.gateFormDialogInitialLoad)
        this.componentKey += 1;

      this.gateFormDialog = true; //Show dialog
      this.gateFormDialogInitialLoad = false;
    },

    async editGate(item, target) {
      App["$appAnalytics"].trackEvent('GM - Edit Gate - Click');
      this.gateEditIconProcessing(target, true);

      await this.setGateFormMode('edit');

      if (!this.gateFormDialogInitialLoad)
        this.componentKey += 1;

      this.gateFormDialog = true; //Show dialog
      await this.$nextTick();
      await this.$refs.gateFormComponent.edit(item.data);
      this.gateEditIconProcessing(target, false);
    },

    async deleteGate(item, target) {
      this.gateDeleteIconProcessing(target, true);
      if (await this.$root["$confirm"].open('Delete gate?', 'Are you sure you want to delete this gate?', { color: 'warning' }))
      {
        App["$appAnalytics"].trackEvent('GM - Delete Gate - Click');
        this.removeGate(item.data.gateId)
            .then(() => {
              window.App.$emit('show-snackbar', "success", "Gate deleted");
              this.reloadGates();
            })
            .finally(() => {
              this.gateDeleteIconProcessing(target, false);
            });
      }
      else
      {
        this.gateDeleteIconProcessing(target, false);
      }
    }

  },
}
</script>

<style scoped>

</style>