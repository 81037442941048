import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {   
            //https://www.colorabout.com/color/hex/c0d830
            //https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=c0d830&secondary.color=4630d8
            light: {
                primary: '#4630d8',     //dark blue
                secondary: '#4630d8',   //dark blue
                accent: '#9a30d8',      //dark blue
                warning: '#d89a30',     //purple
                error: '#d8303b',       //red
                propelGreen: '#c0d830',
                propelLightGreen: '#cde059',
                propelDarkGreen: '#8ca700',
                propelDarkGrey: '#595859'
            },
        },
    },
});
