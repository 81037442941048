import utils from '@/utils/vuexUtils.js'
import transportBillingData from "@/models/transportCapacity/transportBillingData";

const state = {
    // Objects
    billingItemTypes: [],
    items: [],
    billingCustomerItems: [],
    selectedInvoices: [],

    // Loading indicators
    loadingBillingItemTypes: false,
    loadingItems: false,
    loadingBillingCustomerItems: false,

    // Working Objects
    workingBillingItem: new transportBillingData.BillingItemData(),
}

// getters
const getters = {
}

// actions
const actions = {
    fetchBillingItems: ({commit, state}) => {
        state.loadingItems = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchBillingItems(), 'setItems');
    },

    fetchBillingItemTypes: ({commit, state}) => {
        state.loadingBillingItemTypes = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchBillingItemTypes(), 'setBillingItemTypes');
    },

    fetchBillingCustomerItems: ({commit, state}, customerId) => {
        state.loadingBillingCustomerItems = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchBillingCustomerItems(customerId), 'setBillingCustomerItems');
    },

    fetchBillingCustomerItemsOfSecondaryCustomer: ({commit, state}, {customerId, forwarderId}) => {
        state.loadingBillingCustomerItems = true;
        return utils.CommitArrayPromise(commit, CMSApi.fetchBillingCustomerItemsOfSecondaryCustomer(customerId, forwarderId), 'setBillingCustomerItems');
    },

    resetWorkingBillingItem: ({commit}) => {
        let newBillingItem = new transportBillingData.BillingItemData();
        commit('setCurrentWorkingBillingItem', newBillingItem);
    },
}

// mutations
const mutations = {
    setBillingItemTypes: (state, billingItemTypes) => {
        state.billingItemTypes = billingItemTypes;
        state.loadingBillingItemTypes = false;
    },

    setItems: (state, items) => {
        state.items = items;
        state.loadingItems = false;
    },

    setBillingCustomerItems: (state, billingCustomerItems) => {
        state.billingCustomerItems = billingCustomerItems;
        state.loadingBillingCustomerItems = false;
    },

    setCurrentWorkingBillingItem: (state, billingItem) => {
        state.workingBillingItem = billingItem;
    },

    setSelectedInvoices: (state, selectedInvoices) => {
        state.selectedInvoices = selectedInvoices;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}