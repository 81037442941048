class BillingItemData {
    constructor() {
        this.itemCode = null;
        this.billingItemType = null;
        this.description = null;
        this.FAF_Percentage = null;
        this.TUC_Percentage = null;
        this.KR_UnitIso = null;
        this.KR_Origin = null;
        this.KR_Destination = null;
        this.KR_Cartage = null;
        this.RoadBridgeCode = null;
    }
}


export default {
    BillingItemData : function() {
        this.billingItemData = new BillingItemData();

        this.convertToSubmittable = function() {
            // flatten it all out
            return {
                itemCode : this.billingItemData.itemCode,
                billingItemType : this.billingItemData.billingItemType,
                description : this.billingItemData.description,
                FAF_Percentage : this.billingItemData.FAF_Percentage,
                TUC_Percentage : this.billingItemData.TUC_Percentage,
                KR_UnitIso : this.billingItemData.KR_UnitIso,
                KR_Origin : this.billingItemData.KR_Origin,
                KR_Destination : this.billingItemData.KR_Destination,
                KR_Cartage : this.billingItemData.KR_Cartage,
                RoadBridgeCode : this.billingItemData.RoadBridgeCode
            }
        }
    }
}