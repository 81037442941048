<template>
    <div>
        <InboundVesselsSlider id="inboundVesselsSlider" :inboundVessels="inboundVessels" mode="export" />

        <v-card id="exportPreAdvisedContainerList" outlined>
            <v-toolbar class="datatableToolbar" flat>
                <v-toolbar-title>Export Pre-Advised Containers</v-toolbar-title>

                <div class="flex-grow-1"></div>

                <v-btn
                    tile color="secondary"
                    :loading="loadingExportPreAdvisedContainersData"
                    @click="refreshData">
                    <v-icon>mdi-refresh</v-icon>
                    <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
                </v-btn>
                <v-btn
                    tile outlined
                    color="secondary"
                    :disabled="loadingExportPreAdvisedContainersData"
                    @click="exportXlsFile">
                    <v-icon>mdi-file-excel-outline</v-icon>
                    <span v-if="$vuetify.breakpoint.mdAndUp">Export XLS</span>
                </v-btn>
            </v-toolbar>

            <v-container class="datatableControls">
                <v-row>
                    <v-col cols="12" sm="6" md="5" lg="4" xl="4">
                        <v-text-field clearable v-model="searchText" label="Search by Container or Booking #"
                                      append-icon="mdi-magnify" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="6" md="5" lg="4" xl="4" align="right">
                        <v-btn tile color="secondary"
                               :block=$vuetify.breakpoint.xs
                               @click.stop="addPreAdvice"
                               :loading="addingPreAdvice"
                               :disabled="loadingExportPreAdvisedContainersData"
                               class="mb-3"
                        >
                            <v-icon>mdi-plus</v-icon>&nbsp;
                            <span>New Export Pre-Advice</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>

            <div id="exportPreAdvisedContainersSection">
                <ExportPreAdviceContainerList
                    ref="exportPreAdviceContainerList"
                    :searchText="searchText"
                    :loading="loadingExportPreAdvisedContainersData"
                    @edit="editAdvice"
                />
            </div>

        </v-card>

        <v-dialog v-model="openDialog" width="800" persistent ref="preAdviceDialog">
            <!-- Add a v-if on the stepper as well, to make sure it gets "destroyed" when the dialog closes. -->
            <SingleExportPreAdvice
                ref="singleExportPreAdvice"
                v-if="exportPreAdviceAlive"
                @cancel="closeDialog"
                @close="closeDialog"
            >
            </SingleExportPreAdvice>
        </v-dialog>
        
        <v-overlay v-if="loadingEditData">
            <v-progress-circular
                indeterminate
                size="64"
            >
            </v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import InboundVesselsSlider from "@/components/vessels/InboundVesselsSlider.vue";
import ExportPreAdviceContainerList from "@/components/exportPreAdvice/ExportPreAdviceContainerList.vue";
import SingleExportPreAdvice from "@/components/exportPreAdvice/SingleExportPreAdvice.vue";
import {mapState, mapActions} from "vuex";

export default {
    components: {
        InboundVesselsSlider,
        ExportPreAdviceContainerList,
        SingleExportPreAdvice,
    },

    data() {
        return {
            loadingExportPreAdvisedContainersData: false,
            searchText: '',
            addingPreAdvice: false,
            loadingEditData: false,
            openDialog: false,
            autoRefreshTimer: null,
            
            // Use a separate boolean to destroy the data of the EPA on closing. We do this with a small delay
            // so that the dialog close animation is still working
            exportPreAdviceAlive: false
        }
    },
    
    computed: {
        ...mapState('vessels', [
            'inboundVessels',
        ]),
    },

    mounted() {
        App.$appAnalytics.trackEvent('EPA - Export Pre-Advice list page opened');
        this.fetchContainers();
        this.fetchInboundVessels();
        this.fetchExportPreAdviceShippingLineGroups();
    },

    created() {
        var self = this;

        //need to refresh inbound vessel info every 5 minutes
        this.refreshInboundVesselsIntervalId = setInterval(function () {
            self.fetchInboundVessels(true);
        }, 5 * 60 * 1000)
    },

    beforeDestroy() {
        clearInterval(this.refreshInboundVesselsIntervalId);
    },
    
    methods: {
        ...mapActions('exportPreAdvice', [
            'fetchExportPreAdviceContainers',
            'setWorkingAdviceDataForEdit',
            'fetchExportPreAdviceShippingLineGroups'
        ]),

        ...mapActions('vessels', [
            'fetchInboundVessels'
        ]),

        refreshData() {
            App.$appAnalytics.trackEvent('EPA - Refresh List - Click');
            this.fetchContainers();
            this.fetchInboundVessels();
            this.fetchExportPreAdviceShippingLineGroups();
        },

        fetchContainers() {
            this.loadingExportPreAdvisedContainersData = true;
            if (this.autoRefreshTimer) {
                clearTimeout(this.autoRefreshTimer);
            }

            this.fetchExportPreAdviceContainers()
                .finally(() => {
                    this.loadingExportPreAdvisedContainersData = false;
                    // And set a timer to load them again in 5 minutes.
                    this.autoRefreshTimer = setTimeout(this.autoRefreshContainerList, 5 * 60 * 1000);
                });
        },
        
        autoRefreshContainerList() {
            if (!this.openDialog) {
                this.fetchContainers();
            }
            // else {
                // Dialog currently open. Nothing to do. Refresh will then happen when the dialog is closed and then
                // schedule another auto refresh as well.
        },

        exportXlsFile() {
            App.$appAnalytics.trackEvent('EPA - Export List - Click');
            this.$refs.exportPreAdviceContainerList.exportXlsFile();
        },

        addPreAdvice() {
            App.$appAnalytics.trackEvent('EPA - Add Advice - Click');
            // Cancel any planned auto refresh of the list. Refresh of the list will happen automatically again
            // when the dialog is closed.
            if (this.autoRefreshTimer) {
                clearTimeout(this.autoRefreshTimer);
            }
            this.exportPreAdviceAlive = true;
            this.openDialog = true;
        },

        closeDialog() {
            this.openDialog = false;
            // Delay the destruction of the ExportPreAdvice component, so that the "close animation" of the dialog
            // is still what it should be
            setTimeout(() => {this.exportPreAdviceAlive = false;}, 1000);
            // And update the list then, to process any changes that may have happened before the dialog was closed. Note that
            // it is quite possible to add / update a container, then click to advise another one, but then "cancel" that second one.
            // So even on Cancel, there can be changes that are not showing yet in the list.
            this.fetchContainers();
        },

        editAdvice(preAdviceData) {
            App.$appAnalytics.trackEvent('EPA - Edit Advice - Click');
            this.loadingEditData = true;
            CMSApi.fetchExportPreAdviceDetails(preAdviceData.containerNumber)
                .then(foundContainer => {
                    this.setWorkingAdviceDataForEdit(foundContainer);
                    // Cancel any planned auto refresh of the list. This will happen automatically again
                    // when the dialog is closed.
                    if (this.autoRefreshTimer) {
                        clearTimeout(this.autoRefreshTimer);
                    }
                    this.exportPreAdviceAlive = true;
                    this.openDialog = true;
                })
                .finally(() => {
                    this.loadingEditData = false;
                });
        }
    }
};
</script>

<style scoped>
#inboundVesselsSlider {
    margin-bottom: 15px;
}

#exportPreAdvisedContainersSection {
    overflow: auto;
}

</style>