<template>
    <v-form ref="bookingDetailsForm" v-model="stepState.formIsValid" @submit.prevent>
        <div class="stepperStepTitle">{{ titleBase }} (2/4)</div>
        <v-row>
            <v-col cols="3" v-if="showSummary">
                <export-pre-advice-summary :showBookingData="false"></export-pre-advice-summary>
            </v-col>
            <v-divider vertical v-if="showSummary"></v-divider>
            <v-col cols="12" sm="9">
                <v-row>
                    <v-col cols="6">
                        <div id="exportPreAdviceBookingNumberInput">
                            <v-text-field
                                ref="bookingNumberInput"
                                v-model="stepState.bookingSearch"
                                label="Booking Number*"
                                type="text"
                                v-input-upper-alpha-numeric
                                required
                                :readonly="stepState.isUpdateOfExisting"
                                :loading="loadingMatchingBooking"
                                :rules="stepState.bookingNumberRules"
                                :error-messages="stepState.bookingNumberError"
                                :messages="stepState.mismatchShippingMessage"
                                dense
                                validate-on-blur
                                @blur="bookingNumberBlur"
                                @keyup.enter="bookingNumberBlur"
                                @focus="bookingNumberFocus"
                                tabindex="1"
                            >
                            </v-text-field>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row v-if="stepState.showBookingDetailSelect">
                    <v-col cols="12">
                        <v-select
                            ref="bookingDetailSelect"
                            v-model="stepState.selectedBookingDetails"
                            :items="stepState.bookingDetailsList"
                            item-text="bookingDetailSpecification"
                            item-value="n4BookingKey"
                            label="Booking Details"
                            dense
                            required
                            return-object
                            :rules="stepState.bookingDetailsRules"
                            tabindex="2"
                            @input="bookingDetailSelected"
                        >
                        </v-select>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <label class="v-label theme--light formMinimisedLabel">Vessel Visit</label>
                        <p class="text--primary pl-3">{{ bookingData.vesselVisit }}</p>
                    </v-col>
                    <v-col cols="6">
                        <label class="v-label theme--light formMinimisedLabel">Line Operator</label>
                        <p class="text--primary pl-3">{{ bookingData.lineOperator }}</p>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <label class="v-label theme--light formMinimisedLabel">Port of Load</label>
                        <p class="text--primary pl-3">{{ bookingData.portOfLoad }}</p>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="6">
                        <label class="v-label theme--light formMinimisedLabel">Second Port of
                            Discharge</label>
                        <p class="text--primary pl-3">{{ bookingData.secondPortOfDischarge }}</p>
                    </v-col>
                    <v-col cols="6">
                        <label class="v-label theme--light formMinimisedLabel">Port of Discharge</label>
                        <p class="text--primary pl-3">{{ bookingData.portOfDischarge }}</p>
                    </v-col>
                </v-row>
                <v-row v-if="stepState.vesselClosed">
                    <v-col cols="12">
                        <div id="closedMessage">
                            <p>EXPORTS FOR THIS VESSEL ARE NOW CLOSED</p>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" sm="4">
                <v-btn
                    color="secondary"
                    plain
                    @click="cancel"
                    tabindex="4"
                >
                    Cancel
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="9" sm="8" class="text-right">
                <v-btn
                    color="secondary"
                    plain
                    @click="previous"
                    tabindex="3"
                >
                    Previous
                </v-btn>
                <v-btn
                    color="secondary"
                    plain
                    @click="next"
                    tabindex="2"
                    :disabled="stepState.vesselClosed"
                >
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import ExportPreAdviceSummary from "@/components/exportPreAdvice/ExportPreAdviceSummary.vue";
import {mapGetters, mapActions, mapState} from "vuex";
import generalHelpers from '@/utils/generalHelpers.js'

export default {
    name: "ExportBookingDetails.vue",
    mixins: [generalHelpers],

    props: {
        titleBase: {
            type: String,
            required: true
        },
    },

    components: {
        ExportPreAdviceSummary
    },

    data() {
        return {
            minCharsForFetchingMatchingBooking: 4,
            unknownBookingNumberText: 'Napier Port has not (yet) been advised of this booking',
            noTemperatureOnReeferBookingText: 'This booking is for a reefer, but does not have a temperature setting. Please update the booking first',
            bookingOnlyFor40FootContainersErrorText: 'This booking was made for 40 foot containers only, but you have selected a 20 foot container type in step 1',
            bookingOnlyFor20FootContainersErrorText: 'This booking was made for 20 foot containers only, but you have selected a 40 foot container type in step 1',
            bookingNotForSpecifiedContainerTypeErrorText: 'This booking was not made for the container type you selected in step 1',

            stepState: {
                bookingNumberRules: [
                    val => this.validateBookingNumber(val)
                ],
                debouncedLookupBookingTimerId: null,

                bookingDetailsList: null,
                selectedBookingDetails: null,
                bookingDetailsRules: [
                    val => !!val || 'You need to select the details for this booking'
                ],

                isUpdateOfExisting: false,
                isDataInit: false,

                bookingSearch: '',
                bookingSearchUpdated: false,
                bookingNumberError: '',
                mismatchShippingMessage: '',
                mismatchShippingConfirmed: false,
                pendingFindMatchingBooking: 0,
                showBookingDetailSelect: false,

                vesselClosed: false,

                formIsValid: false,
            },
        }
    },

    computed: {
        ...mapState('exportPreAdvice', [
            'shippingLines'
        ]),

        ...mapGetters('exportPreAdvice', [
            'workingContainerDetails',
            'workingBookingDetails',
            'workingAdditionalContainerDetails'
        ]),
        showSummary() {
            return !this.$vuetify.breakpoint.xs;
        },
        containerData() {
            return this.workingContainerDetails;
        },
        bookingData() {
            return this.workingBookingDetails;
        },
        additionalData() {
            return this.workingAdditionalContainerDetails;
        },
        loadingMatchingBooking() {
            return this.stepState.pendingFindMatchingBooking > 0;
        },
        shippingLinesMatch() {
          return this.shippingLines;
        },
    },

    mounted() {
        if (this.bookingData.bookingNumber) {
            this.stepState.isUpdateOfExisting = true;
            this.stepState.isDataInit = true;
            this.stepState.bookingSearch = this.bookingData.bookingNumber;
        }
    },

    watch: {
        'stepState.bookingSearch'() {
            // As soon as the user types something, we remove what we had potentially already set earlier
            // Except for when this is a data initialisation for example because of editing an existing one
            if (!this.stepState.isDataInit) {
                this.resetBookingData();
                this.lookupBookingDataDebounced();
                this.stepState.mismatchShippingConfirmed = false;

                // And record that there was an update so that we know we have to find a match again on blur
                this.stepState.bookingSearchUpdated = true;
            } else {
                this.stepState.isDataInit = false;
            }

            // Always remove a potentially previously set "unknown booking" error message:
            this.stepState.bookingNumberError = '';
        },
        'containerData.equipmentTypeIsoId'(val) {
            if (val && this.stepState.bookingSearch) {
                this.stepState.bookingNumberError = '';
                this.stepState.selectedBookingDetails = null;
                
                // We already had an existing booking, but they changed the container iso type, so we may need
                // to get the details list again.
                clearTimeout(this.stepState.debouncedLookupBookingTimerId);

                this.fetchBookingData();                
            }
        },
        'bookingData.lineOperator'(val) {
            if (val && val != "-" && !this.containerData.containerNumberIsNewForN4) {

                if (this.isExistInShippingLineMatches(val) && this.isExistInShippingLineMatches(this.containerData.lineOperator) ) {
                    
                    if (!this.belongInTheSameShippingLineGroup(val, this.containerData.lineOperator)) {
                        this.stepState.mismatchShippingMessage = "Shipping line does not match for Container and Booking Reference"
                    }

                } else {
                    if (val != this.containerData.lineOperator) {
                        this.stepState.mismatchShippingMessage = "Shipping line does not match for Container and Booking Reference"
                    }
                }
            }
        }
    },

    methods: {
        ...mapActions('exportPreAdvice', [
            'setWorkingBookingData',
            'resetWorkingExportPreAdvice',
        ]),

        isExistInShippingLineMatches(val) {
            return this.shippingLinesMatch.find(o => o.name === val) !== undefined ? true : false;
        },

        belongInTheSameShippingLineGroup(bookingLine, containerLine) {
            let bookingShippingLineGroup = this.shippingLinesMatch.find(o => o.name === bookingLine).group;
            let containerShippingLineGroup = this.shippingLinesMatch.find(o => o.name === containerLine).group;

            return bookingShippingLineGroup === containerShippingLineGroup;
        },

        setFocusOnFirstField() {
            if (!this.stepState.isUpdateOfExisting) {
                this.$refs.bookingNumberInput.focus();
            }
            // else: An existing EPA: no field to focus on. The booking number cannot be edited
        },

        bookingNumberFocus() {
            this.stepState.mismatchShippingMessage = null;
        },

        lookupBookingDataDebounced() {
            // cancel any pending call to find the booking, the number string has already changed.
            clearTimeout(this.stepState.debouncedLookupBookingTimerId);

            // delay new call 2000ms and only lookup if min number of chars typed
            const lookupBookingNumber = this.stepState.bookingSearch?.trim();

            if (lookupBookingNumber && lookupBookingNumber.length >= this.minCharsForFetchingMatchingBooking) {
                this.stepState.debouncedLookupBookingTimerId = setTimeout(() => {
                    this.fetchBookingData();
                }, 2000);
            }
        },

        fetchBookingData() {
            const lookupBookingNumber = this.stepState.bookingSearch?.trim();

            if (lookupBookingNumber && lookupBookingNumber.length >= this.minCharsForFetchingMatchingBooking) {

                this.stepState.bookingSearchUpdated = false;    // We are now processing the latest update

                const bookingSearchIsValid = this.validateBookingNumber(lookupBookingNumber);

                if (typeof bookingSearchIsValid === 'boolean' && bookingSearchIsValid) {
                    this.stepState.pendingFindMatchingBooking++;
                    const bookingNumber = lookupBookingNumber;     // remember the one we used to look up

                    CMSApi.fetchExportPreAdviceBookingWithNumber(bookingNumber)
                        .then(data => {
                            // Only process this data if it is still a response to the current text typed
                            if (bookingNumber === this.stepState.bookingSearch?.trim()) {
                                if (data && data.length > 0) {
                                    // Found the booking in N4
                                    this.setBookingData(data);
                                } else {
                                    this.stepState.bookingNumberError = this.unknownBookingNumberText;
                                }
                            }
                        })
                        .catch(error => {
                            // Only show an error if it is still a response to the current text typed
                            if (bookingNumber === this.stepState.bookingSearch?.trim()) {
                                if (error.response && error.response.data && error.response.data.errors.bookingNumber) {
                                    this.stepState.bookingNumberError = error.response.data.errors.bookingNumber[0];
                                }
                            }
                        })
                        .finally(() => {
                            this.stepState.pendingFindMatchingBooking--;
                        });
                }
            }
        },

        bookingNumberBlur() {
            if (this.stepState.bookingSearchUpdated) {
                // Clear any pending debounced fetch that was started because the user stopped typing. We
                // want to look this up now immediately
                clearTimeout(this.stepState.debouncedLookupBookingTimerId);

                this.fetchBookingData();
            }
        },

        setBookingData(selectedBookings) {
            if (selectedBookings.length > 1) {
                const containerLengthFilter = this.containerData.equipmentTypeIsoId[0];
                const filteredBookingList = selectedBookings.filter(b => b.iso && b.iso[0] === containerLengthFilter);

                if (!filteredBookingList || filteredBookingList.length === 0) {
                    // user selected incorrect container type for this booking
                    if (containerLengthFilter === '2') {
                        this.stepState.bookingNumberError = this.bookingOnlyFor40FootContainersErrorText;
                    } else if (containerLengthFilter === '4') {
                        this.stepState.bookingNumberError = this.bookingOnlyFor20FootContainersErrorText;
                    } else {
                        this.stepState.bookingNumberError = this.bookingNotForSpecifiedContainerTypeErrorText;
                    }
                } else {
                    this.stepState.bookingDetailsList = filteredBookingList;
                    if (filteredBookingList.length === 1 ){
                        this.stepState.selectedBookingDetails = filteredBookingList[0];
                        this.setAdditionalBookingData(filteredBookingList[0]);
                    }
                    // Show the selection always, even if the list was filtered down to 1 option only
                    this.stepState.showBookingDetailSelect = true;
                }
            } else {
                const bookingDetails = selectedBookings[0];

                const vesselOpenPhases = ['inbound', 'working'];
                this.stepState.vesselClosed = !(vesselOpenPhases.includes(bookingDetails.vesselPhase.toLowerCase()));

                if (!this.stepState.vesselClosed) {
                    this.setAdditionalBookingData(bookingDetails);
                }
            }
        },

        setAdditionalBookingData(selectedBooking) {
            // If the booking is a reefer booking, but there is no temperature setting specified, then this is an error
            if (selectedBooking.isReefer && (!this.hasNonEmptyValue(selectedBooking.temperatureRequired) || isNaN(selectedBooking.temperatureRequired))) {
                this.stepState.bookingNumberError = this.noTemperatureOnReeferBookingText;
                return;
            }

            this.bookingData.bookingNumber = selectedBooking.bookingNumber;
            this.bookingData.n4BookingKey = selectedBooking.n4BookingKey;
            this.bookingData.vesselVisit = selectedBooking.vesselVisit;
            this.bookingData.lineOperator = selectedBooking.lineOperator;
            this.bookingData.portOfLoad = selectedBooking.portOfLoadCodeAndName;
            this.bookingData.portOfDischarge = selectedBooking.portOfDischargeCodeAndName;
            this.bookingData.secondPortOfDischarge = selectedBooking.secondPortOfDischargeCodeAndName;
            this.bookingData.isHazardous = selectedBooking.isHazardous;
            this.bookingData.agent = selectedBooking.agent;

            this.additionalData.isHazardous = selectedBooking.isHazardous;
            if (selectedBooking.isHazardous) {
                this.additionalData.hazardData.undgNumber = selectedBooking.undgNumber;
                this.additionalData.hazardData.imdgClass = selectedBooking.imdgClass;
            } else {
                this.additionalData.hazardData.undgNumber = null;
                this.additionalData.hazardData.imdgClass = null;
            }

            this.additionalData.isReefer = selectedBooking.isReefer;
            if (selectedBooking.isReefer) {
                if (this.hasNonEmptyValue(selectedBooking.temperatureRequired) && !isNaN(selectedBooking.temperatureRequired)) {
                    this.additionalData.reeferData.temperature = selectedBooking.temperatureRequired;
                } else {
                    this.additionalData.reeferData.temperature = null;
                }

                if (this.hasNonEmptyValue(selectedBooking.ventilationRequired) && !isNaN(selectedBooking.ventilationRequired)) {
                    this.additionalData.reeferData.ventilation = selectedBooking.ventilationRequired;
                    this.additionalData.reeferData.ventilationUnit = selectedBooking.ventilationUnit;
                } else {
                    this.additionalData.reeferData.ventilation = null;
                    this.additionalData.reeferData.ventilationUnit = null;
                }

                if (this.hasNonEmptyValue(selectedBooking.humidityRequired) && !isNaN(selectedBooking.humidityRequired)) {
                    this.additionalData.reeferData.humidity = selectedBooking.humidityRequired;
                } else {
                    this.additionalData.reeferData.humidity = null;
                }
            } else {
                this.additionalData.reeferData.temperature = null;
                this.additionalData.reeferData.ventilation = null;
                this.additionalData.reeferData.ventilationUnit = null;
                this.additionalData.reeferData.humidity = null;
            }
        },

        bookingDetailSelected(selectedBooking) {
            this.setAdditionalBookingData(selectedBooking);
        },

        resetBookingData() {
            this.stepState.bookingDetailsList = null;
            this.stepState.selectedBookingDetails = null;
            this.stepState.showBookingDetailSelect = false;

            this.bookingData.bookingNumber = null;
            this.bookingData.vesselVisit = '-';
            this.bookingData.lineOperator = '-';
            this.bookingData.portOfLoad = '-';
            this.bookingData.portOfDischarge = '-';
            this.bookingData.secondPortOfDischarge = '-';

            this.stepState.vesselClosed = false;
            this.stepState.mismatchShippingConfirmed = false;

            this.additionalData.isReefer = false;
            this.additionalData.reeferData.temperature = null;
            this.additionalData.reeferData.ventilation = null;
            this.additionalData.reeferData.ventilationUnit = null;
            this.additionalData.reeferData.humidity = null;
        },

        validateBookingNumber(val) {
            val = val?.trim();
            let rulesToCheck = [
                val => !!val || 'Booking Number is required',
                val => !val || val.length >= 4 || 'Not a valid Booking Number',
                val => !val || val.length < 30 || 'A Booking Number must be less than 30 chars',
                val => !val || /^[A-Za-z0-9]+$/.test(val) || 'A Booking Number can only contain letters and numbers'
            ];

            return window.App.checkValidationRules(val, rulesToCheck);
        },

        resetFormForAdviseAnother(isSameBooking) {
            if (!isSameBooking) {
                this.stepState.bookingSearch = '';
            }

            this.stepState.isUpdateOfExisting = false;
            this.stepState.mismatchShippingConfirmed = false;

            this.$refs.bookingDetailsForm.resetValidation();
        },

        async next() {
            // First make sure the form is valid.
            this.formIsValid = this.$refs.bookingDetailsForm.validate();

            // Show warning message if the Container Number is known in N4 and mismatch in Shipping Line against the Booking Reference
            if (!this.containerData.containerNumberIsNewForN4 && this.bookingData.lineOperator != this.containerData.lineOperator && !this.stepState.mismatchShippingConfirmed && !this.stepState.isUpdateOfExisting) {

                if ( (this.isExistInShippingLineMatches(this.bookingData.lineOperator) && this.isExistInShippingLineMatches(this.containerData.lineOperator)) ) {

                    if (!this.belongInTheSameShippingLineGroup(this.bookingData.lineOperator, this.containerData.lineOperator)) {
                        if (await this.$root.$confirm.open('Proceed?', 'The shipping line for booking reference '+this.stepState.bookingSearch+' does not match the previous shipping line for container ' + this.containerData.containerNumber, { color: 'warning' }))
                        {
                            // And that we have an existing booking against the typed booking number
                            if (this.formIsValid && this.bookingData.bookingNumber && !this.stepState.bookingNumberError) {
                                this.stepState.mismatchShippingConfirmed = true;
                                this.setWorkingBookingData(this.bookingData);
                                this.$emit("next");
                            }
                        } else {
                            this.setFocusOnFirstField();
                        }
                    } else {
                        // And that we have an existing booking against the typed booking number
                        if (this.formIsValid && this.bookingData.bookingNumber && !this.stepState.bookingNumberError) {
                            this.setWorkingBookingData(this.bookingData);
                            this.$emit("next");
                        }
                    }

                } else {

                    if (await this.$root.$confirm.open('Proceed?', 'The shipping line for booking reference '+this.stepState.bookingSearch+' does not match the previous shipping line for container ' + this.containerData.containerNumber, { color: 'warning' }))
                    {
                        // And that we have an existing booking against the typed booking number
                        if (this.formIsValid && this.bookingData.bookingNumber && !this.stepState.bookingNumberError) {
                            this.stepState.mismatchShippingConfirmed = true;
                            this.setWorkingBookingData(this.bookingData);
                            this.$emit("next");
                        }
                    } else {
                        this.setFocusOnFirstField();
                    }
                }

            } else {
                // And that we have an existing booking against the typed booking number
                if (this.formIsValid && this.bookingData.bookingNumber && !this.stepState.bookingNumberError) {
                    this.setWorkingBookingData(this.bookingData);
                    this.$emit("next");
                }
            }
        },

        previous() {
            // Does not need to be valid (yet) if the user decides to do a step back 
            this.setWorkingBookingData(this.bookingData);
            this.$emit("previous");
        },

        cancel() {
            this.resetWorkingExportPreAdvice();
            this.$emit("cancel");
        },
    }
}
</script>

<style>
#exportPreAdviceBookingNumberInput .v-messages:not(.error--text) {
    color: var(--v-warning-base);
}

#closedMessage {
    color: var(--v-error-base);
}
</style>
