<template>
  <v-card elevation="3">
    <v-card-text>
      <DxDataGrid
          :ref="reportUserGridRefKey"
          :data-source="reportData.reportUsers"
          key-expr="userId"
          :show-borders="true"
          :column-auto-width="true"
          :word-wrap-enabled="true"
          :height="500"
          @selection-changed="onSelectionChanged"
          @content-ready="contentReady"
          :loading="loadingReportUsers"
      >
        <DxSelection
            mode="multiple"
            select-all-mode="page"
        />

        <DxSorting mode="none"/>
        <DxScrolling mode="virtual"/>

        <DxColumn
            data-field="lastName"
            data-type="string"
            caption="Lastname"
        />
        <DxColumn
            data-field="firstName"
            data-type="string"
            caption="Firstname"
        />
        <DxColumn
            data-field="emailAddress"
            data-type="string"
            caption="Email"
        />
        <DxColumn
            data-field="selected"
            caption="Access"
            cell-template="showAccess"
        />
        <template #showAccess="{data: cellData}">
          <div v-if="cellData.data.selected" style="color: green">
            Yes
          </div>
          <div v-else style="color: red">
            No
          </div>
        </template>

      </DxDataGrid>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  DxDataGrid,
  // DxRowDragging,
  DxSorting,
  DxScrolling,
  DxColumn,
  DxSelection
} from "devextreme-vue/data-grid";
import {mapGetters, mapState} from "vuex";
export default {
  name: "ReportForm-Users",

  components: {
    DxDataGrid,
    DxSelection,
    DxSorting,
    DxScrolling,
    DxColumn
  },

  data() {
    return {
      // Grid
      reportUserGridRefKey: 'data-grid-user',
      selectedUsers: [],
      selectionChangedBySelectBox: false,
    }
  },

  computed: {
    ...mapState('reportManagement', [
        'loadingReportUsers'
    ]),

    ...mapGetters('reportManagement', [
      'workingReport'
    ]),

    reportData() {
      return this.workingReport.reportDetails;
    },

    usersFilled() {
      return this.selectedUsers.length > 0;
    }
  },

  watch: {
    usersFilled(val) {
      return val ? this.$emit('filled') : this.$emit('unfilled');
    }
  },

  methods: {
    // Grid function
    onSelectionChanged({ selectedRowsData }) {
      if (!this.selectionChangedBySelectBox) {
        this.prefix = null;
      }

      this.reportData.reportUsers.forEach(element => {
        if (selectedRowsData.filter(g => g.userId === element.userId).length > 0)
          element.selected = true;
        else
          element.selected = false;
      });

      this.selectedUsers = selectedRowsData;
      this.selectionChangedBySelectBox = false;
    },

    // eslint-disable-next-line no-unused-vars
    contentReady(e) {

      let selectedIndexes = [];
      this.reportData.reportUsers.filter(k => k.selected).forEach(element => {
        selectedIndexes.push(e.component.getRowIndexByKey(element.userId));
      })

      e.component.selectRowsByIndexes(selectedIndexes);
    },
  }
}
</script>

<style scoped>

</style>