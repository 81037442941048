<template>
    <v-dialog v-model="showDialog" persistent max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <ValidationSummary :validationErrors="validationErrors" />
                <v-form ref="notificationform" enctype="multipart/form-data" lazy-validation>
                    <v-container>
                        <v-row dense-vertical v-show="!isUpdate">
                            <v-col cols="12" sm="5">
                                <v-select
                                    v-model="notificationTemplate"
                                    :items="notificationTemplateTypes"
                                    label="Type"
                                ></v-select>   
                            </v-col>
                        </v-row>
                        <v-row dense-vertical v-show="canSetVesselEstamatedLockOff">
                            <v-col cols="12" sm="5">
                                <v-select
                                    v-model="vesselVisit"
                                    :items="incomingImportVessels"
                                    :loading="loadingImportVessels"
                                    :item-text="(row) => {return row.vesselName + ' (' + row.inBoundVoyage + ')';}"
                                    :rules="[v => !!v || !canSetVesselEstamatedLockOff || 'Vessel is required']"
                                    :error-messages="validationErrors['VesselVisitId']"
                                    return-object
                                    label="Vessel"
                                    no-data-text="No upcoming vessels"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <DatetimePicker :selectedDateTime.sync="estimatedLockOffDate" datePickerLabel="Estimated Lock Off" :errorMessages="validationErrors['EstimatedImportsLockOffDate']"></DatetimePicker>
                            </v-col>
                        </v-row>
                        <v-row dense-vertical>
                            <v-col cols="12" sm="5" >
                                <label for="content" class="v-label formMinimisedLabel" v-bind:class="{ 'error--text': validationErrors['NotificationMethod']}">Notification Method/s</label>       
                                <v-checkbox class="notificationApplicationsCheckbox" v-model="editedItem.websiteAlert" label="Website/Mobile" :disabled="isUpdate" hide-details></v-checkbox>         
                                <v-checkbox class="notificationApplicationsCheckbox" v-model="editedItem.emailAlert" label="Email" :disabled="isUpdate"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="7" >
                                <label for="content" class="v-label formMinimisedLabel" v-bind:class="{ 'error--text': validationErrors['Applications']}">Port Function/Audience</label>
                                <v-checkbox
                                    v-for="(item, index) in this.companyApps" :key="item.applicationId"
                                    :disabled="!isEditable"
                                    :label="item.name" :value="item"
                                    v-model="editedItem.applications" multiple
                                    :hide-details="index < companyApps.length-1 ? true : false"
                                    class="notificationApplicationsCheckbox">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row dense-vertical>
                            <v-col cols="12">
                                <v-textarea id="title" class="compact" v-model="editedItem.title" label="Title"
                                    :readonly="!isEditable"
                                    persistent-hint auto-grow rows=1 @keydown.enter.prevent
                                    hint="This is the title on the website notification and/or the email subject"
                                    required :error-messages="validationErrors['Title']">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <label for="content" class="v-label formMinimisedLabel" v-bind:class="{ 'error--text': validationErrors['Content']}">Content</label>
                                <DxHtmlEditor
                                    id="notificationsHtmlEditor"
                                    v-model="editedItem.content"
                                    :readOnly="!isEditable"                                >
                                    <DxToolbar>
                                        <DxItem format-name="undo"/>
                                        <DxItem format-name="redo"/>
                                        <DxItem format-name="separator"/>
                                        <DxItem format-name="bold"/>
                                        <DxItem format-name="italic"/>
                                        <DxItem format-name="underline"/>
                                        <DxItem format-name="link"/>
                                        <DxItem format-name="separator"/>
                                        <DxItem
                                            :format-values="headerValues"
                                            format-name="header"
                                        />
                                        <DxItem format-name="separator"/>
                                        <DxItem format-name="orderedList"/>
                                        <DxItem format-name="bulletList"/>
                                    </DxToolbar>
                                </DxHtmlEditor>

                                <v-text-field id="content-helper" class="compact hiddenfield"
                                    persistent-hint                                    
                                    hint="This is the content that will be displayed on the website and/or sent out in the email. Please ensure any specified links start with http://"
                                    required :error-messages="validationErrors['Content']"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-show="editedItem.websiteAlert">
                            <v-col cols="12" sm="6" >
                                <DatetimePicker :selectedDateTime.sync="editedItem.startDate" datePickerLabel="Start Date" :errorMessages="validationErrors['StartDate']"></DatetimePicker>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <DatetimePicker :selectedDateTime.sync="editedItem.endDate" datePickerLabel="End Date" :errorMessages="validationErrors['EndDate']"></DatetimePicker>
                            </v-col>
                        </v-row>
                        <v-row dense-vertical v-show="editedItem.emailAlert">
                            <v-col cols="12" sm="12">
                                <v-file-input
                                    v-show="!isUpdate"
                                    class="compact" 
                                    show-size 
                                    label="Email Attachment"
                                    truncate-length="100"
                                    :disabled="isUpdate"
                                    accept=".docx, application/msword, .xlsx, application/vnd.ms-excel, .pdf, image/*"
                                    name="emailAttachmentFile"
                                    ref="file"
                                    v-model="emailAttachmentFile"
                                    required :error-messages="validationErrors['EmailAttachmentFile']"></v-file-input>
                                <v-text-field 
                                    v-show="isUpdate" 
                                    :value="editedItem.attachmentName" 
                                    disabled
                                    prepend-icon="mdi-paperclip"
                                    label="Email Attachment"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="close" v-show="!savingIndicator">Cancel</v-btn>
                <v-btn color="primary darken-1" text @click="confirmSave" v-show="isEditable" :loading="savingIndicator">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatetimePicker from '@/components/global/DatetimePicker.vue'
import ValidationSummary from '@/components/global/ValidationSummary.vue'

import {
  DxHtmlEditor,
  DxToolbar,
  DxItem
} from 'devextreme-vue/html-editor';

var NotificationType = Object.freeze({
    Normal: 'Normal',
    ImportAdviceCutoff: 'Import Advice Cutoff',
});

export default {
    components: {
        DatetimePicker,
        ValidationSummary,
        DxHtmlEditor,
        DxToolbar,
        DxItem
    },
    data () {
        return {
            showDialog: false,
            editedItem: null,
            defaultItem: {
                notificationId: '',
                websiteAlert: false,
                emailAlert: false,
                title: '',
                content: '',
                startDate: '',
                endDate: '',
                hasAttachment: false,
                attachmentName: '',
                createdDate: '',
                createdUserId: null,
                applications: []
            },
            emailAttachmentFile: null,
            savingNotification: false,
            savingVesselEstimatedLockOffDate: false,
            validationErrors: {},
            headerValues: [false, 2, 3],
            companyApps: [],
            importVessels: [],
            loadingCompanyApps: false,
            loadingImportVessels: false,
            notificationTemplateTypes: [NotificationType.Normal, NotificationType.ImportAdviceCutoff],
            notificationTemplate: NotificationType.Normal,
            vesselVisit: null,
            estimatedLockOffDate: null
        }
    },

    computed: {
        formTitle() {
            return this.editedItem.notificationId ? 'Edit Notification' : 'New Notification';
        },

        isUpdate() {
            return this.editedItem.notificationId != '';
        },

        isEditable() {
            return this.editedItem.notificationId == '' || this.editedItem.websiteAlert;
        },

        canSetVesselEstamatedLockOff() {
            return !this.isUpdate && this.notificationTemplate == NotificationType.ImportAdviceCutoff
        },

        savingIndicator() {
            return this.savingNotification || this.savingVesselEstimatedLockOffDate;
        },

        incomingImportVessels() {
            // only want vessels that have not arrived yet
            return this.importVessels.filter(v => !v.startWorkDate || window.Moment(String(v.startWorkDate)) > new window.Moment());
        }
    },

    watch: {
        vesselVisit: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            if(newVal) {            
                let lockdate = this.getPreviousWorkday(newVal.estimatedTimeOfArrival).hours(16).minutes(0).seconds(0);           
                this.estimatedLockOffDate = lockdate.format();
                this.setNotificationTitleForImportAdviceCutOff(newVal, this.estimatedLockOffDate)
            }
        },

        estimatedLockOffDate: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            if(this.vesselVisit && newVal) {
                this.setNotificationTitleForImportAdviceCutOff(this.vesselVisit, newVal)
            }
        },
    },

    created () {
        //required to avoid runtime errors when first created component
        this.editedItem = Object.assign({}, this.defaultItem);
        this.NotificationType = NotificationType;

        this.loadCompanyApps();
    },

    methods: {   

        //public method - point of entry to component
        editNotification (notification) {
            this.clearValidationErrors();
            this.editedItem = Object.assign({}, notification);
            this.showDialog = true;
        },

        //public method - point of entry to component
        createNotification () {
            this.clearValidationErrors();
            this.editedItem = Object.assign({}, this.defaultItem);

            let now = window.Moment();
            this.editedItem.startDate = now.startOf('day').format();
            this.editedItem.endDate = now.endOf('day').format();

            this.loadVessels(); //always want latest data
            this.estimatedLockOffDate = now.startOf('day').hours(16).format();

            this.showDialog = true;
        },

        loadCompanyApps() {
            this.loadingCompanyApps = true;

            CMSApi.fetchApplications()
                .then(data => {
                    this.companyApps = data;                    
                })
                .finally(() => {
                    this.loadingCompanyApps = false;
                });
        },

        loadVessels() {
            this.loadingImportVessels = true;

            CMSApi.fetchInboundVessels()
                .then(data => {
                    this.importVessels = data;                    
                })
                .finally(() => {
                    this.loadingImportVessels = false;
                });
        },

        setNotificationTitleForImportAdviceCutOff(vessel, rawLockdate) {
            let lockdate = window.Moment(rawLockdate);
            this.editedItem.title = ("NAPIER PORT - " + vessel.vesselName + " (" + vessel.visitId + ") locking off " + lockdate.format("HH:mm") + " " + lockdate.format("dddd") + " " + lockdate.format("DD-MM-YYYY")).toUpperCase();
        },

        getPreviousWorkday(rawDate){
            let date = window.Moment(rawDate);
            let day = date.day();

            let diff = 1;  // returns yesterday
            if (day == 0 || day == 1){  // is Sunday or Monday
                diff = day + 2;  // returns Friday
            }

            return date.subtract(diff, 'days');
        },

        async confirmSave () {
            if(!this.isUpdate && this.editedItem.emailAlert)
            {
                if (await this.$root.$confirm.open('Send Email?', 'Are you sure you want to send an email out to every Propel user using the selected port functions? \n\n Please note the email will be sent straight away and cannot be undone.', { color: 'warning', icon: 'mdi-alert-circle-outline' })) {
                    this.save();
                }
            }
            else {
                this.save();
            }
        },

        save () {           
            if (this.$refs.notificationform.validate())
            {
                if(this.notificationTemplate == NotificationType.ImportAdviceCutoff)
                {        
                    this.savingVesselEstimatedLockOffDate = true;

                    CMSApi.setImportVesselEstimatedLockOffDate(this.vesselVisit.visitId, this.estimatedLockOffDate)
                        .then(() => {
                            //now can save notification
                            this.saveNotification();
                        })
                        .catch(error => {
                            this.validationErrors = error.response.data.errors;
                        })
                        .finally(() => {
                            this.savingVesselEstimatedLockOffDate = false;
                        });
                }
                else {
                    this.saveNotification();
                }
            }
        },

        saveNotification() {
            this.savingNotification = true;

            if (this.editedItem.notificationId) {
                CMSApi.putNotification(this.editedItem)
                    .then(data => {
                        this.close();

                        this.$emit('notificationUpdated', data);
                        window.App.$emit('show-snackbar', "success", "Notification updated");
                    })
                    .catch(error => {
                        this.validationErrors = error.response.data.errors;
                    })
                    .finally(() => {
                        this.savingNotification = false;
                    });                   
            } else {
                //as we are uploading file, we need to send as multipart form so convert to form data
                const formData = new FormData();
                formData.append('emailAttachmentFile', this.emailAttachmentFile);
                for ( var key in this.editedItem ) {
                    if(Array.isArray(this.editedItem[key]))
                    {
                        let arr = this.editedItem[key];
                        for (var i = 0; i < arr.length; i++) {
                            for (const property in arr[i]) {
                                formData.append(key+"[" + i + "][" + property + "]", arr[i][property]);
                            }
                        }
                    }
                    else
                        formData.append(key, this.editedItem[key]);
                }

                CMSApi.postNotification(formData)
                    .then(data => {
                        this.close();

                        this.$emit('notificationCreated', data);
                        window.App.$emit('show-snackbar', "success", "Notification added");
                    })
                    .catch(error => {
                        this.validationErrors = error.response.data.errors;
                    })
                    .finally(() => {
                        this.savingNotification = false;
                    }); 
            }
        },

        close () {
            this.showDialog = false;
            
            //reset form
            this.notificationTemplate = NotificationType.Normal;
            this.vesselVisit = null;
            this.editedItem = Object.assign({}, this.defaultItem);
            this.emailAttachmentFile = null;
        },

        clearValidationErrors() {
            this.validationErrors = {};

            //clearValidationErrors can be called before form fully loaded 
            if(this.$refs.notificationform)
                this.$refs.notificationform.resetValidation();
        }
    }
};

</script>

<style>
    .notificationApplicationsCheckbox
    {
        margin-top:0px
    }

    .v-file-input .v-file-input__text{
        width: 75%;  /* fix for Edge issue where file inputs overflow modal boxes */
    }

    /* shrink inputs - further than just using vuetify dense */
    .v-input.compact {
        padding-top: 0px;
        margin-top: 0;
    }

    /* used for content field to show placeholder and validation text easily */
    .hiddenfield .v-text-field__slot {
        display: none !important;
    }

    /* fix htmleditor toolbar */
    .dx-htmleditor .dx-toolbar .dx-toolbar-items-container {
        height: 60px;
    }
    .dx-htmleditor .dx-toolbar .dx-toolbar-items-container .dx-icon:before {
        vertical-align: baseline;
    }
</style>