<template>
    <v-fade-transition>
        <v-card
            id="multiContainerSelectedToolbar"
            v-show="visible"
            elevation="10"
        >
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title
                    id="toolbarContainerCount"
                    class="text-h4 justify-center primary lighten-1"
                    v-text="selectedContainersCount"
                ></v-card-title>
                
                <v-card-title id="toolbarContainerLabel">
                    Containers Selected
                </v-card-title>

                <v-card-actions>
                    <v-btn 
                        tile text :icon="$vuetify.breakpoint.smAndDown"
                        color="primary"
                        @click="$emit('setGroupClick')">
                        <v-icon>mdi-pencil</v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Set Day/Group</span>
                    </v-btn>
                    <v-btn 
                        tile text :icon="$vuetify.breakpoint.smAndDown"
                        color="primary"
                        @click="$emit('clearSelectedContainersClick')">
                        <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Unselect All</span>
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-fade-transition>
</template>

<script>

export default {
    props: {
        selectedContainersCount: Number,
        visible: {
            type: Boolean,
            default: () => false
        },
    },
};
</script>

<style scoped>

    #multiContainerSelectedToolbar {
        position: fixed;
        z-index: 10;
        bottom:50px;         
        left: 50%;
        transform: translate(-50%, 0);
        overflow:hidden
    }

    #multiContainerSelectedToolbar #toolbarContainerCount {
        width: 80px;
        color: white;
        white-space:nowrap; 
        padding: 10px
    }

    #multiContainerSelectedToolbar #toolbarContainerLabel {
        white-space:nowrap;
        font-weight: 300;
        padding-top: 0px;
        padding-bottom:0px;
    }

</style>