import Vue from 'vue'
import axios from "axios";
import { getInstance } from "../auth/authWrapper";

const _axios = axios.create({
    baseURL: process.env.VUE_APP_CMS_API_URL
});

_axios.interceptors.request.use(async request => {
    // Get the access token from the auth wrapper
    try
    {
        const authService = getInstance();
        var token = await authService.getTokenSilently();

        if (token != null)
            request.headers['Authorization'] = `Bearer ${token}`;
    }
    catch(error)
    {
        console.log("Unable to get auth token. Error:", error);
    }

    console.log('Request:', request.method, request.baseURL + '/' + request.url, {params: request.params}, {request: request});
    return request;
})

_axios.interceptors.response.use(
    response => {
        console.log('Response:', response.config.method, response.config.url, {data: response.data}, {response: response});
        return response.data;
    },
    error => {
        let actionDescription = error.config.actionDescription ?? "";
        console.log('Response - ERROR ' + actionDescription + ':', error.config.method, error.config.url, error.message, error.request.responseText, error);

        // eslint-disable-next-line no-prototype-builtins
        let skipGlobalErrorHandling = error.config.hasOwnProperty('globalErrorHandling') && error.config.globalErrorHandling === false;

        //for anything other than validation (400 - badrequest) errors, handle in a generic matter (unless skipGlobalErrorHandling specifically set)
        if(!skipGlobalErrorHandling && (error.response == null || error.response.status != 400)){

            if(error.response != null && (error.response.status == 401 || error.response.status == 403))
            {
                const authService = getInstance();
                if (authService.isAuthenticated)
                    window.alert("You are not permitted to perform this action. If you believe this is incorrect, please log out and then log in again to ensure your authentication details are up to date.\n\nIf the problem persists please contact us.");
                else
                    authService.loginWithRedirect(); //this should never actually occur, as authWrapper checks when user becomes unauthenticated
            }
            else
            {
                let errorMessage = "There was a problem" + (actionDescription != "" ? " " : "") + actionDescription + ". Please try again or refresh the webpage, and if the problem persists please contact us."
                if(error.response != null)
                {
                    //set data to empty object , so we can add error property
                    error.response.data = {};
                    error.response.data.errors = {"Exception" : [errorMessage]};
                }
                window.alert(errorMessage);
            }
        }
        
        //for validation errors, strip out unhelpful asp.net standard language to make it more user friendly 
        if (error.response != null && error.response.status == "400") {
            //remove the standard "The field" messaging from asp.net validation errors
            //TODO: any way to do this in asp.net itself? Not really an issue at this stage
            let validationErrors = error.response.data.errors;
            for (var key in validationErrors) {
                for (var e in validationErrors[key]) {
                    const prefixToRemove = "The field";
                    //remove the standard "The field" prefix used for many validation errors
                    if (validationErrors[key][e].startsWith(prefixToRemove))
                        validationErrors[key][e] = validationErrors[key][e].substring(prefixToRemove.length);
                    //remove reference to "The {fieldname} field" from validation errors e.g. required validation
                    if (validationErrors[key][e].startsWith("The") && validationErrors[key][e].includes(" field"))
                        validationErrors[key][e] = validationErrors[key][e].substring(3).replace(" field", "");
                }
            }
        }

        throw error;
    }
)


Vue.prototype.$http = _axios;
Vue.prototype.axios = _axios;
window.axios = _axios;