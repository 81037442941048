<template>
    <v-card>
        <v-toolbar
                dark
                color="propelGreen"
        >
            <v-btn
                    icon
                    dark
                    @click="$emit('close')"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Invoice</v-toolbar-title>
        </v-toolbar>
        <v-container>
            <v-row>
              <v-col cols="3">
                <v-card elevation="3">
                  <v-card-title>Invoice Details</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                                  label="Invoice Number"
                                  v-model="invoiceData.invoiceNumber"
                                  outlined
                                  dense
                                  disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                                  label="Customer"
                                  v-model="customerName"
                                  outlined
                                  dense
                                  disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="invoiceData.fwCompanyId !== null">
                        <v-col cols="12">
                          <v-text-field
                              label="Forwarder"
                              v-model="forwarderName"
                              outlined
                              dense
                              disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                                  label="Invoice Amount ($NZD)"
                                  v-model="invoiceData.invoiceAmount"
                                  outlined
                                  dense
                                  disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                                  label="Cost Amount ($NZD)"
                                  v-model="invoiceData.costAmount"
                                  outlined
                                  dense
                                  disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                                  label="Container Number"
                                  v-model="invoiceData.containerNumber"
                                  outlined
                                  dense
                                  disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-checkbox
                                  label="Already Invoiced?"
                                  v-model="invoiceData.isInvoiced"
                                  disabled
                                  dense
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col :cols="readonly ? 9 : 5">
                <v-card elevation="3">
                  <v-card-title>Invoice Items</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <DxDataGrid
                            ref="invoiceItems"
                            :data-source="invoiceData.items"
                        >
                          <DxColumn
                              v-if="!readonly"
                              caption="Action"
                              cell-template="actionTemplate"
                              width="12%"
                          />

                          <DxColumn
                              dataField="itemCode"
                              caption="Item Code"
                              width="38%"
                              alignment="left"
                          />

                          <DxColumn
                              dataField="percentageCaptured"
                              caption="(%)"
                              width="38%"
                              alignment="right"
                          />

                          <DxColumn
                              dataField="costRate"
                              caption="Buy Rate ($NZD)"
                              data-type="number"
                              width="25%"
                          />

                          <DxColumn
                              dataField="itemRate"
                              caption="Sell Rate ($NZD)"
                              width="25%"
                          />

                          <template #actionTemplate="{data}">
                            <div>
                              <v-icon
                                  color="primary"
                                  size="18"
                                  class="mr-2"
                                  title="Edit Invoice Item"
                                  @click="editInvoiceItem(data.data)"
                              >
                                mdi-pencil
                              </v-icon>

                              <v-icon
                                  v-if="canBeDeleted(data)"
                                  color="primary"
                                  size="18"
                                  class="mr-2"
                                  title="Delete Invoice Item"
                                  @click="deleteInvoiceItem(data.data)"
                              >
                                mdi-trash-can
                              </v-icon>
                            </div>
                          </template>

                          <DxSummary>
                            <DxTotalItem
                                column="itemCode"
                                summary-type="count"
                                :customize-text="totalItemsText"
                            />
                            <DxTotalItem
                                column="itemRate"
                                summary-type="sum"
                                :customize-text="totalInvoiceAmountText"
                            />
                            <DxTotalItem
                                column="costRate"
                                summary-type="sum"
                                :customize-text="totalInvoiceAmountText"
                            />
                          </DxSummary>
                        </DxDataGrid>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="!readonly" cols="4">
                <v-card elevation="10" v-if="showForm">
                  <v-card-title>Item Form</v-card-title>
                  <v-card-text>
                    <v-form ref="itemForm">
                      <v-container>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                                label="Item Code"
                                v-model="formData.itemCode"
                                outlined
                                dense
                                disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense v-if="formData.itemCode.includes('FAF') || formData.itemCode.includes('TUC')">
                          <v-col cols="12">
                            <v-text-field
                                label="Percentage"
                                v-model="formData.percentageCaptured"
                                outlined
                                dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="6">
                            <v-text-field
                                label="Buy Rate"
                                v-model.number="formData.costRate"
                                outlined
                                dense
                                :disabled="formData.itemCode.includes('FAF') || formData.itemCode.includes('TUC')"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                                label="Sell Rate"
                                v-model.number="formData.itemRate"
                                outlined
                                dense
                                :disabled="formData.itemCode.includes('FAF') || formData.itemCode.includes('TUC')"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="saveInvoiceItem"
                    >
                      {{ isUpdate ? 'Update' : 'Add' }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="cancelInvoiceItem"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card v-else elevation="10">
                  <v-card-title v-html="titleItemText"></v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-spacer></v-spacer>
                      <v-col cols="4">
                        <v-checkbox
                            label="Show Default Items"
                            v-model="showDefaultItems"
                            dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <DxDataGrid
                            ref="billingItems"
                            :data-source="showDefaultItems ? computedDefaultItems : computedCustomerItems"
                            :noDataText="showDefaultItems ? 'No Default Items' : 'No Customer Items'"
                        >
                          <DxColumn
                              caption="Action"
                              cell-template="actionTemplate"
                              width="15%"
                          />
                          <DxColumn
                              dataField="itemCode"
                              caption="Item Code"
                              width="40%"
                              alignment="left"
                          />

                          <DxColumn
                              dataField="costRate"
                              caption="Buy Rate ($NZD)"
                              data-type="number"
                              width="20%"
                          />

                          <DxColumn
                              dataField="itemRate"
                              caption="Sell Rate ($NZD)"
                              width="20%"
                          />

                          <template #actionTemplate="{data}">
                            <div>
                              <v-icon
                                  color="primary"
                                  size="18"
                                  class="mr-2"
                                  title="Add Invoice Item"
                                  @click="addItem(data.data)"
                              >
                                mdi-plus
                              </v-icon>
                            </div>
                          </template>

                        </DxDataGrid>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from "vuex";
import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
} from "devextreme-vue/data-grid";

export default defineComponent({
    name: "TransportPlanner-InvoiceDialog",

    components : {
      DxDataGrid,
      DxColumn,
      DxSummary,
      DxTotalItem,
    },

    props: {
        bookingId : Number,
        readonly : Boolean,
    },

    data() {
        return {
          invoiceData: {
            invoiceNumber : null,
            customerId : null,
            fwCompanyId : null,
            invoiceAmount : null,
            costAmount : null,
            isInvoiced : false,
            containerNumber : null,
            items: [],
          },

          formData: {
            invoiceItemId : null,
            itemCode : null,
            itemRate : null,
            costRate : null,
            fwCompanyId: null,
            customerId: null,
            percentageCaptured: null,
          },

          customerItems : [],
          defaultItems : [],
          showDefaultItems: false,
          showForm: false,
        }
    },


    computed : {
      ...mapGetters('transportMappings', [
          'getCustomerName'
      ]),

      customerName() {
        return this.getCustomerName(this.invoiceData.customerId);
      },

      forwarderName() {
        return this.getCustomerName(this.invoiceData.fwCompanyId);
      },

      titleItemText() {
        return this.showDefaultItems ? "Default Items" :
            "Billing Items for &nbsp;<span style='color:green'>" + this.getCustomerName(this.invoiceData.customerId) +"</span>";
      },

      computedCustomerItems() {
        return this.customerItems.filter(g =>
          !g.itemCode.includes("GENSET") &&
          !g.itemCode.includes("HAZMAT") &&
          !g.itemCode.includes("SVC") &&
          !g.itemCode.includes("FAF") &&
          !g.itemCode.includes("TUC") &&
          !this.invoiceData.items.map(i => i.itemCode).includes(g.itemCode)
        );
      },

      computedDefaultItems() {
        return this.defaultItems.filter(g =>
          !g.itemCode.includes("GENSET") &&
          !g.itemCode.includes("HAZMAT") &&
          !g.itemCode.includes("SVC") &&
          !g.itemCode.includes("FAF") &&
          !g.itemCode.includes("TUC") &&
          !this.invoiceData.items.map(i => i.itemCode).includes(g.itemCode)
        );
      },

      isUpdate() {
        return this.formData.invoiceItemId != null;
      },

      serviceTotalAmount() {
        return this.invoiceData.items.filter(i => i.itemCode.includes("SVC")).reduce((a, b) => a + b.itemRate, 0);
      },
    },

    watch : {
        bookingId : {
            immediate : true,
            handler : function (val) {
                if (val) {
                    this.loadBookingInvoice(val)
                }
            }
        },

      'formData.percentageCaptured'(val) {
          if (val) {
            if (this.serviceTotalAmount > 0) {
              this.formData.itemRate = this.serviceTotalAmount * (val / 100);
            }
          }
      }
    },


    mounted() {
      this.fetchCustomers();
    },

    methods : {
      ...mapActions('transportMappings', [
          'fetchCustomers'
      ]),

      ...mapActions('transportBilling', [
          'fetchBillingCustomerItems',
          'fetchBillingCustomerItemsOfSecondaryCustomer'
      ]),

      cancelInvoiceItem() {
        this.showForm = false;
        this.resetForm();
      },

      resetForm() {
        this.formData = {
          invoiceItemId : null,
          itemCode : null,
          itemRate : null,
          costRate : null,
          fwCompanyId: null,
          customerId: null,
          percentageCaptured: null,
        };
      },

      totalItemsText(e) {
        return "Total Items: " + e.value;
      },

      totalInvoiceAmountText(e) {
        return "$" + e.value;
      },

      canBeDeleted(e) {
        return (
            !e.data.itemCode.includes("GENSET") &&
            !e.data.itemCode.includes("HAZMAT") &&
            !e.data.itemCode.includes("SVC") &&
            !e.data.itemCode.includes("TUC") &&
            !e.data.itemCode.includes("RB") &&
            !e.data.itemCode.includes("FAF"));
      },

      async loadBookingInvoice(bookingId) {
          CMSApi.fetchBookingInvoice(bookingId).then((response) => {
            this.invoiceData = Object.assign({}, response);

            // Fetch Default Customer Items
            CMSApi.fetchBillingCustomerItems(0)
                .then(data => {
                  this.defaultItems = data;
                })

            // Fetch Customer Items If there are any
            if (this.invoiceData.fwCompanyId !== null){
              CMSApi.fetchBillingCustomerItemsOfSecondaryCustomer(this.invoiceData.customerId,this.invoiceData.fwCompanyId)
                  .then(data => {
                    this.customerItems = data;
                  })
            } else {
              CMSApi.fetchBillingCustomerItems(this.invoiceData.customerId)
                  .then(data => {
                    this.customerItems = data;
                  })
            }

          });
      },

      async addItem(e) {
        e.cancel = true;
        this.showForm = true;
        this.formData = Object.assign({}, e);
      },

      async saveInvoiceItem() {
        if (await this.$root["$confirm"].open('Update Invoice', 'Proceed to add/update this invoice item?' , { color: 'info' })) {
          if (this.isUpdate) {

            let putData = {
              invoiceItemId: this.formData.invoiceItemId,
              itemCode: this.formData.itemCode,
              itemRate: this.formData.itemRate,
              costRate: this.formData.costRate,
              fwCompanyId: this.formData.fwCompanyId,
              customerId: this.formData.customerId,
              percentageCaptured: this.formData.percentageCaptured,
              invoiceNumber: this.invoiceData.invoiceNumber,
            };

            CMSApi.putBookingInvoiceItem(putData, this.invoiceData.invoiceNumber).then(() => {
              window.App.$emit('show-snackbar', "success", "Item updated");
              this.loadBookingInvoice(this.bookingId);
              this.showForm = false;
              this.resetForm();
            });

          } else {

            let postData = {
              itemCode: this.formData.itemCode,
              itemRate: this.formData.itemRate,
              costRate: this.formData.costRate,
              fwCompanyId: this.formData.fwCompanyId,
              customerId: this.formData.customerId,
              percentageCaptured: this.formData.percentageCaptured,
              invoiceNumber: this.invoiceData.invoiceNumber,
            };

            CMSApi.postBookingInvoiceItem(postData).then(() => {
              window.App.$emit('show-snackbar', "success", "Item added");
              this.loadBookingInvoice(this.bookingId);
              this.showForm = false;
              this.resetForm();
            });
          }
        }
      },

      editInvoiceItem(e) {
        this.showForm = true;
        this.formData = Object.assign({}, e);
      },

      async deleteInvoiceItem(e) {
        e.cancel = true;
        if (await this.$root["$confirm"].open('Delete Item?', 'Proceed to remove this invoice item?' , { color: 'warning' })) {
          CMSApi.deleteInvoiceItem(e.invoiceItemId)
              .then(() => {
                window.App.$emit('show-snackbar', "success", "Item deleted");
                this.loadBookingInvoice(this.bookingId);
              });
        }
      }
    },
})
</script>

<style scoped>

</style>