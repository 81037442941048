class ReportDetails {
    constructor() {
        this.reportName = '';
        this.reportDataSource = '';
        this.reportDataSourceContext = '';
        this.reportDataSourceType = '';
        this.status = '';
        this.reportColumns = [];
        this.reportParams = [];
        this.reportUsers = [];
        this.reportIsCompanyBinded = false;
    }
}

export default {
    Details: function () {
        this.reportId = null;
        this.reportDetails = new ReportDetails();

        this.convertToSubmittableReport = function() {
            return {
                ReportId: this.reportId,
                ReportName: this.reportDetails.reportName,
                DataSource: this.reportDetails.reportDataSource,
                DataSourceType: this.reportDetails.reportDataSourceType,
                DataSourceContext: this.reportDetails.reportDataSourceContext,
                Columns: this.reportDetails.reportColumns,
                Users: this.reportDetails.reportUsers.filter(g => g.selected),
                Filters: this.reportDetails.reportParams,
                IsCompanyReport: this.reportDetails.reportIsCompanyBinded
            }
        }

        this.setFromFlattenedData = async function(flattenedData, roles) {
            this.reportId = flattenedData.reportId;
            this.reportDetails.reportName = flattenedData.reportName;
            this.reportDetails.reportDataSourceContext = flattenedData.dataSourceContext;
            this.reportDetails.reportDataSourceType = flattenedData.dataSourceType;
            this.reportDetails.reportDataSource = flattenedData.dataSource;
            this.reportDetails.reportIsCompanyBinded = flattenedData.isCompanyReport;

            let reportColumns = [];
            for (let k = 0; k < flattenedData.columns.length; ++k) {
                let newArr = {
                    ColumnId: flattenedData.columns[k].columnId,
                    SortNumber: flattenedData.columns[k].sortNumber,
                    ColumnName: flattenedData.columns[k].columnName,
                    ColumnStringName: flattenedData.columns[k].columnStringName,
                    ColumnType: flattenedData.columns[k].columnType,
                }
                reportColumns.push(newArr);
            }

            let reportFilters = [];
            for (let h = 0; h < flattenedData.filters.length; ++h) {
                let newArr = {
                    FilterColumn: flattenedData.filters[h].filterColumn,
                    FilterDataType: flattenedData.filters[h].filterDataType,
                    FilterDefaultValue: flattenedData.filters[h].filterDefaultValue,
                    IsRequired: flattenedData.filters[h].isRequired,
                    FilterType: flattenedData.filters[h].filterType
                }
                reportFilters.push(newArr);
            }

            this.reportDetails.reportColumns = reportColumns;
            this.reportDetails.reportParams = reportFilters;
            this.reportDetails.reportUsers = roles;

            for (let i = 0; i < flattenedData.users.length; ++i) {
                let userIndex = this.reportDetails.reportUsers.findIndex(k => k.userId === flattenedData.users[i].userId);
                this.reportDetails.reportUsers[userIndex].selected = true;
            }
        }
    }
}