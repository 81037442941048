<template>
    <div>
        <v-card elevation="0" v-bind:class="{ 'layout-noNavigationMenu': !$auth.hasAccess }">
            <v-card-title>
                <span class="headline">Help & Guides</span>
            </v-card-title>

            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in links" :key="index">
                        <v-card outlined class="help-card">
                            <a :href="item.link" :target="item.link.startsWith('#/') ? '' : '_blank'">
                                <figure :class="['help-frame', item.type]" :style="{ backgroundImage: 'url(' + item.image + ')'}">
                                    <figcaption>{{item.caption}}</figcaption>
                                </figure>
                            </a>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-card outlined>
                            <v-icon id="contactUsIcon" :class="$vuetify.breakpoint.name">mdi-phone</v-icon>
                            <v-card-title style="display: block;">Contact Us</v-card-title>
                            <v-card-text>If you have any questions about using the Propel VBS, please email the team: <a href="mailto:westg@napierport.co.nz">westg@napierport.co.nz</a>, or call us on <a href="tel:06 8334357" style="white-space: nowrap;">06 8334357</a></v-card-text>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>
        </v-card>
        <div id="appVersion">App Version: v{{appVersion}}</div>
    </div>
</template>

<script>
import termsIcon from '@/assets/img/icon_terms.png';
import pdfIcon from '@/assets/img/icon_pdf.png';

export default {
    name: 'help',

    data () {
        return {
            links: [],
        }
    },

    computed: {
        appVersion () {
            return process.env.VUE_APP_VERSION
        }
    },

    mounted () {
        this.links = [
            { caption:'Video: Basic Introduction', type:'video', image:'https://img.youtube.com/vi/_KxJ8sihaX4/hqdefault.jpg', link:'https://www.youtube.com/watch?v=_KxJ8sihaX4&list=PL5zgAp4fsd_0ZGYEFAC8vIT_jkSMUJNjI&index=2&t=0s'},
            { caption:'Video: User Management', type:'video', image:'https://img.youtube.com/vi/OwBeUcEwcIo/hqdefault.jpg', link:'https://www.youtube.com/watch?v=OwBeUcEwcIo&list=PL5zgAp4fsd_0ZGYEFAC8vIT_jkSMUJNjI&index=2&t=0s'},
            { caption:'Video: Appointments Screen', type:'video', image:'https://img.youtube.com/vi/H19EzkXFSn4/hqdefault.jpg', link:'https://www.youtube.com/watch?v=H19EzkXFSn4&list=PL5zgAp4fsd_0ZGYEFAC8vIT_jkSMUJNjI&index=2&t=0s'},
            { caption:'Video: Creating and Editing Appointments', type:'video', image:'https://img.youtube.com/vi/qpW1SBejs-4/hqdefault.jpg', link:'https://www.youtube.com/watch?v=qpW1SBejs-4&list=PL5zgAp4fsd_0ZGYEFAC8vIT_jkSMUJNjI&index=2&t=0s'},
            { caption:'Video: Notifications', type:'video', image:'https://img.youtube.com/vi/8TizzYsxtp0/hqdefault.jpg', link:'https://www.youtube.com/watch?v=8TizzYsxtp0&list=PL5zgAp4fsd_0ZGYEFAC8vIT_jkSMUJNjI&index=2&t=0s'},
            { caption:'Video: Reports', type:'video', image:'https://img.youtube.com/vi/NXWFX6L6OIw/hqdefault.jpg', link:'https://www.youtube.com/watch?v=NXWFX6L6OIw&list=PL5zgAp4fsd_0ZGYEFAC8vIT_jkSMUJNjI&index=2&t=0s'},
            { caption:'Quick Task Guide', type:'document', image:pdfIcon, link:'https://ponlcmsprod.blob.core.windows.net/ponl-wa-cmsprod/Propel - Quick Task Guide.pdf'},
            { caption:'Terms and Conditions', type:'document', image:termsIcon, link:'#/terms' },
        ]
    },
}
</script>

<style>
    .help-card {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
    }

    .help-frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    figure {
        display: block;
        position: relative;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    figure.document {
        background-size: contain;
    }

    figcaption {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0,0,0,0.6);
        color: #fff;
        padding: 5px 15px 5px 15px;
        font-size: 0.9em;
        transform: translateY(0);
        opacity: 1;
        transition: all .20s;
    }

    #appVersion {
        font-size: 0.9em;
        position:absolute;
        bottom:0;
        right:0;
    }
    
    #contactUsIcon {
        font-size: 50px;
        border: 4px solid currentColor;
        border-radius: 100%;
        height: 1.5em;
        width: 1.5em;
        color: #595859;
        background-color: #c0d830;
        float: left;
        margin: 15px;
    }

    #contactUsIcon.xs {
        font-size: 22px;
        border-width: 2px;
    }
</style>