import utils from '@/utils/vuexUtils.js'
import {storeVesselHelpers} from './vessels.js';

// initial state
const state = {
    importContainers: [],
    selectedContainerIds: [],
    importDayGroups: [],
    importDayGroupsTimeTrigger: new Date(),
}

// getters
const getters = {
    //only new (non-dismissed notificatons)
    selectedImportContainers: state => {
        return state.importContainers.filter(container => 
            state.selectedContainerIds.some(c => c == container.containerNumber)
        );
    },

    importDayGroupOptions() {
        //exclude today from the options, and order from tomorrow onwards
        let currentDateTime = state.importDayGroupsTimeTrigger;
        let currentDayOfWeek = currentDateTime.getDay();

        let sortedDayOptions = [];
        for(let i=currentDayOfWeek+1; i<currentDayOfWeek+7; i++) //from tomorrow onwards
        {
            let targetDayOfWeek = i % 7;
            sortedDayOptions.push(
                ...state.importDayGroups.filter(dayGroup => dayGroup.dayOfWeek == targetDayOfWeek)
            );
        }

        return sortedDayOptions;
    },
}

// actions
const actions = {
    fetchImportContainers: ({ commit, state }) => { // eslint-disable-line no-unused-vars
        return utils.CommitArrayPromise(commit, CMSApi.fetchImportContainers(), 'setImportContainers');
    },

    fetchImportDayGroups: ({ commit, state }, ignoreError = false) => { // eslint-disable-line no-unused-vars
        return utils.CommitArrayPromise(commit, CMSApi.fetchImportDayGroups(ignoreError), 'setImportDayGroups');
    },

    addOrUpdateImportContainer: ({ commit, state }, importContainer) => { // eslint-disable-line no-unused-vars
        commit('addOrUpdateImportContainers', [importContainer]); 
    },

    addOrUpdateImportContainers: ({ commit, state }, importContainers) => { // eslint-disable-line no-unused-vars
        commit('addOrUpdateImportContainers', importContainers); 
    },

    setSelectedContainerIds: ({ commit, state }, containerIds) => { // eslint-disable-line no-unused-vars
        commit('setSelectedContainerIds', containerIds); 
    },

    selectContainers: ({ commit, state }, containerIds) => { // eslint-disable-line no-unused-vars
        let containers = state.importContainers.filter(container => 
            containerIds.some(c => c == container.containerNumber)
        );

        containerIds = containers.filter(
            container => helpers.isContainerSelectable(container)
        ).map(s => s.containerNumber);

        commit('addSelectedContainerIds', containerIds); 
    },

    deselectContainers: ({ commit, state }, containerIds) => { // eslint-disable-line no-unused-vars
        commit('removeSelectedContainerIds', containerIds); 
    },

    setImportDayGroupsTimeTrigger: ({ commit, state }, currentTime) => { // eslint-disable-line no-unused-vars
        commit('setImportDayGroupsTimeTrigger', currentTime); 
    },
}

// mutations
const mutations = {
    setImportContainers(state, importContainers) {
        state.importContainers = importContainers;
    },

    addOrUpdateImportContainers(state, importContainers) {
        for (const container of importContainers) {
            const existingRecordIndex = state.importContainers.findIndex(i => i.containerNumber == container.containerNumber);

            //if record exists in list, update, else add.
            if (existingRecordIndex >= 0)
                Object.assign(state.importContainers[existingRecordIndex], container);
            else
                state.importContainers.unshift(container);
        }
    },

    setImportDayGroups(state, importDayGroups) {
        state.importDayGroups = importDayGroups;
    },

    setSelectedContainerIds(state, containerIds) {
        state.selectedContainerIds = containerIds;
    },

    addSelectedContainerIds(state, containerIds) {
        state.selectedContainerIds.push(...containerIds);
    },

    removeSelectedContainerIds(state, containerIds) {
        for (const containerId of containerIds) {
            state.selectedContainerIds.splice(
                state.selectedContainerIds.findIndex(c => c == containerId), 1
            );
        }
    },
    
    setImportDayGroupsTimeTrigger(state, currentTime) {
        state.importDayGroupsTimeTrigger = currentTime;
    },
}

const helpers = {
    isNoGroup(group) {
        return (!group || group == '' || group == 'NOB');
    },

    isContainerInDayStack(container) {
        return container.group && state.importDayGroups.some(g => g.groupId == container.group);
    },

    isContainerInBlockStack(container) {
        return container.group && container.group.startsWith("R");
    },

    isContainerInNoStack(container) {
        return this.isNoGroup(container.group);
    },

    isContainerSelectable(container) {
        return this.isContainerGroupEditable(container);
    },

    isContainerGroupEditable(container) {
        return !container.appointmentId 
                && (!storeVesselHelpers.isContainerVesselLockedForImport(container) || container.transitState == 'Yard')
                && (
                    this.isContainerInNoStack(container) 
                    || this.isContainerInDayStack(container) 
                    || (this.isContainerInBlockStack(container) && container.transitState != 'Yard')
                );
    },

    getGroupDescription(group) {
        var groupDescription = '';

        if (this.isNoGroup(group)) {
            groupDescription = 'No Group';
        }
        else {
            var importDayGroup = state.importDayGroups.find(d => d.groupId == group);
            groupDescription = (importDayGroup ? importDayGroup.description : group);
        }

        return groupDescription;
    },

    importBlockGroupOptionsForContainers(allCompanyImportContainers, containersToEdit) {
        //NOTE: passing in containers so that call can be reactive in components
        //we want to return all block groups that:
        // - do not have ANY container in the yard
        // - have at least one matching container in their stack (matched on visit id, length, and dry/reefer) to any of the selected containers
        let allBlockContainers = allCompanyImportContainers.filter(c => importAdviceHelpers.isContainerInBlockStack(c));
        let groupsWithContainersInTheYard = 
            [...new Set(
                allBlockContainers.filter(c => c.transitState == 'Yard')
                .map(c => c.group)
            )];

        let matchingContainers = [];
        let potentialBlockGroupContainers = allBlockContainers.filter(c => !groupsWithContainersInTheYard.includes(c.group));
        for(let container of containersToEdit)
        {
            matchingContainers.push( 
                ...potentialBlockGroupContainers.filter(c => c.inBoundActualVisitId == container.inBoundActualVisitId
                                                            && c.typeLength == container.typeLength
                                                            && c.requiresPower == container.requiresPower)
            );
        }            

        //get distinct groups, put into object, then sort
        let groups = [...new Set(matchingContainers.map(c => c.group))]
                    .map(group => ({groupId: group, description: group }));
        groups.sort((a, b) => (a.groupId > b.groupId) ? 1 : ((b.groupId > a.groupId) ? -1 : 0)); 

        return groups;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export const importAdviceHelpers = helpers;