<template>
  <div>
    <v-card elevation="1">
      <v-container>
        <v-row>
          <v-col :cols="gridColumnSize">
            <v-row>
              <v-col>
                <v-toolbar class="datatableToolbar" flat>
                  <v-toolbar-title>Transport Operators</v-toolbar-title>
                </v-toolbar>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-right" cols="2" style="align-self: flex-end;">
                <v-btn
                    v-if="!isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    right
                    @click="fetchTransportOperators"
                    :loading="loadingTransportOperators"
                    style="vertical-align: bottom"
                >
                  <v-icon>mdi-reload</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Refresh</span>
                </v-btn>
              </v-col>
              <v-col cols="3" xl="3" md="6" lg="3">
                <v-text-field clearable v-model="searchText" label="Search by Operator, Operator Code" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
              </v-col>
            </v-row>

            <DxDataGrid
                id="operatorsGrid"
                :ref="dataGridRefName"
                :data-source="transportOperators"
                :show-borders="false"
                :hoverStateEnabled="true"
                noDataText="No operators found"
            >
              <DxHeaderFilter :visible="true"/>
              <DxPaging :page-size="10"/>
              <DxColumn data-field="transportOperatorCode" data-type="string" caption="Operator Code"/>
              <DxColumn data-field="transportOperatorName" data-type="string" caption="Operator Name"/>
              <DxColumn data-field="faF_Rate" :allowSearch="false" :allowFilter="false" data-type="number" caption="FAF %"/>
              <DxColumn data-field="nextFAF_Rate" :allowSearch="false" data-type="number" caption="Next FAF %"/>
              <DxColumn data-field="nextFAF_RateDate" :allowSearch="false" data-type="date" caption="Change Date"/>
              <DxColumn type="buttons" cell-template="actionCellTemplate" caption="Actions" />
              <template #actionCellTemplate="{ data }">
                <div>
                  <v-icon color="primary" size="18" class="mr-2" @click="editOperator(data.data)">
                    mdi-pencil
                  </v-icon>
                </div>
              </template>
            </DxDataGrid>
          </v-col>
          <v-col :cols="formColumnSize" v-if="isFormActive">
            <v-row>
              <v-spacer></v-spacer>
              <v-col class="text-right" cols="5">
                <v-btn
                    v-if="isFormActive"
                    tile class="mb-2"
                    color="secondary"
                    @click="deactivateForm"
                >
                  <v-icon>mdi-arrow-collapse-right</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">Collapse</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="3">
                  <v-card-text>
                    <ValidationSummary :validationErrors="validationErrors" />
                    <v-form ref="operatorForm" lazy-validation>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                                v-model="formData.transportOperatorCode"
                                label="Operator"
                                tabindex="1"
                                disabled
                                dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                                v-model.number="formData.faF_Rate"
                                type="number"
                                label="FAF %"
                                tabindex="2"
                                dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                                v-model.number="formData.nextFAF_Rate"
                                type="number"
                                label="Next FAF %"
                                tabindex="3"
                                dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                                v-model="formData.nextFAF_RateDate"
                                type="date"
                                label="Change Date"
                                tabindex="4"
                                dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="resetForm"
                    >
                      Reset
                    </v-btn>
                    <v-btn
                        color="secondary"
                        text
                        @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {mapActions, mapState} from "vuex";
import {
  DxColumn,
  DxDataGrid, DxHeaderFilter,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";
import ValidationSummary from "@/components/global/ValidationSummary.vue";

export default defineComponent({
  name: "TransportMappings-TransportOperators",

  components: {
    DxHeaderFilter,
    DxDataGrid,
    DxPager,
    DxPaging,
    DxColumn,
    ValidationSummary,
  },

  data() {
    return {
      // Grid
      dataGridRefName: 'operatorsGrid',
      pageSizes: [10,15,25],
      searchText: '',

      // Form
      isFormActive: false,
      isUpdate: false,
      validationErrors: {},
      formData : {
        transportOperatorId: null,
        transportOperatorCode: null,
        faF_Rate: '',
        nextFAF_Rate: '',
        nextFAF_RateDate: '',
      },
    }
  },

  computed : {
    ...mapState('transportMappings', [
        'transportOperators',
        'loadingTransportOperators'
    ]),

    gridColumnSize() {
      return this.isFormActive ? 8 : 12;
    },

    formColumnSize() {
      return this.isFormActive ? 4 : 0;
    },
  },

  mounted() {
    this.fetchTransportOperators();
  },

  watch: {
    searchText: function (newVal) { // eslint-disable-line no-unused-vars
      this.$refs[this.dataGridRefName]["instance"].searchByText(newVal?.trim());
    },
  },

  methods : {
    ...mapActions('transportMappings', [
        'fetchTransportOperators'
    ]),

    deactivateForm() {
      this.isFormActive = false;
    },

    editOperator(data) {
      this.isFormActive = true;
      this.formData = Object.assign({}, data);
    },

    resetForm() {
      this.formData = {
        transportOperatorId: null,
        transportOperatorCode: null,
        faF_Rate: '',
        nextFAF_Rate: '',
        nextFAF_RateDate: '',
      };
      this.$refs.operatorForm.resetValidation();
    },

    async save() {
      const isValid = await this.$refs.operatorForm.validate();
        if (isValid) {
          if (await this.$root["$confirm"].open('Transport Mapping', 'Proceed in saving the operator info?', {color: 'info'})) {

            if (this.formData.transportOperatorId < 1)
            {
              CMSApi.postTransportOperator(this.formData)
                  .then(() => {
                    window.App.$emit('show-snackbar', "success", "Operator info updated");

                  })
                  .catch(error => {
                    let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                    this.$root["$alert"].show('Unable to update operator', errorMessage)
                  })
                  .finally(() => {
                    this.resetForm();
                    this.fetchTransportOperators();
                    this.isFormActive = false;
                  });
            } else {
              CMSApi.putTransportOperator(this.formData)
                  .then(() => {
                    window.App.$emit('show-snackbar', "success", "Operator info updated");
                  })
                  .catch(error => {
                    let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                    this.$root["$alert"].show('Unable to update booking', errorMessage)
                  })
                  .finally(() => {
                    this.resetForm();
                    this.fetchTransportOperators();
                    this.isFormActive = false;
                  });
            }
        }
      }
    }
  },


})
</script>

<style scoped>

</style>