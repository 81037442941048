<template>
  <v-card elevation="3">
    <v-card-text>
      <v-form ref="reportDetailsForm" lazy-validation>
        <v-row>
          <v-col cols="4">
            <v-text-field
                v-model="reportData.reportName"
                label="Report Name"
                tabindex="1"
                :rules="reportNameRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-checkbox
                v-if="reportData.reportDataSourceType === 'N4'"
                label="Company Report?"
                v-model="reportData.reportIsCompanyBinded">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
                v-model="reportData.reportDataSourceContext"
                :items="dbContext"
                label="Source Context"
                item-text="value"
                item-value="code"
                clearable
                tabindex="2"
                :rules="reportContextRules"
                @change="checkFields"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
                v-model="reportData.reportDataSourceType"
                :items="dbSourceType"
                label="Source Type"
                item-text="value"
                item-value="code"
                clearable
                tabindex="3"
                :rules="reportTypeRules"
                @change="checkFields"
                @click:clear="sourceTypeClear"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-select
                v-model="reportData.reportDataSource"
                :items="dbSourceList"
                label="Source"
                item-text="sourceName"
                item-value="sourceName"
                clearable
                tabindex="4"
                :rules="reportSourceRules"
                :loading="loadingDbSourceList"
                @click:clear="sourceClear"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {ReportDBContext, ReportDBSourceType} from "@/utils/constants";

export default {
  name: "ReportForm-Details",

  data() {
    return {
      // Validation
      reportNameRules: [
        v => !!v || 'Report name is required',
      ],
      reportContextRules: [
        v => !!v || 'Report Context is required',
      ],
      reportTypeRules: [
        v => !!v || 'Report type is required',
      ],
      reportSourceRules: [
        v => !!v || 'Report source is required',
      ],
    }
  },

  computed: {
    ...mapState('reportManagement', [
      'sourceList',
      'sourceColumnList',
      'loadingSourceList',
      'loadingSourceColumnList',
      'loadingReportUsers',
      'isUpdate'
    ]),

    ...mapGetters('reportManagement', [
      'workingReport'
    ]),

    reportData() {
      return this.workingReport.reportDetails;
    },

    dbContext() {
      return ReportDBContext;
    },

    dbSourceType() {
      return ReportDBSourceType.filter(s => s[this.reportData.reportDataSourceContext]);
    },

    dbSourceList() {
      return this.sourceList;
    },

    loadingDbSourceList() {
      return this.loadingSourceList;
    },

    nameFilled() {
      return this.reportData.reportName !== '' && this.reportData.reportName !== null;
    },

    contextFilled() {
      return this.reportData.reportDataSourceContext !== '' && this.reportData.reportDataSourceContext !== null;
    },

    typeFilled() {
      return this.reportData.reportDataSourceType !== '' && this.reportData.reportDataSourceType !== null;
    },

    sourceFilled() {
      return this.reportData.reportDataSource !== '' && this.reportData.reportDataSource !== null;
    },

    isContextAndTypeFilled() {
      return this.contextFilled && this.typeFilled;
    },

    allFieldsFilled(){
      return this.nameFilled && this.contextFilled && this.typeFilled && this.sourceFilled;
    }
  },

  watch: {
    isContextAndTypeFilled(val) {
      if (val)
        this.fetchReportSourceList( {context: this.reportData.reportDataSourceContext, type: this.reportData.reportDataSourceType})
    },

    'reportData.reportDataSource'() {
      if (this.isContextAndTypeFilled && this.sourceFilled)
        this.fetchReportSourceColumnList({context: this.reportData.reportDataSourceContext, type: this.reportData.reportDataSourceType, source: this.reportData.reportDataSource})
    },

    allFieldsFilled(val) {
      return val ? this.$emit('filled') : this.$emit('unfilled');
    }
  },

  methods: {
    ...mapActions('reportManagement', [
      'fetchReportSourceList',
      'fetchReportSourceColumnList'
    ]),

    ...mapMutations('reportManagement', [
      'resetSourceList',
      'resetSelectedSource',
      'resetSelectedColumns'
    ]),

    //Misc functions
    checkFields() {
      if (this.contextFilled && this.typeFilled)
        this.fetchReportSourceList( {context: this.reportData.reportDataSourceContext, type: this.reportData.reportDataSourceType})
    },

    sourceTypeClear() {
      this.resetSelectedSource();
      this.resetSourceList();
    },

    sourceClear() {
      this.resetSelectedColumns();
    },
  }
}
</script>

<style scoped>

</style>