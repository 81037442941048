<template>
  <div>
    <v-card elevation="1">
      <v-card-title>
        <span class="headline">Zone & Slots</span>
      </v-card-title>
      <v-card-text>
        <ValidationSummary :validationErrors="validationErrors" />
        <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <DxDataGrid
                  :ref="zoneSlotGridRefKey"
                  :data-source="zonesAndSlots"
                  :show-borders="true"
                  :hoverStateEnabled="true"
                  @selection-changed="onSelectionChanged"
                  @editor-preparing="onEditorPreparing"
                  height="600"
                >
                    <DxSelection
                      mode="multiple"
                    />
                    <DxScrolling mode="virtual"/>
                    <DxColumn
                      caption="Zone"
                      data-field="zone"
                      alignment="center"
                    ></DxColumn>
                    <DxColumn
                      caption="Slots"
                      data-field="slots"
                      alignment="center"
                    ></DxColumn>
                </DxDataGrid>
              </v-col>
              <v-col cols="4">
                  <v-form ref="zoneForm">
                      <div v-if="selectedRowsData.length > 0">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                  v-model="zone"
                                  label="Zone(s)"
                                  disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="slot"
                                        label="Slot(s)"
                                        :rules="slotRules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                  tile
                                  class="secondary"
                                  width="100%"
                                  @click="updateZone"
                                >
                                    {{ btnText }}
                                </v-btn>
                            </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                          <v-row>
                              <v-col cols="12">
                                  <v-select
                                      v-model.number="zone"
                                      :items="computedZones"
                                      label="Zone"
                                      dense
                                      required
                                      :rules="zoneRules"
                                  >
                                  </v-select>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="12">
                                  <v-text-field
                                          clearable
                                          v-model.number="slot"
                                          type="number"
                                          label="Slots"
                                          :rules="slotRules"
                                          style="margin-top:-10px"
                                          autocomplete="off"
                                  >
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="12">
                                  <v-btn
                                    tile
                                    class="secondary"
                                    width="100%"
                                    @click="addZone"
                                  >
                                      Add
                                  </v-btn>
                              </v-col>
                          </v-row>
                      </div>
                  </v-form>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="$emit('close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ValidationSummary from "@/components/global/ValidationSummary";
import {DxDataGrid, DxScrolling, DxSelection, DxColumn} from "devextreme-vue/data-grid";
let selectAllCheckBox;
let checkBoxUpdating = false;
export default {
  name: "GateZoneSlot",

  components: {
      DxScrolling,
      DxSelection,
      DxColumn,
    ValidationSummary,
    DxDataGrid
  },

  data() {
    return {
      // Grid
      zoneSlotGridRefKey: 'data-grid',
      validationErrors: {},
      zoneId: null,
      zone: null,
      zoneRules: [
        val => !!val || 'Zone is required',
      ],
      allowedZones: [7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      slotRules: [
        val => !!val || 'Slot is required'
      ],
      slot: null,
      zonesAndSlots: [],
      loadingEditData: false,
      isAdding: true,
      gateServiceId: null,
      headers: [
        { text: 'Zone', value: 'zone' },
        { text: 'Slot', value: 'slots' },
      ],
      selectedRowsData: [],
    }
  },

  computed: {
    computedZones() {
      return this.allowedZones.filter( id => this.zonesAndSlots.find(({zone}) => zone === id) === undefined )
    },

    selectedRowKeys() {
        return this.selectedRowsData.map((g) => g["gateServiceZoneId"]);
    },

    btnText() {
        return this.selectedRowsData.length > 1 ? 'Update Selected' : 'Update';
    }
  },

  watch : {
      selectedRowsData(val) {
          if (val.length > 0) {
              if (val.length === 1)
              {
                  this.zone = val[0].zone;
                  this.slot = val[0].slots;
              } else {
                  this.zone = 'Selected';
                  this.slot = '';
              }
          } else {
              this.zone = null;
          }
      }
  },

  methods: {
    //point of entry
    async init(data){
      this.gateServiceId = data.gateServiceId;
      await this.loadZones(data.gateServiceId);
    },

    async loadZones(id) {
      this.loadingEditData = true;
      await CMSApi.fetchGateServicesInfo(id)
          .then(async (data) => {
            this.zonesAndSlots = data.zones.sort((a,b) => a.zone - b.zone);
          })
          .finally(() => {
            this.loadingEditData = false;
          });
    },

    async deleteZone(item) {
      if (await this.$root["$confirm"].open('Zone Remove?', 'Please note that only future date instances are affected within this change', { color: 'info' }))
      {
        let delData = {
          GateServiceId: this.gateServiceId,
          Zones: [
            {
              GateServiceZoneId: item["gateServiceZoneId"],
              Zone: item.zone,
              Slots: item.slot
            }
          ]
        }

        CMSApi.removeGateServiceZones(delData)
            .then( () => {
              this.loadZones(this.gateServiceId);
              window.App.$emit('show-snackbar', "success", "Zone and Slot deleted");
            })
            .finally(() => {
              this.resetForm();
            })

      }
    },

    async updateZone() {
        if (this.$refs.zoneForm.validate()) {
            if (await this.$root["$confirm"].open('Zone & Slots modification', 'Please note that only future date instances are affected within this change', { color: 'info' }))
            {
                await this.selectedRowsData.forEach(e => {
                    let putData = {
                        GateServiceId: this.gateServiceId,
                        Zones: [
                            {
                                GateServiceZoneId: e.gateServiceZoneId,
                                Zone: e.zone,
                                Slots: this.slot
                            }
                        ]
                    }
                    CMSApi.putGateServiceZones(putData);
                });

                await this.$nextTick();
                window.App.$emit('show-snackbar', "success", "Zone and Slot updated");
                this.resetForm();
                await this.$emit('close');
            }
        }
    },

    async addZone() {
      if (this.$refs.zoneForm.validate()) {
        if (await this.$root["$confirm"].open('Zone & Slots modification', 'Please note that only future date instances are affected within this change', { color: 'info' }))
        {
          if (this.isAdding) {
            let postData = {
              GateServiceId: this.gateServiceId,
              Zones: [
                {
                  Zone: this.zone,
                  Slots: this.slot
                }
              ]
            }

            CMSApi.postGateServiceZones(postData)
                .then( () => {
                  this.loadZones(this.gateServiceId);
                  window.App.$emit('show-snackbar', "success", "Zone and Slot created");
                })
                .finally(() => {
                  this.resetForm();
                })

          } else {
            let putData = {
              GateServiceId: this.gateServiceId,
              Zones: [
                {
                  GateServiceZoneId: this.zoneId,
                  Zone: this.zone,
                  Slots: this.slot
                }
              ]
            }

            CMSApi.putGateServiceZones(putData)
                .then( () => {
                  this.loadZones(this.gateServiceId);
                  window.App.$emit('show-snackbar', "success", "Zone and Slot updated");
                })
                .finally(() => {
                  this.resetForm();
                })
          }
        }
      }
    },

    editZone(item) {
      this.zoneId = item["gateServiceZoneId"];
      this.zone = item["zone"];
      this.slot = item["slots"];
      this.isAdding = false;
    },

    resetForm() {
      this.isAdding = true;
      this.zone = '';
      this.zoneId = null;
      this.slot = '';
      this.$refs.zoneForm.resetValidation();
    },

    isSelectable(item) {
        return item.approved = true;
    },

    isSelectAll(dataGrid) {
        let items = [];
        dataGrid.getDataSource().store().load().done(function (data) {
            items = data;
        });

        let selectableItems = items.filter(this.isSelectable);
        let selectedRowKeys = dataGrid.option("selectedRowKeys");
        if (!selectedRowKeys.length) {
            return false;
        }
        return selectedRowKeys.length >= selectableItems.length ? true : undefined;
    },

    onSelectionChanged(e) {
        let deselectRowKeys = [];
        e["selectedRowsData"].forEach((item) => {
            if (!this.isSelectable(item))
                deselectRowKeys.push(e.component.keyOf(item));
        });
        if (deselectRowKeys.length) {
            e.component.deselectRows(deselectRowKeys);
        }
        checkBoxUpdating = true;
        selectAllCheckBox.option("value", this.isSelectAll(e.component));

        this.selectedRowsData = e["selectedRowsData"];
    },

      onEditorPreparing(e) {
          let dataGrid = e.component;

          if (e["command"] === "select") {
              if (e["parentType"] === "dataRow" && e.row) {
                  if (!this.isSelectable(e.row.data))
                      e.editorOptions.disabled = true;
              } else if (e["parentType"] === "headerRow") {
                  e.editorOptions.onInitialized = (e) => {
                      selectAllCheckBox = e.component;
                  };
                  e.editorOptions.value = this.isSelectAll(dataGrid);
                  e.editorOptions.onValueChanged = (e) => {
                      if (!e.event) {
                          if (e.previousValue && !checkBoxUpdating) {
                              e.component.option("value", e.previousValue);
                          }
                          return;
                      }
                      if (this.isSelectAll(dataGrid) === e.value) {
                          return;
                      }
                      e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
                      e.event.preventDefault();
                  }
              }

          }
      },
  }
}
</script>

<style scoped>

</style>