<template>
    <div>
      <NotificationAlerts :applicationFilter="[ApplicationType.VBS]" />
      <v-row v-if="$vuetify.breakpoint.mobile">
          <v-col :cols="slotGridLength" v-if="showSlots">
              <AppointmentZoneSlotAvailability
                      id="zoneSlotAvailability"
                      ref="bookingPanel"
                      @hideSlot="hideSlot"
                      @showEditForm="showEditForm($event)"
                      @reloadAppointmentList="refreshList"
              />
          </v-col>
          <v-col :cols="formGridLength" v-if="showForm">
              <AppointmentForm
                      id="appointmentForm"
                      ref="formPanel"
                      @hideForm="hideForm"
                      @appointmentUpdated="appointmentUpdated"
                      @appointmentCreated="appointmentCreated"
              />
          </v-col>.<v-col :cols="listGridLength">
          <UnbookedImportAdvisedList
                  id="unbookedImportAdvisedList" ref="unbookedImportAdvisedList"
                  :panelExpanded="!currentAppointmentsPanelDisplayed" v-on:toggle-panel-click="switchAppointmentPanels"
                  v-on:refresh-button-click="refreshData"
          />
          <br/>
          <AppointmentAppointmentList
                  id="appointmentList"
                  ref="appointmentList"
                  title="Current Appointments"
                  :showSlots="showSlots"
                  :showForm="showForm"
                  :panelExpanded="currentAppointmentsPanelDisplayed"
                  @showSlot="showSlots = true"
                  @hideSlot="showSlots = false"
                  @showForm="showAppointmentForm($event)"
                  v-on:toggle-panel-click="switchAppointmentPanels"
                  :bookingPanelDisplayed="bookingPanelDisplayed"
                  v-on:open-booking-panel-click="toggleBookingPanel"
                  v-on:refresh-button-click="refreshData"
          />
      </v-col>
      </v-row>
      <v-row v-else>
          <v-col :cols="listGridLength">
              <UnbookedImportAdvisedList
                      id="unbookedImportAdvisedList" ref="unbookedImportAdvisedList"
                      :panelExpanded="!currentAppointmentsPanelDisplayed" v-on:toggle-panel-click="switchAppointmentPanels"
                      v-on:refresh-button-click="refreshData"
              />
              <br/>
              <AppointmentAppointmentList
                      id="appointmentList"
                      ref="appointmentList"
                      title="Current Appointments"
                      :showSlots="showSlots"
                      :showForm="showForm"
                      :panelExpanded="currentAppointmentsPanelDisplayed"
                      @showSlot="showSlots = true"
                      @hideSlot="showSlots = false"
                      @showForm="showAppointmentForm($event)"
                      v-on:toggle-panel-click="switchAppointmentPanels"
                      :bookingPanelDisplayed="bookingPanelDisplayed"
                      v-on:open-booking-panel-click="toggleBookingPanel"
                      v-on:refresh-button-click="refreshData"
              />
          </v-col>
          <v-col :cols="slotGridLength" v-if="showSlots">
              <AppointmentZoneSlotAvailability
                      id="zoneSlotAvailability"
                      ref="bookingPanel"
                      @hideSlot="hideSlot"
                      @showEditForm="showEditForm($event)"
                      @reloadAppointmentList="refreshList"
              />
          </v-col>
          <v-col :cols="formGridLength" v-if="showForm">
              <AppointmentForm
                      id="appointmentForm"
                      ref="formPanel"
                      @hideForm="hideForm"
                      @appointmentUpdated="appointmentUpdated"
                      @appointmentCreated="appointmentCreated"
              />
          </v-col>
      </v-row>
    </div>
</template>

<script>
import {ApplicationType} from '@/utils/constants.js';
import AppointmentZoneSlotAvailability from "@/components/appointment/Appointment-ZoneSlotAvailability.vue";
import AppointmentAppointmentList from "@/components/appointment/Appointment-AppointmentList.vue";
import UnbookedImportAdvisedList from '@/components/appointment/UnbookedImportAdvisedList.vue'
import AppointmentForm from "@/components/appointment/Appointment-Form.vue";
import NotificationAlerts from '@/components/global/NotificationAlerts.vue'
import {mapActions} from "vuex";

export default {
    name: 'appointments',
    components: {
      AppointmentZoneSlotAvailability,
      AppointmentAppointmentList,
      UnbookedImportAdvisedList,
      AppointmentForm,
      NotificationAlerts
    },

    data: () => ({
      showSlots : true,
      showForm : false,

      bookingPanelDisplayed: false,
      currentAppointmentsPanelDisplayed: true,
    }),

    computed : {
      listGridLength() {
        let length;
        switch (true) {
          case this.showForm :
            length = this.$vuetify.breakpoint.mobile ? 12 : 6;
            break;
          case this.showSlots :
            length = this.$vuetify.breakpoint.mobile ? 12 : 9;
            break;
          case this.showForm && this.showSlots :
            length = 12;
            break;
        }
        return length;
      },

      slotGridLength() {
        return this.showSlots ? this.$vuetify.breakpoint.mobile ? 12 : 3 : 0;
      },

      formGridLength() {
        return this.showForm ? this.$vuetify.breakpoint.mobile ? 12 : 6 : 0;
      },
    },

    mounted () {
        //hide booking panel by default, unless on a extra large screen
        this.bookingPanelDisplayed = this.$vuetify.breakpoint.xlOnly;
    },

    created() {
        //make enums availble within html tags
        this.ApplicationType = ApplicationType;
    },

    methods: {
      ...mapActions('appointments', [
          'resetWorkingAppointment'
      ]),

      toggleBookingPanel: function () {
          this.bookingPanelDisplayed = !this.bookingPanelDisplayed;
      },

      switchAppointmentPanels: function () {
          this.currentAppointmentsPanelDisplayed = !this.currentAppointmentsPanelDisplayed;
          App.$appAnalytics.trackEvent('VBS - ' + (this.currentAppointmentsPanelDisplayed ? 'Appointments' : 'Unbooked Import-Advised') + ' Panel - Expand');
      },

      refreshData: function () {
          App.$appAnalytics.trackEvent('VBS - Refresh Data - Click');

          this.$refs.unbookedImportAdvisedList.refreshData();
          this.$refs.appointmentList.refreshData();
          window.App.$emit('update-slotavailability');
      },

      refreshList: function () {
        this.$refs.appointmentList.fetchAppointments();
      },

      showAppointmentForm(edit) {  // eslint-disable-line no-unused-vars
        this.showForm = true; // Show Form
        this.showSlots = false; // Hide Slots
      },

      async showEditForm(date) {
        if (this.loadedComponentCounter > 0) {
          this.componentKey += 1;
        }
        this.loadedComponentCounter += 1;
        this.showEditMode = true;
        await this.$nextTick(() => {
          this.$refs.zoneSlotModal.init(date);
        });
      },

      hideSlot() {
        this.showSlots = false;
      },

      hideForm() {
        this.showForm = false;
        this.showSlots = true;
        this.resetWorkingAppointment();
      },

      appointmentUpdated() {
        this.showForm = false; // Hide Form
        this.showSlots = true; // Show Slots
        this.refreshList();
      },

      appointmentCreated() {
          this.showForm = false; // Hide Form
          this.showSlots = true; // Show Slots
          this.refreshList();
      },
    },
}
</script>

<style> 
    #zoneSlotAvailability.ExtraLargeScreen {
        width: 405px;
    }

    #zoneSlotAvailability.LargeScreen {
        width: 260px;
    }

    #zoneSlotAvailability.MediumScreen {
        width: 230px;
    }

    #appointmentList, #unbookedImportAdvisedList {
        display: flow-root; 
        overflow: auto;
    }

</style>