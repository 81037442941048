// noinspection JSCheckFunctionSignatures

import '@/utils/axios'
import {ImportGroupCommand} from '@/utils/constants.js';

export default {
    //GENERAL
    fetchAppSettings() {
        return window.axios
            .get('settings', {actionDescription: 'retrieving settings'})
    },

    
    // VESSELS
    fetchInboundVessels(ignoreError = false) {
        return window.axios
            .get('vessels/inbound', {
                actionDescription: 'retrieving inbound vessel information',
                globalErrorHandling: !ignoreError
            })
    },

    setImportVesselEstimatedLockOffDate(vesselVisitId, estimatedImportsLockOffDate) {
        return window.axios
            .put('vessels/import',
                null,
                {
                    actionDescription: 'setting an import vessel estimated lock off date',
                    params: {
                        vesselVisitId: vesselVisitId,
                        estimatedImportsLockOffDate: estimatedImportsLockOffDate
                    },
                })
    },

    
    //APPOINTMENTS
    fetchN4Appointment(appointmentId) {
        return window.axios
            .get('appointments/' + appointmentId, {actionDescription: 'retrieving appointment information'})
    },

    fetchPropelAppointment(appointmentId) {
        return window.axios
            .get('gateServices/appointments/' + appointmentId, {actionDescription: 'retrieving appointment information'})
    },

    fetchCurrentAppointments(allCompanies = false) {

        if (allCompanies) {
            return window.axios
                .get('companies/all/appointments/false', {actionDescription: 'retrieving current appointments for all companies'})
        } else {
            return window.axios
                .get('appointments', {actionDescription: 'retrieving current appointments'})
        }
    },

    fetchAppointmentsWithPast(allCompanies = false) {

        if (allCompanies) {
            return window.axios
                .get('companies/all/appointments/true', {actionDescription: 'retrieving current appointments for all companies'})
        } else {
            return window.axios
                .get('appointments', {actionDescription: 'retrieving appointments including past'})
        }
    },

    fetchAppointments(dateFrom, dateTo, includeExtendedContainerInfo) {
        return window.axios
            .get('appointments', {
                actionDescription: 'retrieving appointments',
                params: {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    includeExtendedContainerInfo: includeExtendedContainerInfo,
                }
            })
    },

    postN4Appointment(appointment) {
        return window.axios
            .post('appointments', appointment, {actionDescription: 'creating a new n4 appointment'})
    },

    postPropelGateAppointment(appointment) {
        return window.axios
            .post('gateServices/appointments', appointment, {actionDescription: 'creating a new gate appointment'})
    },

    putN4Appointment(appointment) {
        return window.axios
            .put('appointments', appointment, {actionDescription: 'updating the N4 appointment'})
    },

    putPropelGateAppointment(appointment) {
        return window.axios
            .put('gateServices/appointments', appointment, {actionDescription: 'updating the gate appointment'})
    },

    deleteAppointment(appointmentId) {
        return window.axios
            .delete('appointments/' + appointmentId, {actionDescription: 'deleting the appointment'})
    },

    deletePropelAppointment(appointmentId) {
        return window.axios
            .delete('gateServices/appointments/' + appointmentId, {actionDescription: 'deleting the appointment'})
    },

    postN4BulkBooking(date, zone, quantity) {
        return window.axios
            .post('appointments/BulkBooking',
                null,
                {
                    actionDescription: 'creating a bulk booking',
                    params: {
                        date: date,
                        zone: zone,
                        quantity: quantity
                    },
                })
    },

    postPropelGateBulkBooking(date, zone, quantity, gateId, company) {
        return window.axios
            .post('gateServices/appointments/BulkBooking',
                null,
                {
                    actionDescription: 'creating a bulk booking',
                    params: {
                        date: date,
                        zone: zone,
                        quantity: quantity,
                        gateId: gateId,
                        company : company
                    },
                })
    },


    //IMPORT ADVISED CONTAINERS - APPOINTMENTS VIEW
    fetchImportAdvisedContainers() {
        return window.axios
            .get('containers/import/advised', {actionDescription: 'retrieving import advised containers information'})
    },


    //IMPORT CONTAINERS - IMPORT ADVICE VIEW
    fetchImportContainers() {
        return window.axios
            .get('containers/import', {actionDescription: 'retrieving import containers'})
    },

    claimImportContainers(containers) {
        return window.axios
            .post('containers/import/claim', containers, {actionDescription: 'adding import containers'})
    },

    adviseImportContainers(containers, group) {
        if (group == ImportGroupCommand.RemoveGroup)
            group = "";
        
        return window.axios
            .post('containers/import/advise', {
                containers: containers,
                group: group
            }, {actionDescription: 'advising import containers'})
    },


    //IMPORT GROUPS
    fetchImportDayGroups() {
        return window.axios
            .get('containers/import/groups/day', {actionDescription: 'retrieving import day groups'})
    },

    //SLOTS
    fetchAvailableSlots(slotDate, ignoreError = false, gateId) {
        return window.axios
            .get('availableSlots', {
                actionDescription: 'retrieving available slots',
                params: {
                    date: slotDate,
                    gateId: gateId
                },
                globalErrorHandling: !ignoreError
            })
    },

    //EXPORT CONTAINERS - EXPORT PRE-ADVICE VIEW
    fetchExportPreAdviceContainers() {
        return window.axios
            .get('Containers/Export', {actionDescription: 'retrieving current export pre-advice containers'})
    },

    fetchExportPreAdviceEquipmentTypes() {
        return window.axios
            .get('EquipmentTypes/Export', {actionDescription: 'retrieving equipment types for export pre-advice'})
    },

    fetchExportPreAdviceTransportOperators() {
        return window.axios
            .get('TransportOperators/Export', {actionDescription: 'retrieving transport operators for export pre-advice'})
    },

    fetchExportPreAdviceShippingLineGroups() {
        return window.axios
            .get('Shippers/Export/ShippingLinesGroup', { actionDescription: 'retrieving shipping line groups'})
    },

    fetchExportPreAdviceContainerWithNumber(containerNumber) {
        return window.axios
            .get('Containers/Export/' + containerNumber, {actionDescription: 'retrieving container with given number for export pre-advice'})
    },
    
    fetchExportPreAdviceActiveContainer(containerNumber) {
        return window.axios
            .get('Containers/Export/Active/' + containerNumber, {actionDescription: 'retrieving container if Active in N4 with given number for export pre-advice'})
    },

    fetchExportPreAdviceBookingWithNumber(bookingNumber) {
        return window.axios
            .get('Bookings/Export/' + bookingNumber, {actionDescription: 'retrieving booking with given number for export pre-advice'});
    },

    fetchExportPreAdviceMatchingPackPoints(matchString) {
        return window.axios
            .get('PackPoints/Export/IdStartingWith', {
                actionDescription: 'retrieving pack points with their Id starting with given string, for export pre-advice',
                params: {
                    match: matchString
                }
            });
    },

    fetchExportPreAdviceCommodities() {
        return window.axios
            .get('Commodities/Export', {actionDescription: 'retrieving commodities for export pre-advice'})
    },

    fetchUserShipperDetails() {
        return window.axios
            .get('Shippers/Export/UserShipperDetails', {actionDescription: "retrieving shipper details for the user's company"});
    },

    fetchExportPreAdviceMatchingShippers(matchString) {
        return window.axios
            .get('Shippers/Export/IdOrNameMatching', {
                actionDescription: 'retrieving shippers with either their Id or Name matching the given string, for export pre-advice',
                params: {
                    match: matchString
                }
            });
    },

    postExportPreAdvice(exportPreAdviceContainer) {
        return window.axios
            .post('Containers/Export/Advise', exportPreAdviceContainer, {actionDescription: 'new export pre-advice'})
    },

    fetchExportPreAdviceDetails(containerNumber) {
        return window.axios
            .get('Containers/Export/' + containerNumber + '/Details',
                {actionDescription: 'retrieving details of a previously advised container, for export pre-advice'});
    },

    putExportPreAdvice(exportPreAdviceContainer) {
        return window.axios
            .put('Containers/Export/Advise', exportPreAdviceContainer, {actionDescription: 'update existing export pre-advice'})
    },

    //NOTIFICATIONS
    fetchWebsiteNotifications(ignoreError = false) {
        return window.axios
            .get('notifications/WebsiteAlerts', {globalErrorHandling: !ignoreError})
    },

    async fetchAllNotifications() {
        return window.axios
            .get('notifications', {actionDescription: 'retrieving notifications'})
    },

    postNotification(notification) {
        //as we are uploading a file, we are sending as a multipart form
        return window.axios
            .post('notifications',
                notification,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    actionDescription: 'creating a new notification'
                }
            )
    },

    putNotification(notification) {
        return window.axios
            .put('notifications', notification, {actionDescription: 'updating the notification'})
    },


    //USER ACCOUNT
    fetchAccount() {
        return window.axios
            .get('account', {actionDescription: 'retrieving account details'})
    },

    resendAccountVerificationEmail() {
        return window.axios
            .get('account/ResendVerificationEmail', {actionDescription: 'resending account verfication email'})
    },

    requestUserAccess(companyId, roles, mobileNumber) {
        return window.axios
            .put('account/RequestAccess', {
                companyId: companyId,
                roles: roles,
                mobileNumber: mobileNumber
            }, {actionDescription: 'requesting access'})
    },

    requestCompanyAccess(newCompanyRequest) {
        return window.axios
            .put('account/RequestCompanyAccess', newCompanyRequest, {actionDescription: 'requesting company access'})
    },


    //USER MANAGEMENT
    fetchUsers() {
        return window.axios
            .get('users', {actionDescription: 'retrieving users'})
    },

    fetchUsersForCompany(companyId) {
        return window.axios
            .get('companies/' + companyId + '/users', {actionDescription: 'retrieving users'})
    },

    fetchUserRolesForCompany(companyId) {
        return window.axios
            .get('companies/' + companyId + '/roles', {actionDescription: 'retrieving companies'})
    },

    fetchRoleUsers(role) {
      return window.axios
          .get('users/roleUsers/' + role, { actionDescription: 'retrieve users on the role specified'})
    },

    postUser(user) {
        return window.axios
            .post('users', user, {actionDescription: 'creating a new user'})
    },

    putUser(user) {
        return window.axios
            .put('users', user, {actionDescription: 'updating the user'})
    },

    activateUser(userId, roles) {
        return window.axios
            .put('users/' + userId + '/activate', roles, {actionDescription: 'approving the user'})
    },

    deactivateUser(userId) {
        return window.axios
            .delete('users/' + userId, {actionDescription: 'deactivating the user'})
    },


    //COMPANIES
    fetchCompaniesLookup() {
        return window.axios
            .get('companies/lookup', {actionDescription: 'retrieving companies'})
    },

    fetchCompaniesLite() {
        return window.axios
            .get('companies/lite', {actionDescription: 'retrieving companies'})
    },

    fetchCompanies() {
        return window.axios
            .get('companies', {actionDescription: 'retrieving companies'})
    },

    fetchVbsCompanies() {
        return window.axios
            .get('companies/app/vbs', { actionDescription: 'retrieving VBS customers' })
    },

    fetchTransportServiceLogisticCustomers() {
        return window.axios
            .get('companies/app/npl', { actionDescription: 'retrieving Transport Service Logistic customers' })
    },

    fetchFreightForwarders() {
        return window.axios
            .get('companies/app/ffw', { actionDescription: 'retrieving Freight Forwarders' })
    },

    fetchKiwiRailCustomers() {
        return window.axios
            .get('companies/app/krc', { actionDescription: 'retrieving Transport Service KiwiRail customers' })
    },

    postCompany(company) {
        return window.axios
            .post('companies', company, {actionDescription: 'creating a new company'})
    },

    putCompany(company) {
        return window.axios
            .put('companies', company, {actionDescription: 'updating the company'})
    },

    activateCompany(company) {
        return window.axios
            .put('companies/activate', company, {actionDescription: 'approving the company'})
    },

    deactivateCompany(companyId) {
        return window.axios
            .delete('companies/' + companyId, {actionDescription: 'deactivating the company'})
    },

    fetchApplications() {
        return window.axios
            .get('applications', {actionDescription: 'retrieving applications'})
    },


    //TRUCKS
    fetchTruckRegos() {
        return window.axios
            .get('trucks', {actionDescription: 'retrieving truck registration numbers'})
    },


    //CAMERAS
    fetchActiveCameras() {
        return window.axios
            .get('cameras/active', { actionDescription: 'retrieving cameras' })
    },

    fetchCamera(cameraId) {
        return window.axios
            .get('cameras/' + cameraId, { actionDescription: 'retrieving camera information' })
    },

    fetchAllCamera() {
        return window.axios
            .get('cameras/all', { actionDescription: 'retrieving all camera' })
    },

    fetchCameraGroups() {
        return window.axios
        .get('cameras/groups', { actionDescription: 'retrieving camera groups' })
    },

    postCamera(camera) {
        return window.axios
            .post('cameras', camera, { actionDescription: 'creating a new camera' })
    },

    putCamera(camera) {
        return window.axios
            .put('cameras', camera, { actionDescription: 'updating the camera' })
    },

    deleteCamera(cameraId) {
        return window.axios
        .delete('cameras/' + cameraId , { actionDescription: 'deleting the camera' })
    },

    changeSort(camera){
        return window.axios
        .put('cameras/sort' , camera ,  { actionDescription: 'change sorting of the camera' })
    },

    // GATE
    fetchAllGates() {
        return window.axios
            .get('gates', { actionDescription: 'retrieving all gates' })
    },

    fetchActiveGates() {
        return window.axios
            .get('gates/active', { actionDescription: 'retrieving active gates' })
    },

    fetchGateInfo(name) {
        return window.axios
            .get('gates/' + name , {actionDescription: 'retrieving gate info'})
    },

    postGate(gate) {
        return window.axios
            .post('gates', gate, { actionDescription: 'creating a new gate' })
    },

    putGate(gate) {
        return window.axios
            .put('gates', gate, { actionDescription: 'updating the gate' })
    },

    deleteGate(id) {
        return window.axios
            .delete('gates/'+ id, {actionDescription: 'deleting gate'})
    },

    // GATE SERVICE
    fetchGateServices() {
        return window.axios
            .get('gateServices', { actionDescription: 'retrieving active gate services' })
    },

    fetchAllGateServices() {
        return window.axios
            .get('gateServices/All', { actionDescription: 'retrieving all gate services' })
    },

    fetchGateServicesInfo(id) {
        return window.axios
            .get('gateServices/' + id , {actionDescription: 'retrieving gate services info'})
    },

    fetchEDODetails(id) {
        return window.axios
            .get('gateServices/edo/' + id , {actionDescription: 'retrieving EDO # info'})
    },

    postGateService(postData) {
        return window.axios
            .post('gateServices/', postData, { actionDescription: 'new gate service'})
    },

    postGateServiceZones(gateServices) {
        return  window.axios
            .post('gateServices/zonesAndSlots', gateServices, { actionDescription: 'creating new zones for a gate service'})
    },

    putGateServiceZones(gateServices) {
        return window.axios
            .put('gateServices/zonesAndSlots', gateServices, { actionDescription: 'updating zones and slots for a gate service'})
    },

    putGateService(putData) {
        return window.axios
            .put('gateServices/', putData, { actionDescription: 'update gate service'})
    },

    putGateZoneSlots(modifySlots) {
        return window.axios
            .put('gateServices/slots', modifySlots, {actionDescription: 'modifying slots'})
    },

    putBillingAppointment(id, bill) {
        return window.axios
            .put('gateServices/Appointments/Bill/'+ id + '/' + bill, { actionDescription: 'modifying bill'})
    },

    putCompleteAppointment(id) {
        return window.axios
            .put('gateServices/Appointments/Complete/'+ id, { actionDescription: 'completing appointment'})
    },

    removeGateServiceZones(gateServices) {
        return window.axios
            .put('gateServices/zonesAndSlots/remove', gateServices, {actionDescription: 'remove zones and slots for a gate service'})
    },

    removeGateService(id) {
        return window.axios
            .delete('gateServices/'+ id, { actionDescription: 'remove gate service'})
    },
    
    //REPORTS
    fetchReportData(reportId) {
        return window.axios
            .get('reports/' + reportId, { actionDescription: 'retrieving report data'})
    },

    fetchReportRecord(reportId) {
        return window.axios
            .get('reports/record/' + reportId, { actionDescrition: 'retrieving report record data'})
    },

    fetchReportList() {
        return window.axios
            .get('reports', { actionDescription: 'retrieve report list'})
    },

    fetchUserReportList() {
        return window.axios
            .get('reports/user/', { actionDescription: 'retrieving report list by User access'})
    },

    fetchReportColumnList(reportId) {
        return window.axios
            .get('reports/columns/'+ reportId, { actionDescription: 'retrieving report columns'} )
    },

    fetchReportFilterList(reportId) {
        return window.axios
            .get('reports/filters/'+ reportId, { actionDescription: 'retriving report filters'} )
    },

    fetchReportSourceList(context, type) {
        return window.axios
            .get('reports/sourceList/' + context + '/' + type , { actionDescription: 'retrieving source list'})
    },

    fetchReportSourceColumnList(context, type, source) {
        return window.axios
            .get('reports/sourceColumn/' + context + '/' + type + '/' + source, { actionDescription: "retrieving source column list"})
    },

    postFetchReportRecord(reportId, filters) {
        return window.axios
            .post('reports/record/' + reportId, filters, { actionDescrition: 'retrieving report record data'})
    },

    postReport(report) {
        return window.axios
            .post('reports', report, {actionDescription: "creating new report"});
    },

    putReport(report) {
        return window.axios
            .put('reports', report, { actionDescription: "updating report"})
    },

    deleteReport(reportId) {
        return window.axios
            .delete('reports/'+ reportId, { actionDescription: 'deleting report'})
    },


    //region Transport

        //region Transport Mappings

            //region GET
            fetchContainerTerminalSitesAndSidings() {
                return window.axios
                    .get('transportMapping/ContainerTerminal&Sidings', { actionDescription: 'fetch terminal and siding sites'})
            },

            fetchContainerTerminalSiteAndSidingById(id) {
                return window.axios
                    .get('transportMapping/ContainerTerminal&Sidings/' + id, { actionDescription: 'fetch terminal site and sidings data'})
            },

            fetchCustomerSites(id) {
                return window.axios
                    .get('transportMapping/CustomerSites/'+ id, { actionDescription: 'fetch Customer Sites'})
            },

            fetchCustomerSiteInfo(id) {
                return window.axios
                    .get('transportMapping/CustomerSites/Info/'+ id, { actionDescription: 'fetch Customer site data'})
            },

            fetchRoadBridges() {
                return window.axios
                    .get('transportMapping/RoadBridges', { actionDescription: 'fetch all road bridges'})
            },

            fetchRoadBridgeById(id) {
                return window.axios
                    .get('transportMapping/RoadBridges/' + id, { actionDescription: 'fetch road bridge data'})
            },

            fetchFreightForwarderCustomers(id) {
                return window.axios
                    .get('transportMapping/FreightForwarders/' + id, { actionDescription: 'fetch freight forwarder customers'})
            },

            fetchMappingTransportOperators() {
                return window.axios
                    .get('transportMapping/TransportOperators', { actionDescription: 'fetch transport operators'})
            },
            //endregion

            //region POST
            postContainerTerminalSitesAndSidings(data) {
                return window.axios
                    .post('transportMapping/ContainerTerminal&Sidings', data, { actionDescription: 'create terminal sites and sidings'})
            },

            postCustomerSiteAndSidings(id,data) {
                return window.axios
                    .post('transportMapping/CustomerSites/' + id, data, { actionDescription: 'create customer terminal sites and sidings'})
            },

            postRoadBridge(data) {
                return window.axios
                    .post('transportMapping/RoadBridges', data, { actionDescription: 'create road bridges'})
            },

            postFreightForwarder(data) {
                return window.axios
                    .post('transportMapping/FreightForwarder', data, { actionDescription: 'create freight forwarder and associated customers'});
            },

            postTransportOperator(data) {
                return window.axios
                    .post('transportMapping/TransportOperator', data, { actionDescription: 'create transport operator'});
            },
            //endregion

            //region PUT
            putContainerTerminalSitesAndSidings(data) {
                return window.axios
                    .put('transportMapping/ContainerTerminal&Sidings', data, { actionDescription: 'update terminal sites and sidings data'})
            },

            putCustomerSiteAndSidings(data) {
                return window.axios
                    .put('transportMapping/CustomerSites', data, { actionDescription: 'update customer site and sidings data'})
            },

            putRoadBridge(data) {
                return window.axios
                    .put('transportMapping/RoadBridges', data, { actionDescription: 'update road bridges'})
            },

            putTransportOperator(data) {
                return window.axios
                    .put('transportMapping/TransportOperator', data, { actionDescription: 'update transport operator'})
            },

            putFreightForwarderCustomer(data) {
                return window.axios
                    .put('transportMapping/FreightForwarderCustomer', data, { actionDescription: 'update freight forwarder customer'})
            },
            //endregion

            //region DELETE
            deleteContainerTerminalSitesAndSidings(id) {
                return window.axios
                    .delete('transportMapping/ContainerTerminal&Sidings/' + id, { actionDescription: 'delete terminal site'})
            },

            deleteCustomerSiteAndSidings(id) {
                return window.axios
                    .delete('transportMapping/CustomerSites/' + id, { actionDescription: 'delete customer site'})
            },

            deleteRoadBridgeBridge(id) {
                return window.axios
                    .delete('transportMapping/RoadBridges/' + id, { actionDescription: 'delete road bridge'})
            },

            deleteFreightCustomer(id) {
                return window.axios
                    .delete('transportMapping/freightForwarder/' + id, { actionDescription: 'delete freight forwarder customer'})
            },
            //endregion

        //endregion

        //region Transport Service

            //region GET
            fetchTrainServices() {
                return window.axios
                    .get('transportService/TrainService', { actionDescription: 'fetch Train Services'})
            },

            fetchTrainServiceInfo(id) {
                return window.axios
                    .get('transportService/TrainService/' + id, { actionDescription: 'fetch Train Service Info'})
            },
            //endregion

            //region POST
            postTrainService(data) {
                return window.axios
                    .post('transportService/TrainService', data, { actionDescription: 'create train service'})
            },

            postTrainServiceRoute(data) {
                return window.axios
                    .post('transportService/Wagon', data, { actionDescription: 'add new route in the instance'})
            },

            postTrainServiceRoutes(data) {
                return window.axios
                    .post('transportService/Wagons', data, { actionDescription: 'add new routes in the service'})
            },
            //endregion

            //region PUT
            putTrainServiceDetails(data) {
                return window.axios
                    .put('transportService/Details', data, { actionDescription: 'update service details'})
            },

            putTrainServiceDates(data) {
                return window.axios
                    .put('transportService/Dates', data, { actionDescription: 'update service dates'})
            },

            putTrainServiceDays(data) {
                return window.axios
                    .put('transportService/Days', data, { actionDescription: 'update service days'})
            },

            putTrainServiceRoutes(data) {
                return window.axios
                    .put('transportService/Wagons', data, { actionDescription: 'update routes'})
            },

            cancelTrainServiceRoutes(data) {
                return window.axios
                    .put('transportService/Wagons/Cancel', data, { actionDescription: 'remove routes'})
            },

            //endregion

            //region DELETE
            deleteTrainService(id) {
                return window.axios
                    .delete('transportService/' + id, { actionDescription: 'delete service'})
            },

            deleteInstance(id) {
                return window.axios
                    .delete('transportService/Instance/' + id, { actionDescription: 'delete capacity'})
            },

            deleteCapacity(id) {
                return window.axios
                    .delete('transportService/Capacity/' + id, { actionDescription: 'delete capacity'})
            },
            //endregion

        //endregion

        //region Transport Planner

            //region GET
            fetchPlannerListByDates(dateFrom, dateTo) {
                return window.axios
                    .get('transportPlanner/Date/'+ dateFrom +'/'+ dateTo, { actionDescription: 'fetch Planner List by Dates'})
            },

            fetchPlannerWagonListByDates(dateFrom, dateTo) {
                return window.axios
                    .get('transportPlanner/WagonList/' + dateFrom + '/' + dateTo, { actionDescription: 'fetch Planner Wagon List by Dates'})
            },

            fetchPlannerWagonListByInstance(id) {
                return window.axios
                    .get('transportPlanner/WagonList/'+ id, { actionDescription: 'fetch Planner Wagon List by Instance'})
            },

            fetchPlannerBookingData(id) {
                return window.axios
                    .get('transportPlanner/Booking/'+ id, { actionDescription: 'fetch booking data'})
            },

            fetchCheckContainerIfAllowedToBook(id, company, forwarder) {
                return window.axios
                    .get('transportPlanner/Booking/CheckContainer/' + id + '/' + company + '/' + forwarder , { actionDescription: 'check if allowed to book container'});
            },

            fetchPlannerBookingContainerData(id, company, forwarder) {
                return window.axios
                    .get('transportPlanner/Booking/Container/' + id + '/' + company + '/' + forwarder , { actionDescription: 'fetch booking container data'});
            },

            fetchAvailableBookingDates(id) {
                return window.axios
                    .get('transportPlanner/Booking/SwitchDates/' + id, { actionDescription: 'fetch available dates'})
            },

            fetchPlannerN4ContainerData(id) {
                return window.axios
                    .get('transportPlanner/N4/Container/' + id, { actionDescription: 'fetch n4 container data'});
            },
            //endregion

            //region POST
            postPlannerBooking(data) {
                return window.axios
                    .post('transportPlanner/Booking', data, { actionDescription: 'create planner booking'})
            },
            //endregion

            //region PUT
            putPlannerBooking(data) {
                return window.axios
                    .put('transportPlanner/Booking', data, { actionDescription: 'update planner booking'})
            },

            putMergeWagonSlots(id) {
                return window.axios
                    .put('transportPlanner/Merge/' + id, { actionDescription: 'Merge Wagon Slots'})
            },

            putChangeBookingStatus(id, status) {
                return window.axios
                    .put('transportPlanner/BookingStatus/'+ id + '/' + status, { actionDescription: 'Change Booking Status'})
            },

            putChangeBookingDate(data) {
                return window.axios
                    .put('transportPlanner/BookingDate', data, { actionDescription: 'change booking date' })
            },

            putCalculateInvoice(id) {
                return window.axios
                    .put('transportPlanner/CalculateInvoice/' + id + '/false', { actionDescription: 'calculate invoice - normal'});
            },

            putCalculateInvoiceWithDefaultItems(id) {
                return window.axios
                    .put('transportPlanner/CalculateInvoice/' + id + '/true', { actionDescription: 'calculate invoice - use default billing items'});
            },

            putReCalculateInvoice(id) {
                return window.axios
                    .put('transportPlanner/ReCalculateInvoice/' + id + '/false', { actionDescription: 're-calculate invoice - normal'});
            },

            putReCalculateInvoiceWithDefaultItems(id) {
                return window.axios
                    .put('transportPlanner/ReCalculateInvoice/' + id + '/true', { actionDescription: 're-calculate invoice - use default billing items'});
            },

            //endregion

            //region DELETE

            deletePlannerBooking(id) {
                return window.axios
                    .delete('transportPlanner/Booking/' + id, { actionDescription: 'delete booking'})
            },

            deleteInvoiceItem(e) {
                return window.axios
                    .delete('transportPlanner/InvoiceItem/' + e, { actionDescription: 'delete invoice item'})
            },
            //endregion

        //endregion

        //region Transport Billings

            //region GET
            fetchBillingItemTypes() {
                return window.axios
                    .get('transportBilling/BillingItemTypes', { actionDescription: 'fetch billing item types'})
            },

            fetchBillingItems() {
                return window.axios
                    .get('transportBilling/BillingItems', { actionDescription: 'fetch billing items'})
            },

            fetchBillingCustomerItems(id) {
                return window.axios
                    .get('transportBilling/BillingCustomerItems/' + id, { actionDescription: 'fetch billing customer items'})
            },

            fetchBillingCustomerItemsOfSecondaryCustomer(id,freightForwarderId) {
                return window.axios
                    .get('transportBilling/BillingCustomerItems/' + id + '/' + freightForwarderId, { actionDescription: 'fetch billing customer items'})
            },

            fetchBookingInvoice(id) {
                return window.axios
                    .get('transportBilling/BookingInvoice/' + id, { actionDescription: 'fetch booking invoice'});
            },

            fetchAllInvoices(dateFrom, dateTo) {
                return window.axios
                    .get('transportBilling/AllInvoices/'+ dateFrom + '/' + dateTo, { actionDescription: 'fetch all invoices'});
            },

            fetchUnprocessedInvoices() {
                return window.axios
                    .get('transportBilling/AllUnprocessedInvoices', { actionDescription: 'fetch all unprocessed invoices'});
            },
            //endregion

            //region POST
            postBillingItem(data) {
                return window.axios
                    .post('transportBilling/BillingItem', data, { actionDescription: 'create billing item'});
            },

            postBillingCustomerItem(data) {
                return window.axios
                    .post('transportBilling/BillingCustomerItem', data, { actionDescription: 'create billing customer item'});
            },

            postBookingInvoiceItem(data) {
                return window.axios
                    .post('transportBilling/InvoiceItem', data, { actionDescription: 'create booking invoice item'});
            },

            postGenerateInvoiceFiles(data) {
                return window.axios
                    .post('transportBilling/GenerateInvoiceFiles', data, { actionDescription: 'generate invoice files', responseType: 'blob'});
            },

            postRecalculateInvoices(data) {
                return window.axios
                    .post('transportBilling/RecalculateInvoices', data, { actionDescription: 'recalculate invoices'});
            },
            //endregion

            //region PUT
            putBillingItem(data) {
                return window.axios
                    .put('transportBilling/BillingItem', data, { actionDescription: 'update billing item'});
            },

            putBillingCustomerItem(data) {
                return window.axios
                    .put('transportBilling/BillingCustomerItem', data, { actionDescription: 'update billing customer item'});
            },

            putBookingInvoiceItem(data) {
                return window.axios
                    .put('transportBilling/InvoiceItem', data, { actionDescription: 'update booking invoice item'});
            },

            putSyncAllBillingItemsToCustomer(id) {
                return window.axios
                    .put('transportBilling/SyncAllBillingItemsToCustomer/' + id, { actionDescription: 'sync all billing items to customer'});
            },

            putSyncAllBillingItemsToForwardersCustomer(id, freightForwarderId) {
                return window.axios
                    .put('transportBilling/SyncAllBillingItemsToForwardersCustomer/' + id + '/' + freightForwarderId, { actionDescription: 'sync all billing items to customer'});
            },

            putResetAllRatesOfCustomer(id) {
                return window.axios
                    .put('transportBilling/ResetAllRatesOfCustomer/' + id, { actionDescription: 'reset all rates of customer'});
            },

            putResetAllRatesOfForwardersCustomer(id, freightForwarderId) {
                return window.axios
                    .put('transportBilling/ResetAllRatesOfForwardersCustomer/' + id + '/' + freightForwarderId, { actionDescription: 'reset all rates of customer'});
            },
            //endregion

            //region DELETE

            deleteBillingItem(id) {
                return window.axios
                    .delete('transportBilling/BillingItem/' + id, { actionDescription: 'delete billing item'});
            },

            deleteBillingCustomerItem(id) {
                return window.axios
                    .delete('transportBilling/BillingCustomerItem/' + id, { actionDescription: 'delete billing customer item'});
            },

            //endregion

        //endregion

        //region Transport N4

            //region GET
            fetchTransportPlannerCommodities() {
                return window.axios
                    .get('TransportN4/Commodities', {actionDescription: 'retrieving commodities for transport planner'})
            },

            fetchTransportPlannerEquipmentTypes() {
                return window.axios
                    .get('TransportN4/EquipmentTypes', {actionDescription: 'retrieving equipment types for transport planner'})
            },

            fetchTransportPlannerTransportOperators() {
                return window.axios
                    .get('TransportN4/TruckingCompanies', {actionDescription: 'retrieving transport operators for transport planner'})
            },

            //endregion

        //endregion

    //endregion


}