import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import CMSApi from './services/CMSApi'
import generalHelpers from './utils/generalHelpers.js'
import moment from 'moment'
import store from './store/'
import VueAppAnalytics from './plugins/VueAppAnalytics'

import "@/assets/css/global.css"

//devextreme theming
import 'devextreme/dist/css/dx.common.css';
import "@/assets/dev-extreme-theme/dx.material.propel-darkblue.css"


import { Auth0Plugin } from "./auth";

Vue.use(Auth0Plugin, {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});

Vue.use(VueAppAnalytics, {
    appEventPrefix: '(WebApp)', // prefix to use when tracking activities/events
    appVersion: process.env.VUE_APP_VERSION,
    router,
    enableVueErrorExceptionTracking: process.env.VUE_APP_ENABLE_ERROR_LOGGING === 'true',
    enableWindowOnErrorExceptionTracking: process.env.VUE_APP_ENABLE_ERROR_LOGGING === 'true',
    enableOnunhandledRejectionExceptionTracking: false,
    azureAppInsightsConfig: {
        connectionString: process.env.VUE_APP_AZURE_APP_INSIGHTS_CONNECTION_STRING,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        enableUnhandledPromiseRejectionTracking: false,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
    },
    raygunConfig: {
        apiKey: process.env.VUE_APP_RAYGUN_APIKEY,
        options: {
            captureUnhandledRejections: false            
        }
    }
})

Vue.config.productionTip = false


//global directives
Vue.directive('visible', function(el, binding) {
	el.style.visibility = binding.value ? 'visible' : 'hidden';
});

import inputUpperAlphaNumeric from '@/directives/inputUpperAlphaNumeric'
Vue.directive('input-upper-alpha-numeric', inputUpperAlphaNumeric)


//global text format filters
Vue.filter('formatDate', function(value) {
    if (value) {
        return window.App.formatDate(value);
    }
});

Vue.filter('formatTime', function(value) {
    if (value) {
        return window.App.formatTime(value);
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return window.App.formatDateTime(value);
    }
});


window.Moment = moment;
window.CMSApi = CMSApi;

window.App = new Vue({
    vuetify,
    router,
    mixins: [generalHelpers],
    store,
    render: h => h(App),
	beforeCreate() {
		this.$store.commit('notifications/initialiseStore');
	}
})

window.App.$mount('#app')