import utils from '@/utils/vuexUtils.js'
import exportPreAdviceData from '@/models/exportPreAdvice/exportPreAdviceData.js'

// initial state
const state = {
    exportPreAdvisedContainers: [],
    workingExportPreAdvice: new exportPreAdviceData.PreAdviceDetails(),      // export pre-advice data currently being created or updated
    equipmentTypes: [],
    equipmentTypesCacheIsValid: false,
    transportOperators: [],     // Not caching transporters here. Some caching does happen on the server side
    commodities: [],
    commoditiesCacheIsValid: false,
    shippingLines: []
}

// getters
const getters = {
    workingContainerDetails: state => {
        return state.workingExportPreAdvice.containerDetails;
    },
    workingBookingDetails: state => {
        return state.workingExportPreAdvice.bookingDetails;
    },
    workingFreightDetails: state => {
        return state.workingExportPreAdvice.freightDetails;
    },
    workingAdditionalContainerDetails: state => {
        return state.workingExportPreAdvice.additionalContainerDetails;
    },
    workingAdvice: state => {
        return state.workingExportPreAdvice;
    }
}

// actions
const actions = {
    fetchExportPreAdviceContainers: ({commit}) => {
        return utils.CommitArrayPromise(commit, CMSApi.fetchExportPreAdviceContainers(), 'setExportPreAdvisedContainers');
    },

    fetchExportPreAdviceShippingLineGroups: ({commit}) => {
        return utils.CommitArrayPromise(commit, CMSApi.fetchExportPreAdviceShippingLineGroups(), 'setExportPreAdvisedShippingLines');
    },

    resetWorkingExportPreAdvice: ({commit}) => {
        let newAdvice = new exportPreAdviceData.PreAdviceDetails();
        commit('setCurrentWorkingPreAdvice', newAdvice);
    },

    setWorkingExportPreAdviceForSameBooking: ({commit}) => {
        let sameBookingAdvice = state.workingExportPreAdvice;

        sameBookingAdvice.exportPreAdviceId = null;
        sameBookingAdvice.containerDetails.containerNumber = '';
        sameBookingAdvice.containerDetails.selectedContainerOrTypeahead = null;
        sameBookingAdvice.containerDetails.containerMessage = '';
        sameBookingAdvice.containerDetails.vgmWeight = null;
        sameBookingAdvice.containerDetails.vgmVerifier = '';
        sameBookingAdvice.containerDetails.sealNumbers.splice(0);

        commit('setCurrentWorkingPreAdvice', sameBookingAdvice);
    },

    setWorkingAdviceDataForEdit: ({commit}, flattenedAdviceData) => {
        let workingAdvice = new exportPreAdviceData.PreAdviceDetails();

        workingAdvice.setFromFlattenedData(flattenedAdviceData);
        commit('setCurrentWorkingPreAdvice', workingAdvice);
    },

    setWorkingContainerData: ({commit}, containerData) => {
        commit('setCurrentWorkingContainerData', containerData);
    },

    setWorkingBookingData: ({commit}, bookingData) => {
        commit('setCurrentWorkingBookingData', bookingData);
    },

    setWorkingFreightData: ({commit}, freightData) => {
        commit('setCurrentWorkingFreightData', freightData);
    },

    setWorkingAdditionalContainerData: ({commit}, additionalContainerData) => {
        commit('setCurrentWorkingAdditionalContainerData', additionalContainerData);
    },

    fetchEquipmentTypes: ({commit}) => {
        if (!state.equipmentTypesCacheIsValid) {
            return utils.CommitArrayPromise(commit, CMSApi.fetchExportPreAdviceEquipmentTypes(), 'setEquipmentTypes');
        }
    },

    fetchTransportOperators: ({commit}) => {
        return utils.CommitArrayPromise(commit, CMSApi.fetchExportPreAdviceTransportOperators(), 'setTransportOperators');
    },

    fetchCommodities: ({commit}) => {
        if (!state.commoditiesCacheIsValid) {
            return utils.CommitArrayPromise(commit, CMSApi.fetchExportPreAdviceCommodities(), 'setCommodities');
        }
    },
}

// mutations
const mutations = {
    setExportPreAdvisedContainers(state, containers) {
        state.exportPreAdvisedContainers = containers.slice();
    },

    setExportPreAdvisedShippingLines(state, shippingLines) {
        state.shippingLines = shippingLines;
    },

    setCurrentWorkingPreAdvice(state, advice) {
        state.workingExportPreAdvice = advice;
    },

    setCurrentWorkingContainerData(state, containerData) {
        state.workingExportPreAdvice.containerDetails = containerData;
    },

    setCurrentWorkingBookingData(state, bookingData) {
        state.workingExportPreAdvice.bookingDetails = bookingData;
    },

    setCurrentWorkingFreightData(state, freightData) {
        state.workingExportPreAdvice.freightDetails = freightData;
    },

    setCurrentWorkingAdditionalContainerData(state, additionalContainerData) {
        state.workingExportPreAdvice.additionalContainerDetails = additionalContainerData;
    },

    setEquipmentTypes(state, equipmentTypes) {
        state.equipmentTypes = equipmentTypes.slice();

        state.equipmentTypesCacheIsValid = true;
        setTimeout(function () {
            // keep a cache of 5 mins 
            state.equipmentTypesCacheIsValid = false;
        }, 5 * 60 * 1000);
    },

    setTransportOperators(state, transporters) {
        state.transportOperators = transporters.slice();
    },

    setCommodities(state, commodities) {
        state.commodities = commodities.slice();

        state.commoditiesCacheIsValid = true;
        setTimeout(function () {
            // keep a cache of 5 mins 
            state.commoditiesCacheIsValid = false;
        }, 5 * 60 * 1000);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
