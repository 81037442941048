<template>
    <div>
        <v-card elevation="1">
            <v-toolbar class="datatableToolbar" flat>
                <v-toolbar-title>Appointment Report</v-toolbar-title>
                
                <div class="flex-grow-1"></div>
                <v-btn                   
                    tile outlined class="mb-2" 
                    color="secondary"
                    @click="exportXlsFile" >
                        <v-icon>mdi-file-excel-outline</v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Export XLS</span>
                </v-btn>
            </v-toolbar>
            
            <v-container class="datatableControls">
                <v-row>
                    <v-col cols="6" sm="4" md="2" lg="2" xl="1">
                        <v-menu
                            v-model="fromDateMenu"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="computedFromDateFormatted"
                                    label="From"
                                    readonly
                                    prepend-icon="mdi-calendar"
                                    hide-details="auto"
                                    :error-messages="errorMessages['dateFrom']"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="fromDate" @input="fromDateMenu = false"></v-date-picker>
                        </v-menu>
                    </v-col>                     
                    <v-col cols="6" sm="4" md="2" lg="2" xl="1">
                        <v-menu
                            v-model="toDateMenu"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="computedToDateFormatted"
                                    label="To"
                                    readonly
                                    prepend-icon="mdi-calendar"
                                    hide-details="auto"
                                    :error-messages="errorMessages['dateTo']"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="toDate" @input="toDateMenu = false"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" sm="4" lg="2" xl="5">
                        <v-btn                   
                            tile
                            color="secondary" 
                            @click="fetchAppointments" >
                                <span>Go</span>
                        </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6" lg="3" xl="2">
                        <v-checkbox v-model="showAdvancedFiltering" label="Show Advanced Filtering" hide-details></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="6" lg="3" xl="3">
                        <v-text-field clearable v-model="searchText" label="Search by Appointment #, Rego or Container Number" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
                    </v-col>
                                            
                </v-row>
            </v-container>
            <DxDataGrid
                id="appointmentReportgrid"
                :ref="dataGridRefName"
                :data-source="appointments"
                :show-borders="false"
                :filter-builder="filterBuilder"
                :filter-value="filterValue"
                :hoverStateEnabled="true"
                :column-auto-width="true"
            >
                <DxPaging :page-size="10"/>
                <DxPager
                    :show-page-size-selector="true"
                    :allowed-page-sizes="pageSizes"
                    :show-info="true"
                />
                <DxFilterRow
                    :visible="showAdvancedFiltering"
                />
                <DxHeaderFilter
                    :visible="true"
                />
                <DxFilterPanel :visible="showAdvancedFiltering"/>
                <DxFilterBuilderPopup :position="filterBuilderPopupPosition"/>

                <DxSorting mode="multiple"/>

                <DxColumn
                    :width="110"
                    data-field="appointmentDate"
                    alignment="left"
                    data-type="date"
                    caption="Date"
                    format="dd-MM-yyyy"
                />
                <DxColumn data-field="zone" 
                            :allowSearch="false"
                            alignment="right" />
                <DxColumn data-field="truckRegistration" data-type="string" 
                            :header-filter="{ allowSearch: true }" 
                            caption="Rego" />
                <DxColumn data-field="appointmentType" data-type="string"
                            :customize-text="formatAppointmentTypeCell" 
                            :allowSearch="false"
                            :allowFiltering="false" 
                            :allowHeaderFiltering="true" 
                            caption="Trans Type"
                            :hiding-priority="4" />
                <DxColumn data-field="containerNumber" data-type="string"
                            :header-filter="{ allowSearch: true }" 
                            caption="Container / Group #" :hiding-priority="3" />
                <DxColumn data-field="containerVGMWeight" data-type="number"
                            :customize-text="formatWeightCell"
                            :allowSearch="false"                              
                            :header-filter="{ dataSource: saleAmountHeaderFilter }" 
                            caption="Weight (kg)" alignment="right"
                            :hiding-priority="1" />
                <DxColumn data-field="appointmentId" data-type="string" 
                            :header-filter="{ allowSearch: true }" 
                            caption="Appointment #" alignment="left" />
                <DxColumn data-field="status" caption="State"
                            :allowFiltering="false" 
                            :allowHeaderFiltering="true" 
                            :hiding-priority="2" />
                <!-- extra empty column for better layout --> 
                <DxColumn :width="$vuetify.breakpoint.xl ? 400 : 200" :visible="$vuetify.breakpoint.lgAndUp" />
            </DxDataGrid>
        </v-card>
    </div> 
</template>

<script>
import {EnumHelpers} from '@/utils/constants.js';
import generalHelpers from '@/utils/generalHelpers.js'

import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    DxFilterPanel,
    DxFilterBuilderPopup,
    DxPager, 
    DxPaging,
    DxSorting,
} from 'devextreme-vue/data-grid';
import 'devextreme/data/odata/store';

export default {
    mixins: [generalHelpers],
    components: {
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxFilterRow,
        DxFilterPanel,
        DxFilterBuilderPopup,
        DxPager,
        DxPaging,
        DxSorting,
    },
    data () {
        return {
            fromDate: window.Moment().startOf('day').subtract(6, 'days').format('YYYY-MM-DD'),
            fromDateMenu: false,
            toDate: window.Moment().endOf('day').format('YYYY-MM-DD'),
            toDateMenu: false,
            appointments: [],
            loadingAppointments: false,
            searchText: '',
            errorMessages: '',
            pageSizes: [10, 25, 50, 100],
            saleAmountHeaderFilter:  [{
                    text: 'Less than 5000 kg',
                    value: ['containerVGMWeight', '<', 5000]
                }, {
                    text: '5000 - 20000kg',
                    value: [
                        ['containerVGMWeight', '>=', 5000],
                        ['containerVGMWeight', '<', 20000]
                    ]
                }, {
                    text: 'Greater than 20000kg',
                    value: ['containerVGMWeight', '>=', 20000]
            }],
            dataGridRefName: 'dataGrid',
            filterBuilder: {},
            filterValue: '',
            filterBuilderPopupPosition: {
                of: window,
                at: 'top',
                my: 'top',
                offset: { y: 30 }
            },
            showAdvancedFiltering: false,
        }
    },

    watch: {
        searchText: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            this.$refs[this.dataGridRefName].instance.searchByText(newVal?.trim());
        },

        loadingAppointments: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            if(newVal)
                this.$refs[this.dataGridRefName].instance.beginCustomLoading();
            else
                this.$refs[this.dataGridRefName].instance.endCustomLoading();
        }
    },

    mounted () {
        this.fetchAppointments();
    },

    beforeDestroy () {
        this.appointments = [];
    },

    computed: {
        computedFromDateFormatted() {
            return this.formatDate(this.fromDate)
        },

        computedToDateFormatted() {
            return this.formatDate(this.toDate)
        },
    },
    
    methods: {
        fetchAppointments() {
            this.errorMessages = {};
            this.loadingAppointments = true;
            
            CMSApi.fetchAppointments(this.fromDate, this.toDate, true)
                .then(data => {
                    this.appointments = data
                })
                .catch(error => {
                    this.errorMessages = error.response.data.errors;
                })
                .finally(() => {
                    this.loadingAppointments = false;
                });                
        },

        formatAppointmentTypeCell(cellInfo) {
            let appointmentType = EnumHelpers.getAppointmentTypeByCode(cellInfo.value);
            return (appointmentType ? appointmentType.description : '');
        },

        formatWeightCell(cellInfo) {
            return (cellInfo.value == 0 ? '': cellInfo.value.toString());
        },

        exportXlsFile() {
            App.$appAnalytics.trackEvent('Reports - Appointments - Export xls file Click');
            this.$refs[this.dataGridRefName].instance._options.export.fileName = "Propel Appointments"
            this.$refs[this.dataGridRefName].instance.exportToExcel(false);
        }
    }
};
</script>