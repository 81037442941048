<template>
    <v-card elevation="1" tile>
        <v-toolbar class="datatableToolbar" flat @click="$emit('toggle-panel-click')">
            <v-toolbar-title><slot name="headerTitle"></slot></v-toolbar-title>
            <v-icon 
                large color="secondary"
                v-if="showExpansionControl"
                :class="['rotate', this.panelExpanded ? 'down' : '']">
              mdi-chevron-down
            </v-icon>
        
            <div class="flex-grow-1"></div>
            <div @click="stopEventPropagation" class="headerToolbar">
                <slot name="headerToolbar"></slot>                
            </div>      
        </v-toolbar>

        <v-expand-transition>
            <div v-show="panelExpanded">
                <slot></slot>
            </div>  
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    props: {
        panelExpanded: Boolean,
        showExpansionControl: {
            type: Boolean,
            default: true
        },
    },
    
    methods: {
        stopEventPropagation() {
            event.stopPropagation();
        },
    }
};
</script>

<style scoped>
    .rotate {
        -moz-transition: all 0.25s linear;
        -webkit-transition: all .025s linear;
        transition: all 0.25s linear;
    }

    .rotate.down {
        -moz-transform:rotate(180deg);
        -webkit-transform:rotate(180deg);
        transform:rotate(180deg);
    }

    .headerToolbar {
        white-space: nowrap;
    }
</style>