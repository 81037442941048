<template>
    <v-app-bar app color="propelGreen" class="propelDarkGrey--text" clipped-left dense>
        <v-app-bar-nav-icon v-show="$auth.hasAccess" @click.stop="$emit('menu-click')"></v-app-bar-nav-icon>
        <v-toolbar-title id="propelTitle" >
            <router-link to="/">
                <img src="@/assets/img/propel_logo_ongreen.png" height="34px" />
            </router-link>
        </v-toolbar-title>
        <v-toolbar-title id="appEnvironment" v-if="appEnvironment != 'Production' && $vuetify.breakpoint.smAndUp">
            {{this.appEnvironment}}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div v-if="$auth.hasAccess">
            <v-badge
                id="notifictionBadge"
                color="secondary"
                overlap
                :content="newNotificationCount"
                :value="newNotificationCount"
                >
                <v-btn small icon to="/notifications" active-class="btnRouterLinknoActiveClass" >
                    <v-icon size="26">mdi-bell-outline</v-icon>
                </v-btn>            
            </v-badge>
        </div>

        <v-menu
            v-if="$auth.hasAccess"                 
            tile
            offset-y
            open-on-hover
            >
            <template v-slot:activator="{ on }">
                <div v-on="on" id="userInfo">
                    <v-avatar id="userAvatar" size="30px">
                        <v-icon large>mdi-account-circle</v-icon>
                    </v-avatar>
                    <div id="userDetails" v-if="$vuetify.breakpoint.smAndUp">
                        {{ $auth.user.name }}
                        <br>
                        <span id="companyDetails">{{ companyName }}</span>
                    </div>
                </div>    
            </template>
            <v-list id="userMenuList" dense tile color="propelGreen">
                <v-list-item  v-if="!$vuetify.breakpoint.smAndUp">
                    <div id="userInfo">
                    <v-avatar id="userAvatar" size="30px">
                        <v-icon large>mdi-account-circle</v-icon>
                    </v-avatar>
                    <div id="userDetails">
                        {{ $auth.user.name }}
                        <br>
                        <span id="companyDetails">{{ companyName }}</span>
                    </div>
                </div> 
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="propelDarkGrey--text">Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- to appear on login and stand alones pages -->
        <div v-if="!$auth.hasAccess">            
            <v-btn v-show="!$route.matched.some(({ name}) => name === 'login')" large icon to="/login" active-class="btnRouterLinknoActiveClass" >
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn v-show="$auth.isAuthenticated" large icon @click="logout" >
                <v-icon>mdi-logout</v-icon>
            </v-btn>
            <v-btn large icon to="/help" active-class="btnRouterLinknoActiveClass" >
                <v-icon large>mdi-help-circle-outline</v-icon>
            </v-btn>
        </div>

    </v-app-bar>
</template>


<script>
export default {
    name: 'AppBar',

    computed: {

        appEnvironment() {
            let env = process.env.VUE_APP_CMS_ENVIRONMENT;
            return env.replace(/^\w/, c => c.toUpperCase());
        },

        newNotificationCount () {
            return this.$store.getters["notifications/getNewNotifications"].length;
        },

        account() {
            return this.$store.state.account.userAccount;
        },

        companyName() {
            if(this.account != null)
                return this.account.company.name;
            else
                return "";
        }
    },

    methods: {

        logout() {
            App.$appAnalytics.trackEvent('Account - Logout');
            this.$auth.logout({
                returnTo: window.location.origin
            });
        }
    }
};
</script>

<style>

    /* We don't want app menu router buttons to change or indicate when we are on the active page.
       We should be able to specify an empty value for the v-btn active-class prop to achieve this, but not working 
    */
    .btnRouterLinknoActiveClass:before {
        opacity: 0 !important;
    }

    .v-menu__content {
        border-radius: 0px !important;
    }

    #notifictionBadge.v-badge {
        margin-left: 20px;
        margin-right: 20px;
    }

    #userInfo {        
        display: flex; 
        align-items: center;
        white-space: nowrap; 
    }

    #userAvatar {
        margin-right: 0.5em;
    }

    #userDetails {
        line-height: 1em; 
        font-size: 0.95em;
        white-space: nowrap;
    }

    #companyDetails {
        font-size: 0.8em;
    }

    #userMenuList {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #propelTitle img {
        height: 34px;
        margin-top: 5px; 
    }

    #appEnvironment {
        white-space: nowrap; 
        font-size: 1.58em;
        margin-top: 2px;
        margin-left: 8px;
    }

</style>