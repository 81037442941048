<template>
    <v-container id="banner" fill-height>

        <div id="loginInfo" :class="[$vuetify.breakpoint.xsOnly ? 'smallScreen' : '']">
            <img id="loginLogo" src="@/assets/img/propel_logo_reverse.png" />
            <div v-if="$auth.loading">
                <p>Loading....</p>
                <p>
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        indeterminate
                        color="propelDarkGreen"
                    ></v-progress-circular>
                </p>
            </div>
            <div v-else>
                <!-- if not authenticated, show login -->
                <div v-if="!this.authService.isAuthenticated">
                    <div>
                        <p>Please log in if you are already a registered user, or sign up if you need to register.</p>
                        <p>Before you can log in, you must first accept our terms and conditions.</p>
                    </div>
                    <v-row id="termsSection" justify="center">
                        <v-dialog v-model="termsDialog" max-width="800px">
                            <template v-slot:activator="{ on }">
                                <v-checkbox
                                    id="termsCheckbox" v-model="termsAccepted"
                                    color="propelDarkGreen" dark>
                                    <template v-slot:label>
                                        <div>
                                        I accept Napier Port's <a @click.stop href="#" v-on="on">terms and conditions</a>.
                                        </div>
                                    </template>
                                </v-checkbox>
                            </template>
                            <Terms>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary darken-1" text @click="termsDialog = false">Close</v-btn>
                                </v-card-actions>
                            </Terms>
                        </v-dialog>
                    </v-row>

                    <div class="text-center">
                        <v-btn class="loginButtons" tile color="propelDarkGreen" justify="start" @click="login" dark :disabled="!termsAccepted">
                            Log in
                        </v-btn>
                        <v-btn class="loginButtons" tile color="propelDarkGreen" justify="start" @click="signup" dark :disabled="!termsAccepted">
                            Sign up
                        </v-btn>
                    </div>
                </div>

                <!-- if authenticated, but don't have access (i.e. not email verified or no applicable roles), explain to user -->
                <div v-else-if="!$auth.hasAccess">
                    <!-- if not email verified -->
                    <div v-if="!$auth.user.email_verified">
                        <p>Hi {{ $auth.user.given_name }},</p>
                        <p>We're just waiting for you to verify your email address.</p>
                        <p>We sent you an email to {{ $auth.user.email }} and need you to click on the link in that email before proceeding.</p>
                    
                        <div class="text-center">
                            <v-btn class="loginButtons" tile color="propelDarkGreen" justify="start" @click="resendAccountVerificationEmail" dark>
                                Send email again
                            </v-btn>
                            <v-btn class="loginButtons" tile color="propelDarkGreen" justify="start" @click="logout" dark>
                                Log out
                            </v-btn>
                        </div>
                    </div>
                    <!-- if no roles assigned -->
                    <div v-else>
                        <RequestAccess />
                    </div>
                </div>

                <!-- if user has access, provide link to dashboard. Note this shouldn't actually occur as the authguard should have already redirected the user to the dashboard -->
                <p v-else>You are already logged in. <router-link to="/">Go to the Dashboard.</router-link></p>
            </div>                
        </div>

    </v-container>
</template>

<script>
import { getInstance } from "../auth/authWrapper";
import Terms from '@/components/help/Terms.vue';
import RequestAccess from '@/components/login/RequestAccess.vue';

export default {
    name: 'login',
    components: {
        Terms,
        RequestAccess
    },

    data () {
        return {
            termsAccepted: false,
            termsDialog: false,
        }
    },

    computed: {
        authService () {
            return getInstance();
        },
    },

    methods: {
        login() {
            this.$auth.loginWithRedirect();
        },

        signup() {
            let options = {
                screen_hint:'signup',   //Auth0 - new Universal Login - opens signup view
                initialScreen: 'signUp',//Auth0 - classic Universal Login - opens signup view
                login_hint: this.$route.query.emailaddress
            };

            this.$auth.loginWithRedirect(options);
        },

        logout() {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        },

        resendAccountVerificationEmail() {
            if(!this.$auth.user.email_verified)
            {
                CMSApi.resendAccountVerificationEmail()
                    .then(async () => {
                        this.$root.$alert.showSuccess('Verification email resent', 'Note it may take up to 5 minutes to receive this email, and you may need to check your spam folder.')
                    })
            }
        },

        refresh() {
            location.reload();
        }
    }
}
</script>

<style>

    #banner {
        background-image: url('~@/assets/img/login_background.jpg');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        padding-bottom: 15%;
    }

    #banner a {
        color: var(--v-propelGreen-base);

    }

    #loginInfo {
        width: 100%;
        min-height: 350px;
        margin: auto;
        max-width: 700px;
        padding: 30px;
        color: white;
        border-color: white;
        border-width: thin;
        border-style: solid;
        background: rgba(0, 0, 0, .8);
        background-blend-mode: darken;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    #loginInfo.smallScreen {
        padding-left: 30px;
        padding-right: 30px;
    }

    #loginLogo {
        max-height: 90px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        display: block;
    }

    #termsSection {
        padding-left: 20px;
        padding-right: 15px;
    }

    #termsSection label{
        color: white !important;
        text-align: center;
    }

    .loginButtons
    {
        min-width: 100px !important;
        max-width: 300px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    .smallScreen .loginButtons {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

</style>
