<template>
  <div>
    <v-tabs
        v-model="tab"
        background-color="transparent"
        color="propelGreen"
        grow
    >
      <v-tab
          v-for="item in items"
          :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <gate-list
            ref="gateList"
            :isPortAdmin="isPortAdmin"
        ></gate-list>
      </v-tab-item>

      <v-tab-item>
        <gate-service-list
            ref="gateServiceList"
            :isPortAdmin="isPortAdmin"
        ></gate-service-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {getInstance} from "@/auth";
import UserRoles from "@/auth/authUserRoles";
import gateList from "@/components/portAdmin/gate/GateList";
import gateServiceList from "@/components/portAdmin/gate/GateServiceList";
export default {
  name: "admin-gateManagement",

  components: {
    gateList,
    gateServiceList
  },

  data() {
    return {
      gateListPanelExpanded: false,
      gateServiceListPanelExpanded: true,
      isPortAdmin: false,

      //Tab
      tab: null,
      items: [
        'Gate List', 'Gate Service List',
      ],
    }
  },

  mounted() {
    this.checkPermission();
  },

  computed: {

  },

  methods: {
    switchCameraListPanels() {
      this.gateListPanelExpanded = !this.gateListPanelExpanded;
      this.gateServiceListPanelExpanded = !this.gateServiceListPanelExpanded;
    },

    checkPermission () {
      let authService = getInstance();
      if (authService.userHasRole(UserRoles.PortAdmin)) {
        this.isPortAdmin = true;
      }
    },

    reloadGates() {
      this.$refs.gateList.reloadGates();
    },

    addGate() {
      this.$refs.gateList.addGate();
    },
  },
}
</script>

<style scoped>

</style>