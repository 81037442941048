<template>
    <v-dialog v-model="dialog" persistent :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-icon v-if="Boolean(options.icon)" left>{{ options.icon }}</v-icon>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!message" class="pa-4" style="white-space: pre-line;" v-html="message"></v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click.native="close">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/**
 * Vuetify Alert Dialog component
 *
 * Insert component where you want to use it:
 *  <AlertDialog ref="alert"></AlertDialog>
 *
 *  Call it:
 *      this.$refs.alert.show('Unable to Delete', 'The record is does not exist', { color: 'red' })
 *  Or use await:
 *      if (await this.$refs.alert.show('Unable to Delete', 'The record is does not exist', { color: 'red' }) {
 *          // yes
 *      }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$alert
 *  <template>
 *      <v-app>
 *          ...
 *          <AlertDialog ref="alert"></ConfirmDialog>
 *      </v-app>
 *  </template>
 *
 *  mounted() {
 *      this.$root.$alert = this.$refs.alert
 *  }
 */

export default {
    data: () => ({
        dialog: false,
        resolve: null,
        message: null,
        title: null,
        options: null,
        defaultOptions: {
            icon: 'mdi-alert-circle-outline',
            color: 'red',
            width: 400,
            zIndex: 10000
        }
    }),

    created () {
        this.options = Object.assign({}, this.defaultOptions);
    },

    methods: {
        show(title, message, options) {
            //only show if we have an actual title and message
            if(title && message)
            {
                this.dialog = true
                this.title = title
                this.message = message

                //if no options provided, reset to default
                Object.assign(this.options, (!options ? this.defaultOptions : options))
            }

            return new Promise((resolve) => {
                this.resolve = resolve;
            })
        },

        showSuccess(title, message) {
            let options = {}
            Object.assign(options, this.defaultOptions);
            options.color = 'propelDarkGreen';
            options.icon = 'mdi-check-circle-outline'
            return this.show(title, message, options)
        },

        showApiErrorResponse(title, responseData, options) {
            //only show if we have an actual title and message
            if(title && responseData)
            {
                let errorMessage = window.App.extractErrorsFromAjaxResponse(responseData);
                this.show(title, errorMessage, options)
            }
        },

        close() {
            this.dialog = false;
            this.resolve(true);
        }
    }
};

</script>