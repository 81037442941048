<template>
    <v-card elevation="0">
        <v-card-title>
            <span class="headline">Notifications</span>
        </v-card-title>

        <v-card-text v-show="loadingNotifications">
            Loading Notifications
            <v-progress-linear
                :indeterminate="loadingNotifications"
            ></v-progress-linear>
        </v-card-text>

        <v-card-text v-show="notifications.length == 0 && !loadingNotifications">
            No current notifications to show
        </v-card-text>
        
        <v-container v-show="notifications.length > 0 && !loadingNotifications">                
            <v-card class="notificationItem" outlined tile v-for="notification in notifications" v-bind:key="notification.notificationId">
                <v-card-title>
                    {{notification.title}}
                    <v-list-item-subtitle>{{notification.startDate | formatDate}}</v-list-item-subtitle>
                </v-card-title>   
                <v-card-text>
                    <div v-html="notification.content" />
                    <p v-if="notification.hasAttachment">For further information, please see the attachment in the associated notification email that was sent to your email address.</p>
                </v-card-text>
            </v-card>
        </v-container>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {   

    data () {
        return {
            loadingNotifications: false,
        }
    },

    mounted () {
        this.loadingNotifications = true;

        this.fetchNotifications()
            .finally(() => {
                this.loadingNotifications = false;
            });
    },

    computed: {
        ...mapGetters('notifications', {
            notifications : 'getNotifications'
        }),
    },

    methods: {
        ...mapActions('notifications', [
            'fetchNotifications'
        ]),
    }
};
</script>

<style>
    .notificationItem {
        margin-bottom: 30px;
        border-left: solid 5px var(--v-warning-base) !important;
    }

    .notificationItem .v-card__title {
        font-weight: 500;
        font-size: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 20px;
        background: #fafafa;
    }

    .notificationItem .v-card__text {
        color: rgba(0, 0, 0, 0.70) !important;
    }
</style>