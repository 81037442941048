<template>
<div>
    <v-card id="importAdvisedContainerList" elevation="0" >
        <CameraList 
            ref="cameraList"
            v-if="isPortAdmin === true"
            title="Camera Management"
            :panelExpanded="currentCameraListPanelDisplayed" 
            @toggle-panel-click="switchCameraListPanels" 
            @refresh-button-click="refreshData"
            @cameraDeleted="refreshData"
            @sortUpdated="refreshData"
            @cameraRefresh="refreshData" />
        
        <br>
        <v-toolbar class="datatableToolbar" flat>
            <v-toolbar-title>Cameras</v-toolbar-title>

            <div class="flex-grow-1"></div>

            <v-btn                    
                tile color="secondary"                     
                :loading="loadingCameras"
                @click="refreshCameraImages" >
                    <v-icon>mdi-refresh</v-icon>
                    <span v-if="$vuetify.breakpoint.mdAndUp">Refresh</span>
            </v-btn>
        </v-toolbar>

        <v-card elevation="0">
            <v-card-text v-show="cameras.length == 0 && loadingCameras">
                Loading Cameras...
            </v-card-text>

            <v-card-text v-show="cameras.length == 0 && !loadingCameras">
                No current cameras to show
            </v-card-text>
            
            <v-container v-show="cameras.length > 0">                
                <v-card class="cameraGroupCard" outlined tile v-for="cameraGroup in cameras" v-bind:key="cameraGroup.cameraGroupId">
                    <v-card-title>
                        {{ cameraGroup.name }}
                    </v-card-title>
                    <v-container fluid>
                        <v-row dense >
                            <v-col v-for="camera in cameraGroup.cameras" :key="camera.cameraId" align="center" justify="center">
                                <v-card class="cameraCard" :max-width="$vuetify.breakpoint.xl ? '800' : '600'" outlined>
                                    <img 
                                        v-img="{ group: 'group'+cameraGroup.cameraGroupId, title: camera.name }"
                                        class="cam_Image"
                                        :src="cameraApiUrl + camera.imageName + '?' + cameraImageRefreshTrigger"
                                    />
                                    <v-card-text>{{ camera.name }}</v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-card>
    </v-card>
</div>
</template>

<script>
import CameraList from '@/components/cameras/CameraList.vue'
import UserRoles from "@/auth/authUserRoles";
import { getInstance } from "@/auth/authWrapper";
import Vue from 'vue';
import VueImg from 'v-img';

Vue.use(VueImg);

export default {   
    components: {
        CameraList
    },

    data () {
        return {
            cameras: [],
            loadingCameras: false,
            cameraImageDisplayTime: new Date(),
            refreshCameraImagesIntervalId: 0,
            currentCameraListPanelDisplayed: false,
            cameraURLs: [],
            isPortAdmin: false,
            cameraApiUrl: process.env.VUE_APP_CMS_CAMERA_API_URL
        }
    },

    mounted () {
        this.loadCameras();
        this.checkPermission();
        this.refreshCameraImagesIntervalId = setInterval(this.forceImageRefresh.bind(this, true), 30000);
    },

    computed: {
        cameraImageRefreshTrigger() {
            return this.cameraImageDisplayTime;
        }
    },

    methods: {
        loadCameras() {
            this.loadingCameras = true;

            CMSApi.fetchActiveCameras()
                .then(data => {
                    this.cameras = data;
                })
                .finally(() => {
                    this.loadingCameras = false;
                });
        },

        refreshCameraImages() {
            App.$appAnalytics.trackEvent('Cameras - Refresh Images Click'); 
            this.loadCameras();
            this.forceImageRefresh();
        },

        switchCameraListPanels: function () {
            this.currentCameraListPanelDisplayed = !this.currentCameraListPanelDisplayed;
        },

        forceImageRefresh() {
            this.cameraImageDisplayTime = new Date();
        },

        refreshData: function () {
            App.$appAnalytics.trackEvent('Port Admin - Refresh Camera Data - Click');
            this.refreshCameraImages();
            this.$refs.cameraList.refreshData();
        },

        checkPermission () {
            let authService = getInstance();
            if (authService.userHasRole(UserRoles.PortAdmin)) {
                this.isPortAdmin = true;
            }
        }
    }
};
</script>

<style>
    .cameraGroupCard {
        margin-bottom: 40px;
    }

    .cameraGroupCard .v-card__title {
        font-weight: 500;
        font-size: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        background: #fafafa;
    }

    .cameraCard {
        margin: 10px;
    }

    .cameraCard .v-card__text{
        background: #fafafa;
    }

    .cam_Image {
        max-width: 100%; height: auto;
    }
</style>