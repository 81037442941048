<template>
    <div>
        <v-card id="companyList" elevation="1">
            <v-toolbar class="datatableToolbar" flat>
                <v-toolbar-title>Company Management</v-toolbar-title>

                <div class="flex-grow-1"></div>

                <v-btn
                    tile class="mb-2"
                    color="secondary"
                    @click="createCompany" >
                        <v-icon>mdi-plus</v-icon>
                        <span v-if="$vuetify.breakpoint.smAndUp">New Company</span>
                </v-btn>
            </v-toolbar>

            <v-container class="datatableControls" id="companyListTableControls">
                <v-row>                    
                    <v-col cols="12" sm="6" md="5" lg="4" xl="4">                        
                        <v-text-field clearable v-model="searchText" label="Search by Name or Address" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <DxDataGrid
                id="companyGrid"
                :ref="dataGridRefName"
                :data-source="companies"
                :show-borders="false"
                :hoverStateEnabled="true"
                noDataText="No current companies"
                @editing-start="editCompany"
                @row-removing="deleteCompany"
                :column-auto-width="true"
                :column-hiding-enabled="true"                
            >
                <DxHeaderFilter :visible="false" />
                <DxSorting mode="multiple"/>

                <DxEditing
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="row"
                >
                    <DxTexts confirmDeleteMessage="" />
                </DxEditing>

                <DxPaging :page-size="10"/>
                <DxPager
                    :show-page-size-selector="true"
                    :allowed-page-sizes="pageSizes"
                    :show-info="true"
                />

                <DxColumn data-field="name" data-type="string" />
                <DxColumn data-field="address" data-type="string" :hiding-priority="2" />
                <DxColumn data-field="phoneNumber" data-type="string" :hiding-priority="1" :allowSearch="false" />
                <DxColumn data-field="status" cell-template="statusCellTemplate" :hiding-priority="4" :allowSearch="false" />
                <template #statusCellTemplate="{ data }">
                    <div>
                        <span v-if="data.value != CompanyStatus.Pending">{{formatCompanyStatus(data.value)}}</span>                        
                        <span v-else><a href="#" @click.prevent="approveCompany(data)">{{formatCompanyStatus(data.value)}}</a></span>
                    </div>
                </template>

                <DxColumn data-field="adminUsers" caption="Company Admins" cell-template="roleCellTemplate" css-class="columnWordWrap" :hiding-priority="3" />
                <template #roleCellTemplate="{ data }">
                    <div>
                        <v-chip                            
                            v-for="(item, i) in data.value" :key="i"
                            color="propelDarkGrey"
                            label small dark
                            class="gridChips"
                        >{{item.fullName}}</v-chip>
                    </div>
                </template>

                <DxColumn data-field="applications" caption="Port Functions"  cell-template="applicationsCellTemplate" css-class="columnWordWrap" :hiding-priority="5" />
                <template #applicationsCellTemplate="{ data }">
                    <div>
                        <v-chip                            
                            v-for="(item, i) in data.value" :key="i"
                            color="propelDarkGrey"
                            label small dark
                            class="gridChips"
                        >{{item.name}}</v-chip>
                    </div>
                </template>

                <DxColumn type="buttons" cell-template="actionCellTemplate" />
                <template #actionCellTemplate="{ data }">
                    <div>
                        <v-icon color="primary" size="18" class="mr-2" @click="editCompany(data)" v-visible="isCompanyEditable(data)">
                            mdi-pencil
                        </v-icon>
                        <v-icon color="primary" size="18" class="mr-2" @click="deleteCompany(data)">
                            mdi-delete
                        </v-icon>
                    </div>
                </template>
            </DxDataGrid>
        </v-card>
        <CompanyForm
            ref="companyForm"
            v-on:companyCreated="companyCreatedOrUpdated($event)"
            v-on:companyUpdated="companyCreatedOrUpdated($event)"
            v-on:companyApproved="companyCreatedOrUpdated($event)"
            v-on:companyRejected="companyDeleted($event)" />
    </div>
</template>

<script>
import {CompanyStatus, EnumHelpers} from '@/utils/constants.js';
import CompanyForm from './CompanyForm.vue';

import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSorting,
    DxEditing,
    DxTexts
} from 'devextreme-vue/data-grid';


export default {
    components: {
        CompanyForm,
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxPager,
        DxPaging,
        DxSorting,
        DxEditing,
        DxTexts
    },

    data () {
        return {
            companies: [],
            loadingData: false,
            searchText: '',
            pageSizes: [10, 25, 50, 100],
            dataGridRefName: 'companyGrid',
        }
    },

    watch: {
        searchText: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            this.$refs[this.dataGridRefName].instance.searchByText(newVal?.trim());
        },

        loadingData: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
            if(newVal)
                this.$refs[this.dataGridRefName].instance.beginCustomLoading();
            else
                this.$refs[this.dataGridRefName].instance.endCustomLoading();
        }
    },

    mounted () {
        this.CompanyStatus = CompanyStatus;
        this.fetchCompanies();
    },

    methods: {

        fetchCompanies() {
            this.loadingData = true;

            CMSApi.fetchCompanies()
                .then(data => {
                    this.companies = data;
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },

        isCompanyEditable(e){ // eslint-disable-line no-unused-vars
            return e.row.data.status == "A";
        },

        async deleteCompany(e) {
            e.cancel = true;

            if (await this.$root.$confirm.open('Remove company?', 'Are you sure you want to remove this company?', { color: 'warning' }))
            {
                App.$appAnalytics.trackEvent('Port Admin - Companies - Delete Click');
                this.loadingData = true;

                CMSApi.deactivateCompany(e.data.companyId)
                    .then(() => {
                        this.companyDeleted(e.data.companyId);
                        window.App.$emit('show-snackbar', "success", "Company removed");
                    })
                    .catch(error => {
                        let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                        this.$root.$alert.show('Unable to deactivate company', errorMessage)
                    })
                    .finally(() => {
                        this.loadingData = false;
                    });
            }
        },

        editCompany(e) {
            App.$appAnalytics.trackEvent('Port Admin - Companies - Edit Click');
            e.cancel = true;
            this.$refs.companyForm.editCompany(e.data);
        },

        createCompany() {
            App.$appAnalytics.trackEvent('Port Admin - Companies - Create Click');
            this.$refs.companyForm.createCompany();
        },

        approveCompany(e) {
            App.$appAnalytics.trackEvent('Port Admin - Companies - Approve Click');
            this.$refs.companyForm.editCompany(e.data);
        },

        companyCreatedOrUpdated(company) {
            const existingRecordIndex = this.companies.findIndex(i => i.companyId == company.companyId);

            //if record exists in list, update, else add.
            if (existingRecordIndex >= 0)
                Object.assign(this.companies[existingRecordIndex], company);
            else
                this.companies.unshift(company);
        },

        companyDeleted(companyId) {
            this.companies.splice(this.companies.findIndex(i => i.companyId == companyId), 1);
        },

        formatCompanyStatus(value) {
            return EnumHelpers.getCompanyStatusDescriptionByCode(value);
        },

    }
};
</script>

<style>
    #companyList {
        padding-bottom: 12px;
    }

    .gridChips {
        margin: 2px;
        margin-right: 5px;
    }

    .columnWordWrap {
        white-space: break-spaces;
    }

</style>