<template>
  <div>
      <v-card elevation="3">
          <v-card-text>
              <v-container>
                  <v-row dense>
                      <v-col cols="7" lg="12">
                          <v-chip
                              class="ma-2 float-left"
                              color="#a3bc00"
                              label
                              text-color="white"
                          >
                              <v-icon left>
                                  mdi-train-variant
                              </v-icon>
                              {{ rowData.route }}
                          </v-chip>
                          <v-chip
                              class="ma-2 float-right"
                              color="#a3bc00"
                              label
                              text-color="white"
                          >
                              <v-icon left>
                                  mdi-calendar-text-outline
                              </v-icon>
                              {{ readableBookingDate }}
                          </v-chip>
                      </v-col>
                  </v-row>
                  <v-row dense>
                      <v-col cols="12">
                          <v-card elevation="3">
                              <v-card-subtitle>Customer & Vessel Info</v-card-subtitle>
                              <v-card-text>
                                  <v-row>
                                      <v-col cols="6">
                                          <v-text-field
                                              v-model="customerName"
                                              label="Name"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-text-field
                                            v-model="bookingData.vesselDetails.vesselVisitId"
                                            label="Vessel Visit Id"
                                            readonly
                                            dense
                                        ></v-text-field>
                                      </v-col>
                                  </v-row>
                              </v-card-text>
                          </v-card>
                      </v-col>
                  </v-row>
                  <v-row dense>
                      <v-col cols="12">
                          <v-card elevation="3">
                              <v-card-subtitle>Rail Info</v-card-subtitle>
                              <v-card-text>
                                  <v-row dense>
                                      <v-col cols="4">
                                          <v-text-field
                                              v-model="from"
                                              label="From"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="4">
                                          <v-text-field
                                              v-model="to"
                                              label="To"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                  </v-row>
                                  <v-row dense>
                                      <v-col cols="4">
                                          <v-text-field
                                              v-model="bookingData.railDetails.bookingReferenceNumber"
                                              label="Booking Ref#"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="4">
                                          <v-text-field
                                              v-model="bookingData.railDetails.customerReferenceNumber"
                                              label="Customer Ref#"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="4">
                                          <v-text-field
                                              v-model="bookingData.railDetails.railReferenceNumber"
                                              label="Customer Ref#"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                  </v-row>
                              </v-card-text>
                          </v-card>
                      </v-col>
                  </v-row>
                  <v-row dense>
                      <v-col cols="12">
                          <v-card elevation="3">
                              <v-card-subtitle>Unit Info</v-card-subtitle>
                              <v-card-text>
                                  <v-row dense>
                                      <v-col cols="4">
                                          <v-text-field
                                              v-model="bookingData.containerDetails.containerNumber"
                                              label="Unit #"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="4" v-if="bookingData.containerDetails.emptyReleaseNumber !== null">
                                          <v-text-field
                                              v-model="bookingData.containerDetails.emptyReleaseNumber"
                                              label="Empty Release #"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="4">
                                          <v-checkbox
                                              v-model="bookingData.containerDetails.isReturning"
                                              label="Returning ?"
                                              readonly
                                              dense
                                          ></v-checkbox>
                                      </v-col>
                                  </v-row>
                                  <v-row dense>
                                      <v-col cols="3">
                                          <v-text-field
                                              v-model="bookingData.containerDetails.containerCommodity"
                                              label="Commodity"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="3">
                                          <v-text-field
                                              v-model="bookingData.containerDetails.containerIsoType"
                                              label="ISO Type"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="3">
                                          <v-checkbox
                                              v-model="bookingData.containerDetails.isGenset"
                                              label="Genset ?"
                                              readonly
                                              dense
                                          ></v-checkbox>
                                      </v-col>
                                      <v-col cols="3">
                                          <v-checkbox
                                              v-model="bookingData.containerDetails.isHazardous"
                                              label="Hazardous ?"
                                              readonly
                                              dense
                                          ></v-checkbox>
                                      </v-col>
                                  </v-row>
                                  <v-row v-if="bookingData.containerDetails.isHazardous" dense>
                                      <v-col cols="6">
                                          <v-text-field
                                              v-model="bookingData.containerDetails.hazardClass"
                                              label="Hazard Class"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="6">
                                          <v-textarea
                                              v-model="bookingData.containerDetails.hazardNotes"
                                              label="Hazard Notes"
                                              readonly
                                              dense
                                          ></v-textarea>
                                      </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-textarea
                                          v-model="bookingData.containerDetails.containerNotes"
                                          label="Container Notes"
                                          readonly
                                          dense
                                          auto-grow rows=1
                                      ></v-textarea>
                                    </v-col>
                                  </v-row>
                              </v-card-text>
                          </v-card>
                      </v-col>
                  </v-row>
                  <v-row v-if="hasRoadBridge" dense>
                      <v-col cols="12">
                          <v-card elevation="3">
                              <v-card-subtitle>Road Bridge Info</v-card-subtitle>
                              <v-card-text>
                                  <v-row v-for="(item,i) in bookingData.roadBridgeDetailsCollection" :key="i" dense>
                                      <v-col cols="4">
                                          <v-text-field
                                              :value="formattedEta(item.estimatedDate)"
                                              label="ETA"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="4">
                                          <v-text-field
                                              v-model="item.purchaseOrderNumber"
                                              label="Purchase Order"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="4">
                                          <v-text-field
                                              v-model="item.truckCompanyId"
                                              label="Truck Company"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field
                                              :value="item.roadBridgeCode"
                                              label="From - To"
                                              readonly
                                              dense
                                          ></v-text-field>
                                      </v-col>
                                  </v-row>
                              </v-card-text>
                          </v-card>
                      </v-col>
                  </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions>
              <v-btn
                      color="primary"
                      text
                      @click="$emit('close')"
              >
                  Close
              </v-btn>
          </v-card-actions>
      </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: "TransportPlannerList-InfoDialog",

    data() {
        return {
            rowData : {
                route: null,
                instanceDate : null,
            },
            bookingData : {
                customerDetails : {
                    receiverCompanyId : null,
                    senderCompanyId : null,
                },
                railDetails : {
                    bookingReferenceNumber : null,
                    customerReferenceNumber: null,
                    railReferenceNumber : null,
                },
                containerDetails : {
                    containerNumber : null,
                    containerCommodity : null,
                    containerIsoType : null,
                    containerNotes : null,
                    emptyReleaseNumber : null,
                    isGenset : false,
                    isHazardous : false,
                    hazardClass : null,
                    hazardNotes : null,
                    isReturning : false,
                },
                vesselDetails: {
                  vesselVisitId: null,
                },
            }
        }
    },

    computed : {
        ...mapState('transportPlanner', [
            'transportLogisticCustomers',
            'kiwiRailCustomers'
        ]),

        ...mapGetters('transportPlanner', [
            'getCustomerName',
            'getKiwiRailLocationName',
            'getRoadBridgeCode'
        ]),

        readableBookingDate() {
            return window.Moment(this.rowData.instanceDate, 'YYYY-MM-DD').format("dddd, MMMM Do YYYY");
        },

        customerName() {
            return this.transportLogisticCustomers.length > 0 ? this.getCustomerName(this.bookingData.customerDetails.companyId) : '';
        },

        from() {
            return this.kiwiRailCustomers.length > 0 ? this.getKiwiRailLocationName(this.bookingData.railDetails.senderCompanyId) : '';
        },

        to() {
            return this.kiwiRailCustomers.length > 0 ? this.getKiwiRailLocationName(this.bookingData.railDetails.receiverCompanyId) : '';
        },

        hasRoadBridge() {
            return this.bookingData !== undefined && this.bookingData.roadBridgeDetailsCollection !== undefined  ? this.bookingData.roadBridgeDetailsCollection.length > 0 : false;
        }
    },

    mounted() {
      this.fetchTransportServiceLogisticCustomers();
      this.fetchKiwiRailCustomers();
      this.fetchRoadBridgeCodes();
    },

    methods : {
        ...mapActions('transportPlanner', [
            'fetchTransportServiceLogisticCustomers',
            'fetchKiwiRailCustomers',
            'fetchRoadBridgeCodes'
        ]),

        init(bookingData, rowData) {
            this.rowData = Object.assign({}, rowData);
            this.bookingData = Object.assign({}, bookingData);
        },

        formattedEta(date) {
            return window.Moment().utc(date).format('MM/DD/YYYY');
        },
    }
}
</script>

<style scoped>

</style>