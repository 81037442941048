<template>
  <v-card elevation="3">
    <v-card-text>
      <v-row>
        <v-col cols="4" lg="5">
          <v-row>
            <v-col cols="5">
              <v-checkbox
                  v-model="tsDaysData.isOneDayService"
                  label="One-Day Service?"
              ></v-checkbox>
            </v-col>
            <v-col cols="7">
              <v-text-field
                  v-if="!tsDaysData.isOneDayService"
                  v-model="tsDaysData.dateRangeSelected"
                  label="Service Range"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="!tsDaysData.isOneDayService ? dateRangeRules: [] "
              ></v-text-field>
              <v-text-field
                  v-else
                  v-model="tsDaysData.dateSelected"
                  label="Service Day"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="tsDaysData.isOneDayService ? dateSelectedRules: []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-date-picker
                  v-if="!tsDaysData.isOneDayService"
                  v-model="tsDaysData.dates"
                  range
                  :show-current="dateNow"
                  :min="dateNow"
                  @change="dateRangeFilled"
                  full-width
              ></v-date-picker>
              <v-date-picker
                  v-else
                  v-model="tsDaysData.date"
                  :show-current="dateNow"
                  :min="dateNow"
                  @change="dateFilled"
                  full-width
              ></v-date-picker>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="7" lg="6">
          <v-row>
            <v-col class="daySwitches" cols="4" v-for="(item, index) in tsDaysData.days" :key="index">
              <v-switch
                  v-model="tsDaysData.days[index].isActive"
                  :label="item.day"
                  inset
                  :disabled="tsDaysData.isOneDayService"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="secondary"
          text
          @click="previousForm"
      >
        Prev
      </v-btn>
      <v-btn
          color="secondary"
          text
          :disabled="!formIsValid"
          @click="nextForm"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import generalHelpers from "@/utils/generalHelpers";

export default {
  name: "TransportServiceForm-Days",

  mixins: [generalHelpers],

  data() {
    return {
      // Validation
      dateRangeRules: [
        v => !!v || 'Date Range is required',
        v => (!!v && v.length > 1) || 'Should select range of date'
      ],
      dateSelectedRules: [
        v => !!v || 'Date is required',
      ],

      // Default
      defaultData: [],
    }
  },

  computed : {
    ...mapState('trainService', [
      'workingTrainService',
      'retrievingTrainService'
    ]),

    tsDaysData() {
      return this.workingTrainService.trainServiceDays;
    },

    dateNow() {
      return window.Moment().format('YYYY-MM-DD');
    },

    activeDaysSelected() {
      return this.tsDaysData.days.filter((obj) => obj.isActive).length;
    },

    formIsValid() {
      return this.tsDaysData.isOneDayService ? this.isNotNullOrEmpty(this.tsDaysData.dateSelected) : this.isNotNullOrEmpty(this.tsDaysData.dateRangeSelected) && this.activeDaysSelected > 0;
    },

    isUpdate() {
        return this.workingTrainService.trainServiceId && this.workingTrainService.trainServiceId > 0;
    },

    formHasChanged() {
        let newObj = {
            startDate : this.tsDaysData['startDate'],
            endDate : this.tsDaysData['endDate'],
            isOneDayService : this.tsDaysData['isOneDayService'],
            oneDayServiceDate : this.tsDaysData['oneDayServiceDate'],
        };
        return Object.keys(newObj).some(field => newObj[field] !== this.defaultData[field]);
    },

    selectedDaysHasChanges() {
        return this.tsDaysData.days.filter(k => k["isActive"] !== k["dbVal"]).length > 0;
    }
  },

  mounted() {
      if (this.isUpdate)
      {
          this.defaultData = Object.assign({}, this.tsDaysData);
      }
  },

  watch: {
    'tsDaysData.isOneDayService'(val) {
      if (!this.retrievingTrainService) {
        val ? this.resetOneDayService() : this.resetDateRanges();
      }
    },

    formIsValid(val) {
      this.setServiceDaysForm(val);
    },

    formHasChanged(val) {
      this.setTSDatesChangeState(val);
    },

    selectedDaysHasChanges(val) {
      this.setTSDaysChangeState(val);
    },
  },

  methods: {

    ...mapMutations('trainService', [
        'setServiceDaysForm',
        'setTSDatesChangeState',
        'setTSDaysChangeState'
    ]),

    // Misc functions
    isNotNullOrEmpty(val) {
      return (val !== "" && val !== null);
    },

    dateRangeFilled(e) {
      this.tsDaysData.dateRangeSelected = e.sort().join('~');
      this.tsDaysData.startDate = e[0];
      this.tsDaysData.endDate = e[1];
    },

    async dateFilled(t) {
      await this.unselectAllDays();
      this.tsDaysData.dateSelected = t;
      this.tsDaysData.oneDayServiceDate = t;

      const index = this.tsDaysData.days.findIndex(object => {
        return object.day === moment(t).format('dddd');
      })

      this.tsDaysData.days[index].isActive = true;
    },

    unselectAllDays() {
      this.tsDaysData.days.forEach( element => {
        element.isActive = false;
      })
    },

    async resetDateRanges() {
      await this.unselectAllDays();
      this.tsDaysData.dates = [];
      this.tsDaysData.dateRangeSelected = null;
      this.tsDaysData.startDate = null;
      this.tsDaysData.endDate = null;
    },

    async resetOneDayService() {
      await this.unselectAllDays();
      this.tsDaysData.date = null;
      this.tsDaysData.dateSelected = null;
      this.tsDaysData.oneDayServiceDate = null;
    },

    nextForm() {
      this.$emit('nextForm');
    },

    previousForm() {
      this.$emit('previousForm');
    },
  }
}
</script>

<style scoped>
.daySwitches {
  margin-left: 10px;
}
</style>