<template>
  <div>
      <v-container>
        <v-row>
          <v-col :cols="gridListLength" >
            <div class="d-flex" v-if="!masterView">
              <p class="mr-4 my-auto" :class="switchMe? 'inactiveSwitch': 'activeSwitch'"> By Dates</p>
              <v-switch
                  v-model="switchMe"
                  inset
              >
              </v-switch>
              <p class="mr-4 my-auto" :class="switchMe? 'activeSwitch': 'inactiveSwitch'"> By Wagon</p>
            </div>
            <v-container class="datatableControls">
              <v-row>
                <v-col cols="4" v-show="!masterView">
                  <v-dialog
                      ref="dialog"
                      v-model="datePickerModal"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="datesSelected"
                          label="Date Range"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      >
                        <template v-slot:prepend>
                          <v-btn
                              class="mb-2"
                              color="primary"
                              text
                              @click="goToPreviousWeek"
                          >
                            <v-icon>mdi-chevron-double-left</v-icon>
                            PREV
                          </v-btn>
                          <v-btn
                              class="mb-2"
                              color="primary"
                              text
                              @click="goToCurrentWeek"
                          >
                            CURRENT
                          </v-btn>
                        </template>
                        <template v-slot:append-outer>
                          <v-btn
                              class="mb-2"
                              color="primary"
                              text
                              @click="goToNextWeek"
                          >
                            <v-icon>mdi-chevron-double-right</v-icon>
                            NEXT
                          </v-btn>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        scrollable
                        range
                        first-day-of-week="1"
                        @change="dateRangeFilled"
                        :show-current="true"
                        show-adjacent-months
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="datePickerModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="hasSelectedRange"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="3">
                  <v-btn
                      v-if="masterView"
                      id="btnReload"
                      tile
                      color="secondary"
                      :loading="switchMe ? plannerWagonListLoading : plannerListLoading"
                      style="margin-right: 10px"
                      @click="closeMasterView">
                    <v-icon
                        right
                    >
                      mdi-arrow-left
                    </v-icon>
                  </v-btn>
                  <v-btn
                      id="btnReload"
                      tile
                      color="secondary"
                      :loading="switchMe ? plannerWagonListLoading : plannerListLoading"
                      @click="reload">
                    Refresh
                    <v-icon
                        right
                    >
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="masterView && !capacityForm"
                    id="btnAdd"
                    tile
                    color="secondary"
                    style="margin-left: 10px"
                    @click="capacityForm = true"
                  >
                    Add Capacity
                    <v-icon
                            right
                    >
                        mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-spacer></v-spacer>
                <v-col cols="3" xl="3" md="6" lg="3">
                  <v-text-field clearable v-model="searchText" label="Search by Service Name, Direction" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <DxDataGrid
                :ref="transportPlannerGridRefKey"
                :data-source="masterView ? plannerWagonList : switchMe ? plannerWagonList : plannerList"
                :show-borders="true"
                :hoverStateEnabled="true"
                noDataText="No available planner"
                :key-expr="masterView ? 'trainServiceWagonInstanceId' : switchMe? 'trainServiceWagonInstanceId' : 'trainServiceInstanceId'"
                :height="630"
                :onRowPrepared="showBookingWithIssues"
            >
              <DxPaging :page-size="10"/>
              <DxPager
                  :show-page-size-selector="true"
                  :allowed-page-sizes="pageSizes"
                  :show-info="true"
              />
              <DxFilterRow
                  :visible="showAdvancedFiltering"
              />
              <DxHeaderFilter
                  :visible="true"
              />

              <DxColumn
                :visible="masterView"
                v-for="(column, index) in wagonHeaders" :key="'masterView' + index"
                :caption="column.text"
                :data-type="column.useTemplate ? '' : column.type"
                :data-field="column.useTemplate ? '': column.value"
                :format="column.type in ['date'] ? column.format : ''"
                :allowSearch="column.allowSearch"
                :cell-template="column.useTemplate ? column.cellTemplate : ''"
                :width="column.width"
              ></DxColumn>

              <DxColumn
                  :visible="!masterView && switchMe"
                  v-for="(column, index) in wagonHeaders" :key="'wagonView' + index"
                  :caption="column.text"
                  :data-type="column.useTemplate ? '' : column.type"
                  :data-field="column.useTemplate ? '': column.value"
                  :format="column.type in ['date'] ? column.format : ''"
                  :allowSearch="column.allowSearch"
                  :cell-template="column.useTemplate ? column.cellTemplate : ''"
                  :width="column.width"
              ></DxColumn>

              <DxColumn
                  :visible="!masterView && !switchMe"
                  v-for="(column, index) in dateHeaders" :key="'default'+index"
                  :caption="column.text"
                  :data-type="column.useTemplate ? '' : column.type"
                  :data-field="column.useTemplate ? '': column.value"
                  :format="column.type in ['date'] ? column.format : ''"
                  :allowSearch="column.allowSearch"
                  :cell-template="column.useTemplate ? column.cellTemplate : ''"
                  :width="column.width"
              ></DxColumn>

              <template #actionAndMasterCellTemplate="{data}">
                <div>
                  <v-icon
                      color="primary"
                      class="mr-2"
                      title="Open Instance Details"
                      @click="showWagonListOfInstance(data.data)"
                  >
                    mdi-fit-to-page
                  </v-icon>
                  <v-icon
                      v-if="data.data.booked === 0"
                      color="primary"
                      size="18"
                      class="mr-2"
                      @click="deleteTransportServiceInstance(data)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </template>

              <template #actionCellTemplate="{data}">
                <div>

                  <!-- Show Issues if it has -->
                  <v-icon
                      v-if="canShowIssues(data)"
                      color="error"
                      size="18"
                      class="mr-2"
                      title="Show Issues"
                      @click="showIssues(data.data)"
                  >
                    fa-solid fa-triangle-exclamation
                  </v-icon>

                  <!-- Back to Draft -->
                  <v-icon
                      v-if="canBeBackToDraft(data)"
                      color="warning"
                      size="18"
                      class="mr-2"
                      title="Back to Draft"
                      @click="backToDraft(data.data)"
                  >
                    fa-solid fa-arrow-left-long-to-line
                  </v-icon>

                  <!-- Delete Wagon Instance -->
                  <v-icon
                      v-if="canBeDeleted(data)"
                      color="primary"
                      size="18"
                      class="mr-2"
                      title="Delete Capacity"
                      @click="deleteCapacity(data.data)"
                  >
                    mdi-trash-can
                  </v-icon>

                  <!-- Merge Splitted Wagons -->
                  <v-icon
                      v-if="canBeMerge(data)"
                      color="primary"
                      size="18"
                      class="mr-2"
                      title="Merge Wagon Slots"
                      @click="mergeWagonSlots(data.data)"
                  >
                    mdi-merge
                  </v-icon>

                  <!-- Edit Booking Info -->
                  <v-icon
                      v-if="canBeDeEdited(data)"
                      color="primary"
                      size="18"
                      class="mr-2"
                      title="Edit Booking Info"
                      @click="editPlannerBooking(data.data)"
                  >
                    fa-solid fa-file-pen
                  </v-icon>

                  <!-- Ready To Submit -->
                  <v-icon
                      v-if="canBeReadyToSubmit(data)"
                      color="primary"
                      size="18"
                      class="mr-2"
                      title="Ready to Submit"
                      @click="readyToSubmit(data.data)"
                  >
                    fa-solid fa-file-check
                  </v-icon>


                  <!-- Cancel Booking -->
                  <v-icon
                      v-if="canBeCancelled(data)"
                      color="warning"
                      size="18"
                      class="mr-2"
                      title="Cancel Booking"
                      @click="cancelBooking(data.data)"
                  >
                    fa-solid fa-file-slash
                  </v-icon>

                  <!-- Change Date -->
                  <v-icon
                      v-if="canChangeDate(data)"
                      color="warning"
                      size="18"
                      class="mr-2"
                      title="Change Booking Date"
                      @click="showChangeDateForm(data.data)"
                  >
                    fa-solid fa-calendar-lines-pen
                  </v-icon>

                  <!-- Tag Booking as Completed -->
                  <v-icon
                      v-if="canBeCompleted(data)"
                      color="success"
                      size="18"
                      class="mr-2"
                      title="Tag as Completed"
                      @click="completeBooking(data.data)"
                  >
                    fa-solid fa-tags
                  </v-icon>

                  <!-- Get Slot for a New Booking -->
                  <v-btn
                      v-if="canBeBooked(data)"
                      style="height: 20px !important;"
                      color="primary"
                      title="Book Wagon"
                      @click="showBookingForm(data.data)"
                  >
                    Book
                    <v-icon right>
                      mdi-notebook-check
                    </v-icon>
                  </v-btn>

                  <!-- Get the Info of the Booking -->
                  <v-icon
                    v-if="canSeeInfo(data)"
                    color="primary"
                    size="18"
                    class="mr-2"
                    title="Show Information"
                    @click="showBookingInfo(data.data)"
                  >
                      fa-solid fa-memo-circle-info
                  </v-icon>

                  <!-- Calculate Booking Invoice -->
                  <v-icon
                      v-if="canBeInvoiced(data)"
                      color="primary"
                      size="18"
                      class="mr-2"
                      title="Calculate Booking Invoice"
                      @click="calculateBookingInvoice(data.data)"

                  >
                      fa-solid fa-file-invoice-dollar
                  </v-icon>

                  <!-- Re-Calculate Booking Invoice -->
                  <v-icon
                      v-if="canBeReInvoiced(data)"
                      color="primary"
                      size="18"
                      class="mr-2"
                      title="Re-Calculate Booking Invoice"
                      @click="reCalculateBookingInvoice(data.data)"
                  >
                    fa-solid fa-chart-pie-simple-circle-dollar
                  </v-icon>

                  <!-- Show / Edit Booking Invoice -->
                  <v-icon
                      v-if="canSeeAndEditInvoice(data)"
                      color="primary"
                      size="18"
                      class="mr-2"
                      title="Show / Edit Booking Invoice"
                      @click="showBookingInvoice(data.data)"
                  >
                      fa-solid fa-money-check-dollar-pen
                  </v-icon>
                </div>
              </template>
            </DxDataGrid>
          </v-col>
          <v-col v-if="capacityForm" :cols="formLength">
              <transport-planner-list-capacity-form
                @close="closeCapacityForm(false)"
                @closeAndReload="closeCapacityForm(true)"
                :direction="instanceDirection"
                :instanceId="selectedInstance"
                :serviceId="selectedServiceId"
                :key="`plannerListCapacityForm+${componentKey}`"
              ></transport-planner-list-capacity-form>
          </v-col>
        </v-row>
  </v-container>

    <!-- Change Date Dialog -->
    <v-dialog
        content-class="changeDateDialog"
        v-model="dateChangeDialog"
        max-width="375px"
        persistent
    >
      <transport-planner-form-date-change
          @close="closeChangeDateForm(false)"
          @closeAndReload="closeChangeDateForm(true)"
          :key="`tpChangeDateForm+${componentKey}`"
      >
      </transport-planner-form-date-change>
    </v-dialog>


    <!-- Info Dialog -->
    <v-dialog
      v-model="infoDialog"
      max-width="800px"
      persistent
    >
        <transport-planner-list-info-dialog
            @close="infoDialog = false"
            ref="infoDialog"
            :key="`plannerListDialog+${componentKey}`"
        ></transport-planner-list-info-dialog>
    </v-dialog>

    <!-- Issue Dialog -->
    <v-dialog
      v-model="issueDialog"
      max-width="600px"
      persistent
    >
      <transport-planner-issues-dialog
          @close="issueDialog = false"
          ref="issueDialog"
          :key="`plannerListIssueDialog+${componentKey}`"
      ></transport-planner-issues-dialog>
    </v-dialog>

    <!-- Invoice Dialog -->
    <v-dialog
      v-model="invoiceDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <transport-planner-invoice-dialog
        :bookingId="selectedBookingId"
        @close="invoiceDialog = false"
        :key="`invoiceDialog+${componentKey}`"
      >
      </transport-planner-invoice-dialog>
    </v-dialog>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxPaging,
  DxPager,
  DxFilterRow,
  DxHeaderFilter,
  // DxFilterPanel,
  // DxFilterBuilderPopup,
  // DxSorting,
  DxColumn,
  // DxScrolling
} from "devextreme-vue/data-grid";
import moment from "moment";
import {mapActions, mapMutations, mapState} from "vuex";
import {TransportPlannerStatus} from "@/utils/constants";
import UserRoles from "@/auth/authUserRoles";
import {getInstance} from "@/auth";
import CMSApi from "@/services/CMSApi";
import TransportPlannerFormDateChange from "@/components/transportCapacity/planner/TransportPlannerForm-DateChange.vue";
import TransportPlannerListInfoDialog from "@/components/transportCapacity/planner/TransportPlannerList-InfoDialog.vue";
import TransportPlannerListCapacityForm
    from "@/components/transportCapacity/planner/TransportPlannerList-CapacityForm.vue";
import TransportPlannerInvoiceDialog from "@/components/transportCapacity/planner/TransportPlanner-InvoiceDialog.vue";
import TransportPlannerIssuesDialog from "@/components/transportCapacity/planner/TransportPlanner-IssuesDialog.vue";

export default {
  name: "TransportPlannerList",

  components : {
    TransportPlannerIssuesDialog,
      TransportPlannerInvoiceDialog,
      TransportPlannerListCapacityForm,
      TransportPlannerListInfoDialog,
    TransportPlannerFormDateChange,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxPager,
    DxFilterRow,
    DxHeaderFilter,
  },

  data () {
    return {
      // Grid Config
      transportPlannerGridRefKey: 'data-grid',
      pageSizes: [10],
      showAdvancedFiltering: false,
      searchText: '',
      filterBuilderPopupPosition: {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 30 }
      },
      filters: [],

      // Switch
      switchMe: false,
      masterView: false,

      // Date Pickers
      datePickerModal: false,

      // Headers
      dateHeaders: [
        { text: 'Actions', value: '', useTemplate: true , cellTemplate: "actionAndMasterCellTemplate" , type: 'string', allowSearch: false, width: '15%' },
        { text: 'Date', value: 'instanceDate', useTemplate: false , type: 'date', format: 'dd/MM/yyyy', allowSearch: true, width: '10%' },
        { text: 'Service Name', value: 'trainServiceName', useTemplate: false , type: 'string', allowSearch: true, width: '15%' },
        { text: 'Direction', value: 'trainServiceDirection', useTemplate: false , type: 'string', allowSearch: true, width: '15%' },
        { text: 'Capacity (# of Wagons)', value: 'capacity', useTemplate: false , type: 'number', allowSearch: false, width: '15%' },
        { text: 'Booked (TEU)', value: 'booked', useTemplate: false , type: 'number', allowSearch: false, width: '15%' },
        { text: 'Available (TEU)', value: 'available', useTemplate: false , type: 'number', allowSearch: false, width: '15%' },
      ],
      wagonHeaders : [
        { text: 'Actions', value: '', useTemplate: true , cellTemplate: "actionCellTemplate", type: 'string', width: '12%' },
        { text: 'Date', value: 'instanceDate', useTemplate: false , type: 'date', format: 'dd/MM/yyyy', allowSearch: true, width: '6%' },
        { text: 'Wagon', value: 'wagonSequence', useTemplate: false , type: 'string', width: '5%' },
        { text: 'TEU', value: 'capacityTeu', useTemplate: false , type: 'number', width: '4%' },
        { text: 'Route', value: 'route', useTemplate: false , type: 'string', width: '8%' },
        { text: 'Booking#', value: 'bookingReferenceNumber', useTemplate: false , type: 'string', width: '8%' },
        { text: 'Forwarder', value: 'forwarder', useTemplate: false , type: 'string', width: '7%' },
        { text: 'Customer', value: 'sender', useTemplate: false , type: 'string', width: '8%' },
        { text: 'Unit Number', value: 'containerNumber', useTemplate: false , type: 'string', width: '8%' },
        { text: 'Empty Rel. #', value: 'emptyReleaseNumber', useTemplate: false , type: 'string', width: '8%' },
        { text: 'Commodity', value: 'containerCommodity', useTemplate: false , type: 'string', width: '7%' },
        { text: 'GenSet?', value: 'isGenset', useTemplate: false , type: 'boolean', width: '6%' },
        { text: 'Hazard?', value: 'isHazardous', useTemplate: false , type: 'boolean', width: '6%' },
        { text: 'Status', value: 'status', useTemplate: false , type: 'string', width: '7%' },
      ],

      // Dialogs
      dateChangeDialog : false,
      infoDialog : false,
      invoiceDialog : false,
      issueDialog : false,

      // ComponentKey
      componentKey: 0,
      selectedInstance: null,
      selectedServiceId: null,
      instanceDirection : null,
      selectedBookingId : null,

      // Loading Indicators
      changeDateFormInitialLoad: true,
      infoDialogInitialLoad: true,
      invoiceDialogInitialLoad: true,
      issueDialogInitialLoad: true,

      // Form
      capacityForm : false,
      issues: [],
    }
  },

  async mounted() {
    await this.initFetch();
    // this.fromDate = moment().format('YYYY-MM-DD');
    // this.toDate = moment().add(7,'days').format('YYYY-MM-DD');
    // this.dates.push(this.fromDate,this.toDate);
    // this.dateRangeSelected = this.dates.sort().join('~');

    // await this.fetchPlannerListByDates({ fromDate: this.fromDate, toDate: this.toDate});

  },

  computed : {
    ...mapState('transportPlanner', [
      'transportPlannerList',
      'transportPlannerWagonList',
      'loadingTransportPlannerList',
      'loadingTransportPlannerWagonList',
      'workingPlannerBooking',
      'datesObject',
      'dateRangeSelected',
      'fromDate',
      'toDate',
    ]),

    dates: {
      get()  { return this.datesObject },
      set(newVal) { this.$store.commit('transportPlanner/setDatesObject', newVal) }
    },

    datesSelected: {
      get()  { return this.dateRangeSelected },
      set(newVal) { this.$store.commit('transportPlanner/setDateRangeSelected', newVal) }
    },

    plannerList() {
      return this.transportPlannerList;
    },

    plannerWagonList() {
      return this.transportPlannerWagonList;
    },

    plannerListLoading() {
      return this.loadingTransportPlannerList;
    },

    plannerWagonListLoading() {
      return this.loadingTransportPlannerWagonList;
    },

    headers() {
      return this.masterView ? this.wagonHeaders : this.switchMe ? this.wagonHeaders : this.dateHeaders;
    },

    userRoles() {
      return UserRoles;
    },

    gridListLength() {
        return this.capacityForm ? 10 : 12;
    },

    formLength() {
        return this.capacityForm ? 2 : 0;
    }
  },

  watch: {
    searchText: function (newVal) { // eslint-disable-line no-unused-vars
      this.$refs[this.transportPlannerGridRefKey]["instance"].searchByText(newVal?.trim());
    },

    switchMe: function (newVal) { // eslint-disable-line no-unused-vars
      this.reload();
    },
  },

  methods: {
    ...mapActions('transportPlanner', [
      'fetchPlannerListByDates',
      'fetchPlannerWagonListByDates',
      'fetchPlannerWagonListByInstance',
      'fetchRoadBridgeCodes',
      'initNewWorkingPlanner',
      'setWorkingPlannerDataForEdit',
      'initFetch'
    ]),

    ...mapMutations('transportPlanner', [
        'setFormFromMaster'
    ]),

    // Grid Buttons functions
    canSeeInfo(e) {
        return (e.data.status === TransportPlannerStatus.Complete || e.data.status === TransportPlannerStatus.Invoiced);
    },

    canBeInvoiced(e) {
      return (e.data.status === TransportPlannerStatus.Complete)
          &&
          !e.data.hasBillingIssues
          &&
          this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator);
    },

    canBeReInvoiced(e) {
      return (e.data.status === TransportPlannerStatus.Invoiced)
          &&
          this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator);
    },

    canSeeAndEditInvoice(e) {
      return (e.data.status === TransportPlannerStatus.Invoiced)
          &&
          this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator);
    },

    canCapacityBeDeleted(e) {
      return (e.data.status === TransportPlannerStatus.Available)
      &&
      this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator);
    },

    canBeBooked(e) {
      // return (e.data.status === TransportPlannerStatus.Available && window.Moment(e.data.instanceDate).format('YYYY-MM-DD') >= window.Moment().format('YYYY-MM-DD'));
      return (e.data.status === TransportPlannerStatus.Available && window.Moment(e.data.instanceDate).format('YYYY-MM-DD') >= window.Moment().format('YYYY-MM-DD'));
    },

    canBeDeEdited(e) {
      return (
                (
                    (e.data.status === TransportPlannerStatus.Draft || e.data.status === TransportPlannerStatus.InProgress)
                    &&
                    window.Moment(e.data.instanceDate).format('YYYY-MM-DD') >= window.Moment().format('YYYY-MM-DD')
                )
                ||
                (
                    e.data.status !== TransportPlannerStatus.Available
                    &&
                    e.data.status !== TransportPlannerStatus.Complete
                    &&
                    e.data.status !== TransportPlannerStatus.Invoiced
                    &&
                    this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator)
                )
              );
    },

    canBeDeleted(e) {
      return (
            e.data.status === TransportPlannerStatus.Available
          &&
          this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator));
    },

    canShowIssues(e) {
      return (
            e.data.status === TransportPlannerStatus.Draft ||
            e.data.status === TransportPlannerStatus.InProgress ||
            e.data.status === TransportPlannerStatus.Confirmed ||
            e.data.status === TransportPlannerStatus.ReadyToSubmit ||
            e.data.status === TransportPlannerStatus.Complete ) && e.data["hasBillingIssues"];
    },

    canBeBackToDraft(e) {
      return (
          (e.data.status === TransportPlannerStatus.Invoiced ||
          e.data.status === TransportPlannerStatus.Complete ||
          e.data.status === TransportPlannerStatus.Confirmed ||
          e.data.status === TransportPlannerStatus.ReadyToSubmit)
          &&
          (
          window.Moment(e.data.instanceDate).format('YYYY-MM-DD') >= window.Moment().format('YYYY-MM-DD')
          ||
          this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator))
      );
    },

    canBeReadyToSubmit(e) {
      return (
          e.data.status === TransportPlannerStatus.Draft
          &&
          window.Moment(e.data.instanceDate).format('YYYY-MM-DD') >= window.Moment().format('YYYY-MM-DD')
          &&
          this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator)
      );
    },

    canBeMerge(e) {
      return (e.data.canBeMerge && this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator))
    },

    canBeCancelled(e) {
      return (e.data.status === TransportPlannerStatus.Draft && window.Moment(e.data.instanceDate).format('YYYY-MM-DD') >= window.Moment().format('YYYY-MM-DD')
      ||  (
          e.data.status !== TransportPlannerStatus.Available  &&
          e.data.status !== TransportPlannerStatus.Invoiced   &&
              this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator)
          ))
    },

    canChangeDate(e) {
      return (
          (
              e.data.status === TransportPlannerStatus.Draft &&
              ( window.Moment(e.data.instanceDate).format('YYYY-MM-DD') >= window.Moment().format('YYYY-MM-DD') ||
                this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator)
              )
          )
          ||
          (
              e.data.status !== TransportPlannerStatus.Available &&
              e.data.status !== TransportPlannerStatus.Complete &&
              e.data.status !== TransportPlannerStatus.Invoiced &&
              this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator)
          ) );
    },

    canBeCompleted(e) {
      return (e.data.status === TransportPlannerStatus.Confirmed)
          ||
          (e.data.status !== TransportPlannerStatus.Available &&
           e.data.status !== TransportPlannerStatus.Complete &&
           e.data.status !== TransportPlannerStatus.Invoiced &&
           this.userHasRole(this.userRoles.PortAdmin, this.userRoles.TransportAdmin, this.userRoles.TransportCoordinator)
          );
    },

    // Booking
    async showBookingForm(data) {
      App["$appAnalytics"].trackEvent('Transport Planner Wagon Instance List - New Booking Click');

      this.initNewWorkingPlanner();

      this.workingPlannerBooking.routes = data.route;
      this.workingPlannerBooking.instanceDate = window.Moment(data.instanceDate).format('YYYY-MM-DD');
      this.workingPlannerBooking.wagonCapacity = data.capacityTeu;
      this.workingPlannerBooking.trainServiceWagonInstanceId = data.trainServiceWagonInstanceId;
      this.setFormFromMaster(this.masterView);
      this.$emit('activateForm');
    },

    // Editing
    async editPlannerBooking(e) {
      App["$appAnalytics"].trackEvent('Transport Planner Wagon List - Edit Booking Click');

      await this.fetchRoadBridgeCodes();
      await this.$nextTick();

      CMSApi.fetchPlannerBookingData(e.trainServicePlannerBookingId)
          .then(data => {
            this.setWorkingPlannerDataForEdit(data);
            this.workingPlannerBooking.routes = e.route;
            this.workingPlannerBooking.instanceDate = window.Moment(e.instanceDate).format('YYYY-MM-DD');
            this.workingPlannerBooking.wagonCapacity = e.capacityTeu;
            this.workingPlannerBooking.trainServiceWagonInstanceId = e.trainServiceWagonInstanceId;
            this.workingPlannerBooking.trainServicePlannerBookingId = e.trainServicePlannerBookingId;
            this.setFormFromMaster(this.masterView);
            this.$emit('activateFormEdit');
          })
    },

    // Delete Capacity
    async deleteCapacity(data) {
      if (await this.$root["$confirm"].open('Delete', 'Proceed to remove this capacity?' , { color: 'warning' })) {
        CMSApi.deleteCapacity(data.trainServiceWagonInstanceId)
            .then(() => {
              window.App.$emit('show-snackbar', "success", "Capacity removed");
              this.reload();
            })
      }
    },

    // Cancel Booking
    async cancelBooking(data) {
      if (await this.$root["$confirm"].open('Cancellation', 'Proceed to cancel this booking?' , { color: 'warning' })) {
        App["$appAnalytics"].trackEvent('Transport Planner Wagon Instance List - Cancel Booking Click');
        CMSApi.deletePlannerBooking(data.trainServicePlannerBookingId)
            .then(() => {
              window.App.$emit('show-snackbar', "success", "Booking cancelled");
              this.reload();
            })
      }
    },

    // Merge back separated wagons
    async mergeWagonSlots(data) {
      if (await this.$root["$confirm"].open('Merge back', 'Proceed to merge this split slots?' , { color: 'warning' })) {
        App["$appAnalytics"].trackEvent('Transport Planner Wagon Instance List - Merge Wagon Click');
        CMSApi.putMergeWagonSlots(data.trainServiceWagonInstanceId)
            .then(() => {
              window.App.$emit('show-snackbar', "success", "Slots are merge!");
              this.reload();
            })
      }
    },

    // Change Booking Date
    async showChangeDateForm(data) {
      App["$appAnalytics"].trackEvent('Transport Planner Wagon List - Change Date Click');
      if (!this.changeDateFormInitialLoad)
        this.componentKey += 1;

      this.workingPlannerBooking.trainServiceWagonInstanceId = data.trainServiceWagonInstanceId;
      this.workingPlannerBooking.trainServicePlannerBookingId = data.trainServicePlannerBookingId;
      this.dateChangeDialog = true; // Show Dialog
      this.changeDateFormInitialLoad = false;
    },

    // Ready to Submit
    async readyToSubmit(data) {
      if (await this.$root["$confirm"].open('Submit', 'Is the booking ready for submission?' , { color: 'info' })) {
        App["$appAnalytics"].trackEvent('Transport Planner Wagon Instance List - Ready to Submit Click');
        CMSApi.putChangeBookingStatus(data.trainServicePlannerBookingId, TransportPlannerStatus.ReadyToSubmit)
            .then(() => {
              window.App.$emit('show-snackbar', "success", "Booking is tagged as ready for submission");
              this.reload();
            })
      }
    },

    // Back To Draft
    async backToDraft(e) {
      if (await this.$root["$confirm"].open('Back to Draft ?', 'Are you sure you want to change the state of this booking ?', { color: 'warning'})) {
        CMSApi.putChangeBookingStatus(e.trainServicePlannerBookingId, TransportPlannerStatus.Draft)
            .then(() => {
              window.App.$emit('show-snackbar', "success", "Booking is now back to draft");
              this.reload();
            })
      }
    },

    // Complete Booking
    async completeBooking(e) {
      if (await this.$root["$confirm"].open('Notification', 'Complete the Booking ?', { color: 'info' })) {

        CMSApi.putChangeBookingStatus(e.trainServicePlannerBookingId, TransportPlannerStatus.Complete)
            .then(() => {

              if (e["hasBillingIssues"])
              {

                window.App.$emit('show-snackbar', "info", "Booking is now completed, but has billing issues. Fix them first then calculate the invoice");
                this.reload();

              } else {

                if (e.isReturning) {

                  window.App.$emit('show-snackbar', "info", "Booking is now completed but not ready for invoicing. Please wait for the return trip to be completed");
                  this.reload();

                } else {

                  CMSApi.putCalculateInvoice(e["trainServicePlannerBookingId"])
                      .then( () => {
                        window.App.$emit('show-snackbar', 'success', 'Booking is now completed and invoice calculated');
                      })
                      .catch( async(error) => {
                        let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                          this.$root["$alert"].show('Unable to calculate invoice', errorMessage);
                      })
                      .finally(() => {
                        this.reload();
                      });
                }
              }
            })
      }
    },

    // Show Booking Info
    async showBookingInfo(e) {
        if (!this.infoDialogInitialLoad)
            this.componentKey += 1;

        this.infoDialogInitialLoad = false;
        CMSApi.fetchPlannerBookingData(e.trainServicePlannerBookingId)
            .then(async data => {
                this.infoDialog = true;
                await this.$nextTick();
                this.$refs.infoDialog.init(data, e);
            });
    },

    // Show Issues
    async showIssues(e) {
      if (!this.issueDialogInitialLoad)
        this.componentKey += 1;

      this.issueDialogInitialLoad = true;

      this.issueDialog = true;
      await this.$nextTick();
      this.$refs.issueDialog.init(e);
    },

    // Show Wagon List of Instance by Instance ID
    async showWagonListOfInstance(e) {
      this.filters = this.$refs[this.transportPlannerGridRefKey]["instance"].getCombinedFilter(true);

      // Switch headers
      this.masterView = true;
      await this.$nextTick();
      this.selectedInstance = e["trainServiceInstanceId"];
      this.selectedServiceId = e["trainServiceId"];
      this.instanceDirection = e["trainServiceDirection"];
      await this.reload();
    },

    // Close MasterView
    async closeMasterView() {
      this.masterView = false;
    },

    async deleteTransportServiceInstance(e) {
      e.cancel = true;

      if (await this.$root["$confirm"].open('Cancel Transport Service Instance?', 'Are you sure you want to cancel this instance?', {color: 'warning'}))
      {
        App["$appAnalytics"].trackEvent('Transport Planner - Delete Transport Service Instance Click');
        CMSApi.deleteInstance(e.data["trainServiceInstanceId"])
            .then( () => {
              window.App.$emit('show-snackbar', 'success', 'Train Service Instance removed');
            })
            .catch(error => {
              let errorMessage = this.extractErrorsFromAjaxResponse(error.response.data.errors);
              this.$root["$alert"].show('Unable to remove Transport Service Instance', errorMessage)
            })
            .finally(() => {
              this.reload();
            });
      }
    },

    async calculateBookingInvoice(e) {
      e.cancel = true;

      if (await this.$root["$confirm"].open('Calculate Invoice?', 'Are you sure you want to calculate invoice for this booking?', {color: 'info'}))
      {
        App["$appAnalytics"].trackEvent('Transport Planner - Calculate Invoice Click');
        CMSApi.putCalculateInvoice(e["trainServicePlannerBookingId"])
            .then( () => {
              window.App.$emit('show-snackbar', 'success', 'Invoice calculated');
            })
            .catch( async(error) => {
                let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);

                if (errorMessage.includes("override"))
                {

                  if (await this.$root["$confirm"].open('Override ?', 'This customer is missing billing items. Proceed in using default billing items?', {color: 'warning'}))
                  {
                    CMSApi.putCalculateInvoiceWithDefaultItems(e["trainServicePlannerBookingId"])
                        .then( () => {
                          window.App.$emit('show-snackbar', 'success', 'Invoice calculated');
                        })
                        .catch(error => {
                          let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                          this.$root["$alert"].show('Unable to calculate invoice', errorMessage)
                        })
                        .finally(() => {
                          this.reload();
                        });
                  }

                } else {
                  this.$root["$alert"].show('Unable to calculate invoice', errorMessage)
                }
            })
            .finally(() => {
              this.reload();
            });
      }

    },

    async reCalculateBookingInvoice(e) {
      e.cancel = true;

      if (await this.$root["$confirm"].open('Re-Calculate Invoice?', 'Doing so would remove all the items into this invoice. Do you want to proceed?', {color: 'info'}))
      {
        App["$appAnalytics"].trackEvent('Transport Planner - Calculate Invoice Click');
        CMSApi.putReCalculateInvoice(e["trainServicePlannerBookingId"])
            .then( () => {
              window.App.$emit('show-snackbar', 'success', 'Invoice Re-Calculated');
            })
            .catch( async(error) => {
              let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);

              if (errorMessage.includes("override"))
              {

                if (await this.$root["$confirm"].open('Override ?', 'This customer is missing billing items. Proceed in using default billing items?', {color: 'warning'}))
                {
                  CMSApi.putReCalculateInvoiceWithDefaultItems(e["trainServicePlannerBookingId"])
                      .then( () => {
                        window.App.$emit('show-snackbar', 'success', 'Invoice calculated');
                      })
                      .catch(error => {
                        let errorMessage = window.App.extractErrorsFromAjaxResponse(error.response.data.errors);
                        this.$root["$alert"].show('Unable to recalculate invoice', errorMessage)
                      })
                      .finally(() => {
                        this.reload();
                      });
                }

              } else {
                this.$root["$alert"].show('Unable to recalculate invoice', errorMessage)
              }
            })
            .finally(() => {
              this.reload();
            });
      }
    },

    showBookingWithIssues(e) {
      if (e.rowType === "data") {
        if (e.data["hasBillingIssues"]) {
          e["rowElement"].style.backgroundColor = "#b5b4b4";
          e["rowElement"].style.color = "white";
        }
      }
    },

    showBookingInvoice(e) {
      if (!this.invoiceDialogInitialLoad)
        this.componentKey += 1;

      this.invoiceDialogInitialLoad = false;
      this.invoiceDialog = true;
      this.selectedBookingId = e["trainServicePlannerBookingId"];
    },

    closeChangeDateForm(reload) {
      this.dateChangeDialog = false;

      if (reload)
        this.reload();
    },

    closeCapacityForm(reload) {
        this.capacityForm = false;

        if (reload)
            this.reload();
    },

    // Misc functions
    dateRangeFilled(e) {
      this.$store.commit('transportPlanner/setDateRangeSelected', e.sort().join('~'));
      this.$store.commit('transportPlanner/setFromDate', e[0]);
      this.$store.commit('transportPlanner/setToDate', e[1]);
    },

    hasSelectedRange(){
      this.datePickerModal = false;
      this.reload();
    },

    async reload() {
      this.masterView ? await this.fetchPlannerWagonListByInstance(this.selectedInstance) : this.switchMe ? await this.fetchPlannerWagonListByDates( { fromDate: this.fromDate, toDate: this.toDate} ) : await this.fetchPlannerListByDates( { fromDate: this.fromDate, toDate: this.toDate} );
    },

    userHasRole(roles) {
      let authService = getInstance();
      return authService.userHasRole(roles);
    },

    goToCurrentWeek(){
      moment.updateLocale('en', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });

      const today = moment();
      let from_date = today.startOf('week').format('YYYY-MM-DD');
      let to_date = today.endOf('week').format('YYYY-MM-DD');

      this.$store.commit('transportPlanner/setDatesObject', [from_date, to_date]);
      this.$store.commit('transportPlanner/setDateRangeSelected', [from_date, to_date].sort().join('~'));
      this.$store.commit('transportPlanner/setFromDate', from_date);
      this.$store.commit('transportPlanner/setToDate', to_date);

      this.reload();
    },

    goToNextWeek() {
      moment.updateLocale('en', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });

      let current_from_date = this.fromDate;
      let new_from_date = moment(current_from_date).add(7, 'days').format('YYYY-MM-DD');

      let from_date = moment(new_from_date).startOf('week').format('YYYY-MM-DD');
      let to_date = moment(new_from_date).endOf('week').format('YYYY-MM-DD');

      this.$store.commit('transportPlanner/setDatesObject', [from_date, to_date]);
      this.$store.commit('transportPlanner/setDateRangeSelected', [from_date, to_date].sort().join('~'));
      this.$store.commit('transportPlanner/setFromDate', from_date);
      this.$store.commit('transportPlanner/setToDate', to_date);

      this.reload();
    },

    goToPreviousWeek() {
      moment.updateLocale('en', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });

      let current_from_date = this.fromDate;
      let new_from_date = moment(current_from_date).add(-7, 'days').format('YYYY-MM-DD');

      let from_date = moment(new_from_date).startOf('week').format('YYYY-MM-DD');
      let to_date = moment(new_from_date).endOf('week').format('YYYY-MM-DD');

      this.$store.commit('transportPlanner/setDatesObject', [from_date, to_date]);
      this.$store.commit('transportPlanner/setDateRangeSelected', [from_date, to_date].sort().join('~'));
      this.$store.commit('transportPlanner/setFromDate', from_date);
      this.$store.commit('transportPlanner/setToDate', to_date);

      this.reload();
    }
  },
}
</script>

<style scoped>

.activeSwitch {
  color : #4630d8;
}

.inactiveSwitch {
  color: grey;
}

</style>