<template>
    <div>
        
        <v-icon class="importStatusIconSize" :color="colour" v-if="description == ''" >{{ icon }}</v-icon>

        <v-tooltip 
            v-if="description != ''"
            right>
            <template v-slot:activator="{ on, attrs }">
                <v-icon :color="colour"
                    class="importStatusIconSize"
                    v-bind="attrs"
                    v-on="on"
                >{{ icon }}</v-icon>
            </template>
            <span>
                {{description}}
            </span>
        </v-tooltip>
                    
    </div>
</template>

<script>

export default {
    props: {
        colour: String,
        icon: String,
        description: String,
    },
};
</script>

<style scoped>
    .importStatusIconSize:not(.mdi-lock){
        font-size: 30px;
    }
</style>