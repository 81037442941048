import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from './views/Login.vue'
import Appointments from './views/Appointments.vue'
import ImportAdvice from './views/ImportAdvice.vue'
import ExportPreAdvice from './views/ExportPreAdvice.vue'
import Reports from './views/Reports.vue'

import CompanyAppointments from "@/views/reports/CompanyAppointments";

import Notifications from './views/Notifications.vue'
import Cameras from './views/Cameras.vue'
import UserManagement from './views/admin/UserManagement.vue'
import PortAdminAppointments from './views/portAdmin/Appointments.vue'
import PortAdminCompanyManagement from './views/portAdmin/CompanyManagement.vue'
import PortAdminReportManagement from './views/portAdmin/ReportManagement'
import PortAdminUserManagement from './views/portAdmin/UserManagement.vue'
import PortAdminNotifications from './views/portAdmin/Notifications.vue'
import PortAdminGate from './views/portAdmin/GateManagement.vue'
import Help from './views/Help.vue'
import Terms from './views/Terms.vue'
import { authGuard } from "./auth/authGuard";
import UserRoles from "@/auth/authUserRoles"
import PassThrough from "@/components/global/PassThrough.vue";
import TransportMappings from "@/components/transportCapacity/mappings/TransportMappings.vue";
import TransportService from "@/components/transportCapacity/service/TrainService.vue";
import TransportPlanner from "@/components/transportCapacity/planner/TransportPlanner.vue";
import TransportBillings from "@/components/transportCapacity/billing/TransportBillings.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            path: '/appointments',
            name: 'appointments',
            component: Appointments,
            meta: {
                permittedRoles: [UserRoles.Dispatcher],
                menuItem: {
                    description: 'Appointments',
                    icon: 'mdi-calendar-check'
                }
            }
        },
        {
            path: '/import-advice',
            name: 'importadvice',
            component: ImportAdvice,
            meta: {
                permittedRoles: [UserRoles.ImportAdvisor],
                menuItem: {
                    description: 'Import Advice',
                    icon: 'mdi-arrow-right-thin-circle-outline'
                }
            }
        },
        {
            path: '/export-pre-advice',
            name: 'exportpreadvice',
            component: ExportPreAdvice,
            meta: {
                permittedRoles: [UserRoles.ExportCoordinator],
                menuItem: {
                    description: 'Export Pre-Advice',
                    icon: 'mdi-export'
                }
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/reports-company-appointments',
            name: 'companyappointments',
            component: CompanyAppointments,
            meta: {
                permittedRoles: [UserRoles.Dispatcher],
                menuItem: {
                    description: 'Appointment Reports',
                    icon: 'mdi-chart-bar-stacked',
                }
            }
        },
        {
            path: '/reports',
            name: 'reports',
            component: Reports,
            meta: {
                permittedRoles: [UserRoles.ReportViewer],
                menuItem: {
                    description: 'Reports',
                    icon: 'mdi-poll',
                }
            }
        },
        {
            path: '/notifications',
            name: 'notifications',
            component: Notifications,
            meta: {
                menuItem: {
                    description: 'Notifications',
                    icon: 'mdi-bell-ring-outline'
                }
            }
        },
        {
            path: '/admin/users',
            name: 'admin-usermanagement',
            component: UserManagement,
            meta: {
                permittedRoles: [UserRoles.CompanyAdmin],
                menuItem: {
                    description: 'Users',
                    icon: 'mdi-account-multiple',
                }
            }
        },
        {
            path: '/cameras',
            name: 'cameras',
            component: Cameras,
            meta: {
                menuItem: {
                    description: 'Cameras',
                    icon: 'mdi-cctv',
                }
            }
        },
        {
            path: '/portadmin/appointments',
            name: 'portadmin-appointments',
            component: PortAdminAppointments,
            meta: {
                permittedRoles: [UserRoles.PortAdmin],
                menuItem: {
                    description: 'Company Appointments',
                    icon: 'mdi-calendar-cursor',
                    divider: true,
                }
            }
        },
        {
            path:'/management/:id',
            name: 'Management',
            component: PassThrough,
            meta: {
                group: true,
                active: false,
                menuItem: {
                    description: 'Management',
                    icon: 'fa-solid fa-users-gear',
                }
            },
            children: [
                {
                    path: '/management/transport-capacity/:id',
                    name: 'management-transport-capacity',
                    component: PassThrough,
                    meta: {
                        group: true,
                        permittedRoles: [UserRoles.TransportAdmin, UserRoles.TransportCoordinator],
                        menuItem: {
                            description: 'Transport Capacity',
                            icon: 'mdi-boom-gate'
                        }
                    },
                    children: [
                        {
                            path: '/management/transport-capacity/planner',
                            name: 'Planner',
                            component: TransportPlanner,
                            meta: {
                                group: false,
                                permittedRoles: [UserRoles.TransportAdmin, UserRoles.TransportCoordinator],
                                menuItem: {
                                    description: 'Planner',
                                    parent: 'Management',
                                    icon: 'mdi-calendar-expand-horizontal'
                                }
                            }
                        },
                        {
                          path: '/management/transport-capacity/service',
                          name: 'Service',
                          component: TransportService,
                          meta: {
                              group: false,
                              permittedRoles: [UserRoles.TransportAdmin, UserRoles.TransportCoordinator],
                              menuItem: {
                                  description: 'Service',
                                  parent: 'Management',
                                  icon: 'mdi-chart-timeline'
                              }
                          }
                        },
                        {
                            path: '/management/transport-capacity/mappings',
                            name: 'Mappings',
                            component: TransportMappings,
                            meta: {
                                group: false,
                                permittedRoles: [UserRoles.TransportAdmin, UserRoles.TransportCoordinator],
                                menuItem: {
                                    description: 'Mappings',
                                    parent: 'Management',
                                    icon: 'mdi-sitemap'
                                }
                            }
                        },
                        {
                            path: '/management/transport-capacity/billings',
                            name: 'Billings',
                            component: TransportBillings,
                            meta: {
                                group: false,
                                permittedRoles: [UserRoles.TransportAdmin, UserRoles.TransportCoordinator],
                                menuItem: {
                                    description: 'Billing',
                                    parent: 'Management',
                                    icon: 'mdi-currency-usd'
                                }
                            }
                        }
                    ],
                },
                {
                    path: '/management/gate',
                    name: 'management-gate',
                    component: PortAdminGate,
                    meta: {
                        group: false,
                        permittedRoles: [UserRoles.PortAdmin, UserRoles.Dispatcher, UserRoles.Driver, UserRoles.DriverPlus],
                        menuItem: {
                            description: 'Gate',
                            parent: 'Management',
                            icon: 'mdi-boom-gate'
                        }
                    }
                },
                {
                    path: '/management/notification',
                    name: 'management-notifications',
                    component: PortAdminNotifications,
                    meta: {
                        group: false,
                        permittedRoles: [UserRoles.PortAdmin],
                        menuItem: {
                            description: 'Notification',
                            parent: 'Management',
                            icon: 'mdi-alert-rhombus-outline',
                        }
                    }
                },
                {
                    path: '/management/companies',
                    name: 'management-companies',
                    component: PortAdminCompanyManagement,
                    meta: {
                        group: false,
                        permittedRoles: [UserRoles.PortAdmin],
                        menuItem: {
                            description: 'Company',
                            parent: 'Management',
                            icon: 'mdi-domain',
                        }
                    }
                },
                {
                    path: '/management/report',
                    name: 'management-report',
                    component: PortAdminReportManagement,
                    meta: {
                        group: false,
                        permittedRoles: [UserRoles.PortAdmin],
                        menuItem: {
                            description: 'Report',
                            parent: 'Management',
                            icon: 'mdi-table-cog'
                        }
                    }
                },
                {
                    path: '/management/user',
                    name: 'management-user',
                    component: PortAdminUserManagement,
                    meta: {
                        group: false,
                        permittedRoles: [UserRoles.PortAdmin],
                        menuItem: {
                            description: 'User',
                            parent: 'Management',
                            icon: 'mdi-account-multiple'
                        }
                    }
                },
            ]
        },
        {
            path:'/configurations/:id',
            name: 'Configurations',
            component: PassThrough,
            meta: {
                group: true,
                active: false,
                menuItem: {
                    description: 'Configurations',
                    icon: 'fa-solid fa-gear',
                }
            },
            children: [
                {
                    path: '/configurations/gate',
                    name: 'portadmin-gate',
                    component: PortAdminGate,
                    meta: {
                        group: false,
                        permittedRoles: [UserRoles.PortAdmin, UserRoles.Dispatcher, UserRoles.Driver, UserRoles.DriverPlus],
                        menuItem: {
                            description: 'Gate Management',
                            icon: 'mdi-boom-gate'
                        }
                    }
                },
            ]
        },
        {
            path: '/help',
            name: 'help',
            component: Help,
            meta: {
                group: false,
                menuItem: {
                    description: 'Help',
                    icon: 'mdi-help-circle-outline'
                }
            }
        },
        {
            path: '/terms',
            name: 'terms',
            component: Terms,
        },
        {
            path: '/smartflow',
            name: 'smartflow',
            beforeEnter() {
                window.location.href = process.env.VUE_APP_SMARTFLOW_LINK
            }
        }
    ]
});

//As we want all pages secured by default, we are using a global guard to test for authentication/authorisation 
//In a website where you have plenty of unsecured pages, you would likely define by each route e.g. beforeEnter: authGuard 
router.beforeEach(authGuard);
export default router;