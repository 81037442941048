<template>
  <div>
    <v-card elevation="3" id="report" height="800px">
      <v-container>
        <v-btn
            :key="activeFab.icon"
            :color="activeFab.color"
            elevation="2"
            fab
            right
            top
            class="v-btn--showReportPanel"
            @click="showReportPanel = !showReportPanel"
            title="Show Reports List"
            style="width: fit-content;z-index: 3; height: 80px"
            :style="{left: activeFab.position}"
            :disabled="selectedReportId === null"
        >
          <v-icon>{{ activeFab.icon }}</v-icon>
        </v-btn>
        <br>
        <br>
        <br>
        <v-row style="">
          <v-col cols="3" v-show="showReportPanel" :style="{ position: showReportPanel ? 'absolute' : 'unset', top: 0}">
            <v-card>
              <v-card-title>Report Panel</v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="filtersForm" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            v-model="selectedReportId"
                            :items="userReports"
                            label="Reports"
                            item-text="reportName"
                            item-value="reportId"
                            clearable
                            no-data-text="You have no reports to access"
                            @click:clear="resetReportFilters"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <div v-if="needFilters">
                      <v-row v-for="filter in filters.filter(a => ['string', 'numeric'].includes(a.filterDataType))" :key="filter.reportId">
                        <v-col cols="12">
                          <v-text-field
                            v-model="filter.filterDefaultValue"
                            :label="filter.filterColumn"
                            :rules="filter.isRequired? [v => !!v || filter.filterColumn + ' is required']: []"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-for="filter in filters.filter(a => a.filterDataType === 'selection')" :key="filter.reportId">
                        <v-col cols="12">
                          <v-select
                              v-model="filter.filterDefaultValue"
                              :items="filter.items"
                              :rules="filter.isRequired? [v => !!v || filter.filterColumn + ' is required']: []"
                              :label="filter.filterColumn"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row v-for="filter in filters.filter(a => a.filterDataType === 'date')" :key="filter.reportId">
                        <v-col cols="12">
                          <v-menu
                              v-model="menu[filters.indexOf(filter)]"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="filter.filterDefaultValue"
                                  :label="filter.filterColumn"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="filter.isRequired? [v => !!v || filter.filterColumn + ' is required']: []"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.filterDefaultValue" no-title @input="menu[filters.indexOf(filter)] = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-btn
                              tile class="mb-2"
                              color="secondary"
                          >
                            <v-icon>mdi-filter-remove-outline</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp">Reset</span>
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                              absolute
                              tile class="mb-2"
                              color="secondary"
                              right
                              @click="loadData"
                          >
                            <v-icon>mdi-reload</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp">Load</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-show="!showReportPanel" :cols="showReportPanel ? 9 : 12" :style="{ position: !showReportPanel ? 'absolute' : 'unset', top: 0}">
            <v-card style="margin-left: 25px">
              <v-card-title>
                {{ selectedReportId !== null ? userReports.find(a => a.reportId === selectedReportId).reportName : ''}}
              </v-card-title>
            </v-card>
            <br>
            <v-container class="datatableControls">
              <v-row>
                <v-col cols="3">
                  <v-btn
                      tile
                      color="secondary"
                      title="Generate Report"
                      class="v-btn--loadData"
                      :disabled="!Number.isInteger(selectedReportId)"
                      :loading="loadingReportData"
                      @click="loadData"
                  >
                    <v-icon>mdi-reload</v-icon> Reload
                  </v-btn>
                  <v-btn
                      tile
                      color="secondary"
                      title="Generate Report"
                      class="v-btn--generateExcel"
                      :disabled="!Number.isInteger(selectedReportId) || !records.length > 0"
                      @click="exportXlsFile"
                  >
                    <v-icon>mdi-file-export</v-icon> Excel Export
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <v-checkbox v-model="showAdvancedFiltering" label="Show Advanced Filtering" hide-details></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-text-field clearable v-model="searchText" label="Search by anything" prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <DxDataGrid
                id="reportGrid"
                :ref="dataGridRefName"
                :show-borders="false"
                :hoverStateEnabled="true"
                :column-auto-width="true"
                :data-source="records"
                :height="630"
                @content-ready="contentReady"
            >
              <DxSorting mode="multiple"/>
              <DxPaging :page-size="10"/>
              <DxPager
                  :show-page-size-selector="true"
                  :allowed-page-sizes="pageSizes"
                  :show-info="true"
              />
              <DxFilterRow
                  :visible="showAdvancedFiltering"
              />
              <DxHeaderFilter
                  :visible="true"
              />
              <DxFilterPanel :visible="showAdvancedFiltering"/>
              <DxFilterBuilderPopup :position="filterBuilderPopupPosition"/>

              <DxColumn
                  v-for="(item,index) in columns" :key="index"
                  :data-field="item['columnName']"
                  :caption="item['columnStringName']"
                  :data-type="getDataType(item['columnType'])"
                  :format="item.columnType === 'money' ? 'currency': ''"
              />
              <DxSummary>
                <DxTotalItem
                    :column="firstColumn"
                    summary-type="count"
                >
                </DxTotalItem>
                <DxTotalItem v-for="(item, index) in columns.filter(g => g.columnType === 'money')" :key="index"
                     :column="item['columnStringName']"
                     summary-type="sum"
                     value-format="currency"
                ></DxTotalItem>
              </DxSummary>
            </DxDataGrid>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {
  DxDataGrid,
  DxColumn,
  DxSorting,
  DxFilterRow,
  DxHeaderFilter,
  DxFilterPanel,
  DxFilterBuilderPopup,
  DxPaging,
  DxPager,
  DxSummary,
  DxTotalItem,
} from "devextreme-vue/data-grid";

export default {
  name: 'reports',

  components: {
    DxDataGrid,
    DxColumn,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxFilterPanel,
    DxFilterBuilderPopup,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem
  },

  data() {
    return {
      // Grid
      searchText: null,
      dataGridRefName: 'dataGrid',
      showAdvancedFiltering: false,
      filterBuilderPopupPosition: {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 30 }
      },
      pageSizes: [10, 15, 25],

      showReportPanel: true,
      selectedReportId: null,

      initialLoad: true,
      countdown: 0,

      //Component
      componentKey: 0,
      reportTableInitialLoad: true,

      menu: [],
      date: [],

      formIsValid: false,
    }
  },

  mounted() {
    this.fetchUserReportList();

    if (!this.initialLoadOfUserReport && this.userReports.length < 1)
      this.checkUserIfHasAccess();
  },

  computed: {
    ...mapState('report', [
      'reportList',
      'reportColumns',
      'reportFilters',
      'initialLoadOfUserReport',
      'reportData',
      'loadingReportData'
    ]),

    records() {
      return this.reportData.length > 0 ? this.reportData : [];
    },

    userReports() {
      return this.reportList;
    },

    columns() {
      return this.reportColumns;
    },

    filters() {
      return this.reportFilters;
    },

    activeFab () {
      switch (this.showReportPanel) {
        case true : return { color: 'primary', icon: 'mdi-chevron-double-left', position: '450px' }
        case false : return { color: 'primary', icon: 'mdi-chevron-double-right', position: '0px' }
        default: return {}
      }
    },

    needFilters() {
      return this.reportFilters.length > 0;
    },

    firstColumn() {
      return this.reportColumns.length > 0 ? this.columns[0]["columnName"] : '';
    }
  },

  watch: {
    searchText: function (newVal, oldVal) { // eslint-disable-line no-unused-vars
      this.$refs[this.dataGridRefName].instance.searchByText(newVal?.trim());
    },

    async selectedReportId(val) {
      if (Number.isInteger(val)) {
        await this.fetchColumnAndFilters(val);

        // Load instantly if no filters needed
        if (this.reportFilters.length === 0)
        {
          this.loadData();
        }
      }
    },

    loadingReportData(val) {
      if (val)
        this.$refs[this.dataGridRefName].instance.beginCustomLoading();
      else
        this.$refs[this.dataGridRefName].instance.endCustomLoading();
    }
  },

  methods: {
    ...mapActions('report', [
        'fetchUserReportList',
        'fetchReportColumnList',
        'fetchReportFilterList',
        'fetchReportRecord',
    ]),

    ...mapMutations('report', [
       'resetReportFilters'
    ]),

    async fetchColumnAndFilters(reportId) {
      await this.fetchReportColumnList(reportId);
      await this.fetchReportFilterList(reportId);
    },

    async fetchColumnAndLoadTable(reportId) {
      await this.fetchReportColumnList(reportId);

      if (!this.reportTableInitialLoad)
        this.componentKey += 1

      this.reportTableInitialLoad = false;
    },

    loadData() {
      if (this.$refs.filtersForm.validate()) {
        this.fetchReportRecord(this.selectedReportId);
        this.showReportPanel = false;
      }
    },

    redirect() {
      this.$router.push('/');
    },

    checkUserIfHasAccess() {
      this.countdown = 5;
      let instance = this;
      let timer = setInterval( function () {
        instance.countdown--;
        if (instance.countdown <= 0) {
          clearInterval(timer);
          instance.redirect();
        }
      }, 1000)
    },

    //Grid functions
    getDataType(val) {
      let type = ''
      switch (true) {
        case val.includes("nvarchar") :
          type = 'string';
          break;
        case val.includes("int"):
          type = 'number';
          break;
        case val.includes("datetime") :
        case val.includes("date"):
          type = 'date';
          break;
      }
      return type;
    },

    exportXlsFile() {
      App["$appAnalytics"].trackEvent('Reports - '+ this.userReports.find(e => e.reportId === this.selectedReportId).reportName +' - Export xls file Click');
      this.$refs[this.dataGridRefName]["instance"]._options.export.fileName = this.userReports.find(e => e.reportId === this.selectedReportId).reportName;
      this.$refs[this.dataGridRefName]["instance"].exportToExcel(false);
    },

    contentReady(e) {
      let totalRecords = e.component.totalCount();
      e.component.option('pager.infoText', 'Records: ' + totalRecords + '. Page {0} of {1}');
    },
  }
}
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
#report .v-btn--showReportPanel {
  top: 0;
  position: absolute;
  margin: 0 0 0 0;
  border-radius: unset;
}

.v-btn--generateExcel, .v-btn--loadData {
  margin: 16px;
}
</style>